import { combineReducers } from 'redux';

import { casinoReducer } from './CasinoReducer';
import { customerDataReducer } from './customerData';
import { customerManagerReducer } from './customerManager';

export const customerReducer = combineReducers({
  casinoReducer,
  customerDataReducer,
  customerManagerReducer
});
