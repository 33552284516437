export const filtersObj = {
	general: {
		sport: '',
		names: '',
		home: {},
		away: {},
		country: '',
		season: '',
		status: '',
		matchStatus: '',
		homeCompetitorId: '',
		awayCompetitorId: '',
		tournament: '',
		scheduledStart: '',
		actualStart: '',
		matchStatusDescriptions: {
			en: { name: '', translated: true },
			bg: { name: '', translated: true },
			tr: { name: '', translated: true },
			gr: { name: '', translated: true },
			ro: { name: '', translated: true }
		}
	},
	marketTypesList: [],
	markets: [
		{
			id: Date.now() + Math.random(),
			marketCategory: '',
			marketType: '',
			outcomes: [],
			assigned: false,
			specifiers: ''
		}
	],
	specifiers: []
};

export const resetEventForm = {
	sport: '',
	names: '',
	home: {},
	away: {},
	country: '',
	season: '',
	status: '',
	matchStatus: '',
	homeCompetitorId: '',
	awayCompetitorId: '',
	tournament: '',
	scheduledStart: '',
	actualStart: '',
	matchStatusDescriptions: {
		en: { name: '', translated: true },
		bg: { name: '', translated: true },
		tr: { name: '', translated: true },
		gr: { name: '', translated: true },
		ro: { name: '', translated: true }
	}
};

export const resetMarketObj = () => ({
	id: Date.now() + Math.random(),
	marketCategory: '',
	marketType: '',
	outcomes: [],
	assigned: false,
	specifiers: ''
});
