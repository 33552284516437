import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Close from '../Icons/Close';

const FullScreenImg = ({ isFullScreen, close, url }) => {
  return (
    <Fragment>
      {isFullScreen ? (
        <Fragment>
          <div className="background-layer" onClick={close} />
          <div className="pop-up-img">
            <img
              src={url}
              alt="imagePreview"
              className="img-fluid img-thumbnail"
            />
            <span onClick={close} className="close">
              <Close size="md" />
            </span>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

FullScreenImg.propTypes = {
  isFullScreen: PropTypes.bool,
  close: PropTypes.func,
  url: PropTypes.string,
};

export default FullScreenImg;