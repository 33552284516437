import React from 'react';
import InputField from '../Form/InputField';

const SearchBar = ({ value, onChange }) => {
	return (
		<div className="efb-form">
			<InputField 
				id="search"
				name="search"
				placeholder="Search"
				type="text"
				className="form-control"
				value={value}
				onChange={onChange}
				label="Search"
			/>
		</div>
	);
}

export default SearchBar;
