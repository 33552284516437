import React, { Fragment, useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
  getCMSData,
  createLoadingSelector,
  getLanguages,
  getBonusSystemData,
} from '../../../selectors';
import * as RankingActions from '../../../redux/actions/cms/ranking';
import * as LanguagesActions from '../../../redux/actions/Languages';
import * as MarketCategoriesActions from '../../../redux/actions/cms/marketCategories';
import * as BonusSystemActions from '../../../redux/actions/BonusSystem';
import PropTypes from 'prop-types';
import { addNotificationMessage } from '../../../redux/actions/ui';
import FullScreenLoader from '../../../components/Layout/FullScreenLoader/FullScreenLoader';
import { isExistMarketType, isExistMarketCatId } from './MarketCategoriesUtils';
import { notification } from '../../../config/constants';
import MarketCategoryForm from './MarketCategoryForm';
import MarketCategoriesTable from './MarketCategoriesTable';
import MarketTypesTable from './MarketTypes/MarketTypesTable';
import { selectStyles } from '../customReactSelectStyle';
import { useStateForSelect, useMount, useUpdate } from '../../../hooks';

const MarketCategories = ({
  getCategories,
  getMarketTypes,
  getAllLanguages,
  getBusinessUnits,
  businessUnits,
  getAdminMarketType,
  marketTypes,
  loading,
  allMarketTypes,
  addNotificationMessage,
  saveCategoryRanking,
  categories,
  saveTypesRanking,
  saveOpenSelection,
  updateMarketType,
  getMarketTypeDetails,
  updateMarketCategory,
}) => {
  const [marketCategory, setMarketCategory] = useState({});
  const [resetMarketTypes, setResetMarketTypes] = useState(false);
  const [marketTypeToAssign, setMarketTypeToAssign] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [marketCategoryName, setMarketCategoryName] = useState('');
  const [marketCategoryId, setMarketCategoryId] = useState('');
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useStateForSelect('');
  const [childFuncs, setChildFuncs] = useState([]);

  useMount(() => {
    getCategories();
    getMarketTypes();
    getAllLanguages();
    getBusinessUnits();
  });

  useUpdate(() => {
    resetChildrenOrdering();
    setResetMarketTypes(true);
    getCategories(selectedBusinessUnit.value);
    setSelectedBusinessUnit(selectedBusinessUnit);
  }, [selectedBusinessUnit]);

  const businessUnitsList = useMemo(() => {
    return businessUnits
      ? businessUnits.map(bu => {
          return { label: bu.name, value: bu.name };
        })
      : null;
  }, [businessUnits]);

  const resetChildrenOrdering = useCallback(() => {
    for (let func of childFuncs) {
      func();
    }
  }, [childFuncs]);

  const pushChildFunc = useCallback(
    func => {
      const childFuncsClone = [...childFuncs];
      childFuncsClone.push(func);
      setChildFuncs(childFuncsClone);
    },
    [childFuncs]
  );

  const updateAssignedMarketType = useCallback(
    mt => {
      const marketTypeForAssignClone = { ...mt.marketType };
      getAdminMarketType(marketTypeForAssignClone.id);
      setMarketTypeToAssign(marketTypeForAssignClone);
    },
    [getAdminMarketType]
  );

  const assignMarketType = useCallback(
    marketType => {
      if (marketType) {
        if (isExistMarketType(marketType, marketTypes)) {
          addNotificationMessage(
            notification.page.marketCategories.error1.message,
            notification.type.error.name,
            notification.type.error.title
          );
        } else {
          if (isExistMarketCatId(marketCategoryId, marketType)) {
            addNotificationMessage(
              notification.page.marketCategories.error2.message,
              notification.type.error.name,
              notification.type.error.title
            );
          } else {
            const marketTypeClone = { ...marketType };
            marketTypeClone.categories.push({
              id: marketCategoryId,
              specifiers: 'ui_tab',
            });
            updateMarketType(
              marketTypeClone,
              marketCategoryId,
              selectedBusinessUnit.value
            );
          }
        }
      }
      setMarketTypeToAssign({});
    },
    [
      addNotificationMessage,
      marketCategoryId,
      marketTypes,
      selectedBusinessUnit.value,
      updateMarketType,
    ]
  );

  const editCategory = useCallback(category => {
    window.scrollTo(0, 0);
    setMarketCategory(category);
    setIsEditMode(true);
  }, []);

  const viewMarketTypes = useCallback(
    category => {
      if (selectedBusinessUnit) {
        getMarketTypeDetails(category.id, selectedBusinessUnit.value);
        setResetMarketTypes(false);
        setMarketCategoryName(
          category.names.en ? category.names.en.name : 'Not available name'
        );
        setMarketCategoryId(category.id);
      } else {
        addNotificationMessage(
          'You firstly need to select Business Unit.',
          'error',
          'Error'
        );
      }
    },
    [getMarketTypeDetails, selectedBusinessUnit, addNotificationMessage]
  );

  const removeFromMarketCategory = useCallback(
    mt => {
      const index = mt.categories.findIndex(x => x.id === marketCategoryId);
      mt.categories.splice(index, 1);
      updateMarketType(mt, marketCategoryId, selectedBusinessUnit.value);
    },
    [marketCategoryId, selectedBusinessUnit.value, updateMarketType]
  );

  const finishMarketCatEdit = useCallback(
    editedMarketCat => {
      updateMarketCategory(editedMarketCat);
      setIsEditMode(false);
    },
    [updateMarketCategory]
  );

  return (
    <Fragment>
      <FullScreenLoader isActive={loading} />

      {isEditMode ? (
        <MarketCategoryForm
          updateMarketCategory={finishMarketCatEdit}
          marketCategoryObj={marketCategory}
          isEditMode={isEditMode}
          cancelEdit={() => setIsEditMode(false)}
        />
      ) : null}

      <div className="row">
        <div className="col-md">
          <Select
            value={selectedBusinessUnit}
            onChange={setSelectedBusinessUnit}
            options={businessUnitsList}
            styles={selectStyles}
            className="mb-3"
            placeholder="Select Business unit"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <MarketCategoriesTable
            addNotificationMessage={addNotificationMessage}
            saveRanking={saveCategoryRanking}
            setResetOrdering={pushChildFunc}
            categoryList={categories}
            getMarketTypes={getMarketTypes}
            editCategory={editCategory}
            viewMarketTypes={viewMarketTypes}
            selectedBusinessUnit={selectedBusinessUnit}
          />
        </div>
        <div className="col-md-6">
          {!resetMarketTypes ? (
            <MarketTypesTable
              addNotificationMessage={addNotificationMessage}
              businessUnit={selectedBusinessUnit.value}
              saveRanking={saveTypesRanking}
              saveOpenSelection={saveOpenSelection}
              setResetOrdering={pushChildFunc}
              marketTypesList={marketTypes}
              allMarketTypes={allMarketTypes}
              marketTypeToAssign={marketTypeToAssign}
              updateAssignedMarketType={updateAssignedMarketType}
              marketCatName={marketCategoryName}
              marketCategoryId={marketCategoryId}
              removeFromMarketCategory={removeFromMarketCategory}
              assignMarketType={assignMarketType}
            />
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

const loadingReducer = createLoadingSelector(['GET_MARKET_TYPES']);

MarketCategories.propTypes = {
  addMarketType: PropTypes.func,
  allMarketTypes: PropTypes.array,
  assignMarketTypeToCategory: PropTypes.func,
  categories: PropTypes.array,
  getCategories: PropTypes.func,
  getMarketTypeDetails: PropTypes.func,
  getMarketTypes: PropTypes.func,
  getOutcomes: PropTypes.func,
  marketTypes: PropTypes.array,
  updateMarketType: PropTypes.func,
};

const mapStateToProps = state => ({
  categories: getCMSData(state).marketCategoriesReducer.categories,
  marketTypes: getCMSData(state).marketCategoriesReducer.marketTypes,
  allMarketTypes: getCMSData(state).marketCategoriesReducer.allMarketTypes,
  marketType: getCMSData(state).marketCategoriesReducer.marketType,
  loading: loadingReducer(state),
  languages: getLanguages(state).languages,
  businessUnits: getBonusSystemData(state).businessUnits,
});

const mapDispatchToProps = {
  getCategories: MarketCategoriesActions.getCategories,
  getMarketTypeDetails: MarketCategoriesActions.getMarketTypeDetails,
  addMarketType: MarketCategoriesActions.addMarketType,
  saveCategoryRanking: RankingActions.saveCategoryRanking,
  saveTypesRanking: RankingActions.saveTypesRanking,
  saveOpenSelection: RankingActions.saveOpenSelection,
  getAllLanguages: LanguagesActions.getAllLanguages,
  addNotificationMessage,
  getMarketTypes: MarketCategoriesActions.getMarketTypes,
  updateMarketType: MarketCategoriesActions.updateMarketType,
  getAdminMarketType: MarketCategoriesActions.getAdminMarketType,
  updateMarketCategory: MarketCategoriesActions.updateMarketCategory,
  getBusinessUnits: BonusSystemActions.getBusinessUnits,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MarketCategories)
);
