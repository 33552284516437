import React, { useCallback } from 'react';
import { useStateForInput } from '../../../../../hooks';
import Button from '../../../../../components/Layout/Button';
import Modal from '../../../../../components/NewModal/Modal';
import Textarea from '../../../../../components/Form/Textarea/Textarea';

const RejectModal = ({
  reject,
  activeModal,
  setActiveModal,
  currentDocument,
  refetchDocuments,
}) => {
  const [comment, setComment, resetComment] = useStateForInput('');

  const rejectDocument = useCallback(
    async document => {
      await reject({
        cancel_reason: comment,
        customerId: document.customerId,
        documentId: document.id,
      });
      await refetchDocuments();
      await setActiveModal(false);
      await resetComment();
    },
    [comment, refetchDocuments, reject, resetComment, setActiveModal]
  );

  return (
    <Modal
      className="p-3"
      isActive={activeModal}
      onClose={() => setActiveModal(false)}
    >
      <div className="modal-wrapper efb-form transaction-exception-resolve-modal">
        <div className="resolve-wrapper">
          <Textarea
            placeholder="Reason for reject"
            name="comment"
            onChange={setComment}
            value={comment}
          />
          <div className="col-md">
            <Button
              cssClass={'bet-scroller-btn cancel-btn'}
              onClick={() => rejectDocument(currentDocument)}
            >
              Reject
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RejectModal;
