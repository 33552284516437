import React from 'react';
import classes from './RadioButton.module.scss';
import Input from '../Input';

const RadioButton = ({
  className,
  label,
  name,
  onChange,
  isChecked,
  value,
  id,
}) => (
  <div className={`${classes.radioInput} ${className}`}>
    <Input
      type="radio"
      id={id}
      name={name}
      onChange={onChange}
      isChecked={isChecked}
      value={value}
    />
    <label htmlFor={id}>{label}</label>
  </div>
);

export default RadioButton;
