export default {
  spinner: {
    size: 50,
  },
};

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const languages = ['en', 'bg', 'ro', 'tr', 'gr'];

export const notification = {
  type: {
    error: {
      name: 'error',
      title: 'Error!',
    },
    success: {
      name: 'success',
      title: 'Success!',
    },
  },
  page: {
    marketCategories: {
      error1: {
        message: 'This market type already exist in this category',
      },
      error2: {
        message:
          'This market type already exist in this category but is not showing',
      },
    },
  },
};

export const tinyMceApiKey = '1qf4j3jcsgb8bdpp76rlfd41omtermmrr1165uorm3n3lme9';

export const BETRADAR_PRODUCER_ID = '3';
