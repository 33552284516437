import axiosInstance from '../axios-instance';
import { actionCreator, createRequestTypes } from '../index';
import { addNotificationMessage } from '../ui';

export const TEST_CASHOUT = createRequestTypes('TEST_CASHOUT');

export const GET_COEFFICIENTS = createRequestTypes('GET_COEFFICIENT');
export const GET_TIMES = createRequestTypes('GET_TIMES');
export const GET_INPLAY_MODELS = createRequestTypes('GET_INPLAY_MODELS');
export const GET_PREMATCH_MODELS = createRequestTypes('GET_PREMATCH_MODELS');
export const GET_MULTIPLE_COEFFICIENTS = createRequestTypes(
  'GET_MULTIPLE_COEFFICIENTS'
);
export const GET_MULTIPLE_MODELS = createRequestTypes('GET_MULTIPLE_MODELS');
export const GET_MULTIPLE_TIMES = createRequestTypes('GET_MULTIPLE_TIMES');
export const GET_PREMATCH_MULTIPLE_MODELS = createRequestTypes(
  'GET_PREMATCH_MULTIPLE_MODELS'
);
export const GET_PREMATCH_MULTIPLE_STATIC_MODELS = createRequestTypes(
  'GET_PREMATCH_MULTIPLE_STATIC_MODELS'
);
export const GET_SYSTEM_STATIC_PARAMETERS = createRequestTypes(
  'GET_SYSTEM_STATIC_PARAMETERS'
);

export const SET_SPORT_CONFIG = createRequestTypes('SET_SPORT_CONFIG');
export const SET_BET_TYPE_CONFIG = createRequestTypes('SET_BET_TYPE_CONFIG');
export const SET_RISK_CATEGORY_CONFIG = createRequestTypes(
  'SET_RISK_CATEGORY_CONFIG'
);
export const SET_EVENT_CONFIG = createRequestTypes('SET_EVENT_CONFIG');
export const SET_MARKET_CONFIG = createRequestTypes('SET_MARKET_CONFIG');
export const SET_MARKET_TYPE_CONFIG = createRequestTypes(
  'SET_MARKET_TYPE_CONFIG'
);
export const SET_CUSTOMER_CONFIG = createRequestTypes('SET_CUSTOMER_CONFIG');

export const clearFormulaResult = () => dispatch => {
  dispatch(actionCreator.empty(TEST_CASHOUT));
};

export const testCashout = (cashoutType, payload) => async dispatch => {
  dispatch(actionCreator.request(TEST_CASHOUT));
  try {
    const res = await axiosInstance.post(
      `/cashout/admin/test/${cashoutType}`,
      payload
    );
    dispatch(actionCreator.success(TEST_CASHOUT, res));
    dispatch(
      addNotificationMessage('Test passed successfully!', 'success', 'Success!')
    );
  } catch (error) {
    dispatch(actionCreator.failure(TEST_CASHOUT, error));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getCoefficients = () => async dispatch => {
  dispatch(actionCreator.request(GET_COEFFICIENTS));
  try {
    const res = await axiosInstance.get(
      '/cashout/admin/inplay/coefficient/parameters'
    );
    dispatch(actionCreator.success(GET_COEFFICIENTS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_COEFFICIENTS, error));
  }
};

export const updateCoefficients = objFilter => async dispatch => {
  try {
    await axiosInstance.put(
      '/cashout/admin/inplay/coefficient/parameters',
      objFilter
    );
    dispatch(getCoefficients());
    dispatch(
      addNotificationMessage(
        'Config updated successfully!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getTimes = () => async dispatch => {
  dispatch(actionCreator.request(GET_TIMES));
  try {
    const res = await axiosInstance.get(
      '/cashout/admin/inplay/time/parameters'
    );
    dispatch(actionCreator.success(GET_TIMES, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_TIMES, error));
  }
};

export const updateTimes = objFilter => async dispatch => {
  try {
    await axiosInstance.put('/cashout/admin/inplay/time/parameters', objFilter);
    dispatch(getTimes());
    dispatch(
      addNotificationMessage(
        'Config updated successfully!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getInPlayModels = () => async dispatch => {
  dispatch(actionCreator.request(GET_INPLAY_MODELS));
  try {
    const res = await axiosInstance.get(
      '/cashout/admin/inplay/model/parameters'
    );
    dispatch(actionCreator.success(GET_INPLAY_MODELS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_INPLAY_MODELS, error));
  }
};

export const updateInPlayModels = objFilter => async dispatch => {
  try {
    await axiosInstance.put(
      '/cashout/admin/inplay/model/parameters',
      objFilter
    );
    dispatch(getInPlayModels());
    dispatch(
      addNotificationMessage(
        'Config updated successfully!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getPrematchModels = () => async dispatch => {
  dispatch(actionCreator.request(GET_PREMATCH_MODELS));
  try {
    const res = await axiosInstance.get(
      '/cashout/admin/prematch/model/parameters'
    );
    dispatch(actionCreator.success(GET_PREMATCH_MODELS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_PREMATCH_MODELS, error));
  }
};

export const updatePrematchModels = objFilter => async dispatch => {
  try {
    await axiosInstance.put(
      '/cashout/admin/prematch/model/parameters',
      objFilter
    );
    dispatch(getPrematchModels());
    dispatch(
      addNotificationMessage(
        'Config updated successfully!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

// Multiple Inplay and Prematch

export const getMultipleCoefficients = () => async dispatch => {
  dispatch(actionCreator.request(GET_MULTIPLE_COEFFICIENTS));
  try {
    const res = await axiosInstance.get(
      '/cashout/admin/inplay/multiple/coefficient/parameters'
    );
    dispatch(actionCreator.success(GET_MULTIPLE_COEFFICIENTS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_MULTIPLE_COEFFICIENTS, error));
  }
};

export const updateMultipleCoefficients = objFilter => async dispatch => {
  try {
    await axiosInstance.put(
      '/cashout/admin/inplay/multiple/coefficient/parameters',
      objFilter
    );
    dispatch(getMultipleCoefficients());
    dispatch(
      addNotificationMessage(
        'Config updated successfully!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getMultipleModels = () => async dispatch => {
  dispatch(actionCreator.request(GET_MULTIPLE_MODELS));
  try {
    const res = await axiosInstance.get(
      '/cashout/admin/inplay/multiple/model/parameters'
    );
    dispatch(actionCreator.success(GET_MULTIPLE_MODELS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_MULTIPLE_MODELS, error));
  }
};

export const updateMultipleModels = objFilter => async dispatch => {
  try {
    await axiosInstance.put(
      '/cashout/admin/inplay/multiple/model/parameters',
      objFilter
    );
    dispatch(getMultipleModels());
    dispatch(
      addNotificationMessage(
        'Config updated successfully!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getMultipleTimes = () => async dispatch => {
  dispatch(actionCreator.request(GET_MULTIPLE_TIMES));
  try {
    const res = await axiosInstance.get(
      '/cashout/admin/inplay/multiple/time/parameters'
    );
    dispatch(actionCreator.success(GET_MULTIPLE_TIMES, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_MULTIPLE_TIMES, error));
  }
};

export const updateMultipleTimes = objFilter => async dispatch => {
  try {
    await axiosInstance.put(
      '/cashout/admin/inplay/multiple/time/parameters',
      objFilter
    );
    dispatch(getMultipleTimes());
    dispatch(
      addNotificationMessage(
        'Config updated successfully!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getPrematchMultipleModels = () => async dispatch => {
  dispatch(actionCreator.request(GET_PREMATCH_MULTIPLE_MODELS));
  try {
    const res = await axiosInstance.get(
      '/cashout/admin/prematch/multiple/model/parameters'
    );
    dispatch(actionCreator.success(GET_PREMATCH_MULTIPLE_MODELS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_PREMATCH_MULTIPLE_MODELS, error));
  }
};

export const updatePrematchMultipleModels = objFilter => async dispatch => {
  try {
    await axiosInstance.put(
      '/cashout/admin/prematch/multiple/model/parameters',
      objFilter
    );
    dispatch(getPrematchMultipleModels());
    dispatch(
      addNotificationMessage(
        'Config updated successfully!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getPrematchMultipleStaticModels = () => async dispatch => {
  dispatch(actionCreator.request(GET_PREMATCH_MULTIPLE_STATIC_MODELS));
  try {
    const res = await axiosInstance.get(
      '/cashout/admin/prematch/multiple/static/model/parameters'
    );
    dispatch(actionCreator.success(GET_PREMATCH_MULTIPLE_STATIC_MODELS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_PREMATCH_MULTIPLE_STATIC_MODELS, error));
  }
};

export const updatePrematchMultipleStaticModels = objFilter => async dispatch => {
  try {
    await axiosInstance.put(
      '/cashout/admin/prematch/multiple/static/model/parameters',
      objFilter
    );
    dispatch(getPrematchMultipleStaticModels());
    dispatch(
      addNotificationMessage(
        'Config updated successfully!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getSystemStaticParameters = () => async dispatch => {
  dispatch(actionCreator.request(GET_SYSTEM_STATIC_PARAMETERS));
  try {
    const res = await axiosInstance.get(
      '/cashout/admin/system/static/parameters'
    );
    dispatch(actionCreator.success(GET_SYSTEM_STATIC_PARAMETERS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_SYSTEM_STATIC_PARAMETERS, error));
  }
};

export const updateSystemStaticParameters = objFilter => async dispatch => {
  try {
    await axiosInstance.put(
      '/cashout/admin/system/static/parameters',
      objFilter
    );
    dispatch(getSystemStaticParameters());
    dispatch(
      addNotificationMessage(
        'Config updated successfully!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

// Cashout Configurator

export const createOrUpdateSportConfig = filter => async dispatch => {
  dispatch(actionCreator.request(SET_SPORT_CONFIG));
  try {
    const res = await axiosInstance.post('/cashout/admin/config/sport', filter);
    dispatch(
      addNotificationMessage(
        `Config ${filter.id ? 'Updated' : 'Created'} successfully!`,
        'success',
        'Success!'
      )
    );
    dispatch(actionCreator.success(SET_SPORT_CONFIG, res));
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    dispatch(actionCreator.failure(SET_SPORT_CONFIG, error));
  }
};

export const createOrUpdateBetTypeConfig = filter => async dispatch => {
  dispatch(actionCreator.request(SET_BET_TYPE_CONFIG));
  try {
    const res = await axiosInstance.post(
      '/cashout/admin/config/betType',
      filter
    );
    dispatch(
      addNotificationMessage(
        `Config ${filter.id ? 'Updated' : 'Created'} successfully!`,
        'success',
        'Success!'
      )
    );
    dispatch(actionCreator.success(SET_BET_TYPE_CONFIG, res));
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    dispatch(actionCreator.failure(SET_BET_TYPE_CONFIG, error));
  }
};

export const createOrUpdateRiskCategoryConfig = filter => async dispatch => {
  dispatch(actionCreator.request(SET_RISK_CATEGORY_CONFIG));
  try {
    const res = await axiosInstance.post(
      '/cashout/admin/config/riskCategory',
      filter
    );
    dispatch(
      addNotificationMessage(
        `Config ${filter.id ? 'Updated' : 'Created'} successfully!`,
        'success',
        'Success!'
      )
    );
    dispatch(actionCreator.success(SET_RISK_CATEGORY_CONFIG, res));
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    dispatch(actionCreator.failure(SET_RISK_CATEGORY_CONFIG, error));
  }
};

export const createOrUpdateSportEventConfig = filter => async dispatch => {
  dispatch(actionCreator.request(SET_EVENT_CONFIG));
  try {
    const res = await axiosInstance.post(
      '/cashout/admin/config/sportEvent',
      filter
    );
    dispatch(
      addNotificationMessage(
        `Config ${filter.id ? 'Updated' : 'Created'} successfully!`,
        'success',
        'Success!'
      )
    );
    dispatch(actionCreator.success(SET_EVENT_CONFIG, res));
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    dispatch(actionCreator.failure(SET_EVENT_CONFIG, error));
  }
};

export const createOrUpdateMarketConfig = filter => async dispatch => {
  dispatch(actionCreator.request(SET_MARKET_CONFIG));
  try {
    const res = await axiosInstance.post(
      '/cashout/admin/config/market',
      filter
    );
    dispatch(
      addNotificationMessage(
        `Config ${filter.id ? 'Updated' : 'Created'} successfully!`,
        'success',
        'Success!'
      )
    );
    dispatch(actionCreator.success(SET_MARKET_CONFIG, res));
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    dispatch(actionCreator.failure(SET_MARKET_CONFIG, error));
  }
};

export const createOrUpdateCustomer = filter => async dispatch => {
  dispatch(actionCreator.request(SET_CUSTOMER_CONFIG));
  try {
    const res = await axiosInstance.post(
      '/cashout/admin/config/customer',
      filter
    );
    dispatch(
      addNotificationMessage(
        `Config ${filter.id ? 'Updated' : 'Created'} successfully!`,
        'success',
        'Success!'
      )
    );
    dispatch(actionCreator.success(SET_CUSTOMER_CONFIG, res));
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    dispatch(actionCreator.failure(SET_CUSTOMER_CONFIG, error));
  }
};

export const createOrUpdateMarketTypeConfig = filter => async dispatch => {
  dispatch(actionCreator.request(SET_MARKET_TYPE_CONFIG));
  try {
    const res = await axiosInstance.post(
      '/cashout/admin/config/marketType',
      filter
    );
    dispatch(
      addNotificationMessage(
        `Config ${filter.id ? 'Updated' : 'Created'} successfully!`,
        'success',
        'Success!'
      )
    );
    dispatch(actionCreator.success(SET_MARKET_TYPE_CONFIG, res));
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    dispatch(actionCreator.failure(SET_MARKET_TYPE_CONFIG, error));
  }
};
