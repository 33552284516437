import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { getTransactionData } from '../../../../selectors';
import { selectStyles } from '../../../../containers/CMS/customReactSelectStyle';
import { useUpdate, useUnmount } from '../../../../hooks';

import * as transactionsActions from '../../../../redux/actions/TransactionBuilder';

import Switch from '../../../../components/Form/Switch/Switch';
import Table from '../../../../components/NewTable/Table';
import SelectField from '../../../../components/Form/SelectField';

const Currencies = ({
  currencieOptions,
  providerId,
  updateCurrencyPerProvider,
  getAvailableCurrency,
  availableCurrency,
  empetyAvailableCurrency,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [currency, setCurrency] = useState('');

  useUnmount(() => {
    setCurrency('');
    empetyAvailableCurrency('');
  });

  useUpdate(() => {
    if (currency) {
      getAvailableCurrency(providerId, currency);
    } else {
      setCurrency('');
    }
  }, [currency]);

  useUpdate(() => {
    if (availableCurrency) {
      const { currencyCode, available } = availableCurrency;
      setCurrency(currencyCode);
      setIsActive(available);
    } else {
      setIsActive(false);
    }
  }, [availableCurrency]);

  const currencyColumn = useMemo(
    () => [
      {
        name: 'Currency',
        display: entity => entity.currency,
      },
      {
        name: 'Enabled/Disabled',
        display: entity => (
          <div>
            <Switch
              smallSwitch
              name="enabled"
              isChecked={entity.available}
              onChange={e =>
                updateCurrencyPerProvider({
                  available: !isActive,
                  currencyCode: entity.currency,
                  providerId: providerId,
                })
              }
            />
          </div>
        ),
      },
      {
        name: 'Available',
        display: entity => (entity.available ? 'YES' : 'NO'),
      },
    ],
    [isActive, updateCurrencyPerProvider, providerId]
  );

  return (
    <div>
      <div className="form-label-group efb-form pt-3 m-auto">
        <div className="form-row d-flex justify-content-center pt-3 pb-5 px-0 m-2">
          <div className="col-6">
            <SelectField
              options={currencieOptions}
              onChange={e => setCurrency(e.target.value)}
              label="Currencies"
              name="currencyCode"
              placeholder="Currency"
              styles={selectStyles}
              value={currency}
            />
          </div>
        </div>
      </div>
      <div className="col-8 m-auto p-4">
        {currency ? (
          <Table
            data={[
              {
                currency: currency,
                available: isActive,
              },
            ]}
            columns={currencyColumn}
            pagination={false}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  availableCurrency: getTransactionData(state).availableCurrency,
});

const mapDispatchToProps = {
  getAvailableCurrency: transactionsActions.getAvailableCurrency,
  empetyAvailableCurrency: transactionsActions.empetyAvailableCurrency,
  updateCurrencyPerProvider: transactionsActions.updateCurrencyPerProvider,
};

export default connect(mapStateToProps, mapDispatchToProps)(Currencies);
