import React, { useCallback, useMemo, useState } from 'react';
import { debounce } from '../../../utils/helpers';
import Switch from '../../Form/Switch/Switch';
import Button from '../../Layout/Button';
import PropTypes from 'prop-types';
import { useUpdate } from '../../../hooks';
import moment from 'moment';

const EventBuilderMarkets = ({
  tableData,
  proceedToUpdateMarketStatus,
  showMarketOdds,
  showMarketSpecifiers,
  updateDefaultMarket,
}) => {
  const [markets, setMarkets] = useState([]);

  useUpdate(() => {
    setMarkets(tableData);
  }, [tableData]);

  const updateMarketStatus = useCallback(
    (e, marketIndex) => {
      const checked = e.target.checked;
      const marketsClone = [...markets];
      marketsClone[marketIndex].marketStatus = checked
        ? 'ACTIVE'
        : 'DEACTIVATED';
      setMarkets(marketsClone);
      proceedToUpdateMarketStatus(marketsClone[marketIndex]);
    },
    [markets, proceedToUpdateMarketStatus]
  );

  const debouncedUpdateMarket = useMemo(
    () => debounce(updateDefaultMarket, 500),
    [updateDefaultMarket]
  );

  const setDefaultMarket = useCallback(
    index => {
      const marketsClone = [...markets];
      const previousDefaultMarket = marketsClone.find(
        x => x.defaultMarket === true
      );
      if (previousDefaultMarket.id === marketsClone[index].id) return
      if (previousDefaultMarket) previousDefaultMarket.defaultMarket = false;
      marketsClone[index].defaultMarket = true;
      setMarkets(marketsClone);
      debouncedUpdateMarket(marketsClone[index], previousDefaultMarket);
    },
    [debouncedUpdateMarket, markets]
  );

  const renderRow = useCallback(
    (market, i) => {
      return (
        <tr key={market.id}>
          <td>{i + 1}.</td>
          <td className="capitalize">
            {market.names.en ? market.names.en.name : 'Not available name'}
          </td>
          <td>{moment.utc(market.createdOn).format('DD/MM/YYYY')}</td>
          <td>
            <Switch
              onChange={() => setDefaultMarket(i)}
              value={market.defaultMarket}
              isChecked={market.defaultMarket}
            />
          </td>
          <td>
            <Switch
              isChecked={market.marketStatus === 'ACTIVE'}
              onChange={e => updateMarketStatus(e, i)}
            />
          </td>
          <td>
            <Button
              cssClass="btn btn-primary"
              onClick={() => showMarketOdds(market)}
            >
              <i className="fa fa-eye" /> View Odds
            </Button>
            <Button
              disabled={Object.keys(market.specifiers).length === 0}
              cssClass="btn btn-primary"
              onClick={() => showMarketSpecifiers(market)}
            >
              <i className="fa fa-eye" /> View Specifiers
            </Button>
          </td>
        </tr>
      );
    },
    [setDefaultMarket, updateMarketStatus, showMarketOdds, showMarketSpecifiers]
  );
  return markets.map(renderRow);
};

EventBuilderMarkets.propTypes = {
  tableData: PropTypes.array,
  isDefaultMarketSet: PropTypes.bool,
  setAsDefaultMarket: PropTypes.func,
  unsetDefaultMarket: PropTypes.func,
  proceedToUpdateMarketStatus: PropTypes.func,
  showMarketSpecifiers: PropTypes.func,
  showMarketOdds: PropTypes.func,
};

export default EventBuilderMarkets;
