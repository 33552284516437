import { actionCreator, createRequestTypes } from '../index';
import axiosInstance from '../axios-instance';
import { addNotificationMessage } from '../ui';

export const GET_RISK_CATEGORIES = createRequestTypes('GET_RISK_CATEGORIES');
export const CREATE_RISK_CATEGORY = createRequestTypes('CREATE_RISK_CATEGORY');
export const UPDATE_RISK_CATEGORY = createRequestTypes('UPDATE_RISK_CATEGORY');

export const getRiskCategories = () => async dispatch => {
  dispatch(actionCreator.request(GET_RISK_CATEGORIES));
  try {
    const res = await axiosInstance.get('/riskmgnt/view/admin/riskCategories');
    dispatch(actionCreator.success(GET_RISK_CATEGORIES, res));
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    dispatch(actionCreator.failure(GET_RISK_CATEGORIES, error));
  }
};

export const createRiskCategory = payload => async dispatch => {
  dispatch(actionCreator.request(CREATE_RISK_CATEGORY));
  try {
    await axiosInstance.post('/riskmgnt/view/admin/riskCategories', payload);
    dispatch(actionCreator.success(CREATE_RISK_CATEGORY));
    dispatch(
      addNotificationMessage(
        'Successfully created risk category',
        'success',
        'Success!'
      )
    );
    getRiskCategories()(dispatch);
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    for (let err of Object.values(error.errors)) {
      dispatch(addNotificationMessage(err, 'error', 'Error!'));
    }
    dispatch(actionCreator.failure(CREATE_RISK_CATEGORY, error));
  }
};

export const updateRiskCategory = payload => async dispatch => {
  dispatch(actionCreator.request(UPDATE_RISK_CATEGORY));
  try {
    await axiosInstance.put(
      `/riskmgnt/view/admin/riskCategories/${payload.id}`,
      payload
    );
    dispatch(actionCreator.success(UPDATE_RISK_CATEGORY));
    dispatch(
      addNotificationMessage(
        'Successfully updated risk category',
        'success',
        'Success!'
      )
    );
    getRiskCategories()(dispatch);
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    for (let err of Object.values(error.errors)) {
      dispatch(addNotificationMessage(err, 'error', 'Error!'));
    }
    dispatch(actionCreator.failure(UPDATE_RISK_CATEGORY, error));
  }
};
