import React, { useState, Fragment, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getCMSData,
  getRiskManagementData,
  createLoadingSelector,
} from '../../../selectors';
import EventsMarkets from './EventsMarkets';
import EventMarketOutcomes from './EventMarketOutcomes';
import FullScreenLoader from '../../../components/Layout/FullScreenLoader/FullScreenLoader';
import * as SettlementActions from '../../../redux/actions/riskManagement/settlement';
import { useMount, useUpdate, useStateForSwitch } from '../../../hooks';
import { prepareForSettle } from './SettlementsUtils';
import Alert from '../../../components/Layout/Alert/Alert';
import Switch from '../../../components/Form/Switch/Switch';
import { addNotificationMessage } from '../../../redux/actions/ui';
import moment from 'moment';
import Sidebar from './Sidebar/Sidebar';

const Settlements = ({
  loading,
  eventSportInfo,
  settleMarket,
  market,
  getMarket,
  getPlacedBetData,
  placedBetsFiltered,
  getMarketsForSportEvent,
  getMarkets,
  markets,
  getEvent,
  event,
  rollbackSettledMarket,
  addNotificationMessage,
}) => {
  const [activeTab, setActiveTab] = useState('eventMarkets');
  const [marketOutcomes, setMarketOutcomes] = useState([]);
  const [marketForSettle, setMarketForSettle] = useState('');
  const [currentSportEventId, setCurrentSportEventId] = useState('');
  const [activeSettleSwitch, setActiveSettleSwitch] = useStateForSwitch(false);
  const [reopenSettlementMode, setReopenSettlementMode] = useState(false);
  const [deactivatedSwitch, setDeactivatedSwitch] = useStateForSwitch(false);
  const [activeMarketsSwitch, setActiveMarketsSwitch] = useStateForSwitch(
    false
  );
  const [currentStatus, setCurrentStatus] = useState('');

  useMount(() => {
    getPlacedBetData();
  });

  useUpdate(() => {
    if (market && !reopenSettlementMode) {
      if (Object.keys(market).length > 0) {
        setMarketForSettle(prepareForSettle(market));
      }
    }
  }, [market]);

  useUpdate(() => {
    if (deactivatedSwitch && currentSportEventId) {
      getMarkets(currentSportEventId, 'DEACTIVATED');
      setActiveMarketsSwitch(false);
      setActiveSettleSwitch(false);
      setCurrentStatus('DEACTIVATED');
    }
  }, [deactivatedSwitch]);

  useUpdate(() => {
    if (activeMarketsSwitch && currentSportEventId) {
      getMarkets(currentSportEventId, 'ACTIVE');
      setDeactivatedSwitch(false);
      setActiveSettleSwitch(false);
      setCurrentStatus('ACTIVE');
    }
  }, [activeMarketsSwitch]);

  useUpdate(() => {
    if (activeSettleSwitch && currentSportEventId) {
      getMarkets(currentSportEventId, 'SETTLED');
      setDeactivatedSwitch(false);
      setActiveMarketsSwitch(false);
      setCurrentStatus('SETTLED');
    }
  }, [activeSettleSwitch]);

  const viewOutcomes = useCallback(outcomes => {
    setMarketOutcomes(outcomes);
    setActiveTab('eventMarketOutcomes');
  }, []);

  const settleEventMarket = useCallback(
    market => {
      settleMarket(market, currentSportEventId, currentStatus);
    },
    [settleMarket, currentSportEventId, currentStatus]
  );

  const getMarketById = useCallback(
    id => {
      getMarket(id);
    },
    [getMarket]
  );

  getMarketsForSportEvent = useCallback(
    id => {
      setActiveMarketsSwitch(true);
      getMarkets(id);
      getEvent(id);
      setCurrentSportEventId(id);
    },
    [setActiveMarketsSwitch, getMarkets, getEvent]
  );

  const setRolledBackMarket = useCallback(
    market => {
      rollbackSettledMarket(market, currentSportEventId, currentStatus);
    },
    [rollbackSettledMarket, currentSportEventId, currentStatus]
  );

  const content = useMemo(() => {
    switch (activeTab) {
      case 'eventMarkets':
        return (
          <EventsMarkets
            eventMarkets={markets}
            setActiveTab={setActiveTab}
            market={market}
            eventSportInfo={eventSportInfo}
            settleEventMarket={settleEventMarket}
            getMarket={getMarketById}
            viewOutcomes={viewOutcomes}
            marketForSettle={marketForSettle}
            setRolledBackMarket={setRolledBackMarket}
            setReopenSettlementMode={setReopenSettlementMode}
            reopenSettlementMode={reopenSettlementMode}
            addNotificationMessage={addNotificationMessage}
          />
        );

      case 'eventMarketOutcomes':
        return (
          <EventMarketOutcomes
            marketOutcomes={marketOutcomes}
            setActiveTab={setActiveTab}
          />
        );

      default:
        break;
    }
  }, [
    activeTab,
    viewOutcomes,
    marketOutcomes,
    eventSportInfo,
    settleEventMarket,
    getMarketById,
    market,
    markets,
    marketForSettle,
    setRolledBackMarket,
    reopenSettlementMode,
    addNotificationMessage,
  ]);

  return (
    <Fragment>
      <FullScreenLoader isActive={loading} />
      <div className="row">
        <div className="col-md-3">
          <Sidebar
            placedBetsFiltered={placedBetsFiltered}
            getMarketsForSportEvent={getMarketsForSportEvent}
          />
        </div>
        <div className="col-md-9">
          {event ? (
            <>
              <h3 className="efb-h3">{`${event.names.en.name} (${moment(
                event.scheduledTime
              ).format('DD/MM/YYYY HH:mm')})`}</h3>{' '}
              <hr />
            </>
          ) : null}
          {event ? (
            event.competitionStatus.competitionStatus === 'FINISHED' ? (
              <>
                <Alert type="warning">
                  <i className="fa fa-poll-h" />{' '}
                  {`${event.names.en.name} - ${event.competitionStatus.homeScore}:${event.competitionStatus.awayScore}`}
                  <span className="float-right">
                    <i className="fa fa-calendar" />{' '}
                    {moment(event.scheduledTime).format('DD/MM/YYYY')}{' '}
                    <i className="fa fa-clock" />{' '}
                    {moment(event.scheduledTime).format('HH:MM')}
                  </span>
                </Alert>
                <br />
              </>
            ) : null
          ) : null}

          {markets.length === 0 ? (
            <>
              <Alert type="info">
                <i className="fa fa-info-circle	" /> No markets to show.
              </Alert>
              <br />
            </>
          ) : null}
          <Switch
            label={'Active markets'}
            name="activeMarkets"
            isDisabled={!currentSportEventId}
            onChange={setActiveMarketsSwitch}
            value={activeMarketsSwitch}
            isChecked={activeMarketsSwitch}
          />
          <Switch
            label={'Deactivated markets'}
            name="deactivatedMarkets"
            onChange={setDeactivatedSwitch}
            isDisabled={!currentSportEventId}
            value={deactivatedSwitch}
            isChecked={deactivatedSwitch}
          />
          <Switch
            label={'Settled markets'}
            name="settledMarkets"
            isDisabled={!currentSportEventId}
            onChange={setActiveSettleSwitch}
            value={activeSettleSwitch}
            isChecked={activeSettleSwitch}
          />
          {content}
        </div>
      </div>
    </Fragment>
  );
};

Settlements.propTypes = {
  events: PropTypes.array,
  getEventMarkets: PropTypes.func,
  eventMarkets: PropTypes.array,
  loading: PropTypes.bool,
  getEventSportInfo: PropTypes.func,
  eventSportInfo: PropTypes.object,
};

const loadingReducer = createLoadingSelector([
  'MANAGER_GET_EVENTS',
  'GET_EVENT_MARKETS',
]);

const mapStateToProps = state => ({
  events: getCMSData(state).eventsManagerReducer.events,
  eventMarkets: getRiskManagementData(state).settlementsReducer.eventMarkets,
  eventSportInfo: getRiskManagementData(state).settlementsReducer
    .eventSportInfo,
  market: getRiskManagementData(state).settlementsReducer.market,
  markets: getRiskManagementData(state).settlementsReducer.markets,
  placedBetsFiltered: getRiskManagementData(state).settlementsReducer
    .placedBetsFiltered,
  event: getRiskManagementData(state).settlementsReducer.event,
  loading: loadingReducer(state),
});

const mapDispatchToProps = {
  getEventSportInfo: SettlementActions.getEventSportInfo,
  settleMarket: SettlementActions.settleMarket,
  getMarket: SettlementActions.getMarket,
  getPlacedBetData: SettlementActions.getPlacedBetData,
  getMarkets: SettlementActions.getMarkets,
  getEvent: SettlementActions.getEvent,
  addNotificationMessage,
  rollbackSettledMarket: SettlementActions.rollbackSettledMarket,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settlements);
