import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import InputField from '../../Form/InputField';
import { useUpdate } from '../../../hooks';

const EventBuilderMarketOutcomes = ({ tableData, updateOutcomes }) => {
  const [outcomes, setOutcomes] = useState([]);

  useUpdate(() => {
    setOutcomes(tableData);
  }, [tableData]);

  const changeOdd = useCallback(
    (e, index) => {
      const outcomesClone = [...tableData];
      const indexOfActiveOdd = outcomesClone[index].outcomeOdds.findIndex(
        x => x.active === true
      );
      outcomesClone[index].outcomeOdds[indexOfActiveOdd].odds = Number(
        e.target.value
      );
      setOutcomes(outcomesClone);
      updateOutcomes(outcomesClone);
    },
    [tableData, updateOutcomes]
  );

  const renderOutcomes = useCallback(
    (outcome, index) => {
      const activeOutcome = outcome.outcomeOdds.find(x => x.active === true);
      return (
        <td key={outcome.id}>
          <InputField
            type="number"
            step={0.01}
            value={activeOutcome.odds}
            onChange={e => changeOdd(e, index)}
          />
        </td>
      );
    },
    [changeOdd]
  );
  return <tr>{outcomes.map(renderOutcomes)}</tr>;
};

EventBuilderMarketOutcomes.propTypes = {
  tableData: PropTypes.array,
};

export default React.memo(EventBuilderMarketOutcomes);
