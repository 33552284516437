import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { columnsModels, updateFilterObj } from '../CashoutManagerUtils';
import { isEmpty } from '../../../../utils/helpers';
import { useUpdate } from '../../../../hooks';

import Table from '../../../../components/Table/Table';
import Button from '../../../../components/Layout/Button';
import Arrow from '../../../../components/Icons/Arrow';
import FullScreenLoader from '../../../../components/Layout/FullScreenLoader/FullScreenLoader';

const CashoutModelsTable = ({ models, update, title, isLoading }) => {
  const [model, setModel] = useState({});
  const [isEditable, setEditable] = useState(false);
  const [openTable, setOpenTable] = useState(false);

  const updateModel = useCallback(() => {
    if (isEditable && !isEmpty(model)) {
      updateFilterObj(models, model, update);
      setEditable(!isEditable);
    }
  }, [models, model, update, isEditable]);

  useUpdate(() => {
    document.getElementById(title).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, [title, openTable]);

  return (
    <>
      <div
        onClick={() => setOpenTable(!openTable)}
        className="my-2 d-flex justify-content-between pointer border-bottom"
        id={title}
      >
        <h2 className="my-4">{title}</h2>
        <div
          style={{
            transform: `rotate(${openTable ? '180deg' : '0deg'})`,
            alignSelf: 'flex-end',
          }}
        >
          <Arrow size="xs" />
        </div>
      </div>
      {openTable && models.length > 0 ? (
        <div>
          <Table
            data={models}
            columns={columnsModels}
            tableStyle="sortable table table-hover table-striped efb-data-table"
            tableType="cashoutModelTable"
            getModel={obj => setModel(obj)}
            isEditable={isEditable}
            pageSize={models.length}
            pagination={false}
            search={false}
          />
          <div className="d-flex justify-content-center">
            <Button
              cssClass="efb-btn__main"
              onClick={updateModel}
              disabled={!isEditable}
            >
              Save
            </Button>
            <Button
              cssClass="efb-btn__main"
              onClick={() => setEditable(!isEditable)}
            >
              {isEditable ? 'Cancel' : 'Edit'}
            </Button>
          </div>
        </div>
      ) : null}

      <FullScreenLoader isActive={isLoading} />
    </>
  );
};

CashoutModelsTable.propTypes = {
  update: PropTypes.func,
  models: PropTypes.array,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default CashoutModelsTable;
