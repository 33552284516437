import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({
  itemsCount,
  pageSize,
  currentPage,
  onPageChange,
  setPageSize,
  onLoadMoreData,
  totalElements,
  sizing = true
}) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  // if (pagesCount === 1) return null;
  // const pages = _.range(1, pagesCount + 1);

  const totalPages = Math.ceil(itemsCount / pageSize);

  let startPage, endPage;
  if (totalPages <= 10) {
    // less than 10 total pages so show all
    startPage = 1;
    endPage = totalPages;
  } else {
    // more than 10 total pages so calculate start and end pages
    if (currentPage <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = currentPage - 5;
      endPage = currentPage + 4;
    }
  }

  // create an array of pages to ng-repeat in the pager control
  let pages = [...Array(endPage + 1 - startPage).keys()].map(
    i => startPage + i
  );

  return (
    <div className="form-row efb-container-table-pagination">
      <div className="form-label-group col-md-2">
        {sizing ? (
          <>
            <select
              className="form-control"
              name="pageSize"
              value={pageSize}
              onChange={setPageSize}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
            <label htmlFor="rows-per-page">Rows per page</label>
          </>
        ) : null}
      </div>
      <div className="col-md-10">
        <nav>
          <ul className="pagination justify-content-end">
            <li
              className={currentPage === 1 ? 'page-item disabled' : 'page-item'}
            >
              <div
                className="page-link"
                onClick={() => onPageChange(1)}
              >
                <i className="fas fa-angle-double-left" />
              </div>
            </li>
            <li
              className={currentPage === 1 ? 'page-item disabled' : 'page-item'}
            >
              <div
                className="page-link"
                onClick={() => onPageChange(currentPage - 1)}
              >
                <i className="fas fa-angle-left" />
              </div>
            </li>
            {pages.map(page => (
              <li
                key={page}
                className={
                  page === currentPage ? 'page-item active' : 'page-item'
                }
              >
                <div
                  className="page-link"
                  onClick={() => onPageChange(page)}
                >
                  {page}
                </div>
              </li>
            ))}
            <li
              className={
                currentPage === pagesCount ? 'page-item disabled' : 'page-item'
              }
            >
              <div
                className="page-link"
                onClick={() => onPageChange(currentPage + 1)}
              >
                <i className="fas fa-angle-right" />
              </div>
            </li>
            <li
              className={
                currentPage === pagesCount ? 'page-item disabled' : 'page-item'
              }
            >
              <div
                className="page-link"
                onClick={() => onPageChange(pagesCount)}
              >
                <i className="fas fa-angle-double-right" />
              </div>
            </li>
            {currentPage === pagesCount && itemsCount < totalElements ? (
              <li
                className="page-item"
                style={{ color: 'red' }}
              >
                <div
                  className="page-link"
                  onClick={() => onLoadMoreData(currentPage)}
                >
                  Load More
                </div>
              </li>
            ) : null}
          </ul>
        </nav>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  setPageSize: PropTypes.func
};

export default Pagination;
