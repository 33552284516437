import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { groupBy } from 'lodash';
import { uniqueSportType, riskValueTransform } from './utils';
import { selectStyles } from '../../../CMS/customReactSelectStyle';
import Button from '../../../../components/Layout/Button';
import Input from '../../../../components/Form/Input';

class SelectedTemplate extends PureComponent {
  state = {
    sport: '',
    category: '',
    tournament: '',
    sports: [],
    categories: [],
    tournaments: []
  };

  componentDidMount() {
    this.prepareSportViews();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.sportTemplate.template !== this.props.sportTemplate.template
    ) {
      this.prepareSportViews();
    }
  }

  prepareSportViews = () => {
    const { template } = this.props.sportTemplate;
    const sports = uniqueSportType(this.props.sportViews, 'sportName');
    const mappedSports = sports.map(sportView => ({
      value: sportView.sportId,
      label: sportView.sportName
    }));

    let mappedCategories = [];
    if (template.sportId) {
      const allCategories = groupBy(this.props.sportViews, 'sportId')[
        template.sportId
      ];
      const categories = uniqueSportType(allCategories, 'categoryName');
      mappedCategories = categories.map(sportView => ({
        value: sportView.categoryId,
        label: sportView.categoryName
      }));
    }

    let mappedTournaments = [];
    if (template.categoryId) {
      const allTournaments = this.props.sportViews.filter(
        event =>
          event.categoryId === template.categoryId &&
          event.sportId === template.sportId
      );

      mappedTournaments = allTournaments.map(sportView => ({
        value: sportView.tournamentId,
        label: sportView.tournamentName
      }));
    }

    let setSelects = {};
    if (this.props.mode === 'edit') {
      setSelects = {
        sport: '',
        category: '',
        tournament: ''
      };
      Object.keys(template)
        .filter(name => name.indexOf('Id') > -1)
        .forEach(key => {
          if (template[key]) {
            const name = key.slice(0, -2) + 'Name';
            const label = this.props.sportViews.find(
              sportView => sportView[key] === template[key]
            )[name];
            setSelects[key.slice(0, -2)] = {
              value: template[key],
              label
            };
          }
        });
    }

    this.setState({
      sports: mappedSports,
      categories: mappedCategories,
      tournaments: mappedTournaments,
      ...setSelects
    });
  };

  handleViewChange = (data, { name }) => {
    let resetTemplate = {};
    if (name === 'sport') {
      resetTemplate = {
        category: '',
        tournament: ''
      };
    } else if (name === 'category') {
      resetTemplate = {
        tournament: ''
      };
    }

    this.setState(
      {
        ...resetTemplate,
        [name]: data
      },
      () => this.props.handleSubViewInputs(data, name)
    );
  };

  render() {
    const {
      sportTemplate,
      closeSelect,
      templateSend,
      handleInputChange,
      handleRiskControlInputs,
      mode
    } = this.props;
    const {
      sport,
      category,
      tournament,
      sports,
      categories,
      tournaments
    } = this.state;

    return (
      <div
        className="container-template container-template-sports"
        id="selected-container"
      >
        <h3>
          <span name="sport-id" className="badge badge-primary mr-1">
            {sport.label}
          </span>
          {category ? (
            <span name="sport-type" className="text-muted">
              {category.label} -{' '}
            </span>
          ) : null}
          {tournament ? (
            <span name="sport-name">{tournament.label}</span>
          ) : null}
        </h3>

        <div className="efb-form efb-form-template-sports">
          <fieldset>
            <legend>General Template Settings</legend>
            <div className="form-row mb-3">
              <div className="col-lg-4">
                <Select
                  name="sport"
                  styles={selectStyles}
                  value={sport}
                  onChange={this.handleViewChange}
                  options={sports}
                  placeholder="Sport Type"
                  isDisabled={mode === 'edit'}
                />
              </div>
              {(sport && mode === 'create') || (mode === 'edit' && category) ? (
                <div className="col-lg-4">
                  <Select
                    name="category"
                    styles={selectStyles}
                    value={category}
                    onChange={this.handleViewChange}
                    options={categories}
                    placeholder="Sport Category"
                    isDisabled={mode === 'edit'}
                  />
                </div>
              ) : null}
              {(category && mode === 'create') ||
              (mode === 'edit' && tournament) ? (
                <div className="col-lg-4">
                  <Select
                    name="tournament"
                    styles={selectStyles}
                    value={tournament}
                    onChange={this.handleViewChange}
                    options={tournaments}
                    placeholder="Tournament"
                    isDisabled={mode === 'edit'}
                  />
                </div>
              ) : null}
            </div>
            <div className="form-row">
              <div className="form-label-group col-lg-6">
                <Input 
                id='name'
                required={true}
                name='name'
                placeholder="Name"
                type='text'
                className='form-control'
                value={sportTemplate.name || ''}
                onChange={handleInputChange}
                disabled={sportTemplate.predefined}
                />
                <label htmlFor="name">Name</label>
              </div>
            </div>

            {/* Save for later use, when the client says what they are for */}
            {/* <hr />

            <div className="form-row">
              <div className="form-check checkbox-slider-md checkbox-slider--b-flat col-lg-5">
                <Switch
                  label="First Past The Post allowed"
                  name="FIRST_PAST_POST_ALLOW"
                  isChecked={
                    sportTemplate.riskControlParameters['FIRST_PAST_POST_ALLOW']
                  }
                  isDisabled={false}
                  onChange={handleRiskControlInputs}
                />
              </div>
              <div className="form-check checkbox-slider-md checkbox-slider--b-flat col-lg-5">
                <Switch
                  label="Allowed If Absent"
                  name="ALLOW_IF_ABSENT"
                  isChecked={
                    sportTemplate.riskControlParameters['ALLOW_IF_ABSENT']
                  }
                  isDisabled={false}
                  onChange={handleRiskControlInputs}
                />
              </div>
            </div> */}
          </fieldset>

          <fieldset>
            <legend>Risk Control Parameters</legend>
            <div className="form-row">
              <div className="form-label-group col-md">
                <Input
                  id="MAX_TAKEOUT"
                  name="MAX_TAKEOUT"
                  placeholder="Max Takeout - Rulebook Limit"
                  type="number"
                  className="form-control"
                  onChange={handleRiskControlInputs}
                  value={riskValueTransform(
                    sportTemplate.riskControlParameters,
                    'MAX_TAKEOUT'
                  )}
                />
                <label htmlFor="MAX_TAKEOUT">
                  Max Takeout – Rulebook Limit
                </label>
              </div>
            </div>

            {/* Save for later use, when the client says what they are for */}
            {/* <div className="form-row">
							<div className="form-label-group col">
								<div className="input-group input-group-double">
									<div className="input-group-double-left">
										<input
											type="text"
											id="MMT_CEILING_REF_PRICE"
											name="MMT_CEILING_REF_PRICE"
											className="form-control"
											placeholder="Start"
											onChange={handleRiskControlInputs}
											value={riskValueTransform(
												sportTemplate.riskControlParameters,
												'MMT_CEILING_REF_PRICE'
											)}
										/>
										<label htmlFor="MMT_CEILING_REF_PRICE">MMT Ceiling Reference Price</label>
									</div>
									<div className="input-group-append">
										<span className="input-group-text">&mdash;&mdash;&mdash;</span>
									</div>
									<div className="input-group-double-right">
										<input
											name="MMT_CEILING_REF_TAKEOUT"
											type="text"
											id="MMT_CEILING_REF_TAKEOUT"
											className="form-control"
											placeholder="End"
											onChange={handleRiskControlInputs}
											value={riskValueTransform(
												sportTemplate.riskControlParameters,
												'MMT_CEILING_REF_TAKEOUT'
											)}
										/>
										<label htmlFor="MMT_CEILING_REF_TAKEOUT">MMT Ceiling Reference Takeout</label>
									</div>
								</div>
							</div>
						</div>

						<div className="form-row">
							<div className="form-label-group col">
								<div className="input-group input-group-double">
									<div className="input-group-double-left">
										<input
											type="text"
											id="rcp-floor-price"
											name="MMT_FLOOR_REF_PRICE"
											className="form-control"
											placeholder="Start"
											onChange={handleRiskControlInputs}
											value={riskValueTransform(
												sportTemplate.riskControlParameters,
												'MMT_FLOOR_REF_PRICE'
											)}
										/>
										<label htmlFor="MMT_FLOOR_REF_PRICE">MMT Floor Reference Price</label>
									</div>
									<div className="input-group-append">
										<span className="input-group-text">&mdash;&mdash;&mdash;</span>
									</div>
									<div className="input-group-double-right">
										<input
											type="text"
											id="MMT_FLOOR_REF_TAKEOUT"
											name="MMT_FLOOR_REF_TAKEOUT"
											className="form-control"
											placeholder="End"
											onChange={handleRiskControlInputs}
											value={riskValueTransform(
												sportTemplate.riskControlParameters,
												'MMT_FLOOR_REF_TAKEOUT'
											)}
										/>
										<label htmlFor="MMT_FLOOR_REF_TAKEOUT">MMT Floor Reference Takeout</label>
									</div>
								</div>
							</div>
						</div> */}
          </fieldset>
          <div className="form-group d-flex flex-row justify-content-end">
            <Button cssClass="efb-btn__light" onClick={closeSelect}>
              Cancel
            </Button>
            {mode === 'create' ? (
              <Button
                onClick={templateSend}
                name="save"
                cssClass="efb-btn__main"
              >
                Save Template
              </Button>
            ) : (
              <Button
                onClick={templateSend}
                name="edit"
                cssClass="efb-btn__main"
              >
                Edit Template
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SelectedTemplate.propTypes = {
  sportTemplate: PropTypes.object,
  closeSelect: PropTypes.func,
  templateSend: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleRiskControlInputs: PropTypes.func,
  mode: PropTypes.string,
  sportViews: PropTypes.array,
  handleSubViewInputs: PropTypes.func
};

export default SelectedTemplate;
