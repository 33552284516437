import React from 'react';

export const Arrow = (props) => {
    return (
        <span className='efb-icon'>
            <svg className={`efb-icon--${props.size} efb-arrow`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
            <path d="M16.2,27.5L0.7,12c-1-1-1-2.6,0-3.6c1-1,2.6-1,3.6,0L18,22.2L31.7,8.5c1-1,2.6-1,3.6,0c1,1,1,2.6,0,3.6L19.8,27.5
		        c-0.5,0.5-1.1,0.7-1.8,0.7C17.4,28.3,16.7,28,16.2,27.5z"/>
            </svg>
            {props.children}
        </span>
    )
}

export default Arrow;