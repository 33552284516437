export const transactionsColumns = [
  {
    name: 'Date',
    value: 'createdAt',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Customer',
    value: 'customerUsername',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Currency',
    value: 'currency',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Amount',
    value: 'amount',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Type',
    value: 'type',
    type: 'string',
    sortable: true,
  },
  { name: 'Transaction State', value: 'status', type: 'string' },
  { name: 'Payment Method', value: 'paymentMethod', type: 'string' },
  { name: 'Show', value: '', type: 'string' },
];

export const pendingTransactionsColumns = [
  {
    name: 'Date and time',
    value: 'createdAt',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Customer Id',
    value: 'customerId',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Customer username',
    value: 'customerUsername',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Customer Name',
    value: 'customerFirstName',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Currency',
    value: 'currencyCode',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Amount',
    value: 'amount',
    type: 'number',
    sortable: true,
  },
  {
    name: 'Payment Provider',
    value: 'paymentMethod',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Channel',
    value: 'channel',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Ip Address',
    value: 'transactionIpAddress',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Review',
    type: 'string',
  },
  {
    name: 'Customer Deposit/Withdraw',
    type: 'string',
  },
  {
    name: 'Account History',
    type: 'string',
  },
];

export const transactionsColumnsDetails = [
  {
    name: 'ID',
    value: 'id',
    type: 'string',
  },
  {
    name: 'Customer',
    value: 'customerUsername',
    type: 'string',
  },
  {
    name: 'Amount',
    value: 'amount',
    type: 'string',
  },
  {
    name: 'Date',
    value: 'date',
    type: 'string',
  },
  {
    name: 'State',
    value: 'status',
    type: 'string',
  },
  {
    name: 'Ip Address',
    value: 'transactionIpAddress',
    type: 'string',
  },
  {
    name: 'External Ref',
    value: 'createdAt',
    type: 'string',
  },
  {
    name: 'External account id',
    value: 'createdAt',
    type: 'string',
  },
];

export const currenciesColumns = [
  {
    name: 'Name',
    value: 'name',
    type: 'string',
  },
  {
    name: 'Last update',
    value: 'lastUpdatedOn',
    type: 'string',
  },
  {
    name: 'Code',
    value: 'code',
    type: 'string',
  },
  {
    name: 'Rate',
    value: 'rate',
    type: 'number',
    sortable: true,
  },
  {
    name: 'Action',
    type: 'buttons',
  },
];

export const preferencesColumns = [
  {
    name: 'Currency Code',
    display: entity => entity.currencyCode,
  },
  {
    name: 'Deposit Min',
    display: entity => Number(entity.depositMin / 100),
  },
  {
    name: 'Deposit Max',
    display: entity => Number(entity.depositMax / 100),
  },
  {
    name: 'Withdraw Min',
    display: entity => Number(entity.withdrawMin / 100),
  },
  {
    name: 'Withdraw Max',
    display: entity => Number(entity.withdrawMax / 100),
  },
];

export const feesColumns = [
  {
    name: 'Currency Code',
    display: entity => entity.currencyCode,
  },
  {
    name: 'Deposit Amount',
    display: entity => entity.depositAmount,
  },
  {
    name: 'Deposit Threshold',
    display: entity => entity.depositThreshold,
  },
  {
    name: 'Deposit Threshold Amount',
    display: entity => entity.depositThresholdAmount,
  },
  {
    name: 'Deposit Type',
    display: entity => entity.depositType,
  },
  {
    name: 'Withdraw Amount',
    display: entity => entity.withdrawAmount,
  },
  {
    name: 'Withdraw Threshold',
    display: entity => entity.withdrawThreshold,
  },
  {
    name: 'Withdraw Threshold Amount',
    display: entity => entity.withdrawThresholdAmount,
  },
  {
    name: 'Withdraw Type',
    display: entity => entity.withdrawType,
  },
];

export const exceptionColumns = [
  { name: 'No.' },
  { name: 'Date / Time', value: 'createDate', sortable: true },
  { name: 'Username', value: 'username', sortable: true },
  { name: 'Currency', value: 'currency', sortable: true },
  { name: 'Amount', value: 'amount', sortable: true },
  { name: 'Type', value: 'transactionType', sortable: true },
  { name: 'Payment method', value: 'paymentMethod', sortable: true },
  { name: 'Status', value: 'status', sortable: true },
  { name: 'Action', type: 'button' },
];

export const channelsOptions = [
  {
    value: 'WEB',
    label: 'WEB',
  },
  {
    value: 'MOBILE',
    label: 'MOBILE',
  },
];

export const paymentManagerInputs = [
  { label: 'Deposit Min', value: 'depositMin' },
  { label: 'Deposit Max', value: 'depositMax' },
  { label: 'Withdraw Min', value: 'withdrawMin' },
  { label: 'Withdraw Max', value: 'withdrawMax' },
];

export const selectOptions = options =>
  options.map(el => ({
    value: el,
    label: el,
  }));

export const selectFieldOptions = (options, keyId, keyName) =>
  options.map(el => ({
    id: keyId ? el[keyId] : el,
    name: keyName ? el[keyName] : el,
  }));
