import React, { Component } from 'react';
import Transactions from './Transactions/Transactions';
import PendingTransactions from './PendingTransactions/PendingTransactions';
import CurrencyRates from './CurrencyRates/CurrencyRates';
import PaymentManager from './PaymentManager/PaymentManager';
import Exceptions from './Exceptions/Exceptions';
import AutomaticWithdraw from './AutomaticWithdraw/AutomaticWithdraw';
import * as transactionsActions from '../../../redux/actions/TransactionBuilder';
import * as customerData from '../../../redux/actions/customer/customerData';
import { getTransactionData } from '../../../selectors';
import { addNotificationMessage } from '../../../redux/actions/ui';
import { connect } from 'react-redux';

class TransactionBuilder extends Component {
  componentDidMount() {
    this.props.getCurrencies();
    this.props.getStates();
    this.props.getTypes();
    this.props.getPayments();
    this.props.getAllPendingTransactions();
  }

  render() {
    const {
      currencies,
      payments,
      types,
      selectedTab,
      states,
      transactions,
      pendingTransactions,
      downloadedCurrency,
      approveOrReject,
      emptyPendingTransaction,
      emptyTransaction,
      emptyCurrencies,
      getTransactions,
      changeCurrencyRate,
      getDownloadedCurrency,
      getPendingTransaction,
      getAccountHistory,
      addNotificationMessage,
      filteredTransactions,
      resolvePayment,
      getAWStatus,
      awStatus,
      changeAwGlobalStatus,
      getAllCurrencies,
      usedCurrencies,
      getAwStatusByCurrency,
      saveAwCurrencyLimit,
      currencyLimit,
      getPaymentProviderForBSUnits,
      paymentProvidersPerBSUnit,
      updatePaymentProvider,
      getPreferences,
      enableWithdraw,
      enableDeposit,
    } = this.props;
    const paymentMethods =
      payments && payments.map(payment => payment.paymentMethod);

    let content;
    switch (selectedTab) {
      case 'transactions':
        content = (
          <Transactions
            currencies={currencies}
            paymentMethods={paymentMethods}
            transactions={transactions}
            types={types}
            states={states}
            tab={selectedTab}
            getTransactions={getTransactions}
            emptyTransaction={emptyTransaction}
          />
        );
        break;

      case 'pendingTransactions':
        if (payments) {
          content = (
            <PendingTransactions
              tab={selectedTab}
              paymentMethods={paymentMethods}
              transactions={pendingTransactions}
              getPendingTransaction={getPendingTransaction}
              emptyPendingTransaction={emptyPendingTransaction}
              approveOrReject={approveOrReject}
              getAccountHistory={getAccountHistory}
              addNotificationMessage={addNotificationMessage}
            />
          );
        }
        break;

      case 'currencyRates':
        content = (
          <CurrencyRates
            tab={selectedTab}
            changeCurrencyRate={changeCurrencyRate}
            emptyCurrencies={emptyCurrencies}
            currenciesRates={downloadedCurrency}
            getDownloadedCurrency={getDownloadedCurrency}
            addNotificationMessage={addNotificationMessage}
          />
        );
        break;

      case 'paymentManager':
        content = (
          <PaymentManager
            paymentProvidersPerBSUnit={paymentProvidersPerBSUnit}
            getPaymentProviderForBSUnits={getPaymentProviderForBSUnits}
            getPreferences={getPreferences}
            currencies={currencies}
            updatePaymentProvider={updatePaymentProvider}
            enableWithdraw={enableWithdraw}
            enableDeposit={enableDeposit}
          />
        );
        break;

      case 'exceptions':
        content = (
          <Exceptions
            filteredTransactions={filteredTransactions}
            resolvePayment={resolvePayment}
            addNotificationMessage={addNotificationMessage}
          />
        );
        break;

      case 'automaticWithdraw':
        content = (
          <AutomaticWithdraw
            getAWStatus={getAWStatus}
            awStatus={awStatus}
            changeAwGlobalStatus={changeAwGlobalStatus}
            getAllCurrencies={getAllCurrencies}
            usedCurrencies={usedCurrencies}
            getAwStatusByCurrency={getAwStatusByCurrency}
            currencyLimit={currencyLimit}
            saveAwCurrencyLimit={saveAwCurrencyLimit}
          />
        );
        break;

      default:
        content = '';
    }
    return content;
  }
}

const mapStateToProps = state => ({
  currencies: getTransactionData(state).currencies,
  states: getTransactionData(state).states,
  types: getTransactionData(state).types,
  payments: getTransactionData(state).payments,
  pendingTransactions: getTransactionData(state).pendingTransactions,
  transactions: getTransactionData(state).transactions,
  downloadedCurrency: getTransactionData(state).downloadedCurrency,
  paymentsProviders: getTransactionData(state).paymentsProviders,
  filteredTransactions: getTransactionData(state).filteredTransactions,
  awStatus: getTransactionData(state).awStatus,
  usedCurrencies: getTransactionData(state).usedCurrencies,
  currencyLimit: getTransactionData(state).currencyLimit,
  paymentProvidersPerBSUnit: getTransactionData(state)
    .paymentProvidersPerBSUnit,
});

const mapDispatchToProps = {
  getCurrencies: transactionsActions.getCurrencies,
  getStates: transactionsActions.getStates,
  getTypes: transactionsActions.getTypes,
  getPayments: transactionsActions.getPayments,
  getPendingTransaction: transactionsActions.getPendingTransaction,
  getTransactions: transactionsActions.getTransactions,
  emptyPendingTransaction: transactionsActions.emptyPendingTransaction,
  emptyTransaction: transactionsActions.emptyTransaction,
  emptyCurrencies: transactionsActions.emptyCurrencies,
  changeCurrencyRate: transactionsActions.changeCurrencyRate,
  getDownloadedCurrency: transactionsActions.getDownloadedCurrency,
  approveOrReject: transactionsActions.approveOrReject,
  getPaymentProviders: transactionsActions.getPaymentProviders,
  getAccountHistory: customerData.getAccountHistory,
  resolvePayment: transactionsActions.resolvePayment,
  getAWStatus: transactionsActions.getAWStatus,
  changeAwGlobalStatus: transactionsActions.changeAwGlobalStatus,
  getAllCurrencies: transactionsActions.getAllCurrencies,
  getAwStatusByCurrency: transactionsActions.getAwStatusByCurrency,
  saveAwCurrencyLimit: transactionsActions.saveAwCurrencyLimit,
  getPaymentProviderForBSUnits:
    transactionsActions.getPaymentProviderForBSUnits,
  updatePaymentProvider: transactionsActions.updatePaymentProvider,
  getPreferences: transactionsActions.getPreferences,
  getAllPendingTransactions: transactionsActions.getAllPendingTransactions,
  enableWithdraw: transactionsActions.enableWithdraw,
  enableDeposit: transactionsActions.enableDeposit,
  addNotificationMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionBuilder);
