import React, { Fragment, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Layout/Button';
import Select from 'react-select';
import { selectStyles } from '../../CMS/customReactSelectStyle';
import InputField from '../../../components/Form/InputField';
import Switch from '../../../components/Form/Switch/Switch';
import {
  useStateForInput,
  useStateForSwitch,
  useStateForSelect,
} from '../../../hooks';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const CreateCampaign = ({
  createCampaign: cc,
  businessUnits: businessU,
  addNotificationMessage,
}) => {
  const [active, setActive] = useStateForSwitch(true);
  const [name, setName] = useStateForInput('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [businessUnit, setBusinessUnit] = useStateForSelect('');
  const [businessUnitEnabled, setBusinessUnitEnabled] = useStateForSwitch(
    false
  );

  const optionsBU = useMemo(() => {
    return businessU.map(element => {
      return {
        value: element.id,
        label: element.name,
      };
    });
  }, [businessU]);

  const checkValidCampaign = useCallback(
    campaignForCreate => {
      if (campaignForCreate.name === '') {
        addNotificationMessage('Insert name', 'error', 'Error');
        return false;
      }
      if (campaignForCreate.businessUnit === '') {
        addNotificationMessage('Please select country', 'error', 'Error');
        return false;
      }
      if (
        campaignForCreate.startDate === '' ||
        campaignForCreate.endDate === ''
      ) {
        addNotificationMessage('Select date', 'error', 'Error');
        return false;
      }
      if (
        moment(campaignForCreate.startDate) > moment(campaignForCreate.endDate)
      ) {
        addNotificationMessage(
          'End date must be after start date',
          'error',
          'Error'
        );
        return false;
      }
      return true;
    },
    [addNotificationMessage]
  );

  const createCampaign = () => {
    const campaignForCreate = {
      active,
      businessUnit: businessUnit.value,
      businessUnitEnabled,
      endDate,
      name,
      startDate,
      type: 'INDIVIDUAL',
    };

    checkValidCampaign(campaignForCreate) && cc(campaignForCreate);
  };

  return (
    <Fragment>
      <h2>Create Campaign</h2>
      <br />
      <div className="efb-form">
        <div className="form-row">
          <div className="form-label-group col">
            <InputField
              type="text"
              name="name"
              className="form-control"
              label="Name of campaign"
              placeholder="Name of campaign"
              onChange={setName}
            />
          </div>
          <div className="col">
            <DatePicker
              placeholderText="Start date:  DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={e => setStartDate(e)}
              popperPlacement="top-start"
              isClearable
            />
          </div>
          <div className="col">
            <DatePicker
              placeholderText="End date:  DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={e => setEndDate(e)}
              minDate={moment(startDate).toDate()}
              popperPlacement="top-start"
              isClearable
            />
          </div>
          <div className="form-label-group col">
            <Switch
              label="Inactive | Active"
              defIsChecked={active}
              name="IsActive"
              onChange={setActive}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-label-group col">
            <Select
              onChange={setBusinessUnit}
              options={optionsBU}
              styles={selectStyles}
              placeholder={'Select Business unit'}
              defaultValue={businessUnit}
              name="businessUnit"
            />
          </div>
          <Switch
            isChecked={businessUnitEnabled}
            name="businessUnitEnabled"
            label="Enabled business unit?"
            onChange={setBusinessUnitEnabled}
          />
        </div>
      </div>
      <hr />
      <div className="form-row">
        <Button onClick={createCampaign} cssClass="efb-btn__main col-md">
          Create Campaign
        </Button>
      </div>
      <hr />
    </Fragment>
  );
};

CreateCampaign.propTypes = {
  clearDate: PropTypes.func,
  currencies: PropTypes.array,
  getFilteredTransaction: PropTypes.func,
  payments: PropTypes.array,
  states: PropTypes.array,
  types: PropTypes.array,
};

export default CreateCampaign;
