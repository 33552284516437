import React, { useState, useCallback } from 'react';
import Input from '../../../../../../components/Form/Input';
import Button from '../../../../../../components/Layout/Button';
import CreatableSelect from 'react-select/creatable';
import { selectStyles } from '../../../../customReactSelectStyle';
import PropTypes from 'prop-types';
import { tableSubpage } from '../../../StaticContentObj';
import { useUpdate } from '../../../../../../hooks';

const TableContentSubpaths = ({
  pageObj,
  subpage,
  currentLang,
  subpageIndex,
  updateObj,
  countries,
  currencies,
}) => {
  const [subpageObj, setSubpageObj] = useState('');
  const [tableSubpages, setTableSubpages] = useState([
    {
      table: {
        tableSubpage: tableSubpage,
        tableColumns: [[{ key: 0, value: '' }]],
        tableKeysAndLabels: [{ key: 0, labelValue: '', keyValue: '' }],
      },
      key: 0,
    },
  ]);
  const [staticPageObj, setStaticPageObj] = useState({});

  useUpdate(() => {
    setStaticPageObj(pageObj);
  }, [pageObj]);

  useUpdate(() => {
    setSubpageObj(subpage);
  }, [subpage]);

  const handleInputChange = useCallback(
    (e, rowIndex, columnIndex, subpathIndex) => {
      const { name, value } = e.target;
      const tableSubpagesClone = [...tableSubpages];
      const subpageClone = { ...subpageObj };
      const table =
        subpageClone.content[0][currentLang.value].subpages[subpathIndex]
          .tables[0].table;
      const tableColumns = tableSubpagesClone[subpathIndex].table.tableColumns;
      const tableKeysAndLabels =
        tableSubpagesClone[subpathIndex].table.tableKeysAndLabels;
      switch (name) {
        case 'key':
          table[0][rowIndex] = value;
          tableKeysAndLabels[rowIndex].keyValue = value;
          break;

        case 'label':
          table[1][rowIndex] = value;
          tableKeysAndLabels[rowIndex].labelValue = value;
          break;

        case 'row':
          tableColumns[columnIndex][rowIndex].value = value;
          table[columnIndex + 2][rowIndex] = value;
          break;

        default:
          return null;
      }
      console.log(tableSubpagesClone);
      setTableSubpages(tableSubpagesClone);
      setSubpageObj(subpageClone);
    },
    [currentLang.value, subpageObj, tableSubpages]
  );

  const addTableColumn = useCallback(
    subpathIndex => {
      const subpageClone = { ...subpageObj };
      const tableSubpagesClone = [...tableSubpages];
      const tableColumns = tableSubpages[subpathIndex].table.tableColumns;

      let counter = tableColumns.length * tableColumns[0].length;

      tableColumns.push([]);

      if (
        subpage.content[0][currentLang.value].subpages[subpathIndex].tables[0]
          .table.length > 0
      ) {
        tableColumns[0].map(row => {
          return tableColumns[tableColumns.length - 1].push({
            key: counter++,
            value: '',
          });
        });
      }
      subpage.content[0][currentLang.value].subpages[
        subpathIndex
      ].tables[0].table.push(['']);

      setTableSubpages(tableSubpagesClone);
      setSubpageObj(subpageClone);
    },
    [currentLang.value, subpage.content, subpageObj, tableSubpages]
  );

  const removeTableKeyAndLabel = useCallback(
    (index, subpathIndex) => {
      const subpageClone = { ...subpageObj };
      const tableSubpagesClone = [...tableSubpages];
      const tableColumns = tableSubpagesClone[subpathIndex].table.tableColumns;
      const tableKeysAndLabels =
        tableSubpagesClone[subpathIndex].table.tableKeysAndLabels;
      subpageClone.content[0][currentLang.value].subpages[
        subpathIndex
      ].tables[0].table.map(row => {
        return row.splice(index, 1);
      });

      tableColumns.map(column => {
        return column.splice(index, 1);
      });

      tableKeysAndLabels.splice(index, 1);

      setSubpageObj(subpageClone);
      setTableSubpages(tableSubpagesClone);
    },
    [currentLang.value, subpageObj, tableSubpages]
  );

  const setTableCurrency = useCallback(
    (currency, subpathIndex) => {
      const subpageClone = { ...subpageObj };
      subpageClone.content[0][currentLang.value].subpages[
        subpathIndex
      ].tables[0].name = currency.value;
      setSubpageObj(subpageClone);
    },
    [currentLang.value, subpageObj]
  );

  const setTableCountry = useCallback(
    (country, subpathIndex) => {
      const subpageClone = { ...subpageObj };
      subpageClone.content[0][currentLang.value].subpages[subpathIndex].name =
        country.label === 'България' || country.label === 'Bulgaria'
          ? 'bg'
          : country.value;
      setSubpageObj(subpageClone);
    },
    [currentLang.value, subpageObj]
  );

  const addTableRow = subpathIndex => {
    const subpageClone = { ...subpageObj };
    const tableSubpagesClone = [...tableSubpages];
    const tableColumns = tableSubpages[subpathIndex].table.tableColumns;
    const tableKeysAndLabels =
      tableSubpages[subpathIndex].table.tableKeysAndLabels;

    let counter = tableColumns[0].length * tableKeysAndLabels.length;

    tableKeysAndLabels.push({ key: counter++, labelValue: '', keyValue: '' });
    tableColumns.map(column => {
      return column.push({ key: counter++, value: '' });
    });

    subpageClone.content[0][currentLang.value].subpages[
      subpathIndex
    ].tables[0].table[0].push('');
    subpageClone.content[0][currentLang.value].subpages[
      subpathIndex
    ].tables[0].table[1].push('');

    setSubpageObj(subpageClone);
    setTableSubpages(tableSubpagesClone);
  };

  const deleteTableRow = useCallback(
    (columnIndex, subpathIndex) => {
      const tableSubpagesClone = [...tableSubpages];
      const tableColumns = tableSubpages[subpathIndex].table.tableColumns;
      const subpageClone = { ...subpageObj };
      subpageClone.content[0][currentLang.value].subpages[
        subpathIndex
      ].tables[0].table.splice(columnIndex + 2, 1);
      tableColumns.splice(columnIndex, 1);
      setTableSubpages(tableSubpagesClone);
      setSubpageObj(subpageClone);
    },
    [currentLang.value, subpageObj, tableSubpages]
  );

  const addSubpageTable = useCallback(() => {
    const pageObjClone = { ...staticPageObj };
    pageObjClone.subpages[subpageIndex].content[0][
      currentLang.value
    ].subpages.push(tableSubpage);
    updateObj(pageObjClone);
    const tableSubpagesClone = [...tableSubpages];
    let counter = tableSubpagesClone.length;
    tableSubpagesClone.push({
      table: {
        tableSubpage: tableSubpage,
        tableColumns: [[{ key: 0, value: '' }]],
        tableKeysAndLabels: [{ key: 0, labelValue: '', keyValue: '' }],
      },
      key: counter++,
    });
    setTableSubpages(tableSubpagesClone);
    setStaticPageObj(pageObjClone);
  }, [
    currentLang.value,
    staticPageObj,
    subpageIndex,
    tableSubpages,
    updateObj,
  ]);

  const removeSubpageTable = useCallback(
    (subpageIndex, subpathIndex) => {
      const pageObjClone = { ...staticPageObj };
      pageObjClone.subpages[subpageIndex].content[0][
        currentLang.value
      ].subpages.splice(subpathIndex, 1);
      updateObj(pageObjClone);
      const tableSubpagesClone = [...tableSubpages];
      tableSubpagesClone.splice(subpathIndex, 1);
      setTableSubpages(tableSubpagesClone);
      setStaticPageObj(pageObjClone);
    },
    [currentLang.value, staticPageObj, tableSubpages, updateObj]
  );

  return (
    <>
      {tableSubpages.map((subpath, subpathIndex) => {
        return (
          <div key={subpath.key}>
            <h4>Table {subpathIndex + 1}. </h4>
            <br />
            <div className="form-row">
              <div className="col-md">
                <CreatableSelect
                  isClearable
                  options={countries}
                  placeholder={'Select country'}
                  onChange={e => setTableCountry(e, subpathIndex)}
                  styles={selectStyles}
                />
              </div>
              <div className="col-md">
                <CreatableSelect
                  options={currencies}
                  placeholder={'Select currency'}
                  onChange={e => setTableCurrency(e, subpathIndex)}
                  styles={selectStyles}
                />
              </div>
            </div>
            <div className="efb-form">
              <div className="form-row">
                <div className="table-responsive">
                  <table className="sortable table table-hover table-striped efb-table static-page-table">
                    <thead>
                      <tr>
                        <th>No.</th>
                        {tableSubpages[
                          subpathIndex
                        ].table.tableKeysAndLabels.map((label, rowIndex) => {
                          return (
                            <th key={label.key}>
                              <Button
                                disabled={
                                  tableSubpages[subpathIndex].table
                                    .tableKeysAndLabels.length === 1
                                }
                                cssClass="btn btn-sm btn-outline-danger btn-DEMO-del-val column-delete-btn"
                                onClick={() =>
                                  removeTableKeyAndLabel(rowIndex, subpathIndex)
                                }
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                              <Input
                                label={'label'}
                                name={'label'}
                                placeholder="label"
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    rowIndex,
                                    0,
                                    subpathIndex
                                  )
                                }
                              />
                            </th>
                          );
                        })}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Table key</td>
                        {tableSubpages[
                          subpathIndex
                        ].table.tableKeysAndLabels.map((key, rowIndex) => {
                          return (
                            <td key={key.key}>
                              <Input
                                key={'key'}
                                name={'key'}
                                placeholder="key"
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    rowIndex,
                                    1,
                                    subpathIndex
                                  )
                                }
                              />
                            </td>
                          );
                        })}
                        <td>/</td>
                      </tr>
                      {tableSubpages[subpathIndex].table.tableColumns.map(
                        (column, columnIndex) => {
                          return (
                            <tr key={column[0].key}>
                              <td>{columnIndex + 1}.</td>
                              {column.map((tableColumn, rowIndex) => {
                                return (
                                  <td key={tableColumn.key}>
                                    <Input
                                      label={'row'}
                                      name={'row'}
                                      placeholder="row"
                                      onChange={e =>
                                        handleInputChange(
                                          e,
                                          rowIndex,
                                          columnIndex,
                                          subpathIndex
                                        )
                                      }
                                    />
                                  </td>
                                );
                              })}
                              <td>
                                <Button
                                  disabled={
                                    tableSubpages[subpathIndex].table
                                      .tableColumns.length === 1
                                  }
                                  cssClass="efb-btn__main"
                                  onClick={() =>
                                    deleteTableRow(columnIndex, subpathIndex)
                                  }
                                >
                                  <i className="fa fa-trash"></i> Delete data
                                  row
                                </Button>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <br />

              <div className="form-row float-right">
                <Button
                  cssClass="efb-btn__main float-right"
                  disabled={
                    subpage.content[0][currentLang.value].subpages.length === 1
                  }
                  onClick={() => removeSubpageTable(subpageIndex, subpathIndex)}
                >
                  <i className="fa fa-trash"></i> Remove table
                </Button>
              </div>

              <div className="form-row">
                <Button
                  cssClass={'efb-btn__main'}
                  onClick={() => addTableColumn(subpathIndex)}
                >
                  Add data row
                </Button>
                <Button
                  disabled={
                    tableSubpages[subpathIndex].table.tableColumns.length === 0
                  }
                  cssClass={'efb-btn__main'}
                  onClick={() => addTableRow(subpathIndex)}
                >
                  Add table column cell
                </Button>
              </div>
              <br />
              <hr />
            </div>
          </div>
        );
      })}
      <Button
        cssClass="efb-btn__main float-right"
        onClick={() => addSubpageTable(subpageIndex)}
      >
        <i className="fa fa-plus"></i> Add table
      </Button>
      <br /> <br /> <hr />
    </>
  );
};

TableContentSubpaths.propTypes = {
  addSubpageTable: PropTypes.func,
  countries: PropTypes.array,
  currencies: PropTypes.array,
  currentLang: PropTypes.object,
  removeSubpageTable: PropTypes.func,
  subpage: PropTypes.object,
  subpageIndex: PropTypes.number,
  tableColumns: PropTypes.array,
};

export default TableContentSubpaths;
