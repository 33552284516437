import React from 'react';
import Button from '../Layout/Button';
import classes from './Buttons.module.scss';

const Buttons = ({ apply, ok, cancel }) => {
  return (
    <div className={classes.buttons}>
      <Button cssClass={classes.applyButton} onClick={apply}>
        Apply
      </Button>
      <Button cssClass={classes.okButton} onClick={ok}>
        OK
      </Button>
      <Button cssClass={classes.cancelButton} onClick={cancel}>
        Cancel
      </Button>
    </div>
  );
};

export default Buttons;
