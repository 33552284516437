import React, { useState, useCallback } from 'react';
import moment from 'moment';

import classes from './DatesFilter.module.scss';
import CalendarDayPicker from '../../../CalendarDayPicker/CalendarDayPicker';
import { IconCalendar } from '../../../Icons/UI/ui/IconCalendar';

const DatesFilter = ({ label, fields, onFilterChange: pushFilterChange }) => {
  const [calendars, setCalendars] = useState(
    fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.name]: {
          open: false,
          value: new Date(),
        },
      }),
      {}
    )
  );

  const handleDayClick = useCallback(
    (date, field) => {
      setCalendars({
        ...calendars,
        [field.name]: {
          open: true,
          value: date,
        },
      });
      pushFilterChange({
        name: field.name,
        value: date,
      });
    },
    [calendars, pushFilterChange]
  );

  return (
    <div>
      <label>{label}</label>

      <div className={`mt-3 ${classes.bets}`}>
        {fields.map(field => (
          <div key={field.name}>
            <label>{field.label}:</label>
            <div className="position-relative">
              <label
                className={classes.calendarInput}
                onClick={() => {
                  setCalendars(
                    fields.reduce(
                      (acc, reduceField) => ({
                        ...acc,
                        [reduceField.name]: {
                          open:
                            reduceField.name === field.name
                              ? !calendars[reduceField.name].open
                              : false,
                          value: calendars[reduceField.name].value,
                        },
                      }),
                      {}
                    )
                  );
                }}
              >
                {calendars[field.name].value
                  ? moment(calendars[field.name].value).calendar(null, {
                      lastDay: '[Yesterday]',
                      sameDay: '[Today]',
                      nextDay: '[Tomorrow]',
                      lastWeek: 'L',
                      nextWeek: 'L',
                      sameElse: 'L',
                    })
                  : null}{' '}
                <IconCalendar height={16} width={16} />
              </label>
              {calendars[field.name].open && (
                <CalendarDayPicker
                  close={() =>
                    setCalendars({
                      ...calendars,
                      [field.name]: {
                        ...calendars[field.name],
                        open: false,
                      },
                    })
                  }
                  handleDayClick={date => handleDayClick(date, field)}
                  from={calendars[field.name].value}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DatesFilter;
