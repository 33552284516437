import React, { Fragment, useCallback, useState } from 'react';
import BetScrollerFilters from './BetScrollerFilters';
import BetScrollerTabs from './BetScrollerTabs';
import BetScrollerTable from './BetScrollerTable';
import { useUpdate } from '../../../hooks';

const BetScrollerManager = ({
  betScrollers,
  ownId,
  betScrollerData,
  addNotificationMessage,
}) => {
  const [betScrollersCopy, setBetScrollersCopy] = useState(betScrollers);
  const [currentBetScroller, setCurrentBetScroller] = useState({});
  const [betScrollerForTable, setBetScrollerForTable] = useState({});
  const [position, setPosition] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);

  useUpdate(() => setBetScrollersCopy(betScrollers), [betScrollers]);

  useUpdate(() => {
    setCurrentBetScroller(currentBetScroller);
  }, [currentBetScroller]);

  useUpdate(() => {
    setBetScrollerForTable(currentBetScroller);
  }, [position, betScrollersCopy]);

  const openSelectedBSScroller = useCallback((index, bs) => {
    setPosition(index);
    setOpenFilter(true);
    setCurrentBetScroller(bs);
  }, []);

  return (
    <Fragment>
      <BetScrollerTabs
        betScrollersCopy={betScrollersCopy}
        setBetScrollersCopy={setBetScrollersCopy}
        openSelectedBSScroller={openSelectedBSScroller}
        setCurrentBetScroller={setCurrentBetScroller}
        setBetScrollerForTable={setBetScrollerForTable}
        position={position}
        setPosition={setPosition}
        ownId={ownId}
        addNotificationMessage={addNotificationMessage}
      />
      {Object.keys(betScrollerForTable).length > 0 &&
      betScrollerData.length > 0 ? (
        <BetScrollerTable
          betScrollerData={betScrollerData}
          currentBetScroller={betScrollerForTable}
        />
      ) : null}
      {openFilter ? (
        <BetScrollerFilters
          ownId={ownId}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          betScrollersCopy={betScrollersCopy}
          currentBetScroller={currentBetScroller}
          setCurrentBetScroller={setCurrentBetScroller}
          setBetScrollerForTable={setBetScrollerForTable}
          addNotificationMessage={addNotificationMessage}
        />
      ) : null}
    </Fragment>
  );
};

export default React.memo(BetScrollerManager);
