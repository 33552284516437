import React, { useCallback } from 'react';
import Button from '../../../components/Layout/Button';

const EventSearchMarketTableRow = ({ tableData, viewOutcomes }) => {
	const renderMarkets = useCallback(
		(market) => {
			return (
				<tr key={market.id}>
					<td>{market.names.en.name}</td>
					<td>{market.marketStatus}</td>
					<td>
						<Button
							cssClass="efb-btn__main"
							onClick={() => viewOutcomes(market.outcomeBetInfos, market.names.en.name)}
						>
							<i className="fa fa-eye" />
							View odds
						</Button>
					</td>
				</tr>
			);
		},
		[ viewOutcomes ]
	);
	return tableData.map(renderMarkets);
};

export default EventSearchMarketTableRow;
