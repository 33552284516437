import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Layout/Button';

export const DeleteModal = ({
  modalIsActive,
  onClose,
  sportTemplate,
  deleteCurrentSport,
}) => (
  <Modal isActive={modalIsActive} onClose={onClose} headerText="Delete">
    <h3 className="efb-h3 justify-content-center">
      Are you sure you want to delete?
    </h3>
    <p className="text-center">
      Name: <strong>{sportTemplate.name}</strong>
    </p>
    <section className="efb-modal-permissions__toolbar">
      <div className="efb-modal-permissions__toolbar__button-container">
        <Button onClick={deleteCurrentSport} cssClass="efb-btn__link">
          YES
        </Button>
        <Button onClick={onClose} cssClass="efb-btn__link">
          NO
        </Button>
      </div>
    </section>
    <div className="efb-modal-permissions__decorator" />
  </Modal>
);

DeleteModal.propTypes = {
  modalIsActive: PropTypes.bool,
  onClose: PropTypes.func,
  sportTemplate: PropTypes.object,
  deleteCurrentSport: PropTypes.func,
};

export default DeleteModal;
