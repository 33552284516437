import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import BirDelayTable from './BirDelayTable';
import SelectedTemplate from './SelectedTemplate';
import DeleteModal from './DeleteModal';
import FullScreenLoader from '../../../../components/Layout/FullScreenLoader/FullScreenLoader';
import { connect } from 'react-redux';
import * as ConfigurationActions from '../../../../redux/actions/riskManagement/configuration';
import {
  getRiskManagementData,
  createLoadingSelector,
} from '../../../../selectors';
import * as UiActions from '../../../../redux/actions/ui';

class BirDelayTemplates extends PureComponent {
  state = {
    mode: '',
    isSelected: false,
    sportTemplate: {
      template: {},
      duration: 0,
    },
    modalIsActive: false,
  };

  componentDidMount() {
    this.props.getAllBirDelay();
    this.props.getSportViews();
  }

  componentWillUnmount() {
    this.props.emptySportTemplates();
  }

  createSportTemplate = () => {
    let currentTemplate = {
      ...this.state.sportTemplate,
    };
    currentTemplate = {
      template: {},
      duration: 0,
    };

    this.setState(
      {
        sportTemplate: currentTemplate,
        mode: 'create',
        isSelected: true,
      },
      () =>
        document
          .getElementById('selected-container')
          .scrollIntoView({ behavior: 'smooth' })
    );
  };

  editSportTemplate = currentTemplate => {
    this.setState(
      {
        sportTemplate: currentTemplate,
        mode: 'edit',
        isSelected: true,
      },
      () =>
        document
          .getElementById('selected-container')
          .scrollIntoView({ behavior: 'smooth' })
    );
  };

  showDeleteModal = currentTemplate => {
    this.setState({
      sportTemplate: currentTemplate,
      modalIsActive: true,
      mode: 'delete',
    });
  };

  deleteCurrentSport = () => {
    this.props.deleteBirDelay(this.state.sportTemplate);
    this.closeModal();
  };

  closeModal = () => {
    this.setState({
      sportTemplate: {
        template: {},
        duration: 0,
      },
      mode: '',
      isSelected: false,
      modalIsActive: false,
    });
  };

  changeStatus = currentTemplate => {
    const currentTemplateValue = { ...currentTemplate };
    const currentValue = currentTemplateValue['status'];
    currentTemplateValue['status'] = !currentValue;
  };

  closeSelect = e => {
    e.preventDefault();

    this.setState({
      sportTemplate: {
        template: {},
        duration: 0,
      },
      mode: '',
      isSelected: false,
    });
  };

  handleInputChange = e => {
    const { name, value } = e.target;
    const currentTemplate = { ...this.state.sportTemplate };
    currentTemplate[name] = value;
    this.setState({ sportTemplate: currentTemplate });
  };

  handleSubViewInputs = (data, inputName) => {
    let resetTemplate = {};
    if (inputName === 'sport') {
      resetTemplate = {
        categoryId: null,
        categoryName: null,
        tournamentId: null,
        tournamentName: null,
      };
    } else if (inputName === 'category') {
      resetTemplate = {
        tournamentId: null,
        tournamentName: null,
      };
    }

    this.setState(
      {
        sportTemplate: {
          ...this.state.sportTemplate,
          template: {
            ...this.state.sportTemplate.template,
            ...resetTemplate,
            [inputName + 'Id']: data.value,
            [inputName + 'Name']: data.label,
          },
        },
      },
      () => {
        const name = [
          ...Object.values(
            _.pick(this.state.sportTemplate.template, [
              'sportName',
              'categoryName',
              'tournamentName',
            ])
          ).filter(name => name),
        ].join(' - ');
        this.setState({
          sportTemplate: {
            ...this.state.sportTemplate,
            name,
          },
        });
      }
    );
  };

  templateSend = e => {
    e.preventDefault();

    const objectForSend = { ...this.state.sportTemplate };

    if (!objectForSend.name || !objectForSend.template.sportId) {
      this.props.addNotificationMessage(
        'Name and sport are required fields!',
        'error',
        'Error!'
      );
      return;
    }
    if (objectForSend.duration > 30) {
      this.props.addNotificationMessage(
        'Bir delay duration must be less than 30 seconds!',
        'error',
        'Error!'
      );
      return;
    }
    if (
      objectForSend.duration === '' ||
      objectForSend.duration <= 0 ||
      !objectForSend.duration
    ) {
      this.props.addNotificationMessage(
        'Bir delay duration can not be empty or zero!',
        'error',
        'Error!'
      );
      return;
    }

    const { categoryId, sportId, tournamentId } = objectForSend.template;
    const birDelayObject = {
      duration: `PT${objectForSend.duration}S`,
      categoryId,
      sportId,
      tournamentId,
    };

    if (this.state.mode === 'create') {
      this.props.addBirDelay(birDelayObject);
    } else if (this.state.mode === 'edit') {
      this.props.editBirDelay(objectForSend.id, birDelayObject);
    }

    this.setState({
      sportTemplate: {
        template: {},
        duration: 0,
      },
      mode: '',
      isSelected: false,
    });
  };

  render() {
    const { sportTemplate, modalIsActive, mode } = this.state;
    const { allBirDelay, isLoading, sportViews } = this.props;

    return (
      <div className="tab-pane card-body fade show active">
        <div className="form-row">
          <div className="col-md-6">
            <h3>Bir Delay</h3>
          </div>

          <div className="col-md-6" style={{ textAlign: 'right' }}>
            <button
              className="btn btn-outline-primary new-template"
              onClick={this.createSportTemplate}
            >
              <i className="fas fa-plus" /> New
            </button>
          </div>
        </div>
        <DeleteModal
          modalIsActive={modalIsActive}
          onClose={this.closeModal}
          sportTemplate={sportTemplate}
          deleteCurrentSport={this.deleteCurrentSport}
        />
        {isLoading ? <FullScreenLoader isActive={true} /> : null}
        {sportViews.length ? (
          <BirDelayTable
            data={allBirDelay}
            sportViews={sportViews}
            createSportTemplate={this.createSportTemplate}
            editHandler={this.editSportTemplate}
            showDeleteModal={this.showDeleteModal}
            changeStatus={this.changeStatus}
          />
        ) : null}

        {this.state.isSelected ? (
          <SelectedTemplate
            closeSelect={this.closeSelect}
            sportTemplate={sportTemplate}
            templateSend={this.templateSend}
            handleInputChange={this.handleInputChange}
            handleSubViewInputs={this.handleSubViewInputs}
            sportViews={sportViews}
            mode={mode}
          />
        ) : null}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['SPORT_TEMPLATES']);

const mapStateToProps = state => ({
  allBirDelay: getRiskManagementData(state).configurationReducer.allBirDelay,
  sportViews: getRiskManagementData(state).configurationReducer.sportViews,
  isLoading: loadingSelector(state),
});

const mapDispatchToProps = {
  getAllBirDelay: ConfigurationActions.getAllBirDelay,
  addBirDelay: ConfigurationActions.addBirDelay,
  editBirDelay: ConfigurationActions.editBirDelay,
  emptySportTemplates: ConfigurationActions.emptySportTemplates,
  deleteBirDelay: ConfigurationActions.deleteBirDelay,
  getSportViews: ConfigurationActions.getSportViews,
  addNotificationMessage: UiActions.addNotificationMessage,
};

BirDelayTemplates.propTypes = {
  allBirDelay: PropTypes.array,
  sportViews: PropTypes.array,
  getAllBirDelay: PropTypes.func,
  editBirDelay: PropTypes.func,
  setSportTypesTemplates: PropTypes.func,
  emptySportTemplates: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(BirDelayTemplates);
