import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { connect } from 'react-redux';

import * as CustomerManagerActions from '../../../../redux/actions/customer/customerManager';

import classes from './Sidebar.module.scss';
import Input from '../../../../components/Form/Input';
import Button from '../../../../components/Layout/Button';
import { underlinedSelectStyles } from '../../../../utils/selectStyles';
import { IconSearch } from '../../../../components/Icons/UI/ui/IconSearch';
import {
  useMount,
  useStateForInput,
  useStateForSelect,
  useUpdate,
} from '../../../../hooks';
import RadioButton from '../../../../components/Form/RadioButton/RadioButton';

const clientSearchOptions = [
  { label: 'ACC NO.', value: 'customerId' },
  { label: 'Username', value: 'username' },
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
];

const Sidebar = ({
  emptyCustomers,
  getCustomersWithFilters,
  setSearchCustomersWithFilters,
  setCurrentPage,
  setTotalPages,
}) => {
  const [clientSearchOption, setClientSearchOption] = useStateForSelect(
    clientSearchOptions[0]
  );
  const [clientSearch, setClientSearch] = useStateForInput('');
  const [email, setEmail] = useStateForInput('');
  const [phone, setPhone] = useStateForInput('');
  const [minBirthDate, setMinBirthDate] = useStateForInput('');
  const [maxBirthDate, setMaxBirthDate] = useStateForInput('');
  const [minCreationDate, setMinCreationDate] = useStateForInput('');
  const [maxCreationDate, setMaxCreationDate] = useStateForInput('');
  const [verified, setVerified] = useState(null);
  const [minVerificationDate, setMinVerificationDate] = useStateForInput('');
  const [maxVerificationDate, setMaxVerificationDate] = useStateForInput('');
  const [minValidDate, setMinValidDate] = useStateForInput('');
  const [maxValidDate, setMaxValidDate] = useStateForInput('');
  const [nricNumber, setNricNumber] = useStateForInput('');

  useMount(async () => {
    try {
      const res = await getCustomersWithFilters({}, 1);
      setTotalPages(res.totalPages);
    } catch (err) {}
  });

  const searchCustomers = useCallback(
    async (pageNumber = 1) => {
      const filters = {
        [clientSearchOption.value]: clientSearch,
        email,
        mobilePhone: phone,
        dateOfBirthFrom: moment(minBirthDate)
          .utc(true)
          .toISOString(),
        dateOfBirthTo: moment(maxBirthDate)
          .utc(true)
          .toISOString(),
        registerFrom: moment(minCreationDate)
          .utc(true)
          .toISOString(),
        registerTo: moment(maxCreationDate)
          .utc(true)
          .toISOString(),
        verified,
        verifiedFrom: moment(minVerificationDate)
          .utc(true)
          .toISOString(),
        verifiedTo: moment(maxVerificationDate)
          .utc(true)
          .toISOString(),
        validFrom: moment(minValidDate)
          .utc(true)
          .toISOString(),
        validTo: moment(maxValidDate)
          .utc(true)
          .toISOString(),
        personalIdentityNumber: nricNumber,
      };

      Object.keys(filters).forEach(
        key =>
          (filters[key] === undefined || filters[key] === '') &&
          delete filters[key]
      );

      setCurrentPage(pageNumber);
      setTotalPages(1);

      let res;

      try {
        res = await getCustomersWithFilters(filters, pageNumber);
      } catch (err) {}

      setTotalPages(res.totalPages);
      return res;
    },
    [
      clientSearch,
      clientSearchOption.value,
      email,
      getCustomersWithFilters,
      maxBirthDate,
      maxCreationDate,
      maxValidDate,
      maxVerificationDate,
      minBirthDate,
      minCreationDate,
      minValidDate,
      minVerificationDate,
      nricNumber,
      phone,
      setCurrentPage,
      setTotalPages,
      verified,
    ]
  );

  useUpdate(() => {
    setSearchCustomersWithFilters(searchCustomers);
  }, [searchCustomers]);

  return (
    <div className={classes.wrapper}>
      <div>
        <label>Client Search</label>
        <div className="d-flex">
          <Select
            value={clientSearchOption}
            onChange={setClientSearchOption}
            styles={underlinedSelectStyles}
            options={clientSearchOptions}
            className={classes.flex1}
          />

          <div className={`ml-1 position-relative ${classes.flex2}`}>
            <IconSearch className={classes.inputIcon} width={15} />
            <Input
              customInput
              placeholder="Search"
              value={clientSearch}
              onChange={setClientSearch}
              className={classes.searchInput}
            />
          </div>
        </div>
      </div>

      <div>
        <label>Phone</label>
        <div>
          <Input
            customInput
            value={phone}
            onChange={setPhone}
            className="efb-input__underlined"
          />
        </div>
      </div>

      <div>
        <label>Email</label>
        <div>
          <Input
            customInput
            value={email}
            onChange={setEmail}
            className="efb-input__underlined"
          />
        </div>
      </div>

      <div className={classes.dates}>
        <label>Date</label>

        <div>
          <label className="mb-0">Birth</label>
          <div>
            <Input
              customInput
              className={classes.boxedInput}
              value={minBirthDate}
              onChange={setMinBirthDate}
            />
            <span className="mx-2">to</span>
            <Input
              customInput
              className={classes.boxedInput}
              value={maxBirthDate}
              onChange={setMaxBirthDate}
            />
          </div>
        </div>

        <div>
          <label className="mb-0">Creation</label>
          <div>
            <Input
              customInput
              className={classes.boxedInput}
              value={minCreationDate}
              onChange={setMinCreationDate}
            />
            <span className="mx-2">to</span>
            <Input
              customInput
              className={classes.boxedInput}
              value={maxCreationDate}
              onChange={setMaxCreationDate}
            />
          </div>
        </div>

        <div className={classes.verification}>
          <label className="mb-0">Verification</label>
          <div>
            <div className={classes.radioGroup}>
              <RadioButton
                isChecked={verified}
                id="verified"
                label="Verified"
                name="verified"
                onChange={() => setVerified(true)}
              />
              <RadioButton
                isChecked={verified === false}
                id="unverified"
                label="Unverified"
                name="unverified"
                onChange={() => setVerified(false)}
              />
            </div>
            <div>
              <Input
                customInput
                className={classes.boxedInput}
                value={minVerificationDate}
                onChange={setMinVerificationDate}
              />
              <span className="mx-2">to</span>
              <Input
                customInput
                className={classes.boxedInput}
                value={maxVerificationDate}
                onChange={setMaxVerificationDate}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <label>Documents</label>
        <div className={classes.dates}>
          <div>
            <label className="mb-0">Valid</label>
            <div>
              <Input
                customInput
                className={classes.boxedInput}
                value={minValidDate}
                onChange={setMinValidDate}
              />
              <span className="mx-2">to</span>
              <Input
                customInput
                className={classes.boxedInput}
                value={maxValidDate}
                onChange={setMaxValidDate}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <label>NRIC/PASS. NO.</label>
        <div>
          <Input
            customInput
            value={nricNumber}
            onChange={setNricNumber}
            className="efb-input__underlined"
          />
        </div>
      </div>

      <Button
        cssClass="efb-btn__main w-100"
        onClick={() => {
          emptyCustomers();
          searchCustomers();
        }}
      >
        Search
      </Button>
    </div>
  );
};

const mapDispatchToProps = {
  getCustomersWithFilters: CustomerManagerActions.getCustomersWithFilters,
  emptyCustomers: CustomerManagerActions.emptyCustomers,
};

export default connect(null, mapDispatchToProps)(Sidebar);
