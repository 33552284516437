import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal/Modal';
import InputWithLabel from '../../../../components/Form/InputWithLabel/InputWithLabel';
import Button from '../../../../components/Layout/Button';

const FiltersModal = ({
  filters,
  isActive,
  toggleFiltersModal,
  getUsers,
  resetFilters,
  onChange,
}) => {
  const filtersClear = useMemo(() => {
    return Object.values(filters).every(filter => !filter);
  }, [filters]);

  return (
    <Modal
      isActive={isActive}
      onClose={toggleFiltersModal}
      headerText="Filters"
    >
      <div className="efb-modal__content">
        {Object.keys(filters).map(filter => (
          <InputWithLabel
            label={`${filter[0].toUpperCase()}${filter.slice(1)}:`}
            type="text"
            onChange={onChange}
            value={filters[filter]}
            name={filter}
          />
        ))}
      </div>
      <footer className="efb-modal__footer">
        <Button onClick={resetFilters} cssClass="efb-btn__link">
          Clear All
        </Button>
        <Button onClick={getUsers} cssClass="efb-btn__main">
          {filtersClear ? 'Get all users' : 'Apply'}
        </Button>
      </footer>
    </Modal>
  );
};

FiltersModal.propTypes = {
  filters: PropTypes.array.isRequired,
  isActive: PropTypes.bool.isRequired,
  toggleFiltersModal: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FiltersModal;
