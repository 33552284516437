import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  getUsersData,
  getUiComponentsReducer,
  getRolesData,
  createLoadingSelector,
} from '../../../../selectors';
import * as UsersActions from '../../../../redux/actions/usersApi';
import * as UiActions from '../../../../redux/actions/ui';
import * as RolesActions from '../../../../redux/actions/rolesApi';
import { useMount } from '../../../../hooks';
import UserProfilesToolbar from './UserProfilesToolbar';
import UsersTable from './UsersTable';
import FiltersModal from './FiltersModal';
import CreateUserModal from './CreateUserModal';
import EditUserModal from './EditUserModal';
import EditPermissionsModal from './EditPermissionsModal';
import FullScreenLoader from '../../../../components/Layout/FullScreenLoader/FullScreenLoader';

const userObject = {
  username: '',
  entityId: '',
  password: '',
  id: 0,
  active: false,
  description: '',
  roles: [],
};

const UserProfiles = ({
  users,
  roles,
  isLoading,
  totalElements,
  totalPages,
  getAll,
  getAllRoles,
  getUserByUsername,
  addRoleToUser,
  updateUser,
  createUser,
}) => {
  const [editUser, setEditUser] = useState(userObject);
  const [filters, setFilters] = useState({ username: '' });
  const [openUsersModal, setOpenUserModal] = useState(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  const [openRolePermissionsModal, setOpenRolePermissionsModal] = useState(
    false
  );
  const [currentPage, setCurrentPage] = useState(1);

  useMount(() => {
    getAll();
    getAllRoles();
  });

  const getUsersForPage = useCallback(
    page => {
      getAll(page);
      setCurrentPage(page);
    },
    [getAll]
  );

  const createNewUser = useCallback(
    async userBody => {
      await createUser(userBody);
      getUsersForPage(currentPage);
    },
    [createUser, currentPage, getUsersForPage]
  );

  const updateExistingUser = useCallback(
    async (userId, description, isActive) => {
      await updateUser(userId, description, isActive);
      setTimeout(() => {
        getUsersForPage(currentPage);
      }, 500);
    },
    [currentPage, getUsersForPage, updateUser]
  );

  const updateRoles = useCallback(
    async (userId, roles) => {
      await addRoleToUser(userId, roles);
      setTimeout(() => {
        getUsersForPage(currentPage);
      }, 500);
    },
    [addRoleToUser, currentPage, getUsersForPage]
  );

  const toggleUsersModal = useCallback(() => {
    setOpenUserModal(!openUsersModal);
  }, [openUsersModal]);

  const toggleRolePermissionsModal = useCallback(() => {
    setOpenRolePermissionsModal(!openRolePermissionsModal);
  }, [openRolePermissionsModal]);

  const toggleFiltersModal = useCallback(() => {
    setOpenFiltersModal(!openFiltersModal);
  }, [openFiltersModal]);

  const toggleCreateUserModal = useCallback(() => {
    setOpenCreateUserModal(!openCreateUserModal);
  }, [openCreateUserModal]);

  const removeFilter = useCallback(
    filter => {
      setFilters({
        ...filters,
        [filter]: '',
      });
    },
    [filters]
  );

  const resetFilters = useCallback(() => {
    setFilters(
      Object.keys(filters).reduce(
        (acc, filter) => ({ ...acc, [filter]: '' }),
        {}
      )
    );
    getUsersForPage(currentPage);
  }, [currentPage, filters, getUsersForPage]);

  const getUsers = useCallback(() => {
    toggleFiltersModal();
    if (!filters.username) getUsersForPage(currentPage);
    else getUserByUsername(filters.username);
  }, [
    currentPage,
    filters,
    getUserByUsername,
    getUsersForPage,
    toggleFiltersModal,
  ]);

  const onFilterChange = useCallback(
    e => {
      setFilters({
        ...filters,
        [e.target.name]: e.target.value,
      });
    },
    [filters]
  );

  return (
    <>
      {/* UI */}
      <UserProfilesToolbar
        filters={filters}
        resetFilters={resetFilters}
        toggleFiltersModal={toggleFiltersModal}
        toggleCreateUserModal={toggleCreateUserModal}
        removeFilter={removeFilter}
      />
      <UsersTable
        setUserForEdit={setEditUser}
        toggleUsersModal={toggleUsersModal}
        users={users}
        toggleRolePermissionsModal={toggleRolePermissionsModal}
        getUsersForPage={getUsersForPage}
        totalElements={totalElements || 1}
        totalPages={totalPages || 1}
      />

      {/* MODALS */}
      {openFiltersModal ? (
        <FiltersModal
          isActive={openFiltersModal}
          filters={filters}
          toggleFiltersModal={toggleFiltersModal}
          getUsers={getUsers}
          resetFilters={resetFilters}
          onChange={onFilterChange}
        />
      ) : null}
      {openCreateUserModal ? (
        <CreateUserModal
          onClose={toggleCreateUserModal}
          isActive={openCreateUserModal}
          createUser={createNewUser}
        />
      ) : null}
      {openUsersModal ? (
        <EditUserModal
          isActive={openUsersModal}
          onClose={toggleUsersModal}
          user={editUser}
          updateUser={updateExistingUser}
        />
      ) : null}
      {openRolePermissionsModal ? (
        <EditPermissionsModal
          allRoles={roles}
          user={editUser}
          isActive={openRolePermissionsModal}
          onClose={toggleRolePermissionsModal}
          updateRoles={updateRoles}
        />
      ) : null}
      <FullScreenLoader isActive={isLoading} />
    </>
  );
};

UserProfiles.propTypes = {
  getAll: PropTypes.func,
  getUserByUsername: PropTypes.func,
  users: PropTypes.array,
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  usersModal: PropTypes.bool,
  roles: PropTypes.array,
  getAllRoles: PropTypes.func,
};

const loadingSelector = createLoadingSelector([
  'USERS',
  'CREATE_USER',
  'UPDATE_USER',
  'UPDATE_USER_ROLES',
  'UPDATE_USER_DESCRIPTION',
  'UPDATE_USER_STATUS',
]);

const mapStateToProps = state => ({
  users: getUsersData(state).users,
  totalPages: getUsersData(state).totalPages,
  totalElements: getUsersData(state).totalElements,
  usersModal: getUiComponentsReducer(state).toggleUserModal,
  roles: getRolesData(state),
  isLoading: loadingSelector(state),
});

const mapDispatchToProps = {
  getAll: UsersActions.getAll,
  updateUser: UsersActions.updateUser,
  addRoleToUser: UsersActions.addRoleToUser,
  getUserByUsername: UsersActions.getUserByUsername,
  addNotificationMessage: UiActions.addNotificationMessage,
  getAllRoles: RolesActions.getAllRoles,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfiles)
);
