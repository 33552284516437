import { action } from '.';
//Requests (TODO: At some point in needs to go in folder 'middleware/requests.js');
import axiosInstance from './axios-instance';

const REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  FETCH_APPLICATIONS = 'FETCH_APPLICATIONS';

const createRequestTypes = base => {
  return [REQUEST, SUCCESS, FAILURE, FETCH_APPLICATIONS].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
};

export const getRolesRequestType = createRequestTypes('ROLES');

export const rolesActions = {
  request: () => action(getRolesRequestType.REQUEST),
  success: response => action(getRolesRequestType.SUCCESS, { response }),
  failure: error => action(getRolesRequestType.FAILURE, { error }),
  fetchApplications: applications =>
    action(getRolesRequestType.FETCH_APPLICATIONS, { applications })
};

export const getAllRoles = () => {
  return dispatch => {
    dispatch(rolesActions.request());
    axiosInstance
      .get('/usermgnt/view/role/admin/role')
      .then(response => {
        return dispatch(rolesActions.success(response));
      })
      .catch(error => {
        console.log(error);
        //return dispatch(rolesActions.failure(error.message))
      });
  };
};

export const createRole = roleBody => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/usermgnt/view/role/admin/role', roleBody)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const editRole = roleBody => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .patch('/role', roleBody)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchApplications = () => {
  return dispatch => {
    axiosInstance
      .get('/usermgnt/view/application/admin')
      .then(response => {
        return dispatch(rolesActions.fetchApplications(response));
      })
      .catch(error => {
        return dispatch(rolesActions.failure(error.message));
      });
  };
};

// Dynamic dashboard routes
export const getUserRolesRoutePermisions = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get('/usermgnt/view/session/admin')
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};
