import moment from 'moment';

import { actionCreator, createRequestTypes } from '.';
import axiosInstance from './axios-instance';
import { addNotificationMessage } from './ui';

export const FETCH_STREAMING_EVENTS = createRequestTypes(
  'FETCH_STREAMING_EVENTS'
);
export const UPDATE_EVENT_STREAM = createRequestTypes('UPDATE_EVENT_STREAM');
export const GET_MAPPED_EVENTS = createRequestTypes('GET_MAPPED_EVENTS');

export const fetchEvents = (providerId) => async dispatch => {
  dispatch(actionCreator.request(FETCH_STREAMING_EVENTS));
  try {
    const res = await axiosInstance.get('/streaming/admin/events', {
      params: {
        providerId
      }
    });

    dispatch(actionCreator.success(FETCH_STREAMING_EVENTS, res));
  } catch (error) {
    dispatch(actionCreator.failure(FETCH_STREAMING_EVENTS, error));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getMappedEvents = (providerId) => async dispatch => {
  dispatch(actionCreator.request(GET_MAPPED_EVENTS));
  try {
    const res = await axiosInstance.get('/sport/event/view/match/admin/', {
      params: {
        streamingProviderId: providerId,
        startTimeAfter: moment().subtract(3, 'days').utc(true).toISOString()
      }
    });
    dispatch(actionCreator.success(GET_MAPPED_EVENTS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_MAPPED_EVENTS));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const updateEvent = (event, streamId, providerId) => async dispatch => {
  dispatch(actionCreator.request(UPDATE_EVENT_STREAM));
  try {
    let streamingProviders = [...event.streamingProviders];
    if (!streamId && !providerId) {
      streamingProviders = streamingProviders.filter(provider => provider.providerId !== '2');
    } else {
      streamingProviders.push(
        {
          streamId,
          providerId
        }
      );
    }

    await axiosInstance.post(`/sport/event/view/match/admin/${event.id}`, {
      ...event,
      competitionStatus: undefined,
      matchStatus: undefined,
      streamingProviders
    });
    dispatch(getMappedEvents(2));
    dispatch(actionCreator.success(UPDATE_EVENT_STREAM));
    dispatch(
      addNotificationMessage(
        'Successfully mapped event to stream!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(actionCreator.failure(UPDATE_EVENT_STREAM));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};
