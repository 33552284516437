import React from 'react';
import Alert from '../Layout/Alert/Alert';

export const FormErrors = ({ formErrors, alertType }) =>
  Object.keys(formErrors).map((fieldName, i) => {
    return formErrors[fieldName].length > 0 ? (
      <Alert type={alertType} key={i}>
        {formErrors[fieldName]}
      </Alert>
    ) : (
      ''
    );
  });

export default FormErrors;
