import React, { useCallback, useState } from 'react';
import { getSportIcon } from '../../../../helpers/iconHelpers';
import { ICONS_SPORT_MAP } from '../../../../components/Icons/UI/sports/index';
import CheckboxDiv from '../../../../components/Form/CheckboxDiv/CheckboxDiv';

const BySports = ({ sports, betScroller, onChangeSport }) => {
  const [currentSports, setCurrentSports] = useState(sports);

  const checkSport = useCallback(
    (e, id) => {
      const filterSport = [...betScroller.sports];
      onChangeSport({
        ...betScroller,
        sports: filterSport.some(sport => sport.code === e.target.name)
          ? filterSport.filter(sport => sport.code !== e.target.name)
          : [...betScroller.sports, { id, code: e.target.name }],
      });
    },
    [betScroller, onChangeSport]
  );

  const onChangeSearchSport = useCallback(
    e => {
      setCurrentSports(
        sports.filter(
          sport =>
            sport.names.en.name
              .toLowerCase()
              .indexOf(e.target.value.toLowerCase()) !== -1
        )
      );
    },
    [sports]
  );

  const mapSports = useCallback(
    sport => {
      const SportIcon = getSportIcon(sport, ICONS_SPORT_MAP);
      return (
        <CheckboxDiv
          key={sport.id}
          label={
            <>
              {SportIcon && (
                <SportIcon
                  selected={true}
                  colorful={true}
                  width={15}
                  height={15}
                />
              )}
              {sport.names.en.name}
            </>
          }
          name={sport.names.en.name}
          onChange={e => checkSport(e, sport.id)}
          isChecked={betScroller.sports.some(
            bsSport => bsSport.code === sport.names.en.name
          )}
          id={sport.id}
        />
      );
    },
    [betScroller.sports, checkSport]
  );

  return (
    <div className="m-3">
      <div className="search-sport">
        <input
          type="text"
          name="searchSport"
          onChange={onChangeSearchSport}
          placeholder="Find a sport"
        />
        <i className="fa fa-search" />
      </div>
      <div
        className="overflow-scroll-box"
        style={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          gridAutoRows: '11% 11%',
          gridRowGap: 5,
          gridColumnGap: 5,
        }}
      >
        {currentSports.map(mapSports)}
      </div>
    </div>
  );
};

export default BySports;