import React, { memo } from 'react';

export const VirtualBasketball = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  const icon = (
    <>
      <path
        d="M29.51,15.9c0,0.31,0,0.61,0,0.92c-0.01,0-0.02,0.01-0.01,0.03c-0.2,4.52-2.04,7.68-4.44,10.08
	c-1.19,1.19-2.67,2.19-4.35,2.94c-1.69,0.75-3.69,1.23-5.95,1.25c-0.01-0.15-0.03-0.37-0.02-0.49c4.63-0.05,7.85-1.94,10.27-4.36
	c1.2-1.2,2.22-2.7,2.94-4.44c0.71-1.75,1.17-3.86,1.06-6.19c-0.1-2.13-0.72-4.1-1.54-5.72c-0.84-1.66-1.93-3.02-3.21-4.17
	c-1.29-1.15-2.81-2.11-4.6-2.78c-1.8-0.67-4.01-1-6.23-0.8c-0.21,0.79-1.13,1.44-2.14,1.12c-0.29-0.09-0.51-0.28-0.71-0.41
	c-0.58,0.35-0.33,1.29-0.17,1.91c0.15,0.58,0.32,1.21,0.37,1.84c0.02,0.3-0.07,0.72,0,0.94c0.06,0.21,0.37,0.43,0.52,0.62
	c0.33,0.38,0.51,0.86,0.68,1.41c0.07,0.25,0.11,0.54,0.22,0.72c0.16,0.26,0.6,0.32,0.92,0.48c0.16,0.08,0.29,0.19,0.45,0.22
	c0.25-0.45,0.05-0.79-0.11-1.17c-0.14-0.35-0.29-0.74-0.14-1.17c0.23-0.63,0.79-1.02,1.49-1.05c0.38-0.01,0.7,0.09,0.95,0.32
	c0.2,0.18,0.43,0.44,0.49,0.78c0.02,0.11,0,0.32,0,0.38c0,0.09,0.07,0.19,0.06,0.34c0,0.08-0.06,0.19-0.08,0.29
	c-0.04,0.27-0.02,0.56-0.11,0.69c-0.12,0.18-0.5,0.11-0.61,0.32c-0.14,0.27,0.11,0.65,0.05,1c0.57,0.31,1.27,0.42,1.66,0.94
	c0.16,0.21,0.26,0.49,0.48,0.69c0.2,0.18,0.43,0.31,0.63,0.51c0.39,0.39,0.66,0.83,0.98,1.29c0.14,0.2,0.35,0.53,0.54,0.6
	c0.14,0.05,0.27,0.02,0.45,0.05c0.27,0.04,0.58,0.16,0.84,0.28c0.54,0.23,1.07,0.42,1.6,0.65c0.27,0.11,0.54,0.27,0.8,0.32
	c0.26,0.05,0.56-0.01,0.84,0c0.51,0.01,1.01,0.1,1.4,0.25c0.17,0.07,0.44,0.14,0.48,0.35c-0.37,0.09-0.59-0.22-1.01-0.17
	c0.11,0.13,0.29,0.26,0.45,0.43c0.08,0.09,0.43,0.46,0.22,0.55c-0.16,0.07-0.43-0.35-0.51-0.43c-0.05-0.05-0.15-0.15-0.14-0.11
	c0.07,0.12,0.15,0.21,0.23,0.35c0.05,0.09,0.19,0.34,0.06,0.4c-0.15,0.07-0.27-0.25-0.34-0.35c-0.11-0.16-0.24-0.3-0.35-0.35
	c0.04,0.06,0.15,0.18,0.2,0.32c0.03,0.1,0.12,0.48-0.06,0.45c-0.09-0.02-0.07-0.24-0.11-0.34c-0.04-0.11-0.24-0.4-0.35-0.43
	c-0.17-0.04-0.54,0.02-0.69-0.01c-0.34-0.09-0.67-0.3-1.05-0.43c-0.36-0.12-0.81-0.13-1.21-0.18c-0.41-0.05-0.82-0.16-1.25-0.23
	c-0.42-0.07-0.88-0.15-1.17-0.32c-0.18-0.11-0.35-0.32-0.52-0.48c-0.45-0.41-0.94-0.75-1.41-1.11c-0.09-0.07-0.19-0.18-0.28-0.17
	c-0.16,0.34-0.26,0.71-0.4,1.08c-0.13,0.35-0.27,0.74-0.35,1.14c-0.16,0.81-0.12,1.66-0.45,2.46c-0.16,0.38-0.22,0.78-0.25,1.23
	c-0.03,0.45-0.09,0.92-0.09,1.37c0,0.44-0.02,0.9,0,1.35c0.05,0.98,0.27,2.28-0.74,2.38c-0.04,0.21-0.09,0.49-0.14,0.77
	c-0.04,0.25-0.03,0.61-0.26,0.66c-0.26,0.06-0.47-0.14-0.69-0.2c-0.37,0.56-1.23,0.4-1.98,0.54c-0.45,0.08-0.94,0.21-1.38,0.31
	c-0.95,0.21-1.82,0.44-2.6,0.78c0.04,0.18,0.17,0.26,0.17,0.48c-0.59,0.22-1.15-0.03-1.61,0.15c-0.18,0.07-0.37,0.22-0.49,0.35
	c-0.37,0.4-0.55,1.25-1.11,1.44c-0.05,0-0.1,0-0.15,0c-0.31,0.01-0.43-0.31-0.41-0.69c0.02-0.42,0.2-0.84,0.32-1.17
	c0.28-0.7,0.49-1.44,0.88-2.11c0.15-0.27,0.42-0.77,0.74-0.75c0.1,0.01,0.16,0.09,0.26,0.15c0.09,0.06,0.19,0.11,0.25,0.17
	c0.12,0.11,0.16,0.28,0.31,0.38c0.06,0.04,0.17,0.06,0.26,0.11c0.1,0.05,0.12,0.1,0.25,0.12c0.41,0.09,0.91-0.32,1.24-0.52
	c0.3-0.19,0.58-0.37,0.86-0.54c0.64-0.4,1.32-0.53,2.2-0.54c0.01-0.3-0.26-0.58-0.23-1.01c-0.32-0.15-0.67-0.28-1.06-0.42
	c-0.12-0.04-0.29-0.12-0.38-0.11c-0.21,0.03-0.24,0.31-0.45,0.34C9.29,24.8,9,24.67,8.79,24.62c-0.22-0.05-0.5-0.1-0.72-0.08
	c-0.2,0.02-0.46,0.19-0.61,0.29c-0.41,0.26-0.65,0.49-1.11,0.71c-0.18,0.09-0.53,0.26-0.74,0.15c-0.25-0.13-0.19-0.79-0.12-1.09
	c0.21-0.9,0.9-1.23,1.63-1.75c0.23-0.17,0.48-0.39,0.72-0.6c0.26-0.22,0.6-0.66,1.05-0.52c0.15,0.05,0.45,0.37,0.57,0.51
	c0.08,0.09,0.17,0.2,0.25,0.29c0.09,0.1,0.19,0.2,0.25,0.28c0.05,0.07,0.05,0.18,0.11,0.23c0.13,0.1,0.46,0.09,0.66,0.11
	c0.16,0.02,0.47,0.02,0.68,0.01c-0.05-0.32,0.07-0.67,0-0.98c-0.03-0.16-0.18-0.26-0.23-0.42c-0.09-0.28-0.1-0.62-0.15-0.94
	c-0.06-0.42-0.24-0.77-0.2-1.15c0.03-0.31,0.18-0.61,0.32-0.89c0.13-0.25,0.33-0.5,0.43-0.78c0.07-0.19,0.07-0.41,0.15-0.61
	c0.07-0.19,0.25-0.28,0.32-0.51c0.09-0.28,0.02-0.79-0.03-1.17c-0.11-0.8-0.29-1.37-0.49-2.06c-0.1-0.34-0.15-0.7-0.28-1
	c-0.26-0.61-0.41-1.27-0.72-1.84c-0.31-0.59-0.38-1.27-0.61-1.94C9.81,8.62,9.64,8.38,9.51,8.13c-0.12-0.24-0.28-0.54-0.31-0.8
	C9.17,7,9.24,6.65,9.27,6.28C9.3,5.9,9.32,5.5,9.34,5.16c0.05-0.7,0.03-1.4,0.15-2.06C6.88,4.17,4.77,5.84,3.2,8
	c-1.54,2.12-2.66,4.91-2.72,8.36C0.36,16.38,0.05,16.39,0,16.38c0-0.15,0-0.31,0-0.46c0.17-3.53,1.33-6.29,3.01-8.47
	c1.49-1.94,3.26-3.42,5.6-4.49c0.32-0.15,0.66-0.27,1-0.43C9.68,2.5,9.67,2.51,9.7,2.45c0.06-0.12,0.04-0.31,0.06-0.45
	c0.18-0.97,0.68-1.64,1.55-1.92c1.1-0.36,2.17,0.43,2.18,1.57c0.01,0.01,0.02,0.02,0.05,0.02c4.87-0.34,8.62,1.44,11.19,3.83
	C27.35,7.93,29.28,11.29,29.51,15.9L29.51,15.9z"
      />
    </>
  );

  return (
    <svg
      x="0px"
      y="0px"
	  className={
        `VirtualBasketball ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.51 31.35"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      {icon}
    </svg>
  );
};

export default memo(VirtualBasketball);
