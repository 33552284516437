export const selectStyles = {
    control: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: '#f7f7f7',
            border: '2px solid #feb719',
            borderColor: isFocused ? '#feb719 !important' : '#feb719 !important',
            boxShadow: isFocused ? '#feb719 !important' : '#feb719 !important',
            lineHeight: '1rem',
            borderRadius: '4px',
            cursor: 'text',
            padding: '0.3rem'
        }
    },
    input: styles => ({ ...styles, maxHeight: '26px' }),
    placeholder: styles => ({ ...styles, paddingTop: '2px' }),
    menu: styles => ({ ...styles, backgroundColor: '#f7f7f7', border: '2px solid #feb719', borderRadius: '4px', zIndex: '1051', position: 'absolute' }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? '#feb719 !important' : '#f7f7f7 !important',
            color: isFocused ? 'white' : 'black'
        }
    }
}

export const minimalStyle = {
    control: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: '#fff',
            border: 'unset',
            boxShadow: isFocused ? '#feb719 !important' : '#feb719 !important',
            lineHeight: '1rem',
            cursor: 'text',
            padding: '0.3rem'
        }
    },
    indicatorSeparator: styles => ({...styles, display: 'none'}),
    input: styles => ({ ...styles, maxHeight: '26px' }),
    placeholder: styles => ({ ...styles, paddingTop: '2px' }),
    menu: styles => ({ ...styles, backgroundColor: '#f9f9f9', border: 'unset', zIndex: '1051', position: 'absolute' }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? '#feb719 !important' : '#f7f7f7 !important',
            color: isFocused ? 'white' : 'black'
        }
    }
}
