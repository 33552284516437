import React from 'react';
import PropTypes from 'prop-types';
import Abbreviations from './Abbreviations';
import CompetitorNames from './CompetitorNames';
import Country from './Country';
import CompetitorSportSelector from './CompetitorSportSelector';
import Button from '../../../../components/Layout/Button';

const CompetitorForm = ({
  competitorsObj,
  sports,
  countryCodes,
  isEditMode,
  createCompetitor,
  cancelEdit,
  setCompetitorsObj,
  setSportId,
  languages,
}) => {
  return (
    <>
      <form className="efb-form-client-search">
        <CompetitorSportSelector
          sports={sports}
          competitorsObj={competitorsObj}
          setCompetitorsObj={setCompetitorsObj}
          setSportId={setSportId}
        />
        <div className="efb-form">
          <br />
          <hr />
          <CompetitorNames
            competitorsObj={competitorsObj}
            setCompetitorsObj={setCompetitorsObj}
            languages={languages}
          />
          <hr />
          <Abbreviations
            competitorsObj={competitorsObj}
            setCompetitorsObj={setCompetitorsObj}
            languages={languages}
          />
        </div>
        <hr />
        <Country
          competitorsObj={competitorsObj}
          setCompetitorsObj={setCompetitorsObj}
          countryCodes={countryCodes}
          isEditMode={isEditMode}
        />
      </form>
      <div className="form-group d-flex flex-row justify-content-end">
        <Button onClick={createCompetitor} cssClass="efb-btn__main">
          {isEditMode ? 'Update competitor' : 'Add competitor'}
        </Button>
        {isEditMode ? (
          <Button cssClass="efb-btn__main" onClick={cancelEdit}>
            Cancel
          </Button>
        ) : null}
      </div>
    </>
  );
};

CompetitorForm.propTypes = {
  competitorsObj: PropTypes.object,
  createCompetitor: PropTypes.func,
  updateObj: PropTypes.func,
  isEditMode: PropTypes.bool,
  cancelEdit: PropTypes.func,
};

export default CompetitorForm;
