import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../components/Layout/Button';
import Table from '../../../../components/Table/Table';
import Modal from '../../../../components/Modal/Modal';
import Textarea from '../../../../components/Form/Textarea/Textarea';
import Alert from '../../../../components/Layout/Alert/Alert';

import { useStateForInput } from '../../../../hooks';
import { exceptionColumns } from '../transactionsUtils';
import { isEmpty } from '../../../../utils/helpers';

const Exceptions = ({
  filteredTransactions,
  resolvePayment,
  addNotificationMessage,
}) => {
  const [comment, setComment, resetComment] = useStateForInput('');
  const [currentPayment, setCurrentPayment] = useState('');
  const [activeModal, setActiveModal] = useState(false);

  const resolve = useCallback(payment => {
    setCurrentPayment(payment);
    setActiveModal(true);
  }, []);

  const submitExcpetion = useCallback(
    paymentDecision => {
      if (isEmpty(comment)) {
        addNotificationMessage('Comment is required!', 'error', 'Error');
        return;
      }
      setActiveModal(false);
      resolvePayment(currentPayment.id, {
        accepted: paymentDecision,
        comment,
      });
      resetComment();
    },
    [
      comment,
      resolvePayment,
      currentPayment.id,
      resetComment,
      addNotificationMessage,
    ]
  );

  const closeModal = useCallback(() => {
    setActiveModal(false);
    resetComment();
  }, [resetComment]);

  return (
    <div>
      <h3 className="my-4">Exceptions</h3>
      {filteredTransactions.length === 0 ? (
        <Alert type="info">No exceptions in pending transactions</Alert>
      ) : null}
      <Table
        columns={exceptionColumns}
        data={filteredTransactions}
        resolve={resolve}
        tableType="filteredTransactions"
        tableStyle="sortable table table-hover table-striped efb-table events-search-table"
      />
      <Modal
        headerText="Resolve Payment"
        isActive={activeModal}
        onClose={closeModal}
      >
        <div className="modal-wrapper efb-form transaction-exception-resolve-modal">
          <div className="resolve-wrapper">
            <hr />
            <Textarea
              placeholder="Comment"
              name="comment"
              onChange={setComment}
            />
            <div className="row">
              <div className="col-md">
                <Button
                  cssClass={'efb-btn__main accept-btn'}
                  onClick={() => submitExcpetion(true)}
                >
                  Accept
                </Button>
              </div>
              <div className="col-md">
                <Button
                  cssClass={'efb-btn__main reject-btn'}
                  onClick={() => submitExcpetion(false)}
                >
                  Reject
                </Button>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </Modal>
    </div>
  );
};

Exceptions.propTypes = {
  filteredTransactions: PropTypes.array,
  addNotificationMessage: PropTypes.func,
  resolvePayment: PropTypes.func,
};

export default Exceptions;
