import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import * as CustomerDataActions from '../../../../../redux/actions/customer/customerData';
import classes from './General.module.scss';
import Input from '../../../../../components/Form/Input';
import Button from '../../../../../components/Layout/Button';

const PersonalInfo = ({
  customer,
  refetchCustomer,
  resendActivationEmail,
  activateManuallyEmail,
}) => {
  return (
    <div>
      <h1>Personal Info</h1>
      <div>
        <div>
          <label>Name:</label>
          <div className={classes.fullName}>
            <Input
              customInput
              disabled
              value={customer.gender === 'MALE' ? 'Mr.' : 'Ms.'}
              className="efb-input__underlined"
            />
            <Input
              customInput
              disabled
              value={customer.firstName}
              className="efb-input__underlined"
            />
            <Input
              customInput
              disabled
              value={customer.lastName}
              className="efb-input__underlined"
            />
          </div>
        </div>

        <div>
          <label>Mobile:</label>
          <div>
            <Input
              customInput
              disabled
              value={customer.mobilePhone}
              className="efb-input__underlined"
            />
            <div className="bg-danger p-2">
              <Button cssClass="btn btn-secondary btn-sm text-dark">
                Verify
              </Button>
            </div>
          </div>
        </div>

        <div>
          <label>Email:</label>
          <div>
            <Input
              customInput
              disabled
              value={customer.email}
              className="efb-input__underlined"
            />
            <Button
              cssClass="btn btn-sm"
              title="Resend Activation Mail"
              onClick={() => resendActivationEmail(customer.user.id)}
            >
              Resend AM
            </Button>
            {!customer.user?.active ? (
              <Button
                cssClass="btn btn-sm"
                title="Activate account"
                onClick={async () => {
                  await activateManuallyEmail(customer.customerId);
                  await refetchCustomer();
                }}
              >
                Activate
              </Button>
            ) : null}
          </div>
        </div>

        <div className={classes.lastLine}>
          <div>
            <label>Gender:</label>
            <div>
              <Input
                customInput
                disabled
                value={customer.gender}
                className="efb-input__underlined"
              />
            </div>
          </div>
          <div>
            <label>Date of Birth:</label>
            <div>
              <Input
                customInput
                disabled
                value={moment(customer.dateOfBirth).format('DD/MM/YYYY')}
                className="efb-input__underlined"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  resendActivationEmail: CustomerDataActions.resendActivationEmail,
  activateManuallyEmail: CustomerDataActions.activateManuallyEmail,
};

export default connect(null, mapDispatchToProps)(PersonalInfo);
