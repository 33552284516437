import React, { useCallback } from 'react';
import Button from '../../Layout/Button';
import PropTypes from 'prop-types';

const SettlementEventMarkets = React.memo(
	({ tableData: eventMarkets, openResultSetModal, openSettlmentDetailsModal, reopenSettlement }) => {
		const mapEventMarkets = useCallback(
			(market, index) => (
				<tr key={market.id}>
					<td>{index + 1}.</td>
					<td>{market.name}</td>
					<td>{market.marketStatus}</td>
					<td>{market.infoMessage || '/'}</td>
					<td>
						<Button
							cssClass="btn btn-primary btn-md"
							disabled={market.isSettled}
							onClick={() => openResultSetModal(market.id)}
						>
							Settle
						</Button>
						<Button
							cssClass="btn btn-primary btn-md"
							disabled={!market.isSettled}
							onClick={() => reopenSettlement(market.id)}
						>
							<i className="fas fa-redo" /> Rollback settled market
						</Button>
						<Button
							cssClass="btn btn-primary btn-md"
							disabled={!market.isSettled}
							onClick={() => openSettlmentDetailsModal(market)}
						>
							<i className="fa fa-info-circle" /> Settlement Details
						</Button>
					</td>
				</tr>
			),
			[ openResultSetModal, openSettlmentDetailsModal, reopenSettlement ]
		);

		return eventMarkets.map(mapEventMarkets);
	}
);

SettlementEventMarkets.propTypes = {
	tableData: PropTypes.array,
	viewOutcomes: PropTypes.func
};

export default SettlementEventMarkets;
