import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashbord from '../../Moduls/Dashbord';
import CCSSTVMerger from './CCSSTVMerger';
import { store } from './CCSSTVMergerUtils';

const CCSSTVMergerModule = ({ match }) => {
  return (
    <main className="efb-main">
      <section className="efb-container">
        <Switch>
          <Route
            path={`${match.path}/:module`}
            render={props => <CCSSTVMerger {...props} />}
          />
          <Route
            path={`${match.path}/:moduleId`}
            render={() => <Dashbord items={store.dashboard} />}
          />
          <Route
            path={match.path}
            render={() => <Dashbord items={store.dashboard} />}
          />
        </Switch>
      </section>
    </main>
  );
};

export default CCSSTVMergerModule;