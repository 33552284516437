import React, { memo } from 'react';

export const AmericanFootball = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={`AmericanFootball ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''} ${className ? className : ''}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141 139.91"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <path
          className={'Ball'}
          d="M108.49,106.85a113,113,0,0,1-38,24.74,120.59,120.59,0,0,1-20.56,6.17c-20.26,4.14-37.57,2-43.54-4C.09,127.51-2,110.2,2.19,90A119.87,119.87,0,0,1,8.46,69.57,114.79,114.79,0,0,1,33.34,31.69C45.1,19.94,59.27,12.08,73.3,7.13A126.71,126.71,0,0,1,94.55,1.64c20-3.35,36.62-1.19,40.9,3.1,5.2,5.2,7.37,21.81,3.79,41.59a123.76,123.76,0,0,1-5.76,21A110.42,110.42,0,0,1,108.49,106.85Z"
        />
        <path d="M133.47,67.3,73.3,7.13A126.71,126.71,0,0,1,94.55,1.64l44.69,44.69A123.76,123.76,0,0,1,133.47,67.3Z" />
        <path d="M70.49,131.59a120.59,120.59,0,0,1-20.56,6.17L2.19,90A119.87,119.87,0,0,1,8.46,69.57Z" />
        <path d="M96.42,59.86l-5.36-5.36L92.59,53a3.88,3.88,0,1,0-5.48-5.49L85.57,49,80.2,43.64a3.88,3.88,0,1,0-5.48,5.49l5.36,5.36-4.32,4.32L70.4,53.45a3.88,3.88,0,0,0-5.49,5.49l5.37,5.36L66,68.62l-5.36-5.36a3.88,3.88,0,1,0-5.49,5.49l5.36,5.36-4.32,4.32-5.37-5.36a3.88,3.88,0,0,0-5.49,5.49l5.37,5.36-1.53,1.53a3.88,3.88,0,1,0,5.49,5.49l1.53-1.53,5.36,5.36A3.88,3.88,0,0,0,67,89.28l-5.37-5.36L66,79.6,71.31,85a3.88,3.88,0,0,0,5.49-5.49l-5.36-5.36,4.32-4.32,5.37,5.36a3.88,3.88,0,1,0,5.49-5.49L81.25,64.3,85.57,60l5.36,5.36a3.88,3.88,0,0,0,5.49-5.49Z" />
      </g>
    </svg>
  );
};

export default memo(AmericanFootball);
