import React from 'react';
import Button from '../../../../components/Layout/Button';
import PropTypes from 'prop-types';

const BetslipModalActionBtns = ({
  isRegularBetslip,
  potWin,
  currentSystem,
  setBetStatus,
  referralType,
  stakeChanges,
  oddChanges,
  setBetslipStatus,
  betslip,
  sendReoffer,
}) => {
  return (
    <div className="referral-modal-btns-wrapper">
      <div className="form-row modal-table-action-btns">
        {!isRegularBetslip ? (
          <div className="potential-winning">
            <p>Total Pot. Winning: {Number(potWin / 100).toFixed(2)}</p>
          </div>
        ) : null}
        {referralType === 'BETSLIP_REFFERAL_WITH_REOFFER' ? (
          <>
            <Button
              onClick={() =>
                setBetStatus(betslip.betslipId, currentSystem, false)
              }
              cssClass="mr-1 ml-1 bet-scroller-btn ok-btn btn no-width"
            >
              Accept System stake
            </Button>
            <Button
              onClick={() =>
                setBetStatus(betslip.betslipId, currentSystem, true)
              }
              cssClass="mr-1 ml-1 bet-scroller-btn delete-btn btn no-width"
            >
              Reject system stake
            </Button>
          </>
        ) : null}
        {stakeChanges.length === 0 &&
        Object.keys(oddChanges).length === 0 &&
        referralType !== 'BETSLIP_REFFERAL_WITH_REOFFER' ? (
          <Button
            onClick={e => {
              e.stopPropagation();
              setBetslipStatus(betslip.betslipId, 'ACCEPTED_AFTER_REFERRAL');
            }}
            cssClass="mr-1 ml-1 bet-scroller-btn ok-btn btn"
          >
            Accept
          </Button>
        ) : null}

        {stakeChanges.length !== 0 ||
        Object.keys(oddChanges).length > 0 ||
        referralType === 'BETSLIP_REFFERAL_WITH_REOFFER' ? (
          <Button
            cssClass="mr-1 ml-1 bet-scroller-btn cancel-btn btn"
            disabled={!(stakeChanges.length || Object.keys(oddChanges).length)}
            onClick={sendReoffer}
          >
            Reoffer
          </Button>
        ) : null}

        <Button
          onClick={e => {
            e.stopPropagation();
            setBetslipStatus(betslip.betslipId, 'CANCEL_AFTER_REFERRAL');
          }}
          cssClass="mr-1 ml-1 bet-scroller-btn cancel-btn btn"
        >
          Reject
        </Button>
      </div>
    </div>
  );
};

BetslipModalActionBtns.propTypes = {
  isRegularBetslip: PropTypes.bool,
  potWin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentSystem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setBetStatus: PropTypes.func,
  referralType: PropTypes.string,
  stakeChanges: PropTypes.array,
  oddChanges: PropTypes.object,
  setBetslipStatus: PropTypes.func,
  betslip: PropTypes.object,
  sendReoffer: PropTypes.func,
};

export default BetslipModalActionBtns;
