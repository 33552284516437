import React, { useMemo } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { tinyMceApiKey } from '../../../../../config/constants';

const TextEditor = ({ text, htmlBody, placeholders }) => {
  const placeholdersList = useMemo(() => {
    return placeholders
      ? placeholders.map(placeholder => {
          return { value: placeholder, title: placeholder };
        })
      : [];
  }, [placeholders]);

  return (
    <Editor
      value={text}
      apiKey={tinyMceApiKey}
      name="text"
      init={{
        plugins: 'link image code fullscreen',
        toolbar:
          'formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment fullscreen',
        min_height: 300,
        link_list: placeholdersList,
      }}
      onEditorChange={htmlBody}
    />
  );
};

export default TextEditor;
