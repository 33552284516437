import React from 'react';
import classes from './BetHistory.module.scss';
import { getSvgFlag } from '../../../utils/flags';
import { useFetch } from '../../../hooks';

const Header = ({ customerId, title }) => {
  const { data: customer } = useFetch(
    customerId ? `customer/view/admin/${customerId}` : null
  );
  return (
    <header>
      <div className={classes.title}>{title}</div>
      {customerId && customer ? (
        <>
          <div className={classes.fullName}>
            <b>
              {customer.gender === 'MALE' ? 'Mr.' : 'Mrs.'}{' '}
              {`${customer.lastName}, ${customer.firstName}`}
            </b>
          </div>
          <div className={classes.countryInfo}>
            <img
              src={getSvgFlag(customer ? customer.country.countryCode : 'bg')}
              className={classes.countryIcon}
              alt="flag"
            />
            <span className={classes.currency}>
              {customer
                ? customer.accounts.find(x => x.accountType === 'MAIN')
                    .currencyCode
                : 'EUR'}
            </span>
          </div>
          <div className={classes.username}>
            <b>[ {customer.publicId} ]</b>
          </div>
        </>
      ) : null}
    </header>
  );
};

export default React.memo(Header);
