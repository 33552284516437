export const sportTemplate = {
  categoryId: '',
  categoryName: '',
  code: '',
  id: '',
  view: {},
  riskControlParameters: [],
  typeId: '',
  typeName: ''
};

export const riskValueTransform = (riskControlParameters, type) => {
  let control;

  riskControlParameters.forEach(element => {
    if (element.code === type) {
      control = element.value;
    }
  });

  if (typeof control === 'undefined' || control === null) {
    control = '';
  }

  return control;
};

export const uniqueSportType = (array, type) => {
  const result = [];
  const map = new Map();
  for (const item of array) {
    if (!map.has(item[type])) {
      map.set(item[type], true); // set any value to Map
      result.push({
        ...item
      });
    }
  }

  return result;
};
