import axiosInstance from '../axios-instance';
import { createRequestTypes, actionCreator } from '../index';
import { addNotificationMessage } from '../ui';

export const GET_ALL_GAMES = createRequestTypes('GET_ALL_GAMES');
export const GAME_PROVIDERS = createRequestTypes('GAME_PROVIDERS');
export const IMAGE_UPLOAD = createRequestTypes('IMAGE_UPLOAD');
export const GAME_UPDATE = createRequestTypes('GAME_UPDATE');

export const getAllGames = bu => async dispatch => {
  dispatch(actionCreator.request(GET_ALL_GAMES));
  try {
    const res = await axiosInstance.get(
      `/casino-backoffice/admin/games?businessUnit=${bu}`
    );
    dispatch(actionCreator.success(GET_ALL_GAMES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_ALL_GAMES, err.message));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const createGame = (casinoGame, editMode, lang) => async dispatch => {
  dispatch(actionCreator.request(GAME_UPDATE));
  try {
    await axiosInstance.post('/casino-backoffice/admin/game', casinoGame);
    dispatch(
      addNotificationMessage(
        `You have successfully ${editMode ? 'updated' : 'created'} game`,
        'success',
        'Success'
      )
    );
    dispatch(actionCreator.success(GAME_UPDATE, ''));
    dispatch(getAllGames(lang));
    return true;
  } catch (err) {
    dispatch(actionCreator.failure(GAME_UPDATE, ''));
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const deleteGame = (gameId, lang) => async dispatch => {
  try {
    await axiosInstance.delete(`/casino-backoffice/admin/game/${gameId}`);
    dispatch(
      addNotificationMessage(
        'You have successfully deleted game',
        'success',
        'Success'
      )
    );
    dispatch(getAllGames(lang));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const uploadImage = (
  image,
  platform,
  imageSize,
  lang
) => async dispatch => {
  dispatch(actionCreator.request(IMAGE_UPLOAD));
  try {
    await axiosInstance.post('/content/admin/image/upload', image);
    dispatch(
      addNotificationMessage(
        `You have successfully uploaded ${
          platform === 'webImage' ? 'web' : 'mobile'
        } ${imageSize} image`,
        'success',
        'Success'
      )
    );
    dispatch(actionCreator.success(IMAGE_UPLOAD, ''));
    dispatch(getAllGames(lang));
  } catch (err) {
    dispatch(actionCreator.failure(IMAGE_UPLOAD, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateGame = (updatedGame, lang) => async dispatch => {
  try {
    await axiosInstance.post('/casino-backoffice/admin/game', updatedGame);
    dispatch(
      addNotificationMessage(
        'You have successfully updated game',
        'success',
        'Success'
      )
    );
    dispatch(getAllGames(lang));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateRank = updatedGame => async dispatch => {
  dispatch(actionCreator.request(GAME_UPDATE));
  try {
    await axiosInstance.post('/casino-backoffice/admin/game', updatedGame);
    dispatch(
      addNotificationMessage(
        'You have successfully updated game rank',
        'success',
        'Success'
      )
    );
    dispatch(actionCreator.success(GAME_UPDATE, ''));
  } catch (err) {
    dispatch(actionCreator.failure(GAME_UPDATE, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getGameProviders = () => async dispatch => {
  try {
    const res = await axiosInstance.get(
      '/casino-backoffice/public/casino-provider'
    );
    dispatch(actionCreator.success(GAME_PROVIDERS, res));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const deleteImage = (id, gameBu) => async dispatch => {
  try {
    await axiosInstance.delete(`/content/admin/image/${id}`);
    dispatch(
      addNotificationMessage(
        'You have successfully deleted image from this game',
        'success',
        'Success'
      )
    );
    dispatch(getAllGames(gameBu));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};
