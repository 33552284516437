import React, { useMemo, useCallback, useState } from 'react';
import InputField from '../../../../../components/Form/InputField';
import Button from '../../../../../components/Layout/Button';
import {
  useStateForInput,
  useStateForSwitch,
  useUpdate,
  useStateForSelect,
} from '../../../../../hooks';
import classes from './Mail.module.scss';
import Select from 'react-select';
import { selectStyles } from '../../../../CMS/customReactSelectStyle';
import Switch from '../../../../../components/Form/Switch/Switch';
import Textarea from '../../../../../components/Form/Textarea/Textarea';
import UserFinder from './UserFinder';
import Alert from '../../../../../components/Layout/Alert/Alert';

const MailForm = ({
  names,
  getListByName,
  templates,
  sendMessage,
  getCustomersWithFilters,
  customers,
  emptyCustomers,
  getCustomersWithDebounce,
}) => {
  const [subject, setSubject] = useStateForInput('');
  const [emailSwitch, setEmailSwitch] = useStateForSwitch(true);
  const [internalSwitch, setInternalSwitch] = useStateForSwitch(false);
  const [body, setBody] = useStateForInput('');
  const [templateName, setTemplateName] = useStateForSelect('');
  const [activeUserFinderModal, setActiveUserFinderModal] = useState(false);
  const [user, setUser] = useState('');

  useUpdate(() => {
    if (emailSwitch && internalSwitch) setInternalSwitch(false);
  }, [emailSwitch]);

  useUpdate(() => {
    if (internalSwitch && emailSwitch) setEmailSwitch(false);
  }, [internalSwitch]);

  useUpdate(() => {
    if (templateName) getListByName(templateName);
  }, [templateName]);

  const namesList = useMemo(() => {
    return names
      ? names.map(name => {
          return {
            label: name,
            value: name,
          };
        })
      : [];
  }, [names]);

  const send = useCallback(() => {
    sendMessage({
      body: body,
      channel: emailSwitch ? 'EMAIL' : 'INTERNAL',
      receiverUserId: user.user.id,
      subject: subject,
      templateName: templateName.value,
    });
    setBody('');
    setSubject('');
    setTemplateName('');
  }, [
    body,
    emailSwitch,
    sendMessage,
    setBody,
    setSubject,
    setTemplateName,
    subject,
    templateName.value,
    user,
  ]);

  return (
    <>
      <h3>{emailSwitch ? 'E-mail' : 'Internal message'}</h3>
      <hr />
      <UserFinder
        getCustomersWithFilters={getCustomersWithFilters}
        activeUserFinderModal={activeUserFinderModal}
        customers={customers}
        closeModal={() => setActiveUserFinderModal(false)}
        customersLoaded
        setUser={setUser}
        emptyCustomers={emptyCustomers}
        getCustomersWithDebounce={getCustomersWithDebounce}
      />
      <div className={`efb-form ${classes.usernameInputWrapper}`}>
        <InputField
          placeholder="Username"
          disabled
          value={user ? user.user.username : ''}
        />{' '}
        <Button
          cssClass={'efb-btn__main'}
          onClick={() => setActiveUserFinderModal(true)}
        >
          Select User
        </Button>
        <div>
          <InputField
            placeholder="Subject"
            onChange={setSubject}
            value={subject}
          />
        </div>
      </div>
      <br />

      <Switch
        label="Email"
        isChecked={emailSwitch}
        value={emailSwitch}
        onChange={setEmailSwitch}
      />
      <Switch
        label="Internal"
        isChecked={internalSwitch}
        value={internalSwitch}
        onChange={setInternalSwitch}
      />

      {emailSwitch ? (
        <>
          <br />
          <br />
          <Select
            onChange={setTemplateName}
            value={templateName}
            options={namesList}
            styles={selectStyles}
            placeholder="Select Template"
          />
          {templateName && templates.length === 0 ? (
            <Alert type="danger">
              <i className="fa fa-exclamation-triangle"></i>There is no
              templates by this name.
            </Alert>
          ) : null}
        </>
      ) : null}

      <br />
      {internalSwitch ? (
        <div className="efb-form">
          <br />
          <Textarea
            className={classes.textarea}
            value={body}
            placeholder="Internal message body"
            onChange={setBody}
            rows={'10'}
          />
        </div>
      ) : null}
      <br />
      <Button
        cssClass="efb-btn__main"
        onClick={send}
        disabled={user.length === 0}
      >
        Send
      </Button>
    </>
  );
};

export default MailForm;
