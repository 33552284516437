//errorReducer.js
export const errorReducer = (state = {}, action) => {
  const { type, payload, error } = action,
    matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving FAILURE
    //      else clear errorMessage when receiving REQUEST
    [requestName]:
      requestState === 'FAILURE' ? (payload ? payload.message : error) : ''
  };
};
