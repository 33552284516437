import React, { useCallback } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import * as EventsManagerActions from '../../../redux/actions/cms/eventsManager';
import * as UiActions from '../../../redux/actions/ui';
import classes from './DetailsModal.module.scss';
import Input from '../../Form/Input';
import Modal from '../../NewModal/Modal';
import Button from '../../Layout/Button';
import IconPen from '../../Icons/UI/ui/IconPen';
import { useUpdate, useFetch, useCounter } from '../../../hooks';

const EventDetailsModal = ({
  onClose,
  event,
  createSportEventConfig,
  updateSportEventConfig,
  addNotificationMessage,
}) => {
  const {
    value: adjustment,
    setValue: setAdjustment,
    increase: increaseAdjustment,
    decrease: decreaseAdjustment,
  } = useCounter(0);

  const { data: homeCompetitor } = useFetch(
    `/sport/event/view/competitor/admin/${event.homeCompetitorId}`
  );
  const { data: awayCompetitor } = useFetch(
    `/sport/event/view/competitor/admin/${event.awayCompetitorId}`
  );
  const { data: sportEventConfig } = useFetch(
    `/riskmgnt/view/admin/sportEvents/search/findBySportEventId`,
    {
      sportEventId: event.id,
    }
  );

  useUpdate(() => {
    if (sportEventConfig) {
      setAdjustment(sportEventConfig.adjustment);
    }
  }, [sportEventConfig]);

  const saveChanges = useCallback(() => {
    if (!sportEventConfig) {
      createSportEventConfig({
        adjustment: Number(adjustment),
        sportEventId: event.id,
      });
    } else if (Number(adjustment) !== Number(sportEventConfig.adjustment)) {
      updateSportEventConfig({
        id: sportEventConfig.id,
        adjustment: Number(adjustment),
        sportEventId: event.id,
      });
    }

    addNotificationMessage('Changes saved!', 'success', 'Success!');
    onClose();
  }, [
    addNotificationMessage,
    adjustment,
    createSportEventConfig,
    event.id,
    onClose,
    sportEventConfig,
    updateSportEventConfig,
  ]);

  if (!homeCompetitor || !awayCompetitor) {
    return null;
  }

  return (
    <Modal isActive={true} onClose={onClose}>
      <div className="p-3">
        <div className={classes.header}>
          <p>
            Event Edit: <strong>{event.names.en.name}</strong>
          </p>
          <p className="mx-3">
            [
            {event.feedProducers
              .map(producer => producer.feedProviderId)
              .join(', ')}
            ]
          </p>
        </div>

        <div className={classes.info}>
          <div>
            <div>Home:</div>
            <div>{homeCompetitor.names.en.name}</div>
          </div>

          <div>
            <div>Away:</div>
            <div>{awayCompetitor.names.en.name}</div>
          </div>

          <div>
            <div className="py-5">Scheduled Start:</div>
            <div>
              {moment(event.scheduledTime).format('DD/MM/YYYY')}
              <IconPen width={12} />{' '}
              {moment(event.scheduledTime).format('HH:mm')}
              <IconPen width={12} />{' '}
            </div>
          </div>

          <div>
            <div>Risk Adjustment:</div>
            <div>
              <div className={classes.numberInput}>
                <button onClick={increaseAdjustment}>+</button>
                <Input
                  customInput
                  type="number"
                  value={adjustment}
                  onChange={setAdjustment}
                />
                <button onClick={decreaseAdjustment}>-</button>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.buttons}>
          <Button onClick={saveChanges} cssClass="btn btn-success">
            OK
          </Button>
          <Button cssClass="btn btn-secondary">Cancel</Button>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  createSportEventConfig: EventsManagerActions.createSportEventConfig,
  updateSportEventConfig: EventsManagerActions.updateSportEventConfig,
  addNotificationMessage: UiActions.addNotificationMessage,
};

export default connect(null, mapDispatchToProps)(EventDetailsModal);
