import React from 'react';

import classes from './List.module.scss';

const List = ({ items, onChange, currentItem }) => (
  <div
    className="list-group border h-100 w-100"
    style={{ overflow: 'auto' }}
  >
    {items.map(item => (
      <div
        key={`item-${item.label}-${item.value.id}`}
        onClick={() => onChange(item)}
        className={`list-group-item ${currentItem && currentItem.value === item.value ? classes.active : ''}`}
      >
        {item.label}
      </div>
    ))}
  </div>
);

export default List;