import {
  GET_MARKET_CATEGORIES,
  GET_MARKET_TYPES,
  GET_OUTCOMES,
  GET_ALL_MARKET_TYPES,
  GET_ADMIN_MARKET_TYPE,
} from '../../actions/cms/marketCategories';

const initialState = {
  categories: [],
  allMarketTypes: [],
  marketTypes: [],
  outcomes: [],
  marketType: ''
};

export const marketCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MARKET_CATEGORIES.SUCCESS:
      return {
        ...state,
        categories: action.payload
      };

    case GET_MARKET_TYPES.SUCCESS:
      return {
        ...state,
        marketTypes: action.payload
      };

    case GET_OUTCOMES.SUCCESS:
      return {
        ...state,
        outcomes: action.payload
      };

    case GET_ALL_MARKET_TYPES.SUCCESS:
      return {
        ...state,
        allMarketTypes: action.payload
      };

    case GET_ADMIN_MARKET_TYPE.SUCCESS:
      return {
        ...state,
        marketType: action.payload
      };

    default:
      return state;
  }
};
