import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import * as StsActions from '../../../../redux/actions/cms/stsBuilder';
import StsTable from '../StsTable';
import LanguageSelector from '../../../../components/LanguageSelector/LanguageSelector';
import BusinessUnitSelector from '../../../../components/BusinessUnitSelector/BusinessUnitSelector';
import InputField from '../../../../components/Form/InputField';
import Button from '../../../../components/Layout/Button';
import {
  useStateForInput,
  useUpdate,
  useStateForSelect,
} from '../../../../hooks';
import { handleSort } from '../StsUtils';
import { getStsBuilderData } from '../../../../selectors';

const CategoriesTable = ({
  sportId,
  languages,
  categories,
  editCategory,
  editingCategory,
  updateCategory,
  updateRanking,
}) => {
  const [search, setSearch, resetSearch] = useStateForInput('');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [newRanking, setNewRanking] = useState(false);
  const [selectedBu, setSelectedBu] = useStateForSelect('BG');

  const rankedCategories = useMemo(() => handleSort(categories, selectedBu), [
    categories,
    selectedBu,
  ]);

  const [categoriesForTable, setCategoriesForTable] = useState(
    rankedCategories
  );

  useUpdate(() => {
    setCategoriesForTable(
      rankedCategories.filter(
        category =>
          category.names[selectedLanguage].name
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
      )
    );
  }, [search]);

  useUpdate(() => {
    setCategoriesForTable(rankedCategories);
    resetSearch();
  }, [rankedCategories]);

  const onDrop = useCallback(data => {
    setCategoriesForTable(data);
    setNewRanking(true);
  }, []);

  const updateStatus = useCallback(
    (category, status) => updateCategory({ ...category, status }, sportId),
    [sportId, updateCategory]
  );

  const submitRanking = useCallback(() => {
    updateRanking(
      categoriesForTable.map((category, index) => ({
        id: category.id,
        businessUnit: selectedBu,
        rank: index,
        type: 'CATEGORY',
      }))
    );
    setNewRanking(false);
  }, [categoriesForTable, selectedBu, updateRanking]);

  const resetRanking = useCallback(() => {
    setCategoriesForTable(rankedCategories);
    setNewRanking(false);
  }, [rankedCategories]);

  return (
    <>
      <LanguageSelector
        languages={languages}
        defaultLang={selectedLanguage}
        onChange={setSelectedLanguage}
      />
      <br />
      <BusinessUnitSelector onChange={setSelectedBu} defaultBu={selectedBu} />

      <div className="efb-form my-3">
        <InputField
          id="search"
          name="search"
          placeholder="Search"
          type="text"
          className="form-control"
          value={search}
          onChange={setSearch}
        />
      </div>

      {newRanking ? (
        <div className="my-3">
          <Button onClick={submitRanking} cssClass="btn btn-primary">
            Save Rankings
          </Button>
          <Button onClick={resetRanking} cssClass="btn btn-danger">
            Reset Rankings
          </Button>
        </div>
      ) : null}

      <StsTable
        data={categoriesForTable}
        updateStatus={updateStatus}
        onDrop={onDrop}
        editSts={editCategory}
        editingSts={editingCategory}
      />
    </>
  );
};

const mapStateToProps = state => ({
  categories: getStsBuilderData(state).categories,
});

const mapDispatchToProps = {
  updateCategory: StsActions.updateCategory,
  updateRanking: StsActions.updateRank,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesTable);
