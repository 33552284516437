import React, { useState, useCallback, useMemo } from 'react';
import Button from '../../../../components/Layout/Button';
import ContentType from './ContentType/ContentType';
import Sections from './ContentType/Sections';
import Regular from './ContentType/Regular';
import Rules from './ContentType/Rules';
import List from './ContentType/List';
import InputField from '../../../../components/Form/InputField';
import PropTypes from 'prop-types';
import Switch from '../../../../components/Form/Switch/Switch';
import { useUpdate } from '../../../../hooks';

const SubpageContentCreator = ({
  subpage,
  pageObj,
  prepareSubpath,
  index,
  currentLang,
  updateObj,
  removeSubpage,
  images,
  uploadImage,
  subpageContent,
  editMode,
  subpageAssignContentMode,
  availableCountries,
  currencies,
  serverError,
  contentType,
  updateContentTypeValue,
}) => {
  const [pageType, setPageType] = useState('');
  const [staticPageObj, setStaticPageObj] = useState({});
  const [subpageObj, setSubpageObj] = useState({});
  const [countryCurrencySubPath, setCountryCurrencySubpath] = useState(true);

  useUpdate(() => {
    setSubpageObj(subpage);
  }, [subpage]);

  useUpdate(() => {
    setStaticPageObj(pageObj);
  }, [pageObj]);

  useUpdate(() => {
    setPageType(contentType);
  }, [contentType]);

  const setSubpath = useCallback(
    e => {
      const { checked } = e.target;
      setCountryCurrencySubpath(checked);
      prepareSubpath(checked);
    },
    [prepareSubpath]
  );

  const handleInputChange = useCallback(
    e => {
      const { name, value, checked } = e.target;
      const pageObjClone = { ...staticPageObj };
      if (name === 'enabled') {
        pageObjClone.subpages[index].content[0][
          currentLang.value
        ].enabled = checked;
      } else {
        pageObjClone.subpages[index][name] = value;
      }
      updateObj(pageObjClone);
    },
    [currentLang.value, index, staticPageObj, updateObj]
  );

  const pageContent = useCallback(
    e => {
      const contentType = e;
      const subpageClone = { ...subpageObj };
      if (contentType.value === 'TABLES') {
        subpageClone.pageType = 'ROOT';
      } else {
        subpageClone.pageType = contentType.value;
      }
      updateContentTypeValue(contentType.value, index, countryCurrencySubPath);
      setSubpageObj(subpageClone);
      setPageType(contentType.value);
    },
    [countryCurrencySubPath, index, subpageObj, updateContentTypeValue]
  );

  const content = useMemo(() => {
    switch (subpage.pageType) {
      case 'SECTIONS':
        return (
          <Sections
            pageObj={pageObj}
            subpage={subpage}
            subpageContent={subpageContent}
            index={index}
            editMode={editMode}
            currentLang={currentLang}
            images={images}
            updateObj={updateObj}
            uploadImage={uploadImage}
          />
        );

      case 'REGULAR':
        return (
          <Regular
            updateObj={updateObj}
            pageObj={pageObj}
            subpage={subpage}
            index={index}
            images={images}
            currentLang={currentLang}
            uploadImage={uploadImage}
          />
        );

      case 'RULES':
        return (
          <Rules
            updateObj={updateObj}
            pageObj={pageObj}
            subpage={subpage}
            index={index}
            editMode={editMode}
            images={images}
            currentLang={currentLang}
            uploadImage={uploadImage}
          />
        );

      case 'ROOT':
      case 'TABLES':
        return (
          <List
            currencies={currencies}
            pageObj={pageObj}
            subpage={subpage}
            index={index}
            updateObj={updateObj}
            currentLang={currentLang}
            availableCountries={availableCountries}
            countryCurrencySubPath={countryCurrencySubPath}
          />
        );

      default:
        return '';
    }
  }, [
    availableCountries,
    countryCurrencySubPath,
    currencies,
    currentLang,
    editMode,
    images,
    index,
    pageObj,
    subpage,
    subpageContent,
    updateObj,
    uploadImage,
  ]);

  return (
    <>
      {!subpageAssignContentMode ? (
        <>
          <div className="float-left">
            <h3 className="efb-h3">
              Subpage {index + 1}{' '}
              {subpage.name !== '' ? `- ${subpage.name}` : null}
            </h3>
          </div>

          <div className="form-group d-flex flex-row justify-content-end">
            <Button
              cssClass="btn btn-danger btn-md"
              onClick={() => removeSubpage(index)}
            >
              <i className="fa fa-trash"></i> Remove subpage {index + 1}
            </Button>
          </div>
        </>
      ) : null}
      <div className="efb-form">
        {!subpageAssignContentMode ? (
          <>
            <div className="form-row">
              <div className="form-label-group col-md">
                <InputField
                  value={subpage.name}
                  label={'Subpage name'}
                  name={'name'}
                  placeholder="Sport Name"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md">
                <Switch
                  label={'Enabled'}
                  name="enabled"
                  value={
                    subpage.content[0][currentLang.value]
                      ? subpage.content[0][currentLang.value].enabled
                      : ''
                  }
                  isChecked={
                    subpage.content[0][currentLang.value]
                      ? subpage.content[0][currentLang.value].enabled
                      : false
                  }
                  onChange={handleInputChange}
                />
                {pageType === 'TABLES' ? (
                  <Switch
                    label={'Country / Currency subpath'}
                    name="countryCurrencySubPath"
                    isChecked={countryCurrencySubPath}
                    value={countryCurrencySubPath}
                    onChange={setSubpath}
                  />
                ) : null}
              </div>
            </div>
            <div>
              <p>
                Form bellow is for {currentLang.label}{' '}
                <i
                  className={
                    currentLang.value === 'en'
                      ? 'flag-icon flag-icon-gb'
                      : `flag-icon flag-icon-${currentLang.value}`
                  }
                ></i>
              </p>
            </div>
          </>
        ) : null}
      </div>

      <div className="form-row">
        <div className="form-label-group col-md">
          <ContentType
            serverError={serverError}
            pageType={pageType}
            pageContent={pageContent}
            currentLang={currentLang}
          />
        </div>
      </div>

      <hr />
      {content}
    </>
  );
};
// }

SubpageContentCreator.propTypes = {
  cancelEdit: PropTypes.func,
  currentLang: PropTypes.object,
  index: PropTypes.number,
  pageObj: PropTypes.object,
  removeSection: PropTypes.func,
  removeSubpage: PropTypes.func,
  subpage: PropTypes.object,
  updateObj: PropTypes.func,
};

export default SubpageContentCreator;
