import axiosInstance from '../axios-instance';
import { actionCreator, createRequestTypes } from '../index';
import { addNotificationMessage } from '../ui';

export const ACCOUNT_HISTORY = createRequestTypes('ACCOUNT_HISTORY');
export const BET_TYPES = createRequestTypes('BET_TYPES');
export const COUNTRIES = createRequestTypes('COUNTRIES');

export const UPDATE_PASSWORD = createRequestTypes('UPDATE_PASSWORD');
export const GET_DOCUMENTS_FOR_CUSTOMER = createRequestTypes(
  'GET_DOCUMENTS_FOR_CUSTOMER'
);
export const GET_DOCUMENTS_FILE_FOR_CUSTOMER = createRequestTypes(
  'GET_DOCUMENTS_FILE_FOR_CUSTOMER'
);
export const GET_CUSTOMERS_BY_VERIFICATIONS = createRequestTypes(
  'GET_CUSTOMERS_BY_VERIFICATIONS'
);
export const UPDATE_CUSTOMER_DETAILS = createRequestTypes(
  'UPDATE_CUSTOMER_DETAILS'
);

export const getCustomerCitizenship = citizenshipId => async dispatch => {
  return (
    await axiosInstance.get('/customer/view/public/nationalities', {
      params: {
        locale: 'en',
      },
    })
  ).find(citizenship => citizenship.id.toString() === citizenshipId.toString());
};

export const editCustomerPreferences = data => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/customer/admin/preferences', data)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error.message);
      });
  });
};

export const updatePassword = data => async dispatch => {
  dispatch(actionCreator.request(UPDATE_PASSWORD));
  try {
    await axiosInstance.post('/customer/admin/password', data);
    dispatch(actionCreator.success(UPDATE_PASSWORD));
    dispatch(
      addNotificationMessage(
        'Successfully updated customer password!',
        'success',
        'Success!'
      )
    );
  } catch (err) {
    dispatch(actionCreator.failure(UPDATE_PASSWORD, err.message));
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(errorCode => {
        dispatch(
          addNotificationMessage(err.errors[errorCode], 'error', 'Error')
        );
      });
    }
  }
};

export const getCountries = () => async dispatch => {
  dispatch(actionCreator.request(COUNTRIES));
  try {
    const res = await axiosInstance.get('/customer/view/public/countries', {
      params: {
        locale: 'en',
      },
    });

    dispatch(actionCreator.success(COUNTRIES, res));
  } catch (err) {
    dispatch(actionCreator.failure(COUNTRIES, err.message));
    dispatch(addNotificationMessage(err.message, 'error', 'error'));
  }
};

export const getAccountHistory = (customerId, filters) => {
  return dispatch => {
    dispatch(actionCreator.request(ACCOUNT_HISTORY));
    axiosInstance
      .get(`/account/view/admin/account/history/${customerId}`, {
        params: filters,
      })
      .then(response => {
        return dispatch(actionCreator.success(ACCOUNT_HISTORY, response));
      })
      .catch(err => {
        dispatch(actionCreator.failure(ACCOUNT_HISTORY, err.message));
      });
  };
};

// CUSTOMER DOCUMENTS

export const getDocumentsFileForCustomer = customerId => async dispatch => {
  dispatch(actionCreator.request(GET_DOCUMENTS_FILE_FOR_CUSTOMER));
  try {
    const res = await axiosInstance.get(
      `/customer/view/admin/${customerId}/documents-file`
    );
    dispatch(actionCreator.success(GET_DOCUMENTS_FILE_FOR_CUSTOMER, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_DOCUMENTS_FILE_FOR_CUSTOMER, error));
  }
};

export const getDocumentsForCustomer = customerId => async dispatch => {
  dispatch(actionCreator.request(GET_DOCUMENTS_FOR_CUSTOMER));
  try {
    const res = await axiosInstance.get(
      `/customer/view/admin/${customerId}/documents`
    );
    dispatch(actionCreator.success(GET_DOCUMENTS_FOR_CUSTOMER, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_DOCUMENTS_FOR_CUSTOMER, error));
  }
};

export const uploadAdminVerification = customerData => async dispatch => {
  try {
    const { file, customerId } = customerData;
    const formData = new FormData();
    formData.append('file', file);
    const res = await axiosInstance.post(
      `/customer/admin/${customerId}/document?documentType=ADMIN_VERIFICATION`,
      formData
    );
    dispatch(actionCreator.success(GET_DOCUMENTS_FOR_CUSTOMER, res));
    dispatch(getDocumentsFileForCustomer(customerData.customerId));
    dispatch(
      addNotificationMessage(
        'Admin verification is uploaded!',
        'success',
        'Success'
      )
    );
  } catch (error) {
    dispatch(
      addNotificationMessage(
        'Admin verification is not uploaded!',
        'error',
        'Error'
      )
    );
    dispatch(actionCreator.failure(GET_DOCUMENTS_FOR_CUSTOMER));
  }
};

// BET HISTORY

export const getBetTypes = () => {
  return dispatch => {
    axiosInstance
      .get('/betslip/view/admin/system-types')
      .then(response => {
        dispatch(actionCreator.success(BET_TYPES, response));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

// VERIFICATION TAB
export const getCustomersByVerification = filters => async dispatch => {
  dispatch(actionCreator.request(GET_CUSTOMERS_BY_VERIFICATIONS));
  try {
    const res = await axiosInstance.post(
      '/customer/view/admin/verification',
      filters
    );
    dispatch(actionCreator.success(GET_CUSTOMERS_BY_VERIFICATIONS, res));
  } catch (err) {
    dispatch(
      actionCreator.failure(GET_CUSTOMERS_BY_VERIFICATIONS, err.message)
    );
  }
};

export const updateCustomerDetails = data => async dispatch => {
  dispatch(actionCreator.request(UPDATE_CUSTOMER_DETAILS));
  try {
    const res = await axiosInstance.post('/customer/admin/details', data);
    dispatch(
      addNotificationMessage(
        `Successfully ${
          data.additionalVerification ? 'added' : 'removed'
        } customer additional verification!`,
        'success',
        'Success'
      )
    );
    dispatch(actionCreator.success(UPDATE_CUSTOMER_DETAILS, res));
  } catch (err) {
    dispatch(actionCreator.failure(UPDATE_CUSTOMER_DETAILS, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateCustomerMessageStatus = (
  messageId,
  status
) => async dispatch => {
  try {
    await axiosInstance.put(
      `/message/service/messages/${messageId}`,
      `"${status}"`
    );
    dispatch(
      addNotificationMessage(
        'Successfully changed message status',
        'success',
        'Success'
      )
    );
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const resendActivationEmail = userId => async dispatch => {
  try {
    await axiosInstance.get(
      `/usermgnt/view/user/admin/activationMail/${userId}`
    );
    dispatch(
      addNotificationMessage(
        'You have successfully resent activation email to this user.',
        'success',
        'Success'
      )
    );
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const activateManuallyEmail = customerId => async dispatch => {
  try {
    await axiosInstance.post(`/customer/view/admin/${customerId}/activate`);
    dispatch(
      addNotificationMessage(
        'You have successfully activated account.',
        'success',
        'Success'
      )
    );
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};
