import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getMessageTemplatesData,
  createLoadingSelector,
  getCustomerData,
} from '../../../../selectors';
import { useMount } from '../../../../hooks';
import { debounce } from 'lodash';

import * as CustomerManagerActions from '../../../../redux/actions/customer/customerManager';
import * as MessageTemplateActions from '../../../../redux/actions/messageTemplates';
import * as LanguagesActions from '../../../../redux/actions/Languages';
import * as BonusSystemActions from '../../../../redux/actions/BonusSystem';

import MessageTemplatesForm from './Templates/Form/MessageTemplateForm';
import FullScreenLoader from '../../../../components/Layout/FullScreenLoader/FullScreenLoader';
import MailForm from './Mail/MailForm';

const Messages = ({
  createMessageTemplate,
  getAllLanguages,
  getBusinessUnits,
  getNames,
  names,
  getListByName,
  templates,
  getAllPlaceholders,
  placeholders,
  sendMessage,
  loaded,
  customers,
  getCustomersWithFilters,
  customersLoaded,
  emptyCustomers,
}) => {
  const [templateForEdit, setTemplateForEdit] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [currentTab, setCurrentTab] = useState('templates');

  useMount(() => {
    getAllLanguages();
    getBusinessUnits();
    getAllPlaceholders();
    getNames();
  });

  const getCustomersWithDebounce = useCallback(
    debounce(username => {
      emptyCustomers();
      if (username.length > 1) {
        getCustomersWithFilters({ username }, 1);
      } else {
        getCustomersWithFilters(null, 1);
      }
    }, 1000),
    []
  );

  return (
    <>
      <FullScreenLoader isActive={loaded} />
      <ul className="nav nav-tabs nav-fill" id="searchController">
        <li className="nav-item" onClick={() => setCurrentTab('templates')}>
          <span
            className={
              currentTab === 'templates' ? 'nav-link active' : 'nav-link'
            }
          >
            Templates
          </span>
        </li>
        <li className="nav-item" onClick={() => setCurrentTab('mail')}>
          <span
            className={currentTab === 'mail' ? 'nav-link active' : 'nav-link'}
          >
            E-mail / Internal message
          </span>
        </li>
      </ul>
      <br />

      {currentTab === 'templates' ? (
        <MessageTemplatesForm
          names={names}
          createMessageTemplate={createMessageTemplate}
          templateForEdit={templateForEdit}
          editMode={editMode}
          setEditMode={setEditMode}
          setTemplateForEdit={setTemplateForEdit}
          placeholders={placeholders}
          getListByName={getListByName}
          templates={templates}
        />
      ) : null}

      {currentTab === 'mail' ? (
        <MailForm
          names={names}
          templates={templates}
          getListByName={getListByName}
          sendMessage={sendMessage}
          getCustomersWithFilters={getCustomersWithFilters}
          customers={customers}
          customersLoaded={customersLoaded}
          emptyCustomers={emptyCustomers}
          getCustomersWithDebounce={getCustomersWithDebounce}
        />
      ) : null}
    </>
  );
};

const loadingReducer = createLoadingSelector(['GET_USER']);
const customersLoader = createLoadingSelector(['FILTERED_CUSTOMERS']);

const mapStateToProps = state => ({
  names: getMessageTemplatesData(state).names,
  templates: getMessageTemplatesData(state).templates,
  placeholders: getMessageTemplatesData(state).placeholders,
  loaded: loadingReducer(state),
  customersLoaded: customersLoader(state),
  customers: getCustomerData(state).customerManagerReducer.filteredCustomers,
});

const mapDispatchToProps = {
  createMessageTemplate: MessageTemplateActions.createMessageTemplate,
  getAllLanguages: LanguagesActions.getAllLanguages,
  getBusinessUnits: BonusSystemActions.getBusinessUnits,
  getNames: MessageTemplateActions.getNames,
  getListByName: MessageTemplateActions.getListByName,
  getAllPlaceholders: MessageTemplateActions.getAllPlaceholders,
  sendMessage: MessageTemplateActions.sendMessage,
  getCustomersWithFilters: CustomerManagerActions.getCustomersWithFilters,
  emptyCustomers: CustomerManagerActions.emptyCustomers,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Messages)
);
