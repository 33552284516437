import React from 'react';

export const Admin = (props) => {
    return (
	<span className='efb-icon'>
		<svg className={`efb-icon--${props.size} ${props.cssClass} efb-admin`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
			<path d="M36,18c0-9.9-8.1-18-18-18S0,8.1,0,18c0,5.2,2.3,10,5.8,13.3l0,0l0.6,0.5c0,0,0.1,0.1,0.1,0.1c0.3,0.3,0.6,0.5,1,0.7
			c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0.2,0.7,0.5,1.1,0.7c0.1,0,0.2,0.1,0.2,0.1c0.4,0.2,0.8,0.4,1.2,0.6c0,0,0.1,0,0.1,0
			c1.4,0.6,2.8,1.1,4.4,1.4c0,0,0.1,0,0.1,0c0.5,0.1,1,0.1,1.4,0.2c0.1,0,0.1,0,0.2,0c0.5,0,1,0.1,1.5,0.1c0.5,0,1,0,1.5-0.1
			c0.1,0,0.1,0,0.2,0c0.5,0,1-0.1,1.4-0.2c0,0,0.1,0,0.1,0c1.5-0.3,2.9-0.7,4.3-1.3c0,0,0.1,0,0.1-0.1c0.4-0.2,0.8-0.4,1.2-0.6
			c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.2,0.7-0.4,1-0.7c0.1-0.1,0.2-0.2,0.4-0.3c0.3-0.2,0.6-0.4,0.8-0.6c0.1,0,0.1-0.1,0.2-0.1l0.6-0.5
			l0,0C33.7,28,36,23.3,36,18z M1.3,18C1.3,8.8,8.8,1.3,18,1.3S34.7,8.8,34.7,18c0,5-2.2,9.4-5.6,12.5c-0.2-0.1-0.4-0.3-0.6-0.4
			l-5.5-2.8c-0.5-0.2-0.8-0.7-0.8-1.3v-1.9c0.1-0.2,0.3-0.3,0.4-0.5c0.7-1,1.3-2.1,1.7-3.4c0.8-0.4,1.4-1.2,1.4-2.2v-2.3
			c0-0.6-0.2-1.1-0.6-1.6v-3.1c0-0.3,0.2-2.3-1.2-3.8c-1.2-1.4-3.2-2.1-5.8-2.1s-4.6,0.7-5.8,2.1c-1.4,1.6-1.3,3.5-1.2,3.8v3.1
			c-0.4,0.4-0.6,1-0.6,1.6v2.3c0,0.7,0.3,1.4,0.9,1.8c0.5,2.1,1.6,3.6,2,4.2V26c0,0.5-0.3,1-0.8,1.3l-5.2,2.8
			c-0.2,0.1-0.3,0.2-0.5,0.3C3.5,27.4,1.3,22.9,1.3,18z"/>
		</svg>
		{props.children}
	</span>
    )
}

export default Admin;