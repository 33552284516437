import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Layout/Button';

const StickyNav = ({
  createNewSettings,
  disableEditMode,
  enableEditMode,
  submitSystemSettings,
  isSaveMode,
  isEditMode
}) => {
  return (
    <div className="container-client-id d-flex justify-content-between sticky-top">
      <div className="client-name">
        <h3>Betting Control</h3>
      </div>
      <div className="client-core-properties">
        {isEditMode ? (
          <Button
            cssClass={'btn btn-danger'}
            children={'Cancel'}
            onClick={disableEditMode}
          />
        ) : (
          <Fragment>
            <Button
              cssClass={'btn btn-primary m-2'}
              onClick={createNewSettings}
            >
              <i className="fas fa-plus" /> NEW
            </Button>
            <Button cssClass={'btn btn-primary m-2'} onClick={enableEditMode}>
              <i className="fas fa-edit" /> EDIT
            </Button>
          </Fragment>
        )}
        {isSaveMode && (
          <Button
            cssClass={
              isSaveMode
                ? 'btn btn-success pulse-button m-2'
                : 'btn btn-success m-2'
            }
            onClick={submitSystemSettings}
          >
            <i className="fas fa-save" /> SAVE SETTINGS
          </Button>
        )}
      </div>
    </div>
  );
};

StickyNav.propTypes = {
    createNewSettings: PropTypes.func, 
    disableEditMode: PropTypes.func, 
    enableEditMode: PropTypes.func, 
    submitSystemSettings: PropTypes.func, 
    isSaveMode: PropTypes.bool, 
    isEditMode: PropTypes.bool
};

export default StickyNav;
