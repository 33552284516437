import React from 'react';

const IconFolderGreenArrow = ({
  className,
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      className={className}
      viewBox="0 0 22.3 19.147" {...otherProps}>
      <g transform="translate(0 1.392)">
        <g transform="translate(0.3 0.603)">
          <rect
            width="9"
            height="5"
            rx="1"
            transform="translate(2 -0.324)"
            fill="#cf8808"
          />
          <rect
            width="20"
            height="15"
            rx="1"
            transform="translate(2 1.676)"
            fill="#cf8808"
          />
          <path
            d="M-.837,2h17.45a1.155,1.155,0,0,1,1.163,1.148l2.327,9.18a1.155,1.155,0,0,1-1.163,1.148H1.49A1.155,1.155,0,0,1,.327,12.328L-2,3.148A1.155,1.155,0,0,1-.837,2Z"
            transform="translate(1.7 3.676)"
            fill="#ffbe4a"
          />
        </g>
        <g transform="translate(6.116)">
          <path
            d="M10.852,2.481C7.349-2.486-1.768.318.3,8.107"
            transform="matrix(0.985, -0.174, 0.174, 0.985, 0.645, 1.884)"
            fill="none"
            stroke="#04ad10"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <g
            transform="translate(0.504 9.39) rotate(21)"
            fill="#fff"
            stroke="#04ad10"
            strokeWidth="1"
          >
            <rect
              width="3.517"
              height="1.407"
              rx="0.703"
              stroke="none"
            />
            <rect
              x="0.5"
              y="0.5"
              width="2.517"
              height="0.407"
              rx="0.203"
              fill="none"
            />
          </g>
          <g
            transform="translate(4.543 8.68) rotate(111)"
            fill="#fff"
            stroke="#04ad10"
            strokeWidth="1"
          >
            <rect
              width="3.517"
              height="1.407"
              rx="0.703"
              stroke="none"
            />
            <rect
              x="0.5"
              y="0.5"
              width="2.517"
              height="0.407"
              rx="0.203"
              fill="none"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconFolderGreenArrow;