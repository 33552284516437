import React, { useCallback, useState } from 'react';

import Button from '../../Layout/Button';
import Input from '../../Form/Input';

const Row = ({
  system,
  betslipId,
  stakeChanges,
  setBetStatus,
  setStakeChanges,
}) => {
  const [stake, setStake] = useState(system.bets[0].bet / 100);

  const findSystem = useCallback(
    (type, betId) =>
      system.type === type &&
      (system.type === 'SINGLE' ? system.bets[0].betId === betId : true),
    [system.bets, system.type]
  );

  const changeStake = useCallback(
    e => {
      let foundChange;
      const newStake =
        e.target.value > system.bets[0].bet / 100
          ? system.bets[0].bet / 100
          : e.target.value;

      if (
        (foundChange = stakeChanges.find(change =>
          findSystem(change.systemType, change.betId)
        ))
      ) {
        setStakeChanges(
          stakeChanges
            .map(change => {
              if (foundChange === change) {
                if (Number(newStake) === system.bets[0].bet / 100) {
                  return undefined;
                }
                return { ...change, bet: Number(newStake) * 100 };
              }
              return change;
            })
            .filter(a => a)
        );
      } else if (newStake !== system.bets[0].bet / 100) {
        setStakeChanges([
          ...stakeChanges,
          {
            bet: Number(newStake) * 100,
            systemType: system.type,
            betId: system.type === 'SINGLE' ? system.bets[0].betId : undefined,
          },
        ]);
      }

      setStake(newStake ? Number(newStake) : '');
    },
    [findSystem, setStakeChanges, stakeChanges, system.bets, system.type]
  );

  return (
    <tr>
      <td width="15%">{system.type}</td>
      <td width="10%">{Number(system.systemBet / 100).toFixed(2)} €</td>
      <td width="15%">{Number(system.systemOdds).toFixed(2)}</td>
      <td width="10%">{Number(system.systemTakeout / 100).toFixed(2)} €</td>
      <td width="15%">
        <div className="d-flex justify-content-center">
          <Input
            type="number"
            step={1}
            className="form-control"
            max={system.bets[0].bet / 100}
            value={stake}
            onChange={changeStake}
          />
        </div>
      </td>
      <td width="35%">
        {stakeChanges.find(change =>
          findSystem(change.systemType, change.betId)
        ) ? (
          <p>You have changed the bet amount</p>
        ) : (
          <>
            <Button
              onClick={() => setBetStatus(betslipId, system, false)}
              cssClass="btn btn-success"
            >
              Accept System Stake
            </Button>
            <Button
              onClick={() => setBetStatus(betslipId, system, true)}
              cssClass="btn btn-danger"
            >
              Reject System Stake
            </Button>
          </>
        )}
      </td>
    </tr>
  );
};

const SystemsTableRow = ({ tableData, ...props }) => {
  return tableData.map(system => (
    <Row
      key={`${system.systemType}${system.bets[0].selection[0].selectionId}`}
      system={system}
      {...props}
    />
  ));
};

export default React.memo(SystemsTableRow);
