import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import CashoutInPlayParameters from './InPlayParameters/CashoutInPlayParameters';
import CashoutPrematchParameter from './PrematchParameters/CashoutPrematchParameter';
import CashoutCalculator from './CashoutCalculator/CashoutCalculator';
import CashoutConfigurator from './CashoutConfigurator/CashoutConfigurator';


const CashoutManager = ({ match }) => {
  let RouteComponent;
  switch (match.params.module) {
    case 'cipp':
      RouteComponent = () => (
        <Route render={() => <CashoutInPlayParameters />} />
      );
      break;
    case 'cpp':
      RouteComponent = () => (
        <Route render={() => <CashoutPrematchParameter />} />
      );
      break;
    case 'calc':
      RouteComponent = () => <Route render={() => <CashoutCalculator />} />;
      break;
    case 'cc':
      RouteComponent = () => <Route render={() => <CashoutConfigurator />} />;
      break;
    default:
      RouteComponent = () => <Redirect to="/ctm" />;
  }
  return <Route path={`${match.path}`} component={RouteComponent} />;
};

export default CashoutManager;
