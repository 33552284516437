import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import * as CashoutManagerActions from '../../../../redux/actions/riskManagement/cashoutManager';

import TableSelector from '../../../../components/TableSelector/TableSelector';
import Button from '../../../../components/Layout/Button';
import Switch from '../../../../components/Form/Switch/Switch';
import classes from './CashoutConfigurator.module.scss';

import { useStateForSwitch, useFetch } from '../../../../hooks';

const SportConfigurator = ({ createOrUpdateSportConfig }) => {
  const [currentSport, setCurrentSport] = useState(null);
  const [enabled, setEnabled, resetEnabled] = useStateForSwitch(false);

  const { data: sportConfig, refetch } = useFetch(
    '/cashout/admin/config/sport'
  );
  const { data: sports } = useFetch('/sport/event/view/sport/admin');

  const config = useMemo(() => {
    if (currentSport) {
      const config = sportConfig.find(conf => conf.sportId === currentSport.id);
      if (config) {
        resetEnabled(config.enabled);
      }
      return config;
    }
    // eslint-disable-next-line
  }, [currentSport, sportConfig]);

  const createConfig = useCallback(async () => {
    await createOrUpdateSportConfig({
      changedDate: moment(),
      createdDate: moment(),
      id: config ? config.id : null,
      name: currentSport.names.en.name,
      sportId: currentSport.id,
      enabled,
    });
    await refetch();
  }, [config, createOrUpdateSportConfig, currentSport, enabled, refetch]);

  if (!sports || !sportConfig) return null;
  return (
    <div className="d-flex">
      <div className="col-md-3">
        <TableSelector
          onEntityClick={entity => setCurrentSport(entity)}
          sports={sports}
        />
      </div>
      <div className="col-md-9">
        {currentSport ? (
          <>
            <div className="d-flex justify-content-around pt-5">
              <h3 className="efb-h3">{currentSport.names.en.name}</h3>
              <Button cssClass="efb-btn__main" onClick={createConfig}>
                {config ? 'Update' : 'Create'} config
              </Button>
            </div>
            <hr />
          </>
        ) : null}

        <div className="p-5">
          {config && currentSport ? (
            <div className={classes.config}>
              <h5>ID</h5>
              <h5>Created Date</h5>
              <h5>Disabled/Enabled</h5>
              <div>{currentSport.id}</div>
              <div>
                {moment(currentSport.createdDate).format('YYYY-MM-DD HH:mm')}
              </div>
              <div>
                <Switch
                  id="config"
                  name="config"
                  isChecked={enabled}
                  onChange={setEnabled}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  createOrUpdateSportConfig: CashoutManagerActions.createOrUpdateSportConfig,
};

export default connect(null, mapDispatchToProps)(SportConfigurator);
