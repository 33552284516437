import { createRequestTypes, actionCreator } from '..';
import axiosInstance from '../axios-instance';
import { addNotificationMessage } from '../ui';

export const TYPES_RANKING_SAVE = createRequestTypes('TYPES_RANKING_SAVE');
export const CATEGORIES_RANKING_SAVE = createRequestTypes(
  'CATEGORIES_RANKING_SAVE'
);
export const OPEN_SELECTION_SAVE = createRequestTypes('OPEN_SELECTION_SAVE');

export const saveOpenSelection = item => async dispatch => {
  dispatch(actionCreator.request(OPEN_SELECTION_SAVE));
  try {
    await axiosInstance.post('/market/view/open-selection/admin', [item]);

    dispatch(actionCreator.success(OPEN_SELECTION_SAVE));
    dispatch(
      addNotificationMessage(
        'Expanded set successfully!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(actionCreator.failure(OPEN_SELECTION_SAVE, error));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

const saveMarketRanking = (type, arr, msg) => async dispatch => {
  dispatch(actionCreator.request(type));
  try {
    await axiosInstance.post('/market/view/ranking/admin', arr);

    dispatch(addNotificationMessage(msg, 'success', 'Success!'));

    dispatch(actionCreator.success(type));
  } catch (error) {
    dispatch(actionCreator.failure(type, error));
  }
};

export const saveCategoryRanking = (arr, msg) =>
  saveMarketRanking(CATEGORIES_RANKING_SAVE, arr, msg);

export const saveTypesRanking = (arr, msg) =>
  saveMarketRanking(TYPES_RANKING_SAVE, arr, msg);
