import React, { memo } from 'react';

export const TableTennis = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  const grayscale = (
    <g className={'Grayscale'}>
      <path d="M58.57,20.17c0,.12,0,.25,0,.37a.37.37,0,0,1,0,.11.48.48,0,0,1,0,.17.81.81,0,0,1,0,.16.2.2,0,0,1,0,.11v0a.06.06,0,0,1,0,0,0,0,0,0,1,0,0,.66.66,0,0,1,0,.14s0,0,0,0a3.4,3.4,0,0,1,0,.46,1.66,1.66,0,0,1,0,.32,1.11,1.11,0,0,1,0,.19.06.06,0,0,1,0,0,2,2,0,0,1-.06.35.59.59,0,0,1,0,.14,0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0,0,0,0,0a.4.4,0,0,1,0,.15h0a.88.88,0,0,1,0,.16l0,.13a1,1,0,0,1,0,.17c0,.15-.06.29-.1.43a.75.75,0,0,1,0,.16l0,.11,0,.12c0,.05,0,.09,0,.14s-.07.24-.12.36a11.62,11.62,0,0,1-.52,1.39l0,.09,0,0-.06.13c-.12.26-.24.52-.38.78s-.33.6-.51.9h0c-.26.43-.54.84-.84,1.24l-.58.74c-.08.09-.16.17-.23.26s-.43.47-.66.7a15,15,0,0,1-3,2.31c-.42.26-.86.5-1.31.72a14.49,14.49,0,0,1-1.41.59l-.63.22c-.43.13-.86.25-1.3.35-.28.06-.56.12-.84.16s-.56.09-.85.12l-.57.05a3.7,3.7,0,0,1-.47,0l-.7,0h-.33a1.82,1.82,0,0,1-.33,0l-.66,0L41,35.65l-.6-.08a16.09,16.09,0,0,1-2.1-.5,15.25,15.25,0,0,1-2.7-1.13l-.07,0c-.44-.24-.87-.51-1.29-.79l-.61-.43-.13-.09h0l0,0a64.78,64.78,0,0,1,4.35-6.29c.52-.68,1.06-1.35,1.61-2,1.33-1.59,2.75-3.15,4.25-4.65.81-.81,1.64-1.6,2.48-2.36l1.27-1.12c.42-.36.83-.71,1.25-1a.27.27,0,0,1,.08-.07c.38-.31.76-.62,1.14-.91a1.93,1.93,0,0,1,.22-.18l1.12-.85.31-.23.94-.67c.37-.27.76-.53,1.14-.79s1-.68,1.49-1c0,0,0,0,0,0a16.14,16.14,0,0,1,1.46,2.17c.1.18.2.36.29.55s.13.25.19.38c.2.43.39.87.55,1.31a15.26,15.26,0,0,1,.58,2c.05.22.09.44.13.66s.07.44.1.66a12.27,12.27,0,0,1,.12,1.36C58.56,19.71,58.57,19.94,58.57,20.17Z" />
      <path d="M24.11,63.13l53.75,53.75a52.8,52.8,0,0,1-31.15-8.29,48.25,48.25,0,0,0-9.78,7.93c-15.46,15.47-19.3,22.39-19.3,22.39a7.16,7.16,0,0,1-10.09,0l-5.46-5.47a7.16,7.16,0,0,1,0-10.09A205.56,205.56,0,0,0,24.46,104.1a52.05,52.05,0,0,0,8-9.82A53.2,53.2,0,0,1,24.11,63.13Z" />
      <path d="M121.39,97.31l-1.18,1.15c-.25.25-.51.49-.77.73s-.72.66-1.09,1-.58.52-.88.77l-.24.21-1,.81q-2.29,1.89-4.71,3.54l-1.58,1c-.72.47-1.45.91-2.19,1.35l-1.29.74-.8.44-.71.38-1,.52c-.32.17-.65.33-1,.49l-1,.47c-.57.26-1.13.52-1.71.76l-.67.29-1.13.46-.58.22-.13.05c-.65.25-1.29.49-1.94.71l-1.09.37-1,.31L26.86,47.2l0,0c.27-.87.56-1.74.88-2.6A28.19,28.19,0,0,0,43,49,28.82,28.82,0,0,0,71.78,20.17,28.38,28.38,0,0,0,67,4.32h0l.39-.14,1-.38c1-.35,1.93-.68,2.91-1l1-.29a4.88,4.88,0,0,1,.51-.15L74,2c.53-.14,1-.27,1.58-.39.35-.09.69-.17,1-.24l1.5-.31C78.7,1,79.24.88,79.78.8l1-.16c1.51-.22,3-.39,4.52-.49.65,0,1.31-.08,2-.1h.28c.63,0,1.26,0,1.89,0h.14l1.5,0,.55,0,1.57.08.61,0,1.3.11c1,.11,2,.23,3,.39.36.05.72.11,1.07.18.72.12,1.42.26,2.13.42q.53.1,1.05.24l1.05.26,1.47.4.16.05c.49.14,1,.3,1.47.46a49,49,0,0,1,6.22,2.5c.75.36,1.49.74,2.23,1.14h0q1.45.78,2.85,1.68c.28.17.56.36.84.54.56.38,1.12.76,1.67,1.16l1,.77c.68.52,1.35,1.06,2,1.62,1,.84,1.94,1.72,2.87,2.65C147.69,36.21,145.49,73.17,121.39,97.31Z" />
      <path d="M58.57,20.17c0,.12,0,.25,0,.37a2.53,2.53,0,0,1,0,.28,2.43,2.43,0,0,1,0,.27v0a.19.19,0,0,1,0,.08.66.66,0,0,1,0,.14s0,0,0,0c0,.26,0,.52-.08.78a1.11,1.11,0,0,1,0,.19h0v0a2,2,0,0,1-.06.35.59.59,0,0,1,0,.14s0,0,0,.06,0,0,0,0,0,0,0,0h0a.5.5,0,0,1,0,.12s0,0,0,0h0l0,.17,0,.13a1,1,0,0,1,0,.17l-.09.41s0,0,0,0,0,.11,0,.16l0,.11,0,.12h0l0,.14-.12.36a11.62,11.62,0,0,1-.52,1.39l0,.09h0s0,0,0,0l-.06.13c-.12.26-.24.52-.38.78s-.33.6-.51.9h0c-.26.43-.54.84-.84,1.24l-.58.74c-.08.09-.16.17-.23.26s-.43.47-.66.7a15,15,0,0,1-3,2.31c-.42.26-.86.5-1.31.72a14.49,14.49,0,0,1-1.41.59l-.63.22c-.43.13-.86.25-1.3.35-.28.06-.56.12-.84.16s-.56.09-.85.12l-.57.05-.32,0h-.15l-.7,0h-.33a1.82,1.82,0,0,1-.33,0l-.66,0L41,35.65l-.6-.08a16.09,16.09,0,0,1-2.1-.5,15.25,15.25,0,0,1-2.7-1.13l-.07,0c-.44-.24-.87-.51-1.29-.79l-.61-.43-.13-.09h0a69.09,69.09,0,0,1,4.32-6.31c.52-.68,1.06-1.35,1.61-2,1.33-1.59,2.75-3.15,4.25-4.65.81-.81,1.64-1.6,2.48-2.36l1.27-1.12c.42-.36.83-.71,1.25-1a.27.27,0,0,1,.08-.07c.38-.31.76-.62,1.14-.91a1.93,1.93,0,0,1,.22-.18l1.12-.85.31-.23.94-.67c.37-.27.76-.53,1.14-.79s1-.66,1.51-1a16.14,16.14,0,0,1,1.46,2.17c.1.18.2.36.29.55s.13.25.19.38c.2.43.39.87.55,1.31a15.26,15.26,0,0,1,.58,2c.05.22.09.44.13.66s.07.44.1.66a12.27,12.27,0,0,1,.12,1.36C58.56,19.71,58.57,19.94,58.57,20.17Z" />
      <path d="M67,4.31l1.38-.52c1-.35,1.93-.68,2.91-1l1-.29a4.91,4.91,0,0,1,.51-.15L74,2c.53-.14,1-.27,1.58-.39.35-.09.69-.17,1-.24l1.5-.31C78.7,1,79.24.88,79.78.8l1-.16c1.51-.22,3-.39,4.52-.49.65,0,1.31-.08,2-.1h.28c.63,0,1.26,0,1.89,0h.14l1.5,0,.55,0,1.57.08.61.05,1.3.11c1,.11,2,.23,3,.39.36,0,.72.11,1.07.18.72.12,1.42.26,2.13.42q.52.1,1,.24l1,.26,1.47.4.16.05c.49.14,1,.3,1.47.46a49,49,0,0,1,6.22,2.5c.75.36,1.49.74,2.23,1.14h0q1.45.78,2.85,1.68l.84.54c.56.38,1.12.76,1.67,1.16l1,.77c.68.52,1.35,1.06,2,1.62,1,.84,1.94,1.72,2.87,2.65,21.45,21.46,19.25,58.42-4.85,82.56l-1.18,1.15c-.25.25-.51.49-.77.73s-.72.66-1.09,1-.58.52-.88.77l-.24.21-1,.81q-2.3,1.89-4.71,3.54l-1.58,1c-.72.47-1.45.91-2.19,1.35l-1.29.74-.8.44-.71.38-1,.52c-.32.17-.65.33-1,.49l-1,.47c-.57.26-1.13.52-1.71.76l-.67.29-1.64.66-.07,0-.13,0c-.65.25-1.29.49-1.94.71l-1.26.42a8,8,0,0,1-.83.26L26.86,47.2c.26-.88.55-1.75.86-2.62A28.19,28.19,0,0,0,43,49,28.82,28.82,0,0,0,71.78,20.17,28.38,28.38,0,0,0,67,4.32Z" />
      <path d="M43,35.77h0l.7,0h.15ZM58.43,18.12c.06.45.1.9.12,1.36A12.27,12.27,0,0,0,58.43,18.12Zm-1.17,8.3s0,0,0,0l0,0,0-.09Zm.84-2.47c0,.17-.08.33-.13.49l-.09.32.06-.18s0-.09,0-.14l0-.12,0-.11a.75.75,0,0,0,0-.16s0,0,0,0a2.05,2.05,0,0,0,0-.24A.75.75,0,0,1,58.1,23.95Zm.43-2.79a.06.06,0,0,0,0,0V21A.57.57,0,0,1,58.53,21.16Zm0-1.68c0,.23,0,.46,0,.69s0,.32,0,.48a.37.37,0,0,0,0-.11c0-.12,0-.25,0-.37S58.56,19.71,58.55,19.48Zm-.14,2.87v0a.06.06,0,0,0,0,0,1.11,1.11,0,0,0,0-.19,1.66,1.66,0,0,0,0-.32A4.42,4.42,0,0,1,58.41,22.35Zm-.09.54v0a0,0,0,0,1,0,0v0a.59.59,0,0,0,0-.14Z" />
      <path d="M58.57,20.17c0,.12,0,.25,0,.37a.37.37,0,0,1,0,.11.48.48,0,0,1,0,.17.81.81,0,0,1,0,.16.2.2,0,0,1,0,.11v0a.06.06,0,0,1,0,0,0,0,0,0,1,0,0,.66.66,0,0,1,0,.14s0,0,0,0a3.4,3.4,0,0,1,0,.46,1.66,1.66,0,0,1,0,.32,1.11,1.11,0,0,1,0,.19.06.06,0,0,1,0,0,2,2,0,0,1-.06.35.59.59,0,0,1,0,.14,0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0,0,0,0,0a.4.4,0,0,1,0,.15h0a.88.88,0,0,1,0,.16l0,.13a1,1,0,0,1,0,.17c0,.15-.06.29-.1.43a.75.75,0,0,1,0,.16l0,.11,0,.12c0,.05,0,.09,0,.14s-.07.24-.12.36a11.62,11.62,0,0,1-.52,1.39l0,.09,0,0-.06.13c-.12.26-.24.52-.38.78s-.33.6-.51.9h0c-.26.43-.54.84-.84,1.24l-.58.74c-.08.09-.16.17-.23.26s-.43.47-.66.7a15,15,0,0,1-3,2.31c-.42.26-.86.5-1.31.72a14.49,14.49,0,0,1-1.41.59l-.63.22c-.43.13-.86.25-1.3.35-.28.06-.56.12-.84.16s-.56.09-.85.12l-.57.05a3.7,3.7,0,0,1-.47,0l-.7,0h-.33a1.82,1.82,0,0,1-.33,0l-.66,0L41,35.65l-.6-.08a16.09,16.09,0,0,1-2.1-.5,16.9,16.9,0,0,1-2.7-1.13l-.07,0c-.44-.24-.87-.51-1.29-.79l-.61-.43-.13-.1,0,0A15.61,15.61,0,1,1,55.11,10.37s0,0,0,0a16.14,16.14,0,0,1,1.46,2.17c.1.18.2.36.29.55s.13.25.19.38c.2.43.39.87.55,1.31a15.26,15.26,0,0,1,.58,2c.05.22.09.44.13.66s.07.44.1.66a12.27,12.27,0,0,1,.12,1.36C58.56,19.71,58.57,19.94,58.57,20.17Z" />
    </g>
  );

  const color = (
    <g className={'WithColor'}>
      <path
        className={'Handle'}
        d="M121,97a66.06,66.06,0,0,1-36.52,18.84c-13.39,2.07-26.87-.43-37.88-7.63a48.79,48.79,0,0,0-9.79,7.93c-15.41,15.41-19.19,22.29-19.19,22.29a7.15,7.15,0,0,1-10.08,0L2.08,133a7.15,7.15,0,0,1,0-10.08,201.26,201.26,0,0,0,22.29-19.19,48.8,48.8,0,0,0,7.93-9.79c-7.2-11-9.7-24.49-7.63-37.88A66.06,66.06,0,0,1,43.51,19.56C67.57-4.5,104.41-6.66,125.79,14.72S145,72.95,121,97Z"
      />
      <path
        className={'Side'}
        d="M121,97a66.06,66.06,0,0,1-36.52,18.84L24.67,56.07A66.06,66.06,0,0,1,43.51,19.56C67.57-4.5,104.41-6.66,125.79,14.72S145,72.95,121,97Z"
      />
      <circle className={'Ball'} cx="42.8" cy="20.11" r="15.56" />
    </g>
  );

  return (
    <svg
      x="0px"
      y="0px"
      className={
        `TableTennis ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141 140.99"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      {colorful ? (
        <>
          {color}
          {grayscale}
        </>
      ) : (
        grayscale
      )}
    </svg>
  );
};

export default memo(TableTennis);
