import React, { useState, useCallback } from 'react';
import { useUpdate } from '../../../hooks';
import IconTick from '../../Icons/UI/ui/IconTick';

import classes from './ColumnFilterDialog.module.scss';

const ColumnFilterDialog = ({
  coords,
  activeOptions,
  options,
  onColumnFilterChange: pushColumnFilterChange,
}) => {
  const [active, setActive] = useState(activeOptions);

  const handleOptionClick = useCallback(
    optionName => {
      if (active.indexOf(optionName) > -1) {
        setActive(active.filter(optName => optName !== optionName));
      } else {
        setActive([...active, optionName]);
      }
    },
    [active]
  );

  useUpdate(() => {
    pushColumnFilterChange(active);
  }, [active]);

  return (
    <div style={{ top: coords.height }} className={classes.wrapper}>
      {options.map(option => {
        const isOptionActive = active.indexOf(option.name) > -1;
        return (
          <>
            <div
              onClick={() => handleOptionClick(option.name)}
              className={isOptionActive ? classes.active : classes.inactive}
            >
              {isOptionActive ? <IconTick width={19} /> : ''}
            </div>
            <div
              onClick={() => handleOptionClick(option.name)}
              className={isOptionActive ? classes.active : classes.inactive}
            >
              {option.name}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default ColumnFilterDialog;
