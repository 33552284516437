import React, { Fragment, useState, useMemo,useCallback } from 'react';
import Button from '../../../../components/Layout/Button';
import EditCurrency from './EditCurrency';
import InputField from '../../../../components/Form/InputField';
import Table from '../../../../components/Table/Table';
import { currenciesColumns } from '../transactionsUtils';
import { useMount } from '../../../../hooks';
import moment from 'moment';

const CurrencyRates = ({
  tab,
  currenciesRates,
  changeCurrencyRate,
  getDownloadedCurrency,
  emptyCurrencies,
  addNotificationMessage
}) => {
  const [toEditCurrency, setToEditCurrency] = useState('');
  const [filterByDate, setFilterByDate] = useState(moment());

  useMount(() => getDownloadedCurrency(moment()));

  const currenciesByDefaultCurrency = useMemo(() => {
    return (
      currenciesRates && Object.keys(currenciesRates).length > 0 && {
        ...currenciesRates,
        currencyRates: currenciesRates.currencyRates.filter(
          currency => currency.base === currenciesRates.defaultCurrencyCode
        )
      }
    );
  },[currenciesRates]);

  const clearDateInternal = useCallback(() => {
    setFilterByDate(moment());
    emptyCurrencies();
    setToEditCurrency('');
  }, [emptyCurrencies]);

  const validateForm = useCallback(newCurrencyRate => {
    if (newCurrencyRate.newRate < 0) {
      addNotificationMessage(
        'Please input positive number',
        'warning',
        'Warning'
      );
      return false;
    }
    if (newCurrencyRate.newRate === newCurrencyRate.rate) {
      addNotificationMessage(
        'Currency rate is not save because it has not been changed!',
        'error',
        'Error'
      );
      return false;
    }
    return true;
  }, [addNotificationMessage]);

  const saveCurrentCurrencyRate = useCallback(
    newCurrencyRate => {
      if(validateForm(newCurrencyRate)){
        const saveObject = {
          requests: [
            {
              currencyRateId: newCurrencyRate.id,
              newRate: newCurrencyRate.newRate,
              newReverseRate: 1 / newCurrencyRate.newRate
            }
          ]
        };
        changeCurrencyRate(saveObject);
        getDownloadedCurrency();
        setToEditCurrency('');
      }
    },
    [changeCurrencyRate, getDownloadedCurrency, validateForm]
  );
  
  return (
    <Fragment>
      <h2>{toEditCurrency ? 'Edit currency' : 'Currency Rates'}</h2>
      <hr />
      {toEditCurrency ? (
        <EditCurrency
          toEditCurrency={toEditCurrency}
          saveCurrentCurrencyRate={saveCurrentCurrencyRate}
          cancelEdit={() => setToEditCurrency('')}
        />
      ) : (
        <Fragment>
          <div className="efb-form form-row form-label-group col">
            <div className="form-label-group col">
              <InputField
                type="date"
                name="date"
                label="Date"
                placeholder="Date"
                value={moment(filterByDate).format('YYYY-MM-DD')}
                onChange={event => setFilterByDate(moment(event.target.value))}
                min="1000-01-01"
                max={moment().format('YYYY-MM-DD')}
              />
            </div>
          </div>
          <div className="form-row">
            <Button onClick={clearDateInternal} cssClass="efb-btn__main col-md">
              Clear
            </Button>
            <Button
              onClick={() => getDownloadedCurrency(filterByDate)}
              cssClass="efb-btn__main col-md"
            >
              Show result
            </Button>
          </div>
        </Fragment>
      )}
      {currenciesRates ? (
        <Fragment>
          <h4 className="my-4 text-center">
            {currenciesRates && currenciesRates.defaultCurrencyCode && (
              <p> Base currency: {currenciesRates.defaultCurrencyCode}</p>
            )}
          </h4>
          <Table
            data={currenciesByDefaultCurrency.currencyRates || []}
            columns={currenciesColumns}
            tableStyle="sortable table table-hover table-striped efb-table efb-data-table"
            tableType="transactionTable"
            tab={tab}
            editCurrency={oldCurrency => setToEditCurrency(oldCurrency)}
            pagination={false}
            search={false}
          />
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default CurrencyRates;
