import React from 'react';
import Table from '../../../components/Table/Table';

const columns = [
  { name: 'Language' },
  { name: 'Key' },
  { name: 'Value' },
  { name: 'Actions' },
];

const TranslationsTable = ({
  translationsList,
  updateTranslation,
  deleteTranslation,
  language,
}) => {
  return (
    <Table
      columns={columns}
      data={translationsList}
      updateTranslation={updateTranslation}
      tableType="translationsTable"
      language={language}
      deleteTranslation={deleteTranslation}
      tableStyle="sortable table table-hover table-striped efb-table events-search-table"
    />
  );
};

export default TranslationsTable;
