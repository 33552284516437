import React from 'react';
import moment from 'moment';

import classes from './CustomerDetails.module.scss';
import CustomerSummary from './CustomerSummary';
import CustomerSportCalculations from './CustomerSportCalculations';
import CustomerCasinoCalculations from './CustomerCasinoCalculations';
import CustomerTotalCalculations from './CustomerTotalCalculations';
import { getSvgFlag } from '../../../../utils/flags';

const CustomerDetails = ({ customer }) => {
  if (!customer) return null;

  return (
    <div className={classes.customerDetailsWrapper}>
      {/* Header */}
      <div className={classes.customerHeader}>
        <p className={classes.customerName}>
          {customer.gender === 'MALE' ? 'Mr.' : 'Mrs.'}{' '}
          <span className="uppercase">{customer.lastName}</span>,{' '}
          {customer.firstName}
        </p>
        <img
          src={getSvgFlag(customer.country.countryCode)}
          className={classes.countryIcon}
          alt="country-icon"
        />
        <p>
          {customer.accounts.find(x => x.accountType === 'MAIN')
            ? customer.accounts.find(x => x.accountType === 'MAIN').currencyCode
            : '/'}
        </p>
        <p className="float-right">
          [ {customer.customerId} / {customer.user?.username}]
        </p>
      </div>
      {/* Customer Money */}
      {<CustomerSummary customer={customer} />}
      {/* Basic Info */}
      <div className="d-flex flex-row">
        <div className="col-md-4">
          <div
            className={`${classes.borderRight} ${classes.basicInfo} ${classes.flexSpaceBetween}`}
          >
            <div>
              <p>Country:</p>
              <b>{customer.country.names.en.name || 'Empty'}</b>
            </div>
            <div>
              <p>City:</p>
              <b>{customer.city.names.en.name || 'Empty'}</b>
            </div>
            <div>
              <p>Address:</p>
              <b>{customer.address || 'Empty'}</b>
            </div>
            <div>
              <p>Postcode:</p>
              <b>{customer.postalCode || 'Empty'}</b>
            </div>
            <div>
              <p>Affiliate Ref:</p>
              <b>{customer.affiliate || 'Empty'}</b>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className={`${classes.basicInfo} ${classes.flexSpaceBetween}`}>
            <div>
              <p>Phone:</p>
              <b>{customer.mobilePhone || 'Empty'}</b>
            </div>
            <div>
              <p>Email:</p>
              <b>{customer.email || 'Empty'}</b>
            </div>
            <div>
              <p>Opened:</p>
              <b>
                {customer.createdOn
                  ? moment.utc(customer.createdOn).format('DD/MM/YYYY HH:mm:ss')
                  : 'Empty'}
              </b>
            </div>
            <div>
              <p>Closed:</p>
              <b>
                {customer.closedDate
                  ? moment
                      .utc(customer.closedDate)
                      .format('DD/MM/YYYY HH:mm:ss')
                  : 'Empty'}
              </b>
            </div>
            <div className={classes.missing}>
              <p>CC Password:</p>
              <b>Empty</b>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className={`${classes.basicInfo}`}>
            <div className={`${classes.extraInfoDiv}`}>
              <p>There aren't any notes by "Support Team"</p>
              <span className={classes.authorName}>Support</span>
            </div>
            <div
              className={`${classes.extraInfoDiv} ${classes.extraInfoYellow}`}
            >
              <p>
                {customer.notes.find(x => x.noteType === 'TRADER')
                  ? customer.notes.find(x => x.noteType === 'TRADER').note
                  : "There aren't any notes by 'Traders'"}
              </p>
              <span className={classes.authorName}>Traders</span>
            </div>
          </div>
        </div>
      </div>
      {/* Calculations */}
      <div className={classes.calculationsWrapper}>
        <div className="d-flex">
          <CustomerSportCalculations customerId={customer.customerId} />
          <CustomerCasinoCalculations customerId={customer.customerId} />
          <CustomerTotalCalculations customerId={customer.customerId} />
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
