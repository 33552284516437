import React, { useCallback, useState } from 'react';
import moment from 'moment';
import Button from '../../Layout/Button';
import Countdown from 'react-countdown';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const ReferralBetTableRowNew = ({
  tableData,
  setBetslipStatus,
  openDetails,
  changedOdds,
  columns,
  showAllTypes,
  customerCountries,
  referralType,
  removeBetslipFromList,
  removeReferralBetslip,
}) => {
  const [expandedBetslip, setExpandedBetslip] = useState({});

  const renderRow = useCallback(
    (betslip, index) => {
      const timeoutSecs = moment
        .duration(
          moment(betslip.expireAt) - moment(betslip.createdAt),
          'seconds'
        )
        .asSeconds();
      const prolongedSecs = moment
        .duration(moment() - moment(betslip.createdAt), 'seconds')
        .asSeconds();

      const initialTime =
        prolongedSecs < timeoutSecs ? timeoutSecs - prolongedSecs : 0;

      const result = [];

      if (Number((initialTime / 1000).toFixed(0)) === 0) {
        removeBetslipFromList(index);
        removeReferralBetslip(betslip.betslipId);
      }

      result.push(
        <tr
          onClick={() =>
            setExpandedBetslip(betslip !== expandedBetslip ? betslip : null)
          }
          key={betslip.betslipId}
        >
          <td>
            <div className="channel-icon">
              <i
                className={`flag flag-icon flag-icon-${
                  customerCountries.length === tableData.length &&
                  isNaN(customerCountries[0])
                    ? customerCountries[index].countryCode.toLowerCase()
                    : 'bg'
                }`}
              />
              <i
                className={`fas ${
                  betslip.channel === 'MOBILE' ? 'fa-mobile-alt' : 'fa-desktop'
                } `}
              />
            </div>
            {'  '}
            <strong
              className="name-higlight"
              style={{ backgroundColor: betslip.riskCategoryColor }}
            >
              {' '}
              [{betslip.customerId}] {betslip.customerName}
            </strong>
          </td>
          <td>
            €{' '}
            {betslip.totalBet ? Number(betslip.totalBet / 100).toFixed(2) : '/'}{' '}
          </td>
          <td>
            {betslip.systemBets.length > 0 ? betslip.systemBets[0].type : null}{' '}
            <i
              onClick={() => showAllTypes(index)}
              className="fa fa-angle-double-right"
            />
          </td>
          <td>
            {betslip.totalTakeout
              ? `${Number(betslip.totalTakeout / 100).toFixed(2)} €`
              : '/'}
          </td>
          <td className={'referral-timer-td'}>
            <CountdownCircleTimer
              isPlaying
              duration={initialTime / 1000}
              trailColor={'#fff'}
              size={50}
              strokeWidth="6"
              strokeLinecap="stroke"
              colors={[['#00c06a'], ['#ff0000', 0.1]]}
              onComplete={() => {
                removeReferralBetslip(betslip.betslipId, index);
                removeBetslipFromList(index);
              }}
            >
              {({ remainingTime }) => <span>{remainingTime}</span>}
            </CountdownCircleTimer>
          </td>
          <td className="last-row-centered">
            <Button
              onClick={e => {
                e.stopPropagation();
                setBetslipStatus(betslip.betslipId, 'ACCEPTED_AFTER_REFERRAL');
              }}
              disabled={referralType === 'BETSLIP_REFFERAL_WITH_REOFFER'}
              cssClass="mr-1 ml-1 bet-scroller-btn ok-btn btn"
            >
              Accept
            </Button>
            <Button
              onClick={e => {
                e.stopPropagation();
                setBetslipStatus(betslip.betslipId, 'CANCEL_AFTER_REFERRAL');
              }}
              cssClass="mr-1 ml-1 bet-scroller-btn cancel-btn btn"
            >
              Reject
            </Button>
            <Button
              onClick={e => {
                e.stopPropagation();
                openDetails(betslip.betslipId);
              }}
              cssClass="mr-1 ml-1 bet-scroller-btn cancel-btn btn"
            >
              Details
            </Button>
            <div className="form-row text-danger justify-content-center pt-1">
              {betslip.riskRules[0].details.indexOf(
                'REFERRAL_DUPLICATE_SELECTION'
              ) > -1 && '* this bet skips Autopilot'}
            </div>
          </td>
        </tr>
      );

      if (expandedBetslip === betslip) {
        result.push(
          <tr
            key={`details-${betslip.betslipId}`}
            className="no-hover expanded-table"
          >
            <td colSpan={columns.length} className="p-0">
              <div className="referral-custom-table-wrapper">
                <table className="table table-hover w-100 referral-betslip-details-table">
                  <thead>
                    <tr>
                      <th>Sport</th>
                      <th>Event</th>
                      <th>Market</th>
                      <th>Selection</th>
                      <th>Odds</th>
                      <th>Exp. Time</th>
                      <th>Bet F.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betslip.selections.map(selection => (
                      <tr
                        key={`${selection.eventName}${selection.marketName}${selection.outcomeOddsName}`}
                      >
                        <td>{selection.sportName}</td>
                        <td>{selection.eventName}</td>
                        <td>{selection.marketName}</td>
                        <td>{selection.outcomeOddsName}</td>
                        <td>
                          {Number(
                            changedOdds[selection.selectionId] ||
                              selection.outcomeOdds
                          ).toFixed(2)}
                        </td>
                        <td>
                          {
                            <Countdown
                              daysInHours
                              date={moment
                                .unix(selection.priceChangeExpireAt / 1000)
                                .format()}
                            />
                          }
                        </td>
                        <td>/</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        );
      }

      return result;
    },
    [
      customerCountries,
      tableData.length,
      referralType,
      expandedBetslip,
      removeBetslipFromList,
      removeReferralBetslip,
      showAllTypes,
      setBetslipStatus,
      openDetails,
      columns.length,
      changedOdds,
    ]
  );

  return tableData.map(renderRow);
};

export default ReferralBetTableRowNew;
