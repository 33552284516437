import React, { useCallback } from 'react';
import CheckboxDiv from '../../../../components/Form/CheckboxDiv/CheckboxDiv';

const Main = ({ betScroller, onChangeBetStake }) => {
  const onChangeInputs = useCallback(
    e => {
      const { name, value } = e.target;
      if (value < 0) return;
      onChangeBetStake({
        ...betScroller,
        [name]: value ? Number(value) : '',
      });
    },
    [betScroller, onChangeBetStake]
  );

  const onChangeBetStates = useCallback(
    e => {
      const filterBetState = [...betScroller.betStates];
      onChangeBetStake({
        ...betScroller,
        betStates: filterBetState.includes(e.target.name)
          ? filterBetState.filter(state => state !== e.target.name)
          : [...betScroller.betStates, e.target.name],
      });
    },
    [betScroller, onChangeBetStake]
  );

  const onChangeGames = useCallback(
    e => {
      const filterByGame = [...betScroller.games];
      onChangeBetStake({
        ...betScroller,
        games: filterByGame.includes(e.target.name)
          ? filterByGame.filter(game => game !== e.target.name)
          : [...betScroller.games, e.target.name],
      });
    },
    [betScroller, onChangeBetStake]
  );

  return (
    <div className="m-2">
      <div className="d-flex my-3 justify-content-around">
        <div className="d-flex flex-column">
          <div className="ml-2 mb-2">
            <b>By Bet State</b>
          </div>
          <div className="d-flex">
            <CheckboxDiv
              id="ATTEMPTED"
              label="ATTEMPTED"
              name="ATTEMPTED"
              onChange={onChangeBetStates}
              isChecked={
                betScroller.betStates &&
                betScroller.betStates.includes('ATTEMPTED')
              }
            />
          </div>
          <div className="d-flex">
            <CheckboxDiv
              id="OPEN"
              label="OPEN"
              name="OPEN"
              onChange={onChangeBetStates}
              isChecked={
                betScroller.betStates && betScroller.betStates.includes('OPEN')
              }
            />
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="ml-2 mb-2">
            <b>By Game</b>
          </div>
          <div className="d-flex">
            <CheckboxDiv
              id="PRE_MATCH"
              label="PreMatch"
              name="PRE_MATCH"
              onChange={onChangeGames}
              isChecked={betScroller.games.includes('PRE_MATCH')}
            />
          </div>
          <div className="d-flex">
            <CheckboxDiv
              id="IN_PLAY"
              label="InPlay"
              name="IN_PLAY"
              onChange={onChangeGames}
              isChecked={betScroller.games.includes('IN_PLAY')}
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-around m-2">
        <div>
          <b>By Bet Stake</b>
        </div>
        <div>
          <b>Takeout bigger than</b>
        </div>
      </div>
      <div className="d-flex my-2">
        <div className="text-center">
          <span className="m-1">Min</span>
          <input
            name="betStakeMin"
            onChange={onChangeInputs}
            className="filter-input m-1"
            type="number"
            value={betScroller.betStakeMin}
          />
          <span className="m-1">€</span>
        </div>
        <div>
          <input
            name="takeoutMin"
            onChange={onChangeInputs}
            className="filter-input m-1"
            type="number"
            value={betScroller.takeoutMin || 0}
          />
          <span className="m-1">€</span>
        </div>
      </div>
      <div className="max-input text-center">
        <span className="m-1">Max</span>
        <input
          name="betStakeMax"
          onChange={onChangeInputs}
          className="filter-input m-1"
          type="number"
          value={betScroller.betStakeMax}
        />
        <span className="m-1">€</span>
      </div>
    </div>
  );
};

export default Main;
