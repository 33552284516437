import React, { useMemo } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';

import Merger from './Merger';

const CCSSTVMerger = ({ match }) => {
  const module = useMemo(() => {
    switch (match.params.module) {
      case 'ca':
        return () => <Merger entityType="category" />;
      case 'sp':
        return () => <Merger entityType="sport" />;
      case 'to':
        return () => <Merger entityType="tournament" />;
      default:
        return () => <Redirect to="/adm" />;
    }
  }, [match.params.module]);

  return (
    <>
      <Route
        path={`${match.path}`}
        component={module}
      />
    </>
  );
};

export default withRouter(CCSSTVMerger);