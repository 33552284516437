import React, { useCallback } from 'react';
import Select from 'react-select';

import classes from './SelectFilter.module.scss';
import { underlinedSelectStyles } from '../../../../utils/selectStyles';
import { useStateForSelect } from '../../../../hooks';

const SelectFilter = ({
  name,
  label,
  options,
  default: defaultValue,
  onFilterChange: pushFilterChange,
}) => {
  const [value, setValue] = useStateForSelect(defaultValue || null);

  const handleChange = useCallback(
    newValue => {
      setValue(newValue);
      pushFilterChange({ name, value: newValue.value });
    },
    [name, pushFilterChange, setValue]
  );

  return (
    <div className={classes.select}>
      <label>{label}:</label>
      <Select
        value={value}
        onChange={handleChange}
        options={options}
        styles={underlinedSelectStyles}
      />
    </div>
  );
};

export default SelectFilter;
