import React from 'react';

export const CustomerSer = (props) => {
    return (
        <span className='efb-icon'>
            <svg className={`efb-icon--${props.size} efb-customer-service`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
            
            <path d="M36,27.8V8.2c0-1.8-1.5-3.3-3.3-3.3H3.3C1.5,4.9,0,6.4,0,8.2v19.6c0,1.8,1.5,3.3,3.3,3.3h29.5C34.5,31.1,36,29.6,36,27.8z
			    M1.6,27.8V8.2c0-0.9,0.7-1.6,1.6-1.6h29.5c0.9,0,1.6,0.7,1.6,1.6v19.6c0,0.9-0.7,1.6-1.6,1.6H3.3C2.4,29.5,1.6,28.7,1.6,27.8z"/>
            <path d="M30.3,22.9h-9.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h9.8c0.5,0,0.8-0.4,0.8-0.8C31.1,23.3,30.7,22.9,30.3,22.9z
                "/>
            <path d="M30.3,18h-9.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h9.8c0.5,0,0.8-0.4,0.8-0.8C31.1,18.4,30.7,18,30.3,18z"/>
            <path d="M30.3,13.1h-9.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h9.8c0.5,0,0.8-0.4,0.8-0.8C31.1,13.5,30.7,13.1,30.3,13.1z
                "/>
            <path d="M11.5,18c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3s-3.3,1.5-3.3,3.3C8.2,16.5,9.7,18,11.5,18z"/>
            <path d="M16.3,20c-1.1-0.5-2.9-1.2-4.9-1.2c-2,0-3.7,0.7-4.9,1.2c-1,0.5-1.7,1.5-1.7,2.6V23c0,0.9,0.7,1.6,1.6,1.6h9.8
                c0.9,0,1.6-0.7,1.6-1.6v-0.4C18,21.5,17.4,20.5,16.3,20z"/>

            </svg>
            {props.children}
        </span>
    )
}

export default CustomerSer;