import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Close from '../Icons/Close';
import PropTypes from 'prop-types';
import logo from '../../resources/images/logo.png';
import Button from '../Layout/Button';

const PermissionsModal = ({
  isActive,
  onClose,
  headerText = 'Modal',
  children,
  onSubmit,
  onClear,
  submitBtnName
}) => {
  const timeout = { enter: 500, exit: 300 };

  return (
    <TransitionGroup className="efb-modal__animation" appear={true}>
      {isActive ? (
        <CSSTransition timeout={timeout}>
          <section className="efb-modal-permissions">
            <div className="efb-modal__overlay">
              <div className="efb-modal-permissions__wrapper">
                <header className="efb-modal-permissions__header">
                  <img src={logo} alt="efbet logo" height={25} />
                  <span>{headerText}</span>
                  <span style={{ fill: '#feb719' }} onClick={onClose}>
                    <Close size="xs" />
                  </span>
                </header>
                <section className="efb-modal-permissions__toolbar">
                  <div className="efb-modal-permissions__toolbar__button-container">
                    <Button onClick={() => onClear()} cssClass="efb-btn__link">
                      Clear
                    </Button>
                    <Button onClick={() => onSubmit()} cssClass="efb-btn__link">
                      {submitBtnName ? submitBtnName : 'Save'}
                    </Button>
                  </div>
                </section>
                {children}
                <div className="efb-modal-permissions__decorator" />
              </div>
            </div>
          </section>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  );
};

PermissionsModal.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  headerText: PropTypes.string,
  submitBtnName: PropTypes.string,
  onSubmit: PropTypes.func,
  onClear: PropTypes.func
};

export default PermissionsModal;
