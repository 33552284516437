import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { selectStyles } from '../../../customReactSelectStyle';
import { useStateForSelect, useUpdate } from '../../../../../hooks';

const ContentType = ({ pageContent, currentLang, serverError }) => {
  const [contentType, setContentType] = useStateForSelect('');

  const contentTypes = useMemo(() => {
    return [
      { value: 'SECTIONS', label: 'Sections' },
      { value: 'RULES', label: 'Rules' },
      { value: 'REGULAR', label: 'Regular' },
      { value: 'TABLES', label: 'Tables' },
    ];
  }, []);

  useUpdate(() => {
    if (contentType) pageContent(contentType);
  }, [contentType]);

  return (
    <>
      <Select
        isDisabled={!currentLang || serverError}
        value={contentType}
        options={contentTypes}
        placeholder={'Content Type...'}
        onChange={setContentType}
        styles={selectStyles}
      />
      {serverError ? (
        <small>
          <i className="fa fa-exclamation-triangle"></i> There is some server
          error. You cannot change content type now but you can try to upload
          data again.
        </small>
      ) : null}
    </>
  );
};

ContentType.propTypes = {
  pageContent: PropTypes.func,
  currentLang: PropTypes.object,
};

export default ContentType;
