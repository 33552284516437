import React, { Fragment, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as CustomerValidationAction from '../../../redux/actions/CustomerValidation';
import * as LanguagesAction from '../../../redux/actions/Languages';
import FullScreenLoader from '../../../components/Layout/FullScreenLoader/FullScreenLoader';
import CreateValidationType from './CreateValidationType';
import NavTabs from '../../../components/Layout/NavTabs';
import PendingValidations from './PendingValidations';
import ChangeValidation from './ChangeValidation';
import {
  getCustomerValidationsData,
  createLoadingSelector,
  getLanguages,
} from '../../../selectors';
import { addNotificationMessage } from '../../../redux/actions/ui';
import { configurationTabs } from './Utils';
import { useMount, useQueryParams } from '../../../hooks';
import { connect } from 'react-redux';

const CustomerValidationBuilder = ({
  isLoading,
  types,
  approve,
  reject,
  addType,
  languages,
  documentTypes,
  pendingUsers,
  paymentMethods,
  getDocumentTypes,
  getPaymentMethods,
  getAllLanguages,
  getPendingsUsers,
  getTypes,
  removeType,
  getAllDocuments,
  allDocumentsTypes,
  verificationDocuments,
  addNotificationMessage,
  history,
  location,
}) => {
  const [selectedTab, setSelectedTab] = useState('');
  const { query, setQueryParams } = useQueryParams(location, history);

  useMount(() => {
    getTypes();
    getAllLanguages();
    getDocumentTypes();
    getPendingsUsers();
    getPaymentMethods();

    setSelectedTab(query.get('tab') || 'pendingVerification');
  });

  const setConfigurationTab = useCallback(
    e => {
      setSelectedTab(e.target.getAttribute('name'));
      setQueryParams({ tab: e.target.getAttribute('name') });
    },
    [setQueryParams]
  );

  const content = useMemo(() => {
    switch (selectedTab) {
      case 'pendingVerification':
        if (pendingUsers.length > 0) {
          return (
            <PendingValidations
              reject={reject}
              approve={approve}
              pendingUsers={pendingUsers}
              addNotificationMessage={addNotificationMessage}
            />
          );
        } else {
          return (
            <h2 className="text-center my-5">
              There are no pending documents for verification at the moment!
            </h2>
          );
        }

      case 'typeVerification':
        if (documentTypes) {
          return (
            <CreateValidationType
              types={types}
              languages={languages}
              documentTypes={documentTypes}
              verificationDocuments={verificationDocuments}
              addNotificationMessage={addNotificationMessage}
            />
          );
        }
        break;

      case 'changeVerification':
        if (paymentMethods && types) {
          return (
            <ChangeValidation
              paymentMethods={paymentMethods}
              types={types}
              addType={addType}
              removeType={removeType}
              getAllDocuments={getAllDocuments}
              allDocumentsTypes={allDocumentsTypes}
              addNotificationMessage={addNotificationMessage}
            />
          );
        }
        break;

      default:
        return '';
    }
  }, [
    selectedTab,
    pendingUsers,
    documentTypes,
    paymentMethods,
    types,
    reject,
    approve,
    addNotificationMessage,
    languages,
    verificationDocuments,
    addType,
    removeType,
    getAllDocuments,
    allDocumentsTypes,
  ]);

  return (
    <Fragment>
      <NavTabs
        navTab={selectedTab}
        setConfigurationTab={setConfigurationTab}
        tabs={configurationTabs}
      />
      {content}
      <FullScreenLoader isActive={isLoading} />
    </Fragment>
  );
};

const loadingReducer = createLoadingSelector([
  'GET_TYPES',
  'GET_PAYMENTS',
  'GET_DOCUMENT_TYPES',
  'GET_PENDING_USERS',
  'GET_ALL_DOCUMENTS',
]);

const mapStateToProps = state => ({
  documentTypes: getCustomerValidationsData(state).documentTypes,
  paymentMethods: getCustomerValidationsData(state).paymentMethods,
  allDocuments: getCustomerValidationsData(state).allDocuments,
  allDocumentsTypes: getCustomerValidationsData(state).allDocumentsTypes,
  pendingUsers: getCustomerValidationsData(state).pendingUsers,
  types: getCustomerValidationsData(state).types,
  languages: getLanguages(state).languages,
  isLoading: loadingReducer(state),
});

const mapDispatchToProps = {
  getDocsAfterVerification: CustomerValidationAction.getDocsAfterVerification,
  verificationDocuments: CustomerValidationAction.verificationDocuments,
  getAllDocuments: CustomerValidationAction.getAllDocuments,
  getDocumentTypes: CustomerValidationAction.getDocumentTypes,
  getPaymentMethods: CustomerValidationAction.getPaymentMethods,
  getPendingsUsers: CustomerValidationAction.getPendingsUsers,
  getAllLanguages: LanguagesAction.getAllLanguages,
  getTypes: CustomerValidationAction.getTypes,
  removeType: CustomerValidationAction.removeType,
  addType: CustomerValidationAction.addType,
  approve: CustomerValidationAction.approve,
  reject: CustomerValidationAction.reject,
  addNotificationMessage,
};

CustomerValidationBuilder.propTypes = {
  languages: PropTypes.array,
  allDocuments: PropTypes.array,
  pendingUsers: PropTypes.array,
  types: PropTypes.array,
  documentTypes: PropTypes.array,
  allDocumentsTypes: PropTypes.array,
  paymentMethods: PropTypes.array,
  getPendingsUsers: PropTypes.func,
  getDocumentTypes: PropTypes.func,
  verificationDocuments: PropTypes.func,
  getDocsAfterVerification: PropTypes.func,
  addNotificationMessage: PropTypes.func,
  getAllLanguages: PropTypes.func,
  addType: PropTypes.func,
  approve: PropTypes.func,
  reject: PropTypes.func,
  getTypes: PropTypes.func,
  removeType: PropTypes.func,
  getAllDocuments: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomerValidationBuilder));
