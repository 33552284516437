import PropTypes from 'prop-types';
import React from 'react';

const Checkbox = ({
  onChange,
  name,
  readOnly,
  isActive,
  label,
  type,
  disabled,
  placeholder
}) => (
  <span
    className={type === 'userActivity' ? 'efb-checkbox' : 'efb-checkbox-new'}
  >
    <input
      type="checkbox"
      onChange={onChange}
      name={name}
      readOnly={readOnly}
      checked={isActive}
      disabled={disabled}
      placeholder={placeholder}
    />
    <label htmlFor={name} onClick={onChange}>
      {label}
    </label>
  </span>
);

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  type: PropTypes.string
};

export default Checkbox;
