import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SystemSettingsTable from './SystemSettingsTable';
import Selected from './Selected';
import Switch from '../../../components/Form/Switch/Switch';
import { connect } from 'react-redux';
import {
  getRiskManagementData,
  createLoadingSelector,
} from '../../../selectors/index';
import {
  getSystemSettings,
  createSystemSettings,
  setSportTypesView,
  setFeedProviders,
} from '../../../redux/actions/riskManagement/systemSettings';
import FullScreenLoader from '../../../components/Layout/FullScreenLoader/FullScreenLoader';
import { addNotificationMessage } from '../../../redux/actions/ui';
import { isChecked, suspendOptions, selectOptions } from './utils';
import _ from 'lodash';
import StickyNav from './StickyNav';

class SystemSettings extends Component {
  state = {
    systemSettings: {
      global: false,
      entries: [],
      feedScopes: [],
    },
    betSettings: {
      feedProvider: null,
      feedScopes: [],
      targetType: 'SPORT', //harcoded param
    },
    isSelectedOpen: false,
    isEditMode: false,
    isSaveMode: false,
  };

  get feedProviders() {
    const providers = this.props.feedProviders.map(feed => ({
      label: feed.name,
      value: feed.scope,
      id: feed.id,
    }));

    providers.unshift({ label: 'All', value: '' });

    return providers;
  }

  componentDidMount() {
    this.props.getSystemSettings();
    this.props.setSportTypesView();
    this.props.setFeedProviders();
  }

  componentDidUpdate(prevProps) {
    //if systemSettings props are not empty object - state = props
    if (prevProps.systemSettings !== this.props.systemSettings) {
      if (!_.isEmpty(this.props.systemSettings)) {
        this.setState({
          systemSettings: this.props.systemSettings,
        });
      }
    }
  }

  onChangeSuspendDropdown = (data, id) => {
    const copySystemSettings = { ...this.state.systemSettings };

    const newState = copySystemSettings.entries.map((item, i) => {
      if (item.id === id) {
        return { ...item, feedScopes: data.value };
      }
      return item;
    });

    copySystemSettings['entries'] = newState;

    this.setState({
      systemSettings: copySystemSettings,
      isSaveMode: true,
    });
  };

  onChangeSystemDropDown = (data, id) => {
    const copySystemSettings = { ...this.state.systemSettings };

    const newState = copySystemSettings.entries.map(item => {
      if (item.id === id) {
        if (!data.value) {
          return { ...item, feedProvider: null };
        }
        return {
          ...item,
          feedProvider: { ...item.feedProvider, id: data.value },
        };
      }
      return item;
    });

    copySystemSettings['entries'] = newState;

    this.setState({
      systemSettings: copySystemSettings,
      isSaveMode: true,
    });
  };

  onChangeGlobalSwitch = e => {
    let feedScopes = [...this.state.systemSettings.feedScopes];
    const { name } = e.target;

    if (feedScopes.includes(name)) {
      feedScopes = feedScopes.filter(scope => scope !== name);
    } else {
      feedScopes.push(name);
    }
    this.setState({
      systemSettings: {
        ...this.state.systemSettings,
        feedScopes,
        global: Boolean(feedScopes.length),
      },
      isSaveMode: true,
    });
  };

  handleSelectedSuspendDropdown = data => {
    let copyBetSettings = { ...this.state.betSettings };

    copyBetSettings.feedScopes = data.value;

    this.setState({
      betSettings: copyBetSettings,
    });
  };

  handleSelectedInputChange = e => {
    const copyBetSettings = { ...this.state.betSettings };

    if (e.targetId) {
      copyBetSettings['targetId'] = e.targetId;
      copyBetSettings['targetName'] = e.value;
    }
    if (e.id) {
      copyBetSettings['feedProvider'] = {
        id: e.id,
        name: e.value,
      };
    }

    this.setState({
      betSettings: copyBetSettings,
    });
  };

  closeSelect = () => {
    this.setState({
      isSelectedOpen: false,
    });
  };

  enableEditMode = () => {
    this.setState({
      isEditMode: true,
      isSelectedOpen: false,
    });
  };

  disableEditMode = () => {
    this.setState({
      isEditMode: false,
      isSaveMode: false,
      systemSettings: this.props.systemSettings,
    });
  };

  addSportSettings = e => {
    e.preventDefault();

    const copySystemSettings = { ...this.state.systemSettings };
    const entries = [this.state.betSettings, ...copySystemSettings.entries];

    if (
      !this.state.betSettings.targetId ||
      !this.state.betSettings.feedProvider ||
      this.state.betSettings.feedScopes.length < 1
    ) {
      this.props.addNotificationMessage(
        'All fields are required!',
        'warning',
        'Warning!'
      );
      return;
    }

    if (copySystemSettings.entries.some(this.checkDuplicateItems)) {
      this.props.addNotificationMessage('Duplicate item', 'error', 'Error!');
      return;
    }

    copySystemSettings['entries'] = entries;

    this.setState(
      {
        systemSettings: copySystemSettings,
        betSettings: {
          feedProvider: null,
          feedScopes: [],
          targetType: 'SPORT',
        },
        isSelectedOpen: false,
        isSaveMode: true,
      },
      () => this.submitSystemSettings()
    );
  };

  checkDuplicateItems = element => {
    if (
      element.targetName === this.state.betSettings.targetName &&
      element.feedProvider.id === this.state.betSettings.feedProvider.id
    ) {
      return true;
    }
  };

  deleteSettings = itemId => {
    const copySystemSettings = { ...this.state.systemSettings };
    copySystemSettings['entries'] = copySystemSettings['entries'].filter(
      entrie => entrie.id !== itemId
    );

    this.setState({
      systemSettings: copySystemSettings,
      isSaveMode: true,
    });
  };

  createNewSettings = e => {
    this.setState(
      {
        sportTemplate: {},
        isSelectedOpen: true,
      },
      () =>
        document
          .getElementById('selected-container')
          .scrollIntoView({ behavior: 'smooth' })
    );
  };

  submitSystemSettings = () => {
    this.props.createSystemSettings(this.state.systemSettings);
    this.disableEditMode();

    this.setState({
      isSaveMode: false,
    });
  };

  render() {
    const { betSettings, systemSettings, isEditMode, isSaveMode } = this.state;
    const { sportTypesView, isLoading, feedProviders } = this.props;

    return (
      <div className="efb-container">
        <StickyNav
          createNewSettings={this.createNewSettings}
          disableEditMode={this.disableEditMode}
          enableEditMode={this.enableEditMode}
          submitSystemSettings={this.submitSystemSettings}
          isSaveMode={isSaveMode}
          isEditMode={isEditMode}
        />
        <hr />
        <div className="row">
          <div className="col-md-4">
            <Switch
              label="Suspend Pre-match"
              name="PREMATCH"
              isChecked={isChecked(systemSettings, 'PREMATCH')}
              isDisabled={!isEditMode}
              onChange={this.onChangeGlobalSwitch}
            />
          </div>
          <div className="col-md-4">
            <Switch
              label="Suspend In-play"
              name="LIVE"
              isChecked={isChecked(systemSettings, 'LIVE')}
              isDisabled={!isEditMode}
              onChange={this.onChangeGlobalSwitch}
            />
          </div>
        </div>
        {isLoading ? <FullScreenLoader isActive={true} /> : null}
        <SystemSettingsTable
          data={systemSettings.entries || []}
          onDelete={this.deleteSettings}
          onChangeSuspendDropdown={this.onChangeSuspendDropdown}
          onChangeSystemDropDown={this.onChangeSystemDropDown}
          suspendOptions={suspendOptions}
          feedProviders={this.feedProviders}
          isEditMode={isEditMode}
        />
        {this.state.isSelectedOpen ? (
          <Selected
            closeSelect={this.closeSelect}
            betSettings={betSettings}
            sportTypesView={sportTypesView}
            feedProviders={feedProviders}
            selectOptions={selectOptions}
            suspendOptions={suspendOptions}
            addSportSettings={this.addSportSettings}
            handleSelectedInputChange={this.handleSelectedInputChange}
            handleSelectedSuspendDropdown={this.handleSelectedSuspendDropdown}
          />
        ) : null}
      </div>
    );
  }
}

SystemSettings.propTypes = {
  systemSettings: PropTypes.object,
  sportTypesView: PropTypes.array,
  feedProviders: PropTypes.array,
  getSystemSettings: PropTypes.func,
  createSystemSettings: PropTypes.func,
  setSportTypesView: PropTypes.func,
  setFeedProviders: PropTypes.func,
  addNotificationMessage: PropTypes.func,
};

const loadingSelector = createLoadingSelector(['SET_SYSTEM_SETTINGS']);

const mapStateToProps = state => ({
  systemSettings: getRiskManagementData(state).systemSettingsReducer
    .systemSettings,
  sportTypesView: getRiskManagementData(state).systemSettingsReducer
    .sportTypesView,
  feedProviders: getRiskManagementData(state).systemSettingsReducer
    .feedProviders,
  isLoading: loadingSelector(state),
});

const mapDispatchToProps = {
  getSystemSettings,
  createSystemSettings,
  setSportTypesView,
  setFeedProviders,
  addNotificationMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemSettings);
