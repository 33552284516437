import React, { useCallback, useState } from 'react';
import Button from '../../Layout/Button';
import { useUpdate, useStateForInput } from '../../../hooks';
import InputField from '../../Form/InputField';
import { confirmAlert } from 'react-confirm-alert';
import { getSvgFlag } from '../../../utils/flags';

const TranslationsTableRow = ({
  tableData,
  updateTranslation,
  deleteTranslation,
  language,
}) => {
  const [translations, setTranslations] = useState([]);
  const [translationValue, setTranslationValue] = useStateForInput('');
  const [currentTranslationIndex, setCurrentTranslationIndex] = useState(0);

  useUpdate(() => {
    setTranslations(tableData);
  }, [tableData]);

  useUpdate(() => {
    const translationsClone = [...translations];
    if (translationsClone.length > 0) {
      translationsClone[currentTranslationIndex].value = translationValue;
    }
    setTranslations(translationsClone);
  }, [translationValue, currentTranslationIndex]);

  const prepareEditMode = useCallback(
    index => {
      const translationsClone = [...translations];
      translationsClone.forEach(tr => {
        tr.editMode = false;
      });
      setCurrentTranslationIndex(index);
      setTranslationValue(translationsClone[index].value);
      translationsClone[index].editMode = true;
      setTranslations(translationsClone);
    },
    [setTranslationValue, translations]
  );

  const cancelEditMode = useCallback(() => {
    const translationsClone = [...translations];
    translationsClone.forEach(tr => {
      tr.editMode = false;
    });
    setTranslations(translationsClone);
  }, [translations]);

  const saveChanges = useCallback(() => {
    updateTranslation(translations[currentTranslationIndex]);
  }, [currentTranslationIndex, translations, updateTranslation]);

  const proceedToDeleteTranslation = useCallback(
    translation => {
      confirmAlert({
        title: 'Confirm to delete',
        message: 'Are you sure?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              deleteTranslation({
                [translation.key]: translation.value,
              });
            },
          },
          {
            label: 'No',
          },
        ],
      });
    },
    [deleteTranslation]
  );

  const mapTranslations = useCallback(
    (translation, index) => {
      return (
        <tr key={translation.key}>
          <td>
            <img
              src={getSvgFlag(language === 'en' ? 'gb' : language)}
              style={{ width: 20 }}
              alt="country-icon"
            />
          </td>
          <td>{translation.key}</td>
          <td>
            {translation.editMode ? (
              <div className="efb-form" style={{ height: 45 }}>
                <InputField
                  value={translationValue}
                  onChange={setTranslationValue}
                />
              </div>
            ) : (
              translation.value
            )}
          </td>
          <td>
            {!translation.editMode ? (
              <>
                {' '}
                <Button
                  cssClass={
                    'btn btn-sm btn-outline-secondary btn-DEMO-edit-val'
                  }
                  onClick={() => prepareEditMode(index)}
                >
                  <i className="fas fa-edit" /> Edit
                </Button>
                <Button
                  onClick={() => proceedToDeleteTranslation(translation)}
                  cssClass={'btn btn-sm btn-outline-danger btn-DEMO-del-val'}
                >
                  <i className="fas fa-trash" /> Delete
                </Button>
              </>
            ) : (
              <>
                {' '}
                <Button
                  onClick={cancelEditMode}
                  cssClass={'btn btn-sm btn-DEMO-edit-val btn-secondary'}
                >
                  Cancel
                </Button>
                <Button
                  cssClass={'btn btn-sm btn-DEMO-del-val btn-primary'}
                  onClick={saveChanges}
                >
                  Save
                </Button>
              </>
            )}
          </td>
        </tr>
      );
    },
    [
      cancelEditMode,
      language,
      prepareEditMode,
      proceedToDeleteTranslation,
      saveChanges,
      setTranslationValue,
      translationValue,
    ]
  );
  return translations.map(mapTranslations);
};

export default TranslationsTableRow;
