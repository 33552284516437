import React, { useState, useCallback } from 'react';
import SearchIcon from '../../../../components/Icons/UI/ui/IconSearch';
import Input from '../../../../components/Form/Input';
import Button from '../../../../components/Layout/Button';
import { useMount } from '../../../../hooks';

const AddEvents = ({ betScrollerEvents, betScroller, onChangeEvents }) => {
  const [searchedEvents, setSearchedEvents] = useState([]);

  useMount(() => {
    if (!betScroller.id) {
      onChangeEvents({
        ...betScroller,
        events: betScrollerEvents.map(event => ({
          id: event.id,
          code: event.name,
        })),
      });
    }
  });

  const handleSearchChange = useCallback(
    e => {
      setSearchedEvents(
        e.target.value
          ? betScrollerEvents.filter(
              event =>
                event.name
                  .toLowerCase()
                  .indexOf(e.target.value.toLowerCase()) !== -1
            )
          : []
      );
    },
    [betScrollerEvents]
  );

  const removeEvent = useCallback(
    eventId => {
      onChangeEvents({
        ...betScroller,
        events: betScroller.events.filter(event => event.id !== eventId),
      });
    },
    [betScroller, onChangeEvents]
  );

  const addEvent = useCallback(
    event => {
      onChangeEvents({
        ...betScroller,
        events: [
          ...betScroller.events,
          {
            code: event.name,
            id: event.id,
          },
        ],
      });
    },
    [betScroller, onChangeEvents]
  );

  return (
    <>
      <div className="bet-scroller-search">
        <SearchIcon width={16} height={16} />
        <Input customInput placeholder="Search" onChange={handleSearchChange} />
      </div>
      <div className="overflow-scroll-box mb-5">
        {searchedEvents.map(event => (
          <div className="bet-scroller-event p-1" key={event.id}>
            <div className="bet-scroller-event-name">{event.name}</div>
            <div>
              {betScroller.events.find(bsEvent => bsEvent.id === event.id) ? (
                <Button
                  cssClass="btn btn-sm"
                  onClick={() => removeEvent(event.id)}
                >
                  Remove
                </Button>
              ) : (
                <Button cssClass="btn btn-sm" onClick={() => addEvent(event)}>
                  Add
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AddEvents;
