import React from 'react';

export const DBCore = (props) => {
    return (
	<span className='efb-icon'>
    	<svg className={`efb-icon--${props.size} ${props.cssClass}`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 128 128" >

	
			<path fill="#545E73" d="M126.4,100.4L126.4,100.4c-0.5-2.7-7-4.9-15.1-4.9s-14.6,2.2-15.1,4.9l0,0h0v0.3v7.1v0.3v0.3v6.8v0.3v0.3
			v7.4h0c0.5,2.7,7,4.9,15.1,4.9s14.6-2.2,15.1-4.9h0v-7.4v-0.3v-0.3v-6.8v-0.3v-0.3v-7.1L126.4,100.4L126.4,100.4z"/>
		
			<path fill="#38454F" d="M111.3,120.7c-8.3,0-15.1-2.3-15.1-5.2v7.7h0c0.5,2.7,7,4.9,15.1,4.9s14.6-2.2,15.1-4.9h0v-7.7
				C126.4,118.4,119.6,120.7,111.3,120.7z"/>
			<path fill="#38454F" d="M96.2,115.2v0.3C96.2,115.4,96.2,115.3,96.2,115.2L96.2,115.2z"/>
			<path fill="#38454F" d="M126.4,115.2c0,0.1,0,0.2,0,0.3L126.4,115.2L126.4,115.2z"/>
		
		
			<path fill="#556080" d="M111.3,113.3c-8.3,0-15.1-2.3-15.1-5.2v7.7h0c0.5,2.7,7,4.9,15.1,4.9s14.6-2.2,15.1-4.9h0v-7.7
				C126.4,111,119.6,113.3,111.3,113.3z"/>
			<path fill="#556080" d="M96.2,107.8v0.3C96.2,108,96.2,107.9,96.2,107.8L96.2,107.8z"/>
			<path fill="#556080" d="M126.4,107.8c0,0.1,0,0.2,0,0.3L126.4,107.8L126.4,107.8z"/>
		
			<ellipse fill="#91BAE1" cx="111.3" cy="100.7" rx="15.1" ry="5.2"/>
		
			<path fill="#8697CB" d="M111.3,106c-8.3,0-15.1-2.3-15.1-5.2v7.7h0c0.5,2.7,7,4.9,15.1,4.9s14.6-2.2,15.1-4.9h0v-7.7
				C126.4,103.6,119.6,106,111.3,106z"/>
			<path fill="#8697CB" d="M96.2,100.4v0.3C96.2,100.6,96.2,100.5,96.2,100.4L96.2,100.4z"/>
			<path fill="#8697CB" d="M126.4,100.4c0,0.1,0,0.2,0,0.3L126.4,100.4L126.4,100.4z"/>

	
			<path fill="#545E73" d="M79.4,100.4L79.4,100.4c-0.5-2.7-7-4.9-15.1-4.9s-14.6,2.2-15.1,4.9l0,0h0v0.3v7.1v0.3v0.3v6.8v0.3v0.3
			v7.4h0c0.5,2.7,7,4.9,15.1,4.9s14.6-2.2,15.1-4.9h0v-7.4v-0.3v-0.3v-6.8v-0.3v-0.3v-7.1L79.4,100.4L79.4,100.4z"/>
		
			<path fill="#38454F" d="M64.4,120.7c-8.3,0-15.1-2.3-15.1-5.2v7.7h0c0.5,2.7,7,4.9,15.1,4.9s14.6-2.2,15.1-4.9h0v-7.7
				C79.5,118.4,72.7,120.7,64.4,120.7z"/>
			<path fill="#38454F" d="M49.3,115.2v0.3C49.3,115.4,49.3,115.3,49.3,115.2L49.3,115.2z"/>
			<path fill="#38454F" d="M79.4,115.2c0,0.1,0,0.2,0,0.3L79.4,115.2L79.4,115.2z"/>
		
		
			<path fill="#556080" d="M64.4,113.3c-8.3,0-15.1-2.3-15.1-5.2v7.7h0c0.5,2.7,7,4.9,15.1,4.9s14.6-2.2,15.1-4.9h0v-7.7
				C79.5,111,72.7,113.3,64.4,113.3z"/>
			<path fill="#556080" d="M49.3,107.8v0.3C49.3,108,49.3,107.9,49.3,107.8L49.3,107.8z"/>
			<path fill="#556080" d="M79.4,107.8c0,0.1,0,0.2,0,0.3L79.4,107.8L79.4,107.8z"/>
		
			<ellipse fill="#91BAE1" cx="64.4" cy="100.7" rx="15.1" ry="5.2"/>
		
			<path fill="#8697CB" d="M64.4,106c-8.3,0-15.1-2.3-15.1-5.2v7.7h0c0.5,2.7,7,4.9,15.1,4.9s14.6-2.2,15.1-4.9h0v-7.7
				C79.5,103.6,72.7,106,64.4,106z"/>
			<path fill="#8697CB" d="M49.3,100.4v0.3C49.3,100.6,49.3,100.5,49.3,100.4L49.3,100.4z"/>
			<path fill="#8697CB" d="M79.4,100.4c0,0.1,0,0.2,0,0.3L79.4,100.4L79.4,100.4z"/>

	
			<path fill="#545E73" d="M31.7,100.4L31.7,100.4c-0.5-2.7-7-4.9-15.1-4.9s-14.6,2.2-15.1,4.9l0,0h0v0.3v7.1v0.3v0.3v6.8v0.3v0.3
			v7.4h0c0.5,2.7,7,4.9,15.1,4.9s14.6-2.2,15.1-4.9h0v-7.4v-0.3v-0.3v-6.8v-0.3v-0.3v-7.1L31.7,100.4L31.7,100.4z"/>
		
			<path fill="#38454F" d="M16.7,120.7c-8.3,0-15.1-2.3-15.1-5.2v7.7h0c0.5,2.7,7,4.9,15.1,4.9s14.6-2.2,15.1-4.9h0v-7.7
				C31.8,118.4,25,120.7,16.7,120.7z"/>
			<path fill="#38454F" d="M1.6,115.2v0.3C1.6,115.4,1.6,115.3,1.6,115.2L1.6,115.2z"/>
			<path fill="#38454F" d="M31.7,115.2c0,0.1,0,0.2,0,0.3L31.7,115.2L31.7,115.2z"/>
		
		
			<path fill="#556080" d="M16.7,113.3c-8.3,0-15.1-2.3-15.1-5.2v7.7h0c0.5,2.7,7,4.9,15.1,4.9s14.6-2.2,15.1-4.9h0v-7.7
				C31.8,111,25,113.3,16.7,113.3z"/>
			<path fill="#556080" d="M1.6,107.8v0.3C1.6,108,1.6,107.9,1.6,107.8L1.6,107.8z"/>
			<path fill="#556080" d="M31.7,107.8c0,0.1,0,0.2,0,0.3L31.7,107.8L31.7,107.8z"/>
		
			<ellipse fill="#91BAE1" cx="16.7" cy="100.7" rx="15.1" ry="5.2"/>
		
			<path fill="#8697CB" d="M16.7,106c-8.3,0-15.1-2.3-15.1-5.2v7.7h0c0.5,2.7,7,4.9,15.1,4.9s14.6-2.2,15.1-4.9h0v-7.7
				C31.8,103.6,25,106,16.7,106z"/>
			<path fill="#8697CB" d="M1.6,100.4v0.3C1.6,100.6,1.6,100.5,1.6,100.4L1.6,100.4z"/>
			<path fill="#8697CB" d="M31.7,100.4c0,0.1,0,0.2,0,0.3L31.7,100.4L31.7,100.4z"/>

			<path fill="#BDC3C7" d="M114,89.1c0-4.6-3.3-8.1-7.9-8.1H67V69h-6v12H22.3c-4.6,0-8.3,3.5-8.3,8.1v4.5v3.3v4c0,0.9,0.7,2.2,1.7,2.2
				h2.7c0.9,0,1.6-1.2,1.6-2.2V97l0,0v-7.9c0-1.1,1.2-2.1,2.3-2.1H61v10l0,0v3.8c0,0.9,0.9,2.2,1.8,2.2h2.7c0.9,0,1.5-1.2,1.5-2.2V97
				l0,0V87h39.1c1.1,0,1.9,1,1.9,2.1V97l0,0v3.8c0,0.9,1,2.2,2,2.2h2.7c0.9,0,1.3-1.2,1.3-2.2V97l0,0V89.1z"/>
			<path fill="#FEB719" d="M98.7,35c0,19.2-15.6,34.7-34.7,34.7S29.3,54.2,29.3,35S44.8,0.3,64,0.3S98.7,15.8,98.7,35z M64,11.9
				c-13.4,0-24.2,10.4-24.2,23.1S50.6,58.1,64,58.1S88.2,47.8,88.2,35S77.4,11.9,64,11.9z"/>

    		</svg>
			{props.children}
		</span>
    )
}

export default DBCore;