import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compObj } from './CompetitorObj';
import {
  createLoadingSelector,
  getCMSData,
  getLanguages,
} from '../../../selectors';
import CompetitorForm from './Form/CompetitorForm';
import CompetitorTable from './CompetitorList/CompetitorTable';
import CompetitorSearch from './CompetitorList/CompetitorSearch';
import * as CompetitorActions from '../../../redux/actions/cms/competitorBuilder';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import FullScreenLoader from '../../../components/Layout/FullScreenLoader/FullScreenLoader';
import { useMount } from '../../../hooks';
import { v1 as uuidv1 } from 'uuid';
import * as LanguagesActions from '../../../redux/actions/Languages';

const CompetitorBuilder = ({
  getSports,
  getCountryCodes,
  createCompetitor,
  updateCompetitor,
  sports,
  countryCodes,
  competitors,
  loaded,
  getCompetitors,
  languages,
  getAllLanguages,
}) => {
  const [competitorsObj, setCompetitorsObj] = useState(compObj);
  const [editMode, setEditMode] = useState(false);
  const [sportId, setSportId] = useState('');

  useMount(() => {
    getSports();
    getCountryCodes();
    getAllLanguages();
  });

  const prepareToCreateCompetitor = useCallback(() => {
    if (!editMode) {
      const competitorsObjClone = { ...competitorsObj };
      competitorsObjClone.feedProducers[0].feedProviderId = uuidv1();
      createCompetitor(competitorsObj);
    } else {
      updateCompetitor(competitorsObj);
    }
    setEditMode(false);
    setCompetitorsObj(compObj);
  }, [competitorsObj, createCompetitor, editMode, updateCompetitor]);

  const searchCompetitors = debounce(value => {
    if (value.length >= 3) {
      getCompetitors(value, sportId);
    }
  }, 1000);

  const editCompetitor = useCallback(
    competitor => {
      if (!competitor.country) {
        competitor['countries'] = competitorsObj.countries;
      }
      competitor.countryCode = competitor.country.countryCode;
      setEditMode(true, setCompetitorsObj(competitor));
    },
    [competitorsObj.countries]
  );

  return (
    <>
      <FullScreenLoader isActive={loaded} />
      <CompetitorForm
        competitorsObj={competitorsObj}
        sports={sports}
        countryCodes={countryCodes}
        createCompetitor={prepareToCreateCompetitor}
        isEditMode={editMode}
        setSportId={setSportId}
        languages={languages}
        cancelEdit={() => {
          setEditMode(false);
          setCompetitorsObj(compObj);
        }}
        setCompetitorsObj={setCompetitorsObj}
      />
      <hr />
      <CompetitorSearch
        searchCompetitors={searchCompetitors}
        sportId={sportId}
      />
      {!sportId ? (
        <small>
          <i className="fa fa-info-circle"></i> You need to select sport
          firstly.
        </small>
      ) : null}
      <CompetitorTable
        competitors={competitors}
        editCompetitor={editCompetitor}
      />
    </>
  );
};

const loadingReducer = createLoadingSelector(['GET_COMPETITORS_LIST']);

CompetitorBuilder.propTypes = {
  createCompetitor: PropTypes.func,
  updateObj: PropTypes.func,
  sports: PropTypes.array,
  competitors: PropTypes.array,
};

const mapStateToProps = state => ({
  sports: getCMSData(state).competitorReducer.sports,
  countryCodes: getCMSData(state).competitorReducer.countryCodes,
  competitors: getCMSData(state).competitorReducer.competitors,
  loaded: loadingReducer(state),
  languages: getLanguages(state).languages,
});

const mapDispatchToProps = {
  createCompetitor: CompetitorActions.createCompetitor,
  getCompetitors: CompetitorActions.getCompetitors,
  getSports: CompetitorActions.getSports,
  getCountryCodes: CompetitorActions.getCountryCodes,
  getAllLanguages: LanguagesActions.getAllLanguages,
  updateCompetitor: CompetitorActions.updateCompetitor,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompetitorBuilder)
);
