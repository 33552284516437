import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { selectStyles } from '../../customReactSelectStyle';
import { useUpdate, useStateForSelect } from '../../../../hooks';

const Country = ({
  competitorsObj,
  countryCodes,
  setCompetitorsObj,
  isEditMode,
}) => {
  const [country, setCountry] = useStateForSelect('');
  const [editInitialMode, setEditInitialMode] = useState(false);

  useUpdate(() => {
    if (isEditMode) setEditInitialMode(true);
  }, [isEditMode]);

  useUpdate(() => {
    setCompetitorsObj({ ...competitorsObj, countryCode: country.value });
  }, [country]);

  useUpdate(() => {
    if (!competitorsObj.countryCode) {
      setCountry('');
    } else {
      if (editInitialMode) {
        const countryFromApi = countryCodes.find(
          x => x.countryCode === competitorsObj.countryCode
        );
        if (countryFromApi) {
          setCountry({
            value: countryFromApi.countryCode,
            label: `${countryFromApi.countryCode}
              ${
                Object.keys(countryFromApi.names).length > 0
                  ? ` - ${countryFromApi.names.en.name}`
                  : ''
              }`,
          });
        }
        setEditInitialMode(false);
      }
    }
  }, [competitorsObj, editInitialMode]);

  const mappedCountryCodes = useMemo(() => {
    return countryCodes
      ? countryCodes.reduce(
          (acc, country) => [
            ...acc,
            {
              value: country.countryCode,
              label: `${country.countryCode}
                ${
                  Object.keys(country.names).length > 0
                    ? ` - ${country.names.en.name}`
                    : ''
                }`,
            },
          ],
          []
        )
      : [];
  }, [countryCodes]);

  return (
    <>
      <h3 className="efb-h3">Country</h3>
      <div className="form-row">
        <div className="form-label-group col-md">
          <Select
            name="countryCode"
            styles={selectStyles}
            options={mappedCountryCodes}
            value={country}
            onChange={setCountry}
          />
        </div>
      </div>
    </>
  );
};

Country.propTypes = {
  competitorsObj: PropTypes.object,
  updateObj: PropTypes.func,
  countryCodes: PropTypes.array,
};

export default Country;
