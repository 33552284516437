import React, { Fragment, useMemo, useCallback } from 'react';
import Select from 'react-select';
import { selectStyles } from '../../../customReactSelectStyle';
import PropTypes from 'prop-types';
import { useUpdate, useStateForSelect } from '../../../../../hooks';

const Selectors = ({ sports, countries, tournaments, seasons, filters, updateFilter }) => {
	const [ sportValue, setSportValue ] = useStateForSelect('');
	const [ countryValue, setCountryValue ] = useStateForSelect('');
	const [ tournamentValue, setTournamentValue ] = useStateForSelect('');
	const [ seasonValue, setSeasonValue ] = useStateForSelect('');

	useUpdate(
		() => {
			if (filters.sport === '') setSportValue('');
			if (countries.length === 0) setCountryValue('');
			if (tournaments.length === 0) setTournamentValue('');
			if (seasons.length === 0) setSeasonValue('');
		},
		[ countries, tournaments, seasons, filters ]
	);

	useUpdate(() => {
		setCountryValue('');
	}, [sportValue])

	useUpdate(() => {
		setTournamentValue('');
	}, [countryValue])

	useUpdate(() => {
		setSeasonValue('');
	}, [tournamentValue])

	useUpdate(
		() => {
			if (Object.keys(sportValue).length > 0) setSelectorValueToObj(sportValue, 'sport');
			if (Object.keys(countryValue).length > 0) setSelectorValueToObj(countryValue, 'country');
			if (Object.keys(tournamentValue).length > 0) setSelectorValueToObj(tournamentValue, 'tournament');
			if (Object.keys(seasonValue).length > 0) setSelectorValueToObj(seasonValue, 'season');
		},
		[ sportValue, countryValue, tournamentValue, seasonValue ]
	);

	const setSelectorValueToObj = useCallback(
		(value, name) => {
			const filtersClone = { ...filters };
			filtersClone[value.name] = value.value.id;
			updateFilter(filtersClone, 'general', name);
		},
		[ filters, updateFilter ]
	);

	const filteredOptions = useCallback((options, name) => {
		if (options) {
			return options.reduce(
				(acc, val) => [ ...acc, { value: { id: val.id }, label: val.names.en.name, name: name } ],
				[]
			);
		}
	}, []);

	const filteredSports = useMemo(
		() => {
			return filteredOptions(sports, 'sport');
		},
		[ sports, filteredOptions ]
	);

	const filteredCountries = useMemo(
		() => {
			return filteredOptions(countries, 'country');
		},
		[ countries, filteredOptions ]
	);

	const filteredTournaments = useMemo(
		() => {
			return filteredOptions(tournaments, 'tournament');
		},
		[ tournaments, filteredOptions ]
	);

	const filteredSeasons = useMemo(
		() => {
			return filteredOptions(seasons, 'season');
		},
		[ seasons, filteredOptions ]
	);

	return (
		<Fragment>
			<div className="form-row">
				<div className="col-md">
					<Select
						value={sportValue}
						options={filteredSports}
						onChange={setSportValue}
						placeholder="Select Sport"
						styles={selectStyles}
					/>
				</div>
			</div>
			<hr />
			<div className="form-row">
				<div className="col-md">
					<label className="d-none">Country</label>
					<Select
						value={countryValue}
						options={filteredCountries}
						onChange={setCountryValue}
						isDisabled={filteredCountries.length === 0}
						placeholder="Select Country"
						styles={selectStyles}
					/>
				</div>
				<div className="col-md">
					<label className="d-none">Tournament</label>
					<Select
						value={tournamentValue}
						options={filteredTournaments}
						onChange={setTournamentValue}
						isDisabled={filteredTournaments.length === 0}
						placeholder="Select Tournament"
						styles={selectStyles}
					/>
				</div>
				<div className="col-md">
					<label className="d-none">Season</label>
					<Select
						value={seasonValue}
						options={filteredSeasons}
						onChange={setSeasonValue}
						isDisabled={filteredSeasons.length === 0}
						placeholder="Select Season"
						styles={selectStyles}
					/>
				</div>
			</div>
		</Fragment>
	);
};

Selectors.propTypes = {
	filters: PropTypes.object,
	countries: PropTypes.array,
	handleInputChange: PropTypes.func,
	seasons: PropTypes.array,
	sports: PropTypes.array,
	tournaments: PropTypes.array
};

export default Selectors;
