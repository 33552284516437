import React from 'react';

const IconFolderBooked = ({
  className,
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      className={className}
      viewBox="0 0 15 12" {...otherProps}>
      <g
        transform="translate(-252 -113.353)"
        opacity="0.86"
      >
        <g
          transform="translate(252 113.353)"
          fill="none"
          stroke="#727272"
          stroke-linejoin="round"
          stroke-width="1"
        >
          <rect
            width="7"
            height="3"
            rx="1"
            stroke="none"
          />
          <rect
            x="0.5"
            y="0.5"
            width="6"
            height="2"
            rx="0.5"
            fill="none"
          />
        </g>
        <rect
          width="15"
          height="11"
          rx="1"
          transform="translate(252 114.353)"
          fill="#aeaeae"
        />
      </g>
    </svg>);
};

export default IconFolderBooked;