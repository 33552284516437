import React from 'react';
import classes from './General.module.scss';
import PersonalInfo from './PersonalInfo';
import AccountInfo from './AccountInfo';
import IdInfo from './IdInfo';
import Channels from './Channels';
import Buttons from '../../../../../components/ModalButtons/Buttons';

const General = ({ customer, refetchCustomer, onClose }) => {
  if (!customer) return null;
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.info}>
          <PersonalInfo customer={customer} refetchCustomer={refetchCustomer} />
          <AccountInfo customer={customer} />
          <IdInfo customer={customer} />
          <Channels />
        </div>
        <div className={classes.address}>
          <h1>Address</h1>
          <p>{customer.address || 'No address added...'}</p>
        </div>
      </div>
      <Buttons cancel={onClose} />
    </>
  );
};

export default General;
