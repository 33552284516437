import React from 'react';

export const CallCenter = (props) => {
    return (
	<span className='efb-icon'>
    	<svg className={`efb-icon--${props.size} ${props.cssClass}`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 128 128" >
	
        
        <path fill="#3A556A" d="M117.4,80.1H111V52.4C111,27,89.9,6.4,64,6.4S17,27,17,52.4v27.7h-6.4V52.4C10.6,23.5,34.6,0,64,0
            c29.4,0,53.4,23.5,53.4,52.4L117.4,80.1L117.4,80.1z"/>
        <path fill="#EBF0F3" d="M88.9,65.2v25.9c0,5.6,4.5,10.1,10,10.1V55.1C93.4,55.1,88.9,59.6,88.9,65.2z"/>
        <path fill="#E1E6E9" d="M98.9,55.1L98.9,55.1v46.1l0,0c5.5,0,10-4.5,10-10.1V65.2C108.9,59.6,104.5,55.1,98.9,55.1z"/>
        <path fill="#64798A" d="M108.9,64.5v27.3c7.5,0,13.6-6.1,13.6-13.7C122.5,70.6,116.4,64.5,108.9,64.5z"/>
        <path fill="#EBF0F3" d="M39.1,65.2v25.9c0,5.6-4.5,10.1-10,10.1V55.1C34.6,55.1,39.1,59.6,39.1,65.2z"/>
        <path fill="#E1E6E9" d="M29.1,55.1L29.1,55.1v46.1l0,0c-5.5,0-10-4.5-10-10.1V65.2C19.1,59.6,23.5,55.1,29.1,55.1z"/>
        <path fill="#64798A" d="M19.1,64.5v27.3c-7.5,0-13.6-6.1-13.6-13.7C5.5,70.6,11.6,64.5,19.1,64.5z"/>
	
		<path fill="#FEB719" d="M69.3,115.1c-0.1,0-0.3,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.3l-0.1-2.1c0-0.3,0.2-0.5,0.5-0.5
			c26.3-0.7,39.3-22.4,39.4-22.6c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0.1l1.8,1.1c0.2,0.1,0.3,0.4,0.2,0.7
			C110.7,91.9,97.1,114.4,69.3,115.1C69.3,115.1,69.3,115.1,69.3,115.1z"/>
		<path fill="#FEB719" d="M109,89.6l1.8,1.1c-0.1,0.2-13.9,23.2-41.6,23.9l-0.1-2.1C95.8,111.7,108.9,89.8,109,89.6 M109,88.6
			c-0.1,0-0.2,0-0.3,0c-0.3,0.1-0.5,0.2-0.6,0.5l0,0c-0.1,0.2-13,21.7-39,22.3c-0.6,0-1,0.5-1,1l0.1,2.1c0,0.3,0.1,0.5,0.3,0.7
			c0.2,0.2,0.4,0.3,0.7,0.3c0,0,0,0,0,0c28.1-0.7,42.3-24.1,42.4-24.4c0.3-0.5,0.1-1.1-0.4-1.4l-1.8-1.1
			C109.4,88.6,109.2,88.6,109,88.6L109,88.6z"/>
        <path fill="#FEB719" d="M111,92.3c-0.1,0-0.2,0-0.2-0.1l-3-1.5c-0.2-0.1-0.3-0.4-0.2-0.7l3.1-6.4c-1-1.4-1.2-3.2-0.4-4.8
            c0.8-1.6,2.4-2.6,4.2-2.6c0.7,0,1.4,0.2,2,0.5c2.3,1.1,3.3,3.9,2.1,6.2c-0.7,1.6-2.3,2.5-4,2.6l-3.1,6.4
            c-0.1,0.1-0.2,0.2-0.3,0.3C111.1,92.3,111,92.3,111,92.3z"/>
        <path fill="#FEB719" d="M114.3,76.7c0.6,0,1.2,0.1,1.8,0.4c2.1,1,2.9,3.5,1.9,5.6c-0.7,1.5-2.2,2.3-3.7,2.3c0,0-0.1,0-0.1,0
            l-3.2,6.7l-3-1.5l3.2-6.7c-1-1.2-1.3-3-0.6-4.5C111.3,77.6,112.8,76.7,114.3,76.7 M114.3,75.7L114.3,75.7c-2,0-3.8,1.1-4.6,2.9
            c-0.8,1.6-0.7,3.5,0.3,5l-3,6.2c-0.2,0.5,0,1.1,0.5,1.3l3,1.5c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3-0.1
            c0.3-0.1,0.5-0.3,0.6-0.5l3-6.2c1.8-0.2,3.3-1.2,4.1-2.9c1.2-2.6,0.2-5.7-2.4-6.9C115.8,75.9,115.1,75.7,114.3,75.7L114.3,75.7z"
            />
		<circle fill="#FEB719" cx="65.5" cy="115.1" r="13"/>
	    <ellipse fill="#FFFFFF" cx="65.6" cy="114.8" rx="7.9" ry="7.6"/>

    	</svg>     
		{props.children}
	</span>
    )
}

export default CallCenter;