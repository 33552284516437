import React, { useCallback } from 'react';
import Switch from '../../Form/Switch/Switch';

const CategoriesRow = React.memo(({ tableData, onChange, categories }) => {
  const renderRow = useCallback(
    cat => (
      <tr key={cat.id}>
        <td>{`${cat.name} - ${cat.description}`}</td>
        <td>
          <Switch
            name={cat.id}
            id={cat.id}
            isChecked={categories.find(fs => fs.id === cat.id)}
            onChange={onChange}
          />
        </td>
      </tr>
    ),

    [categories, onChange]
  );
  return tableData.map(renderRow);
});

export default CategoriesRow;
