import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import * as StsActions from '../../../../redux/actions/cms/stsBuilder';
import LanguageSelector from '../../../../components/LanguageSelector/LanguageSelector';
import BusinessUnitSelector from '../../../../components/BusinessUnitSelector/BusinessUnitSelector';
import InputField from '../../../../components/Form/InputField';
import Button from '../../../../components/Layout/Button';
import StsTable from '../StsTable';
import {
  useStateForInput,
  useUpdate,
  useStateForSelect,
} from '../../../../hooks';
import { getStsBuilderData } from '../../../../selectors';
import { handleSort, handleSearch } from '../StsUtils';

const TournamentsTable = ({
  languages,
  tournaments,
  editTournament,
  editingTournament,
  updateRanking,
  updateTournament,
}) => {
  const [newRanking, setNewRanking] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [search, setSearch, resetSearch] = useStateForInput('');
  const [selectedBu, setSelectedBu] = useStateForSelect('BG');

  const rankedTournaments = useMemo(() => handleSort(tournaments, selectedBu), [
    selectedBu,
    tournaments,
  ]);

  const [tournamentsForTable, setTournamentsForTable] = useState(
    rankedTournaments
  );

  useUpdate(() => {
    setTournamentsForTable(rankedTournaments);
    resetSearch();
  }, [rankedTournaments]);

  useUpdate(() => {
    setTournamentsForTable(
      handleSearch(rankedTournaments, search, selectedLanguage)
    );
  }, [search]);

  const submitRanking = useCallback(() => {
    updateRanking(
      tournamentsForTable.map((tournament, index) => ({
        id: tournament.id,
        businessUnit: selectedBu,
        rank: index,
        type: 'TOURNAMENT',
      }))
    );
    setNewRanking(false);
  }, [selectedBu, tournamentsForTable, updateRanking]);

  const resetRanking = useCallback(() => {
    setTournamentsForTable(rankedTournaments);
    setNewRanking(false);
  }, [rankedTournaments]);

  const onDrop = useCallback(data => {
    setTournamentsForTable(data);
    setNewRanking(true);
  }, []);

  const updateStatus = useCallback(
    (tournament, status) => {
      updateTournament({
        ...tournament,
        status,
      });
    },
    [updateTournament]
  );

  return (
    <>
      <LanguageSelector
        languages={languages}
        onChange={setSelectedLanguage}
        defaultLang={selectedLanguage}
      />
      <br />
      <BusinessUnitSelector onChange={setSelectedBu} defaultBu={selectedBu} />
      <div className="efb-form my-3">
        <InputField
          id="search"
          name="search"
          placeholder="Search"
          type="text"
          className="form-control"
          value={search}
          onChange={setSearch}
        />
      </div>

      {newRanking ? (
        <div className="my-3">
          <Button onClick={submitRanking} cssClass="btn btn-primary">
            Save Rankings
          </Button>
          <Button onClick={resetRanking} cssClass="btn btn-danger">
            Reset Rankings
          </Button>
        </div>
      ) : null}

      <StsTable
        data={tournamentsForTable}
        updateStatus={updateStatus}
        onDrop={onDrop}
        editSts={editTournament}
        editingSts={editingTournament}
      />
    </>
  );
};

const mapStateToProps = state => ({
  tournaments: getStsBuilderData(state).tournaments,
});

const mapDispatchToProps = {
  updateRanking: StsActions.updateRank,
  updateTournament: StsActions.updateTournament,
};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentsTable);
