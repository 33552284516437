export const removeSystemFromBetslip = (
  betslips,
  { betslipId, systemType, betId }
) => {
  if (!systemType && !betId) {
    return betslips.filter(betslip => betslip.betslipId !== betslipId);
  }

  return betslips
    .map(betslip => {
      if (betslip.betslipId !== betslipId) {
        return betslip;
      }
      const filteredBets = betslip.systemBets.filter(system =>
        systemType !== 'SINGLE'
          ? system.type !== systemType
          : system.bets[0].betId !== betId
      );
      if (!filteredBets) {
        return null;
      }
      return { ...betslip, systemBets: filteredBets };
    })
    .filter(betslip => betslip);
};
