import React, { memo } from 'react';

export const Futsal = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Futsal ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141 130.48"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <polygon
          className={'Field'}
          points="141 52.77 34.86 52.77 0 130.48 132.93 130.48 141 52.77"
        />
        <polygon
          className={'Lines'}
          points="67.88 130.48 65.06 130.48 86.49 53.91 88.75 53.91 67.88 130.48"
        />
        <path
          className={'Lines'}
          d="M73.43,105.27c-8.92,0-13.51-8.3-10.4-18C66,78.1,74.82,71,82.9,71s13,7.11,10.87,16.28C91.53,97,82.35,105.27,73.43,105.27Zm8.76-31.73c-6.81,0-14.24,6-16.66,13.74-2.53,8.08,1.34,14.93,8.74,14.93s15.07-6.86,17-14.93C93.11,79.56,89,73.54,82.19,73.54Z"
        />
        <path
          className={'Lines'}
          d="M38.94,78.76,42.06,71H26.68L11.31,105.27h17l3.63-9c4.4-.13,9.42-4.15,11.24-9S43,78.88,38.94,78.76Zm-12,23.44H15.29L27.94,73.54H38.63Zm6.18-8.88,4.77-11.89c2.76.12,4,2.68,2.8,5.84S36,93.2,33.09,93.33Z"
        />
        <path
          className={'Lines'}
          d="M119.92,96.23l-1.36,9h17L139.11,71H123.73l-1.17,7.77c-4.15.11-8.12,3.84-8.93,8.52S115.59,96.11,119.92,96.23Zm5.83-22.69h10.69l-3.19,28.67H121.63Zm-3.59,7.89-1.8,11.89c-2.89-.13-4.76-2.82-4.23-6S119.33,81.55,122.16,81.43Z"
        />
        <path
          className={'Border'}
          d="M138.5,55l-8,71.94H4.36L36.11,55H138.5m2.5-2.27H34.86L0,130.48H132.93L141,52.78Z"
        />
        <circle className={'Ball'} cx="70.4" cy="49.78" r="49.68" />
        <path d="M64.59.34A49.85,49.85,0,0,0,20.64,48.58h0c0,.06,0,.11,0,.17A49.77,49.77,0,1,0,64.59.34Zm30.2,79.58c-2.31-.35-7.72-1.38-11.64-3.81-.38-7-1.14-19.06-1.26-21,2.66-2.22,9.78-8.26,12.33-11.24a145.58,145.58,0,0,1,16,5c1.54,4.71,1.46,10.42,1.23,13.79C107.62,71.49,97.9,78,94.79,79.92ZM61.21,46.39c-5.64,3.17-15.12,8.11-17.55,9.37-2.34-1.3-7.68-4.37-10.08-6.7A57.41,57.41,0,0,1,35.7,26.68c2.87-2.41,9.56-7.7,14.5-9.14,2.53,1.45,9.38,5.52,13.94,9.74C63.52,30.94,61.55,42.79,61.21,46.39ZM26.72,56.94a11.73,11.73,0,0,1-2.64-3.41,46.24,46.24,0,0,1,7.45-29.18l2,2.58a58.89,58.89,0,0,0-2.05,21.4A33,33,0,0,0,26.72,56.94ZM65.25,25.6A85.58,85.58,0,0,0,51.4,15.94a75.34,75.34,0,0,1,5.35-7.32c.13,0,13.45-3.61,23.46-1.81,3,1.22,7.91,3.44,9.94,5.89-1.85,3.46-4.35,8-6.06,10.8C80.49,23.17,68.93,24,65.25,25.6Zm45.11,21.22A149.47,149.47,0,0,0,94.94,42c-1.14-2.3-7-14.12-9.17-17.44,2-3.26,4.71-8.25,6.05-10.77,2.64,1,11.21,4.78,11.21,4.78a66.73,66.73,0,0,1,10.74,21.88A69,69,0,0,1,110.35,46.82Zm5.16-7.46a67.54,67.54,0,0,0-7-16.2,46.12,46.12,0,0,1,7.24,16.37ZM78.48,4l-.16.53c-10-1.07-21.56,2-22.09,2.16v0l-2.77-.26A46.48,46.48,0,0,1,78.48,4ZM26.54,59.31c.67,2.71,3.07,11.3,7.72,16,.17,1,.49,2.85.93,4.74a46.18,46.18,0,0,1-10.6-22.46A12.77,12.77,0,0,0,26.54,59.31ZM38.06,83.12a57.06,57.06,0,0,1-1.61-6.73A27.74,27.74,0,0,0,46.32,75s-1.77-13.37-1.66-17.58c2.55-1.32,11.7-6.08,17.33-9.24,3.13,2.31,14.36,6.87,18,7.63.19,3,.84,13.33,1.19,19.83C79.74,77,75.53,80.58,66.75,84c-6.81-1.06-20.82-10.71-21-10.8L46.32,75,45,75.28c.54.36,13.08,8.86,20.47,10.53.86,1.88,2.61,5.68,3.65,7.6a22.1,22.1,0,0,1-8.82,1.74A46.65,46.65,0,0,1,38.06,83.12Zm29.38,13a16.49,16.49,0,0,0,4.05-1.77c3.31-.32,10.8-1.12,14.55-2.12a5,5,0,0,0,2.07.48,46.62,46.62,0,0,1-20.68,3.41ZM95.8,88.68c-3.82,1.73-6.13,2.1-7.46,2.06A91.87,91.87,0,0,0,95,82.1c1.75-1,12.11-7.2,17.19-16.47l1.84.13A46.78,46.78,0,0,1,95.8,88.68Z" />
      </g>
    </svg>
  );
};

export default memo(Futsal);
