import React, { useState, useMemo } from 'react';
import classes from './ResponsibleGambling.module.scss';
import SelfExclusionRow from './SelfExclusionRow';
import { useFetch, useUpdate } from '../../../../../hooks';
import { uniqBy } from 'lodash';

const SelfExclusion = ({ customer, updateSelfRestrictions }) => {
  const { data: casinoProviders } = useFetch(
    '/casino-backoffice/public/casino-provider'
  );
  const { data: selfRestrictions, refetch } = useFetch(
    `/customer/admin/${customer.customerId}/global-status`
  );

  const casinoProvidersOptions = useMemo(
    () =>
      casinoProviders &&
      casinoProviders.map(casProvider => ({
        label: casProvider.name,
        value: casProvider.name,
      })),
    [casinoProviders]
  );

  const [provider, setProvider] = useState({
    section: '',
    endDate: null,
    active: null,
    casinoProvider: null,
  });

  const providerBuilder = useMemo(() => {
    const providers = ['SPORT', 'CASINO', 'LOTTO', 'LIVEGAMES', 'LIVE'];
    const providersObjects = providers.map(prov => ({
      section: prov,
      active: null,
      endDate: null,
    }));
    return providersObjects;
  }, []);

  const allRestrictions = useMemo(() => {
    if (selfRestrictions) {
      if (!provider.casinoProvider) {
        return uniqBy(
          selfRestrictions.sectionRestrictions.map(restricts => restricts[1]),
          'section'
        );
      } else {
        return selfRestrictions.sectionRestrictions
          .map(restricts => restricts[1])
          .filter(casino =>
            casino.section === 'CASINO'
              ? casino.provider === provider.casinoProvider
              : casino
          );
      }
    }
  }, [provider.casinoProvider, selfRestrictions]);

  const restrictions = useMemo(() => {
    return (
      allRestrictions &&
      providerBuilder.map(item => {
        const restricts = allRestrictions.find(
          restrict => restrict.section === item.section
        );
        return restricts ? { ...item, ...restricts } : item;
      })
    );
  }, [allRestrictions, providerBuilder]);

  useUpdate(async () => {
    if (provider.section && restrictions) {
      const currentRestriction = restrictions.find(
        restrict => restrict.section === provider.section
      );
      const { section, endDate, active } = currentRestriction;
      if (provider && provider.active !== null) {
        await updateSelfRestrictions({
          section,
          endDate,
          active,
          customerId: customer.customerId,
          ...provider,
          ...(section === 'CASINO' && {
            provider: provider.casinoProvider || currentRestriction.provider,
          }),
        });
        await refetch();
      }
    }
  }, [provider]);

  return (
    <>
      <h1 className={classes.title}>SELF-EXCLUSION</h1>
      <div className={classes.selfExclusionWrapper}>
        {restrictions &&
          restrictions.map(restriction => {
            return (
              <SelfExclusionRow
                key={restriction.section}
                name={restriction.section}
                provider={provider}
                setProvider={setProvider}
                restriction={restriction}
                casinoProvidersOptions={casinoProvidersOptions}
                refetch={refetch}
              />
            );
          })}
      </div>
    </>
  );
};

export default SelfExclusion;
