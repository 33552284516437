import React, { Fragment, useState, useMemo } from 'react';
import CheckboxDiv from '../../../components/Form/CheckboxDiv/CheckboxDiv';
import Input from '../../../components/Form/Input';
import { Button } from '../../../components/Layout';
import { useUpdate } from '../../../hooks';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';

export const ReferalSidebar = ({
  allSystemTypes,
  countActive,
  feedScopes,
  onSwitchChange,
  onMinMaxChange,
  allRiskCategories,
  edit,
  referral,
  saveReferral,
  categories: currentRiskCategories,
  setStateCategories,
  systemTypes,
  setStateSystemTypes,
  getCountOfActive,
  wsBound,
  minBet,
  wsConnectionStatus,
}) => {
  const [selectedRiskCategories, setSelectedRiskCategories] = useState([]);

  useUpdate(() => {
    if (wsConnectionStatus) getCountOfActive();
  }, [wsConnectionStatus]);

  const riskCategoriesFiltered = useMemo(() => {
    return allRiskCategories.length
      ? allRiskCategories.reduce((acc, val) => {
          if (!val.skipReferral) {
            return [...acc, { name: val.name, id: val.id }];
          }
          return [...acc];
        }, [])
      : [];
  }, [allRiskCategories]);

  useUpdate(() => {
    if (Object.keys(referral).length === 0) {
      setStateCategories(riskCategoriesFiltered);
      setStateSystemTypes(allSystemTypes);
    }
  }, [referral, riskCategoriesFiltered, allSystemTypes]);

  useUpdate(() => {
    setStateCategories(selectedRiskCategories);
  }, [selectedRiskCategories]);

  const betTypesList = useMemo(() => {
    return allSystemTypes
      ? allSystemTypes.reduce((acc, type) => [...acc, type], [])
      : [];
  }, [allSystemTypes]);

  return (
    <div className="event-picker-wrapper referal-sidebar-wrapper">
      <div className="square-box-wrapper traiders">
        <p className="clear-changes" onClick={getCountOfActive}>
          Refresh
        </p>
        <span className={`circle-status ${wsBound ? 'active' : 'inactive'}`} />
        <p>Active Traders: {countActive}</p>
      </div>
      <div className="square-box-wrapper provider-wrapper">
        <div className="form-row">
          <div className="col-md">
            <div className="provider-box-shadow">
              <CheckboxDiv
                label="Prematch"
                name="PREMATCH"
                onChange={onSwitchChange}
                id="PREMATCH"
                isChecked={feedScopes.find(fs => fs === 'PREMATCH')}
              />
            </div>
          </div>
          <div className="col-md">
            <div className="provider-box-shadow">
              <CheckboxDiv
                label={
                  <Fragment>
                    <span className="live-circle" />
                    LIVE
                  </Fragment>
                }
                name="LIVE"
                id="LIVE"
                onChange={onSwitchChange}
                isChecked={feedScopes.find(fs => fs === 'LIVE')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="square-box-wrapper">
        <p className="box-title">STAKE</p>
        <div className="efb-form">
          <label>Min (EUR)</label>
          <Input
            type="number"
            name="minBet"
            value={minBet}
            onChange={onMinMaxChange}
            min="0"
            className="efb-input"
          />
        </div>
      </div>
      <div
        className={`square-box-wrapper no-big-margin sport-list-box custom-checkbox-wrapper `}
      >
        <p className="box-title">Risk Category</p>

        <div className="referral-sidebar-select">
          <Picky
            id="picky"
            options={riskCategoriesFiltered}
            value={currentRiskCategories}
            multiple
            includeSelectAll
            valueKey="id"
            labelKey="name"
            includeFilter
            onChange={setSelectedRiskCategories}
            dropdownHeight={200}
          />
        </div>
      </div>
      <div className="square-box-wrapper no-big-margin custom-checkbox-wrapper">
        <p className="box-title">Bet Type</p>

        <div className="referral-sidebar-select">
          <Picky
            id="picky"
            options={betTypesList}
            value={systemTypes}
            multiple
            includeSelectAll
            includeFilter
            onChange={setStateSystemTypes}
            dropdownHeight={200}
          />
        </div>
      </div>
      <div className="square-box-wrapper create-btn">
        <Button
          disabled={!edit}
          cssClass="btn btn-primary"
          onClick={saveReferral}
        >
          {Object.keys(referral).length > 0 ? 'Save' : 'Create'}
        </Button>
      </div>
    </div>
  );
};

export default ReferalSidebar;
