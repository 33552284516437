import React from 'react';
import classes from './Modal.module.scss';

const Modal = ({
  isActive,
  onClose,
  className,
  children,
  showCloseBtn = true,
}) => {
  if (isActive) {
    return (
      <>
        <div className={classes.backgroundLayerPopUp} />
        <div className={classes.modal}>
          {showCloseBtn ? (
            <div onClick={onClose} className={classes.closeModal}>
              <i className="fas fa-times fa-lg" />{' '}
            </div>
          ) : null}
          <div className={className}>{children}</div>
        </div>
      </>
    );
  }

  return null;
};

export default Modal;
