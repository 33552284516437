import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {findPrivilege, findApplication, createAppView, operateOnExistingApp} from "./rolesUtils";
import {connect} from "react-redux";
import {getApplications} from "../../../../selectors";
import RoleSection from './RoleSection';

class CreateRolePrivileges extends Component {

	componentWillUnmount() {
		//console.log('empty state used in this component');
	}

	handleActionsChange = (action, view, selectedApp) => {
		let newRole = JSON.parse(JSON.stringify(this.props.newRole));
		let privIdx = findPrivilege(newRole, action),
			appIdx = findApplication(newRole.privileges, privIdx, selectedApp.label);
		if(newRole.privileges[privIdx].applications.length) {
			if(appIdx !== -1) {
				operateOnExistingApp(newRole, privIdx, appIdx, view, action);
			} else {
				let applications = createAppView(selectedApp, view);
				newRole.privileges[privIdx].applications = [...newRole.privileges[privIdx].applications, applications];
			}
		} else {
			if(action === 'WRITE') {
				let readApps = createAppView(selectedApp, view),
					readPrivIdx = findPrivilege(newRole, 'READ');
				newRole.privileges[readPrivIdx].applications = readApps.slice();
			}
			let applications = createAppView(selectedApp, view);
			newRole.privileges[privIdx].applications = applications.slice();
		}

		this.props.updateNewRole(newRole);
	};

	render() {
		let {applications, newRole} = this.props;
		return (
			<Fragment>
				<section className='efb-app-view-priv-header'>
					<span style={{width: '20%'}}>
						Role
					</span>
					<span style={{width: '30%'}}>
						Application
					</span>
					<span style={{width: '30%'}}>
						View
					</span>
					<span style={{width: '20%'}}>
						Privileges/Actions
					</span>
				</section>
				<section className='efb-app-view-priv-container'>
					{
						applications.length ? applications.map((app, idx) =>
							<RoleSection newRole={newRole} handleActionsChange={this.handleActionsChange} application={app} key={idx}/>
						) : (
							<div>
								Loading...
							</div>
						)
					}
				</section>
			</Fragment>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const applications = getApplications(state);
	return {
		applications
	};
};

CreateRolePrivileges.propTypes = {
	newRole: PropTypes.object,
	updateNewRole: PropTypes.func
};

export default connect(mapStateToProps, null)(CreateRolePrivileges);