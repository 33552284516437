import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const EventSearchMarketSpecifiers = ({ tableData }) => {
  const renderSpecifiers = useCallback(specifier => {
    return <td key={specifier}>{specifier}</td>;
  }, []);
  return <tr>{tableData.map(renderSpecifiers)}</tr>;
};

EventSearchMarketSpecifiers.propTypes = {
  tableData: PropTypes.array,
};

export default React.memo(EventSearchMarketSpecifiers);
