import {
  GET_TOP_BETS,
  GET_TOP_EVENTS,
  GET_TOP_COUPONS,
  BETS_SUGGESTED_LOAD,
  EVENT_SUGGESTED_LOAD,
  COUPON_SUGGESTED_LOAD
} from '../../actions/cms/TopManager';

const initialState = {
  bets: [],
  events: [],
  coupons: [],
  suggestedBets: {},
  suggestedEvents: {},
  suggestedCoupons: {}
};

export const topManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOP_BETS.SUCCESS:
      return {
        ...state,
        bets: action.payload
      };
    case GET_TOP_COUPONS.SUCCESS:
      return {
        ...state,
        coupons: action.payload
      };
    case GET_TOP_EVENTS.SUCCESS:
      return {
        ...state,
        events: action.payload
      };
    case BETS_SUGGESTED_LOAD.SUCCESS:
      return {
        ...state,
        suggestedBets: action.payload
      };
    case EVENT_SUGGESTED_LOAD.SUCCESS:
      return {
        ...state,
        suggestedEvents: action.payload
      };
    case COUPON_SUGGESTED_LOAD.SUCCESS:
      return {
        ...state,
        suggestedCoupons: action.payload
      };
    default:
      return state;
  }
};
