import React, { useCallback, useState } from 'react';
import InputIcon from '../../../components/Form/InputIcon/InputIcon';
import CheckboxSquare from '../../../components/Form/CheckboxSquare';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createLoadingSelector, getCMSData } from '../../../selectors';
import * as EventSearchActions from '../../../redux/actions/cms/eventSearch';
import Select from 'react-select';
import useUpdate from '../../../hooks/useUpdate';
import { minimalStyle } from '../customReactSelectStyle';
import { getSportIcon } from '../../../helpers/iconHelpers';
import { ICONS_SPORT_MAP } from '../../../components/Icons/UI/sports/index';
import PropTypes from 'prop-types';
import calendarImage from '../../../resources/images/calendar.png';
import Input from '../../../components/Form/Input';
import RadioButton from '../../../components/Form/RadioButton/RadioButton';
import { useMount } from '../../../hooks';
import { addNotificationMessage } from '../../../redux/actions/ui';
import FullScreenLoader from '../../../components/Layout/FullScreenLoader/FullScreenLoader';

// Events Search page
const eventsSearch = '/adm/cm/es';

const EventPicker = ({
  getSports,
  sports,
  countries: eventCountriesList,
  tournaments: eventTournamentsList,
  events,
  getCountries,
  getTournaments,
  getEvents,
  loading,
  setSportName,
  setCountryName,
  setTournamentName,
  setFilterDate,
  setSportIcon,
  location,
  setCurrentProducer,
}) => {
  const [countries, setCountries] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [isSportListExpanded, setSportListExpand] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedTournament, setSelectedTournament] = useState('');
  const [sportsList, setSports] = useState([]);
  const [providers, setProviders] = useState([
    { name: 'Bet Radar', checked: false, value: '3' },
    { name: 'Bet Genius', checked: false, value: '13' },
  ]);

  useMount(() => {
    getSports();
  });

  useUpdate(() => {
    const countriesList = eventCountriesList.reduce(
      (acc, val) => [...acc, { label: val.names.en.name, value: val.id }],
      []
    );
    setCountries(countriesList);

    const tournamentsList = eventTournamentsList.reduce(
      (acc, val) => [...acc, { label: val.names.en.name, value: val.id }],
      []
    );
    setTournaments(tournamentsList);
    setSports(sports);
  }, [eventCountriesList, eventTournamentsList, events, sports]);

  const clearProviders = useCallback(() => {
    const providersClone = [...providers];
    providersClone.map(provider => {
      return (provider.checked = false);
    });
    setCurrentProducer('');
    setProviders(providersClone);
  }, [providers, setCurrentProducer]);

  const getCountriesList = useCallback(
    (e, sport, index, sportIcon) => {
      const isChecked = e.target.checked;
      const sports = [...sportsList].map(sport => {
        sport.checked = false;
        return sport;
      });
      sports[index].checked = isChecked;
      setSports(sports);
      getCountries(sport.id);
      clearProviders();
      setSelectedCountry('');
      setSelectedTournament('');
      if (location.pathname === eventsSearch) {
        setSportName(sport.names.en.name);
        setSportIcon(sportIcon);
      }
    },
    [
      getCountries,
      sportsList,
      setSelectedCountry,
      setSelectedTournament,
      location,
      setSportName,
      setSportIcon,
      clearProviders,
    ]
  );

  const mapSports = useCallback(
    (sport, index) => {
      const SportIcon = getSportIcon(sport, ICONS_SPORT_MAP);
      return (
        <div className="col-lg-6 sport-wrapper" key={sport.id}>
          <div className="event-picker-sport-icon">
            {SportIcon ? (
              <SportIcon selected={true} colorful={true} />
            ) : (
              <span className="no-sport-icon">/</span>
            )}
          </div>

          <CheckboxSquare
            name={sport.names.en.name}
            checked={!sport.checked ? false : sport.checked}
            onChange={e => getCountriesList(e, sport, index, SportIcon)}
            label={
              sport.names.en.name.length > 20
                ? `${sport.names.en.name.slice(0, 20)}..`
                : sport.names.en.name
            }
          />
        </div>
      );
    },
    [getCountriesList]
  );

  const clearSports = useCallback(() => {
    const sportsClone = [...sports];
    sportsClone.map(sport => {
      sport.checked = false;
      return sport;
    });
    setSports(sportsClone);
  }, [setSports, sports]);

  const getTournamentsList = useCallback(
    country => {
      setSelectedTournament('');
      setSelectedCountry(country);
      getTournaments(country.value);
      clearProviders();
      if (location.pathname === eventsSearch) {
        setCountryName(country.label);
      }
    },
    [getTournaments, location, setCountryName, clearProviders]
  );

  const getEventsList = useCallback(
    tournament => {
      setSelectedTournament(tournament);
      getEvents(tournament.value);
      if (location.pathname === eventsSearch) {
        setTournamentName(tournament.label);
      }
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    },
    [getEvents, location, setTournamentName]
  );

  const searchSports = useCallback(
    e => {
      const value = e.target.value;
      const filteredResults = sports.filter(data =>
        data.names
          ? data.names.en.name.toLowerCase().includes(value.toLowerCase())
          : data
      );
      setSports(filteredResults);
    },
    [sports]
  );

  const getEventsByDate = useCallback(
    e => {
      if (selectedTournament === '') {
        addNotificationMessage(
          'You firstly need to select tournament',
          'error',
          'Error'
        );
      } else {
        const startDate = e.target.value;
        const date = new Date(startDate);
        if (startDate !== '') {
          getEvents(selectedTournament.value, date.toISOString());
          if (location.pathname === eventsSearch) {
            setFilterDate(date);
          }
        } else {
          getEvents(selectedTournament.value);
          if (location.pathname === eventsSearch) {
            setFilterDate('');
          }
        }
      }
    },
    [getEvents, selectedTournament, location, setFilterDate]
  );

  const selectProvider = useCallback(
    (e, index) => {
      const providersClone = [...providers];
      providersClone.map(provider => {
        return (provider.checked = false);
      });
      providersClone[index].checked = e.target.checked;
      setCurrentProducer(providersClone[index].value);
      setProviders(providersClone);
    },
    [providers, setCurrentProducer]
  );

  return (
    <div className="event-picker-wrapper">
      <FullScreenLoader isActive={loading} />
      <InputIcon
        placeholder="Search..."
        onChange={searchSports}
        iconName="search"
      />

      <div className="square-box-wrapper provider-wrapper">
        <p className="box-title">Provider</p>
        <p className="clear-changes" onClick={clearProviders}>
          clear
        </p>
        <div className="form-row">
          {providers.map((provider, index) => {
            return (
              <div className="col-md" key={provider.name}>
                <RadioButton
                  label={provider.name}
                  value={provider.name}
                  id={provider.name}
                  onChange={e => selectProvider(e, index)}
                  name="provider"
                  isChecked={provider.checked}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div
        className={`square-box-wrapper sport-list-box ${
          isSportListExpanded ? 'expanded' : null
        }`}
      >
        <p className="box-title">Sport</p>
        <p className="clear-changes" onClick={clearSports}>
          clear
        </p>
        <div className="form-row">
          {sportsList ? sportsList.map(mapSports) : null}
        </div>
        {sportsList.length > 100 ? (
          <div
            className="show-more"
            onClick={() => setSportListExpand(!isSportListExpanded)}
          >
            <p>Show {`${isSportListExpanded ? 'less' : 'more'}`}</p>
            <i
              className={`fa fa-caret-${isSportListExpanded ? 'up' : 'down'}`}
            />
          </div>
        ) : null}
      </div>
      <div className="square-box-wrapper">
        <div className="form-row">
          <div className="col-md-3">
            <p className="box-title centered">Country:</p>
          </div>
          <div className="col-md-9">
            <p
              className="clear-changes select-clear"
              onClick={() => setSelectedCountry('')}
            >
              clear
            </p>
            <Select
              value={selectedCountry}
              placeholder="Select country"
              onChange={getTournamentsList}
              options={countries}
              isDisabled={countries.length === 0}
              styles={minimalStyle}
            />
          </div>
        </div>
      </div>

      <div className="square-box-wrapper">
        <div className="form-row">
          <div className="col-md-3">
            <p className="box-title centered">Tournament:</p>
          </div>
          <div className="col-md-9">
            <p
              className="clear-changes select-clear"
              onClick={() => setSelectedTournament('')}
            >
              clear
            </p>
            <Select
              value={selectedTournament}
              placeholder="Select tournament"
              onChange={getEventsList}
              options={tournaments}
              isDisabled={tournaments.length === 0}
              styles={minimalStyle}
            />
          </div>
        </div>
      </div>

      <div className="square-box-wrapper">
        <p className="box-title">Date</p>
        <div className="date-filter-list">
          <div className="form-row">
            <div className="col-md date-filter-list-item">
              <p>Today</p>
            </div>
            <div className="col-md date-filter-list-item">
              <p>3 days</p>
            </div>
            <div className="col-md date-filter-list-item">
              <Input
                customInput
                type="date"
                className="unstyled-date-input"
                onChange={getEventsByDate}
              />
              <img src={calendarImage} alt="calendar icon" />
            </div>
          </div>
          <div>
            <small>
              <i className="fa fa-info-circle" /> <br /> If date is selected, in
              the right table will be shown only events starting from selected
              date.
              <br />
              - Today (not yet available)
              <br />- 3 days (not yet available)
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

EventPicker.propTypes = {
  getCountriesList: PropTypes.func,
  getTournamentsList: PropTypes.func,
  sports: PropTypes.array,
  countries: PropTypes.array,
  tournaments: PropTypes.array,
  events: PropTypes.array,
  getEventsList: PropTypes.func,
  searchSports: PropTypes.func,
};

const loadingReducer = createLoadingSelector([
  'GET_MARKETS_BY_SPORT_EVENT',
  'EVENT_SEARCH_GET_COUNTRIES',
  'EVENT_SEARCH_GET_SPORTS',
  'EVENT_SEARCH_GET_TOURNAMENTS',
  'EVENT_SEARCH_GET_EVENTS',
]);

const mapStateToProps = state => ({
  sports: getCMSData(state).eventSearchReducer.sports,
  countries: getCMSData(state).eventSearchReducer.countries,
  tournaments: getCMSData(state).eventSearchReducer.tournaments,
  sportEventMarkets: getCMSData(state).eventSearchReducer.sportEventMarkets,
  loading: loadingReducer(state),
});

const mapDispatchToProps = {
  getSports: EventSearchActions.getSports,
  getCountries: EventSearchActions.getCountries,
  getTournaments: EventSearchActions.getTournaments,
  getEvents: EventSearchActions.getEvents,
  getMarketsBySportEvent: EventSearchActions.getMarketsBySportEvent,
  addNotificationMessage,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventPicker)
);
