import React, { memo } from 'react';

export const Rugby = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Rugby ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141 139.9"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g id="Rugby">
        <path
          className={'Ball'}
          d="M108.5,106.85a113,113,0,0,1-38,24.74,120.55,120.55,0,0,1-20.56,6.17,95.25,95.25,0,0,1-24.65,2,53.51,53.51,0,0,1-5.82-.63c-6-1-10.54-2.79-13.07-5.32s-4.5-7.1-5.52-12.91a54.84,54.84,0,0,1-.7-5.81A92.43,92.43,0,0,1,2.2,90,119.33,119.33,0,0,1,8.47,69.57,114.71,114.71,0,0,1,33.34,31.69C45.1,19.94,59.27,12.07,73.3,7.12A126.51,126.51,0,0,1,94.55,1.64,112.54,112.54,0,0,1,115.69,0c2.12.05,4.11.17,5.94.37,6.81.7,11.75,2.28,13.82,4.34,2.36,2.35,4.09,7.07,4.94,13.36.26,1.82.43,3.78.52,5.87a101.31,101.31,0,0,1-1.68,22.36,123.07,123.07,0,0,1-5.76,21A110.37,110.37,0,0,1,108.5,106.85Z"
        />
        <path
          className={'Stripe'}
          d="M25.28,139.76a53.51,53.51,0,0,1-5.82-.63c-6-1-10.54-2.79-13.07-5.32s-4.5-7.1-5.52-12.91a54.84,54.84,0,0,1-.7-5.81A463.75,463.75,0,0,0,115.69,0c2.12.05,4.11.17,5.94.37,6.81.7,11.75,2.28,13.82,4.34,2.36,2.35,4.09,7.07,4.94,13.36.26,1.82.43,3.78.52,5.87A464,464,0,0,0,25.28,139.76Z"
        />
        <path
          className={'Center'}
          d="M.87,120.89A648.56,648.56,0,0,0,121.64.4c6.81.7,11.75,2.28,13.82,4.34,2.36,2.35,4.09,7.07,4.94,13.36a648.48,648.48,0,0,0-120.94,121c-6-1-10.54-2.79-13.07-5.32S1.89,126.7.87,120.89Z"
        />
      </g>
    </svg>
  );
};

export default memo(Rugby);
