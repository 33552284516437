import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../components/Layout/Button';
import Filter from '../../../../components/Icons/Filter';
import Delete from '../../../../components/Icons/Delete';
import Add from '../../../../components/Icons/Add';

const UserProfilesToolbar = ({
  toggleFiltersModal,
  toggleCreateUserModal,
  resetFilters,
  filters
}) => {
  const filtersClear = useMemo(() => {
    return Object.values(filters).every(filter => !filter);
  }, [filters]);

  return (
    <section className="efb-toolbar-filter">
      <div className="efb-btn-group">
        <Button cssClass="efb-btn__light" onClick={toggleFiltersModal}>
          <Filter size="sm">
            <span className="efb-icon__text">Filter</span>
          </Filter>
        </Button>
        <section className="efb-toolbar-filter__info">
          <span>Filtered by: </span>
          {!filtersClear ? (
            Object.keys(filters).map(filter => {
              if (filters[filter])
                return (
                  <div key={filter} className="efb-span">
                    <div className="efb-span__text">{filter}</div>
                  </div>
                );
              return null;
            })
          ) : (
            <strong>No filters applied</strong>
          )}
        </section>
        {
          <Button onClick={resetFilters} cssClass="efb-btn__light">
            <Delete size="sm"></Delete>
          </Button>
        }
      </div>
      <Button cssClass="efb-btn__light" onClick={toggleCreateUserModal}>
        <Add size="sm">
          <span className="efb-icon__text">New</span>
        </Add>
      </Button>
    </section>
  );
};

UserProfilesToolbar.propTypes = {
  filters: PropTypes.object
};

export default UserProfilesToolbar;
