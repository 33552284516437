import React, { useState } from 'react';
import { Button } from '../../Layout';

const CashoutCoefficientTableRow = ({ tableData, setCashout, placeHolder }) => {
  const [isEditable, setEditable] = useState(false);

  const renderRow = (cashout, i) => {
    const onKeyDown = event => {
      cashout.p = +event.target.value;
    };

    return (
      <tr key={cashout.displayName}>
        <td>{i + 1}</td>
        <td className="pb-sm-0 pt-0">
          {cashout.displayName || 'Not available name'}
        </td>
        <td className="pb-sm-0 pt-0">
          <div className="form-row">
            <div className="col-md-6 my-0">
              {isEditable ? (
                <input
                  type="number"
                  step="0.1"
                  name="parameter"
                  className="form-control"
                  placeholder={placeHolder || 'Times value'}
                  onChange={onKeyDown}
                />
              ) : (
                <div className="col-md-6 my-2">{cashout.p}</div>
              )}
            </div>
          </div>
        </td>
        <td>
          {' '}
          {isEditable ? (
            <Button
              onClick={() => setCashout(cashout)}
              cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val px-3 mx-2"
            >
              Save
            </Button>
          ) : null}
          <Button
            onClick={() => setEditable(!isEditable)}
            cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val px-3 mx-2"
          >
            {isEditable ? 'Cancel' : 'Edit'}
          </Button>
        </td>
        <td></td>
      </tr>
    );
  };
  return tableData.map(renderRow);
};

export default React.memo(CashoutCoefficientTableRow);
