import axiosInstance from './axios-instance';
import { actionCreator, createRequestTypes } from './index';
import { addNotificationMessage } from './ui';

export const GET_BONUS_TYPES = createRequestTypes('GET_BONUS_TYPES');
export const GET_BONUS_CONFIGS = createRequestTypes('GET_BONUS_CONFIGS');
export const GET_SPORTS = createRequestTypes('GET_SPORTS');
export const GET_CATEGORIES = createRequestTypes('GET_CATEGORIES');
export const GET_TOURNAMENTS = createRequestTypes('GET_TOURNAMENTS');
export const GET_BUSINESS_UNITS = createRequestTypes('GET_BUSINESS_UNITS');
export const GET_CAMPAIGNS = createRequestTypes('GET_CAMPAIGNS');
export const GET_EVENTS = createRequestTypes('GET_EVENTS');
export const CAMPAIGN = createRequestTypes('CAMPAIGN');
export const GET_BONUSES = createRequestTypes('GET_BONUSES');
export const APPROVE_CUSTOMER_BONUS = createRequestTypes(
  'APPROVE_CUSTOMER_BONUS'
);
export const REJECT_CUSTOMER_BONUS = createRequestTypes(
  'REJECT_CUSTOMER_BONUS'
);
export const BONUS_STATUS_UPDATED = createRequestTypes('BONUS_STATUS_UPDATED');

export const getAllSports = () => async dispatch => {
  try {
    const res = await axiosInstance.get('/sport/event/view/sport/admin/');

    dispatch(actionCreator.success(GET_SPORTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_SPORTS, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getBonusTypes = () => async dispatch => {
  dispatch(actionCreator.request(GET_BONUS_TYPES));
  try {
    const res = await axiosInstance.get('/bonus/admin/bonus-types');
    dispatch(actionCreator.success(GET_BONUS_TYPES, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_BONUS_TYPES, error));
    dispatch(addNotificationMessage(error.message, 'error', 'Error'));
  }
};

export const getEvents = tournamentId => async dispatch => {
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/match/admin?tournamentId=${tournamentId}&status=ACTIVE`
    );

    dispatch(actionCreator.success(GET_EVENTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_EVENTS, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getPendingBonuses = (campaignId, status) => async dispatch => {
  dispatch(actionCreator.request(GET_BONUSES));
  try {
    const res = await axiosInstance.get('/bonus/admin/bonuses', {
      params: {
        campaignId,
        status
      }
    });
    dispatch(actionCreator.success(GET_BONUSES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_BONUSES, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getAllBonuses = (optional) => async dispatch => {
  dispatch(actionCreator.request(GET_BONUSES));
  try {
    const res = await axiosInstance.get('/bonus/admin/bonus/statuses', {
      params: {
        ...optional
      }
    });

    dispatch(actionCreator.success(GET_BONUSES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_BONUSES, err));
    if (!err) {
      dispatch(addNotificationMessage('Some error occured, please try again.', 'error', 'Error'));
    } else {
      dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    }
  }
};

export const getCategories = sportId => async dispatch => {
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/category/admin?sportId=${sportId}`
    );

    dispatch(actionCreator.success(GET_CATEGORIES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_CATEGORIES, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const clearCategories = () => async dispatch => {
  dispatch(actionCreator.empty(GET_CATEGORIES));
};

export const clearBonuses = () => async dispatch => {
  dispatch(actionCreator.empty(GET_BONUSES));
};

export const clearTournaments = () => async dispatch => {
  dispatch(actionCreator.empty(GET_TOURNAMENTS));
};

export const clearEvents = () => async dispatch => {
  dispatch(actionCreator.empty(GET_EVENTS));
};

export const getTournaments = categoryId => async dispatch => {
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/tournament/admin?categoryId=${categoryId}`
    );

    dispatch(actionCreator.success(GET_TOURNAMENTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_TOURNAMENTS, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getBusinessUnits = () => async dispatch => {
  try {
    const res = await axiosInstance.get('/customer/view/admin/businessUnit');

    dispatch(actionCreator.success(GET_BUSINESS_UNITS, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_BUSINESS_UNITS, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getCampaigns = () => async dispatch => {
  try {
    const res = await axiosInstance.get('/bonus/admin/campaigns');

    dispatch(actionCreator.success(GET_CAMPAIGNS, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_CAMPAIGNS, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getConfigs = campaignId => async dispatch => {
  dispatch(actionCreator.request(GET_BONUS_CONFIGS));
  try {
    const res = await axiosInstance.get('bonus/admin/bonus-config', {
      params: {
        campaignId
      }
    });

    dispatch(actionCreator.success(GET_BONUS_CONFIGS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_BONUS_CONFIGS, error));
    dispatch(addNotificationMessage(error.message, 'error', 'Error'));
  }
};

export const createCampaign = campaign => async dispatch => {
  try {
    await axiosInstance.post('/bonus/admin/campaign', campaign);
    dispatch(
      addNotificationMessage(
        'You have successfully create campaign',
        'success',
        'Success'
      )
    );
    dispatch(getCampaigns());
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const createBonus = bonus => async dispatch => {
  try {
    await axiosInstance.post('/bonus/admin/bonus', bonus);
    dispatch(
      addNotificationMessage(
        'You have successfully create bonus',
        'success',
        'Success'
      )
    );
  } catch (err) {
    for (let message of Object.values(err.errors)) {
      dispatch(addNotificationMessage(message, 'warning', 'Warning'));
    }
  }
};

const checkBonusStatus = bonus => async dispatch => {
  const checkInterval = setInterval(async () => {
    const newStatus = await axiosInstance.get(`/bonus/admin/bonus-status/${bonus.bonusCustomerId}`);

    if (newStatus !== bonus.bonusType) {
      dispatch(actionCreator.success(BONUS_STATUS_UPDATED, { bonusCustomerId: bonus.bonusCustomerId, newStatus }));
      clearInterval(checkInterval);
    }
  }, 500);
};

export const approveCustomerBonus = payload => async dispatch => {
  dispatch(actionCreator.request(APPROVE_CUSTOMER_BONUS));
  try {
    await axiosInstance.post('/customer/admin/bonus/approve', payload);
    for (const bonusStatus of payload) {
      checkBonusStatus(bonusStatus)(dispatch);
    }
    dispatch(
      addNotificationMessage(
        'Successfully approved pending bonus!',
        'success',
        'Success'
      )
    );
    dispatch(actionCreator.success(APPROVE_CUSTOMER_BONUS));
  } catch (error) {
    dispatch(actionCreator.failure(APPROVE_CUSTOMER_BONUS, error));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const rejectCustomerBonus = payload => async dispatch => {
  dispatch(actionCreator.request(REJECT_CUSTOMER_BONUS));
  try {
    await axiosInstance.post('/customer/admin/bonus/reject', payload);
    checkBonusStatus(payload)(dispatch);
    dispatch(actionCreator.success(REJECT_CUSTOMER_BONUS));
  } catch (error) {
    dispatch(actionCreator.failure(REJECT_CUSTOMER_BONUS, error));
    if (error.errors && typeof error.errors === 'object') {
      Object.keys(error.errors).forEach(error_code => {
        dispatch(addNotificationMessage(`${error.errors[error_code]}`, 'error', 'Error'));
      });
    }
  }
};

export const editBonus = bonus => async dispatch => {
  try {
    await axiosInstance.post('/bonus/admin/bonus', bonus);
    dispatch(
      addNotificationMessage(
        'You have successfully edit bonus',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const createEmailTemplate = emailTemplate => async dispatch => {
  try {
    //TODO: Wait API
    await axiosInstance.post('/bonus/admin/bonus', emailTemplate);
    dispatch(
      addNotificationMessage(
        'You have successfully create email template',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};
