import React from 'react';

export const CustomerSer = (props) => {
    return (
	<span className='efb-icon'>
    	<svg className={`efb-icon--${props.size} ${props.cssClass}`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 128 128" >
	 
            <path fill="#556080" d="M117.9,106.1H3c-1.7,0-3-1.3-3-3V32c0-1.7,1.3-3,3-3h114.9c1.7,0,3,1.3,3,3v71.1
                C120.9,104.8,119.6,106.1,117.9,106.1z"/>
            <path fill="#ECF0F1" d="M125,99H10.1c-1.7,0-3-1.3-3-3V24.9c0-1.7,1.3-3,3-3H125c1.7,0,3,1.3,3,3V96C128,97.7,126.7,99,125,99z"/>
            <path fill="#91BAE1" d="M56,76.5l-9.9-5c-1.3-0.6-2.1-2-2.1-3.4v-3.9c0.3-0.3,0.6-0.7,0.9-1.2c1.4-1.9,2.4-4,3.1-6.2
                c1.3-0.4,2.2-1.6,2.2-3v-4.2c0-0.9-0.4-1.7-1-2.3v-6c0,0,1.2-9.4-11.4-9.4c-12.7,0-11.4,9.4-11.4,9.4v6c-0.6,0.6-1,1.4-1,2.3v4.2
                c0,1.1,0.6,2.1,1.4,2.6c1,4.5,3.8,7.8,3.8,7.8v3.8c0,1.4-0.8,2.7-2,3.3l-9.3,5.1c-2.7,1.5-4.3,4.3-4.3,7.3v3.4h45.8V84
                C60.6,80.8,58.8,77.9,56,76.5z"/>
            <path fill="#FEB719" d="M115.8,50.6H67.2c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2h48.6c1.1,0,2,0.9,2,2C117.8,49.7,116.9,50.6,115.8,50.6
                z"/>
            <path fill="#FEB719" d="M115.8,62.8H67.2c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2h48.6c1.1,0,2,0.9,2,2C117.8,61.9,116.9,62.8,115.8,62.8
                z"/>
            <path fill="#FEB719" d="M93.5,74.9H67.2c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2h26.3c1.1,0,2,0.9,2,2C95.6,74,94.6,74.9,93.5,74.9z"/>

    	</svg>     
		{props.children}
	</span>
    )
}

export default CustomerSer;