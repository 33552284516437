import { actionCreator, createRequestTypes } from '../index';
import { addNotificationMessage } from '../ui';
import axiosInstance from '../axios-instance';

export const UPDATE_MARKET = createRequestTypes('UPDATE_MARKET');
export const TAKE_OVER_MARKET = createRequestTypes('TAKE_OVER_MARKET');

export const updateMarket = market => async dispatch => {
  dispatch(actionCreator.request(UPDATE_MARKET));
  try {
    await axiosInstance.post('/market/market/', {
      ...market,
      sportEventAggregateId: market.sportEventId,
      marketTypeAggregateId: market.marketType.id,
      outcomeBetInfos: market.outcomeBetInfos.map(outcomeBetInfo => ({
        ...outcomeBetInfo,
        names: Object.keys(outcomeBetInfo.names).reduce((acc, langCode) => {
          return {
            ...acc,
            name: outcomeBetInfo.names[langCode].name,
          };
        }, {}),
      })),
    });
    dispatch(actionCreator.success(UPDATE_MARKET));
    dispatch(
      addNotificationMessage(
        'Market updated successfully!',
        'success',
        'success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'error'));
    dispatch(actionCreator.failure(UPDATE_MARKET, err.message));
  }
};

export const takeOverMarket = marketId => async dispatch => {
  dispatch(actionCreator.request(TAKE_OVER_MARKET));
  try {
    await axiosInstance.post(`/market/market/takeover/${marketId}`);
    dispatch(actionCreator.success(TAKE_OVER_MARKET));
  } catch (err) {
    if (err.errors) {
      Object.entries(err.errors).forEach(([_, message]) => {
        dispatch(addNotificationMessage(message, 'error', 'Error!'));
      });
    } else {
      dispatch(addNotificationMessage(err.message, 'error', 'Error!'));
      dispatch(actionCreator.failure(TAKE_OVER_MARKET, err.message));
    }
    throw err;
  }
};
