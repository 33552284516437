import axiosInstance from '../axios-instance';
import { createRequestTypes, actionCreator } from '../index';
import { addNotificationMessage } from '../ui';

export const SYSTEM_SETTINGS = createRequestTypes('SYSTEM_SETTINGS');
export const SPORT_TYPES_VIEW = createRequestTypes('SPORT_TYPES_VIEW'); // ENUM
export const FEED_PROVIDERS = createRequestTypes('FEED_PROVIDERS'); // ENUM

export const getSystemSettings = () => {
  return dispatch => {
    dispatch(actionCreator.request(SYSTEM_SETTINGS));
    axiosInstance
      .get('/riskmgnt/view/admin/bettingSettings/search/findLast')
      .then(response => {
        return dispatch(actionCreator.success(SYSTEM_SETTINGS, response));
      })
      .catch(error => {
        if (error.errors) {
          let errors = error.errors;
          let messages = Object.keys(errors).map(key => {
            return errors[key];
          });
          messages.forEach(message => {
            dispatch(addNotificationMessage(message, 'error', 'Error!'));
          });
          dispatch({
            type: 'SET_SYSTEM_SETTINGS_FAILURE',
            payload: error.error,
          });
        }
      });
  };
};

export const createSystemSettings = settings => {
  return dispatch => {
    axiosInstance
      .post('/riskmgnt/view/admin/bettingSettings', settings)
      .then(response => {
        dispatch(
          addNotificationMessage(
            'Successful save system settings',
            'success',
            'Success!'
          )
        );
        return dispatch(getSystemSettings());
      })
      .catch(error => {
        dispatch(addNotificationMessage('Duplicate item', 'error', 'Error!'));
      });
  };
};

export const setSportTypesView = () => {
  return dispatch => {
    axiosInstance
      .get('/riskmgnt/view/admin/sports/views')
      .then(response => {
        return dispatch(actionCreator.success(SPORT_TYPES_VIEW, response));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const setFeedProviders = () => {
  return dispatch => {
    axiosInstance
      .get('/riskmgnt/view/admin/feedProviders')
      .then(response => {
        return dispatch(actionCreator.success(FEED_PROVIDERS, response));
      })
      .catch(error => {
        console.log(error);
      });
  };
};
