import React from 'react';

export const AdHock = (props) => {
    return (
        <span className='efb-icon'>
            <svg className={`efb-icon--${props.size} ${props.cssClass} efb-adhock`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
            <path d="M34,31.4h-32c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h32c1,0,1.8-0.8,1.8-1.8C35.9,32.3,35,31.4,34,31.4z"/>
            <path d="M4,28.8c2.2,0,4-1.8,4-4c0-0.9-0.3-1.6-0.7-2.3l2.8-5.3c0,0,0,0,0.1,0c0.6,0,1.2-0.1,1.7-0.4l6.2,4.8
                c0,0.2-0.1,0.5-0.1,0.7c0,2.2,1.8,4,4,4s4-1.8,4-4c0-0.8-0.2-1.6-0.7-2.2l6.4-11.3c0.1,0,0.1,0,0.2,0c2.2,0,4-1.8,4-4s-1.8-4-4-4
                s-4,1.8-4,4c0,0.8,0.2,1.6,0.7,2.2l-6.4,11.3c-0.1,0-0.1,0-0.2,0c-0.6,0-1.2,0.1-1.7,0.4L14.1,14c0-0.2,0.1-0.5,0.1-0.7
                c0-2.2-1.8-4-4-4s-4,1.8-4,4c0,0.9,0.3,1.6,0.7,2.3l-2.8,5.3c0,0,0,0-0.1,0c-2.2,0-4,1.8-4,4C0,27.1,1.8,28.8,4,28.8z"/>
            </svg>
            {props.children}
        </span>
    )
}

export default AdHock;