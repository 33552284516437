import React, { memo } from 'react';

export const CombatSports = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `CombatSports ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 136.52 141"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <path
          className={'Glove'}
          d="M135.56,92.12,111.63,68.2a3.3,3.3,0,0,0-4-.49c.29-2.92,2.26-29.09-16.28-47.62C80.07,8.83,73.48,8.7,70.37,11.81,57.18-1.39,43.47-7.08,22.52,13.87s-32,44.58-12,64.54c36,36,50.6,34.31,52.64,33.83a3.31,3.31,0,0,0,.58,3.87L87.64,140a3.3,3.3,0,0,0,4.66,0l43.26-43.26A3.3,3.3,0,0,0,135.56,92.12Z"
        />
        <path
          className={'Palm'}
          d="M107.41,68.15,68.57,107c-23.41-2.87-48.51-32.45-48.51-32.45S35.32,78.67,54.59,59.4C60.67,53.32,66,47,67.46,40.32,53,25.84,63.1,17.72,63.1,17.72s-.7,5.07,2.84,8.61c6.73,6.73,14.78-5,27.2,7.46C103,43.68,106.43,61.36,107.41,68.15Z"
        />
      </g>
    </svg>
  );
};

export default memo(CombatSports);
