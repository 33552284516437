import React, { memo } from 'react';

export const VirtualEuropaCup = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  const icon = (
    <>
      <g>
        <path
          d="M13.8,28.1c0,0,1.2-2.4,1.3-2.8c0.2-0.7,0.3-1.4,0.2-1.4c0.1-0.6,0.5-1,0.5-1.4c0.2-0.1,0.3-0.2,0.5-0.4
		c0.1-0.1,0.2-0.4,0.2-0.6c0-0.1,0.1-2-0.1-3.3c0.2-0.2,0.1-0.8,0.1-0.9c0.1-0.2,0.1-1,0.3-2c0.1-0.7,0.2-1.4,0.3-2.2
		c0.1-1.3,0.2-2.5,0.3-2.5c0.1-0.1,0.7,0.6,1.6,0.9c0,0,0.2,0,0.3,0c0.4,0.2,0.8,0.3,0.9,0.3c0.7,0.3,2.2,0.2,3.4,0.5
		c0.3,0.2,1,0.2,1,0.2s0.1,0.3,0.3,0.3c0.1,0,0.1,0,0.2,0c0,0,0-0.1,0.1-0.1s0.2-0.1,0.3-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1
		s0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.1c0-0.1,0.1-0.2-0.1-0.3c-0.1-0.2-0.2-0.4-0.3-0.4c-0.1-0.1-0.5,0-0.8,0s-0.5,0.1-0.7,0.1
		c-0.7,0-2.3-0.8-3-0.9c-0.3,0-0.6-0.1-0.8-0.3c0-0.2,0-0.2,0-0.2s-0.7-0.6-1.4-1.3c-0.5-0.5-0.9-0.9-1.4-1.1s-0.8,0-1.3-0.2
		c-0.6-0.1-0.7-0.1-1-0.3c-0.1-0.2,0-0.4,0-0.6c0.1-0.2,0.1-0.6,0.2-0.8c0.2-0.1,0.2-0.3,0.2-0.5s0.1-0.4,0.1-0.5
		c0-0.2-0.2-0.1-0.2-0.1s0.1-0.8,0.1-1.2c-0.1-0.3-0.5-0.9-1.2-0.9l0,0c0,0,0,0-0.1,0c-0.7,0-1.1,0.3-1.3,0.7
		c-0.2,0.3-0.2,1-0.1,1.3c-0.4-0.1-0.2,0.6-0.2,0.7s0.2,0.4,0.4,0.5c0,0.9,0,1.7-0.1,1.8C12.7,7.7,12.4,7.7,12,8
		c-0.2,0.1-0.7,0.1-1.2,0.4c-0.5,0.3-1,1.1-1.3,1.4C9,10.4,8.4,11.2,8,11.6c0,0.2,0.2,0.1,0.1,0.2c-0.4,0.2-1,0.8-1.3,1
		c-0.1,0.1-0.7,0.8-0.7,0.9c-0.1,0.1-1.4,0.9-1.4,1.1c0,0.1-0.2,0.7-0.2,0.8s0.1,0.5,0.1,0.6s0.1,0.2,0.1,0.2h0.2c0,0,0-0.3,0.1-0.3
		s0.4,0,0.5-0.1s0.1-0.1,0-0.2c0-0.1-0.4,0-0.5,0c0-0.1-0.2-0.4,0-0.4c0.3,0,0.4-0.3,0.6-0.2c-0.1,0.2,0,0.1,0,0.2
		c0,0,0.1,0.1,0.2,0.1S6,15.2,6.2,15c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,1.3-0.5,1.8-0.9C8.4,13.5,8.9,13,9,13c0,0,0.1,0,0.1,0.1
		c0.3-0.3,2-1.7,2.3-1.6c0.3,0.2,0.4,0.7,0.5,1c0.1,0.7-0.3,1.9-0.7,2.4c-0.2,0.2-0.5,0.9-0.7,1.3c0,0.1,0.1,0.1,0,0.2
		c-0.2,0.2-2.7,3.1-2.8,3.5s0.1,0.4,0.1,0.5c-0.2,0.4-0.2,0.5-0.2,0.5c-0.1,0.1-0.1,0-0.4,0.2c-0.7,0.5-1,2.6-1.4,2.9
		c-0.3,0-1.1,0-1.7,0.4S2.9,25.5,3.3,26c0.6,0.6,2-0.5,2.5-0.7s1.3-0.6,1.3-0.8c0.1-0.4,0-0.4,0-0.5c0.3-0.5,1.3-0.8,1.7-1.3
		c0.4-0.6,0.3-0.6,0.4-0.7s0.4-0.3,0.6-0.5c0.2,0,0.5,0,0.6,0c0.2-0.1,1.7-2.4,2.5-2.3c0.5,0,0.3,2.4,0.4,2.6
		c0.1,0.2,0.3,0.2,0.4,0.3c0,0.3,0,0.4-0.1,0.7c0,0.2,0,0.3-0.3,0.6h-0.1c-0.2,0.2-0.3,0.5-0.4,0.9c-0.5,1.8,0.2,1.4-0.7,4.5
		c-0.1,0.1-0.2,0.5-0.3,0.8c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.1-0.1,0.8-0.1,0.9c0.1,0.1,0.1,0,0.1,0.2c0,0.1,0.1,0.3,0.2,0.4
		s0.3,0.1,0.3,0.1s0.2-0.2,0.3-0.2s0.6,0.6,0.7,0.5c0.1,0,0.2-0.2,0.2-0.2s0.1,0,0.2,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.2,0.1
		c0.1,0,0.2,0,0.2,0c0.1,0.1,0,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.2c0.1-0.2,0-0.2,0-0.3c-0.1-0.3-0.4-0.6-0.7-0.9s-0.5-0.3-0.6-0.5
		c-0.1-0.1,0-0.3,0-0.4C13.3,29,13.8,28.1,13.8,28.1z"
        />
        <path
          d="M15.2,0C6.8,0,0,6.8,0,15.2c0.4,0,0.3,0,0.6,0c0-8,6.5-14.6,14.6-14.6s14.6,6.5,14.6,14.6s-6.5,14.6-14.6,14.6
		c0,0.2,0,0.2,0,0.6c8.4,0,15.2-6.8,15.2-15.2S23.6,0,15.2,0z"
        />
        <path d="M8.2,27.6c-0.9,0-1.7,0.8-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7s1.7-0.8,1.7-1.7C9.9,28.3,9.1,27.6,8.2,27.6z" />
      </g>
      <path
        d="M23,13.5c-2.9,0-5.2,2.3-5.2,5.2s2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2S25.8,13.5,23,13.5z M22.3,16.3l0.7-1.9l0.7,1.9l2,0.1
	l-1.6,1.2l0.5,1.9l-1.7-1.1l-1.7,1.1l0.5-1.9l-1.6-1.2L22.3,16.3z M26.3,21.6c-0.9-0.2-2.1-0.3-3.3-0.3c-1.2,0-2.4,0.1-3.3,0.3v-1.1
	c1-0.3,2.1-0.5,3.3-0.5c1.2,0,2.4,0.2,3.3,0.5V21.6z"
      />
    </>
  );

  return (
    <svg
      x="0px"
      y="0px"
      className={
        `VirtualEuropaCup ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.4 32.01"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      {icon}
    </svg>
  );
};

export default memo(VirtualEuropaCup);
