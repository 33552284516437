import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCMSData, createLoadingSelector } from '../../../selectors';
import PropTypes from 'prop-types';
import ImageForm from './ImageForm';
import ImageList from './ImageList';
import { useMount } from '../../../hooks';
import * as ImageActions from '../../../redux/actions/cms/images';
import FullScreenLoader from '../../../components/Layout/FullScreenLoader/FullScreenLoader';

const Images = ({
  getImageTypes,
  getImages,
  deleteImage,
  uploadImage,
  images,
  imageTypes,
  loading,
}) => {
  useMount(() => {
    getImageTypes();
  });

  return (
    <>
      <FullScreenLoader isActive={loading} />
      <ImageForm
        uploadImage={uploadImage}
        imageTypes={imageTypes}
        getImages={getImages}
      />
      <hr />
      <ImageList images={images} deleteImage={deleteImage} />
    </>
  );
};

Images.propTypes = {
  deleteImage: PropTypes.func,
  getImages: PropTypes.func,
  uploadImage: PropTypes.func,
  images: PropTypes.array,
};

const loadingReducer = createLoadingSelector(['GET_IMAGES']);

const mapStateToProps = state => ({
  images: getCMSData(state).imagesReducer.images,
  imageTypes: getCMSData(state).imagesReducer.imageTypes,
  loading: loadingReducer(state),
});

const mapDispatchToProps = {
  getImages: ImageActions.getImages,
  deleteImage: ImageActions.deleteImage,
  uploadImage: ImageActions.uploadImage,
  getImageTypes: ImageActions.getImageTypes,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Images));
