import React from 'react';

export const Close = (props) => {
    return (
        <span className='efb-icon'>
            <svg fill={props.color} className={`efb-icon--${props.size} efb-close`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
            <path d="M34.8,7.1L23.9,18l10.9,10.9c1.6,1.6,1.6,4.3,0,5.9C34,35.6,32.9,36,31.8,36c-1.1,0-2.1-0.4-2.9-1.2L18,23.9L7.1,34.8
	            C6.3,35.6,5.2,36,4.2,36c-1.1,0-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.3,0-5.9L12.1,18L1.2,7.1c-1.6-1.6-1.6-4.3,0-5.9
	            c1.6-1.6,4.3-1.6,5.9,0L18,12.1L28.9,1.2c1.6-1.6,4.3-1.6,5.9,0C36.4,2.8,36.4,5.5,34.8,7.1z"/>
            </svg>
            {props.children}
        </span>
    )
}

export default Close;