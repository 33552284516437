import React from 'react';
import PageForm from './PageForm';
import Button from '../../../../components/Layout/Button';
import SubpageContentCreator from '../ContentCreator/SubpageContentCreator';
import PropTypes from 'prop-types';
import LanguageSelector from '../../../../components/LanguageSelector/LanguageSelector';
import { useStateForSelect, useUpdate } from '../../../../hooks';

const PageCreator = ({
  pageObj,
  addSubPage,
  updateObj,
  submitPage,
  currentLang,
  submitContent,
  removeSection,
  images,
  uploadImage,
  contentType,
  updateContentTypeValue,
  subpageContent,
  editMode,
  cancelPageContentEditMode,
  pageContentEdit,
  availableCountries,
  currencies,
  prepareSubpath,
  serverError,
  getPages,
  languages,
}) => {
  const [language, setLanguage] = useStateForSelect(currentLang);

  useUpdate(() => {
    if (language) getPages(language);
  }, [language]);

  const mapSubpages = (subpage, index) => {
    return (
      <SubpageContentCreator
        key={index}
        pageObj={pageObj}
        index={index}
        subpage={subpage}
        currentLang={currentLang}
        images={images}
        contentType={contentType}
        availableCountries={availableCountries}
        removeSubpage={removeSubpage}
        uploadImage={uploadImage}
        updateContentTypeValue={updateContentTypeValue}
        subpageContent={subpageContent}
        updateObj={updateObj}
        submitContent={submitContent}
        editMode={editMode}
        currencies={currencies}
        prepareSubpath={prepareSubpath}
        serverError={serverError}
        removeSection={removeSection}
      />
    );
  };

  const removeSubpage = index => {
    const pageObjClone = { ...pageObj };
    pageObjClone.subpages.splice(index, 1);
    updateObj(pageObjClone);
  };
  return (
    <>
      {!pageContentEdit ? (
        <>
          <PageForm
            pageObj={pageObj}
            updateObj={updateObj}
            languages={languages}
          />
          <LanguageSelector
            defaultLang={language}
            onChange={setLanguage}
            moreData
          />
          <hr />
          {serverError ? (
            <small>
              <i className="fa fa-exclamation-triangle"></i> There is some
              server error. You cannot change language now but you can try to
              upload data again.
            </small>
          ) : null}
        </>
      ) : null}

      {pageObj.subpages.map(mapSubpages)}

      <br />
      <div className="form-group d-flex flex-row justify-content-end">
        <Button
          cssClass="efb-btn__main"
          disabled={contentType === ''}
          onClick={submitPage}
        >
          {pageContentEdit ? 'Update' : 'Create'}
        </Button>
        <Button
          cssClass="efb-btn__main"
          disabled={contentType === 'TABLES'}
          onClick={addSubPage}
        >
          <i className="fas fa-plus"></i> New subpage
        </Button>
        {pageContentEdit ? (
          <Button cssClass="efb-btn__main" onClick={cancelPageContentEditMode}>
            {' '}
            Cancel
          </Button>
        ) : null}
      </div>
    </>
  );
};

PageCreator.propTypes = {
  addSubPage: PropTypes.func,
  languages: PropTypes.array,
  pageObj: PropTypes.object,
  removeSubpage: PropTypes.func,
  setContentLang: PropTypes.func,
  submitPage: PropTypes.func,
  updateObj: PropTypes.func,
};

export default PageCreator;
