import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import * as StsActions from '../../../../redux/actions/cms/stsBuilder';
import StsTable from '../StsTable';
import LanguageSelector from '../../../../components/LanguageSelector/LanguageSelector';
import BusinessUnitSelector from '../../../../components/BusinessUnitSelector/BusinessUnitSelector';
import Button from '../../../../components/Layout/Button';
import InputField from '../../../../components/Form/InputField';
import { handleSort, handleSearch } from '../StsUtils';
import { getStsBuilderData } from '../../../../selectors';
import {
  useMount,
  useUpdate,
  useStateForInput,
  useStateForSelect,
} from '../../../../hooks';

const SportsTable = ({
  sports,
  getAllSports,
  languages,
  updateSport,
  updateRanking,
  editSport,
  editingSport,
}) => {
  const [search, setSearch, resetSearch] = useStateForInput('');

  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [selectedBu, setSelectedBu] = useStateForSelect('BG');

  const rankedSports = useMemo(() => handleSort(sports, selectedBu), [
    selectedBu,
    sports,
  ]);

  const [sportsForTable, setSportsForTable] = useState(rankedSports);
  const [newRanking, setNewRanking] = useState(false);

  useMount(getAllSports);

  useUpdate(() => {
    setSportsForTable(rankedSports);
    resetSearch();
  }, [rankedSports]);

  useUpdate(() => {
    setSportsForTable(handleSearch(rankedSports, search, selectedLanguage));
  }, [search]);

  const resetRanking = useCallback(() => {
    setSportsForTable(rankedSports);
    setNewRanking(false);
  }, [rankedSports]);

  const submitRanking = useCallback(() => {
    updateRanking(
      sportsForTable.map((sport, index) => ({
        id: sport.id,
        businessUnit: selectedBu,
        rank: index,
        type: 'SPORT',
      }))
    );
    setNewRanking(false);
  }, [selectedBu, sportsForTable, updateRanking]);

  const onDrop = useCallback(data => {
    setSportsForTable(data);
    setNewRanking(true);
  }, []);

  const updateStatus = useCallback(
    (sport, status) => {
      updateSport({
        ...sport,
        status,
      });
    },
    [updateSport]
  );

  return (
    <>
      <LanguageSelector
        languages={languages}
        onChange={setSelectedLanguage}
        defaultLang={selectedLanguage}
      />
      <br />
      <BusinessUnitSelector onChange={setSelectedBu} defaultBu={selectedBu} />
      <div className="efb-form my-3">
        <InputField
          id="search"
          name="search"
          placeholder="Search"
          type="text"
          className="form-control"
          value={search}
          onChange={setSearch}
        />
      </div>

      {newRanking ? (
        <div className="my-3">
          <Button onClick={submitRanking} cssClass="btn btn-primary">
            Save Rankings
          </Button>
          <Button onClick={resetRanking} cssClass="btn btn-danger">
            Reset Rankings
          </Button>
        </div>
      ) : null}

      <StsTable
        data={sportsForTable}
        updateStatus={updateStatus}
        onDrop={onDrop}
        editSts={editSport}
        editingSts={editingSport}
      />
    </>
  );
};

const mapStateToProps = state => ({
  sports: getStsBuilderData(state).sports,
});

const mapDispatchToProps = {
  getAllSports: StsActions.getAllSports,
  updateSport: StsActions.updateSport,
  updateRanking: StsActions.updateRank,
};

export default connect(mapStateToProps, mapDispatchToProps)(SportsTable);
