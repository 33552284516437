import React, { useCallback } from 'react';
import Switch from '../../Form/Switch/Switch';

const SystemTypesRow = React.memo(({ tableData, onChange, systemTypes }) => {
  const renderRow = useCallback(
    el => (
      <tr key={el}>
        <td>{el}</td>
        <td>
          <Switch
            name={el}
            id={el}
            isChecked={systemTypes.find(fs => fs === el)}
            onChange={onChange}
          />
        </td>
      </tr>
    ),
    [onChange, systemTypes]
  );
  return tableData.map(renderRow);
});

export default SystemTypesRow;
