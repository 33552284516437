import React from 'react';
import PropTypes from 'prop-types';

const InputButton = ({
  name,
  value,
  onChange,
  disabled,
  placeholder,
  label,
}) => (
  <div
    className={
      disabled
        ? 'efb-btn__main file-wrapper disabled'
        : 'efb-btn__main file-wrapper'
    }
  >
    <i className="fas fa-box-open"></i> {label || 'Browse...'}
    <input
      type="file"
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      name={name}
      onChange={onChange}
    />
  </div>
);

InputButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default InputButton;
