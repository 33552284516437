import React, { Component, Fragment } from 'react';
import moment from 'moment';

import Switch from '../Form/Switch/Switch';
import Button from '../Layout/Button';
import SeasonsTableRow from './Rows/SeasonsTableRow';
import BirDelayRow from './Rows/BirDelayRow';
import CashoutCoefficientTableRow from './Rows/CashoutCoefficientTableRow';
import CashoutModelTableRow from './Rows/CashoutModelTableRow';
import BonusStatusRow from './Rows/BonusStatusRow';
import TransactionTableRow from './Rows/TransactionTableRow';
import CasinoGameTableRow from './Rows/CasinoGameTableRow';
import MarketTypesRow from './Rows/MarketTypesRow';
import BetTypesRow from './Rows/BetTypesRow';
import RiskCategoriesRow from './Rows/RiskCategoriesRow';
import SportTemplatesRow from './Rows/SportTemplatesRow';
import SportTypesRow from './Rows/SportTypesRow';
import SystemSettingsRow from './Rows/SystemSettingsRow';
import SystemTypesRow from './Rows/SystemTypesRow';
import ValidationsTableRow from './Rows/ValidationsTableRow';
import CategoriesRow from './Rows/CategoriesRow';
import StaticPageTableRow from './Rows/StaticPageTableRow';
import StaticSubpageTableRow from './Rows/StaticSubpageTableRow';
import BetradarEventsTableRow from './Rows/BetradarEventsTableRow';
import IMGEventsTableRow from './Rows/IMGEventsTableRow';
import SystemsTableRow from './Rows/SystemsTableRow';
import EventsSearchTableRow from './Rows/EventsSearchTableRow';
import SettlementEventsList from './Rows/SettlementEventsList';
import SettlementEventMarkets from './Rows/SettlementEventMarkets';
import SettlementEventMarketOutcomes from './Rows/SettlementEventMarketOutcomes';
import EventSearchMarketTableRow from './Rows/EventSearchMarketTableRow';
import EventSearchMarketOutcomes from './Rows/EventSearchMarketOutcomes';
import SettlementMarketDetails from './Rows/SettlementMarketDetails';
import EventBuilderMarkets from './Rows/EventBuilderMarkets';
import FilteredTransactionsRow from './Rows/FilteredTransactionsRow';
import EventBuilderMarketOutcomes from './Rows/EventBuilderMarketOutcomes';
import EventBuilderMarketSpecifiers from './Rows/EventBuilderMarketSpecifiers';

import ReferralBetTableRowNew from './Rows/ReferralBetTableRowNew';
import SelectionsTableRowNew from './Rows/SelectionsTableRowNew';

import SelectionsTableRow from './Rows/SelectionsTableRow';
import TranslationsTableRow from './Rows/TranslationsTableRow';
import CompetitorsTableRow from './Rows/CompetitorsTableRow';

//TO DO - make tbody dynamic
export class TableBody extends Component {
  renderIcon = value => {
    switch (value) {
      case 'BG':
        return <i className="flag-icon flag-icon-bg" />;
      case 'WEB':
        return <i className="fas fa-globe-americas" />;
      case 'MOBILE':
        return <i className="fas fa-mobile-alt" />;
      default:
        return '';
    }
  };

  renderCell = (value, type, currentTemplate) => {
    switch (type) {
      case 'number':
        return Number(value).toFixed(2);
      case 'account-history-number':
        return (Number(value) / 100).toFixed(2);
      case 'date':
        return value ? moment.utc(value).format('DD/MM/YYYY HH:mm:ss') : '';
      case 'icon':
        return this.renderIcon(value);
      case 'buttons':
        return (
          <Fragment>
            <Button
              onClick={() => this.props.editHandler(currentTemplate)}
              cssClass="btn btn-outline-secondary btn-xs m-2"
            >
              <i className="fas fa-edit" /> Edit
            </Button>
            <Button
              onClick={() => this.props.showDeleteModal(currentTemplate)}
              cssClass="btn btn-danger btn-xs"
            >
              <i className="fas fa-trash" /> Delete
            </Button>
          </Fragment>
        );
      case 'switch-btn':
        return <Switch isChecked={value} isDisabled={true} />;
      default:
        return value;
    }
  };

  renderRow = (tableType, tableData, columns) => {
    switch (tableType) {
      case 'birDelay':
        return <BirDelayRow {...this.props} />;
      case 'seasonsTable':
        return <SeasonsTableRow {...this.props} />;
      case 'BonusStatusTable':
        return <BonusStatusRow {...this.props} />;
      case 'cashoutCoefficientTable':
        return <CashoutCoefficientTableRow {...this.props} />;
      case 'cashoutModelTable':
        return <CashoutModelTableRow {...this.props} />;
      case 'transactionTable':
        return <TransactionTableRow {...this.props} />;
      case 'validationsTable':
        return <ValidationsTableRow {...this.props} />;
      case 'sportTypesTable':
        return <SportTypesRow {...this.props} />;
      case 'systemSettingsRow':
        return <SystemSettingsRow {...this.props} />;
      case 'sportTemplates':
        return <SportTemplatesRow {...this.props} />;
      case 'marketTypesTable':
        return <MarketTypesRow {...this.props} />;
      case 'betTypesTable':
        return <BetTypesRow {...this.props} />;
      case 'casinoGameTable':
        return <CasinoGameTableRow tableData={tableData} {...this.props} />;
      case 'riskCategoriesTable':
        return <RiskCategoriesRow {...this.props} />;
      case 'systemTypesTable':
        return <SystemTypesRow {...this.props} />;
      case 'categoriesTable':
        return <CategoriesRow {...this.props} />;
      case 'staticPagesTable':
        return <StaticPageTableRow {...this.props} />;
      case 'staticSubpageTable':
        return <StaticSubpageTableRow {...this.props} />;
      case 'betradarEventsTable':
        return <BetradarEventsTableRow {...this.props} />;
      case 'imgEventsTable':
        return <IMGEventsTableRow {...this.props} />;
      case 'referralBetTableNew':
        return <ReferralBetTableRowNew {...this.props} />;
      case 'systemsTable':
        return <SystemsTableRow {...this.props} />;
      case 'selectionsTable':
        return <SelectionsTableRow {...this.props} />;
      case 'selectionsTableNew':
        return <SelectionsTableRowNew {...this.props} />;
      case 'eventsSearchTable':
        return <EventsSearchTableRow {...this.props} />;
      case 'settlementEventsList':
        return <SettlementEventsList {...this.props} />;
      case 'settlementEventMarkets':
        return <SettlementEventMarkets {...this.props} />;
      case 'settlementEventMarketOutcomes':
        return <SettlementEventMarketOutcomes {...this.props} />;
      case 'eventSearchMarketTable':
        return <EventSearchMarketTableRow {...this.props} />;
      case 'eventSearchMarketOutcomes':
        return <EventSearchMarketOutcomes {...this.props} />;
      case 'settlementMarketDetails':
        return <SettlementMarketDetails {...this.props} />;
      case 'eventBuilderMarkets':
        return <EventBuilderMarkets {...this.props} />;
      case 'filteredTransactions':
        return <FilteredTransactionsRow {...this.props} />;
      case 'eventBuilderMarketOutcomes':
        return <EventBuilderMarketOutcomes {...this.props} />;
      case 'eventBuilderMarketSpecifiers':
        return <EventBuilderMarketSpecifiers {...this.props} />;
      case 'translationsTable':
        return <TranslationsTableRow {...this.props} />;
      case 'competitorsTable':
        return <CompetitorsTableRow {...this.props} />;

      default:
        return null;
    }
  };

  render() {
    const { tableData, columns, tableType } = this.props;
    return <tbody>{this.renderRow(tableType, tableData, columns)}</tbody>;
  }
}

export default TableBody;
