import React, { useState } from 'react';
import classes from './Sidebar.module.scss';
import TableSelector from '../../../../components/TableSelector/TableSelector';
import { useUpdate } from '../../../../hooks';

const Sidebar = ({
  placedBetsFiltered,
  getMarketsForSportEvent,
  treeProps,
}) => {
  const [entities, setEntities] = useState({});
  const [sportsList, setSportsList] = useState({});

  treeProps = {
    onEntityClick: (entity, entityType) => {
      if (entityType === 'event') {
        getMarketsForSportEvent(entity.id);
      }
    },
    showTournaments: true,
    showEvents: true,
  };

  useUpdate(() => {
    if (Object.keys(placedBetsFiltered).length > 0) {
      const placedBetsFilteredClone = { ...placedBetsFiltered };
      setSportsList({
        ...sportsList,
        sports: placedBetsFilteredClone.sports.map(sport => {
          return {
            names: {
              en: {
                name: sport.sportName,
              },
            },
            id: sport.sportId,
          };
        }),
        categoriess: placedBetsFiltered.categories,
      });

      const res = {
        sport: async id =>
          await Promise.resolve(
            placedBetsFilteredClone.categories[id].map(cat => {
              return {
                names: {
                  en: {
                    name: cat.categoryName,
                  },
                },
                id: cat.categoryId,
                status: 'ACTIVE',
              };
            })
          ),
        category: async id =>
          await Promise.resolve(
            placedBetsFilteredClone.tournaments[id].map(tournament => {
              return {
                names: {
                  en: {
                    name: tournament.tournamentName,
                  },
                },
                id: tournament.tournamentId,
                status: 'ACTIVE',
              };
            })
          ),
        tournament: async id =>
          await Promise.resolve(
            placedBetsFilteredClone.sportEvents[id].map(event => {
              return {
                names: {
                  en: {
                    name: event.sportEventName,
                  },
                },
                id: event.sportEventId,
              };
            })
          ),
        event: async id => getMarketsForSportEvent(id),
      };

      setEntities(res);
    }
  }, [placedBetsFiltered]);

  return (
    <div className={classes.sidebar}>
      <TableSelector
        sports={sportsList.sports}
        getters={entities}
        withoutContextMenu
        {...treeProps}
      />
    </div>
  );
};

export default Sidebar;
