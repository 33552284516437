import React, { useState, useCallback, useMemo } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

import * as EventsManagerActions from '../../redux/actions/cms/eventsManager';
import * as EventSearchActions from '../../redux/actions/cms/eventSearch';
import * as SctManagerActions from '../../redux/actions/cms/sctManager';
import classes from './MapIMGStreamModal.module.scss';
import Input from '../../components/Form/Input';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Layout/Button';
import { selectStyles } from '../CMS/customReactSelectStyle';
import { getEventSearchData, getEventsManagerData } from '../../selectors';
import {
  useMount,
  useStateForInput,
  useStateForSelect,
  useUpdate,
} from '../../hooks';

const MapIMGStreamModal = ({
  stream,
  setStream,
  sports,
  categories,
  seasons,
  events,
  getSports,
  getCategories,
  getSeasons,
  getEvents,
  getEventById,
  updateEvent,
  searchSportEvents,
  clearSearchSportEvents,
  searchResults,
}) => {
  const [search, setSearch, resetSearch] = useStateForInput('');
  const [selectedSport, setSelectedSport] = useStateForSelect(null);
  const [selectedCategory, setSelectedCategory] = useStateForSelect(null);
  const [selectedSeason, setSelectedSeason] = useStateForSelect(null);
  const [selectedEvent, setSelectedEvent] = useStateForSelect(null);
  const [shouldPulse, setShouldPulse] = useState(false);

  const sportOptions = useMemo(
    () =>
      sports.map(sport => ({
        value: sport,
        label:
          sport.names.en !== undefined
            ? sport.names.en.name
            : 'Not available name',
      })),
    [sports]
  );

  const categoryOptions = useMemo(
    () =>
      categories.map(category => ({
        value: category,
        label:
          category.names.en !== undefined
            ? category.names.en.name
            : 'Not available name',
      })),
    [categories]
  );

  const seasonOptions = useMemo(
    () =>
      seasons.map(season => ({
        value: season,
        label:
          season.names.en !== undefined
            ? season.names.en.name
            : 'Not available name',
      })),
    [seasons]
  );

  const [eventsOptions, setEventsOptions] = useState([]);

  useUpdate(async () => {
    if (searchResults.length > 0) {
      setEventsOptions(
        await Promise.all(
          searchResults
            .filter(
              res =>
                res.result.type === 'match' && res.highlights['names.en.name']
            )
            .map(async res => {
              const event = await getEventById(res.result.id);
              return {
                value: event,
                label: event.names.en.name,
              };
            })
        )
      );
      setSelectedSport(null);
      setSelectedCategory(null);
      setSelectedSeason(null);
      setSelectedEvent(null);
      setShouldPulse(true);
    } else if (events.length) {
      setEventsOptions(
        events.map(ev => ({
          value: ev,
          label: ev.names.en.name,
        }))
      );
      setShouldPulse(true);
    }
  }, [searchResults, events]);

  useMount(() => {
    getSports();
  });

  const handleOnKeyDown = useCallback(
    e => {
      if (e.keyCode === 13) {
        searchSportEvents(search);
      }
    },
    [search, searchSportEvents]
  );

  const findOption = useCallback((array, name, updateFn) => {
    const foundOption = array.find(option => option.label === name);
    if (foundOption) {
      updateFn(foundOption);
    }
  }, []);

  useUpdate(() => {
    findOption(
      sportOptions,
      stream.first_level_category.name,
      setSelectedSport
    );
  }, [sportOptions]);

  useUpdate(() => {
    findOption(
      categoryOptions,
      stream.second_level_category.name,
      setSelectedCategory
    );
  }, [categoryOptions]);

  useUpdate(() => {
    findOption(
      seasonOptions,
      stream.third_level_category.name,
      setSelectedSeason
    );
  }, [seasonOptions]);

  useUpdate(() => {
    if (!searchResults && !searchResults.length) {
      findOption(eventsOptions, stream.name, setSelectedEvent);
    }
  }, [seasonOptions]);

  useUpdate(() => {
    if (selectedSport && selectedSport.value) {
      getCategories(selectedSport.value.id);
      setSelectedCategory(null);
      setSelectedSeason(null);
      setSelectedEvent(null);
    }
  }, [selectedSport]);

  useUpdate(() => {
    if (selectedCategory && selectedCategory.value) {
      getSeasons(selectedCategory.value.id);
      setSelectedSeason(null);
      setSelectedEvent(null);
    }
  }, [selectedCategory]);

  useUpdate(() => {
    if (selectedSeason && selectedSeason.value) {
      clearSearchSportEvents();
      resetSearch();
      getEvents(selectedSeason.value.id);
      setSelectedEvent(null);
    }
  }, [selectedSeason]);

  return (
    <Modal
      isActive={!!stream}
      headerText="Select which event maps to this stream"
      onClose={() => setStream(null)}
      fullscreen
    >
      <div className="efb-modal__content">
        <div className="d-flex justify-content-around mb-5">
          <h3 className="text-center">
            Sport:{' '}
            <span className="badge badge-warning">
              {stream.first_level_category.name}
            </span>
          </h3>
          <h3 className="text-center">
            Category:{' '}
            <span className="badge badge-warning">
              {stream.second_level_category.name}
            </span>
          </h3>
          <h3 className="text-center">
            Tournament:{' '}
            <span className="badge badge-warning">
              {stream.third_level_category.name}
            </span>
          </h3>
          <h3 className="text-center">
            Event: <span className="badge badge-warning">{stream.name}</span>
          </h3>
        </div>
        <div className="flex-column mb-3">
          <div className="col efb-form mb-3">
            <Input
              className="form-control"
              placeholder="Search event"
              value={search}
              onChange={setSearch}
              onKeyDown={handleOnKeyDown}
            />
          </div>
          <h4 className="text-center mb-3">OR</h4>
          <div className="d-flex flex-row mb-3">
            <Select
              className="col"
              styles={selectStyles}
              options={sportOptions}
              value={selectedSport}
              onChange={setSelectedSport}
              placeholder="Select Sport"
            />
            <Select
              className="col"
              styles={selectStyles}
              options={categoryOptions}
              value={selectedCategory}
              onChange={setSelectedCategory}
              placeholder="Select Category"
            />
          </div>
          <Select
            className="col mb-3"
            styles={selectStyles}
            options={seasonOptions}
            value={selectedSeason}
            onChange={setSelectedSeason}
            placeholder="Select Tournament"
          />
          <hr />
          <Select
            className={`col ${shouldPulse ? classes.pulseAnim : ''}`}
            onFocus={() => setShouldPulse(false)}
            styles={selectStyles}
            options={eventsOptions}
            value={selectedEvent}
            onChange={setSelectedEvent}
            placeholder="Select Event"
          />
        </div>
      </div>
      <div className="form-row justify-content-center mb-5">
        <Button
          onClick={() => {
            updateEvent(selectedEvent.value, stream.id, 2);
            setStream(null);
          }}
          cssClass="efb-btn__main"
        >
          Map
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  sports: getEventsManagerData(state).sports,
  categories: getEventsManagerData(state).categories,
  seasons: getEventsManagerData(state).seasons,
  events: getEventsManagerData(state).events,

  searchResults: getEventSearchData(state).searchSportEvents,
});

const mapDispatchToProps = {
  getSports: EventsManagerActions.getSports,
  getCategories: EventsManagerActions.getCategories,
  getSeasons: EventsManagerActions.getSeasons,
  getEvents: EventsManagerActions.getEvents,

  getEventById: SctManagerActions.getEventById,

  searchSportEvents: EventSearchActions.searchSportEvents,
  clearSearchSportEvents: EventSearchActions.clearSearchSportEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapIMGStreamModal);
