import {TOGGLE_PLAYER_FILTER_MODAL} from './../actions/ui';
import {getUiRequestType} from '../actions/ui';

const initialState = {
	togglePlayerFilterModal: false,
	isFiltersModalOpen: false,
	toggleUserModal: false,
	toggleRolesFilter: false,
	toggleRolesModal: false,
	toggleRolePermissionsModal: false,
	togglePlayerManagerFiltersModal: false,
	toggleEventSettleModal: false
};

export const uiComponentsReducer = (state = initialState, action) => {
	switch(action.type){
		case TOGGLE_PLAYER_FILTER_MODAL:
			return {...state, togglePlayerFilterModal: action.payload};
		case getUiRequestType.OPEN_FILTERS_MODAL:
			return {...state, isFiltersModalOpen: true};
		case getUiRequestType.CLOSE_FILTERS_MODAL:
			return {...state, isFiltersModalOpen: false};
		case getUiRequestType.TOGGLE_USERS_MODAL:
			return {...state, toggleUserModal: action.payload};
		case getUiRequestType.TOGGLE_ROLES_FILTERS:
			return {...state, toggleRolesFilter: action.payload};
		case getUiRequestType.TOGGLE_ROLES_MODAL:
			return {...state, toggleRolesModal: action.payload};
		case getUiRequestType.TOGGLE_ROLE_PERMISSIONS_MODAL:
			return {...state, toggleRolePermissionsModal: action.payload};
		case getUiRequestType.TOGGLE_PLAYER_MANAGER_FILTERS_MODAL:
			return {...state, togglePlayerManagerFiltersModal: action.payload};
		case getUiRequestType.TOGGLE_EVENT_SETTLE_MODAL:
			return {...state, toggleEventSettleModal: action.payload};
		default: return state
	}
};