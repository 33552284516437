import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import logo from '../../resources/images/logo.png';
import Alert from '../Icons/Alert';
import ArrowSmall from '../Icons/ArrowSmall';
import Admin from '../Icons/Admin';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/authentication';
import { getAuthData } from '../../selectors/index';

class AdminBar extends Component {
  state = {
    isOpen: false,
  };

  logout = () => {
    this.props.logout(this.props.history);
  };

  render() {
    const { match } = this.props;
    return (
      <section className="efb-admin-bar">
        <div className="efb-container efb-admin-bar__wrapper">
          <Link to={match.path}>
            <img src={logo} alt="efbet logo" height={25} />
          </Link>
          <nav>
            <ul className="efb-admin-bar__nav">
              <li className="efb-admin-bar__nav-item">
                <Alert size="sm" />
              </li>
              <li className="efb-admin-bar__nav-item dropdown">
                <Admin size="lg" />
                <span className="efb-admin-bar__admin-details">
                  {localStorage.getItem('currentUser') ? (
                    <span>
                      {JSON.parse(localStorage.getItem('currentUser')).username}
                    </span>
                  ) : (
                    <Fragment>
                      <span>Firstname</span>
                      <span>Lastname</span>
                    </Fragment>
                  )}
                </span>
                <span className="efb-icon">
                  <ArrowSmall size="xxs" />
                </span>
                <div className="dropdown-menu">
                  <div className="dropdown-item" onClick={this.logout}>
                    Logout
                  </div>
                </div>
              </li>
              <li />
            </ul>
          </nav>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  let auth = getAuthData(state);

  return {
    auth,
  };
};

const mapDispatchToProps = {
  logout,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminBar)
);
