export const transformRoles = user => {
  let roles = user.roles;
  let result = {};

  if (roles.length > 0) {
    roles.forEach(element => {
      element.privileges.forEach(privilege => {
        if (privilege.applications.length) {
          privilege.applications.forEach(app => {
            if (result.hasOwnProperty(app.label)) {
              if (app.views.length > 0) {
                app.views.forEach(view => {
                  let currentElement = {};
                  currentElement['name'] = view.label;
                  currentElement['url'] = view.url;
                  let isExist = Boolean(
                    result[app.label]['children'].find(route => {
                      return route.name === currentElement.name;
                    })
                  );
                  if (!isExist) {
                    result[app.label]['children'].push(currentElement);
                  }
                });
              }
            } else {
              let currentElement = {};
              currentElement['name'] = app.label;
              currentElement['url'] = app.url;
              currentElement['children'] = [];
              if (app.views.length > 0) {
                app.views.forEach(view => {
                  let el = {};
                  el['name'] = view.label;
                  el['url'] = view.url;
                  currentElement['children'].push(el);
                });
              }

              result[app.label] = currentElement;
            }
          });
        }
      });
    });
  }

  return Object.values(result);
};
