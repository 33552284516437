import React, { useMemo } from 'react';
import Select from 'react-select';
import { selectStyles } from '../../containers/CMS/customReactSelectStyle';
import Button from '../Layout/Button';

import classes from './Pagination.module.scss';

const pageSizeOptions = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '15',
    value: 15,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '50',
    value: 50,
  },
];

const Pagination = ({
  pageSize,
  currentPage,
  setCurrentPage,
  setPageSize,
  dataLength,
}) => {
  const pages = useMemo(() => Math.ceil(dataLength / pageSize), [
    dataLength,
    pageSize,
  ]);

  return (
    <div className={classes.wrapper}>
      <div className="col-2">
        <Select
          menuPlacement="auto"
          styles={selectStyles}
          options={pageSizeOptions}
          onChange={data => setPageSize(data.value)}
          value={pageSizeOptions.find(option => option.value === pageSize)}
        />
      </div>

      {pages > 1 && (
        <div className={classes.pageButtons}>
          {currentPage > 1 && (
            <Button onClick={() => setCurrentPage(currentPage - 1)}>{'<'}</Button>
          )}
          {[...Array(pages)].map((_, index) => (
            <Button disabled={currentPage === index + 1} onClick={() => setCurrentPage(index + 1)}>{index + 1}</Button>
          ))}
          {currentPage < pages && (
            <Button onClick={() => setCurrentPage(currentPage + 1)}>{'>'}</Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Pagination;
