import axiosInstance from '../axios-instance';
import { actionCreator, createRequestTypes } from '../index';
import { addNotificationMessage } from '../ui';

export const MANAGER_GET_SPORTS = createRequestTypes('MANAGER_GET_SPORTS');
export const MANAGER_GET_CATEGORIES = createRequestTypes(
  'MANAGER_GET_CATEGORIES'
);
export const MANAGER_GET_TOURNAMENTS = createRequestTypes(
  'MANAGER_GET_TOURNAMENTS'
);
export const MANAGER_GET_SEASONS = createRequestTypes('MANAGER_GET_SEASONS');
export const MANAGER_GET_EVENTS = createRequestTypes('MANAGER_GET_EVENTS');
export const GET_PRODUCERS = createRequestTypes('GET_PRODUCERS');
export const BOOK = createRequestTypes('BOOK');
export const ENTITY_MERGE = createRequestTypes('ENTITY_MERGE');
export const GET_SOURCE_PROVIDER_ENTITY = createRequestTypes(
  'GET_SOURCE_PROVIDER_ENTITY'
);
export const GET_DESTINATION_PROVIDER_ENTITY = createRequestTypes(
  'GET_DESTINATION_PROVIDER_ENTITY'
);
export const CREATE_SPORT_EVENT_CONFIG = createRequestTypes(
  'CREATE_SPORT_EVENT_CONFIG'
);
export const UPDATE_SPORT_EVENT_CONFIG = createRequestTypes(
  'UPDATE_SPORT_EVENT_CONFIG'
);

export const merge = (
  sourceId,
  destinationId,
  entityType,
  producerId,
  excludeProducerId
) => async dispatch => {
  dispatch(actionCreator.request(ENTITY_MERGE));
  try {
    await axiosInstance.post(`/sport/event/view/${entityType}/admin/merge`, {
      sourceId,
      destinationId,
    });
    getSourceEntity(producerId, excludeProducerId, entityType)(dispatch);
    getDestinationEntity(producerId, excludeProducerId, entityType)(dispatch);
    dispatch(actionCreator.success(ENTITY_MERGE));
    dispatch(
      addNotificationMessage(
        'Successfully merged entities!',
        'success',
        'Success'
      )
    );
  } catch (error) {
    dispatch(actionCreator.failure(ENTITY_MERGE, error.message));
    dispatch(addNotificationMessage(error.message, 'error', 'Error'));
  }
};
export const getSourceEntity = (producerId, excludeProducerId, entityType) =>
  getProviderEntity(
    producerId,
    excludeProducerId,
    GET_SOURCE_PROVIDER_ENTITY,
    entityType
  );
export const getDestinationEntity = (
  producerId,
  excludeProducerId,
  entityType
) =>
  getProviderEntity(
    producerId,
    excludeProducerId,
    GET_DESTINATION_PROVIDER_ENTITY,
    entityType
  );

const getProviderEntity = (
  producerId,
  excludeProducerId,
  requestType,
  entityType
) => async dispatch => {
  dispatch(actionCreator.request(requestType));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/${entityType}/admin`,
      {
        params: {
          producerId,
          excludeProducerId,
        },
      }
    );
    dispatch(actionCreator.success(requestType, res));
  } catch (error) {
    dispatch(actionCreator.failure(requestType, error.message));
    dispatch(addNotificationMessage(error.message, 'error', 'Error'));
  }
};

export const getSports = (filters = {}) => async dispatch => {
  dispatch(actionCreator.request(MANAGER_GET_SPORTS));
  try {
    const res = await axiosInstance.get('/sport/event/view/sport/admin', {
      params: filters,
    });
    dispatch(actionCreator.success(MANAGER_GET_SPORTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(MANAGER_GET_SPORTS, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getCategories = (id, filters = {}) => async dispatch => {
  dispatch(actionCreator.request(MANAGER_GET_CATEGORIES));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/category/admin?sportId=${id}`,
      { params: filters }
    );
    dispatch(actionCreator.success(MANAGER_GET_CATEGORIES, res));
    return res;
  } catch (err) {
    dispatch(actionCreator.failure(MANAGER_GET_CATEGORIES, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getTournaments = (id, filters = {}) => async dispatch => {
  dispatch(actionCreator.request(MANAGER_GET_TOURNAMENTS));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/tournament/admin?categoryId=${id}`,
      { params: filters }
    );
    dispatch(actionCreator.success(MANAGER_GET_TOURNAMENTS, res));
    return res;
  } catch (err) {
    dispatch(actionCreator.failure(MANAGER_GET_TOURNAMENTS, ''));
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const getSeasons = id => async dispatch => {
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/tournament/admin?categoryId=${id}`
    );
    dispatch(actionCreator.success(MANAGER_GET_SEASONS, res));
  } catch (err) {
    dispatch(actionCreator.failure(MANAGER_GET_SEASONS, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

// Events
export const getEvents = (id, filters = {}) => async dispatch => {
  dispatch(actionCreator.request(MANAGER_GET_EVENTS));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/match/admin?tournamentId=${id}`,
      { params: filters }
    );
    dispatch(actionCreator.success(MANAGER_GET_EVENTS, res));
    return res;
  } catch (err) {
    dispatch(actionCreator.failure(MANAGER_GET_EVENTS, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateSportEventConfig = ({
  id,
  adjustment,
  sportEventId,
}) => async dispatch => {
  dispatch(actionCreator.request(UPDATE_SPORT_EVENT_CONFIG));
  try {
    await axiosInstance.put(`/riskmgnt/view/admin/sportEvents/${id}`, {
      adjustment,
      sportEventId,
    });
    dispatch(actionCreator.success(UPDATE_SPORT_EVENT_CONFIG));
  } catch (err) {
    dispatch(actionCreator.failure(UPDATE_SPORT_EVENT_CONFIG, err.message));
    dispatch(addNotificationMessage(err.message, 'error', 'Error!'));
  }
};

export const createSportEventConfig = ({
  adjustment,
  sportEventId,
}) => async dispatch => {
  dispatch(actionCreator.request(CREATE_SPORT_EVENT_CONFIG));
  try {
    await axiosInstance.post('/riskmgnt/view/admin/sportEvents', {
      adjustment,
      sportEventId,
    });
    dispatch(actionCreator.success(CREATE_SPORT_EVENT_CONFIG));
  } catch (err) {
    dispatch(actionCreator.failure(CREATE_SPORT_EVENT_CONFIG, err.message));
    dispatch(addNotificationMessage(err.message, 'error', 'Error!'));
  }
};

export const getProducers = () => async dispatch => {
  dispatch(actionCreator.request(GET_PRODUCERS));
  try {
    const res = await axiosInstance.get('sport/event/view/producer/public');
    dispatch(actionCreator.success(GET_PRODUCERS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_PRODUCERS, error.message));
  }
};

export const updateMatchWebStatus = (event, id) => async dispatch => {
  try {
    await axiosInstance.post(`/sport/event/view/match/admin/${id}`, event);
    dispatch(
      addNotificationMessage(
        'Successfully updated match status',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const filterByBookingStatus = (
  status,
  tournamentId
) => async dispatch => {
  if (status === 'ALL') {
    try {
      const res = await axiosInstance.get(
        `/sport/event/view/match/admin?tournamentId=${tournamentId}`
      );
      dispatch(actionCreator.success(MANAGER_GET_EVENTS, res));
    } catch (err) {
      dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    }
  } else {
    try {
      const res = await axiosInstance.get(
        `/sport/event/view/match/admin?bookingStatus=${status}&tournamentId=${tournamentId}`
      );
      dispatch(actionCreator.success(MANAGER_GET_EVENTS, res));
    } catch (err) {
      dispatch(actionCreator.failure(MANAGER_GET_EVENTS, ''));
      dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    }
  }
};

export const bookEvent = event => async dispatch => {
  let feedProviderId;
  if (event.feedProducers.length > 0) {
    feedProviderId = event.feedProducers[0].feedProviderId;
    try {
      await axiosInstance.get(
        `/sport/event/view/match/admin/book?feedProducerId=1&feedProviderId=${feedProviderId}`
      );
      dispatch(actionCreator.success(BOOK, ''));
      dispatch(
        addNotificationMessage(
          'You have successfully booked this event',
          'success',
          'Success'
        )
      );
    } catch (err) {
      dispatch(actionCreator.failure(BOOK, ''));
      dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    }
  } else {
    dispatch(
      actionCreator.failure(BOOK, {
        error: 'Feed Provider',
        message: 'Missing feedProviderId',
      })
    );
  }
};
