import React, { useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import classes from './ActionLog.module.scss';
import Modal from '../../../../components/NewModal/Modal';
import Input from '../../../../components/Form/Input';
import CheckboxDiv from '../../../../components/Form/CheckboxDiv/CheckboxDiv';
import CalendarDayPicker from '../../../../components/CalendarDayPicker/CalendarDayPicker';
import Table from '../../../../components/NewTable/Table';

import { IconCalendar } from '../../../../components/Icons/UI/ui/IconCalendar';
import { useUpdate } from '../../../../hooks';

// data for testing
const testData = [
  {
    date: '02/11/2019  14:32:48',
    message: 'Вие получихте 10 BGN бонус по Вашата сметка! При..',
  },
  {
    date: '02/11/2019  14:32:48',
    message: 'Вие получихте 10 BGN бонус по Вашата сметка! При..',
  },
  {
    date: '02/11/2019  14:32:48',
    message: 'Вие получихте 10 BGN бонус по Вашата сметка! При..',
  },
  {
    date: '02/11/2019  14:32:48',
    message: 'Вие получихте 10 BGN бонус по Вашата сметка! При..',
  },
  {
    date: '02/11/2019  14:32:48',
    message: 'Вие получихте 10 BGN бонус по Вашата сметка! При..',
  },
];

const dataTable = [
  {
    type: 'Email address',
    old: 'kpamporov89@abv.bg',
    new: 'k89pamporov@gmail.com',
  },
  {
    type: 'Email address',
    old: 'kpamporov89@abv.bg',
    new: 'k89pamporov@gmail.com',
  },
  {
    type: 'Email address',
    old: 'kpamporov89@abv.bg',
    new: 'k89pamporov@gmail.com',
  },
  {
    type: 'Email address',
    old: 'kpamporov89@abv.bg',
    new: 'k89pamporov@gmail.com',
  },
  {
    type: 'Email address',
    old: 'kpamporov89@abv.bg',
    new: 'k89pamporov@gmail.com',
  },
  {
    type: 'Email address',
    old: 'kpamporov89@abv.bg',
    new: 'k89pamporov@gmail.com',
  },
];

const ActionLog = ({ isActive, onClose, customer }) => {
  const columns = useMemo(
    () => [
      { name: 'Date', display: message => message.date },
      { name: 'Operator', display: message => message.message },
    ],
    []
  );

  const columns2 = useMemo(
    () => [
      { name: 'Data Type', display: message => message.type },
      { name: 'Old Data', display: message => message.old },
      { name: 'New Data', display: message => message.new },
    ],
    []
  );

  const [openedFromCalendar, setOpenedFromCalendar] = useState(false);
  const [openedToCalendar, setOpenedToCalendar] = useState(false);
  const [from, setFrom] = useState(Date.now());
  const [to, setTo] = useState(Date.now());
  const [fromDateActive, setFromDateActive] = useState(false);
  const [toDateActive, setToDateActive] = useState(false);

  const handleDayClick = useCallback((day, direction) => {
    if (direction === 'from') {
      setFrom(day);
      setOpenedFromCalendar(false);
    } else {
      setTo(day);
      setOpenedToCalendar(false);
    }
  }, []);

  useUpdate(() => {
    if (!fromDateActive) setFrom(Date.now());
    if (!toDateActive) setTo(Date.now());
  }, [fromDateActive, toDateActive]);

  const checkIsDateTodayOrYesterday = useCallback(date => {
    if (
      moment(date).format('DD.MM.YYYY') ===
      moment(Date.now()).format('DD.MM.YYYY')
    ) {
      return 'Today';
    } else if (
      moment(Date.now()).format('DD') - moment(date).format('DD') ===
      1
    ) {
      return 'Yesterday';
    }
    return moment(date).format('DD.MM.YYYY');
  }, []);

  return (
    <Modal
      className={`${classes.missing}`}
      isActive={isActive}
      onClose={onClose}
    >
      <div className={classes.header}>
        <div>
          <p>Action Log</p>
        </div>
        <div>
          <div>
            <p className={classes.name}>{customer.firstLastName}</p>
            <p className={classes.id}>[{customer.publicId}]</p>
          </div>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.divider}>
          <div>
            <h1>Action Date</h1>
            <div className={classes.dateWrapper}>
              <div>
                <p>From:</p>
              </div>
              <div className={classes.dateBox}>
                <CheckboxDiv
                  isChecked={fromDateActive}
                  onChange={() => setFromDateActive(!fromDateActive)}
                  value={fromDateActive}
                  name={'fromDate'}
                  id={'fromDate'}
                />
                {openedFromCalendar && fromDateActive ? (
                  <CalendarDayPicker
                    handleDayClick={e => handleDayClick(e, 'from')}
                    from={from}
                  />
                ) : null}
                <div
                  onClick={() => {
                    setOpenedFromCalendar(
                      fromDateActive ? !openedFromCalendar : ''
                    );
                  }}
                >
                  <p
                    className={classes.pickedTime}
                    style={{
                      color: !fromDateActive ? 'rgba(112, 112, 112, 0.5)' : '',
                    }}
                  >
                    {checkIsDateTodayOrYesterday(from)}
                  </p>
                  <IconCalendar width={15} />
                </div>
              </div>
            </div>
            <div className={classes.dateWrapper}>
              <div>
                <p>To:</p>
              </div>
              <div className={classes.dateBox}>
                <CheckboxDiv
                  isChecked={toDateActive}
                  onChange={() => setToDateActive(!toDateActive)}
                  value={toDateActive}
                  name={'toDate'}
                  id={'toDate'}
                />
                {openedToCalendar && toDateActive ? (
                  <CalendarDayPicker
                    handleDayClick={e => handleDayClick(e, 'to')}
                    to={to}
                  />
                ) : null}
                <div
                  onClick={() => {
                    setOpenedToCalendar(toDateActive ? !openedToCalendar : '');
                  }}
                >
                  <p
                    className={classes.pickedTime}
                    style={{
                      color: !toDateActive ? 'rgba(112, 112, 112, 0.5)' : '',
                    }}
                  >
                    {checkIsDateTodayOrYesterday(to)}
                  </p>
                  <IconCalendar width={15} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.inputWrapper}>
              <h1>OPERATOR</h1>
              <Input
                customInput
                type="text"
                className={'efb-input__underlined'}
              />
            </div>
          </div>
        </div>
        <div className={classes.tableWrapper}>
          <div className={classes.operatorTable}>
            <Table columns={columns} data={testData} pagination={false} />
          </div>
          <div className={classes.dataTableWrapper}>
            <Table columns={columns2} data={dataTable} pagination={false} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ActionLog;
