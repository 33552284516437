import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import * as CustomerDataActions from '../../../redux/actions/customer/customerData';
import * as RiskCategoryActions from '../../../redux/actions/riskManagement/RiskCategory';
import * as ReferralConsoleActions from '../../../redux/actions/riskManagement/ReferralConsole';
import {
  getCustomerData,
  getReferralConsoleData,
  getRiskCategoryData,
  getWsData,
} from '../../../selectors';
import DetailsModal from './DetailsModal';
import ReferralBetTable from './ReferralBetTable';
import ReferalSidebar from './ReferalSidebar';
import { useMount, useUnmount, useUpdate, useInterval } from '../../../hooks';

// for testing
// import {
//   hardcoded,
//   hardcoded2,
//   hardcoded3,
//   treble,
//   doubles,
//   singlesPayload,
//   doublesRes,
//   singlesRes,
//   transformBetslipToSystems,
//   patentRes,
//   singleDouble,
//   singlesOneTab,
//   singleTrebleNew,
// } from './ReferralUtils';
// // import { test } from '../Referral/ReferralUtils';
// const betslips = hardcoded2;

const Referral = ({
  getBetTypes,
  getRiskCategories,
  wsFrame,
  emptyReferralBetslip,
  wsConnectionStatus,
  getCountOfActive,
  pingConsole,
  updateReferral,
  createReferral,
  getReferralForCurrentUser,
  referral,
  systemTypes: allSystemTypes,
  riskCategories: allRiskCategories,
  betslips,
  setBetslipStatus,
  changedOdds,
  countActive,
  referralType,
  systemReoffer,
  customerCountries,
  removeReferralBetslip,
}) => {
  const [feedScopes, setFeedScopes] = useState([]);
  const [systemTypes, setSystemTypes] = useState([]);
  const [betslipsList, setBetslipsList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [betslipId, setBetslipId] = useState('');
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [wsBound, setWsBound] = useState(false);
  const [minBet, setMinBet] = useState(0);

  useMount(() => {
    getBetTypes();
    getRiskCategories();
    getReferralForCurrentUser();
  });

  useInterval(() => {
    if (Object.keys(referral).length > 0) {
      pingConsole(referral.id);
      getActiveCount();
    }
  }, 1000 * 10);

  useUpdate(() => {
    const betslipsClone = [...betslips];
    if (Object.keys(referral).length > 0) {
      const betslipsReduced = betslipsClone.reduce(
        (betslipsAcc, betslip, betslipIndex) => {
          const systemBetsClone = [...betslip.systemBets];
          const filteredSystemBetsByReferralTypes = systemBetsClone
            .filter(
              systemBet =>
                referral.systemTypes.findIndex(
                  referralType => referralType === systemBet.type
                ) !== -1
            )
            .reduce((acc, bet, index) => [...acc, { ...bet, id: index }], []);
          if (filteredSystemBetsByReferralTypes.length === 0) {
            betslipsClone.splice(betslipIndex, 1);
          }
          return [
            ...betslipsAcc,
            {
              ...betslipsClone[betslipIndex],
              systemBets: filteredSystemBetsByReferralTypes,
            },
          ];
        },
        []
      );
      setBetslipsList(
        betslipsReduced.filter(
          betslip =>
            referral.categories.findIndex(
              x => x.id === betslip.riskCategoryId
            ) !== -1
        )
      );
    }
  }, [referral, betslips]);

  useUnmount(() => {
    if (wsBound) {
      ReferralConsoleActions.removeWsBinding(
        referral.id,
        wsFrame.headers['session']
      );
      emptyReferralBetslip();
    }
  }, [wsBound]);

  useUpdate(() => {
    if (
      !wsBound &&
      Object.keys(referral).length &&
      Object.keys(wsFrame).length &&
      wsConnectionStatus
    ) {
      ReferralConsoleActions.addWsBinding(
        referral.id,
        wsFrame.headers['session']
      ).catch(() => setWsBound(false));
      setWsBound(true);
    }
    if (Object.keys(referral).length) {
      setFeedScopes(referral.feedScopes);
      setSystemTypes(referral.systemTypes);
      setCategories(referral.categories.filter(cat => !cat.skipReferral));
      setMinBet(referral.minBet);
    }
  }, [referral, wsFrame, wsConnectionStatus]);

  const saveReferral = useCallback(() => {
    const shouldUpdate = Boolean(Object.keys(referral).length);
    const data = { feedScopes, systemTypes, categories, minBet };
    if (shouldUpdate) {
      updateReferral(data, referral.id);
    } else {
      createReferral(data);
    }
    setEdit(false);
    setWsBound(false);
  }, [
    categories,
    createReferral,
    feedScopes,
    minBet,
    referral,
    systemTypes,
    updateReferral,
  ]);

  const onMinMaxChange = useCallback(e => {
    if (Number(e.target.value) < 0) {
      return;
    }
    setMinBet(e.target.value);
    setEdit(true);
  }, []);

  const onSwitchChange = useCallback(
    e => {
      let feedScopesClone = [...feedScopes];
      if (feedScopesClone.includes(e.target.name)) {
        feedScopesClone = feedScopesClone.filter(
          scope => scope !== e.target.name
        );
      } else {
        feedScopesClone.push(e.target.name);
      }
      setFeedScopes(feedScopesClone);
      setEdit(true);
    },
    [feedScopes]
  );

  const setStateCategories = useCallback(data => {
    setCategories(data);
    setEdit(true);
  }, []);

  const setStateSystemTypes = useCallback(data => {
    setSystemTypes(data);
    setEdit(true);
  }, []);

  const getActiveCount = async () => {
    await getCountOfActive();
  };

  return (
    <div className="form-row">
      <div className="col-md-3">
        <ReferalSidebar
          allSystemTypes={allSystemTypes}
          countActive={countActive}
          feedScopes={feedScopes}
          onSwitchChange={onSwitchChange}
          onMinMaxChange={onMinMaxChange}
          allRiskCategories={allRiskCategories}
          edit={edit}
          referral={referral}
          saveReferral={saveReferral}
          categories={categories}
          setStateCategories={setStateCategories}
          systemTypes={systemTypes}
          setStateSystemTypes={setStateSystemTypes}
          getCountOfActive={getActiveCount}
          wsBound={wsBound}
          minBet={minBet}
          wsConnectionStatus={wsConnectionStatus}
        />
      </div>
      <div className="col-md-9">
        <div className="referal-table-wrapper">
          <ReferralBetTable
            setBetslipStatus={setBetslipStatus}
            openDetails={betslipId => {
              setModal(true);
              setBetslipId(betslipId);
            }}
            changedOdds={changedOdds}
            data={betslipsList}
            removeReferralBetslip={removeReferralBetslip}
            referralType={referralType}
            customerCountries={customerCountries}
          />
        </div>
      </div>
      {modal && betslips.find(betslip => betslip.betslipId === betslipId) ? (
        <DetailsModal
          onClose={() => setModal(false)}
          changedOdds={changedOdds}
          setBetslipStatus={setBetslipStatus}
          systemReoffer={systemReoffer}
          referralType={referralType}
          betslip={betslipsList.find(
            betslip => betslip.betslipId === betslipId
          )}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  systemTypes: getCustomerData(state).customerDataReducer.betTypes,
  riskCategories: getRiskCategoryData(state).riskCategories,
  referral: getReferralConsoleData(state).referral,
  betslips: getReferralConsoleData(state).betslips,
  changedOdds: getReferralConsoleData(state).changedOdds,
  countActive: getReferralConsoleData(state).countActive,
  referralType: getReferralConsoleData(state).referralType,
  systemReoffer: getReferralConsoleData(state).systemReoffer,
  customerCountries: getReferralConsoleData(state).customerCountries,
  wsFrame: getWsData(state).wsFrame,
  wsConnectionStatus: getWsData(state).wsConnectionStatus,
});

const mapDispatchToProps = {
  getBetTypes: CustomerDataActions.getBetTypes,
  getRiskCategories: RiskCategoryActions.getRiskCategories,
  getReferralForCurrentUser: ReferralConsoleActions.getReferralForCurrentUser,
  createReferral: ReferralConsoleActions.createReferral,
  updateReferral: ReferralConsoleActions.updateReferral,
  getCountOfActive: ReferralConsoleActions.getCountOfActive,
  emptyReferralBetslip: ReferralConsoleActions.emptyReferralBetslip,
  setBetslipStatus: ReferralConsoleActions.setBetslipStatus,
  pingConsole: ReferralConsoleActions.pingConsole,
  removeReferralBetslip: ReferralConsoleActions.removeReferralBetslip,
};

export default connect(mapStateToProps, mapDispatchToProps)(Referral);
