import {
  GET_NAMES,
  GET_TEMPLATES_BY_NAME,
  GET_PLACEHOLDERS,
  GET_USER,
} from '../actions/messageTemplates';

const initialState = {
  names: [],
  templates: [],
  placeholders: [],
  user: '',
};

export const messageTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NAMES.SUCCESS:
      return {
        ...state,
        names: action.payload,
      };

    case GET_TEMPLATES_BY_NAME.SUCCESS:
      return {
        ...state,
        templates: action.payload,
      };

    case GET_PLACEHOLDERS.SUCCESS:
      return {
        ...state,
        placeholders: action.payload,
      };

    case GET_USER.SUCCESS:
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
};
