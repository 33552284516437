import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import * as LiveStreamingActions from '../../redux/actions/LiveStreaming';
import * as SctManagerActions from '../../redux/actions/cms/sctManager';
import Table from '../../components/Table/Table';
import Modal from '../../components/Modal/Modal';
import { useUpdate } from '../../hooks';
import MapIMGStreamModal from './MapIMGStreamModal';

const columns = [
  { name: 'Name', value: 'name', type: 'string' },
  { name: 'Start Time', value: 'start_time', type: 'date' },
  { name: 'Status', value: 'status', type: 'string' },
  { name: 'Sport', value: 'sportName', type: 'string' },
  { name: 'Country', value: 'categoryName', type: 'string' },
  { name: 'Tournament', value: 'tournamentName', type: 'string' },
  { name: 'Actions', value: '', type: 'buttons' }
];

const IMGEventsTable = ({
  from,
  to,
  streamStatus,
  streamingEvents,
  mappedEvents,
  updateEvent,
  getSportById,
  getCategoryById,
  getTournamentById
}) => {

  const [stream, setStream] = useState(null);
  const [detailsStream, setDetailsStream] = useState(null);
  const [detailsSport, setDetailsSport] = useState(null);
  const [detailsCategory, setDetailsCategory] = useState(null);
  const [detailsTournament, setDetailsTournament] = useState(null);

  const filteredStreamingEvents = useMemo(() => streamingEvents.filter(event => {
    const isMapped = mappedEvents.find(ev => ev.streamingProviders.find(provider => provider.streamId === event.id));

    if ((!isMapped && streamStatus === 'mapped') || (isMapped && streamStatus === 'unmapped')) {
      return false;
    }

    if (moment(from).isSame(moment(to))) {
      return true;
    }
    return event;
  }), [streamingEvents, mappedEvents, streamStatus, from, to]);

  useUpdate(async () => {
    if (detailsStream) {
      setDetailsSport(await getSportById(detailsStream.sportId));
      setDetailsCategory(await getCategoryById(detailsStream.categoryId));
      setDetailsTournament(await getTournamentById(detailsStream.tournamentId));
    }
  }, [detailsStream]);

  return (
    <>
      <Table
        columns={columns}
        data={filteredStreamingEvents}
        mappedEvents={mappedEvents}
        updateEvent={updateEvent}
        openMappingModal={setStream}
        openDetailsModal={setDetailsStream}
        tableStyle="sortable table table-hover table-striped efb-table efb-data-table"
        tableType="imgEventsTable"
        pageSize="50"
      />

      {stream ? (
        <MapIMGStreamModal
          stream={stream}
          setStream={setStream}
          updateEvent={updateEvent}
        />
      ) : null}

      {detailsStream && detailsSport && detailsCategory && detailsTournament ? (
        <Modal
          isActive={!!detailsStream}
          headerText="Details for mapped event"
          onClose={() => setDetailsStream(null)}

        >
          <div className="efb-modal__content">
            <div className="d-flex flex-column">
              <h3>Sport: {detailsSport.names.en.name}</h3>
              <h3>Category: {detailsCategory.names.en.name}</h3>
              <h3>Tournament: {detailsTournament.names.en.name}</h3>
              <h3>Event: {detailsStream.names.en.name}</h3>
              <h3>Start Time: {moment.utc(detailsStream.scheduledTime).format('DD/MM/YYYY HH:mm:ss')}</h3>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

const mapDispatchToProps = {
  updateEvent: LiveStreamingActions.updateEvent,
  getSportById: SctManagerActions.getSportById,
  getCategoryById: SctManagerActions.getCategoryById,
  getTournamentById: SctManagerActions.getTournamentById
};

export default connect(
  null,
  mapDispatchToProps
)(IMGEventsTable);
