import React, { memo } from 'react';

export const Hockey = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Hockey ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 139.99 141"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <path
          className={'Stick'}
          d="M135.33,0H130.9C127,0,122.62,3.12,121,7l-39.6,95.44H20.73c-3.85,0-8.27,3.12-9.87,7L.58,134c-1.6,3.85.23,7,4.08,7H71.93l.45,0h4.43c3.85,0,8.27-3.12,9.87-7L139.41,7C141,3.12,139.18,0,135.33,0Z"
        />
        <polygon
          className={'Stripe'}
          points="56.93 140.94 14.2 140.94 30.27 102.38 73 102.38 56.93 140.94"
        />
      </g>
    </svg>
  );
};

export default memo(Hockey);
