import React from 'react';

export const DataWarehouse = (props) => {
    return (
        <span className='efb-icon'>
            <svg className={`efb-icon--${props.size} efb-data-warehouse`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
            <path d="M35.9,34.5V9c0-0.6-0.3-1.1-0.8-1.4L18.6,0.1c-0.4-0.2-0.8-0.2-1.2,0L0.9,7.6C0.4,7.8,0.1,8.4,0.1,9v25.5
                c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V10l15-6.8L33,10v24.5c0,0.8,0.7,1.5,1.5,1.5C35.4,36,36.1,35.3,35.9,34.5z"/>
            <path d="M23,21c0.8,0,1.5-0.7,1.5-1.5V9.6c0-0.8-0.7-1.5-1.5-1.5h-9.9c-0.8,0-1.5,0.7-1.5,1.5v9.9c0,0.8,0.7,1.5,1.5,1.5H23z"/>
            <path d="M15.7,23.1H5.8c-0.8,0-1.5,0.7-1.5,1.5v9.9C4.3,35.3,5,36,5.8,36h9.9c0.8,0,1.5-0.7,1.5-1.5v-9.9
                C17.2,23.8,16.5,23.1,15.7,23.1z M14.2,33H7.3v-6.8h6.8V33z"/>
            <path d="M30.4,23.1h-9.9c-0.8,0-1.5,0.7-1.5,1.5v9.9c0,0.8,0.7,1.5,1.5,1.5h9.9c0.8,0,1.5-0.7,1.5-1.5v-9.9
                C31.9,23.8,31.2,23.1,30.4,23.1z M28.8,33H22v-6.8h6.8V33z"/>
            </svg>
            {props.children}
        </span>
    )
}

export default DataWarehouse;