import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Selectors from './Selectors';
import CompetitorSelector from './CompetitorSelector';
import StartTimes from './StartTimes';
import EventStatus from './EventStatus';
import { Button } from '../../../../../components/Layout';

const EventInfo = ({
	filterType,
	filters,
	sports,
	countries,
	seasons,
	tournaments,
	getCompetitors,
	homeCompetitors,
	awayCompetitors,
	loaded,
	createEvent,
	updateFilter
}) => {
	const handleInputChange = useCallback(
		(e) => {
			const filtersClone = { ...filters };
			if (e.target) {
				const { name, value } = e.target;
				filtersClone[name] = value;
				updateFilter(filtersClone, 'general', name);
			}
		},
		[ filters, updateFilter ]
	);

	return (
		<form className="efb-form-client-search">
			<div className={filterType === 'event_info' ? '' : 'd-none'}>
				<Selectors
					sports={sports}
					countries={countries}
					seasons={seasons}
					tournaments={tournaments}
					filters={filters}
					updateFilter={updateFilter}
				/>
				<hr />
				<CompetitorSelector
					filters={filters}
					getCompetitors={getCompetitors}
					homeCompetitors={homeCompetitors}
					awayCompetitors={awayCompetitors}
					loaded={loaded}
					updateFilter={updateFilter}
				/>
				<hr />
				<StartTimes filters={filters} handleInputChange={handleInputChange} />
				<hr />
				<EventStatus filters={filters} handleInputChange={handleInputChange} />
			</div>
			<hr />
			<Button cssClass="efb-btn__main" onClick={createEvent}>
				Create sport event
			</Button>
		</form>
	);
};

EventInfo.propTypes = {
	updateFilters: PropTypes.func,
	filters: PropTypes.object,
	filterType: PropTypes.string,
	sports: PropTypes.array,
	countries: PropTypes.array,
	seasons: PropTypes.array,
	tournaments: PropTypes.array,
	getCompetitors: PropTypes.func,
	loaded: PropTypes.bool
};

export default EventInfo;
