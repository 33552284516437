import React from 'react';
import PropTypes from 'prop-types';
import { riskValueTransform } from '../SportTemplates/utils';
import Input from '../../../../components/Form/Input';

const RiskControlParameters = ({ onRiskChange, riskParams }) => {
  return (
    <>
      <hr />
      <div className="col-12 mb-3">
        <h3>Risk Control Parameters</h3>
      </div>
      <div className="col-6">
        <div className="form-row mb-3">
          <label
            className="col-4 d-flex align-items-center justify-content-start"
            htmlFor="MBL"
          >
            Max Book Loss
          </label>
          <Input
            id="MBL"
            name="MBL"
            type="number"
            className="form-control col-8"
            onChange={onRiskChange}
            value={riskValueTransform(riskParams, 'MBL')}
          />
        </div>
        <div className="form-row mb-3">
          <label
            htmlFor="MST"
            className="col-4 d-flex align-items-center justify-content-start"
          >
            Max Singles Takeout
          </label>
          <Input
            id="MST"
            name="MST"
            type="number"
            className="form-control col-8"
            onChange={onRiskChange}
            value={riskValueTransform(riskParams, 'MST')}
          />
        </div>
        <div className="form-row mb-3">
          <label
            htmlFor="MAX_TAKEOUT"
            className="col-4 d-flex align-items-center justify-content-start"
          >
            Max Takeout - Rule Book Limit
          </label>
          <Input
            id="MAX_TAKEOUT"
            name="MAX_TAKEOUT"
            type="number"
            className="form-control col-8"
            onChange={onRiskChange}
            value={riskValueTransform(riskParams, 'MAX_TAKEOUT')}
          />
        </div>

        <div className="form-row mb-3">
          <label
            htmlFor="MSF_COUNT"
            className="col-4 d-flex align-items-center justify-content-start"
          >
            Max Syndicate Frequency - Count
          </label>
          <Input
            id="MSF_COUNT"
            name="MSF_COUNT"
            type="number"
            className="form-control col-8"
            onChange={onRiskChange}
            value={riskValueTransform(riskParams, 'MSF_COUNT')}
          />
        </div>

        <div className="form-row mb-3">
          <label
            htmlFor="MSF_DURATION"
            className="col-4 d-flex align-items-center justify-content-start"
          >
            Max Syndicate Frequency - Duration (in minutes)
          </label>
          <Input
            id="MSF_DURATION"
            name="MSF_DURATION"
            type="number"
            className="form-control col-8"
            onChange={onRiskChange}
            value={riskValueTransform(riskParams, 'MSF_DURATION').slice(0, -1).slice(2)}
          />
        </div>
      </div>
      {/* TODO: Needed when those are implemented in backend */}
      {/* <div className="col-6">
        <div className="form-row mb-3">
          <label
            htmlFor="PRICE_CLASS_LOW"
            className="col-4 d-flex align-items-center justify-content-start"
          >
            Price Class Value - Low
          </label>
          <Input
            id="PRICE_CLASS_LOW"
            name="PRICE_CLASS_LOW"
            type="number"
            className="form-control col-8"
            onChange={onRiskChange}
            value={riskValueTransform(riskParams, 'PRICE_CLASS_LOW')}
          />
        </div>
        <div className="form-row mb-3">
          <label
            htmlFor="PRICE_CLASS_MEDIUM"
            className="col-4 d-flex align-items-center justify-content-start"
          >
            Price Class Value - Meduim
          </label>
          <Input
            id="PRICE_CLASS_MEDIUM"
            name="PRICE_CLASS_MEDIUM"
            type="number"
            className="form-control col-8"
            onChange={onRiskChange}
            value={riskValueTransform(riskParams, 'PRICE_CLASS_MEDIUM')}
          />
        </div>
        <div className="form-row mb-3">
          <label
            htmlFor="PRICE_CLASS_HIGH"
            className="col-4 d-flex align-items-center justify-content-start"
          >
            Price Class value - High
          </label>
          <Input
            id="PRICE_CLASS_HIGH"
            name="PRICE_CLASS_HIGH"
            type="number"
            className="form-control col-8"
            onChange={onRiskChange}
            value={riskValueTransform(riskParams, 'PRICE_CLASS_HIGH')}
          />
        </div>
        <div className="form-row mb-3">
          <label
            htmlFor="GRACE_PERIOD"
            className="col-4 d-flex align-items-center justify-content-start"
          >
            Grace period (in seconds)
          </label>
          <Input
            id="GRACE_PERIOD"
            name="GRACE_PERIOD"
            type="number"
            className="form-control col-8"
            onChange={onRiskChange}
            value={riskValueTransform(riskParams, 'GRACE_PERIOD')}
          />
        </div>
      </div> */}
    </>
  );
};

RiskControlParameters.propTypes = {
  onRiskChange: PropTypes.func.isRequired,
  riskParams: PropTypes.array.isRequired
};

export default RiskControlParameters;
