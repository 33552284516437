import React, { useState, useCallback } from 'react';
import Button from '../../../../../components/Layout/Button';
import InputField from '../../../../../components/Form/InputField';
import PropTypes from 'prop-types';
import TextEditor from '../TextEditor';
import { prepareHtml } from '../../StaticContentUtils';
import { rules } from '../../StaticContentObj';
import { useUpdate } from '../../../../../hooks';

const Rules = ({
  pageObj,
  index,
  updateObj,
  currentLang,
  images,
  uploadImage,
}) => {
  const [rulesList, setRulesList] = useState([{ rule: rules, key: 0 }]);
  const [staticPageObj, setStaticPageObj] = useState({});

  useUpdate(() => {
    setStaticPageObj(pageObj);
  }, [pageObj]);

  const handleChange = useCallback(
    (e, i) => {
      const { name, value } = e.target;
      const pageObjClone = { ...staticPageObj };
      const rule =
        pageObjClone.subpages[index].content[0][currentLang.value].rules[i];
      rule[name === 'htmlBody' ? 'description' : name] =
        name === 'htmlBody' ? prepareHtml(value, index) : (rule[name] = value);
      updateObj(pageObjClone);
      const rulesClone = [...rulesList];
      if (name === 'htmlBody') {
        rule.description = prepareHtml(value, index);
      } else {
        rule[name] = value;
      }
      setStaticPageObj(pageObjClone);
      setRulesList(rulesClone);
    },
    [currentLang.value, index, rulesList, staticPageObj, updateObj]
  );

  const addRule = useCallback(
    e => {
      e.preventDefault();
      const pageObjClone = { ...staticPageObj };
      const rulesClone = [...rulesList];
      let counter = rulesClone.length;
      rulesClone.push({ rule: rules, key: counter++ });
      pageObjClone.subpages[index].content[0][currentLang.value].rules.push({
        name: '',
        description: '',
        title: '',
      });
      updateObj(pageObjClone);
      setRulesList(rulesClone);
      setStaticPageObj(pageObjClone);
    },
    [currentLang.value, index, rulesList, staticPageObj, updateObj]
  );

  const removeRule = useCallback(
    ruleIndex => {
      const pageObjClone = { ...staticPageObj };
      pageObjClone.subpages[index].content[0][currentLang.value].rules.splice(
        ruleIndex,
        1
      );
      const rulesClone = [...rulesList];
      rulesClone.splice(ruleIndex, 1);
      updateObj(pageObjClone);
      setRulesList(rulesClone);
      setStaticPageObj(pageObjClone);
    },
    [currentLang.value, index, rulesList, staticPageObj, updateObj]
  );

  const mapRules = useCallback(
    (rule, index) => {
      return (
        <div key={rule.key}>
          <div className="efb-form">
            <div className="form-row">
              <div className="form-label-group col-md">
                <InputField
                  type="text"
                  name="name"
                  placeholder="Rule Name"
                  value={rule.name}
                  onChange={e => handleChange(e, index)}
                  label="Rule Name"
                />
              </div>
              <div className="form-label-group col-md">
                <InputField
                  type="text"
                  name="title"
                  placeholder="Rule Title"
                  value={rule.title}
                  onChange={e => handleChange(e, index)}
                  label="Rule Title"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-label-group col-md">
                <TextEditor
                  htmlBody={e => handleChange(e, index)}
                  images={images}
                  uploadImage={uploadImage}
                />
              </div>
            </div>
            <Button cssClass="efb-btn__main" onClick={() => removeRule(index)}>
              <i className="fa fa-trash"></i> Remove rule {index + 1}
            </Button>
          </div>
          <hr />
        </div>
      );
    },
    [handleChange, images, removeRule, uploadImage]
  );

  return (
    <>
      {rulesList.map(mapRules)}
      <div className="form-group d-flex flex-row justify-content-end">
        <Button cssClass="efb-btn__main float-right" onClick={addRule}>
          <i className="fa fa-plus"></i>
          Add rule
        </Button>
      </div>
      <hr />
    </>
  );
};

Rules.propTypes = {
  pageObj: PropTypes.object,
  subpage: PropTypes.object,
  updateObj: PropTypes.func,
};

export default Rules;
