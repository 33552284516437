import React, { useMemo } from 'react';
import moment from 'moment';

import Modal from '../../NewModal/Modal';
import Table from '../../NewTable/Table';
import { useFetch } from '../../../hooks';

const ActionHistoryModal = ({ onClose, market }) => {
  const { data: actionHistory } = useFetch(`/market/market/history/${market.id}`);

  const columns = useMemo(() => [
    { name: 'Time', active: false, display: action => moment(action.time).format('DD/MM/YYYY HH:mm:ss') },
    { name: 'Actions', active: false, display: action => action.action },
    { name: 'Performed by', active: false, display: action => `[${action.performedBy.toUpperCase()}]` }
  ], []);

  if (!actionHistory) {
    return null;
  }

  return (
    <Modal
      isActive={true}
      onClose={onClose}
    >
      <div className="p-3">
        <p className="mr-5">Market Actions History: <strong>{market.names.en.name}</strong></p>
        <Table
          columns={columns}
          data={actionHistory.sort((a, b) => moment(b.time).diff(moment(a.time)))}
        />
      </div>
    </Modal>
  );
};

export default ActionHistoryModal;