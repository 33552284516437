import React, { useCallback } from 'react';
import moment from 'moment';

const BetradarEventsTableRow = ({ tableData, mappedEvents }) => {
  const renderRow = useCallback(
    ev => {
      const isMapped = mappedEvents.find(event =>
        event.streamingProviders.find(
          provider => provider.streamId === ev.contents[0].streams[0].id
        )
      );

      return (
        <tr style={{ backgroundColor: isMapped ? 'green' : '' }} key={ev.id}>
          <td>
            {ev.competitors[0] ? ev.competitors[0].name : ''} -{' '}
            {ev.competitors[1] ? ev.competitors[1].name : ''}
          </td>
          <td>{moment(ev.start_time).format('DD/MM/YYYY HH:mm:ss')}</td>
          <td>{ev.first_level_category.name}</td>
          <td>{ev.second_level_category.name}</td>
          <td>{ev.third_level_category.name}</td>
        </tr>
      );
    },
    [mappedEvents]
  );

  return tableData.map(renderRow);
};

export default React.memo(BetradarEventsTableRow);
