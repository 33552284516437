import React, { Fragment, useState } from 'react';
import Table from '../../../components/Table/Table';
import Button from '../../../components/Layout/Button';
import useUpdate from '../../../hooks/useUpdate';
import PropTypes from 'prop-types';

const EventMarketOutcomes = React.memo(
  ({ setActiveTab, viewOutcomes, marketOutcomes }) => {
    const columns = [
      { name: 'No.', value: 'no', type: 'string' },
      { name: 'Outcome name', value: 'name', type: 'string' },
      { name: 'Results', value: 'results', type: 'string' },
    ];

    const [outcomes, setOutcomes] = useState([]);

    useUpdate(() => {
      const outcomesClone = marketOutcomes.reduce(
        (acc, val) => [...acc, val],
        []
      );
      setOutcomes(outcomesClone);
    });

    return (
      <Fragment>
        <h3 className="efb-h3">
          Event Market outcomes{' '}
          <Button
            cssClass="efb-btn__main"
            onClick={() => setActiveTab('eventMarkets')}
          >
            <i className="fa fa-arrow-left" /> Back
          </Button>{' '}
        </h3>
        <Table
          columns={columns}
          data={outcomes}
          viewOutcomes={viewOutcomes}
          tableType="settlementEventMarketOutcomes"
          tableStyle="sortable table table-hover table-striped efb-table"
        />
      </Fragment>
    );
  }
);

EventMarketOutcomes.propTypes = {
  backToMarketsList: PropTypes.func,
  viewOutcomes: PropTypes.func,
  marketOutcomes: PropTypes.array,
};

export default EventMarketOutcomes;
