import {
  ADD_CUSTOMER_FILTERS,
  CUSTOMER_AW_STATUS,
  EMPTY_CUSTOMER_FILTERS,
  FILTERED_CUSTOMERS,
  GET_CUSTOMER_PREFERENCES,
  RESET_CUSTOMER_MANAGER,
  SET_CUSTOMER_ACCOUNTS,
  SET_CUSTOMER_BETSLIP,
  SET_CUSTOMER_BETTING_PERFORMANCE,
  SET_CUSTOMER_MANAGER,
  SET_CUSTOMER_TRANSACTIONS,
} from '../../actions/customer/customerManager';

const initialState = {
  customers: {
    content: [],
    totalElements: 0,
    totalPages: 0,
  },
  customerManager: {},
  customerPreferences: {},
  customerAccounts: [],
  customerTransactions: [],
  customerBettingPerformance: {},
  customerBetslip: [],
  filteredCustomers: {
    totalElements: 0,
    totalPages: 0,
    content: [],
  },
  settlementSuccess: false,
  error: {},
  eventSettlement: {},
  customerFilters: {},
  customerAwStatus: {},
};

//use switch it's more readable
export const customerManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTERED_CUSTOMERS.SUCCESS:
      return {
        ...state,
        filteredCustomers: {
          content: [
            ...state.filteredCustomers.content,
            ...action.payload.content,
          ],
          number: action.payload.number,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
        },
      };
    case FILTERED_CUSTOMERS.EMPTY:
      return {
        ...state,
        filteredCustomers: {
          content: [],
          totalElements: 0,
          totalPages: 0,
        },
      };
    case SET_CUSTOMER_MANAGER.SUCCESS:
      return {
        ...state,
        customerManager: action.payload,
      };
    case SET_CUSTOMER_MANAGER.EMPTY:
      return {
        ...state,
        customerManager: {},
      };

    case GET_CUSTOMER_PREFERENCES.SUCCESS:
      return {
        ...state,
        customerPreferences: action.payload,
      };
    case SET_CUSTOMER_ACCOUNTS.SUCCESS:
      return { ...state, customerAccounts: action.payload };
    case SET_CUSTOMER_ACCOUNTS.EMPTY:
      return { ...state, customerAccounts: [] };
    case SET_CUSTOMER_TRANSACTIONS.SUCCESS:
      return { ...state, customerTransactions: action.payload };
    case SET_CUSTOMER_TRANSACTIONS.EMPTY:
      return { ...state, customerTransactions: [] };
    case SET_CUSTOMER_BETTING_PERFORMANCE.SUCCESS:
      return { ...state, customerBettingPerformance: action.payload };
    case SET_CUSTOMER_BETTING_PERFORMANCE.EMPTY:
      return { ...state, customerBettingPerformance: {} };
    case SET_CUSTOMER_BETSLIP.SUCCESS:
      return { ...state, customerBetslip: action.payload };
    case SET_CUSTOMER_BETSLIP.EMPTY:
      return { ...state, customerBetslip: [] };
    case RESET_CUSTOMER_MANAGER:
      return {
        ...state,
        customerManager: {},
        customerAccounts: [],
        customerTransactions: [],
        customerBettingPerformance: {},
        customerBetslip: [],
        settlementSuccess: false,
        error: {},
        eventSettlement: {},
      };
    case ADD_CUSTOMER_FILTERS:
      return {
        ...state,
        customerFilters: action.payload,
      };
    case EMPTY_CUSTOMER_FILTERS:
      return {
        ...state,
        customerFilters: {},
      };
    case CUSTOMER_AW_STATUS.SUCCESS:
      return {
        ...state,
        customerAwStatus: action.payload,
      };
    default:
      return state;
  }
};
