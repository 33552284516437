import React from 'react';

const IconFolderSuspended = ({
  className,
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      className={className}
      viewBox="0 0 17 14"
      {...otherProps}
    >
      <defs>
        <style>{'.a{fill:#cf8808;}.b{fill:#ffbe4a;}.c{fill:#fff;stroke:#c70000;}.d{stroke:none;}.e{fill:none;}'}</style>
      </defs>
      <rect
        className="a"
        width="8"
        height="4"
        rx="1"
      />
      <rect
        className="b"
        width="17"
        height="12"
        rx="1"
        transform="translate(0 2)"
      />
      <g
        className="c"
        transform="translate(4.672 5.457) rotate(-45)"
      >
        <rect
          className="d"
          width="1.389"
          height="9.718"
          rx="0.694"
        />
        <rect
          className="e"
          x="0.5"
          y="0.5"
          width="0.389"
          height="8.718"
          rx="0.194"
        />
      </g>
      <g
        className="c"
        transform="translate(5.653 12.328) rotate(-135)"
      >
        <rect
          className="d"
          width="1.388"
          height="9.718"
          rx="0.694"
        />
        <rect
          className="e"
          x="0.5"
          y="0.5"
          width="0.388"
          height="8.718"
          rx="0.194"
        />
      </g>
    </svg>
  );
};

export default IconFolderSuspended;