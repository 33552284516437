import React from 'react';
import Spinner from '../Spinner/Spinner';
import PropTypes from 'prop-types';

export const FullScreenLoader = ({ isActive }) => {
  return (
    <div className={isActive ? 'efb-full-screen-loader' : 'd-none'}>
      <Spinner size="100" active="true" />
    </div>
  );
};

FullScreenLoader.propTypes = {
  isActive: PropTypes.bool,
};

export default FullScreenLoader;
