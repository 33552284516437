import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import Pagination from '../../../../components/Table/Pagination';
import Arrow from '../../../../components/Icons/Arrow';
import Edit from '../../../../components/Icons/Edit';
import Button from '../../../../components/Layout/Button';
import Checkbox from '../../../../components/Form/Checkbox';
import AppViewPrivilegesUsers from './AppViewPrivilegesUsers';

class UsersTable extends PureComponent {
  state = {
    selectedUser: null,
    selectedSection: 'general',
    currentPage: 1
  };

  handleEditClick = clickedUser => {
    this.props.setUserForEdit(clickedUser);
    this.props.toggleUsersModal();
  };

  toggleTableRow = username => {
    this.setState({
      selectedUser: this.state.selectedUser ? null : username
    });
  };

  generateSectionContent = user => {
    let sectionContent;
    switch (this.state.selectedSection) {
      case 'general':
        sectionContent = (
          <section className="efb-data-table__user">
            <ul className="efb-data-table__user-data">
              <li>
                ID: <strong>{user.id}</strong>
              </li>
              <li>
                Username: <strong>{user.username}</strong>
              </li>
              <li>
                Is Active: <strong>{user.active ? 'Yes' : 'No'} </strong>
              </li>
              <li>
                Description: <strong>{user.description}</strong>
              </li>
            </ul>
          </section>
        );
        break;
      case 'permissions':
        sectionContent = (
          <div
            style={{
              width: '100%',
              padding: '5px 5px 0 5px',
              fontSize: '14px'
            }}
          >
            <AppViewPrivilegesUsers userRoles={user.roles} readOnly={true} />
          </div>
        );
        break;
      default:
        sectionContent = null;
        break;
    }

    return sectionContent;
  };

  handlePermissionsClick = clickedUser => {
    this.props.setUserForEdit(clickedUser);
    this.props.toggleRolePermissionsModal();
  };

  handleContextMenuClick = event => {
    console.log(event);
  };

  render() {
    const { users } = this.props;
    return (
      <Fragment>
        <ul className="efb-data-table">
          <li className="efb-data-table__header">
            <section className="efb-data-table__section">
              <span>UN:</span>
              <span>UN (BD):</span>
              <span>Domain:</span>
              <span>Is Active:</span>
              <span>Description:</span>
              <span>
                <span className="efb-f-light">Records Found:</span>{' '}
                {users.length}
              </span>
            </section>
          </li>
          {users.map((user, i) => (
            <li
              key={i}
              className={`efb-data-table__row ${
                this.state.selectedUser === user.username ? 'selected' : ''
              }`}
              onClick={() => this.toggleTableRow(user.username)}
              onContextMenu={this.handleContextMenuClick}
            >
              <section className="efb-data-table__section">
                <span>{user.username}</span>
                <span>{user.bd}</span>
                <span>{user.domain}</span>
                <Checkbox
                  type="userActivity"
                  readOnly={true}
                  isActive={user.active}
                  name={`isActive-${i}`}
                />
                <span>{user.description}</span>
                <Arrow size="xs" />
              </section>
              {this.state.selectedUser === user.username ? (
                <section
                  onClick={event => event.stopPropagation()}
                  className="efb-data-table__desc"
                >
                  <aside className="efb-data-table__controls">
                    <Button
                      onClick={() =>
                        this.setState({ selectedSection: 'general' })
                      }
                      cssClass={
                        this.state.selectedSection === 'general'
                          ? 'efb-btn__link selected'
                          : 'efb-btn__link'
                      }
                    >
                      General
                    </Button>
                    <Button
                      onClick={e => this.handlePermissionsClick(user, e)}
                      cssClass={
                        this.state.selectedSection === 'permissions'
                          ? 'efb-btn__link selected'
                          : 'efb-btn__link'
                      }
                    >
                      Permissions
                    </Button>
                  </aside>
                  {this.generateSectionContent(user)}

                  <aside className="efb-data-table__controls">
                    <Button
                      onClick={e => this.handleEditClick(user, e)}
                      cssClass="efb-btn__link"
                    >
                      <Edit size="sm" />
                    </Button>
                  </aside>
                </section>
              ) : null}
            </li>
          ))}
        </ul>
        <Pagination
          onPageChange={page => {
            this.setState({
              currentPage: page
            });
            this.props.getUsersForPage(page);
          }}
          itemsCount={this.props.totalElements}
          sizing={false}
          pageSize={100}
          currentPage={this.state.currentPage}
        />
      </Fragment>
    );
  }
}

UsersTable.propTypes = {
  setUserForEdit: PropTypes.func,
  toggleUserModal: PropTypes.func,
  users: PropTypes.array,
  toggleRolePermissionsModal: PropTypes.func
};

export default UsersTable;
