import React from 'react';

const IconDesktop = ({
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 22.228 13.65" {...otherProps}
    >
      <path
        d="M4957.781-1740.291v-.329q0-4.558,0-9.116,0-1.08,0-2.161a.574.574,0,0,1,.646-.65q5.906,0,11.811,0c.652,0,1.3.023,1.955.022,1.147,0,2.295-.016,3.443-.023a.6.6,0,0,1,.684.68v11.574c.4,0,.782,0,1.163,0a1.533,1.533,0,0,1,.386.045.384.384,0,0,1,.295.418c0,.137,0,.275,0,.412a.447.447,0,0,1-.481.513c-.2.01-.4,0-.6,0h-16.209q-2.163,0-4.324,0a1.07,1.07,0,0,1-.344-.049.365.365,0,0,1-.27-.391c.008-.151,0-.3,0-.454,0-.305.109-.453.419-.476.39-.029.782-.015,1.174-.02Zm.164,0h18.21v-4.229q0-3.661,0-7.322c0-.392-.144-.528-.537-.53-1.065-.006-2.131-.019-3.2-.02-.8,0-1.591.019-2.387.02q-5.784,0-11.566,0c-.381,0-.521.141-.522.522q0,1.123,0,2.245,0,4.466,0,8.932Zm5.742.172h-7.056a1.7,1.7,0,0,0-.288.018.25.25,0,0,0-.24.279c.007.151.008.3,0,.454a.245.245,0,0,0,.217.277,1.278,1.278,0,0,0,.265.023h20.57c.172,0,.344.007.515,0,.237-.014.316-.1.319-.334,0-.1,0-.192,0-.288-.008-.357-.073-.419-.437-.427h-7.142c-.05.367-.091.4-.452.4H4964.1C4963.783-1739.718,4963.715-1739.781,4963.688-1740.119Zm3.37,0h-2.8a1.174,1.174,0,0,0-.266.01c-.045.01-.078.076-.116.116a.467.467,0,0,0,.114.1.379.379,0,0,0,.143.007h5.836a.335.335,0,0,0,.142-.007.223.223,0,0,0,.106-.12c.005-.023-.061-.083-.1-.1a.616.616,0,0,0-.184-.008Z"
        transform="translate(-4955.936 1752.546)"
      />
      <path
        d="M5036.675-1734.329v-11.56c0-.381.141-.522.522-.522q5.783,0,11.566,0c.8,0,1.591-.021,2.387-.02,1.065,0,2.131.014,3.2.02.394,0,.537.139.537.53q0,3.661,0,7.322v4.229Zm6.653-10.719h-5.28c-.333,0-.4.061-.4.4v8.909c0,.391.05.439.439.439q4.021,0,8.043-.006,2.754,0,5.506.006c.335,0,.67-.012,1.005-.013s.673.012,1.01.006a.234.234,0,0,0,.244-.27c0-.158,0-.316,0-.474q0-4.321,0-8.642c0-.287-.065-.35-.354-.35l-2.941,0-1.994,0Zm2.446-.218a.464.464,0,0,0,.474-.462.467.467,0,0,0-.474-.464.464.464,0,0,0-.459.455A.454.454,0,0,0,5045.774-1745.266Z"
        transform="translate(-5034.666 1746.583)"
        opacity="0.36"
      />
      <path
        d="M4970.271-1253.3c.027.339.1.4.414.4h5.856c.361,0,.4-.035.453-.4h7.142c.365.008.429.07.438.427,0,.1,0,.192,0,.288,0,.231-.082.32-.318.334-.171.01-.343,0-.516,0h-16.267q-2.151,0-4.3,0a1.27,1.27,0,0,1-.265-.023.245.245,0,0,1-.217-.277c.007-.151.006-.3,0-.453a.25.25,0,0,1,.24-.279,1.714,1.714,0,0,1,.288-.018h7.056Z"
        transform="translate(-4962.519 1265.731)"
        opacity="0.36"
      />
      <path
        d="M5277.918-1253.366h2.867a.618.618,0,0,1,.184.008c.043.014.109.074.1.1a.219.219,0,0,1-.106.12.335.335,0,0,1-.143.007h-5.836a.383.383,0,0,1-.143-.007.473.473,0,0,1-.113-.1c.038-.041.071-.105.116-.116a1.2,1.2,0,0,1,.266-.01Z"
        transform="translate(-5266.796 1265.793)"
        opacity="0.36"
      />
      <path
        d="M5081.58-1690.923h7.276l2.94,0c.29,0,.355.063.355.35q0,4.321,0,8.642c0,.158,0,.316,0,.474a.234.234,0,0,1-.244.27c-.337.005-.673-.007-1.01-.006s-.67.013-1,.013c-1.836,0-3.671-.006-5.507-.006q-4.022,0-8.043.006c-.389,0-.439-.048-.439-.439v-8.909c0-.336.063-.4.4-.4Zm-5.489.175c-.009.105-.021.187-.022.268,0,.261,0,.523,0,.784q0,4.043,0,8.085c0,.16.01.265.229.265q7.742-.01,15.484,0c.17,0,.207-.056.205-.212-.005-.873,0-1.746,0-2.62q0-3.177,0-6.353c0-.132,0-.219-.179-.219-.885,0-1.77-.019-2.655-.019-.754,0-1.509.02-2.263.021q-4.838,0-9.675,0Z"
        transform="translate(-5072.917 1692.458)"
      />
      <path
        d="M5384.209-1735.922a.455.455,0,0,1-.46-.471.464.464,0,0,1,.459-.455.468.468,0,0,1,.475.464A.463.463,0,0,1,5384.209-1735.922Zm.008-.164a.3.3,0,0,0,.292-.307.294.294,0,0,0-.29-.283.3.3,0,0,0-.3.294A.3.3,0,0,0,5384.217-1736.086Z"
        transform="translate(-5373.101 1737.239)"
      />
      <path
        d="M5390.676-1729.359a.3.3,0,0,1-.3-.3.3.3,0,0,1,.3-.294.294.294,0,0,1,.291.283A.3.3,0,0,1,5390.676-1729.359Z"
        transform="translate(-5379.56 1730.511)"
        opacity="0.36"
      />
    </svg>
  );
};

export default IconDesktop;