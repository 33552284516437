import React, { useState } from 'react';
import { useMount } from '../../../hooks';

const BetScrollerMenu = ({
  filterMenu,
  menuPosition,
  setMenuPosition,
  onChangeName,
  betScroller,
  ownId,
}) => {
  const [isEditable, setIsEditable] = useState(false);

  useMount(() => {
    onChangeName({
      ...betScroller,
      id: betScroller ? betScroller.id : '',
    });
  });

  const handleChange = e => {
    const { value, name } = e.target;
    onChangeName({
      ...betScroller,
      [name]: value,
    });
  };

  return (
    <div className="col-md-4 filter-menu p-0">
      <div
        className={`py-3 ml-4 ${
          betScroller.createdUserId !== ownId && betScroller.id
            ? 'not-allowed'
            : ''
        }`}
        onClick={() => setIsEditable(!isEditable)}
      >
        {isEditable ? (
          <input
            autoFocus
            name="name"
            className="name-input"
            type="text"
            onChange={handleChange}
            value={betScroller.name}
          />
        ) : (
          betScroller.name
        )}
        <i className="fas fa-pen ml-2 pointer" />
      </div>
      <div className="m-1">
        {filterMenu.map((menu, index) => {
          return (
            <div
              key={index}
              className={`my-2 pointer ${
                index === menuPosition ? 'active-filter' : 'default-filter'
              }`}
              onClick={() => setMenuPosition(index)}
            >
              <b className="ml-4">{menu}</b>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BetScrollerMenu;
