import React from 'react';

export const Add = (props) => {
    return (
        <span className='efb-icon'>
            <svg className={`efb-icon--${props.size} ${props.cssClass} efb-add`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
                <path d="M34,15.5H20.5V2c0-0.6-0.8-2-2.5-2s-2.5,1.3-2.5,2v13.5H2c-0.6,0-2,0.8-2,2.5c0,1.7,1.3,2.5,2,2.5h13.5V34
			        c0,0.6,0.8,2,2.5,2c1.7,0,2.5-1.3,2.5-2V20.5H34c0.6,0,2-0.8,2-2.5S34.7,15.5,34,15.5z"/>
            </svg>
            {props.children}
        </span>
    )
}

export default Add;