import React from 'react';
import { Button } from '../../../components/Layout';

const ManageTournamentButton = ({
  tournamentId,
  topCoupons,
  removeTopCoupon,
  addTopCoupon
}) => {
  if (!tournamentId) return null;

  return (
    <>
      {topCoupons.find(coupon => coupon.highlightId === tournamentId) ? (
        <Button
          onClick={removeTopCoupon}
          cssClass="btn btn-danger d-block mb-3 ml-auto"
        >
          Remove selected tournament from Top
        </Button>
      ) : (
        <Button
          onClick={addTopCoupon}
          cssClass="btn btn-secondary d-block mb-3 ml-auto"
        >
          Add selected tournament to Top
        </Button>
      )}
    </>
  );
};

export default ManageTournamentButton;
