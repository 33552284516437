import { actionCreator, createRequestTypes } from '..';
import axiosInstance from '../axios-instance';
import { addNotificationMessage } from '../ui';

export const GET_REFERRAL_FOR_USER = createRequestTypes(
  'GET_REFERRAL_FOR_USER'
);
export const CREATE_REFERRAL = createRequestTypes('CREATE_REFERRAL');
export const ADD_REFERRAL_BETSLIP = createRequestTypes('ADD_REFERRAL_BETSLIP');
export const REMOVE_REFERRAL_BETSLIP = createRequestTypes(
  'REMOVE_REFERRAL_BETSLIP'
);
export const REMOVE_SYSTEM_BETSLIP = createRequestTypes(
  'REMOVE_SYSTEM_BETSLIP'
);
export const EMPTY_REFERRAL_BETSLIP = createRequestTypes(
  'EMPTY_REFERRAL_BETSLIP'
);
export const REOFFER_REFERRAL_BET = createRequestTypes('REOFFER_REFERRAL_BET');
export const SET_BET_STATUS = createRequestTypes('SET_BET_STATUS');
export const SET_BETSLIP_STATUS = createRequestTypes('SET_BETSLIP_STATUS');
export const PRICE_CHANGE = createRequestTypes('PRICE_CHANGE');
export const RECEIVE_ODDS_CHANGE = createRequestTypes('RECEIVE_ODDS_CHANGE');
export const GET_ACTIVE_OPERATORS = createRequestTypes('GET_ACTIVE_OPERATORS');
export const GET_REFERRAL_TYPE = createRequestTypes('GET_REFERRAL_TYPE');
export const GET_SYSTEM_REOFFER = createRequestTypes('GET_SYSTEM_REOFFER');
export const GET_CUSTOMER_COUNTRY = createRequestTypes('GET_CUSTOMER_COUNTRY');

export const addReferralBetslip = betslip => dispatch => {
  dispatch(actionCreator.success(ADD_REFERRAL_BETSLIP, betslip));
  dispatch(getCustomerCountry(betslip.customerId));
};

export const getReferralType = referralType => async dispatch => {
  dispatch(actionCreator.success(GET_REFERRAL_TYPE, referralType));
};

export const removeReferralBetslip = betslipId => dispatch => {
  dispatch(actionCreator.success(REMOVE_REFERRAL_BETSLIP, betslipId));
};

export const removeSystemBetslip = payload => dispatch => {
  dispatch(actionCreator.success(REMOVE_SYSTEM_BETSLIP, payload));
};

export const emptyReferralBetslip = () => dispatch => {
  dispatch(actionCreator.success(EMPTY_REFERRAL_BETSLIP));
};

export const handleOddsChange = change => dispatch => {
  dispatch(actionCreator.success(RECEIVE_ODDS_CHANGE, change));
};

export const getSystemReofferInfo = reofferInfo => dispatch => {
  dispatch(actionCreator.success(GET_SYSTEM_REOFFER, reofferInfo));
};

export const pingConsole = referralId => async () => {
  await axiosInstance.get(
    `riskmgnt/view/admin/referralConsole/ping/${referralId}`
  );
};

export const priceChange = payloads => async dispatch => {
  dispatch(actionCreator.request(PRICE_CHANGE));
  try {
    await Promise.all(
      payloads.map(payload =>
        axiosInstance.post('market/market/referral/priceChange', payload)
      )
    );
    dispatch(actionCreator.success(PRICE_CHANGE));
    dispatch(
      addNotificationMessage(
        'Successfully changed global odds!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(actionCreator.failure(PRICE_CHANGE, error));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const reofferBet = (betslipId, payload) => async dispatch => {
  dispatch(actionCreator.request(REOFFER_REFERRAL_BET));
  try {
    await axiosInstance.post(`/betslip/admin/${betslipId}/reoffer`, payload);
    dispatch(actionCreator.success(REOFFER_REFERRAL_BET));
  } catch (error) {
    dispatch(actionCreator.failure(REOFFER_REFERRAL_BET, error));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const setBetslipStatus = (betslipId, status) => async dispatch => {
  dispatch(actionCreator.request(SET_BETSLIP_STATUS));
  try {
    await axiosInstance.post(`/betslip/admin/${betslipId}/betslipStatus`, {
      status,
    });
    dispatch(actionCreator.success(SET_BETSLIP_STATUS));
    dispatch(removeReferralBetslip(betslipId));
  } catch (error) {
    dispatch(actionCreator.failure(SET_BETSLIP_STATUS, error));
    if (error.errors && typeof error.errors === 'object') {
      Object.keys(error.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(error.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const setBetStatus = (betslipId, system, reject) => async dispatch => {
  dispatch(actionCreator.request(SET_BET_STATUS));
  try {
    const payload = {
      systemType: system.type,
      status: reject ? 'CANCEL_AFTER_REFERRAL' : 'ACCEPTED_AFTER_REFERRAL',
      betId: system.type === 'SINGLE' ? system.bets[0].betId : undefined,
    };
    await axiosInstance.post(`/betslip/admin/${betslipId}/betStatus`, payload);
    dispatch(actionCreator.success(SET_BET_STATUS));
    dispatch(removeReferralBetslip(betslipId));
    dispatch(
      addNotificationMessage(
        'You have successfully set bet status',
        'success',
        'Success'
      )
    );
  } catch (error) {
    dispatch(actionCreator.failure(SET_BET_STATUS, error));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const addWsBinding = async (referralId, session) => {
  try {
    await axiosInstance.post('/websocket-gateway/routings', {
      session: `user.${session}`,
      prefix: 'referral_console',
      keys: [referralId, 'common'],
    });
    await axiosInstance.post(
      'riskmgnt/view/admin/referralConsole/subscriberEvent',
      {
        type: 'SUBSCRIBE',
        prefix: 'referral_console',
        keys: [referralId],
      }
    );
  } catch (error) {
    console.log('ws binding error:', error);
    throw error;
  }
};

export const removeWsBinding = async (referralId, session) => {
  try {
    await axiosInstance.post(
      'riskmgnt/view/admin/referralConsole/subscriberEvent',
      {
        type: 'UNSUBSCRIBE',
        prefix: 'referral_console',
        keys: [referralId],
      }
    );
    await axiosInstance.delete('/websocket-gateway/routings', {
      data: {
        session: `user.${session}`,
        prefix: 'referral_console',
        keys: [referralId, 'common'],
      },
    });
  } catch (error) {
    console.log('ws unbinding error:', error);
  }
};

export const getReferralForCurrentUser = () => async dispatch => {
  dispatch(actionCreator.request(GET_REFERRAL_FOR_USER));
  try {
    const res = await axiosInstance.get(
      'riskmgnt/view/admin/referralConsole/findByCurrentUser'
    );

    dispatch(actionCreator.success(GET_REFERRAL_FOR_USER, res));
  } catch (error) {
    if (error.status === 404) {
      dispatch(actionCreator.success(GET_REFERRAL_FOR_USER, {}));
    } else {
      dispatch(actionCreator.failure(GET_REFERRAL_FOR_USER, error));
      dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    }
  }
};

export const getCountOfActive = () => async dispatch => {
  dispatch(actionCreator.request(GET_ACTIVE_OPERATORS));
  try {
    const res = await axiosInstance.get(
      'riskmgnt/view/admin/referralConsole/countActive'
    );
    dispatch(actionCreator.success(GET_ACTIVE_OPERATORS, res));
  } catch (error) {
    if (error.status === 404) {
      dispatch(actionCreator.success(GET_ACTIVE_OPERATORS, {}));
    } else {
      dispatch(actionCreator.failure(GET_ACTIVE_OPERATORS, error));
      dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    }
  }
};

export const createReferral = data => async dispatch => {
  dispatch(actionCreator.request(CREATE_REFERRAL));
  try {
    const res = await axiosInstance.post(
      'riskmgnt/view/admin/referralConsole',
      data
    );
    dispatch(actionCreator.success(CREATE_REFERRAL, res));
    dispatch(getReferralForCurrentUser());
    dispatch(getCountOfActive());
    dispatch(
      addNotificationMessage(
        'Successfully created referral!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(actionCreator.failure(CREATE_REFERRAL, error));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const updateReferral = (data, id) => async dispatch => {
  dispatch(actionCreator.request(CREATE_REFERRAL));
  try {
    const res = await axiosInstance.put(
      `riskmgnt/view/admin/referralConsole/${id}`,
      data
    );
    dispatch(actionCreator.success(CREATE_REFERRAL, res));
    getReferralForCurrentUser()(dispatch);
    dispatch(
      addNotificationMessage(
        'Successfully updated referral!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(actionCreator.failure(CREATE_REFERRAL, error));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getCustomerCountry = customerId => async dispatch => {
  dispatch(actionCreator.request(GET_CUSTOMER_COUNTRY));
  try {
    const res = await axiosInstance.get(`customer/view/admin/${customerId}`);
    dispatch(actionCreator.success(GET_CUSTOMER_COUNTRY, res.country));
  } catch (err) {
    dispatch(actionCreator.failure(GET_CUSTOMER_COUNTRY, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error!'));
  }
};
