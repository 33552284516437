import React, { useMemo, useState } from 'react';
import moment from 'moment';
import Select from 'react-select';
import classes from './ResponsibleGambling.module.scss';
import Input from '../../../../../components/Form/Input';
import Switch from '../../../../../components/Form/Switch/Switch';
import { underlinedSelectStyles } from '../../../../../utils/selectStyles';
import { useStateForSelect, useUpdate } from '../../../../../hooks';

const DepositLimit = ({
  depositLimit,
  setPeriod,
  period,
  setLimit,
  limit,
  setIsActive,
  isActive,
  refetch,
}) => {
  const [depositPeriod, setDepositPeriod] = useStateForSelect('');
  const [depositlimit, setDepositlimit] = useState('');
  const [activeDeposit, setActiveDeposit] = useState(false);
  const [scheduledDate, setScheduledDate] = useState('');

  const options = useMemo(
    () =>
      ['DAY', 'WEEK', 'MONTH', 'YEAR'].map(period => ({
        value: period,
        label: period,
      })),
    []
  );

  useUpdate(() => {
    if (depositLimit && !depositPeriod) {
      const currentDeposit = depositLimit.find(lim => lim.active);
      if (currentDeposit) {
        setDepositPeriod({
          value: currentDeposit.periodType,
          label: currentDeposit.periodType,
        });
        setDepositlimit(currentDeposit.depositlimit);
      }
    }
  }, [depositLimit]);

  useUpdate(() => {
    if (depositLimit && limit !== 0) {
      const currentDeposit = depositLimit.find(
        lim => lim.scheduledDepositLimit !== 0
      );
      if (currentDeposit) {
        setPeriod({
          value: currentDeposit.periodType,
          label: currentDeposit.periodType,
        });
        setScheduledDate(currentDeposit.scheduledDate);
        setLimit(currentDeposit.scheduledDepositLimit);
      }
    }
  }, [depositLimit]);

  useUpdate(() => {
    if (depositPeriod) {
      const currentDeposit = depositLimit.find(
        lim => lim.periodType === depositPeriod.value
      );
      if (currentDeposit) {
        setActiveDeposit(currentDeposit.active);
        setDepositlimit(currentDeposit.depositlimit);
      }
    }
  }, [depositPeriod]);

  useUpdate(() => {
    if (period) {
      setIsActive(false);
      const currentDeposit = depositLimit.find(
        lim => lim.periodType === period.value
      );
      if (currentDeposit) {
        setScheduledDate(currentDeposit.scheduledDate);
        setLimit(currentDeposit.scheduledDepositLimit);
      }
    }
  }, [period]);

  useUpdate(() => {
    setIsActive(isActive);
  }, [isActive]);

  return (
    <>
      <div>
        <h1 className={classes.title}>DEPOSIT LIMIT CONFIGURATION</h1>
        <div className={classes.formLayout}>
          <label>Period:</label>
          <Select
            options={options}
            placeholder="Week"
            styles={underlinedSelectStyles}
            value={depositPeriod}
            onChange={e => {
              setDepositPeriod(e);
              refetch();
            }}
          />
        </div>
        <div className={classes.formLayout}>
          <label>Limit:</label>
          <Input
            readOnly
            customInput
            type="text"
            className="efb-input__underlined"
            value={activeDeposit ? depositlimit : ''}
          />
        </div>
      </div>
      <div className={classes.moveToBottom}>
        <h1 className={classes.title}>Pending Values</h1>
        <div className={classes.formLayout}>
          <label>Period:</label>
          <Select
            name="pendingValues"
            options={options}
            placeholder="Week"
            styles={underlinedSelectStyles}
            value={period}
            onChange={e => {
              setPeriod(e);
              refetch();
            }}
          />
        </div>
        <div className={classes.formLayout}>
          <label>Limit:</label>
          <Input
            readOnly
            customInput
            min="10"
            type="number"
            className="efb-input__underlined"
            value={limit || ''}
          />
        </div>
        <div className={classes.activationInput}>
          <label>Activate now:</label>
          <div>
            <Switch smallSwitch isChecked={isActive} onChange={setIsActive} />
            <Input
              readOnly
              customInput
              type="text"
              className="efb-input__underlined"
              value={
                scheduledDate
                  ? moment(scheduledDate).format('DD/MM/YYYY HH:MM:SS')
                  : ''
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositLimit;
