import React, { memo } from 'react';

export const MotorSports = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `MotorSports ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 133.97 141"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <path
          className={'Helmet'}
          d="M134,72.57c0,40.08-54.91,51.44-98.52,66-23.68,7.89-31.6-4.09-34.21-23.94L71,84.27c3.8-1.66,5.44-7.65,3.8-13.38L69.17,51.32C67.54,45.59,63,41.36,58.87,42L3.17,50.15C11.59,21,36.33,0,68.59,0,108.67,0,134,32.49,134,72.57Z"
        />
        <path d="M71,84.27,1.24,114.6c-1.57-12-1.2-26.92-1.2-42A80.62,80.62,0,0,1,3.17,50.15L58.87,42c4.11-.61,8.66,3.62,10.29,9.36l5.59,19.56C76.4,76.62,74.76,82.6,71,84.27Z" />
        <path
          className={'Visor'}
          d="M10.34,72.56c0,13.38-.29,26.57.74,37.76L1.24,114.6C-.33,102.58,0,87.69,0,72.56A80.62,80.62,0,0,1,3.17,50.15l10.78-1.59c-.17.52-.33,1.06-.48,1.59A81,81,0,0,0,10.34,72.56Z"
        />
        <path
          className={'Shade'}
          d="M84.71,1.88C62.21,7.24,45.06,23.61,37.19,45.16l-6.89,1L23,47.23C31.26,22.51,51.5,4.17,78.06.63c1.15.16,2.28.34,3.4.56h0C82.56,1.39,83.64,1.63,84.71,1.88Z"
        />
        <path
          className={'Shade'}
          d="M42.13,136.35l-4.67,1.53-2,.67c-.83.28-1.64.53-2.42.76-7.75-3.61-11.26-12.72-12.83-24.71-.33-2.52-.58-5.16-.76-7.91l6.74-2.94,6.4-2.78c.17,4.83.49,9.4,1,13.63C34.85,124.09,37.32,131.78,42.13,136.35Z"
        />
        <path
          className={'Visor'}
          d="M66.87,46.46c-.42,1.22-.81,2.44-1.17,3.69a80.62,80.62,0,0,0-3.13,22.41c0,5.18,0,10.35,0,15.37l-22.75,9.9c-.19-8-.07-16.61-.07-25.27a80.62,80.62,0,0,1,3.13-22.41Q43.75,47,44.9,44l14-2.05C61.68,41.55,64.71,43.4,66.87,46.46Z"
        />
        <path d="M59.67,46.89c1.07,0,3.62,2,4.71,5.8L70,72.26c1.16,4-.2,7.09-1,7.44L5.52,107.3C4.93,99,5,89.66,5,79.87q0-3.63,0-7.3a76.18,76.18,0,0,1,2.12-18L59.6,46.9h.07m0-5a5.43,5.43,0,0,0-.79.06L3.17,50.15A80.62,80.62,0,0,0,0,72.57c0,15.12-.37,30,1.2,42L71,84.27c3.8-1.66,5.44-7.65,3.8-13.38L69.17,51.32c-1.53-5.37-5.62-9.42-9.5-9.42Z" />
      </g>
    </svg>
  );
};

export default memo(MotorSports);
