import React, { memo } from 'react';

export const Floorball = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Floorball ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140.04 141"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <path
          className={'Stick'}
          d="M139.46,7l-39.6,95.44H81.47L121.07,7c1.6-3.85,6-7,9.87-7h4.44C139.23,0,141.06,3.13,139.46,7Z"
        />
        <path
          className={'Stick'}
          d="M81.47,102.42l0,0H20.73c-3.86,0-8.27,3.12-9.88,7L.58,134.05c-1.51,3.65,0,6.64,3.48,6.95H77.72a12,12,0,0,0,9-6.92l13.14-31.66ZM24,117.6H34.86l-3.54,8.07H20.5Zm-6.81,8.07H7.32l3.37-8.07H20.78Zm35-8.07H63l-3.54,8.07H48.66Zm-6.81,8.07H34.58l3.54-8.07H48.94Zm8.13-11.06,4-9.16H68.36l-4,9.16Zm-3.27,0H39.44l4-9.16H54.28Zm-14.08,0H25.36l4-9.16H40.2Zm-6.17,14L25.89,138H15.08l4.11-9.36Zm3.27,0H44.08L40,138H29.16Zm14.08,0H58.16L54.05,138H43.24Zm14.08,0H72.24L68.13,138H57.32Zm1.31-3,3.54-8.07H77.1l-3.54,8.07Zm17.63-8.07h10L87,125.66H76.82Zm1.31-3,4-9.2h9.66l-3.82,9.2Zm.77-9.18-4,9.18H67.6l4-9.16h9.82Zm-68.84,5.16c1.14-2.74,4.46-5.14,7.12-5.14h5.39l-4,9.16H11.94ZM3.16,137.49a2.73,2.73,0,0,1,.17-2.28l2.74-6.55h9.85L11.81,138H4.21A1.4,1.4,0,0,1,3.16,137.49ZM84,132.93A9.15,9.15,0,0,1,77.53,138H71.4l4.11-9.36H85.74Z"
        />
        <circle className={'Ball'} cx="45.11" cy="70.5" r="24.89" />
        <circle cx="45.11" cy="70.5" r="4.56" />
        <ellipse
          cx="58.01"
          cy="58.32"
          rx="3.16"
          ry="4.56"
          transform="translate(-24.25 58.1) rotate(-45)"
        />
        <ellipse
          cx="32.22"
          cy="84.11"
          rx="3.16"
          ry="4.56"
          transform="translate(-50.04 47.42) rotate(-45)"
        />
        <ellipse
          cx="32.22"
          cy="58.32"
          rx="4.56"
          ry="3.16"
          transform="translate(-31.8 39.86) rotate(-45)"
        />
        <ellipse
          cx="58.01"
          cy="84.11"
          rx="4.56"
          ry="3.16"
          transform="translate(-42.49 65.65) rotate(-45)"
        />
      </g>
    </svg>
  );
};

export default memo(Floorball);
