import { FILTER_TRANSLATIONS } from '../../actions/cms/translations';

const initialState = {
  translations: [],
};

export const translationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_TRANSLATIONS.SUCCESS:
      const filtered = (initialState.translations = [
        action.payload.payload !== ''
          ? action.payload.response
          : { language: action.payload.lang, translations: {} },
      ]);
      return {
        ...state,
        translations: filtered,
      };
    default:
      return state;
  }
};
