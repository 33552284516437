import React from 'react';

const IconTick = ({ width, height, ...otherProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 19 19"
      {...otherProps}
    >
      <g fill="#fff8da" stroke="#ebc317" stroke-width="1.4" opacity="0.67">
        <rect width="19" height="19" rx="2" stroke="none" />
        <rect x="0.7" y="0.7" width="17.6" height="17.6" rx="1.3" fill="none" />
      </g>
      <g transform="translate(5.114 6.26)">
        <path
          d="M-3876.352-6895.569l-2.777-2.776a1.135,1.135,0,0,1,0-1.605,1.135,1.135,0,0,1,1.606,0l1.973,1.974,3.15-3.151a1.135,1.135,0,0,1,1.605,0,1.138,1.138,0,0,1,0,1.607l-3.953,3.952a1.132,1.132,0,0,1-.8.333A1.132,1.132,0,0,1-3876.352-6895.569Z"
          transform="translate(3879.461 6901.461)"
          fill="#616161"
        />
      </g>
    </svg>
  );
};

export default IconTick;
