import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import * as StsActions from '../../../../redux/actions/cms/stsBuilder';
import * as UiActions from '../../../../redux/actions/ui';
import StsCreateForm from '../StsCreateForm';

const CreateSport = ({
  languages,
  createSport,
  editingSport,
  updateSport,
  clearEditingSport,
  addNotificationMessage
}) => {
  const submitSport = useCallback(
    (names, status) => {
      if (!names.en.name) {
        addNotificationMessage(
          'Name in English is required!',
          'warning',
          'Warning!'
        );
        return;
      }
      const sport = { status, names };
      if (editingSport) {
        updateSport({ ...editingSport, ...sport });
        clearEditingSport();
      } else {
        createSport(sport);
      }
    },
    [addNotificationMessage, clearEditingSport, createSport, editingSport, updateSport]
  );

  return (
    <div className="efb-form mt-3">
      <StsCreateForm
        sts="sport"
        languages={languages}
        editingSts={editingSport}
        onClear={clearEditingSport}
        onSubmit={submitSport}
      />
    </div>
  );
};

const mapDispatchToProps = {
  createSport: StsActions.createSport,
  updateSport: StsActions.updateSport,
  addNotificationMessage: UiActions.addNotificationMessage
};

export default connect(
  null,
  mapDispatchToProps
)(CreateSport);
