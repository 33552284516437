import axiosInstance from '../axios-instance';
import { actionCreator, createRequestTypes } from '../index';
import { addNotificationMessage } from '../ui';

export const GET_SPORTS = createRequestTypes('GET_SPORTS');
export const GET_COUNTRY_CODES = createRequestTypes('GET_COUNTRY_CODES');
export const GET_COMPETITORS_LIST = createRequestTypes('GET_COMPETITORS_LIST');

export const getSports = () => async dispatch => {
  dispatch(actionCreator.request(GET_SPORTS));
  try {
    const res = await axiosInstance.get('/sport/event/view/sport/admin');
    dispatch(actionCreator.success(GET_SPORTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_SPORTS, err.message));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getCountryCodes = () => async dispatch => {
  dispatch(actionCreator.request(GET_COUNTRY_CODES));
  try {
    const res = await axiosInstance.get('sport/event/view/country/admin');
    dispatch(actionCreator.success(GET_COUNTRY_CODES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_COUNTRY_CODES, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const createCompetitor = competitor => async dispatch => {
  try {
    await axiosInstance.post('/sport/event/view/competitor/admin', competitor);
    dispatch(
      addNotificationMessage(
        'Successfully created competitor',
        'success',
        'Success!'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getCompetitors = (value, sportId) => async dispatch => {
  dispatch(actionCreator.request(GET_COMPETITORS_LIST));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/competitor/admin/name?pattern=${value}&sportId=${sportId}`
    );
    dispatch(actionCreator.success(GET_COMPETITORS_LIST, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_COMPETITORS_LIST, err.message));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateCompetitor = competitor => async dispatch => {
  try {
    await axiosInstance.post(
      `/sport/event/view/competitor/admin/${competitor.id}`,
      competitor
    );
    dispatch(
      addNotificationMessage(
        'You have successfully updated competitor',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};
