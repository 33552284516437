import { actionCreator, createRequestTypes } from '../index';
import { addNotificationMessage } from '../ui';
import axiosInstance from '../axios-instance';

export const GET_NRA_REPORTS = createRequestTypes('GET_NRA_REPORTS');
export const GET_PLAYER_REPORTS = createRequestTypes('GET_PLAYER_REPORTS');

export const getNraReports = params => async dispatch => {
  dispatch(actionCreator.request(GET_NRA_REPORTS));
  try {
    const res = await axiosInstance.get('/report/admin/nra/', {
      params,
    });

    dispatch(actionCreator.success(GET_NRA_REPORTS));
    return res;
  } catch (error) {
    dispatch(actionCreator.failure(GET_NRA_REPORTS, error));
    dispatch(
      addNotificationMessage(error.error_description, 'error', 'Error!')
    );
  }
};

export const getPlayerReports = params => async dispatch => {
  dispatch(actionCreator.request(GET_PLAYER_REPORTS));
  try {
    const res = await axiosInstance.get('/report/admin/nra/player-details', {
      params,
    });

    dispatch(actionCreator.success(GET_PLAYER_REPORTS));
    return res;
  } catch (error) {
    dispatch(actionCreator.failure(GET_PLAYER_REPORTS, error));
    dispatch(
      addNotificationMessage(error.error_description, 'error', 'Error!')
    );
  }
};
