import React, { Component, Fragment } from 'react';
import SportTemplates from './SportTemplates/SportTemplates';
import MarketTypes from './MarketTypes/MarketTypes';
import BetTypes from './BetTypes/BetTypes';
import birDelay from './BirDelay/BirDelayTemplates';
import RiskCategories from './RiskCategories/RiskCategories';
import NavTabs from '../../../components/Layout/NavTabs';
import { configurationTabs } from './ConfigurationUtils';

class Configuration extends Component {
  state = {
    configurationTab: 'sportTemplates',
  };

  setConfigurationTab = e => {
    this.setState({ configurationTab: e.target.getAttribute('name') });
  };

  renderTabContent = () => {
    let Tab;
    switch (this.state.configurationTab) {
      case 'sportTemplates':
        Tab = SportTemplates;
        break;
      case 'marketTypes':
        Tab = MarketTypes;
        break;
      case 'betTypes':
        Tab = BetTypes;
        break;
      case 'birDelay':
        Tab = birDelay;
        break;
      case 'riskCategories':
        Tab = RiskCategories;
        break;
      default:
        Tab = Fragment;
        break;
    }

    return <Tab />;
  };

  render() {
    return (
      <div className="efb-container">
        <NavTabs
          navTab={this.state.configurationTab}
          setConfigurationTab={this.setConfigurationTab}
          tabs={configurationTabs}
        />
        <div className="tab-content">{this.renderTabContent()}</div>
      </div>
    );
  }
}

export default Configuration;
