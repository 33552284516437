import React, { memo } from 'react';

export const Bandy = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={ `Bandy ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''} ${className ? className : ''}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140.48 141"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <path
          className={'Stick'}
          d="M139.41,7l-33,79.6a30.87,30.87,0,0,0-18.16,43.76L86.69,134c-1.6,3.86-6,7-9.88,7H72.38l-.45,0H4.65C.8,141-1,137.85.57,134l10.28-24.61c1.6-3.86,6-7,9.88-7H81.42L121,7c1.6-3.86,6-7,9.87-7h4.44C139.18,0,141,3.12,139.41,7Z"
        />
        <polygon
          className={'Stripe'}
          points="56.93 140.94 14.2 140.94 30.27 102.38 73 102.38 56.93 140.94"
        />
        <circle className={'classes.Ball'} cx="116" cy="116.45" r="24.97" />
      </g>
    </svg>
  );
};

export default memo(Bandy);
