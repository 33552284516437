export const selectOptions = allData => {
  if (allData.length === 0) return [];
  return allData.reduce((acc, curr) => {
    acc.push({
      value: curr,
      label: curr,
    });
    return acc;
  }, []);
};

export const configurationTabs = {
  'Pending Verification': 'pendingVerification',
  'Change Verification': 'changeVerification',
  'Type of Verification': 'typeVerification',
};

export const columnsForValidationTable = [
  {
    name: 'Username',
    value: 'username',
    type: 'string',
    sortable: true,
  },
  {
    name: 'User id',
    value: 'id',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Document Type',
    value: 'documentType',
    type: 'string',
  },
  {
    name: 'Date upload',
    value: 'uploadDate',
    type: 'string',
    sortable: true,
  },
  { name: 'Actions', type: 'button' },
];
