import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';

import * as CashoutManagerActions from '../../../../redux/actions/riskManagement/cashoutManager';

import Switch from '../../../../components/Form/Switch/Switch';
import Button from '../../../../components/Layout/Button';
import classes from './CashoutConfigurator.module.scss';

import { selectStyles } from '../../../CMS/customReactSelectStyle';
import {
  useFetch,
  useStateForSelect,
  useStateForSwitch,
} from '../../../../hooks';

const MarketTypeConfigurator = ({ createOrUpdateMarketTypeConfig }) => {
  const [sport, setSport] = useStateForSelect('');
  const [marketType, setMarketType] = useStateForSelect('');
  const [enabled, setEnabled, resetEnabled] = useStateForSwitch(false);

  const { data: sports } = useFetch('/sport/event/view/sport/admin');

  const { data: marketTypes } = useFetch(
    '/riskmgnt/view/admin/marketTypes/views'
  );

  const { data: marketTypeConfig, refetch } = useFetch(
    '/cashout/admin/config/marketType'
  );

  const config = useMemo(() => {
    if (marketType) {
      const config = marketTypeConfig.find(
        conf => conf.marketTypeId === marketType.id
      );
      if (config) {
        resetEnabled(config.enabled);
      }
      return config;
    }
    // eslint-disable-next-line
  }, [marketType, marketTypeConfig]);

  const createConfig = useCallback(async () => {
    await createOrUpdateMarketTypeConfig({
      changedDate: moment(),
      createdDate: moment(),
      id: config ? config.id : null,
      marketTypeId: marketType.id,
      marketTypeName: marketType.value,
      sportId: sport.id,
      sportName: sport.value,
      enabled,
    });
    await refetch();
  }, [
    config,
    createOrUpdateMarketTypeConfig,
    enabled,
    marketType,
    refetch,
    sport,
  ]);

  if (!sports || !marketTypes || !marketTypeConfig) return null;
  return (
    <div className="p-5">
      <div className="my-3 form-row justify-content-center">
        <Select
          className="col-md-8"
          name="sport"
          placeholder="Select sport"
          styles={selectStyles}
          value={sport}
          options={
            sports &&
            sports.map(sport => ({
              value: sport.names.en.name,
              label: sport.names.en.name,
              id: sport.id,
            }))
          }
          onChange={setSport}
        />
      </div>
      <div className="my-3 form-row justify-content-center">
        <Select
          className="col-md-8"
          name="markets"
          placeholder="Select market type"
          styles={selectStyles}
          value={marketType}
          options={
            marketTypes &&
            marketTypes.map(market => ({
              value: market.name,
              label: market.name,
              id: market.id,
            }))
          }
          onChange={setMarketType}
        />
      </div>
      {sport && marketType ? (
        <>
          <div className="d-flex justify-content-around pt-5">
            <h3 className="efb-h3">{`${sport.value}-${marketType.value}`}</h3>
            <Button cssClass="efb-btn__main" onClick={createConfig}>
              {config ? 'Update' : 'Create'} config
            </Button>
          </div>
          <hr />
        </>
      ) : null}
      {config ? (
        <>
          <div className={classes.marketTypeConfig}>
            <h5>Sport Name</h5>
            <h5>Market Type</h5>
            <h5>Id</h5>
            <h5>Disable/Enable</h5>
            <div>{config.sportName}</div>
            <div>{config.marketTypeName}</div>
            <div>{config.id}</div>
            <div>
              <Switch
                id="config"
                name="config"
                isChecked={enabled}
                onChange={setEnabled}
              />
            </div>
          </div>
          <hr />
        </>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = {
  createOrUpdateMarketTypeConfig:
    CashoutManagerActions.createOrUpdateMarketTypeConfig,
};

export default connect(null, mapDispatchToProps)(MarketTypeConfigurator);
