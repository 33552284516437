import React from 'react';
import classes from './Journal.module.scss';
import Modal from '../../../../components/NewModal/Modal';
import Input from '../../../../components/Form/Input';
import Buttons from '../../../../components/ModalButtons/Buttons';
import RadioButton from '../../../../components/Form/RadioButton/RadioButton';
import Select from 'react-select';
import { underlinedSelectStyles } from '../../../../utils/selectStyles';
import { useCounter } from '../../../../hooks';

const Journal = ({ isActive, onClose, customer }) => {
  const amount = useCounter(0);

  return (
    <Modal className="px-3 py-2" isActive={isActive} onClose={onClose}>
      <div className={classes.layout}>
        <p>
          Journal: <b>{customer.firstLastName}</b>{' '}
        </p>
        <div className={`text-right ${classes.customerInfo}`}>
          <strong className="mr-3">[ {customer.publicId} ]</strong>
        </div>
      </div>
      <div className={classes.formWrapper}>
        <div className={classes.form}>
          <div className={classes.divider}>
            <label>Account:</label>
            <div className={classes.radioGroup}>
              <RadioButton label="Main" name="verified" />
              <RadioButton label="Free" name="unverified" />
            </div>
          </div>
          <div className={classes.divider}>
            <label>Tx Class:</label>
            <Select
              options={[]}
              placeholder="Payouts"
              styles={underlinedSelectStyles}
            />
          </div>
          <div className={classes.divider}>
            <label>Tx Type:</label>
            <Select
              options={[]}
              placeholder="Cancel Bet"
              styles={underlinedSelectStyles}
            />
          </div>
          <div className={classes.divider}>
            <label>Amount:</label>
            <div className={`no-text-highlight ${classes.numberInput}`}>
              <span onClick={amount.increase}>+</span>
              <Input
                customInput
                type="number"
                value={amount.value}
                onChange={amount.setValue}
              />
              <span onClick={amount.decrease}>-</span>
            </div>
          </div>
        </div>
        <div className={classes.box}>
          <h1>Reason</h1>
          <Input customInput placeholder="Describe the transaction reason" />
        </div>
      </div>
      <div className="pt-4">
        <Buttons cancel={onClose} />
      </div>
    </Modal>
  );
};

export default Journal;
