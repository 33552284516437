import React from 'react';
import ReactCrop from 'react-image-crop';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as UiActions from '../../../redux/actions/ui';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/Layout/Button';
import Input from '../../../components/Form/Input';
import classes from './EventModal.module.scss';
import 'react-image-crop/lib/ReactCrop.scss';

const EventModal = ({
  isActive,
  onClose,
  addNotificationMessage,
  addTopEvent
}) => {
  const minWidth = 300;
  const minHeight = 150;
  const maxWidth = minWidth * 3;
  const maxHeight = minHeight * 3;
  const requiredRatio = 2 / 1;

  const [imageRef, setImageRef] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [shouldCrop, setShouldCrop] = React.useState(false);
  const [crop, setCrop] = React.useState({
    aspect: requiredRatio,
    x: 0,
    y: 0,
    width: minWidth,
    height: minHeight
  });

  const onImageLoaded = image => {
    setImageRef(image);
  };

  const onCropChange = crop => setCrop(crop);

  const checkResolution = e => {
    const img = e.target;
    const width = img.naturalWidth;
    const height = img.naturalHeight;

    window.URL.revokeObjectURL(img.src);

    if (width < minWidth || height < minHeight) {
      addNotificationMessage(
        `Image is too small. Minimum dimensions are ${minWidth}x${minHeight}`,
        'error',
        'Error!'
      );

      setImage(null);
      setShouldCrop(false);
      return;
    }

    if (
      width > maxWidth ||
      height > maxHeight ||
      width / height !== requiredRatio
    ) {
      addNotificationMessage(
        'Image is not the correct dimensions. Try cropping it!',
        'error',
        'Error!'
      );
      setShouldCrop(true);
    }
  };

  const onImageUpload = e => {
    setImage(e.target.files[0]);
    setShouldCrop(false);
  };
  const saveEvent = async () => {
    addTopEvent(
      shouldCrop ? await getCroppedImg(imageRef, crop, image.name) : image
    );
    setImage(null);
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        if (!blob) {
          return;
        }
        blob.lastModifiedDate = new Date();
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  return (
    <Modal
      isActive={isActive}
      onClose={onClose}
      headerText="Add event to Top Events"
      small
    >
      {image ? (
        shouldCrop ? (
          <div className="my-3 text-center">
            <ReactCrop
              src={URL.createObjectURL(image)}
              onImageLoaded={onImageLoaded}
              onChange={onCropChange}
              crop={crop}
              minWidth={minWidth}
              minHeight={minHeight}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
            />
          </div>
        ) : (
          <div className="my-3 text-center">
            <img
              onLoad={checkResolution}
              className={classes['thumbnail-image']}
              src={URL.createObjectURL(image)}
              alt=""
            />
          </div>
        )
      ) : null}
      <div className="container">
        <div className="row">
          <p className="col-4 text-center">
            Minimum Dimension: {minWidth}x{minHeight}
          </p>
          <p className="col-4 text-center">
            Maximum Dimension: {maxWidth}x{maxHeight}
          </p>
          <p className="col-4 text-center">Ratio: 2 / 1</p>
        </div>
      </div>
      <div className="container my-3 d-lg-flex align-items-center justify-content-center">
        <div className="mr-3">
          <label className="btn btn-primary mb-0" htmlFor="image-1">
            Upload image
          </label>
          <Input
            className="d-none"
            accept="image/*"
            type="file"
            name="image"
            id="image-1"
            onChange={onImageUpload}
          />
        </div>
        {image ? (
          <div>
            <Button cssClass="btn btn-primary" onClick={saveEvent}>
              Save to Top Events
            </Button>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

EventModal.propTypes = {
  addTopEvent: PropTypes.func.isRequired,
  addNotificationMessage: PropTypes.func
};

const mapDispatchToProps = {
  addNotificationMessage: UiActions.addNotificationMessage
};

export default connect(
  null,
  mapDispatchToProps
)(EventModal);
