import {getRolesRequestType} from "../actions/rolesApi";

const initialState = {
	roles: [],
	error: {},
	applications: []
};

//use switch it's more readable
export const rolesReducer = (state = initialState, action) => {
	switch(action.type){
		case getRolesRequestType.SUCCESS:
			return {...state,
				roles: Array.isArray(action.response) ? action.response : [...state, action.response],
				error: {}
			};
		case getRolesRequestType.FAILURE:
			return {...state,
				roles: [],
				error: action.error
			};
		case getRolesRequestType.FETCH_APPLICATIONS:
			return {
				...state,
				applications: action.applications,
			};

		default: return state
	}
};