import React, { useState, useCallback, useMemo } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import Button from '../../../../../components/Layout/Button';
import InputButton from '../../../../../components/Form/InputButton/InputButton';
import { useMount, useUpdate } from '../../../../../hooks';

const ImageSize = ({
  imagePlatform,
  casinoGameObj,
  currentGameImages,
  updateChanges,
  activeImageSize,
  finishUploadingImage,
}) => {
  {
    const [image, setImage] = useState('');
    const [casinoObj, setCasinoObj] = useState('');
    const [imageRatio, setImageRatio] = useState({
      aspect: 0,
      x: 0,
      y: 0,
      width: 150,
      height: 150,
      minWidth: 150,
      maxWidth: 250,
    });
    const [shouldCrop, setShouldCrop] = useState(false);
    const [imageRef, setImageRef] = useState('');

    useMount(() => {
      const imageRatioClone = { ...imageRatio };
      if (activeImageSize === 'big' && imagePlatform === 'webImage') {
        imageRatioClone.aspect = 1.78 / 1;
      }
      if (activeImageSize === 'big' && imagePlatform === 'mobileImage') {
        imageRatioClone.aspect = 60 / 20;
      }

      if (activeImageSize === 'small' && imagePlatform === 'webImage') {
        imageRatioClone.aspect = 2 / 1;
      }

      if (activeImageSize === 'small' && imagePlatform === 'mobileImage') {
        imageRatioClone.aspect = 40 / 21;
      }
      setImageRatio(imageRatioClone);
    });

    useUpdate(() => {
      setCasinoObj(casinoGameObj);
    }, [casinoGameObj]);

    const onChange = useCallback(
      e => {
        const name = e.target.name;
        const casinoGameObjClone = { ...casinoObj };
        if (casinoGameObjClone[imagePlatform]) {
          casinoGameObjClone[imagePlatform][name] = {
            name: e.target.files[0],
            url: { url: e.target.files[0] },
          };
          setCasinoObj(casinoGameObjClone);
          setImage(URL.createObjectURL(e.target.files[0]));
          updateChanges(casinoGameObjClone);
        }
      },
      [casinoObj, imagePlatform, updateChanges]
    );

    const uploadImage = useCallback(
      e => {
        e.preventDefault();
        finishUploadingImage(casinoObj, imagePlatform, activeImageSize);
        const casinoGameObjClone = { ...casinoObj };
        setImage('');
        setShouldCrop(false);
        setCasinoObj(casinoGameObjClone);
      },
      [activeImageSize, casinoObj, finishUploadingImage, imagePlatform]
    );

    const getCroppedPhoto = useCallback((image, crop, fileName) => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
          if (!blob) {
            return;
          }
          blob.name = fileName;
          const fileUrl = window.URL.createObjectURL(blob);
          window.URL.revokeObjectURL(blob);
          blob.url = fileUrl;
          resolve(blob);
        }, 'image/jpeg');
      });
    }, []);

    const checkResolution = useCallback(
      e => {
        const img = e.target;
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        if (width < imageRatio.minWidth || height < imageRatio.minHeight) {
          setShouldCrop(false);
        } else {
          setShouldCrop(true);
        }
      },
      [imageRatio.minHeight, imageRatio.minWidth]
    );

    const setCroppedImage = useCallback(async () => {
      const casinoGameObjClone = { ...casinoObj };
      if (imageRef) {
        const croppedImageUrl = await getCroppedPhoto(
          imageRef,
          imageRatio,
          casinoGameObj[imagePlatform][activeImageSize].name.name
        );
        casinoGameObjClone[imagePlatform][
          activeImageSize
        ].url = croppedImageUrl;
        setCasinoObj(casinoGameObjClone);
        updateChanges(casinoGameObjClone);
      }
    }, [
      activeImageSize,
      casinoGameObj,
      casinoObj,
      getCroppedPhoto,
      imagePlatform,
      imageRatio,
      imageRef,
      updateChanges,
    ]);

    const existingImage = useMemo(() => {
      const imgPlatform = imagePlatform === 'webImage' ? 'WEB' : 'MOBILE';
      return currentGameImages
        ? currentGameImages.findIndex(
            x =>
              x.channel === imgPlatform &&
              x.gameImageSize === activeImageSize.toUpperCase()
          )
        : -1;
    }, [activeImageSize, currentGameImages, imagePlatform]);

    return (
      <>
        <div className="modal-wrapper casino-manager-modal text-center">
          <div className="container">
            <div className="row">
              <div className="container align-items-center justify-content-start">
                <InputButton
                  disabled={existingImage > -1}
                  name={activeImageSize}
                  onChange={onChange}
                />
                <br />
                <br />
                {existingImage > -1 ? (
                  <small>
                    <i className="fa fa-info" /> There is already{' '}
                    {imagePlatform === 'webImage' ? 'web' : 'mobile'}{' '}
                    {activeImageSize} image for this game.
                  </small>
                ) : null}
              </div>
            </div>
          </div>

          <>
            {shouldCrop ? (
              <ReactCrop
                src={image}
                onComplete={setCroppedImage}
                onChange={setImageRatio}
                onImageLoaded={setImageRef}
                crop={imageRatio}
              />
            ) : (
              <img
                src={image}
                alt=""
                className="thumbnail-photo"
                onLoad={checkResolution}
              />
            )}
            <img
              src={image}
              alt=""
              onLoad={checkResolution}
              className="d-none"
            />
          </>
          <hr />
          {shouldCrop ? (
            <div className="image-thumbnails">
              <br />
              <div
                className={
                  casinoGameObj[imagePlatform][activeImageSize] === ''
                    ? 'd-none'
                    : 'image'
                }
              >
                <img
                  src={
                    casinoGameObj[imagePlatform][activeImageSize] !== ''
                      ? casinoGameObj[imagePlatform][activeImageSize].url.url
                      : ''
                  }
                  alt=""
                />
              </div>
            </div>
          ) : null}
          <Button
            cssClass="efb-btn__main"
            disabled={
              casinoGameObj[imagePlatform][activeImageSize] === '' ||
              !casinoGameObj[imagePlatform][activeImageSize]
            }
            onClick={uploadImage}
          >
            <i className="fa fa-upload" /> Upload
          </Button>
        </div>
      </>
    );
  }
};

export default ImageSize;
