import React, { Fragment, useState, useCallback } from 'react';
import Table from '../../../components/Table/Table';
import Button from '../../../components/Layout/Button';
import Modal from '../../../components/Modal/Modal';
import PropTypes from 'prop-types';
import Switch from '../../../components/Form/Switch/Switch';
import { settleMarketObj, rollbackSettledMarket } from './SettlementsUtils';
import { useUpdate } from '../../../hooks';
import InputField from '../../../components/Form/InputField';

const columns = [
  { name: 'No.', value: 'no', type: 'string' },
  { name: 'Market name', value: 'name', type: 'string' },
  { name: 'Market status' },
  { name: 'Info message', value: 'message', type: 'string' },
  { name: 'Action', value: 'action', type: 'string' },
];

const marketDetailsColumns = [
  { name: 'No.', value: '', type: 'string' },
  { name: 'Outcome name', value: '', type: 'string' },
  { name: 'Outcome result', value: '', type: 'string' },
  { name: 'Last Update', value: '', type: 'string' },
  { name: 'Winning', value: '', type: 'string' },
];

const EventsMarkets = React.memo(
  ({
    eventMarkets,
    viewOutcomes,
    settleEventMarket,
    getMarket,
    market,
    resettleMarket,
    marketForSettle,
    setRolledBackMarket,
    setReopenSettlementMode,
    reopenSettlementMode,
    addNotificationMessage,
  }) => {
    const [isResultModalActive, setResultModalActive] = useState(false);
    const [outcomes, setOutcomes] = useState([]);
    const [eventMarket, setEventMarket] = useState([]);
    const [settlementDetailsModal, setSettlementDetailsModal] = useState(false);
    const [settledMarketDetails, setSettledMarketDetails] = useState({});

    useUpdate(() => {
      setEventMarket(market);
      if (reopenSettlementMode && market) {
        setRolledBackMarket(rollbackSettledMarket(market));
        setReopenSettlementMode(false);
      }
      if (Object.keys(eventMarket).length > 0) {
        setOutcomes(market.outcomeBetInfos);
      }
    }, [market, marketForSettle]);

    const closeModal = useCallback(() => {
      setResultModalActive(false);
    }, []);

    const openResultSetModal = useCallback(
      marketId => {
        setResultModalActive(true);
        getMarket(marketId);
      },
      [getMarket]
    );

    const setWinOrLose = useCallback(
      (e, index) => {
        const checked = e.target.checked;
        const outcomesClone = [...outcomes];
        outcomesClone[index].outcomeSettlement.winning = checked;
        setOutcomes(outcomesClone);
      },
      [outcomes]
    );

    const setVoid = useCallback(
      (e, index) => {
        const { name, value } = e.target;
        const outcomesClone = [...outcomes];
        outcomesClone[index].outcomeSettlement[name] =
          name === 'voidFactor' ? Number(value) : value;
        setOutcomes(outcomesClone);
      },
      [outcomes]
    );

    const setDeadHeatFactor = useCallback(
      (e, index) => {
        const { name, value } = e.target;
        const outcomesClone = [...outcomes];
        outcomesClone[index].outcomeSettlement[name] = Number(value);
        setOutcomes(outcomesClone);
      },
      [outcomes]
    );

    const settleMarket = useCallback(() => {
      let isNegative = false;
      eventMarket.outcomeBetInfos.forEach(outcome => {
        if (
          outcome.outcomeSettlement.voidFactor &&
          outcome.outcomeSettlement.voidFactor < 0
        )
          isNegative = true;
      });
      if (!isNegative) {
        settleEventMarket(settleMarketObj(eventMarket, outcomes));
        setResultModalActive(false);
      } else {
        addNotificationMessage(
          "Void Factor value must be between '0.0' and '1.0'",
          'error',
          'Error'
        );
      }
    }, [eventMarket, settleEventMarket, outcomes, addNotificationMessage]);

    const reopenSettlement = useCallback(
      marketId => {
        getMarket(marketId);
        setReopenSettlementMode(true);
      },
      [getMarket, setReopenSettlementMode]
    );

    const openSettlmentDetailsModal = useCallback(market => {
      setSettlementDetailsModal(true);
      setSettledMarketDetails(market);
    }, []);

    return (
      <Fragment>
        <Modal
          headerText={market.names ? market.names.en.name : ''}
          isActive={isResultModalActive}
          onClose={closeModal}
        >
          <div className="modal-wrapper">
            <div className="efb-form">
              <div className="form-row">
                {outcomes
                  ? outcomes.length > 0
                    ? outcomes.map((outcome, index) => {
                        return (
                          <div
                            className="col-md-4 mx-auto"
                            key={outcome.outcomeId}
                          >
                            <b>{outcome.names.en}</b>
                            <br />
                            <br />
                            Lose{' '}
                            <Switch
                              label="Win"
                              value={outcome.outcomeSettlement.winning}
                              onChange={e => setWinOrLose(e, index)}
                            />
                            <hr />
                            <div className="form-label-group label-fix">
                              <InputField
                                type="number"
                                name="voidFactor"
                                min={0}
                                label="Void Factor"
                                onChange={e => setVoid(e, index)}
                              />
                            </div>
                            <div className="form-label-group label-fix">
                              <InputField
                                type="text"
                                name="voidReason"
                                label="Void Reason"
                                onChange={e => setVoid(e, index)}
                              />
                            </div>
                            <div className="form-label-group label-fix">
                              <InputField
                                type="text"
                                name="voidReasonDescription"
                                label="Void Reason Description"
                                onChange={e => setVoid(e, index)}
                              />
                            </div>
                            <div className="form-label-group label-fix">
                              <InputField
                                type="number"
                                min={0}
                                name="deadHeatFactor"
                                label="Dead Heat factor"
                                onChange={e => setDeadHeatFactor(e, index)}
                              />
                            </div>
                          </div>
                        );
                      })
                    : null
                  : null}
              </div>
            </div>
            <hr />
            <Button cssClass="efb-btn__main" onClick={settleMarket}>
              Set
            </Button>
          </div>
        </Modal>

        <Modal
          headerText={settledMarketDetails.name}
          onClose={() => setSettlementDetailsModal(false)}
          isActive={settlementDetailsModal}
        >
          <div className="modal-wrapper">
            <Table
              columns={marketDetailsColumns}
              data={settledMarketDetails.outcomeBetInfos}
              tableType="settlementMarketDetails"
              tableStyle="sortable table table-hover table-striped efb-table"
            />
          </div>
        </Modal>
        <br />
        <Table
          columns={columns}
          data={eventMarkets}
          viewOutcomes={viewOutcomes}
          resettleMarket={resettleMarket}
          openResultSetModal={openResultSetModal}
          reopenSettlement={reopenSettlement}
          openSettlmentDetailsModal={openSettlmentDetailsModal}
          tableType="settlementEventMarkets"
          tableStyle="sortable table table-hover table-striped efb-table"
        />
      </Fragment>
    );
  }
);

EventsMarkets.propTypes = {
  currentEvent: PropTypes.object,
  eventMarkets: PropTypes.array,
  backToEventList: PropTypes.func,
  viewOutcomes: PropTypes.func,
  eventSportInfo: PropTypes.object,
};

export default EventsMarkets;
