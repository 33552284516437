import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ManageBetButton from '../../../containers/CMS/TopManager/ManageBetButton';
import ManageEventButton from '../../../containers/CMS/TopManager/ManageEventButton';
import moment from 'moment';

class SeasonsTableRow extends PureComponent {
  renderRow = (season, i) => {
    const {
      topBets,
      topEvents,
      removeTopBet,
      addTopBet,
      removeTopEvent,
      openEventModal
    } = this.props;

    return (
      <tr key={season.id}>
        <td>{season.names.en.name}</td>
        <td>{moment(season.scheduledTime).format('DD/MM/YYYY HH:mm:ss')}</td>
        <td>{season.status}</td>
        <td>
          <ManageBetButton
            seasonId={season.id}
            topBets={topBets}
            addTopBet={addTopBet}
            removeTopBet={removeTopBet}
          />
          <ManageEventButton
            seasonId={season.id}
            topEvents={topEvents}
            openEventModal={openEventModal}
            removeTopEvent={removeTopEvent}
          />
        </td>
      </tr>
    );
  };

  render() {
    return this.props.tableData.map(this.renderRow);
  }
}

SeasonsTableRow.propTypes = {
  data: PropTypes.array.isRequired
};

export default SeasonsTableRow;
