import React from 'react';

export const BetToView = (props) => {
    return (
	<span className='efb-icon'>
    	<svg className={`efb-icon--${props.size} ${props.cssClass}`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 128 128" >
	
            <path fill="#556080" d="M111.7,109.5H9.4c-5.2,0-9.4-4.2-9.4-9.4v-64c0-5.2,4.2-9.4,9.4-9.4h102.3c5.2,0,9.4,4.2,9.4,9.4v64
                C121.1,105.3,116.9,109.5,111.7,109.5z"/>
            <path fill="#ECF0F1" d="M118.6,101.3H16.3c-5.2,0-9.4-4.2-9.4-9.4v-64c0-5.2,4.2-9.4,9.4-9.4h102.3c5.2,0,9.4,4.2,9.4,9.4v64
                C128,97.1,123.8,101.3,118.6,101.3z"/>
            <path fill="#FFFFFF" d="M51.2,73.1V46.7c0-3,3.2-5,5.9-3.6l26,13.2c2.9,1.5,2.9,5.7,0,7.2L57,76.7C54.4,78.1,51.2,76.2,51.2,73.1z"
                />
            <path fill="#FEB719" d="M55.2,80.7c-4.2,0-7.5-3.4-7.5-7.5V46.7c0-4.2,3.4-7.5,7.5-7.5c1.2,0,2.3,0.3,3.4,0.8l26,13.2
                c2.5,1.3,4.1,3.9,4.1,6.7s-1.6,5.4-4.1,6.7l-26,13.2C57.6,80.4,56.4,80.7,55.2,80.7z M55.2,46.1c-0.2,0-0.5,0.2-0.5,0.5v26.5
                c0,0.4,0.4,0.5,0.5,0.5c0.1,0,0.2,0,0.2-0.1l26-13.2c0.1,0,0.3-0.1,0.3-0.5c0-0.3-0.2-0.4-0.3-0.5l-26-13.2
                C55.4,46.1,55.3,46.1,55.2,46.1z"/>
            <polygon fill="#E1E6E9" points="30.2,101.3 38.3,101.3 38.3,18.5 30.2,18.5 30.2,38.2 6.9,38.2 6.9,46.3 30.2,46.3 30.2,55.9 
                6.9,55.9 6.9,63.9 30.2,63.9 30.2,73.5 6.9,73.5 6.9,81.6 30.2,81.6 "/>
            <path fill="#E1E6E9" d="M128,73.5h-23.4v-9.6H128v-8.1h-23.4v-9.6H128v-8.1h-23.4V18.5h-8.1c0,2.2,0,73,0,82.8h8.1V81.6H128V73.5z"
                />
    	</svg>     
		{props.children}
	</span>
    )
}

export default BetToView;