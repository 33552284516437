import React, { useState, useCallback, useMemo } from 'react';
import Button from '../../../../components/Layout/Button';
import Table from '../../../../components/Table/Table';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal/Modal';
import InputField from '../../../../components/Form/InputField';
import TextEditor from '../ContentCreator/TextEditor';
import ContentEditor from './ContentEditor';
import { useMount } from '../../../../hooks';

const SubpagesTable = ({
  showSubpageContent,
  updateSubpageActiveStatus,
  images,
  uploadImage,
  updateContent,
  subpageContent,
  contentType,
  currentPageType,
  getSubpageContent,
  saveRowCellData,
  submitNewSubpageContent,
  subpages,
  backToMainPages,
  saveColumnCellData,
  updateSubpage,
}) => {
  const [activeModal, setActiveModal] = useState(false);
  const [editingSubpageIndex, setEditingSubpageIndex] = useState('');
  const [editingSubpageResourceType, setEditingSubpageResoruceType] = useState(
    ''
  );
  const [rules, setRules] = useState([
    {
      title: '',
      description: '',
      name: '',
    },
  ]);
  const [sections, setSections] = useState([
    {
      headerTitle: '',
      htmlBody: '',
      name: '',
    },
  ]);
  const [childrenLevel, setChildrenLevel] = useState(0);
  const [subpagesList, setSubpagesList] = useState([]);
  const [contentChildrens, setContentChildrens] = useState([]);
  const [activeContentEditModal, setActiveContentEditModal] = useState(false);

  const columns = useMemo(() => {
    return [
      { name: 'No.' },
      { name: 'Subpage type' },
      { name: 'Name' },
      { name: 'Status' },
      { name: 'Action' },
    ];
  }, []);

  useMount(() => {
    setSubpagesList(subpages);
  }, []);

  const handleInputChange = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      if (editingSubpageResourceType === 'RULES') {
        const rulesClone = [...rules];
        if (name === 'htmlBody') {
          rulesClone[index].description = value;
        } else {
          rulesClone[index][name] = value;
        }
        setRules(rulesClone);
      } else {
        const sectionsClone = [...sections];
        sectionsClone[index][name] = value;
        setSections(sectionsClone);
      }
    },
    [editingSubpageResourceType, rules, sections]
  );

  const editContent = useCallback(
    (subpage, index) => {
      setActiveContentEditModal(true);
      getSubpageContent(subpage, index);
    },
    [getSubpageContent]
  );

  const editSubpageName = useCallback(
    (value, index) => {
      const subpagesClone = [...subpagesList];
      subpagesClone.forEach(subpage => {
        subpage.editMode = false;
      });
      subpagesClone[index].editMode = value;
      setSubpagesList(subpagesClone);
    },
    [subpagesList]
  );

  const closeModal = useCallback(() => {
    setActiveContentEditModal(false);
    setActiveModal(false);
  }, []);

  const assignSubpageContent = useCallback((subpage, index) => {
    setActiveModal(true);
    setEditingSubpageResoruceType(subpage.resourceType);
    setEditingSubpageIndex(index);
  }, []);

  const addContentRow = useCallback(() => {
    if (editingSubpageResourceType === 'RULES') {
      const rulesClone = [...rules];
      rulesClone.push({ title: '', description: '', name: '' });
      setRules(rulesClone);
    } else {
      const sectionsClone = [...sections];
      sectionsClone.push({ headerTitle: '', htmlBody: '', name: '' });
      setSections(sectionsClone);
    }
  }, [editingSubpageResourceType, rules, sections]);

  const removeContentRow = useCallback(
    index => {
      if (editingSubpageResourceType === 'SECTIONS') {
        const sectionsClone = [...sections];
        sectionsClone.splice(index, 1);
        setSections(sectionsClone);
      } else {
        const rulesClone = [...rules];
        rulesClone.splice(index, 1);
        setRules(rulesClone);
      }
    },
    [editingSubpageResourceType, rules, sections]
  );

  const mapContent = useCallback(
    (content, index) => {
      return (
        <div key={index}>
          <p className="float-left">{index + 1}.</p>
          <div className="remove-row" onClick={() => removeContentRow(index)}>
            <i className="fa fa-trash"></i>
          </div>
          <br /> <br />
          <div className="form-row">
            <div className="form-label-group col-md">
              <InputField
                type="text"
                name="name"
                label={'Name'}
                value={content.name}
                onChange={e => handleInputChange(e, index)}
              />
            </div>
            <div className="form-label-group col-md">
              <InputField
                type="text"
                name={currentPageType === 'Section' ? 'headerTitle' : 'title'}
                label={'Header Title'}
                value={
                  currentPageType === 'Section'
                    ? content.headerTitle
                    : !content.title
                    ? ''
                    : content.title
                }
                onChange={e => handleInputChange(e, index)}
              />
            </div>
          </div>
          <TextEditor
            htmlBody={e => handleInputChange(e, index)}
            images={images}
            uploadImage={uploadImage}
            initialValue={
              editingSubpageResourceType === 'SECTIONS'
                ? content.htmlBody
                : content.description
            }
          />
          <br /> <hr />
        </div>
      );
    },
    [
      currentPageType,
      editingSubpageResourceType,
      handleInputChange,
      images,
      removeContentRow,
      uploadImage,
    ]
  );

  const submitSubpageContent = useCallback(
    (content, subpages, subpageIndex) => {
      setActiveModal(false);
      submitNewSubpageContent(content, subpages, subpageIndex);
    },
    [submitNewSubpageContent]
  );

  const showChildren = useCallback(
    subpage => {
      setSubpagesList(subpage.children);
      setChildrenLevel(childrenLevel + 1);
      setContentChildrens([...subpagesList]);
    },
    [childrenLevel, subpagesList]
  );

  const back = useCallback(() => {
    let childrenCounter = childrenLevel;
    childrenCounter--;
    if (childrenCounter > -1) {
      setChildrenLevel(childrenCounter);
      setSubpagesList(contentChildrens);
    } else {
      backToMainPages();
    }
  }, [backToMainPages, childrenLevel, contentChildrens]);

  return (
    <>
      <Modal
        isActive={activeModal}
        onClose={closeModal}
        headerText="Edit"
        myClassName="efb-form"
      >
        <div className="modal-wrapper">
          {editingSubpageResourceType === 'RULES'
            ? rules.map(mapContent)
            : sections.map(mapContent)}
          <div className="form-group d-flex flex-row justify-content-start">
            <Button
              cssClass="efb-btn__main"
              disabled={
                editingSubpageResourceType === 'RULES'
                  ? rules.length === 0
                  : sections.length === 0
              }
              onClick={() =>
                submitSubpageContent(
                  editingSubpageResourceType === 'RULES' ? rules : sections,
                  subpages,
                  editingSubpageIndex
                )
              }
            >
              Submit
            </Button>
            <Button cssClass="efb-btn__main" onClick={closeModal}>
              {' '}
              Cancel
            </Button>
            <Button cssClass="efb-btn__main" onClick={addContentRow}>
              <i className="fas fa-plus"></i> Add{' '}
              {editingSubpageResourceType === 'RULES' ? 'rule' : 'section'}{' '}
              column
            </Button>
          </div>
        </div>
      </Modal>

      <hr />
      <div className="form-row justify-content-start">
        <div className="form-label-group col-md-2">
          <Button
            type="button"
            cssClass="efb-btn__main btn-block"
            onClick={back}
          >
            <i className="fas fa-arrow-left"></i> Back
          </Button>
        </div>
      </div>

      <h3 className="efb-h3">Subpages</h3>

      {subpagesList ? (
        subpagesList.length > 0 ? (
          <Table
            columns={columns}
            data={subpagesList}
            editContent={editContent}
            editSubpageName={editSubpageName}
            showSubpageContent={showSubpageContent}
            assignSubpageContent={assignSubpageContent}
            updateSubpageActiveStatus={updateSubpageActiveStatus}
            childrenLevel={childrenLevel}
            updateSubpage={updateSubpage}
            showChildren={showChildren}
            tableType="staticSubpageTable"
            tableStyle="sortable table table-hover table-striped efb-table efb-data-table"
          />
        ) : null
      ) : null}

      <ContentEditor
        activeContentEditModal={activeContentEditModal}
        closeModal={closeModal}
        subpages={subpagesList}
        images={images}
        uploadImage={uploadImage}
        updateContent={updateContent}
        subpageContent={subpageContent}
        currentPageType={currentPageType}
        contentType={contentType}
        saveRowCellData={saveRowCellData}
        saveColumnCellData={saveColumnCellData}
      />
      <br />
    </>
  );
};
// }

SubpagesTable.propTypes = {
  addSection: PropTypes.func,
  backToMainPages: PropTypes.func,
  pageObj: PropTypes.object,
  subpageSections: PropTypes.array,
  subpages: PropTypes.array,
};

export default SubpagesTable;
