import React, { PureComponent } from 'react';
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as BonusActions from '../../../redux/actions/BonusSystem';
import * as EventsManagerActions from '../../../redux/actions/cms/eventsManager';
import * as LanguagesActions from '../../../redux/actions/Languages';
import * as TopManagerActions from '../../../redux/actions/cms/TopManager';
import ManageBetButton from './ManageBetButton';
import ManageEventButton from './ManageEventButton';

import classes from './TopManager.module.scss';
import EventModal from './EventModal';
import CurrentBets from './CurrentBets';
import CurrentEvents from './CurrentEvents';
import Table from '../../../components/NewTable/Table';
import Switch from '../../../components/Form/Switch/Switch';
import CurrentTournaments from './CurrentTournaments';
import Sidebar from '../../../components/Sidebar/Sidebar';
import ManageTournamentButton from './ManageTournamentButton';
import { selectStyles } from '../customReactSelectStyle';
import {
  getCMSData,
  getLanguages,
  getTopManager,
  getBonusSystemData,
} from '../../../selectors';

class TopManager extends PureComponent {
  state = {
    selectedBusinessUnit: '',
    bsUnitsList: [],
    tournamentId: '',
    eventModalActive: false,
    showCurrent: '',
    selectedEvent: '',
  };

  treeProps = {
    onEntityClick: (entity, entityType) => {
      if (entityType === 'tournament') {
        this.props.getEvents(entity.id);
        this.setState({ tournamentId: entity.id });
        return true;
      }
      return false;
    },
    showTournaments: true,
  };

  columnConfig = [
    { name: 'Name', display: entity => entity.names.en.name },
    {
      name: 'Start Time',
      display: entity =>
        moment(entity.scheduledTime).format('DD/MM/YYYY HH:mm:ss'),
    },
    { name: 'Status', display: entity => entity.status },
    {
      name: '',
      display: entity => (
        <>
          <ManageBetButton
            seasonId={entity.id}
            topBets={this.props.topBets}
            addTopBet={this.addTopBet}
            removeTopBet={this.removeTopBet}
          />
          <ManageEventButton
            seasonId={entity.id}
            topEvents={this.props.topEvents}
            openEventModal={this.openEventModal}
            removeTopEvent={this.removeTopEvent}
          />
        </>
      ),
    },
  ];

  componentDidMount() {
    this.props.getBusinessUnits();

    this.props.getAllLanguages();

    if (this.props.businessUnits.length > 0) {
      this.prepareBusinessUnits();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.businessUnits !== prevProps.businessUnits) {
      this.prepareBusinessUnits();
    }
  }

  prepareBusinessUnits = () => {
    const mapped = this.props.businessUnits.map(bUnit => ({
      value: bUnit.name,
      label: bUnit.name,
    }));

    this.setState({
      bsUnitsList: mapped,
    });
  };

  updateLanguage = data => {
    this.props.getTopCoupons(data.value);
    this.props.getTopBets(data.value);
    this.props.getTopEvents(data.value);

    this.setState({ selectedBusinessUnit: data });
  };

  addTopEvent = image => {
    this.props.addTopEvent(
      this.state.selectedEvent,
      image,
      this.state.selectedBusinessUnit.value
    );

    this.closeModal();
  };

  addTopCoupon = () => {
    this.props.addTopCoupon(
      this.state.tournamentId,
      this.state.selectedBusinessUnit.value
    );
  };

  removeTopCoupon = () => {
    this.props.removeTopCoupon(
      this.state.tournamentId,
      this.state.selectedBusinessUnit.value,
      this.props.topCoupons
    );
  };

  // REFACTOR: remove functions for adding and removing and bind them in jsx
  addTopBet = eventId => {
    this.props.addTopBet(eventId, this.state.selectedBusinessUnit.value);
  };

  removeTopBet = eventId => {
    this.props.removeTopBet(
      eventId,
      this.state.selectedBusinessUnit.value,
      this.props.topBets
    );
  };

  removeTopEvent = eventId => {
    this.props.removeTopEvent(
      eventId,
      this.state.selectedBusinessUnit.value,
      this.props.topEvents
    );
  };

  closeModal = () => {
    this.setState({
      eventModalActive: false,
    });
  };

  openEventModal = eventId => {
    this.setState({
      eventModalActive: true,
      selectedEvent: eventId,
    });
  };

  toggleShowCurrent = e => {
    const showCurrent =
      this.state.showCurrent === e.target.value ? '' : e.target.value;
    this.setState({ showCurrent });
  };

  render() {
    return (
      <div className={classes.wrapper}>
        <Sidebar resultType="tournament" treeProps={this.treeProps} />
        <div className={classes.main}>
          <h3 className="efb-h3">Top Manager</h3>
          <Select
            value={this.state.selectedBusinessUnit}
            onChange={this.updateLanguage}
            options={this.state.bsUnitsList}
            styles={selectStyles}
            className="mb-3"
            placeholder="Select business unit"
          />
          {this.state.selectedBusinessUnit && (
            <>
              <div className="row mb-3">
                <div className="col-md-3">
                  <Switch
                    value="Tournaments"
                    name="showCurrent"
                    label="Show current top tournaments"
                    isChecked={this.state.showCurrent === 'Tournaments'}
                    onChange={this.toggleShowCurrent}
                  />
                </div>
                <div className="col-md-3">
                  <Switch
                    value="Bets"
                    name="showCurrent"
                    label="Show current top bets"
                    isChecked={this.state.showCurrent === 'Bets'}
                    onChange={this.toggleShowCurrent}
                  />
                </div>
                <div className="col-md-3">
                  <Switch
                    value="Events"
                    name="showCurrent"
                    label="Show current top events"
                    isChecked={this.state.showCurrent === 'Events'}
                    onChange={this.toggleShowCurrent}
                  />
                </div>
                <div className="col-md-3">
                  <ManageTournamentButton
                    tournamentId={this.state.tournamentId}
                    topCoupons={this.props.topCoupons}
                    removeTopCoupon={this.removeTopCoupon}
                    addTopCoupon={this.addTopCoupon}
                  />
                </div>
              </div>

              {this.state.showCurrent === 'Tournaments' && (
                <CurrentTournaments
                  rowClassName={() => classes.row}
                  removeTopCoupon={this.props.removeTopCoupon}
                  language={this.state.selectedBusinessUnit.value}
                  topCoupons={this.props.topCoupons}
                />
              )}

              {this.state.showCurrent === 'Bets' && (
                <CurrentBets
                  rowClassName={() => classes.row}
                  removeTopBet={this.props.removeTopBet}
                  language={this.state.selectedBusinessUnit.value}
                  topBets={this.props.topBets}
                />
              )}

              {this.state.showCurrent === 'Events' && (
                <CurrentEvents
                  rowClassName={() => classes.row}
                  removeTopEvent={this.props.removeTopEvent}
                  language={this.state.selectedBusinessUnit.value}
                  topEvents={this.props.topEvents}
                />
              )}

              {this.props.events.length > 0 && !this.state.showCurrent && (
                <Table
                  rowClassName={() => classes.row}
                  data={this.props.events}
                  columns={this.columnConfig}
                />
              )}
            </>
          )}
          {this.state.eventModalActive ? (
            <EventModal
              isActive={this.state.eventModalActive}
              onClose={this.closeModal}
              addTopEvent={this.addTopEvent}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

TopManager.propTypes = {
  languages: PropTypes.array,
  getAllLanguages: PropTypes.func,
};

const mapStateToProps = state => ({
  languages: getLanguages(state).languages,
  events: getCMSData(state).eventsManagerReducer.events,
  topBets: getTopManager(state).bets,
  topCoupons: getTopManager(state).coupons,
  topEvents: getTopManager(state).events,
  businessUnits: getBonusSystemData(state).businessUnits,
});

const mapDispatchToProps = {
  getAllLanguages: LanguagesActions.getAllLanguages,
  getEvents: EventsManagerActions.getEvents,

  // Coupon actions
  addTopCoupon: TopManagerActions.addTopCoupon,
  removeTopCoupon: TopManagerActions.removeTopCoupon,
  getTopCoupons: TopManagerActions.getTopCoupons,

  // Bet actions
  addTopBet: TopManagerActions.addTopBet,
  removeTopBet: TopManagerActions.removeTopBet,
  getTopBets: TopManagerActions.getTopBets,

  // Event actions
  addTopEvent: TopManagerActions.addTopEvent,
  removeTopEvent: TopManagerActions.removeTopEvent,
  getTopEvents: TopManagerActions.getTopEvents,
  getBusinessUnits: BonusActions.getBusinessUnits,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopManager);
