import React, { Fragment, useCallback, useMemo, useState } from 'react';

import * as BonusActions from '../../../redux/actions/BonusSystem';
import * as CasinoActions from '../../../redux/actions/customer/Casino';
import * as LanguagesActions from '../../../redux/actions/Languages';
import * as MarketCategoriesActions from '../../../redux/actions/cms/marketCategories';
import * as UiActions from '../../../redux/actions/ui';

import BonusesStatus from './BonusesStatus';
import CreateCampaign from './CreateCampaign';
import CreateSportBonus from './CreateSportBonus';
import EmailTemplates from './EmailTemplates';
import NavTabs from '../../../components/Layout/NavTabs';
import { configurationTabs } from './ConfigurationUtils';
import { connect } from 'react-redux';
import { getBonusSystemData, getCasinoData, getLanguages, getMarketCategoriesData } from '../../../selectors';
import { useMount, useUpdate } from '../../../hooks';

const BonusSystemBuilder = ({
  getBusinessUnits,
  getMarketTypes,
  createCampaign,
  createBonus,
  businessUnits,
  getCampaigns,
  casinoGames,
  casinoProviders,
  campaigns,
  getCategories,
  getTournaments,
  getEvents,
  getAllSports,
  getBonusTypes,
  languages,
  sports,
  bonusTypes,
  bonusConfigs,
  categories,
  tournaments,
  events,
  clearCategories,
  clearTournaments,
  clearEvents,
  addNotificationMessage,
  getPendingBonuses,
  getAllBonuses,
  getConfigs,
  getAllLanguages,
  getCasinoGames,
  getCasinoProviders,
  createEmailTemplate,
  bonuses,
  clearBonuses,
  editBonus,
  approveCustomerBonus,
  rejectCustomerBonus,
  allMarketTypes
}) => {
  useMount(() => {
    getMarketTypes();
    getAllLanguages();
    getCasinoGames();
    getCasinoProviders();
    getBusinessUnits();
    getCampaigns();
    getAllSports();
    getBonusTypes();
  });

  const [selectedTab, setSelectedTab] = useState('CreateSportBonus');
  const [sportsList, setSportsList] = useState('');
  const [tournamentsList, setTournamentsList] = useState([]);

  useUpdate(() => {
    getCampaigns();
    setTournamentsList(tournaments);
  }, [createCampaign, getCampaigns, tournaments]);

  const getCategoriesByManyIds = useCallback(
    sportsIds => {
      setSportsList(sportsIds);
      sportsIds &&
      sportsIds.forEach(el => {
        getCategories(el.value);
      });
    },
    [getCategories]
  );

  const getTournamentsByManyIds = useCallback(
    categoriesIds => {
      categoriesIds &&
      categoriesIds.forEach(el => {
        getTournaments(el.value);
      });
    },
    [getTournaments]
  );

  const getBonusesByManyNames = useCallback(
    CampaignsNames => {
      CampaignsNames &&
      CampaignsNames.forEach(el => {
        getPendingBonuses(el);
      });
    },
    [getPendingBonuses]
  );

  const getEventsByManyIds = useCallback(
    tournamentsIds => {
      tournamentsIds &&
      tournamentsIds.forEach(el => {
        getEvents(el.value);
      });
    },
    [getEvents]
  );

  const setConfigurationTab = useCallback(
    e => {
      clearBonuses();
      clearCategories();
      clearEvents();
      clearTournaments();
      setSelectedTab(e.target.getAttribute('name'));
    },
    [clearBonuses, clearCategories, clearEvents, clearTournaments]
  );

  const content = useMemo(() => {
    if (sportsList) {
      if (sportsList.length > 1) {
        const tournaments = [...tournamentsList];
        tournaments.forEach(tournament => {
          tournament['sportName'] = sportsList.find(
            x => x.value === tournament.sportId
          ).label;
        });
      }
    }

    switch (selectedTab) {
      case 'createCampaign':
        if (businessUnits.length > 0) {
          return (
            <CreateCampaign
              createCampaign={createCampaign}
              businessUnits={businessUnits}
              addNotificationMessage={addNotificationMessage}
            />
          );
        }
        break;
      case 'CreateSportBonus':
        if (bonusTypes.length > 0) {
          return (
            <CreateSportBonus
              createBonus={createBonus}
              businessUnits={businessUnits}
              languages={languages}
              sports={sports}
              bonusTypes={bonusTypes}
              categories={categories}
              tournaments={tournamentsList}
              events={events}
              casinoGames={casinoGames}
              casinoProviders={casinoProviders}
              getCategoriesByManyIds={getCategoriesByManyIds}
              getTournamentsByManyIds={getTournamentsByManyIds}
              getEventsByManyIds={getEventsByManyIds}
              campaigns={campaigns}
              addNotificationMessage={addNotificationMessage}
              clearCategories={clearCategories}
              clearTournaments={clearTournaments}
              clearEvents={clearEvents}
              bonusConfigs={bonusConfigs}
              getConfigs={getConfigs}
              allMarketTypes={allMarketTypes}
            />
          );
        } else {
          return '';
        }

      case 'EmailTemplates':
        return (
          <EmailTemplates
            bonuses={bonuses}
            createEmailTemplate={createEmailTemplate}
          />
        );

      case 'BonusesStatus':
        if (campaigns.length > 0) {
          return (
            <BonusesStatus
              bonuses={bonuses}
              addNotificationMessage={addNotificationMessage}
              getBonusesByManyNames={getBonusesByManyNames}
              getAllBonuses={getAllBonuses}
              campaigns={campaigns}
              clearBonuses={clearBonuses}
              editBonus={editBonus}
              approveCustomerBonus={approveCustomerBonus}
              rejectCustomerBonus={rejectCustomerBonus}
            />
          );
        }
        return '';

      default:
        return '';
    }
  }, [
    sportsList,
    selectedTab,
    tournamentsList,
    businessUnits,
    bonusTypes,
    bonuses,
    createEmailTemplate,
    campaigns,
    createCampaign,
    addNotificationMessage,
    createBonus,
    languages,
    sports,
    categories,
    events,
    casinoGames,
    casinoProviders,
    getCategoriesByManyIds,
    getTournamentsByManyIds,
    getEventsByManyIds,
    clearCategories,
    clearTournaments,
    clearEvents,
    bonusConfigs,
    getConfigs,
    allMarketTypes,
    getBonusesByManyNames,
    getAllBonuses,
    clearBonuses,
    editBonus,
    approveCustomerBonus,
    rejectCustomerBonus
  ]);

  return (
    <Fragment>
      <NavTabs
        navTab={selectedTab}
        setConfigurationTab={setConfigurationTab}
        tabs={configurationTabs}
      />
      {content}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  languages: getLanguages(state).languages,
  sports: getBonusSystemData(state).sports,
  bonusTypes: getBonusSystemData(state).bonusTypes,
  bonusConfigs: getBonusSystemData(state).bonusConfigs,
  categories: getBonusSystemData(state).categories,
  tournaments: getBonusSystemData(state).tournaments,
  businessUnits: getBonusSystemData(state).businessUnits,
  campaigns: getBonusSystemData(state).campaigns,
  events: getBonusSystemData(state).events,
  bonuses: getBonusSystemData(state).bonuses,
  casinoGames: getCasinoData(state).games,
  casinoProviders: getCasinoData(state).providers,
  allMarketTypes: getMarketCategoriesData(state).allMarketTypes
});

const mapDispatchToProps = {
  getAllLanguages: LanguagesActions.getAllLanguages,
  getAllSports: BonusActions.getAllSports,
  getBonusTypes: BonusActions.getBonusTypes,
  getConfigs: BonusActions.getConfigs,
  getCategories: BonusActions.getCategories,
  getTournaments: BonusActions.getTournaments,
  createCampaign: BonusActions.createCampaign,
  createBonus: BonusActions.createBonus,
  getBusinessUnits: BonusActions.getBusinessUnits,
  getCampaigns: BonusActions.getCampaigns,
  getEvents: BonusActions.getEvents,
  clearCategories: BonusActions.clearCategories,
  clearTournaments: BonusActions.clearTournaments,
  clearEvents: BonusActions.clearEvents,
  getPendingBonuses: BonusActions.getPendingBonuses,
  getAllBonuses: BonusActions.getAllBonuses,
  createEmailTemplate: BonusActions.createEmailTemplate,
  clearBonuses: BonusActions.clearBonuses,
  editBonus: BonusActions.editBonus,
  approveCustomerBonus: BonusActions.approveCustomerBonus,
  rejectCustomerBonus: BonusActions.rejectCustomerBonus,
  getCasinoGames: CasinoActions.getCasinoGames,
  getCasinoProviders: CasinoActions.getCasinoProviders,
  getMarketTypes: MarketCategoriesActions.getMarketTypes,
  addNotificationMessage: UiActions.addNotificationMessage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BonusSystemBuilder);
