import React from 'react';

import Table from '../../../components/NewTable/Table';
import { Button } from '../../../components/Layout';

const CurrentTournaments = ({
  rowClassName,
  topCoupons,
  removeTopCoupon,
  language,
}) => {
  const columnConfig = [
    { name: 'Tournament', display: entity => entity.highlightName },
    { name: 'Sport', display: entity => entity.sportName },
    { name: 'Category', display: entity => entity.categoryName },
    {
      name: '',
      display: entity => (
        <Button
          cssClass="btn btn-danger btn-md mr-3"
          onClick={() =>
            removeTopCoupon(entity.highlightId, language, topCoupons)
          }
        >
          Remove tournament from Top
        </Button>
      ),
    },
  ];

  if (!topCoupons.length) {
    return <h4 className="text-center">No top tournaments</h4>;
  }

  return (
    <Table
      rowClassName={rowClassName}
      data={topCoupons}
      columns={columnConfig}
    />
  );
};

export default CurrentTournaments;
