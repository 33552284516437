import React, { useCallback } from 'react';
import { Button } from '../../Layout';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const EventsSearchTableRow = ({ tableData, getEventMarkets, sportIcon, clearEventMarkets }) => {
  const renderEvents = useCallback(
    (event, index) => {
      const SportIcon = sportIcon;
      const formattedDate = moment(event.scheduledTime).format('DD/MM/YYYY HH:mm:ss');
      return (
        <tr key={event.id}>
          <td>
            {Object.keys(SportIcon).length > 0 ? (
              <div className="event-list-sport-icons">
                <SportIcon
                  selected={true}
                  colorful={true}
                />
              </div>
            ) : null}
            {event.names.en.name}
          </td>
          <td>{event.status}</td>
          <td>{formattedDate}</td>
          <td>
            <Button
              cssClass={event.areMarketDetailsActive ? 'efb-btn__main white-btn' : 'efb-btn__main'}
              onClick={
                !event.areMarketDetailsActive ? (
                  () => getEventMarkets(event.id, event.names, index)
                ) : (
                  () => clearEventMarkets(index)
                )
              }
            >
              {event.areMarketDetailsActive ? 'Hide markets' : 'View markets'}
            </Button>
          </td>
        </tr>
      );
    },
    [clearEventMarkets, getEventMarkets, sportIcon]
  );
  return tableData.map(renderEvents);
};

export default withRouter(EventsSearchTableRow);
