import React, { memo } from 'react';

export const Cricket = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Cricket ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140.92 141"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <path
          className={'Handle'}
          d="M46,109.6a8.43,8.43,0,0,0-6.6,2.45L11.3,140.2a2.7,2.7,0,0,1-3.83,0L.79,133.53a2.71,2.71,0,0,1,0-3.83l28.15-28.15a8.49,8.49,0,0,0,2.49-6,4.69,4.69,0,0,0,0-.6Z"
        />
        <path
          className={'Body'}
          d="M138.5,37.41,63.81,112.05a8.77,8.77,0,0,1-12.37,0,8.45,8.45,0,0,0-5.37-2.47L31.4,95a8.23,8.23,0,0,0-2.47-5.42,8.76,8.76,0,0,1,0-12.38L76.19,29.92a20.19,20.19,0,0,0,14.22,5.86A20.37,20.37,0,0,0,105.08,1.3c3.26-2.11,7.4-1.59,10.92,1.19,3.13,2.51,9.29,7.57,12.11,10.39A135.85,135.85,0,0,1,138.5,25C141.54,29.17,141.89,34,138.5,37.41Z"
        />
        <path
          className={'Guard'}
          d="M65.74,110.14l-1.92,1.92a8.74,8.74,0,0,1-12.38,0A8.35,8.35,0,0,0,46,109.6L31.4,95a8.43,8.43,0,0,0-2.46-5.4,8.76,8.76,0,0,1,0-12.39l1.92-1.92Z"
        />
        <circle className={'Ball'} cx="90.43" cy="15.43" r="12.93" />
      </g>
    </svg>
  );
};

export default memo(Cricket);
