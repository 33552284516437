import axiosInstance from '../axios-instance';
import { actionCreator, createRequestTypes } from '../index';
import { addNotificationMessage } from '../ui';

export const GET_MARKET_CATEGORIES = createRequestTypes(
  'GET_MARKET_CATEGORIES'
);
export const GET_ALL_MARKETS = createRequestTypes('GET_ALL_MARKETS');
export const GET_MARKET_TYPES = createRequestTypes('GET_MARKET_TYPES');
export const GET_OUTCOMES = createRequestTypes('GET_OUTCOMES');
export const GET_ALL_MARKET_TYPES = createRequestTypes('GET_ALL_MARKET_TYPES');
export const GET_ADMIN_MARKET_TYPE = createRequestTypes(
  'GET_ADMIN_MARKET_TYPE'
);

export const getMarkets = sportEventId => async dispatch => {
  dispatch(actionCreator.request(GET_ALL_MARKETS));
  try {
    const res = await axiosInstance.get('/market/view/market/admin', {
      params: {
        sportEventId,
      },
    });
    dispatch(actionCreator.success(GET_ALL_MARKETS, res));
    return res;
  } catch (err) {
    dispatch(actionCreator.failure(GET_ALL_MARKETS, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getCategories = bu => async dispatch => {
  try {
    let res = await axiosInstance.get(
      `/market/view/market-type-category/admin?businessUnit=${bu}`
    );
    if (res.length === 0) {
      res = await axiosInstance.get(`/market/view/market-type-category/admin`);
    }
    dispatch(actionCreator.success(GET_MARKET_CATEGORIES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_MARKET_CATEGORIES, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getMarketTypeDetails = (categoryId, bu) => async dispatch => {
  dispatch(actionCreator.request(GET_MARKET_TYPES));
  try {
    const res = await axiosInstance.get(
      `/market/view/market-type/admin?businessUnit=${bu}&categoryId=${categoryId}`
    );
    dispatch(actionCreator.success(GET_MARKET_TYPES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_MARKET_TYPES, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const addMarketCategory = (
  marketCategory,
  isEditMode
) => async dispatch => {
  if (!isEditMode) {
    try {
      await axiosInstance.post(
        '/market/view/market-type-category/admin',
        marketCategory
      );
      dispatch(getCategories());
    } catch (err) {
      dispatch(actionCreator.failure(GET_MARKET_CATEGORIES, ''));
      dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    }
  } else {
    try {
      await axiosInstance.post(
        `/market/view/market-type-category/admin/${marketCategory.id}`,
        marketCategory
      );
      dispatch(getCategories());
    } catch (err) {
      dispatch(actionCreator.failure(GET_MARKET_CATEGORIES, ''));
      dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    }
  }
};

export const getMarketTypes = () => async dispatch => {
  try {
    const res = await axiosInstance.get('/market/view/market-type/admin');
    dispatch(actionCreator.success(GET_ALL_MARKET_TYPES, res));
  } catch (err) {
    dispatch(actionCreator.success(GET_ALL_MARKET_TYPES, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const addMarketType = marketType => async dispatch => {
  try {
    await axiosInstance.post('/market/market-type/', marketType);
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const deleteMarketCategory = id => async dispatch => {
  try {
    await axiosInstance.delete(`/market/view/market-type-category/admin/${id}`);
    dispatch(getCategories());
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateMarketType = (mt, marketCatId, bu) => async dispatch => {
  try {
    await axiosInstance.post(`/market/view/market-type/admin/${mt.id}`, mt);
    dispatch(
      addNotificationMessage(
        'Succesfully updated market type',
        'success',
        'Success'
      )
    );
    dispatch(getMarketTypeDetails(marketCatId, bu));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getAdminMarketType = id => async dispatch => {
  try {
    const res = await axiosInstance.get(`/market/view/market-type/admin/${id}`);
    dispatch(actionCreator.success(GET_ADMIN_MARKET_TYPE, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_ADMIN_MARKET_TYPE, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateMarketCategory = marketCat => async dispatch => {
  try {
    await axiosInstance.post(
      `/market/view/market-type-category/admin/`,
      marketCat
    );
    dispatch(
      addNotificationMessage(
        'Succesfully updated market category',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};
