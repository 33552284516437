import React from 'react';

const IconStarHollow = ({
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 14.435 13.903"
      {...otherProps}
    >
      <g transform="translate(0.705 0.7)">
        <g
          transform="translate(846.705 -416.476)"
          opacity="0.63"
        >
          <path
            d="M-844.249,428.857c.118-.69.226-1.333.337-1.975q.192-1.114.388-2.228a.356.356,0,0,0-.131-.341q-1.394-1.353-2.782-2.713c-.068-.067-.14-.13-.2-.2-.112-.126-.076-.228.091-.252q1.421-.209,2.843-.412c.417-.06.833-.129,1.251-.179a.426.426,0,0,0,.362-.276c.582-1.193,1.172-2.381,1.759-3.571.021-.043.039-.088.064-.128.086-.139.177-.141.263-.007a1.255,1.255,0,0,1,.064.127c.581,1.178,1.168,2.353,1.739,3.535a.534.534,0,0,0,.458.333q1.978.276,3.952.573c.078.012.152.263.094.32q-1.416,1.39-2.841,2.77a.54.54,0,0,0-.183.552c.238,1.316.454,2.636.674,3.956.011.065,0,.182-.029.195a.3.3,0,0,1-.222-.016c-.84-.436-1.676-.878-2.513-1.318-.361-.19-.728-.372-1.082-.574a.432.432,0,0,0-.471,0c-1.165.622-2.335,1.233-3.5,1.847a1.1,1.1,0,0,1-.182.092C-844.187,429-844.241,428.933-844.249,428.857Z"
            transform="translate(0 0)"
            fill="none"
            stroke="#fff"
            stroke-miterlimit="10"
            stroke-width="1.4"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconStarHollow;