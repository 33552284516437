import React, { memo } from 'react';

export const Chess = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Chess ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 99.7 141"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <path
          className={'Figure'}
          d="M4.69,120.35H95A4.69,4.69,0,0,1,99.7,125v16a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V125A4.69,4.69,0,0,1,4.69,120.35Z"
        />
        <rect
          className={'Figure'}
          x="27.22"
          y="107.15"
          width="45.26"
          height="13.2"
        />
        <path
          className={'Figure'}
          d="M49.85,0l3.93,7.18L3.19,39.83,0,53.29,8.17,63.51h11l5.79-7.73,16.9-1S8.42,82.24,8.92,107.15H89.68S124.93,19.74,49.85,0Z"
        />
      </g>
    </svg>
  );
};

export default memo(Chess);
