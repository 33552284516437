import React, { useMemo, useCallback } from 'react';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Layout/Button';
import PropTypes from 'prop-types';
import { selectStyles } from '../../customReactSelectStyle';
import Select from 'react-select';
import { useStateForSelect } from '../../../../hooks';

const MarketTypeForm = ({
  isActiveModal,
  closeModal,
  assignMarketType,
  allMarketTypes,
}) => {
  const [marketType, setMarketType] = useStateForSelect('');

  const marketTypes = useMemo(() => {
    const marketTypesClone = [...allMarketTypes];
    return (
      marketTypesClone &&
      marketTypesClone.map(mt => {
        return {
          value: mt,
          label: mt.descriptionTemplates.en.name,
        };
      })
    );
  }, [allMarketTypes]);

  const closeModalAndAssign = useCallback(() => {
    assignMarketType(marketType.value);
    closeModal();
  }, [assignMarketType, closeModal, marketType.value]);

  return (
    <Modal
      isActive={isActiveModal}
      myClassName="marketTypeSelector"
      onClose={closeModal}
      headerText="Add new market type"
    >
      <div className="efb-modal__content">
        <div className="form-row">
          <div className="form-label-group col-md">
            <Select
              value={marketType}
              options={marketTypes}
              onChange={setMarketType}
              placeholder="Select Market Type to assign"
              styles={selectStyles}
            />
          </div>
        </div>
        <div className="form-row">
          <div>
            <Button cssClass="efb-btn__main" onClick={closeModalAndAssign}>
              Assign market type to this market category
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

MarketTypeForm.propTypes = {
  allMarketTypes: PropTypes.array,
  assignMarketType: PropTypes.func,
  closeModal: PropTypes.func,
  isActiveModal: PropTypes.bool,
  updateAssignedMarketType: PropTypes.func,
};

export default MarketTypeForm;
