import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { getUserRolesRoutePermisions } from '../../../redux/actions/rolesApi';
import { getRiskManagementData, getWsData } from '../../../selectors';
import { useMount, useUpdate } from '../../../hooks';
import BetScrollerManager from './BetScrollerManager';
import * as UiActions from '../../../redux/actions/ui';
import * as BetScrollerActions from '../../../redux/actions/riskManagement/betScroller';

const BetScroller = ({
  getBetScrollers,
  betScrollers,
  betScrollerId,
  betScrollerData,
  setBetscrollerId,
  addWsBinding,
  wsConnectionStatus,
  wsFrame,
  addNotificationMessage,
}) => {
  const [ownId, setOwnId] = useState('');

  useMount(() => {
    getBetScrollers();
    getUserId();
  });

  useUpdate(async () => {
    await wsConnection();
    await setBSScrollerId();
  }, [betScrollerId, betScrollers, wsConnectionStatus]);

  const getUserId = useCallback(() => {
    getUserRolesRoutePermisions().then(user => {
      setOwnId(user.id);
    });
  }, []);

  const wsConnection = useCallback(() => {
    if (wsConnectionStatus && betScrollerId) {
      addWsBinding(betScrollerId, wsFrame.headers['session']);
    }
  }, [addWsBinding, betScrollerId, wsConnectionStatus, wsFrame.headers]);

  const setBSScrollerId = useCallback(() => {
    if (betScrollers.length > 0) {
      const myBetScroller = betScrollers.find(bs => bs.createdUserId === ownId);
      if (myBetScroller) {
        setBetscrollerId(myBetScroller.id);
      }
    }
  }, [betScrollers, setBetscrollerId, ownId]);

  return (
    <BetScrollerManager
      ownId={ownId}
      betScrollers={betScrollers}
      betScrollerData={betScrollerData}
      addNotificationMessage={addNotificationMessage}
    />
  );
};

const mapStateToProps = state => ({
  betScrollers: getRiskManagementData(state).betScrollerReducer.betScrollers,
  betScrollerId: getRiskManagementData(state).betScrollerReducer.betScrollerId,
  betScrollerData: getRiskManagementData(state).betScrollerReducer
    .betScrollerData,
  wsConnectionStatus: getWsData(state).wsConnectionStatus,
  wsFrame: getWsData(state).wsFrame,
});

const mapDispatchToProps = {
  getBetScrollers: BetScrollerActions.getBetScrollers,
  setBetscrollerId: BetScrollerActions.setBetscrollerId,
  addWsBinding: BetScrollerActions.addWsBinding,
  addNotificationMessage: UiActions.addNotificationMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BetScroller);
