import {
  GET_COEFFICIENTS,
  GET_INPLAY_MODELS,
  GET_PREMATCH_MODELS,
  GET_TIMES,
  GET_MULTIPLE_COEFFICIENTS,
  GET_MULTIPLE_MODELS,
  GET_MULTIPLE_TIMES,
  GET_PREMATCH_MULTIPLE_MODELS,
  GET_PREMATCH_MULTIPLE_STATIC_MODELS,
  GET_SYSTEM_STATIC_PARAMETERS,
  TEST_CASHOUT,
} from '../../actions/riskManagement/cashoutManager';

const initialState = {
  coefficients: [],
  times: [],
  modelsInplay: [],
  modelsPrematch: [],
  multipleCoefficients: [],
  multipleModels: [],
  multipleTimes: [],
  prematchMultipleModels: [],
  prematchMultipleStaticModels: [],
  systemStaticParameters: [],
  formulaResult: null,
};

export const cashoutManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEST_CASHOUT.SUCCESS:
      return {
        ...state,
        formulaResult: action.payload,
      };
    case TEST_CASHOUT.EMPTY:
      return {
        ...state,
        formulaResult: null,
      };
    case GET_COEFFICIENTS.SUCCESS:
      return {
        ...state,
        coefficients: action.payload,
      };
    case GET_TIMES.SUCCESS:
      return {
        ...state,
        times: action.payload,
      };
    case GET_INPLAY_MODELS.SUCCESS:
      return {
        ...state,
        modelsInplay: action.payload,
      };
    case GET_PREMATCH_MODELS.SUCCESS:
      return {
        ...state,
        modelsPrematch: action.payload,
      };
    case GET_MULTIPLE_COEFFICIENTS.SUCCESS:
      return {
        ...state,
        multipleCoefficients: action.payload,
      };
    case GET_MULTIPLE_MODELS.SUCCESS:
      return {
        ...state,
        multipleModels: action.payload,
      };
    case GET_MULTIPLE_TIMES.SUCCESS:
      return {
        ...state,
        multipleTimes: action.payload,
      };
    case GET_PREMATCH_MULTIPLE_MODELS.SUCCESS:
      return {
        ...state,
        prematchMultipleModels: action.payload,
      };
    case GET_PREMATCH_MULTIPLE_STATIC_MODELS.SUCCESS:
      return {
        ...state,
        prematchMultipleStaticModels: action.payload,
      };
    case GET_SYSTEM_STATIC_PARAMETERS.SUCCESS:
      return {
        ...state,
        systemStaticParameters: action.payload,
      };
    default:
      return state;
  }
};
