import * as CV from '../actions/CustomerValidation';

const initialState = {
  types: [],
  documentTypes: [],
  paymentMethods: [],
  allDocuments: [],
  pendingUsers: [],
  allDocumentsTypes: []
};

export const CustomerValidationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CV.GET_DOCUMENT_TYPES.SUCCESS:
      return {
        ...state,
        documentTypes: action.payload
      };

    case CV.GET_TYPES.SUCCESS:
      return {
        ...state,
        types: action.payload
      };

    case CV.GET_PAYMENT_METHODS.SUCCESS:
      return {
        ...state,
        paymentMethods: action.payload
      };

    case CV.AFTER_VERIFICATION.SUCCESS:
      return {
        ...state,
        allDocuments: action.payload
      };

    case CV.AFTER_VERIFICATION.EMPTY:
      return {
        ...state,
        allDocuments: []
      };

    case CV.GET_PENDING_USERS.SUCCESS:
      return {
        ...state,
        pendingUsers: action.payload
      };

    case CV.GET_ALL_DOCUMENTS.SUCCESS:
      return {
        ...state,
        allDocumentsTypes: action.payload
      };

    default:
      return state;
  }
};