import React, { useState, useMemo } from 'react';
import moment from 'moment';
import classes from './TransactionHistoryModal.module.scss';
import Modal from '../../../../components/NewModal/Modal';
import HistoryModal from '../../../../components/HistoryModal/HistoryModal';
import SettlementWrapper from './SettlementWrapper';
import Header from '../../../RiskManagement/BetHistory/Header';
import { useFetch } from '../../../../hooks';

const TransactionHistoryModal = () => {
  const columns = useMemo(
    () => [
      { name: 'Id', display: transaction => transaction.transactionId },
      {
        name: 'Request Time',
        display: transaction =>
          transaction.createdAt
            ? moment(transaction.createdAt).format('DD/MM/YYYY HH:mm:ss')
            : '',
      },
      { name: 'Inst. Type', display: transaction => transaction.type },
      { name: 'Tx. State', display: transaction => transaction.status },
      {
        name: 'Amount',
        display: transaction => Number(transaction.amount / 100).toFixed(2),
      },
    ],
    []
  );

  const paymentOptions = [
    { value: 'All', label: 'All' },
    { value: 'Receipt', label: 'Receipt' },
    { value: 'Payment', label: 'Payment' },
  ];

  const deviceOptions = [
    { value: 'MOBILE', label: 'Mobile' },
    { value: 'ALL', label: 'All' },
    { value: 'WEB', label: 'Web' },
  ];

  const stateOptions = [
    { value: 'Ready for notifi...', label: 'Ready for notifi...' },
    { value: 'In redirection.', label: 'In redirection' },
    { value: 'Notication in p...', label: 'Notication in p...' },
    { value: 'In redirection..', label: 'In redirection' },
    { value: 'Ready for redire...', label: 'Ready for redire...' },
    { value: 'In redirection...', label: 'In redirection' },
  ];

  const filtersConfig = useMemo(
    () => [
      {
        name: 'txType',
        label: 'TX TYPE',
        type: 'radios',
        withBorders: true,
        options: paymentOptions,
        default: paymentOptions[0],
      },
      {
        label: 'REQUESTED',
        type: 'dates',
        fields: [
          {
            name: 'dateFrom',
            label: 'From',
            default: moment(),
          },
          {
            name: 'dateTo',
            label: 'To',
            default: moment(),
          },
        ],
      },
      {
        label: 'TX Amount',
        type: 'inputs',
        fields: [
          {
            name: 'amountFrom',
          },
          {
            name: 'amountTo',
          },
        ],
      },
      {
        label: 'TX STATE',
        type: 'checkbox',
        name: 'txState',
        options: stateOptions,
      },
      {
        name: 'externalAccountId',
        label: 'EXT. ACC. NO',
        type: 'input',
      },
      {
        name: 'channel',
        label: 'CHANNEL',
        type: 'select',
        options: deviceOptions,
      },
    ],
    [deviceOptions, paymentOptions, stateOptions]
  );

  const [filters, setFilter] = useState({});

  const customerId = new URLSearchParams(window.location.search).get(
    'transactionHistoryCustomerId'
  );

  const { data: customer } = useFetch(`customer/view/admin/${customerId}`);

  const accountId = useMemo(
    () =>
      customer && customer.accounts.find(acc => acc.accountType === 'MAIN').id,
    [customer]
  );

  const transactionHistoryObject = useMemo(
    () => ({
      accountId,
      ...(filters.externalAccountId
        ? { externalAccountId: filters.externalAccountId }
        : {}),
      dateFrom: filters.dateFrom
        ? moment(filters.dateFrom)
            .startOf('day')
            .utc(true)
            .toISOString()
        : '',
      dateTo: filters.dateTo
        ? moment(filters.dateTo)
            .endOf('day')
            .utc(true)
            .toISOString()
        : '',
      amountFrom: filters.amountFrom ? filters.amountFrom * 100 : '',
      amountTo: filters.amountTo ? filters.amountTo * 100 : '',
      channel: filters.channel || '',
      pageNumber: 1,
      pageSize: 10000,
    }),
    [accountId, filters]
  );

  const { data: transactionHistory } = useFetch(
    `/account/view/admin/transactions?&${new URLSearchParams(
      transactionHistoryObject
    ).toString()}`
  );

  return (
    <Modal className={classes.wrapper} showCloseBtn={false} isActive>
      <HistoryModal
        columns={columns}
        history={transactionHistory ? transactionHistory.content : []}
        filtersConfig={filtersConfig}
        onFiltersChange={setFilter}
        openMenu={true}
        headerTitle={
          <Header
            customerId={customerId}
            title={'Settlement Transaction History'}
          />
        }
      />
      <SettlementWrapper />
    </Modal>
  );
};

export default React.memo(TransactionHistoryModal);
