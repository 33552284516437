import axiosInstance from '../axios-instance';
import { actionCreator, createRequestTypes } from '../index';
import { addNotificationMessage } from '../ui';

export const SET_CUSTOMER_MANAGER = createRequestTypes('SET_CUSTOMER_MANAGER'),
  SET_CUSTOMER_ACCOUNTS = createRequestTypes('SET_CUSTOMER_ACCOUNTS'),
  GET_CUSTOMER_PREFERENCES = createRequestTypes('GET_CUSTOMER_PREFERENCES'),
  SET_CUSTOMER_TRANSACTIONS = createRequestTypes('SET_CUSTOMER_TRANSACTIONS'),
  SET_CUSTOMER_BETTING_PERFORMANCE = createRequestTypes(
    'SET_CUSTOMER_BETTING_PERFORMANCE'
  ),
  SET_CUSTOMER_BETSLIP = createRequestTypes('SET_CUSTOMER_BETSLIP'),
  FILTERED_CUSTOMERS = createRequestTypes('FILTERED_CUSTOMERS'),
  RESET_CUSTOMER_MANAGER = 'RESET_CUSTOMER_MANAGER',
  ADD_CUSTOMER_FILTERS = createRequestTypes('ADD_CUSTOMER_FILTERS'),
  EMPTY_CUSTOMER_FILTERS = createRequestTypes('EMPTY_CUSTOMER_FILTERS'),
  CUSTOMER_AW_STATUS = createRequestTypes('CUSTOMER_AW_STATUS');

export const getCustomersWithFilters = (
  filtersBody,
  pageNumber = 1
) => async dispatch => {
  let copyFiltersBody = { ...filtersBody };
  copyFiltersBody['pageNumber'] = pageNumber; // hardcoded
  copyFiltersBody['pageSize'] = 20; // hardcoded
  dispatch(actionCreator.request(FILTERED_CUSTOMERS));
  try {
    const response = await axiosInstance.post(
      '/customer/view/admin',
      copyFiltersBody
    );
    dispatch(actionCreator.success(FILTERED_CUSTOMERS, response));
    return response;
  } catch (err) {
    dispatch(
      addNotificationMessage(err.message || err.error, 'error', 'Error!')
    );
    dispatch(actionCreator.failure(FILTERED_CUSTOMERS, ''));
    throw err;
  }
};

export const getCustomerPreferences = customerId => async dispatch => {
  dispatch(actionCreator.request(GET_CUSTOMER_PREFERENCES));
  try {
    const res = await axiosInstance.get(
      `customer/view/admin/${customerId}/preferences`
    );
    dispatch(actionCreator.success(GET_CUSTOMER_PREFERENCES, res));
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    dispatch(actionCreator.failure(GET_CUSTOMER_PREFERENCES, error.message));
  }
};

export const getCustomerNotes = customerId => async dispatch => {
  let res;
  try {
    res = await axiosInstance.get(`customer/view/admin/${customerId}/note`);
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }

  return res;
};

export const addCustomerNote = (note, customerId) => async dispatch => {
  try {
    await axiosInstance.post(`customer/admin/${customerId}/note`, note);
    dispatch(
      addNotificationMessage(
        'You have successfully added note',
        'success',
        'Success'
      )
    );
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getCustomerById = filtersBody => {
  let copyFiltersBody = { ...filtersBody };

  copyFiltersBody['pageNumber'] = 1; // hardcoded
  copyFiltersBody['pageSize'] = 1; // hardcodeds

  return dispatch => {
    dispatch(actionCreator.request(SET_CUSTOMER_MANAGER));
    axiosInstance
      .post('/customer/view/admin', copyFiltersBody)
      .then(response => {
        let customer = response.content[0];
        return dispatch(actionCreator.success(SET_CUSTOMER_MANAGER, customer));
      })
      .catch(error => {
        console.log('get customer by id error: ');
        return dispatch(actionCreator.failure(SET_CUSTOMER_MANAGER, ''));
      });
  };
};

export const getAccountsByCustomer = customerId => {
  return dispatch => {
    axiosInstance
      .get(`/account/view/admin/accounts/${customerId}`)
      .then(response => {
        return dispatch(actionCreator.success(SET_CUSTOMER_ACCOUNTS, response));
      })
      .catch(error => {
        console.log('get accounts error ', error);
      });
  };
};
export const getCustomerTransactions = customerId => {
  return dispatch => {
    axiosInstance
      .get(`/account/view/admin/customer/${customerId}`)
      .then(response => {
        return dispatch(
          actionCreator.success(SET_CUSTOMER_TRANSACTIONS, response)
        );
      })
      .catch(error => {
        console.log('get customer transactions error ', error);
      });
  };
};

export const getCustomerBettingPerformance = customerId => {
  return dispatch => {
    axiosInstance
      .get(`/betslip/view/admin/betting/performance/${customerId}`)
      .then(response => {
        return dispatch(
          actionCreator.success(SET_CUSTOMER_BETTING_PERFORMANCE, response)
        );
      })
      .catch(error => {
        console.log('caught error', error);
      });
  };
};

export const getCustomerBetslipRecord = customerId => {
  return dispatch => {
    axiosInstance
      .get(`/betslip/view/admin/customer/${customerId}`)
      .then(response => {
        return dispatch(actionCreator.success(SET_CUSTOMER_BETSLIP, response));
      })
      .catch(error => {
        console.log('get customer betslip record error: ', error);
      });
  };
};

// Send notification from personal data component

export const setCustomerManagerUpdate = customerObj => {
  return dispatch => {
    dispatch(actionCreator.success(SET_CUSTOMER_MANAGER, customerObj));
  };
};

export const emptyCustomers = () => {
  return dispatch => {
    dispatch(actionCreator.empty(FILTERED_CUSTOMERS));
  };
};

export const setCustomerAccountsUpdate = customerAccounts => {
  return dispatch => {
    dispatch(actionCreator.success(SET_CUSTOMER_ACCOUNTS, customerAccounts));
  };
};

export const setCustomerTransactionsUpdate = customerTransactions => {
  return dispatch => {
    dispatch(
      actionCreator.success(SET_CUSTOMER_TRANSACTIONS, customerTransactions)
    );
  };
};

export const setFilteredCustomers = customers => {
  return dispatch => {
    dispatch(actionCreator.success(FILTERED_CUSTOMERS, customers));
  };
};

export const resetCustomerManager = () => {
  return dispatch => {
    dispatch({ type: RESET_CUSTOMER_MANAGER });
  };
};

export const addCustomerFilters = filters => {
  return dispatch => {
    dispatch({ type: ADD_CUSTOMER_FILTERS, payload: filters });
  };
};

export const emptyCustomerFilters = () => {
  return dispatch => {
    dispatch({ type: EMPTY_CUSTOMER_FILTERS });
  };
};

export const getCustomerAWStatus = customerId => async dispatch => {
  dispatch(actionCreator.request(CUSTOMER_AW_STATUS));
  try {
    const res = await axiosInstance.get(
      `/customer/admin/${customerId}/global-status`
    );
    dispatch(actionCreator.success(CUSTOMER_AW_STATUS, res));
  } catch (err) {
    dispatch(actionCreator.failure(CUSTOMER_AW_STATUS, err.message));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateCustomerAW = (data, customerId) => async dispatch => {
  try {
    await axiosInstance.post(
      `/customer/admin/auto-withdraw/${customerId}`,
      data
    );
    dispatch(
      addNotificationMessage(
        'Successfully updated customer Auto Withdraw settings',
        'success',
        'Success'
      )
    );
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const updateCustomerDocument = data => async dispatch => {
  try {
    await axiosInstance.post(
      `customer/view/admin/${data.customerId}/documents`,
      data
    );
    dispatch(
      addNotificationMessage(
        'Successfully updated customer document',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateDepositLimit = limits => async dispatch => {
  try {
    await axiosInstance.post(`/account/admin/deposit/limit`, limits);
    dispatch(
      addNotificationMessage(
        'Successfully updated customer deposit limits',
        'success',
        'Success'
      )
    );
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(errorCode => {
        dispatch(
          addNotificationMessage(err.errors[errorCode], 'error', 'Error')
        );
      });
    }
  }
};

export const updateSelfRestrictions = restriction => async dispatch => {
  try {
    await axiosInstance.post(
      `/customer/admin/section-restrictions`,
      restriction
    );
    dispatch(
      addNotificationMessage(
        'Successfully updated customer self restriction',
        'success',
        'Success'
      )
    );
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(errorCode => {
        dispatch(
          addNotificationMessage(err.errors[errorCode], 'error', 'Error')
        );
      });
    }
  }
};

export const createConfigRestrictionPerBsUnit = ({
  id,
  businessUnit,
  active,
}) => async dispatch => {
  try {
    await axiosInstance.post(
      `/customer/view/admin/section/restrictions/config`,
      {
        businessUnit,
        active,
      }
    );
    dispatch(
      addNotificationMessage(
        `Successfully ${id ? 'updated' : 'created'} customer self restriction`,
        'success',
        'Success'
      )
    );
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(errorCode => {
        dispatch(
          addNotificationMessage(err.errors[errorCode], 'error', 'Error')
        );
      });
    }
  }
};
