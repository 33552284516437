import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../components/Table/Table';

const columns = [
  { name: 'Sport', value: 'sport', type: 'string' },
  { name: 'Event', value: 'event', type: 'string' },
  { name: 'Market', value: 'market', type: 'string' },
  { name: 'Selection', value: 'selection', type: 'string' },
  { name: 'Odds', value: 'odds', type: 'string' },
  { name: 'Curr. Odds', value: 'current', type: 'string' },
  { name: 'Exp. Time', value: 'priceChangeExpired', type: 'date' },
  { name: 'Changed by', value: '', type: 'string' },
];

const SelectionsTable = ({
  selections,
  oddChanges,
  setOddChanges,
  priceChanges,
  setPriceChanges,
  changedOdds,
  prepareSelectionForEdit,
  types,
}) => {
  return (
    <div className="referal-table-wrapper">
      <Table
        data={selections}
        columns={columns}
        oddChanges={oddChanges}
        setOddChanges={setOddChanges}
        priceChanges={priceChanges}
        setPriceChanges={setPriceChanges}
        changedOdds={changedOdds}
        types={types}
        prepareSelectionForEdit={prepareSelectionForEdit}
        tableStyle="table table-hover referral-details-table"
        tableType="selectionsTableNew"
        pagination={false}
        search={false}
      />
    </div>
  );
};

SelectionsTable.propTypes = {
  selections: PropTypes.array.isRequired,
  oddChanges: PropTypes.object.isRequired,
  setOddChanges: PropTypes.func.isRequired,
  priceChanges: PropTypes.array.isRequired,
  setPriceChanges: PropTypes.func.isRequired,
  changedOdds: PropTypes.object.isRequired,
};

export default SelectionsTable;
