import { combineReducers } from 'redux';

import { configurationReducer } from './configuration';
import { systemSettingsReducer } from './systemSettings';
import { betHistoryReducer } from './betHistory';
import { betScrollerReducer } from './betScroller';
import { riskCategoryReducer } from './RiskCategoryReducer';
import { referralConsoleReducer } from './ReferralConsoleReducer';
import { userSettingsReducer } from './UserSettingsReducer';
import {settlementsReducer} from './settlementsReducer';

export const riskManagementReducer = combineReducers({
  configurationReducer,
  systemSettingsReducer,
  betHistoryReducer,
  betScrollerReducer,
  riskCategoryReducer,
  referralConsoleReducer,
  userSettingsReducer,
  settlementsReducer
});
