import React, { Component } from 'react';
import Button from '../../../../components/Layout/Button';
import Modal from '../../../../components/Modal/Modal';
import InputWithLabel from '../../../../components/Form/InputWithLabel/InputWithLabel';
import PropTypes from 'prop-types';

class RolesFiltersModal extends Component {
  getFilteredRoles = () => {
    this.props.getAllRoles();
    this.props.toggleRolesFilter(false);
  };

  handleUserInput = e => {
    const name = e.target.name,
      value = e.target.value;
    let filtersChange = Object.assign({}, this.props.filters);
    filtersChange[name] = value;
    this.props.setRolesFilters(filtersChange);
  };

  render() {
    let { isModalOpen, toggleRolesFilter } = this.props;
    return (
      <Modal
        isActive={isModalOpen}
        onClose={() => toggleRolesFilter(false)}
        headerText="Roles Filter"
      >
        <div className="efb-modal__content">
          <InputWithLabel
            label="Role:"
            type="text"
            onChange={event => this.handleUserInput(event)}
            value={this.props.filters.role}
            name="role"
          />
        </div>
        <footer className="efb-modal__footer">
          <Button onClick={this.clearAllFilters} cssClass="efb-btn__link">
            Clear All
          </Button>
          <Button onClick={this.getFilteredRoles} cssClass="efb-btn__main">
            Apply
          </Button>
        </footer>
      </Modal>
    );
  }
}

RolesFiltersModal.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleRolesFilter: PropTypes.func,
  getAllRoles: PropTypes.func,
  setRolesFilters: PropTypes.func,
  filters: PropTypes.object,
};

export default RolesFiltersModal;
