import React from 'react';

const IconBurgerMenu = ({
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 18 10"
      {...otherProps}
    >
      <g
        fill="#fff"
        stroke="#707070"
        strokeWidth="1"
      >
        <rect
          width="18"
          height="2"
          rx="1"
          stroke="none"
        />
        <rect
          x="0.5"
          y="0.5"
          width="17"
          height="1"
          rx="0.5"
          fill="none"
        />
      </g>
      <g transform="translate(-6 -13)">
        <g
          transform="translate(6 17)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
        >
          <rect
            width="18"
            height="2"
            rx="1"
            stroke="none"
          />
          <rect
            x="0.5"
            y="0.5"
            width="17"
            height="1"
            rx="0.5"
            fill="none"
          />
        </g>
        <g
          transform="translate(6 21)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
        >
          <rect
            width="18"
            height="2"
            rx="1"
            stroke="none"
          />
          <rect
            x="0.5"
            y="0.5"
            width="17"
            height="1"
            rx="0.5"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconBurgerMenu;