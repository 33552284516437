import React, { Fragment } from 'react';
import GamePlatform from './GamePlatform';
import GameName from './GameName';
import GameType from './GameType';
import GameUrl from './GameUrl';
import GameBu from './GameBu';
import GameEditImages from './GameEditImages';
import Button from '../../../../components/Layout/Button';
import PropTypes from 'prop-types';

const CasinoManagerForm = ({
  languages,
  casinoGameObj,
  updateChanges,
  submitForm,
  editMode,
  cancelEdit,
  gameProviders,
  deleteImage,
  finishUploadingImage,
}) => {
  return (
    <Fragment>
      <br />
      <form>
        <GamePlatform
          casinoGameObj={casinoGameObj}
          updateChanges={updateChanges}
          editMode={editMode}
        />
        <hr />
        <GameName
          casinoGameObj={casinoGameObj}
          languages={languages}
          updateChanges={updateChanges}
          editMode={editMode}
        />

        <GameBu
          casinoGameObj={casinoGameObj}
          updateChanges={updateChanges}
          editMode={editMode}
        />
        <hr />
        <GameType
          casinoGameObj={casinoGameObj}
          updateChanges={updateChanges}
          gameProviders={gameProviders}
          editMode={editMode}
        />
        <hr />
        <GameUrl
          casinoGameObj={casinoGameObj}
          updateChanges={updateChanges}
          editMode={editMode}
        />
        {editMode && casinoGameObj.images && casinoGameObj.images.length > 0 ? (
          <GameEditImages
            casinoGameObj={casinoGameObj}
            deleteImage={deleteImage}
            updateChanges={updateChanges}
            finishUploadingImage={finishUploadingImage}
          />
        ) : null}
        <hr />
        {!editMode ? (
          <Button cssClass="efb-btn__main" onClick={submitForm}>
            Submit
          </Button>
        ) : (
          <Fragment>
            <div className="form-group d-flex flex-row justify-content-start">
              <Button cssClass="efb-btn__main" onClick={submitForm}>
                Update
              </Button>
              <Button cssClass="efb-btn__main" onClick={cancelEdit}>
                Cancel
              </Button>
            </div>
          </Fragment>
        )}
      </form>
    </Fragment>
  );
};

CasinoManagerForm.propTypes = {
  casinoGameObj: PropTypes.object,
  getImages: PropTypes.func,
  languages: PropTypes.array,
  loading: PropTypes.bool,
  submitForm: PropTypes.func,
  updateChanges: PropTypes.func,
};

export default CasinoManagerForm;
