import React from 'react';
import { useFetch } from '../../../../hooks';
import classes from './CustomerDetails.module.scss';

const CustomerTotalCalculations = ({ customerId }) => {
  const { data: accountStats } = useFetch(
    'account/view/admin/quick-stats',
    {
      customerId,
    },
    [customerId]
  );

  if (!accountStats) return null;

  return (
    <div className="col-md-4">
      <div className={classes.header}>
        <p>Total</p>
      </div>
      <div
        className={`${classes.body} ${classes.bodyPadding} ${classes.flexSpaceBetween}`}
      >
        <div>
          <p>Aff. Code</p>
          <p>{accountStats.affiliateCode}</p>
        </div>
        <div>
          <p>Avg. Deposit</p>
          <p>{Number(accountStats.averageDeposit).toFixed(2)}</p>
        </div>
        <div>
          <p>Last 3 Deposit Amounts</p>
          <p>
            {accountStats.lastThreeDeposit.length
              ? accountStats.lastThreeDeposit.concat('/')
              : ''}
          </p>
        </div>
        <div>
          <p>Deposit Count</p>
          <p>{accountStats.depositsCount}</p>
        </div>
        <div>
          <p>Withdrawals counts</p>
          <p>{accountStats.withdrawalsCount}</p>
        </div>
        <br />
        <div>
          <p>Net Profit (30 days)</p>
          <p className={classes.greenNumber}>
            {accountStats.netProfitLastThirtyDays}
          </p>
        </div>
      </div>
      <div
        className={`${classes.sum} ${classes.sumPadding} ${classes.flexSpaceBetween}`}
      >
        <div>
          <p>Deposits</p>
          <p>{Number(accountStats.deposits).toFixed(2)}</p>
        </div>
        <div>
          <p>Withdrawals</p>
          <p>{Number(accountStats.withdrawals).toFixed(2)}</p>
        </div>
        <div>
          <p>
            <b>Net Profit (total)</b>
          </p>
          <p className={classes.greenNumber}>
            {Number(accountStats.netProfit).toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomerTotalCalculations;
