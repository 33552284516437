import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';

export const transformBetslipToSystems = (betslip, systemReoffer) => {
  let generalInfoBet = omit(betslip, ['bet']);
  const systemBets = [];
  const { SINGLE, ...systems } = groupBy(betslip.bet, bet => bet.type);

  const singleSystems = SINGLE
    ? SINGLE.map(bet => ({
        type: 'SINGLE',
        bets: [bet],
        systemBet: !systemReoffer
          ? bet.bet
          : systemReoffer.reoffer.betSystems[0].bet,
        systemOdds: bet.odds,
        systemTakeout: !systemReoffer
          ? bet.odds * bet.bet
          : systemReoffer.reoffer.betSystems[0].bet * bet.odds,
      }))
    : [];

  systemBets.push(...singleSystems);

  Object.keys(systems).forEach(systemName => {
    const system = systems[systemName];

    let newSystem = {
      type: system[0].type,
      bets: system,
    };

    const systemBetSum = newSystem.bets[0].bet * newSystem.bets.length;
    const systeOddsSum = newSystem.bets.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.odds;
    }, 0);

    newSystem = {
      ...newSystem,
      systemBet: systemBetSum,
      systemOdds: systeOddsSum,
      systemTakeout: systeOddsSum * newSystem.bets[0].bet,
    };

    systemBets.push(newSystem);
  });

  const totalTakeout =
    systemBets.length > 1
      ? systemBets.reduce((a, b) => {
          return a + b.systemTakeout;
        }, 0)
      : systemBets[0].systemTakeout;

  const totalBet =
    systemBets.length > 1
      ? systemBets.reduce((a, b) => {
          return a + b.systemBet;
        }, 0)
      : systemBets[0].systemBet;

  generalInfoBet = {
    ...generalInfoBet,
    totalBet: totalBet,
    totalTakeout: Math.round(totalTakeout, 2),
  };

  return { ...generalInfoBet, systemBets: systemBets };
};

export const orderedSystems = [
  'UNIONJACK_PATENT',
  'UNIONJACK_TRIXIE',
  'UNIONJACK_TREBLE',
  'MAGNIFICENT_SEVEN',
  'PONTOON',
  'FIVESPOT',
  'LUCKY_SEVEN_BINGO',
  'GOLIATH',
  'SUPERHEINZ',
  'LUCKY_63',
  'HEINZ',
  'LUCKY_31',
  'CANADIAN',
  'LUCKY_15',
  'YANKEE',
  'ROUND_ROBIN',
  'PATENT',
  'TRIXIE',
  'ACCOMULATOR',
  'FOURTEEN_FOLDS',
  'THIRTEEN_FOLDS',
  'TWELVE_FOLDS',
  'ELEVEN_FOLDS',
  'TEN_FOLDS',
  'NINE_FOLDS',
  'EIGHT_FOLDS',
  'SEVEN_FOLDS',
  'SIX_FOLDS',
  'FIVE_FOLDS',
  'FOUR_FOLDS',
  'TREBLES',
  'TREBLE',
  'DOUBLES',
  'DOUBLE',
  'SINGLES',
  'SINGLE',
];

export const minPrice = 1.01;
