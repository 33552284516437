import React, { useMemo, useCallback, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import * as CustomerActions from '../../../../../redux/actions/customer/customerManager';
import * as UserSettingsActions from '../../../../../redux/actions/riskManagement/UserSettings';
import * as CashoutManagerActions from '../../../../../redux/actions/riskManagement/cashoutManager';

import classes from './Additional.module.scss';
import Input from '../../../../../components/Form/Input';
import Button from '../../../../../components/Layout/Button';
import Buttons from '../../../../../components/ModalButtons/Buttons';
import Select from 'react-select';
import Table from '../../../../../components/NewTable/Table';
import Switch from '../../../../../components/Form/Switch/Switch';
import IconPen from '../../../../../components/Icons/UI/ui/IconPen';

import {
  useFetch,
  useStateForSelect,
  useStateForSwitch,
  useUpdate,
  useCounter,
} from '../../../../../hooks';
import { underlinedSelectStyles } from '../../../../../utils/selectStyles';

const testData = [{ name: 'Tavira, Singles M-ITF-POR-14A - I...', bf: 1 }];

const Additional = ({
  customer,
  addCustomerNote,
  updateUserSettings,
  createOrUpdateCustomer,
  onClose,
}) => {
  const { data: riskCategories } = useFetch(
    'riskmgnt/view/admin/riskCategories'
  );
  const { data: userSettings } = useFetch(
    `riskmgnt/view/admin/userSettings/findByCustomerId?customerId=${customer.customerId}`
  );
  const { data: notes } = useFetch(
    `customer/view/admin/${customer.customerId}/note`
  );
  const { data: customerConfig, refetch } = useFetch(
    '/cashout/admin/config/customer'
  );

  const [customerNote, setCustomerNote] = useState({ note: '' });
  const [riskCategory, setRiskCategory] = useStateForSelect('');
  const [enableCashout, setEnableCashout, resetEnabled] = useStateForSwitch(
    false
  );
  const betFactor = useCounter(0);
  const betAcceptance = useCounter(0);

  useUpdate(() => {
    betFactor.setValue(userSettings ? userSettings.betFactor : 0.01);
    betAcceptance.setValue(
      userSettings ? userSettings.betAcceptanceGracePeriod : 0
    );
    setRiskCategory(
      userSettings
        ? {
            label: userSettings.riskCategory.name,
            value: userSettings.riskCategory.description,
          }
        : ''
    );
  }, [userSettings]);

  const config = useMemo(() => {
    const config =
      customerConfig &&
      customerConfig.find(conf => conf.customerId === customer.customerId);
    if (config) {
      resetEnabled(config.enabled);
    }
    return config;
    // eslint-disable-next-line
  }, [customer.customerId, customerConfig]);

  const riskCategoriesMapped = useMemo(() => {
    return riskCategories
      ? riskCategories.map(cat => {
          return { label: `${cat.name} - ${cat.description}`, value: cat };
        })
      : null;
  }, [riskCategories]);

  const columns = useMemo(
    () => [
      { name: 'Tournament', display: test => test.name, active: false },
      { name: 'BF', display: test => test.bf, active: false },
    ],
    []
  );

  const addNote = useCallback(
    type => {
      addCustomerNote(
        {
          note: customerNote,
          noteType: type === 'support' ? 'CUSTOMER_SERVICE' : 'TRADER',
        },
        customer.customerId
      );
    },
    [addCustomerNote, customerNote, customer]
  );

  const activateCashout = useCallback(async () => {
    await createOrUpdateCustomer({
      changedDate: moment(),
      createdDate: moment(),
      id: config ? config.id : null,
      customerId: customer.customerId,
      enabled: enableCashout,
    });
    await refetch();
  }, [
    config,
    createOrUpdateCustomer,
    customer.customerId,
    enableCashout,
    refetch,
  ]);

  const saveChanges = useCallback(() => {
    const payload = {
      ...userSettings,
      betFactor: Number(betFactor.value),
      customerId: customer.customerId,
      riskCategory: riskCategory.value,
    };
    if (riskCategory.value) {
      updateUserSettings(payload, userSettings.id);
    }
    activateCashout();
  }, [
    userSettings,
    betFactor,
    customer.customerId,
    riskCategory.value,
    activateCashout,
    updateUserSettings,
  ]);

  return (
    <>
      <div className={classes.additionalWrapper}>
        <div className={classes.formWrapper}>
          <div className={classes.divider}>
            <label>Risk Category: </label>
            <Select
              value={riskCategory}
              options={riskCategoriesMapped ? riskCategoriesMapped : []}
              onChange={setRiskCategory}
              placeholder="Risk Category"
              styles={underlinedSelectStyles}
            />
          </div>
          <div className={classes.divider}>
            <label>Bet Factor: </label>
            <div className={`${classes.betFactor} no-text-highlight`}>
              <span onClick={betFactor.increase}>+</span>
              <Input
                customInput
                type="number"
                value={betFactor.value}
                onChange={betFactor.setValue}
              />
              <span onClick={betFactor.decrease}>-</span>
            </div>
          </div>
          <div className={classes.divider}>
            <label>Suspension: </label>
            <Select value={''} options={[]} styles={underlinedSelectStyles} />
          </div>
          <div className={classes.divider}>
            <label>Auto Re-offers: </label>
            <p>Yes</p>
          </div>
          <div className={classes.divider}>
            <label>Bet acceptance: </label>
            <div className={`no-text-highlight ${classes.betFactor}`}>
              <span onClick={betAcceptance.increase}>+</span>
              <Input
                customInput
                type="number"
                value={betAcceptance.value}
                onChange={betAcceptance.setValue}
              />
              <span onClick={betAcceptance.decrease}>-</span>
            </div>
          </div>
          <div className={`${classes.infoMessage}`}>
            <div className={`${classes.infoBox}`}>
              <Input
                type="text"
                className={`${classes.missing} ${classes.inputWithIcon} d-inline`}
                customInput
                placeholder="Note Title..."
              />
              <IconPen
                className={classes.penIcon}
                width={10}
                fill="rgba(112, 112, 112, 0.69)"
              />
              <Input
                type="text"
                onChange={e => setCustomerNote(e.target.value)}
                customInput
                placeholder="Note description..."
              />

              <span className={classes.author}>Support</span>
              <Button
                cssClass="mr-1 ml-1 bet-scroller-btn cancel-btn"
                onClick={() => addNote('support')}
              >
                Add
              </Button>
            </div>
            <div className={`${classes.infoBox} ${classes.yellowBox}`}>
              <Input
                type="text"
                onChange={e => setCustomerNote(e.target.value)}
                customInput
                placeholder="Note description..."
              />
              <span className={classes.author}>Traders</span>
              <Button
                cssClass="mr-1 ml-1 bet-scroller-btn cancel-btn"
                onClick={() => addNote('traders')}
              >
                Add
              </Button>
            </div>
            {notes
              ? notes.map(note => {
                  return (
                    <div
                      className={`${classes.infoBox} ${
                        note.noteType === 'CUSTOMER_SERVICE'
                          ? ''
                          : classes.yellowBox
                      }`}
                    >
                      <p>{note.note}</p>
                      <span className={classes.author}>
                        {note.noteType === 'CUSTOMER_SERVICE'
                          ? 'Support'
                          : 'Traders'}
                      </span>
                      <span
                        className={`${classes.removeBtn}  ${classes.missing}`}
                      >
                        remove
                      </span>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <div>
          <div className={classes.tournamentTable}>
            <Table data={testData} columns={columns} pagination={false} />
            <Button cssClass={classes.addTournamentButton}>+ Add</Button>
          </div>
          <div className={classes.cashoutSwitch}>
            <Switch
              label="Cashout"
              isChecked={enableCashout}
              onChange={setEnableCashout}
              smallSwitch
            />
          </div>
        </div>
      </div>
      <Buttons
        apply={saveChanges}
        ok={() => {
          saveChanges();
          onClose();
        }}
        cancel={onClose}
      />
    </>
  );
};

const mapDispatchToProps = {
  addCustomerNote: CustomerActions.addCustomerNote,
  updateUserSettings: UserSettingsActions.updateUserSettings,
  createOrUpdateCustomer: CashoutManagerActions.createOrUpdateCustomer,
};

export default connect(null, mapDispatchToProps)(Additional);
