import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getCMSData,
  getLanguages,
  createLoadingSelector,
} from '../../../selectors';
import {
  submitContent,
  updatePage,
  prepareImage,
  preparePageContentForEdit,
  updateRegular,
  updateSubpageContent,
  prepareRowCellUpdate,
  prepareColumnCellUpdate,
  prepareForRuleSectionUpdate,
  updateSubpage,
} from './StaticContentUtils';
import * as StaticContentActions from '../../../redux/actions/cms/staticPages';
import * as LanguagesActions from '../../../redux/actions/Languages';
import { pageObj, subPagePushData } from './StaticContentObj';
import TabsContainer from './TabsContainer';
import FullScreenLoader from '../../../components/Layout/FullScreenLoader/FullScreenLoader';
import { confirmAlert } from 'react-confirm-alert';
import { useMount } from '../../../hooks';

const StaticContent = ({
  getAllLanguages,
  getStaticPageImages,
  getPagesByLanguage,
  getCountries,
  updatePageContent,
  getSubpageSections,
  uploadImage,
  getSubpageContent,
  extendSubpageContent,
  updateSectionContent,
  getRegularPageContent,
  deletePage,
  languages,
  staticPageData,
  loading,
  updateTable,
  updateRulesContent,
  getCurrencies,
  updateStaticPage,
  addPage,
  updateRegularPageContent,
}) => {
  const [tab, setTab] = useState('main_pages');
  const [staticPageObj, setStaticPageObj] = useState(pageObj);
  const [subpages, setSubpages] = useState([]);
  const [currentLang, setCurrentLang] = useState({
    value: 'en',
    label: 'English',
  });
  const [contentType, setContentType] = useState('');
  const [pagesListLang, setPagesListLang] = useState('');
  const [editingPageInfo, setEditingPageInfo] = useState('');
  const [pageContentEdit, setPageContentEdit] = useState(false);
  const [pageContentEditType, setPageContentEditType] = useState('ADD');
  const [showingSubpage, setShowingSubpage] = useState('');
  const [currentPageId, setCurrentPageId] = useState('');
  const [countryCurrencySubpath, setCountryCurrencySubpath] = useState(true);
  const [serverError, setServerError] = useState(false);

  useMount(() => {
    getAllLanguages();
    getStaticPageImages();
    getCountries();
    getCurrencies();
  });

  const showSubpages = useCallback(page => {
    setTab('subpages');
    setSubpages(page.children);
    setCurrentPageId(page.id);
  }, []);

  const getPages = useCallback(
    lang => {
      getPagesByLanguage(lang.value);
      setPagesListLang(lang);
      setCurrentLang({ value: lang.value, label: lang.label });
    },
    [getPagesByLanguage]
  );

  const submitPage = useCallback(
    async e => {
      e.preventDefault();
      let submitted;
      const { id: pageId, name } = editingPageInfo;
      if (pageContentEdit) {
        submitted = await updatePageContent(
          preparePageContentForEdit(
            staticPageObj,
            pageContentEditType,
            name,
            pagesListLang,
            contentType,
            countryCurrencySubpath
          ),
          pageId
        );
      } else {
        submitted = await addPage(
          submitContent(
            staticPageObj,
            countryCurrencySubpath,
            serverError,
            contentType
          ),
          currentLang.value
        );
        setPageContentEdit(false);
        setPagesListLang({
          value: currentLang.value,
          label: currentLang.label,
        });
      }

      if (submitted) {
        setStaticPageObj({
          ...staticPageObj,
          name: '',
          subpages: [subPagePushData()],
        });
        setServerError(false);
        setContentType('');
      } else {
        setServerError(true);
      }
    },
    [
      addPage,
      contentType,
      countryCurrencySubpath,
      currentLang,
      editingPageInfo,
      pageContentEdit,
      pageContentEditType,
      pagesListLang,
      serverError,
      staticPageObj,
      updatePageContent,
    ]
  );

  const updatePageStatus = useCallback(
    (isChecked, page) => {
      updateStaticPage(updatePage(isChecked, page), page.id, currentLang.value);
    },
    [currentLang, updateStaticPage]
  );

  const saveEditedName = useCallback(
    page => {
      updateStaticPage(
        updatePage(page.enabled, page),
        page.id,
        currentLang.value
      );
    },
    [currentLang.value, updateStaticPage]
  );

  const prepareToUpdateSubpage = useCallback(
    subpage => {
      updateStaticPage(updateSubpage(subpage), subpage.id, currentLang.value);
    },
    [currentLang, updateStaticPage]
  );

  const prepareImageForUpload = useCallback(
    (file, success, failure) => {
      uploadImage(prepareImage(file), success, failure);
    },
    [uploadImage]
  );

  const updateContentTypeValue = useCallback(
    (value, subpageIndex, countryCurrencySubpath) => {
      const pageObjClone = { ...staticPageObj };
      value === 'TABLES' || !countryCurrencySubpath
        ? (pageObjClone.subpages[subpageIndex].pageType = 'ROOT')
        : (pageObjClone.subpages[subpageIndex].pageType = value);
      setContentType(value);
      setStaticPageObj(pageObjClone);
    },
    [staticPageObj]
  );

  const getContentForSubpage = useCallback(
    subpage => {
      subpage.url = subpage.url.replace('/api/backend', '');
      getSubpageContent(subpage.url);
      setContentType(subpage.resourceType);
    },
    [getSubpageContent]
  );

  const updateContent = useCallback(
    content => {
      if (content.type === 'Section') {
        updateSectionContent(
          prepareForRuleSectionUpdate(content),
          pagesListLang.value,
          content.id
        );
      } else {
        updateRulesContent(
          prepareForRuleSectionUpdate(content),
          pagesListLang.value,
          content.id
        );
      }
      setTab('main_pages');
    },
    [pagesListLang.value, updateRulesContent, updateSectionContent]
  );

  const editPageContent = useCallback(page => {
    window.scrollTo(0, 0);
    setPageContentEdit(true);
    setPageContentEditType('REPLACE');
  }, []);

  const extendPageContent = useCallback((page, lang) => {
    window.scrollTo(0, 0);
    setPageContentEdit(true);
    setEditingPageInfo(page);
    setPageContentEditType('ADD');
  }, []);

  const updateRegularPage = useCallback(
    (content, pageId) => {
      updateRegularPageContent(
        updateRegular(content),
        pageId,
        currentLang.value
      );
    },
    [currentLang, updateRegularPageContent]
  );

  const showSubpageContent = useCallback((subpage, index) => {
    setTab('subpages_content');
    setShowingSubpage(subpage);
  }, []);

  const submitNewSubpageContent = useCallback(
    (content, subpages, subpageIndex) => {
      extendSubpageContent(
        updateSubpageContent(content, subpages, subpageIndex),
        currentPageId,
        currentLang.value
      );
      setTab('main_pages');
    },
    [currentLang.value, currentPageId, extendSubpageContent]
  );

  const deleteStaticPage = useCallback(
    pageId => {
      confirmAlert({
        title: 'Confirm to delete',
        message: 'Are you sure? You will delete page with all subpages if any',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              deletePage(pageId, currentLang.value);
            },
          },
          {
            label: 'No',
          },
        ],
      });
    },
    [currentLang.value, deletePage]
  );

  const prepareSubpath = useCallback(
    countryCurrencySubpath => {
      const pageObj = { ...staticPageObj };
      setStaticPageObj({
        ...pageObj,
        subpages: [subPagePushData()],
      });
      setCountryCurrencySubpath(countryCurrencySubpath);
      setContentType('TABLES');
      pageObj.subpages[0].pageType = 'TABLES';
      setStaticPageObj(pageObj);
    },
    [staticPageObj]
  );

  const saveRowCellData = useCallback(
    data => {
      updateTable(prepareRowCellUpdate(data));
    },
    [updateTable]
  );

  const saveColumnCellData = useCallback(
    data => {
      updateTable(prepareColumnCellUpdate(data));
    },
    [updateTable]
  );

  return (
    <>
      <FullScreenLoader isActive={loading} />
      <TabsContainer
        tab={tab}
        showSubpages={showSubpages}
        backToMainPages={() => setTab('main_pages')}
        pageObj={staticPageObj}
        setContentLang={lang => setCurrentLang(lang)}
        languages={languages}
        updateObj={updatedObj => setStaticPageObj(updatedObj)}
        contentType={contentType}
        saveEditedName={saveEditedName}
        getSubpageSections={getSubpageSections}
        updateContentTypeValue={updateContentTypeValue}
        submitPage={submitPage}
        currentLang={currentLang}
        getPages={getPages}
        staticPageData={staticPageData}
        updatePageStatus={updatePageStatus}
        subpages={subpages}
        uploadImage={prepareImageForUpload}
        cancelPageContentEditMode={() => setPageContentEdit(false)}
        getSubpageContent={getContentForSubpage}
        updateContent={updateContent}
        pagesListLang={pagesListLang}
        editPageContent={editPageContent}
        pageContentEdit={pageContentEdit}
        extendPageContent={extendPageContent}
        getRegularPageContent={getRegularPageContent}
        updateRegularPageContent={updateRegularPage}
        showSubpageContent={showSubpageContent}
        backToSubpages={() => setTab('subpages')}
        showingSubpage={showingSubpage}
        submitNewSubpageContent={submitNewSubpageContent}
        deletePage={deleteStaticPage}
        prepareSubpath={prepareSubpath}
        saveRowCellData={saveRowCellData}
        saveColumnCellData={saveColumnCellData}
        serverError={serverError}
        updateSubpage={prepareToUpdateSubpage}
      />
    </>
  );
};

const loadingReducer = createLoadingSelector(['GET_PAGES']);

const mapStateToProps = state => ({
  languages: getLanguages(state).languages,
  staticPageData: getCMSData(state).staticPagesReducer,
  loading: loadingReducer(state),
});

const mapDispatchToProps = {
  getAllLanguages: LanguagesActions.getAllLanguages,
  addPage: StaticContentActions.addPage,
  getPagesByLanguage: StaticContentActions.getPagesByLanguage,
  updateStaticPage: StaticContentActions.updateStaticPage,
  getStaticPageImages: StaticContentActions.getStaticPageImages,
  uploadImage: StaticContentActions.uploadImage,
  getSubpageContent: StaticContentActions.getSubpageContent,
  updateSectionContent: StaticContentActions.updateSectionContent,
  updateRulesContent: StaticContentActions.updateRulesContent,
  updatePageContent: StaticContentActions.updatePageContent,
  getRegularPageContent: StaticContentActions.getRegularPageContent,
  updateRegularPageContent: StaticContentActions.updateRegularPageContent,
  getCountries: StaticContentActions.getCountries,
  getCurrencies: StaticContentActions.getCurrencies,
  extendSubpageContent: StaticContentActions.extendSubpageContent,
  deletePage: StaticContentActions.deletePage,
  updateTable: StaticContentActions.updateTable,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StaticContent)
);
