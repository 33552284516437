import React, { useState, useCallback, useRef } from 'react';
import Button from '../../../../../components/Layout/Button';
import { isImage } from '../../../../../utils/helpers';
import { useFetch } from '../../../../../hooks';
import classes from './Documents.module.scss';

const UploadImage = ({
  customerId,
  refetchDocuments,
  uploadAdminVerification,
  addNotificationMessage,
}) => {
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);

  const { data: allDocuments } = useFetch(
    '/customer/view/admin/documents/verification-types?type=ADMIN_VERIFICATION'
  );

  const hasAdminVerfication = useCallback(array => {
    return array[0].verificationDocuments.find(
      type => type.documentType === 'ADMIN_VERIFICATION'
    );
  }, []);

  const handleFileChange = e => {
    const error = isImage(e.target.files[0]);
    if (error) {
      inputRef.current.value = null;
      addNotificationMessage(error, 'error', 'Error!');
      return;
    }
    if (!hasAdminVerfication(allDocuments)) {
      addNotificationMessage(
        'Create a Admin Verification type in Customer Service module first!',
        'error',
        'Error'
      );
      return;
    }
    setFile(e.target.files[0]);
  };

  const upload = useCallback(async () => {
    if (file) {
      await uploadAdminVerification({
        customerId,
        file,
      });
      await refetchDocuments();
      await setFile(null);
    }
    inputRef.current.value = null;
  }, [customerId, file, refetchDocuments, uploadAdminVerification]);

  return (
    <div className={classes.fileBar}>
      <div>{inputRef.current && file ? inputRef.current.value : null}</div>
      <div className="pl-1 pr-1">
        <Button cssClass="p-1" onClick={upload}>
          Upload
        </Button>
      </div>
      <div className={classes.fileWrapper}>
        <Button cssClass={classes.btnFile}>Add</Button>
        <input
          type="file"
          name="file"
          ref={inputRef}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default UploadImage;
