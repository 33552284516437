import React, { useCallback, Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import MarketTypesTableRow from './MarketTypesTableRow';
import MarketTypeForm from './MarketTypeForm';
import Button from '../../../../components/Layout/Button';
import DragAndDrop from '../../../../components/DragAndDrop/DragAndDrop';
import { useMount, useUpdate } from '../../../../hooks';

const MarketTypesTable = ({
  setResetOrdering,
  marketTypesList,
  marketCatName,
  marketTypesObj,
  updateMarketTypeObj,
  addNewMarketType,
  outcomes,
  allMarketTypes,
  assignMarketType,
  updateAssignedMarketType,
  marketTypeToAssign,
  removeFromMarketCategory,
  saveOpenSelection,
  businessUnit,
  addNotificationMessage,
  saveRanking,
}) => {
  const [marketTypes, setMarketTypes] = useState([]);
  const [orderedMarketTypes, setOrderedMarketTypes] = useState([]);
  const [changed, setChanged] = useState(false);
  const [marketTypeFormModal, setMarketTypeFormModal] = useState(false);

  useMount(() => {
    setResetOrdering(resetOrdering);
  });

  useUpdate(() => {
    setResetOrdering(resetOrdering);
    orderByRank(marketTypesList);
  }, [marketTypesList]);

  useUpdate(() => {
    setMarketTypes(orderedMarketTypes);
  }, [orderedMarketTypes]);

  const resetOrdering = useCallback(() => {
    setMarketTypes(orderedMarketTypes);
    setChanged(false);
  }, [orderedMarketTypes]);

  const orderByRank = useCallback(
    list => {
      const newList = [...list].sort((a, b) => {
        if (a.rank[businessUnit || 'BG'] && b.rank[businessUnit || 'BG']) {
          return (
            a.rank[businessUnit || 'BG'].rank -
            b.rank[businessUnit || 'BG'].rank
          );
        }
        return 0;
      });
      setOrderedMarketTypes(newList);
      resetOrdering();
    },
    [resetOrdering, businessUnit]
  );

  const mapMarkets = useMemo(() => {
    return marketTypes
      ? marketTypes.map((market, i) => ({
          businessUnit: businessUnit,
          id: market.id,
          type: 'TYPE',
          rank: i,
        }))
      : [];
  }, [marketTypes, businessUnit]);

  const onDrop = useCallback(newData => {
    setMarketTypes(newData);
    setChanged(true);
  }, []);

  const saveMarketTypeRanking = useCallback(() => {
    if (!businessUnit) {
      addNotificationMessage('Select businessUnit first!', 'error', 'Error!');
      return;
    }
    saveRanking(mapMarkets, 'Market types ranking saved successfuly!');
    setChanged(false);
  }, [businessUnit, saveRanking, mapMarkets, addNotificationMessage]);

  return (
    <Fragment>
      <h3>Market Types {marketCatName !== '' ? '- ' + marketCatName : ''}</h3>
      <div className="form-group d-flex flex-row justify-content-end">
        <MarketTypeForm
          isActiveModal={marketTypeFormModal}
          marketTypesObj={marketTypesObj}
          closeModal={() => setMarketTypeFormModal(false)}
          updateMarketTypeObj={updateMarketTypeObj}
          addNewMarketType={addNewMarketType}
          outcomes={outcomes}
          allMarketTypes={allMarketTypes}
          assignMarketType={assignMarketType}
          updateAssignedMarketType={updateAssignedMarketType}
          marketTypeToAssign={marketTypeToAssign}
        />
        <Button
          cssClass="efb-btn__main"
          onClick={() => setMarketTypeFormModal(true)}
        >
          Add new
        </Button>
      </div>
      {changed && (
        <div className="d-flex flex-row justify-content-end mb-3">
          <Button onClick={saveMarketTypeRanking} cssClass="btn btn-primary">
            Save Rankings
          </Button>
          <Button onClick={resetOrdering} cssClass="btn btn-danger">
            Reset Rankings
          </Button>
        </div>
      )}
      <table className="table table-striped table-sm efb-table efb-table-translations">
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <DragAndDrop data={marketTypes} onDrop={onDrop}>
          {({ droppableProps, draggableProps }) => (
            <tbody {...droppableProps}>
              {marketTypes
                ? marketTypes.map((mt, index) => {
                    return (
                      <MarketTypesTableRow
                        key={index}
                        index={index}
                        mt={mt}
                        draggableProps={draggableProps}
                        marketCatName={marketCatName}
                        removeFromMarketCategory={removeFromMarketCategory}
                        saveOpenSelection={saveOpenSelection}
                        businessUnit={businessUnit}
                        addNotificationMessage={addNotificationMessage}
                      />
                    );
                  })
                : null}
            </tbody>
          )}
        </DragAndDrop>
      </table>
    </Fragment>
  );
};

MarketTypesTable.propTypes = {
  addNotificationMessage: PropTypes.func.isRequired,
  setResetOrdering: PropTypes.func.isRequired,
  saveRanking: PropTypes.func.isRequired,
  saveOpenSelection: PropTypes.func.isRequired,
  businessUnit: PropTypes.string,
  marketTypesList: PropTypes.array,
  allMarketTypes: PropTypes.array,
  assignMarketType: PropTypes.func,
  closeModal: PropTypes.func,
  isActiveModal: PropTypes.bool,
  marketCatName: PropTypes.string,
  marketCategoryId: PropTypes.string,
  marketTypeToAssign: PropTypes.object,
  openModal: PropTypes.func,
  updateAssignedMarketType: PropTypes.func,
};

export default MarketTypesTable;
