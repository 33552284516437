import React from 'react';
import PropTypes from 'prop-types';

export const Alert = props => {
  return <div className={`efb-alertbox__${props.type}`}>{props.children}</div>;
};

export default Alert;

Alert.propTypes = {
  type: PropTypes.oneOf(['info', 'data', 'success', 'warning', 'danger'])
    .isRequired,
};

Alert.defaultProps = {
  type: 'danger',
};
