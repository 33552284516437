import { default as AmericanFootball } from './AmericanFootball/AmericanFootball';
import { default as Athletics } from './Athletics/Athletics';
import { default as Badminton } from './Badminton/Badminton';
import { default as Bandy } from './Bandy/Bandy';
import { default as Baseball } from './Baseball/Baseball';
import { default as Basketball } from './Basketball/Basketball';
import { default as BeachFootball } from './BeachFootball/BeachFootball';
import { default as BeachVolleyball } from './BeachVolleyball/BeachVolleyball';
import { default as Bowls } from './Bowls/Bowls';
import { default as Chess } from './Chess/Chess';
import { default as CombatSports } from './CombatSports/CombatSports';
import { default as Cricket } from './Cricket/Cricket';
import { default as Cycling } from './Cycling/Cycling';
import { default as Darts } from './Darts/Darts';
import { default as ElectronicSports } from './ElectronicSports/ElectronicSports';
import { default as Floorball } from './Floorball/Floorball';
import { default as Football } from './Football/Football';
import { default as Futsal } from './Futsal/Futsal';
import { default as Golf } from './Golf/Golf';
import { default as Handball } from './Handball/Handball';
import { default as Hockey } from './Hockey/Hockey';
import { default as MotorSports } from './MotorSports/MotorSports';
import { default as Pesapallo } from './Pesapallo/Pesapallo';
import { default as Rugby } from './Rugby/Rugby';
import { default as Snooker } from './Snooker/Snooker';
import { default as TableTennis } from './TableTennis/TableTennis';
import { default as Tennis } from './Tennis/Tennis';
import { default as VirtualBasketball } from './VirtualBasketball/VirtualBasketball';
import { default as VirtualDogs } from './VirtualDogs/VirtualDogs';
import { default as VirtualEuropaCup } from './VirtualEuropaCup/VirtualEuropaCup';
import { default as VirtualHorses } from './VirtualHorses/VirtualHorses';
import { default as VirtualSoccer } from './VirtualSoccer/VirtualSoccer';
import { default as VirtualTennis } from './VirtualTennis/VirtualTennis';
import { default as Volleyball } from './Volleyball/Volleyball';
import { default as WaterPolo } from './WaterPolo/WaterPolo';
import { default as WinterSports } from './WinterSports/WinterSports';

export default {
  AmericanFootball,
  Badminton,
  Bandy,
  Baseball,
  Basketball,
  BeachFootball,
  BeachVolleyball,
  Bowls,
  Chess,
  CombatSports,
  Cricket,
  Cycling,
  Darts,
  ElectronicSports,
  Floorball,
  Football,
  Futsal,
  Golf,
  Handball,
  Hockey,
  MotorSports,
  Pesapallo,
  Rugby,
  Snooker,
  TableTennis,
  Tennis,
  VirtualBasketball,
  VirtualDogs,
  VirtualEuropaCup,
  VirtualHorses,
  VirtualSoccer,
  VirtualTennis,
  Volleyball,
  WaterPolo,
  WinterSports
};

export const ICONS_SPORT_MAP = {
  'sr:sport:61': {
    icon: Pesapallo,
    names: {
      en: {
        name: 'Pesapallo'
      }
    }
  },
  'sr:sport:36': {
    icon: Athletics,
    names: {
      en: {
        name: 'Athletics'
      }
    }
  },
  'sr:sport:3': {
    icon: Baseball,
    names: {
      en: {
        name: 'Baseball'
      }
    }
  },
  'sr:sport:48': {
    icon: WinterSports,
    names: {
      en: {
        name: 'Ski Jumping'
      }
    }
  },
  'sr:sport:10': {
    icon: CombatSports,
    names: {
      en: {
        name: 'Boxing'
      }
    }
  },
  'sr:sport:2': {
    icon: Basketball,
    names: {
      en: {
        name: 'Basketball'
      }
    }
  },
  'sr:sport:44': {
    icon: WinterSports,
    names: {
      en: {
        name: 'Biathlon'
      }
    }
  },
  'sr:sport:37': {
    icon: Hockey,
    names: {
      en: {
        name: 'Squash'
      }
    }
  },
  'sr:sport:47': {
    icon: WinterSports,
    names: {
      en: {
        name: 'Nordic Combined'
      }
    }
  },
  'sr:sport:43': {
    icon: WinterSports,
    names: {
      en: {
        name: 'Alpine Skiing'
      }
    }
  },
  'sr:sport:32': {
    icon: Bowls,
    names: {
      en: {
        name: 'Bowls'
      }
    }
  },
  'sr:sport:15': {
    icon: Bandy,
    names: {
      en: {
        name: 'Bandy'
      }
    }
  },
  'sr:sport:31': {
    icon: Badminton,
    names: {
      en: {
        name: 'Badminton'
      }
    }
  },
  'sr:sport:49': {
    icon: WinterSports,
    names: {
      en: {
        name: 'Snowboard'
      }
    }
  },
  'sr:sport:111': {
    icon: ElectronicSports,
    names: {
      en: {
        name: 'Dota 2'
      }
    }
  },
  'sr:sport:9': {
    icon: Golf,
    names: {
      en: {
        name: 'Golf'
      }
    }
  },
  'sr:sport:13': {
    icon: AmericanFootball,
    names: {
      en: {
        name: 'Aussie Rules'
      }
    }
  },
  'sr:sport:12': {
    icon: Rugby,
    names: {
      en: {
        name: 'Rugby'
      }
    }
  },
  'sr:sport:11': {
    icon: MotorSports,
    names: {
      en: {
        name: 'Motorsport'
      }
    }
  },
  'sr:sport:22': {
    icon: Darts,
    names: {
      en: {
        name: 'Darts'
      }
    }
  },
  'sr:sport:117': {
    icon: CombatSports,
    names: {
      en: {
        name: 'MMA'
      }
    }
  },
  'sr:sport:26': {
    icon: WaterPolo,
    names: {
      en: {
        name: 'Waterpolo'
      }
    }
  },
  'sr:sport:7': {
    icon: Floorball,
    names: {
      en: {
        name: 'Floorball'
      }
    }
  },
  'sr:sport:6': {
    icon: Handball,
    names: {
      en: {
        name: 'Handball'
      }
    }
  },
  'sr:sport:21': {
    icon: Cricket,
    names: {
      en: {
        name: 'Cricket'
      }
    }
  },
  'sr:sport:1': {
    icon: Football,
    names: {
      en: {
        name: 'Soccer'
      }
    }
  },
  'sr:sport:109': {
    icon: ElectronicSports,
    names: {
      en: {
        name: 'Counter-Strike'
      }
    }
  },
  'sr:sport:46': {
    icon: null,
    names: {
      en: {
        name: 'Cross-Country'
      }
    }
  },
  'sr:sport:45': {
    icon: WinterSports,
    names: {
      en: {
        name: 'Bobsleigh'
      }
    }
  },
  'sr:sport:17': {
    icon: Cycling,
    names: {
      en: {
        name: 'Cycling'
      }
    }
  },
  'sr:sport:4': {
    icon: Hockey,
    names: {
      en: {
        name: 'Ice Hockey'
      }
    }
  },
  'sr:sport:24': {
    icon: Hockey,
    names: {
      en: {
        name: 'Field hockey'
      }
    }
  },
  'sr:sport:23': {
    icon: Volleyball,
    names: {
      en: {
        name: 'Volleyball'
      }
    }
  },
  'sr:sport:19': {
    icon: Snooker,
    names: {
      en: {
        name: 'Snooker'
      }
    }
  },
  'sr:sport:16': {
    icon: AmericanFootball,
    names: {
      en: {
        name: 'American Football'
      }
    }
  },
  'sr:sport:20': {
    icon: TableTennis,
    names: {
      en: {
        name: 'Table Tennis'
      }
    }
  },
  'sr:sport:5': {
    icon: Tennis,
    names: {
      en: {
        name: 'Tennis'
      }
    }
  },
  'sr:sport:51': {
    icon: WinterSports,
    names: {
      en: {
        name: 'Luge'
      }
    }
  },
  'sr:sport:29': {
    icon: Futsal,
    names: {
      en: {
        name: 'Futsal'
      }
    }
  },
  'sr:sport:50': {
    icon: WinterSports,
    names: {
      en: {
        name: 'Speed Skating'
      }
    }
  },
  'sr:sport:38': {
    icon: Hockey,
    names: {
      en: {
        name: 'Rink Hockey'
      }
    }
  },
  'sr:sport:110': {
    icon: ElectronicSports,
    names: {
      en: {
        name: 'League of Legends'
      }
    }
  },
  'sr:sport:118': {
    icon: ElectronicSports,
    names: {
      en: {
        name: 'Call of Duty'
      }
    }
  },
  'sr:sport:34': {
    icon: BeachVolleyball,
    names: {
      en: {
        name: 'Beach Volley'
      }
    }
  },
  'sr:sport:112': {
    icon: ElectronicSports,
    names: {
      en: {
        name: 'StarCraft'
      }
    }
  },
  'sr:sport:140': {
    icon: ElectronicSports,
    names: {
      en: {
        name: 'ESport PlayerUnknowns Battlegrounds'
      }
    }
  }
};
