import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import SportTemplatesTable from './SportTemplatesTable';
import SelectedTemplate from './SelectedTemplate';
import DeleteModal from './DeleteModal';
import FullScreenLoader from '../../../../components/Layout/FullScreenLoader/FullScreenLoader';
import { connect } from 'react-redux';
import * as ConfigurationActions from '../../../../redux/actions/riskManagement/configuration';
import {
  getRiskManagementData,
  createLoadingSelector,
} from '../../../../selectors';
import * as UiActions from '../../../../redux/actions/ui';
import { riskValueTransform } from './utils';

class SportTemplates extends PureComponent {
  state = {
    mode: '',
    isSelected: false,
    sportTemplate: {
      template: {},
      riskControlParameters: [],
    },
    modalIsActive: false,
  };

  componentDidMount() {
    this.props.getSportTemplates();
    this.props.getSportViews();
  }

  componentWillUnmount() {
    this.props.emptySportTemplates();
  }

  createSportTemplate = () => {
    let currentTemplate = {
      ...this.state.sportTemplate,
    };
    currentTemplate = {
      template: {},
      riskControlParameters: [],
    };

    this.setState(
      {
        sportTemplate: currentTemplate,
        mode: 'create',
        isSelected: true,
      },
      () =>
        document
          .getElementById('selected-container')
          .scrollIntoView({ behavior: 'smooth' })
    );
  };

  editSportTemplate = currentTemplate => {
    this.setState(
      {
        sportTemplate: currentTemplate,
        mode: 'edit',
        isSelected: true,
      },
      () =>
        document
          .getElementById('selected-container')
          .scrollIntoView({ behavior: 'smooth' })
    );
  };

  showDeleteModal = currentTemplate => {
    this.setState({
      sportTemplate: currentTemplate,
      modalIsActive: true,
      mode: 'delete',
    });
  };

  deleteCurrentSport = () => {
    this.props.deleteSportTemplate(this.state.sportTemplate);
    this.closeModal();
  };

  closeModal = () => {
    this.setState({
      sportTemplate: {
        template: {},
        riskControlParameters: [],
      },
      mode: '',
      isSelected: false,
      modalIsActive: false,
    });
  };

  changeStatus = currentTemplate => {
    const currentTemplateValue = { ...currentTemplate };
    const currentValue = currentTemplateValue['status'];

    currentTemplateValue['status'] = !currentValue;
  };

  closeSelect = e => {
    e.preventDefault();

    this.setState({
      sportTemplate: {
        template: {},
        riskControlParameters: [],
      },
      mode: '',
      isSelected: false,
    });
  };

  handleInputChange = e => {
    const currentTemplate = {
      ...this.state.sportTemplate,
    };

    currentTemplate[e.target.name] = e.target.value;

    this.setState({
      sportTemplate: currentTemplate,
    });
  };

  handleSubViewInputs = (data, inputName) => {
    let resetTemplate = {};
    if (inputName === 'sport') {
      resetTemplate = {
        categoryId: null,
        categoryName: null,
        tournamentId: null,
        tournamentName: null,
      };
    } else if (inputName === 'category') {
      resetTemplate = {
        tournamentId: null,
        tournamentName: null,
      };
    }

    this.setState(
      {
        sportTemplate: {
          ...this.state.sportTemplate,
          template: {
            ...this.state.sportTemplate.template,
            ...resetTemplate,
            [inputName + 'Id']: data.value,
            [inputName + 'Name']: data.label,
          },
        },
      },
      () => {
        const name = [
          ...Object.values(
            _.pick(this.state.sportTemplate.template, [
              'sportName',
              'categoryName',
              'tournamentName',
            ])
          ).filter(name => name),
        ].join(' - ');
        this.setState({
          sportTemplate: {
            ...this.state.sportTemplate,
            name,
          },
        });
      }
    );
  };

  handleRiskControlInputs = e => {
    const name = e.target.name;
    let value;

    //Checkbox with string value = true : false
    if (e.target.type === 'checkbox') {
      value = e.target.checked ? 'true' : 'false';
    } else {
      value = e.target.value;
    }

    const currentTemplate = {
      ...this.state.sportTemplate,
    };

    const riskControl = [...currentTemplate.riskControlParameters];

    const foundIndex = riskControl.findIndex(x => x.code === name);
    if (foundIndex > -1) {
      riskControl[foundIndex]['value'] = value;
    } else {
      riskControl.push({
        code: name,
        value: value,
      });
    }

    currentTemplate['riskControlParameters'] = riskControl;

    this.setState({
      sportTemplate: currentTemplate,
    });
  };

  templateSend = e => {
    e.preventDefault();

    const objectForSend = { ...this.state.sportTemplate };

    if (!objectForSend.name || !objectForSend.template.sportId) {
      this.props.addNotificationMessage(
        'Name and sport are required fields!',
        'error',
        'Error!'
      );
      return;
    } else if (
      isNaN(
        riskValueTransform(objectForSend.riskControlParameters, 'MAX_TAKEOUT')
      )
    ) {
      this.props.addNotificationMessage(
        'Max Takeout should be a number',
        'error',
        'Error!'
      );
      return;
    }
    objectForSend['riskControlParameters'] = objectForSend[
      'riskControlParameters'
    ].filter(element => element.value !== '');

    if (this.state.mode === 'create') {
      this.props.addSportTemplate(objectForSend);
    } else if (this.state.mode === 'edit') {
      this.props.editSportTemplate(objectForSend);
    }

    this.setState({
      sportTemplate: {
        template: {},
        riskControlParameters: [],
      },
      mode: '',
      isSelected: false,
    });
  };

  render() {
    const { sportTemplate, modalIsActive, mode } = this.state;
    const { sportTemplatesData, isLoading, sportViews } = this.props;

    return (
      <div className="tab-pane card-body fade show active">
        <div className="form-row">
          <div className="col-md-6">
            <h3>Sport Templates</h3>
          </div>

          <div className="col-md-6" style={{ textAlign: 'right' }}>
            <button
              className="btn btn-outline-primary new-template"
              onClick={this.createSportTemplate}
            >
              <i className="fas fa-plus" /> New
            </button>
          </div>
        </div>
        <DeleteModal
          modalIsActive={modalIsActive}
          onClose={this.closeModal}
          sportTemplate={sportTemplate}
          deleteCurrentSport={this.deleteCurrentSport}
        />
        {isLoading ? <FullScreenLoader isActive={true} /> : null}
        {sportViews.length ? (
          <SportTemplatesTable
            data={sportTemplatesData}
            sportViews={sportViews}
            createSportTemplate={this.createSportTemplate}
            editHandler={this.editSportTemplate}
            showDeleteModal={this.showDeleteModal}
            changeStatus={this.changeStatus}
          />
        ) : null}

        {this.state.isSelected ? (
          <SelectedTemplate
            closeSelect={this.closeSelect}
            sportTemplate={sportTemplate}
            templateSend={this.templateSend}
            handleInputChange={this.handleInputChange}
            handleRiskControlInputs={this.handleRiskControlInputs}
            mode={mode}
            sportViews={sportViews}
            handleSubViewInputs={this.handleSubViewInputs}
          />
        ) : null}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['SPORT_TEMPLATES']);

const mapStateToProps = state => {
  let sportTemplatesData = getRiskManagementData(state).configurationReducer
      .sportTemplatesData,
    sportViews = getRiskManagementData(state).configurationReducer.sportViews,
    isLoading = loadingSelector(state);

  return {
    sportTemplatesData,
    isLoading,
    sportViews,
  };
};

const mapDispatchToProps = {
  getSportTemplates: ConfigurationActions.getSportTemplates,
  addSportTemplate: ConfigurationActions.addSportTemplate,
  editSportTemplate: ConfigurationActions.editSportTemplate,
  emptySportTemplates: ConfigurationActions.emptySportTemplates,
  deleteSportTemplate: ConfigurationActions.deleteSportTemplate,
  getSportViews: ConfigurationActions.getSportViews,
  addNotificationMessage: UiActions.addNotificationMessage,
};

SportTemplates.propTypes = {
  sportTemplatesData: PropTypes.array,
  sportViews: PropTypes.array,
  getSportTemplates: PropTypes.func,
  editSportTemplate: PropTypes.func,
  setSportTypesTemplates: PropTypes.func,
  emptySportTemplates: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SportTemplates);
