import React from 'react';
import Switch from '../../../../components/Form/Switch/Switch';
import { useStateForSwitch, useUpdate } from '../../../../hooks';

const GamePlatform = ({ casinoGameObj, updateChanges, editMode }) => {
  const [activeGame, setActiveGame] = useStateForSwitch(false);
  const [web, setWeb] = useStateForSwitch(false);
  const [mobile, setMobile] = useStateForSwitch(false);

  useUpdate(() => {
    setActiveGame(casinoGameObj.active);
    setWeb(casinoGameObj.web);
    setMobile(casinoGameObj.mobile);
  }, [casinoGameObj]);

  useUpdate(() => {
    updateChanges({ ...casinoGameObj, active: activeGame, web, mobile });
  }, [activeGame, web, mobile]);

  return (
    <div className="efb-form">
      <div className="form-row">
        <div className="col-md">
          <Switch
            label={'Is game active'}
            value={activeGame}
            isChecked={activeGame}
            name="active"
            onChange={setActiveGame}
          />
          <Switch
            label={'Web'}
            value={web}
            isChecked={web}
            name="web"
            onChange={setWeb}
          />
          <Switch
            label={'Mobile'}
            value={mobile}
            isChecked={mobile}
            name="mobile"
            onChange={setMobile}
          />
        </div>
      </div>
    </div>
  );
};

export default GamePlatform;
