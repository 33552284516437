import React, { useCallback } from 'react';

import classes from './RadioFilter.module.scss';
import RadioButton from '../../../Form/RadioButton/RadioButton';
import { useStateForInput } from '../../../../hooks';

const RadioFilter = ({
  name,
  label,
  options,
  default: defaultValue,
  onFilterChange: pushFilterChange,
}) => {
  const [value, setValue] = useStateForInput(defaultValue.value || null);

  const handleChange = useCallback(
    e => {
      setValue(e);
      pushFilterChange({ name, value: e.target.value });
    },
    [name, pushFilterChange, setValue]
  );

  return (
    <div className={classes.radio}>
      <label>{label}:</label>
      <div className={classes.radioGroup}>
        {options.map((option, i) => (
          <RadioButton
            id={`${name}-${option.value}-${i}`}
            key={`${name}-${option.value}-${i}`}
            name={name}
            value={option.value}
            label={option.label}
            isChecked={value === option.value}
            onChange={handleChange}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioFilter;
