export const transformRole = role => {
  let roleForDisplay = {};
  if (role) {
    roleForDisplay.role = role.name;
    roleForDisplay.applications = {};
    role.privileges.forEach((privilege, idx) => {
      if (privilege.applications.length) {
        roleForDisplay.domainObject = privilege.domainObject;
        privilege.applications.forEach((app, idx) => {
          if (roleForDisplay['applications'][app.label]) {
            app.views.forEach((view, idx) => {
              if (roleForDisplay['applications'][app.label][view.label]) {
                let actions =
                  roleForDisplay['applications'][app.label][view.label].actions;
                roleForDisplay['applications'][app.label][view.label] = {
                  label: view.label,
                  actions: [...actions, privilege.action.toLowerCase()],
                };
              } else {
                roleForDisplay['applications'][app.label][view.label] = {
                  label: view.label,
                  actions: [privilege.action.toLowerCase()],
                };
              }
            });
          } else {
            roleForDisplay['applications'][app.label] = {};
            app.views.forEach((view, idx) => {
              roleForDisplay['applications'][app.label][view.label] = {
                label: view.label,
                actions: [privilege.action.toLowerCase()],
              };
            });
          }
        });
      }
    });
  }

  return roleForDisplay;
};

export const transformUserRoles = userRoles => {
  console.log(userRoles);
  return userRoles.map(userRole => {
    return transformRole(userRole);
  });
};

export const constructRoleObject = (transformedRole, role) => {
  let updatedRole = JSON.parse(JSON.stringify(role));
  updatedRole.privileges.forEach((privilege, idx) => {
    privilege.applications.forEach((app, idx) => {
      app.views = app.views.filter((view, idx) => {
        if (
          transformedRole[app.label][view.label].actions.includes(
            privilege.action.toLowerCase()
          )
        ) {
          return true;
        } else {
          console.log(
            `action ${privilege.action} for view ${view.label} has been deleted ... remove from original object`
          );
          return false;
        }
      });
    });
  });

  return updatedRole;
};

export const findPrivilege = (newRole, action) => {
  return newRole.privileges.findIndex((priv, idx) => {
    return priv.action === action;
  });
};

export const findApplication = (privileges, privInd, appLabel) => {
  return privileges[privInd].applications.findIndex((app, idx) => {
    return app.label === appLabel;
  });
};

export const findView = (applications, appIndex, viewLabel) => {
  console.log('applications', applications);
  console.log('appIndex', appIndex);
  console.log('viewLabel', viewLabel);

  if (appIndex >= 0 && appIndex < applications.length) {
    return applications[appIndex].views.findIndex((view, idx) => {
      return view.label === viewLabel;
    });
  } else {
    return false;
  }
};

export const createAppView = (application, view) => {
  let appObject = {
    label: application.label,
    url: application.label.toLowerCase(),
    views: [...[], Object.assign({}, view)],
    id: application.id,
  };
  return [...[], appObject];
};

export const roleObj = {
  description: '',
  name: '',
  privileges: [
    {
      action: 'WRITE',
      domainObject: '',
      applications: [],
    },
    {
      action: 'READ',
      domainObject: '',
      applications: [],
    },
  ],
};

export const operateOnExistingApp = (
  newRole,
  privIdx,
  appIdx,
  view,
  action
) => {
  let viewIdx = findView(
      newRole.privileges[privIdx].applications,
      appIdx,
      view.label
    ),
    application = newRole.privileges[privIdx].applications[appIdx];
  if (viewIdx !== -1) {
    //view in applications array already exists so we should delete it
    //we should check if the action is READ, because if the read privilege is disabled
    //the write privilege should be disabled as well.
    deleteViewPrivilege(newRole, application, view, action);
  } else {
    //view in applications array doesn't exist so we should add it
    //we should check if the actions is WRITE, because a write privilege
    //cannot exist for a view if there isn't a read privilege first.
    addViewPrivilege(newRole, application, view, action);
  }
};

export const deleteViewPrivilege = (newRole, application, view, action) => {
  let writePrivIdx = findPrivilege(newRole, 'WRITE');
  if (
    action === 'READ' &&
    newRole.privileges[writePrivIdx].applications.length
  ) {
    let applicationIdx = findApplication(
        newRole.privileges,
        writePrivIdx,
        application.label
      ),
      app = newRole.privileges[writePrivIdx].applications[applicationIdx],
      filteredViews = app.views.filter(appView => appView.label !== view.label);
    app.views = filteredViews.slice();
  }
  let filteredViews = application.views.filter(
    appView => appView.label !== view.label
  );
  application.views = filteredViews.slice();
};

export const addViewPrivilege = (newRole, application, view, action) => {
  let readPrivIdx = findPrivilege(newRole, 'READ'),
    readAppIdx = findApplication(
      newRole.privileges,
      readPrivIdx,
      application.label
    );
  if (readAppIdx === -1) {
    newRole.privileges[readPrivIdx].applications = createAppView(
      application,
      view
    );
  }

  let readAppViewInd = findView(
    newRole.privileges[readPrivIdx].applications,
    findApplication(newRole.privileges, readPrivIdx, application.label),
    view.label
  );
  if (action === 'WRITE' && readAppViewInd) {
    let applicationIdx = findApplication(
        newRole.privileges,
        readPrivIdx,
        application.label
      ),
      app = newRole.privileges[readPrivIdx].applications[applicationIdx];
    app.views = [...app.views, view];
  }
  application.views = [...application.views, view];
};
