import React from 'react';
import classes from './General.module.scss';
import CheckboxDiv from '../../../../../components/Form/CheckboxDiv/CheckboxDiv';
import Switch from '../../../../../components/Form/Switch/Switch';

const Channels = () => {
  return (
    <div>
      <h1>
        Channels
        <div className="float-right">
          <Switch name="channels" smallSwitch isChecked />
        </div>
      </h1>
      <div className={classes.channels}>
        <CheckboxDiv
          isChecked={false}
          onChange={() => {}}
          label="Account Notifications"
          name="accountNotifications"
          id="accountNotifications"
        />
        <CheckboxDiv
          isChecked={false}
          onChange={() => {}}
          label="Email"
          name="email"
          id="email"
        />
        <CheckboxDiv
          isChecked={false}
          onChange={() => {}}
          label="Mobile Notifications"
          name="mobileNotifications"
          id="mobileNotifications"
        />
      </div>
    </div>
  );
};

export default Channels;
