import React, { memo } from 'react';

export const VirtualTennis = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  const icon = (
    <>
      <path
        d="M11.86,32c-0.05-0.03-0.1-0.03-0.15-0.04c-0.42-0.1-0.66-0.38-0.78-0.78c-0.07-0.23-0.06-0.47-0.01-0.7
	c0.04-0.19-0.03-0.34-0.18-0.44c-0.16-0.11-0.27-0.26-0.34-0.44c-0.07-0.19-0.05-0.38,0.07-0.54c0.2-0.26,0.44-0.48,0.68-0.71
	c0.12-0.11,0.27-0.16,0.44-0.12c0.04,0.01,0.06-0.01,0.08-0.04c0.09-0.11,0.18-0.22,0.28-0.33c0.05-0.06,0.11-0.13,0.19-0.15
	c0.01,0,0.02-0.01,0.03-0.02c0.16-0.2,0.36-0.37,0.42-0.65c0.04-0.2,0.16-0.37,0.27-0.55c0.2-0.33,0.44-0.64,0.75-0.88
	c0.21-0.16,0.44-0.25,0.7-0.27c0.05,0,0.06-0.01,0.05-0.07c-0.06-0.16-0.1-0.32-0.15-0.48c-0.01-0.04-0.03-0.05-0.07-0.05
	c-0.12,0-0.24,0-0.36,0c-0.08,0-0.11-0.03-0.11-0.11c-0.01-0.27,0.01-0.55,0.02-0.82c0.02-0.36,0.01-0.72,0.02-1.08
	c0-0.19,0.03-0.38,0.01-0.57c-0.04-0.4,0.03-0.81-0.08-1.21c-0.04-0.13-0.03-0.27-0.03-0.4c0-0.02,0.01-0.05-0.02-0.06
	c-0.09-0.03-0.1-0.11-0.1-0.18c-0.01-0.32-0.09-0.62-0.07-0.94c0.03-0.31-0.04-0.6-0.07-0.91c0-0.02-0.01-0.04-0.02-0.06
	c-0.13-0.19-0.13-0.41-0.11-0.63c0.01-0.06-0.03-0.11-0.07-0.16c-0.28-0.32-0.62-0.55-0.94-0.8c-0.39-0.3-0.74-0.63-1.09-0.98
	c-0.06-0.05-0.11-0.07-0.19-0.05c-0.21,0.05-0.36,0.17-0.5,0.34c-0.15,0.17-0.3,0.33-0.49,0.46c-0.07,0.05-0.13,0.13-0.19,0.19
	c-0.05,0.06-0.11,0.07-0.17,0.01c-0.04-0.04-0.08-0.03-0.12,0c-0.2,0.14-0.4,0.27-0.6,0.4c-0.31,0.2-0.64,0.36-0.98,0.51
	c-0.11,0.05-0.24,0.08-0.37,0.07c-0.14-0.01-0.24-0.07-0.27-0.2c-0.02-0.07-0.05-0.15-0.07-0.22c-0.07-0.27-0.18-0.52-0.21-0.8
	c-0.01-0.06-0.01-0.11-0.02-0.17C6.92,16,6.92,15.6,6.88,15.21c-0.04-0.37-0.1-0.74-0.15-1.11c-0.03-0.24-0.1-0.47-0.14-0.7
	c-0.02-0.07-0.05-0.15-0.09-0.21c-0.11-0.16-0.21-0.32-0.3-0.5c-0.08-0.15-0.09-0.31-0.05-0.46c0.03-0.1,0.08-0.2,0.11-0.3
	c0.01-0.04,0.06-0.05,0.1-0.06c0.08-0.04,0.14-0.09,0.15-0.19c0.01-0.14,0.08-0.26,0.16-0.37c0.03-0.04,0.07-0.05,0.11-0.04
	c0.02,0.01,0.04,0.02,0.05-0.02c0.03-0.13,0.11-0.23,0.14-0.36c0.07-0.3,0.05-0.6-0.01-0.9C6.9,9.58,6.76,9.17,6.63,8.77
	C6.51,8.39,6.46,8.02,6.51,7.63C6.56,7.31,6.7,7.06,6.97,6.87C7.21,6.7,7.45,6.73,7.7,6.83C8.04,6.96,8.29,7.2,8.5,7.48
	C8.83,7.94,9.03,8.45,9,9.02c-0.02,0.32-0.1,0.62-0.35,0.85c-0.15,0.14-0.33,0.24-0.52,0.32c-0.14,0.06-0.29,0.11-0.4,0.21
	c-0.19,0.17-0.32,0.39-0.35,0.64c-0.02,0.15-0.01,0.3-0.05,0.46c-0.01,0.02,0.01,0.03,0.02,0.04c0.07,0.07,0.11,0.15,0.16,0.23
	c0.02,0.04,0.04,0.08,0.04,0.12c-0.01,0.29,0.03,0.59-0.03,0.89c-0.02,0.09-0.05,0.17-0.08,0.26c-0.03,0.13,0.01,0.25,0.02,0.37
	c0.09,0.67,0.27,1.33,0.45,1.98c0.07,0.24,0.14,0.48,0.18,0.74c0.16-0.18,0.32-0.34,0.47-0.5c0.21-0.23,0.45-0.45,0.65-0.69
	c0.2-0.24,0.36-0.5,0.52-0.76c0.16-0.23,0.34-0.45,0.52-0.66c0.1-0.13,0.24-0.18,0.39-0.21c0.25-0.04,0.49-0.11,0.72-0.19
	c0.07-0.03,0.13-0.06,0.15-0.14c0.02-0.11,0.09-0.19,0.12-0.3c0.07-0.27-0.04-0.49-0.18-0.7c-0.11-0.18-0.29-0.28-0.47-0.37
	c-0.16-0.08-0.29-0.17-0.38-0.32c-0.1-0.16-0.18-0.32-0.25-0.5c-0.12-0.29-0.08-0.57,0.06-0.84c0.17-0.35,0.44-0.61,0.78-0.79
	c0.18-0.09,0.37-0.14,0.57-0.14c0.13,0,0.27,0,0.4,0.02c0.03,0.01,0.05,0,0.07-0.02c0.1-0.09,0.22-0.19,0.28-0.32
	c0.04-0.07,0.08-0.13,0.13-0.18c0.25-0.28,0.59-0.13,0.69,0.14c0.06,0.15,0.1,0.3,0.14,0.46c0.03,0.11,0,0.22-0.06,0.31
	c-0.11,0.17-0.28,0.29-0.45,0.39c-0.02,0.01-0.05,0.02-0.05,0.04c0,0.03,0.04,0.03,0.06,0.03c0.07,0.03,0.11,0.07,0.1,0.15
	c0,0.06,0,0.11-0.02,0.17c-0.03,0.06-0.01,0.1,0.03,0.15c0.07,0.08,0.1,0.18,0.13,0.28s0.03,0.2,0.08,0.31
	c0.04,0.09,0.01,0.2-0.04,0.29c-0.04,0.08-0.09,0.15-0.16,0.21c-0.07,0.07-0.09,0.15-0.07,0.24c0.03,0.16,0.11,0.29,0.19,0.42
	c0.03,0.05,0.06,0.02,0.08-0.01c0.07-0.1,0.12-0.22,0.17-0.32c0.06-0.11,0.1-0.22,0.16-0.33c0.02-0.04,0.04-0.07,0.07-0.09
	c0.25-0.23,0.42-0.52,0.59-0.81c0.2-0.34,0.38-0.69,0.63-1c0.08-0.1,0.16-0.22,0.28-0.27c0.02-0.01,0.03-0.03,0.04-0.04
	c0.24-0.33,0.47-0.66,0.71-0.99c0.1-0.14,0.15-0.29,0.18-0.46c0.06-0.32,0.21-0.6,0.4-0.87c0.15-0.2,0.27-0.41,0.41-0.61
	c0.01-0.02,0.03-0.03,0.02-0.04c-0.03-0.09,0.04-0.16,0.07-0.24c0.14-0.32,0.27-0.62,0.41-0.94c0.03-0.07,0.05-0.14,0.12-0.18
	c0.05-0.03,0.05-0.11,0.05-0.16c0-0.07,0-0.14-0.02-0.2c-0.05-0.25-0.02-0.49,0.02-0.73c0.02-0.15,0.03-0.15-0.11-0.19
	c-0.33-0.09-0.66-0.17-0.99-0.23c-0.39-0.07-0.79-0.13-1.2-0.18c-0.68-0.08-1.37-0.08-2.06-0.05c-0.44,0.02-0.86,0.06-1.3,0.13
	c-0.47,0.07-0.93,0.16-1.38,0.28C9.84,3.77,9.13,4.02,8.44,4.33C7.73,4.66,7.05,5.04,6.4,5.48C5.74,5.93,5.12,6.44,4.55,7
	c-1,1-1.84,2.12-2.48,3.37c-0.33,0.62-0.6,1.27-0.82,1.93c-0.2,0.59-0.36,1.2-0.47,1.82c-0.09,0.53-0.16,1.06-0.19,1.6
	c-0.01,0.24-0.03,0.48-0.03,0.73c0,0.06-0.02,0.08-0.08,0.08c-0.13,0.01-0.25,0.01-0.38,0C0,16.53,0,16.53,0,16.42
	c0-0.23,0-0.46,0.02-0.69c0.03-0.48,0.08-0.95,0.15-1.42c0.07-0.45,0.16-0.89,0.28-1.32c0.13-0.49,0.29-0.98,0.47-1.45
	c0.25-0.65,0.55-1.29,0.9-1.9c0.38-0.66,0.8-1.29,1.28-1.88c0.74-0.9,1.57-1.71,2.5-2.41c0.67-0.49,1.37-0.93,2.11-1.3
	C8.22,3.8,8.75,3.58,9.29,3.39c0.39-0.14,0.78-0.26,1.17-0.36c0.52-0.13,1.04-0.24,1.58-0.31c0.36-0.05,0.72-0.07,1.08-0.11
	c0.49-0.05,0.98-0.04,1.47-0.02c0.32,0.02,0.65,0.05,0.98,0.09c0.4,0.05,0.79,0.12,1.19,0.19c0.28,0.06,0.55,0.12,0.82,0.2
	c0.09,0.03,0.09,0.03,0.11-0.06c0.04-0.2,0.02-0.41,0.04-0.61c0-0.04,0-0.07,0.01-0.11c0-0.05,0.03-0.08,0.08-0.08
	s0.09,0.02,0.11,0.07c0.02,0.05,0.03,0.09,0.03,0.14c0,0.17,0.01,0.33,0,0.5c-0.01,0.09,0,0.18,0.02,0.27C17.99,3.2,18,3.19,18,3.17
	c0.06-0.25,0.18-0.48,0.26-0.72c0.02-0.05,0.03-0.09,0.06-0.14c0.03-0.04,0.06-0.07,0.11-0.05c0.05,0.02,0.07,0.05,0.07,0.1
	c0,0.08-0.02,0.15-0.05,0.21c-0.07,0.21-0.15,0.41-0.19,0.62c0,0.02,0,0.04-0.01,0.06c0,0.03,0.02,0.04,0.04,0.05
	c0.03,0.01,0.04,0,0.05-0.02c0-0.01,0-0.01,0-0.02c0.05-0.18,0.18-0.33,0.25-0.5c0.03-0.08,0.09-0.14,0.15-0.19
	c0.06-0.06,0.13-0.03,0.15,0.06c0.01,0.07-0.02,0.13-0.05,0.19c-0.09,0.16-0.17,0.34-0.24,0.51c-0.01,0.03-0.03,0.06,0,0.08
	c0.04,0.02,0.04-0.02,0.06-0.04c0.08-0.13,0.18-0.22,0.29-0.33c0.04-0.03,0.08-0.06,0.13-0.03s0.03,0.09,0.01,0.14
	c-0.04,0.07-0.1,0.12-0.15,0.18C18.83,3.49,18.84,3.51,19,3.57c0.7,0.26,1.37,0.6,2.02,0.99c1.09,0.64,2.08,1.43,2.96,2.34
	c1.03,1.08,1.88,2.29,2.52,3.63c0.27,0.57,0.51,1.15,0.7,1.76c0.17,0.53,0.31,1.07,0.41,1.63c0.09,0.49,0.16,0.99,0.2,1.48
	c0.01,0.16,0.02,0.33,0.03,0.49c0,0.03,0,0.05,0.02,0.07c0,0.4,0,0.8,0,1.2c-0.03,0.07-0.03,0.15-0.03,0.22
	c-0.03,0.55-0.09,1.1-0.19,1.64c-0.07,0.4-0.16,0.8-0.27,1.2c-0.13,0.49-0.29,0.98-0.48,1.46c-0.35,0.89-0.8,1.74-1.32,2.54
	c-0.62,0.93-1.33,1.79-2.16,2.55c-0.49,0.46-1.01,0.88-1.57,1.26c-0.74,0.51-1.52,0.95-2.34,1.31c-0.51,0.23-1.04,0.42-1.57,0.58
	c-0.4,0.12-0.81,0.22-1.22,0.3s-0.82,0.15-1.24,0.19c-0.17,0.02-0.35,0.04-0.53,0.05c0.08,0.14,0.19,0.23,0.31,0.3
	c0.1,0.05,0.2,0.1,0.3,0.16c0.13,0.07,0.24,0.17,0.27,0.33c0.05,0.25-0.01,0.37-0.26,0.44c-0.33,0.09-0.66,0.09-1,0.04
	c-0.29-0.04-0.55-0.14-0.77-0.34c-0.11-0.1-0.23-0.22-0.34-0.33c-0.11-0.13-0.24-0.25-0.4-0.31c-0.23-0.07-0.46-0.11-0.68-0.24
	c0.03,0.18,0.06,0.34,0.11,0.49c0.06,0.17,0.14,0.33,0.16,0.51c0.04,0.28-0.06,0.44-0.34,0.49c-0.05,0.01-0.09,0-0.14,0.03
	C12.09,32,11.97,32,11.86,32L11.86,32z M27.31,16.4c0-0.33-0.02-0.75-0.06-1.18c-0.04-0.46-0.11-0.92-0.2-1.37
	c-0.1-0.49-0.23-0.97-0.38-1.44c-0.2-0.64-0.46-1.26-0.76-1.86c-0.45-0.9-0.99-1.74-1.63-2.52c-0.47-0.58-0.99-1.11-1.55-1.6
	c-0.51-0.45-1.05-0.86-1.63-1.22c-0.72-0.46-1.48-0.84-2.28-1.16c-0.06-0.03-0.08-0.02-0.09,0.05c-0.02,0.14-0.04,0.27-0.06,0.41
	c-0.03,0.19-0.07,0.38-0.23,0.52c-0.05,0.05-0.08,0.11-0.11,0.17c-0.01,0.02-0.02,0.03,0,0.05c0.04,0.03,0.02,0.06,0,0.09
	c-0.17,0.3-0.27,0.64-0.39,0.97c-0.04,0.11-0.04,0.24-0.16,0.32c-0.01,0.01-0.01,0.03-0.02,0.04c-0.14,0.49-0.33,0.97-0.46,1.47
	c-0.02,0.06-0.04,0.12-0.08,0.17c-0.09,0.11-0.17,0.23-0.24,0.35c-0.22,0.42-0.41,0.86-0.61,1.28c-0.02,0.04-0.03,0.08,0,0.13
	c0.05,0.07,0.06,0.14-0.02,0.2c-0.06,0.06-0.1,0.13-0.13,0.2c-0.09,0.29-0.22,0.57-0.33,0.85c-0.08,0.21-0.17,0.42-0.2,0.65
	c-0.05,0.37-0.02,0.74,0.06,1.11c0.19,0.9,0.44,1.78,0.77,2.65c0.35,0.91,0.8,1.77,1.17,2.66c0.11,0.27,0.25,0.52,0.38,0.78
	c0.02,0.04,0.04,0.09,0,0.13c-0.08,0.07-0.03,0.11,0,0.17c0.15,0.2,0.29,0.41,0.44,0.62c0.07,0.1,0.08,0.22,0.03,0.33
	c-0.02,0.04-0.03,0.07-0.05,0.11c-0.08,0.18-0.1,0.38-0.06,0.58c0.07,0.35,0.09,0.71,0.08,1.06c-0.01,0.3-0.02,0.6-0.03,0.9
	c-0.01,0.33,0.02,0.65-0.02,0.98c0,0.09-0.07,0.15-0.16,0.14c-0.06-0.01-0.06,0.02-0.06,0.06c-0.02,0.21-0.03,0.41-0.04,0.62
	c0,0.16,0.02,0.33-0.02,0.49c-0.07,0.3-0.23,0.55-0.48,0.72c-0.21,0.14-0.4,0.31-0.58,0.49c-0.52,0.55-1.06,1.06-1.65,1.54
	c-0.27,0.22-0.52,0.45-0.77,0.69c-0.14,0.15-0.32,0.25-0.45,0.4c-0.01,0.02-0.04,0.02-0.03,0.05c0.02,0.12,0.05,0.23,0.14,0.33
	c0.06,0.07,0.11,0.15,0.17,0.23c0.03,0.04,0.06,0.06,0.11,0.06c0.17-0.02,0.34-0.02,0.51-0.04c0.33-0.03,0.65-0.07,0.98-0.13
	c0.33-0.05,0.66-0.12,0.98-0.2c0.36-0.08,0.7-0.19,1.05-0.31c0.53-0.18,1.05-0.39,1.55-0.63c0.57-0.27,1.1-0.58,1.62-0.93
	c0.76-0.51,1.47-1.1,2.12-1.76c0.66-0.67,1.24-1.41,1.75-2.2c0.69-1.08,1.22-2.25,1.57-3.49c0.19-0.66,0.33-1.33,0.41-2.01
	C27.28,17.62,27.32,17.06,27.31,16.4L27.31,16.4z M8.8,9.02c-0.01-0.1-0.01-0.2-0.03-0.3C8.71,8.28,8.52,7.9,8.26,7.56
	c-0.24-0.3-0.52-0.53-0.9-0.63C7.19,6.89,6.98,6.92,6.88,7.06C6.83,7.14,6.79,7.23,6.76,7.32C6.65,7.68,6.69,8.04,6.76,8.4
	c0.08,0.38,0.26,0.71,0.5,1.02c0.21,0.26,0.46,0.48,0.8,0.54c0.23,0.04,0.43-0.03,0.55-0.23C8.74,9.51,8.79,9.27,8.8,9.02z
	 M12.35,29.52c0.09-0.13,0.16-0.25,0.23-0.37c0.07-0.13,0.16-0.26,0.26-0.36c0.07-0.07,0.16-0.11,0.27-0.08
	c0.03,0.01,0.06,0,0.08-0.02c0.14-0.08,0.26-0.19,0.36-0.31c0.11-0.14,0.24-0.26,0.37-0.38c0.04-0.04,0.09-0.05,0.13-0.09
	c0.14-0.2,0.29-0.39,0.44-0.59c0.05-0.06,0.09-0.13,0.14-0.19c-0.03,0-0.04,0.01-0.06,0.03c-0.57,0.5-1.16,0.98-1.76,1.45
	c-0.07,0.06-0.13,0.13-0.18,0.2c-0.03,0.05-0.07,0.08-0.12,0.09c-0.09,0.03-0.15,0.09-0.19,0.17c-0.02,0.03-0.04,0.06,0.02,0.08
	c0.04,0.02,0.04,0.06,0.02,0.1C12.31,29.32,12.33,29.41,12.35,29.52L12.35,29.52z M7.26,10.8c0.14-0.28,0.35-0.48,0.61-0.63
	c0.01-0.01,0.03-0.01,0.03-0.03s-0.03-0.02-0.04-0.02c-0.24-0.06-0.44-0.18-0.61-0.36C7.21,9.72,7.18,9.69,7.15,9.65
	C7.14,9.67,7.14,9.68,7.14,9.68c0.07,0.34,0.11,0.68,0.07,1.02C7.2,10.74,7.22,10.78,7.26,10.8z M16.1,25.2
	c0.19-0.11,0.38-0.18,0.58-0.23c0.04-0.01,0.05-0.02,0.03-0.06c-0.09-0.2-0.18-0.4-0.26-0.61c-0.02-0.04-0.04-0.04-0.07-0.04
	c-0.04,0-0.03,0.03-0.03,0.05c0,0.05,0,0.09,0,0.14c0,0.09-0.03,0.16-0.12,0.2c-0.04,0.02-0.04,0.04-0.05,0.08
	C16.15,24.88,16.12,25.04,16.1,25.2z M7.23,12.15c0.02-0.07-0.04-0.07-0.07-0.11C7.16,12.11,7.21,12.12,7.23,12.15z"
      />
      <path
        d="M1.87,16.53c0.04-2.98,1.01-5.62,2.95-7.9c0.29-0.34,0.61-0.66,0.93-0.97c0.04-0.04,0.07-0.05,0.11,0
	c0.03,0.04,0.07,0.08,0.11,0.11C6.1,7.88,6.12,8.01,6.13,8.15C6.14,8.37,6.19,8.59,6.26,8.8c0.02,0.04,0.01,0.07-0.03,0.11
	c-0.84,0.84-1.51,1.8-2.03,2.86c-0.5,1.02-0.82,2.08-0.99,3.2c-0.08,0.6-0.13,1.2-0.11,1.79c0.07,2.42,0.86,4.58,2.35,6.49
	c0.24,0.3,0.49,0.59,0.76,0.85c0.04,0.04,0.04,0.06,0,0.1c-0.25,0.26-0.5,0.52-0.74,0.78c-0.05,0.05-0.07,0.05-0.11,0
	c-1.3-1.33-2.26-2.87-2.86-4.64c-0.26-0.76-0.43-1.54-0.53-2.33C1.9,17.53,1.87,17.03,1.87,16.53L1.87,16.53z"
      />
      <path
        d="M6.63,10.33c0,0.12,0.01,0.25,0,0.37c-0.01,0.1-0.05,0.19-0.13,0.26c-0.16,0.13-0.26,0.3-0.32,0.5
	c-0.02,0.05-0.03,0.09-0.08,0.12C6,11.63,5.92,11.71,5.9,11.82c-0.02,0.13-0.11,0.22-0.14,0.34c-0.05,0.24-0.01,0.47,0.08,0.69
	c0.07,0.18,0.19,0.33,0.29,0.49c0.03,0.04,0.03,0.06,0.01,0.1c-0.24,0.6-0.41,1.23-0.5,1.87c-0.08,0.55-0.11,1.1-0.07,1.65
	c0.06,1.17,0.36,2.28,0.89,3.33c0.38,0.74,0.86,1.42,1.44,2.02c0.06,0.06,0.05,0.09,0,0.15c-0.25,0.25-0.49,0.5-0.72,0.76
	c-0.05,0.05-0.07,0.04-0.11,0c-0.91-0.93-1.6-2-2.07-3.21c-0.26-0.67-0.45-1.37-0.55-2.08c-0.1-0.68-0.13-1.35-0.08-2.03
	c0.14-1.9,0.77-3.62,1.92-5.15c0.11-0.15,0.22-0.28,0.34-0.43C6.61,10.33,6.62,10.33,6.63,10.33L6.63,10.33z"
      />
      <path
        d="M6.93,17.84c0.05,0.06,0.09,0.12,0.15,0.16c0.22,0.16,0.48,0.19,0.74,0.12c0.11-0.03,0.22-0.06,0.33-0.11
	c0.05-0.03,0.07-0.03,0.09,0.04c0.17,0.62,0.43,1.2,0.78,1.74c0.18,0.27,0.38,0.53,0.61,0.77c0.04,0.05,0.04,0.07-0.01,0.11
	c-0.25,0.26-0.5,0.51-0.74,0.78c-0.05,0.05-0.07,0.05-0.11,0c-0.87-0.93-1.46-2.01-1.77-3.24c-0.03-0.1-0.04-0.2-0.06-0.3
	C6.93,17.89,6.93,17.87,6.93,17.84L6.93,17.84z"
      />
      <path
        d="M8.21,15.04c-0.07-0.28-0.16-0.55-0.22-0.83c-0.03-0.16-0.07-0.31-0.1-0.47c-0.04-0.19-0.07-0.38-0.09-0.57
	c-0.01-0.1,0.06-0.2,0.07-0.3c0.02-0.11,0.07-0.2,0.13-0.29c0.34-0.5,0.72-0.94,1.16-1.35c0.04-0.04,0.06-0.04,0.1,0.01
	c0.25,0.26,0.5,0.53,0.75,0.79c0.04,0.05,0.04,0.06-0.01,0.1c-0.66,0.59-1.17,1.3-1.52,2.12C8.38,14.5,8.3,14.77,8.21,15.04
	L8.21,15.04z"
      />
      <path
        d="M17.59,1.03c-0.04,0-0.07,0-0.09-0.03c-0.03-0.03-0.06,0.02-0.1,0c-0.15-0.07-0.26-0.17-0.31-0.34
	c-0.09-0.28,0.09-0.6,0.39-0.65C17.54,0,17.6-0.01,17.66,0.02c0.04,0.02,0.09,0,0.14,0.02c0.21,0.1,0.32,0.3,0.29,0.55
	C18.04,0.84,17.84,1.02,17.59,1.03L17.59,1.03z"
      />
      <path
        d="M25.98,16.53c-0.05,3.28-1.19,6.11-3.49,8.47c-0.05,0.05-0.07,0.05-0.12,0c-0.25-0.26-0.49-0.52-0.74-0.78
	c-0.05-0.05-0.04-0.07,0-0.11c1.31-1.34,2.22-2.91,2.72-4.72c0.17-0.61,0.28-1.24,0.34-1.88c0.06-0.61,0.06-1.23,0.01-1.85
	c-0.19-2.19-0.96-4.14-2.32-5.87C22.05,9.39,21.69,9,21.31,8.64c-0.04-0.04-0.04-0.05,0-0.09c0.26-0.26,0.51-0.52,0.77-0.79
	c0.04-0.04,0.06-0.04,0.1,0c1.52,1.45,2.6,3.17,3.25,5.16c0.2,0.65,0.35,1.31,0.44,1.99C25.93,15.44,25.97,15.98,25.98,16.53
	L25.98,16.53z"
      />
      <path
        d="M23.51,16.53c-0.02,2.21-0.7,4.19-2.06,5.93c-0.2,0.27-0.43,0.51-0.67,0.75c-0.04,0.04-0.06,0.04-0.1,0
	c-0.25-0.27-0.5-0.53-0.75-0.79c-0.05-0.05-0.04-0.07,0-0.11c0.85-0.9,1.49-1.92,1.89-3.1c0.22-0.65,0.36-1.32,0.41-2
	c0.17-2.33-0.5-4.38-2-6.16c-0.19-0.23-0.41-0.45-0.63-0.65c-0.04-0.04-0.04-0.06,0-0.1c0.26-0.26,0.51-0.52,0.77-0.79
	c0.03-0.04,0.05-0.04,0.09-0.01c1.24,1.17,2.12,2.56,2.63,4.19c0.18,0.59,0.31,1.19,0.37,1.81C23.49,15.85,23.51,16.19,23.51,16.53
	L23.51,16.53z"
      />
      <path
        d="M21.04,16.53c-0.03,1.88-0.67,3.52-1.95,4.9c-0.05,0.05-0.06,0.04-0.11,0c-0.12-0.09-0.12-0.23-0.15-0.36
	c-0.04-0.16,0-0.32,0.06-0.46c0.12-0.27,0.1-0.52-0.07-0.76c-0.03-0.04-0.01-0.07,0.01-0.09c0.25-0.37,0.45-0.77,0.6-1.19
	c0.19-0.53,0.31-1.08,0.35-1.65c0.08-1.33-0.24-2.54-0.97-3.65c-0.26-0.4-0.58-0.77-0.93-1.09c-0.04-0.04-0.05-0.06,0-0.11
	c0.26-0.26,0.51-0.52,0.76-0.78c0.04-0.04,0.06-0.05,0.11-0.01c1.05,0.98,1.76,2.17,2.1,3.57C20.98,15.4,21.04,15.96,21.04,16.53
	L21.04,16.53z"
      />
    </>
  );

  return (
    <svg
      x="0px"
      y="0px"
	  className={
        `VirtualTennis ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27.86 32.04"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      {icon}
    </svg>
  );
};

export default memo(VirtualTennis);
