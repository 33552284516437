import React, { useCallback } from 'react';
import CheckboxDiv from '../../../../components/Form/CheckboxDiv/CheckboxDiv';

const ByRiskCategories = ({ betScroller, onChangeRisk, riskCategories }) => {
  const checkRisk = useCallback(
    e => {
      const { id } = e.target;
      const filterRisks = [...betScroller.riskCategories];
      onChangeRisk({
        ...betScroller,
        riskCategories: filterRisks.includes(id)
          ? filterRisks.filter(risk => risk !== id)
          : [...betScroller.riskCategories, id]
      });
    },
    [onChangeRisk, betScroller]
  );

  return (
    <div
      className={`m-auto m-2 ${
        riskCategories.length > 10 ? 'overflow-scroll-box' : ''
      }`}
    >
      {riskCategories.map(riskCategory => {
        return (
          <div className="d-flex" key={riskCategory.name}>
            <CheckboxDiv
              id={riskCategory.id}
              label={riskCategory.name}
              name={riskCategory.name}
              onChange={checkRisk}
              isChecked={betScroller.riskCategories.includes(riskCategory.id)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ByRiskCategories;