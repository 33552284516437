import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../../components/Form/Checkbox';
import {findPrivilege, findApplication, findView} from "./rolesUtils";

class RoleSection extends Component {

	checkIfActionIsActive = (action, view, application) => {
		let privIdx = findPrivilege(this.props.newRole, action),
			isActive = false;
		if(this.props.newRole.privileges[privIdx].applications.length) {
			let appIdx = findApplication(this.props.newRole.privileges, privIdx, application.label),
				viewIdx = findView(this.props.newRole.privileges[privIdx].applications, appIdx, view.label);

			if(viewIdx !== -1) isActive = true;
		}

		return isActive;
	};

	checkIfViewIsActive = (appLabel, viewLabel) => {
		let actions = [];
		this.props.newRole.privileges.forEach((privilege, idx) => {
			let appIndex = privilege.applications.findIndex(app => app.label === appLabel);
			if (appIndex !== -1) {
				let viewIdx = privilege.applications[appIndex].views.findIndex(view => view.label === viewLabel);
				actions = viewIdx !== -1 ? [...actions, privilege.action] : [...actions];
			}
		});

		if(!actions.length) {
			return true;
		}
	};

	render() {
		let {application, handleActionsChange, newRole} = this.props;
		return (
			<section className='efb-app-view-priv-container role-section'>
				<section style={{width: '20%'}}>
					{newRole.name}
				</section>
				<section style={{width: '30%'}}>
					{application.label}
				</section>
				<section style={{flexDirection: 'column', width: '50%'}} className='efb-app-view-priv'>
					{
						application.views.map((view, idx) =>
							<section className='efb-app-view-priv__view-priv' key={idx}>
								<div className={this.checkIfViewIsActive(application.label, view.label) ? 'efb-app-view-priv__view-priv__view disabled' : 'efb-app-view-priv__view-priv__view'}>
									<span>
										{view.label}
									</span>
								</div>
								<div className='efb-app-view-priv__view-priv__actions'>
									<span>{/*{transformedRole.domainObject}*/}</span>
									<div>
										<Checkbox
											type="userActivity"
											readOnly={this.props.readOnly}
											isActive={this.checkIfActionIsActive('READ', view, application)}
											name='read'
											label='Read'
											disabled={this.props.readOnly}
											onChange={() => handleActionsChange('READ', view, application)}
										/>
										<Checkbox
											type="userActivity"
											readOnly={this.props.readOnly}
											isActive={this.checkIfActionIsActive('WRITE', view, application)}
											name='write'
											label='Write'
											disabled={this.props.readOnly}
											onChange={() => handleActionsChange('WRITE', view, application)}
										/>
									</div>
								</div>
							</section>
						)
					}
				</section>
			</section>
		)
	}
}

RoleSection.propTypes = {
	application: PropTypes.object,
	handleActionsChange: PropTypes.func,
	newRole: PropTypes.object
};

export default RoleSection;