import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';

import Table from '../../../../../components/NewTable/Table';
import Input from '../../../../../components/Form/Input';
import Switch from '../../../../../components/Form/Switch/Switch';
import Buttons from '../../../../../components/ModalButtons/Buttons';

import * as CustomerActions from '../../../../../redux/actions/customer/customerManager';

import {
  useFetch,
  useUpdate,
  useStateForInput,
  useStateForSwitch,
} from '../../../../../hooks';
import classes from './PaymentDetails.module.scss';

const PaymentDetails = ({ customer, updateCustomerAW, onClose }) => {
  const [autoWithdraw, setAutoWithdraw] = useStateForInput('');
  const [autoWithdrawEnable, setAutoWithdrawEnable] = useStateForSwitch('');

  const { data: paymentDetails } = useFetch(
    `/customer/view/admin/${customer.customerId}/payment-methods`
  );

  const { data: globalStatus, refetch } = useFetch(
    `/customer/admin/${customer.customerId}/global-status`
  );

  const columns = useMemo(
    () => [
      { name: 'Name', display: () => customer.user?.username },
      {
        name: 'Number',
        display: payment => payment.paymentMethodDetails[0].description,
      },
      { name: 'Type', display: payment => payment.paymentMethod },
      {
        name: 'Active',
        display: payment => (
          <div
            className={
              payment.paymentProviderBusinessUnit.enabled
                ? classes.active
                : classes.inactive
            }
          />
        ),
      },
      {
        name: 'Deposit',
        display: payment => (
          <div
            className={
              payment.paymentProviderBusinessUnit.depositEnabled
                ? classes.active
                : classes.inactive
            }
          />
        ),
      },
      {
        name: 'Withdraw',
        display: payment => (
          <div
            className={
              payment.paymentProviderBusinessUnit.withdrawEnabled
                ? classes.active
                : classes.inactive
            }
          />
        ),
      },
    ],
    [customer.user?.username]
  );

  useUpdate(() => {
    if (globalStatus && globalStatus.autoWithdrawSettings) {
      setAutoWithdrawEnable(
        globalStatus.autoWithdrawSettings.autoWithdrawEnabled
      );
      setAutoWithdraw(globalStatus.autoWithdrawSettings.autoWithdrawLimit);
    }
  }, [globalStatus]);

  const saveChanges = useCallback(async () => {
    await updateCustomerAW(
      {
        autoWithdrawEnabled: autoWithdrawEnable,
        autoWithdrawLimit: autoWithdraw,
      },
      customer.customerId
    );
    await refetch();
  }, [
    autoWithdraw,
    autoWithdrawEnable,
    customer.customerId,
    refetch,
    updateCustomerAW,
  ]);

  return (
    <>
      <div className={classes.wrapper}>
        <h1 className={classes.title}>Payment Providers</h1>
        {paymentDetails && paymentDetails.length > 0 && (
          <Table columns={columns} data={paymentDetails} pagination={false} />
        )}
        <div className={classes.autoWithdraw}>
          <div className={classes.formLayout}>
            <label>Auto withdraw limit:</label>
            <Input
              customInput
              name="autoWithdraw"
              type="number"
              min="0"
              className="efb-input__underlined"
              onChange={setAutoWithdraw}
              value={autoWithdraw}
            />
          </div>
          <div>
            <Switch
              label="Auto Withdraw"
              isChecked={autoWithdrawEnable}
              onChange={setAutoWithdrawEnable}
              smallSwitch
            />
          </div>
        </div>
      </div>
      <Buttons
        apply={saveChanges}
        ok={() => {
          saveChanges();
          onClose();
        }}
        cancel={onClose}
      />
    </>
  );
};

const mapDispatchToProps = {
  updateCustomerAW: CustomerActions.updateCustomerAW,
};

export default connect(null, mapDispatchToProps)(PaymentDetails);
