import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Close from '../Icons/Close';
import classes from './ReferralModal.module.scss';

const ReferralModal = ({
	isActive,
	onClose,
	firstName,
	lastName,
	gender,
	customerName,
	myClassName = '',
	children,
	small,
	big,
	fullscreen,
	customerId,
	channel,
	country
}) => {
	const timeout = { enter: 500, exit: 300 };

	return (
		<TransitionGroup className="efb-modal__animation" appear={true}>
			{isActive ? (
				<CSSTransition timeout={timeout}>
					<section className={'efb-modal ' + myClassName}>
						<div className="efb-modal__overlay">
							<div
								className={`efb-modal__wrapper ${classes.wrapper} ${small ? 'small' : ''}${big
									? 'big'
									: ''}${fullscreen ? 'fullscreen' : ''}`}
							>
								<header className="efb-modal__header referral-modal-header">
									<h1>
										Client Risk:{' '}
										<strong>
											{gender === 'MALE' ? 'Mr.' : 'Mrs.'}{' '}
											<span className="uppercase">{lastName}</span>,{' '}
											<span className="capitalize">{firstName}</span>{' '}
										</strong>{' '}
										<i className={`fas ${channel === 'WEB' ? 'fa-desktop' : 'fa-mobile-alt'}`} />
										{/* TODO: will be used later */}
										<div className={classes.headerFlagIcon}>
											<i className={`flag-icon flag-icon-${country ? country.toLowerCase() : 'bg'}`} />
										</div>
									</h1>
									<strong className="customer-info">{`[${customerId} / ${customerName}]`}</strong>
									<span onClick={onClose}>
										<Close color="black" size="xs" />
									</span>
								</header>
								{children}
							</div>
						</div>
					</section>
				</CSSTransition>
			) : null}
		</TransitionGroup>
	);
};

export default ReferralModal;
