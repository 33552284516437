import React from 'react';

const Button = ({
  cssClass,
  children,
  BtnType = 'button',
  onClick,
  disabled,
  title,
}) => (
  <BtnType
    className={disabled ? cssClass + ' disabled' : cssClass}
    onClick={onClick}
    disabled={disabled}
    title={title}
  >
    {children}
  </BtnType>
);

export default Button;
