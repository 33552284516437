import React from 'react';
import { useFetch } from '../../../../hooks';
import classes from './CustomerDetails.module.scss';

const CustomerSportCalculations = ({ customerId }) => {
  const { data: sportStats } = useFetch(
    'betslip/view/admin/sport/quick-stats',
    {
      customerId,
    },
    [customerId]
  );

  if (!sportStats) return null;

  return (
    <div className="col-md-4">
      <div className={classes.header}>
        <p>Sport</p>
      </div>
      <div className={classes.body}>
        <div className="d-flex">
          <div className="col-md-5">
            <p>Avg. Bet</p>
          </div>
          <div className="col-md-4 text-right">
            <p>{sportStats.averageBets}</p>
          </div>
          <div className="col-md-3" />
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <p>Bets count</p>
          </div>
          <div className="col-md-4 text-right">
            <p>{sportStats.betsCount}</p>
          </div>
          <div className="col-md-3" />
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <p>Bets count (30 days)</p>
          </div>
          <div className="col-md-4 text-right">
            <p>{sportStats.lastThirtyDaysBetsCount}</p>
          </div>
          <div className="col-md-3" />
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <p>Freebets</p>
          </div>
          <div className="col-md-4 text-right">
            <p>{sportStats.freeBets}</p>
          </div>
          <div className="col-md-3" />
        </div>
        <br />
        <div className="d-flex">
          <div className="col-md-5">
            <b>W/L (7 days)</b>
          </div>
          <div className="col-md-4 text-right">
            <p className={classes.greenNumber}>{sportStats.winLossSevenDays}</p>
          </div>
          <div className="col-md-3 text-right">
            <b>{sportStats.netWinLossSevenDaysPercentage} %</b>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <b>W/L (30 days)</b>
          </div>
          <div className="col-md-4 text-right">
            <p className={classes.greenNumber}>
              {sportStats.winLossThirtyDays}
            </p>
          </div>
          <div className="col-md-3 text-right">
            <b>{sportStats.netWinLossThirtyDaysPercentage} %</b>
          </div>
        </div>
      </div>
      <div className={classes.sum}>
        <div className="d-flex">
          <div className="col-md-5">
            <b>Stake</b>
          </div>
          <div className="col-md-4 text-right">
            <p>{sportStats.stake}</p>
          </div>
          <div className="col-md-3" />
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <b>Payout</b>
          </div>
          <div className="col-md-4 text-right">
            <p>{sportStats.payout}</p>
          </div>
          <div className="col-md-3" />
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <b>Net W/L</b>
          </div>
          <div className="col-md-4 text-right">
            <p className={classes.greenNumber}>{sportStats.netWinLoss}</p>
          </div>
          <div className="col-md-3" />
        </div>
      </div>
    </div>
  );
};

export default CustomerSportCalculations;
