import React, { useCallback } from 'react';
import classes from './CustomerDetails.module.scss';
import { useFetch } from '../../../../hooks';

const accountTypes = {
  MAIN: 'Current Balance',
  LOCK_FUND: 'Locked Funds',
  SPORT_BET: 'Sport Bets',
  CASINO_BONUS: 'Casino Bonus',
  SPORT_BONUS: 'Sport Bonus',
  FREE_BET: 'Free Balance',
};

const prettifyAccountNames = type => {
  return accountTypes[type];
};

const CustomerSummary = ({ customer }) => {
  const { data: accounts } = useFetch(
    `account/view/admin/accounts/${customer.customerId}`
  );
  const { data: userSettings } = useFetch(
    `riskmgnt/view/admin/userSettings/findByCustomerId?customerId=${customer.customerId}`
  );

  const renderAccount = useCallback(account => {
    if (!prettifyAccountNames(account.accountType)) {
      return null;
    }

    return (
      <div key={account.accountId} className="col-md">
        <div className={classes.borderDivider}>
          <div>
            <p>{prettifyAccountNames(account.accountType)}:</p>
            <b>
              {Number(account['balance'] / 100).toFixed(2)}{' '}
              <span className="text-muted">{account['currencyCode']}</span>
            </b>
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <div className={`${classes.customerMoney}`}>
      {accounts ? accounts.map(renderAccount) : null}

      <div className="col-md-1">
        <div className={classes.borderDivider}>
          <div>
            <p>Bet Factor:</p>
            <b>{userSettings ? userSettings.betFactor : 0}</b>
          </div>
        </div>
      </div>
      <div className="col-md-1">
        <div className={`${classes.borderDivider} border-0`}>
          <div>
            <p>Status:</p>
            <span
              className={`${classes.statusCircle} ${
                customer && customer.user?.active
                  ? classes.active
                  : classes.inactive
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSummary;
