import React from 'react';
import classes from './SettlementWrapper.module.scss';

const SettlementWrapper = () => {
  return (
    <div className={classes.settlement}>
      <div className={classes.settData}>
        <div className={classes.row}>
          <div>Tx. Type:</div>
          <div>Receipt</div>
        </div>
        <div className={classes.row}>
          <div>Inst. Type:</div>
          <div>WireCard</div>
        </div>
        <div className={classes.row}>
          <div>Sum Payment</div>
          <div>10 000 EUR</div>
        </div>
        <div className={classes.row}>
          <div>Tx. State:</div>
          <div>FAILED</div>
        </div>
      </div>
      <div className={classes.settData}>
        <div className={classes.row}>
          <div>Tx. Amount:</div>
          <div>10 000 EUR</div>
        </div>
        <div className={classes.row}>
          <div>Ext Acc. No.:</div>
          <div>75321321</div>
        </div>
        <div className={classes.row}>
          <div>Sum Receipt</div>
          <div>10 000 EUR</div>
        </div>
        <div></div>
      </div>
      <div className={classes.settData}>
        <div className={classes.row}>
          <div>Data Requested:</div>
          <div>10/11/ 09:34:42</div>
        </div>
        <div className={classes.row}>
          <div>Requested Amount:</div>
          <div>10 000 EUR</div>
        </div>
        <div className={classes.row}>
          <div>Ext. Refference:</div>
          <div>10 000 EUR</div>
        </div>
        <div></div>
      </div>
      <div className={classes.extAdditional}>
        <div>Ext. Additional</div>
      </div>
    </div>
  );
};

export default SettlementWrapper;
