import PropTypes from 'prop-types';
import React, { Fragment, useCallback } from 'react';
import { Button } from '../../Layout';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const TransactionTableRow = ({
  tableData,
  tab,
  editCurrency,
  showDetails,
  viewFullSize,
  detail = false,
  history,
}) => {
  const checkToday = useCallback(
    date => moment(date).format('YYYY/MM/DD') !== moment().format('YYYY/MM/DD'),
    []
  );

  const showAllAccHistory = useCallback(
    transaction => {
      history.push(
        `/adm/pm/manager?accountHistoryCustomerId=${transaction.customerId}`
      );
    },
    [history]
  );

  const renderRow = transaction => {
    return (
      <tr
        index={transaction.transactionId}
        key={transaction.id ? transaction.id : transaction.transactionId}
      >
        {tab === 'transactions' ? (
          <Fragment>
            {detail ? (
              <Fragment>
                <td>{transaction.transactionId}</td>
                <td>{transaction.customerUsername}</td>
                <td>{Number(transaction.amount / 100).toFixed(2)}</td>
                <td>
                  {moment(transaction.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                </td>
                <td>{transaction.status}</td>
                <td>{transaction.transactionIpAddress}</td>
                <td>{transaction.externalRef}</td>
                <td>{transaction.externalAccountId}</td>
              </Fragment>
            ) : (
              <Fragment>
                <td>
                  {moment(transaction.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                </td>
                <td>{transaction.customerUsername}</td>
                <td>{transaction.currency}</td>
                <td>{Number(transaction.amount / 100).toFixed(2)}</td>
                <td>{transaction.type}</td>
                <td>{transaction.status}</td>
                <td>{transaction.paymentMethod}</td>
                <td>
                  <Button
                    cssClass="btn btn-info"
                    onClick={() => showDetails(transaction)}
                  >
                    <i className="fas fa-info" /> Show details
                  </Button>
                </td>
              </Fragment>
            )}
          </Fragment>
        ) : null}
        {tab === 'pendingTransactions' ? (
          <Fragment>
            <td>
              {moment(transaction.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </td>
            <td
              className="pointer"
              onClick={() => showAllAccHistory(transaction)}
            >
              {transaction.customerId}
            </td>
            <td
              className="pointer"
              onClick={() => showAllAccHistory(transaction)}
            >
              {transaction.customerUsername}
            </td>
            <td>{`${transaction.customerFirstName} ${transaction.customerLastName}`}</td>
            <td>{transaction.currencyCode}</td>
            <td>{Number(transaction.amount / 100).toFixed(2)}</td>
            <td>{transaction.paymentMethod}</td>
            <td>{transaction.channel}</td>
            <td>{transaction.transactionIpAddress}</td>
            <td>
              <Button
                cssClass="btn btn-danger btn-md"
                onClick={() => viewFullSize(transaction)}
              >
                <i className="fas fa-edit" /> Review
              </Button>
            </td>
            <td>
              <Button
                cssClass="btn btn-danger btn-md"
                onClick={() => showAllAccHistory(transaction)}
              >
                <i className="fas fa fa-arrow-right" /> Deposit/Withdraw
              </Button>
            </td>
            <td>
              <Button
                cssClass="btn btn-danger btn-md"
                onClick={() => showAllAccHistory(transaction)}
              >
                <i className="fas fa fa-arrow-right" /> History
              </Button>
            </td>
          </Fragment>
        ) : null}
        {tab === 'currencyRates' ? (
          <Fragment>
            <td>{transaction.name}</td>
            <td>
              {moment(transaction.lastUpdatedOn).format('DD/MM/YYYY HH:mm:ss')}
            </td>
            <td>{transaction.code}</td>
            <td>{transaction.rate}</td>
            <td>
              <Button
                disabled={checkToday(transaction.createdOn)}
                className="disabled btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
                onClick={() => editCurrency(transaction)}
              >
                <i className="fas fa-edit" /> Edit
              </Button>
            </td>
          </Fragment>
        ) : null}
      </tr>
    );
  };
  return tableData.map(renderRow);
};

TransactionTableRow.propTypes = {
  detail: PropTypes.bool,
  editCurrency: PropTypes.func,
  showDetails: PropTypes.func,
  viewFullSize: PropTypes.func,
  getAccountHistory: PropTypes.func,
  tab: PropTypes.string,
  tableData: PropTypes.array,
};

export default React.memo(withRouter(TransactionTableRow));
