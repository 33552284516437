import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';

import * as CashoutManagerActions from '../../../../redux/actions/riskManagement/cashoutManager';

import Switch from '../../../../components/Form/Switch/Switch';
import Button from '../../../../components/Layout/Button';
import classes from './CashoutConfigurator.module.scss';

import { selectStyles } from '../../../CMS/customReactSelectStyle';
import {
  useFetch,
  useStateForSelect,
  useStateForSwitch,
} from '../../../../hooks';

const RiskCategoryConfigurator = ({ createOrUpdateRiskCategoryConfig }) => {
  const [riskCategory, setRiskCategory] = useStateForSelect('');
  const [enabled, setEnabled, resetEnabled] = useStateForSwitch(false);
  const { data: riskCategories } = useFetch(
    '/riskmgnt/view/admin/riskCategories'
  );

  const { data: riskCategoryConfig, refetch } = useFetch(
    '/cashout/admin/config/riskCategory'
  );

  const riskCategoryOptions = useMemo(() => {
    return (
      riskCategories &&
      riskCategories.map(el => ({
        value: el.name,
        label: el.name,
        id: el.id,
      }))
    );
  }, [riskCategories]);

  const config = useMemo(() => {
    if (riskCategory) {
      const config = riskCategoryConfig.find(
        conf => conf.riskCategoryId === riskCategory.id
      );
      if (config) {
        resetEnabled(config.enabled);
      }
      return config;
    }
    // eslint-disable-next-line
  }, [riskCategory, riskCategoryConfig]);

  const createConfig = useCallback(async () => {
    await createOrUpdateRiskCategoryConfig({
      changedDate: moment(),
      createdDate: moment(),
      name: riskCategory.label,
      id: config ? config.id : null,
      riskCategoryId: riskCategory.id,
      enabled,
    });
    await refetch();
  }, [
    config,
    createOrUpdateRiskCategoryConfig,
    enabled,
    refetch,
    riskCategory,
  ]);

  if (!riskCategories) return null;
  return (
    <div className="p-5">
      <div className="form-row justify-content-center">
        <Select
          onChange={setRiskCategory}
          value={riskCategory}
          options={riskCategoryOptions}
          placeholder="Select Risk Category"
          styles={selectStyles}
          className="my-4 col-md-8"
        />
      </div>
      {riskCategory ? (
        <>
          <div className="d-flex justify-content-around pt-3">
            <h3 className="efb-h3">{riskCategory.value}</h3>
            <Button cssClass="efb-btn__main" onClick={createConfig}>
              {config ? 'Update' : 'Create'} config
            </Button>
          </div>
          <hr />
          {config ? (
            <>
              <div
                className={classes.config}
                style={{ justifyItems: 'center' }}
              >
                <h5>Bet Type</h5>
                <h5>Id</h5>
                <h5>Disable/Enable</h5>
                <div>{config.name}</div>
                <div>{config.id}</div>
                <div>
                  <Switch
                    id="config"
                    name="config"
                    isChecked={enabled}
                    onChange={setEnabled}
                  />
                </div>
              </div>
              <hr />
            </>
          ) : (
            <div className="d-flex justify-content-center py-4">
              <h3 className="efb-h3">
                There is no config for this risk category!
              </h3>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = {
  createOrUpdateRiskCategoryConfig:
    CashoutManagerActions.createOrUpdateRiskCategoryConfig,
};

export default connect(null, mapDispatchToProps)(RiskCategoryConfigurator);
