import React, { useState, useCallback, Fragment } from 'react';
import Input from '../../../Form/Input';
import classes from './InputsFilter.module.scss';

const InputsFilter = ({ fields, label, onFilterChange: pushFilterChange }) => {
  const [inputs, setInputs] = useState(
    fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.name]: '',
      }),
      {}
    )
  );

  const handleChange = useCallback(
    e => {
      const { name, value } = e.target;
      setInputs({
        ...inputs,
        [name]: value,
      });
      pushFilterChange({ name, value });
    },
    [inputs, pushFilterChange]
  );

  return (
    <div className={classes.inputsFromTo}>
      <label>{label}</label>
      {Object.keys(inputs).map((input, i) => (
        <Fragment key={i}>
          <div>
            <Input
              type="number"
              customInput
              name={input}
              className={classes.inputs}
              value={inputs[input] || ''}
              onChange={handleChange}
            />
          </div>
          {i % 2 === 0 ? <div>to</div> : null}
        </Fragment>
      ))}
    </div>
  );
};

export default InputsFilter;
