import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import * as CashoutManagerActions from '../../../../redux/actions/riskManagement/cashoutManager';

import classes from './CashoutConfigurator.module.scss';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import Button from '../../../../components/Layout/Button';
import Switch from '../../../../components/Form/Switch/Switch';

import { useStateForSwitch, useFetch, useUpdate } from '../../../../hooks';

const MarketEventConfigurator = ({
  createOrUpdateSportEventConfig,
  createOrUpdateMarketConfig,
}) => {
  const [currentEntity, setCurrentEntity] = useState(null);
  const [entityType, setEntityType] = useState(null);
  const [enabled, setEnabled, resetEnabled] = useStateForSwitch(false);

  const { data: sportConfig, refetch: refetchSportEvents } = useFetch(
    '/cashout/admin/config/sportEvent'
  );
  const { data: marketConfig, refetch: refetchMarkets } = useFetch(
    '/cashout/admin/config/market'
  );
  const { data: sports } = useFetch('/sport/event/view/sport/admin');

  const updateEntity = useMemo(
    () => ({
      event: {
        update: createOrUpdateSportEventConfig,
        id: 'sportEventId',
        refetch: refetchSportEvents,
        config: sportConfig,
      },
      market: {
        update: createOrUpdateMarketConfig,
        id: 'marketId',
        refetch: refetchMarkets,
        config: marketConfig,
      },
    }),
    [
      createOrUpdateMarketConfig,
      createOrUpdateSportEventConfig,
      marketConfig,
      refetchMarkets,
      refetchSportEvents,
      sportConfig,
    ]
  );

  const configuration = useMemo(() => {
    if (updateEntity[entityType] && currentEntity) {
      const { config, id } = updateEntity[entityType];
      return config.find(conf => conf[id] === currentEntity.id);
    }
  }, [currentEntity, updateEntity, entityType]);

  useUpdate(() => {
    if (configuration) {
      resetEnabled(configuration.enabled);
    }
  }, [configuration]);

  const createConfig = useCallback(async () => {
    const { update, refetch, id } = updateEntity[entityType];
    await update({
      changedDate: moment(),
      createdDate: moment(),
      id: configuration ? configuration.id : null,
      name: currentEntity.names.en.name,
      [id]: currentEntity.id,
      enabled,
    });
    await refetch();
  }, [configuration, currentEntity, enabled, entityType, updateEntity]);

  const treeProps = useMemo(
    () => ({
      onEntityClick: (entity, entityType) => {
        setCurrentEntity(entity);
        setEntityType(entityType);
      },
      showMarkets: true,
    }),
    []
  );

  if (!sports) return null;

  return (
    <div className="d-flex">
      <div className="col-md-3">
        <Sidebar treeProps={treeProps} />
      </div>
      <div className="col-md-9">
        {currentEntity ? (
          <>
            <div className="d-flex justify-content-around pt-5">
              <h3 className="efb-h3">{currentEntity.names.en.name}</h3>
              <Button
                cssClass="efb-btn__main"
                onClick={createConfig}
                disabled={entityType !== 'event' && entityType !== 'market'}
              >
                {configuration ? 'Update' : 'Create'} config
              </Button>
            </div>
            <hr />
          </>
        ) : null}

        <div className="p-5">
          {configuration && currentEntity && updateEntity[entityType] ? (
            <div className={classes.config}>
              <h4>ID</h4>
              <h4>Created Date</h4>
              <h4>Disabled/Enabled</h4>
              <div>{currentEntity.id}</div>
              <div>
                {moment(currentEntity.createdDate).format('YYYY-MM-DD HH:mm')}
              </div>
              <div>
                <Switch
                  id="config"
                  name="config"
                  isChecked={enabled}
                  onChange={setEnabled}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  createOrUpdateSportEventConfig:
    CashoutManagerActions.createOrUpdateSportEventConfig,
  createOrUpdateMarketConfig: CashoutManagerActions.createOrUpdateMarketConfig,
};

export default connect(null, mapDispatchToProps)(MarketEventConfigurator);
