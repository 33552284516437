import React, { useState, useCallback } from 'react';
import Button from '../../../components/Layout/Button';
import Switch from '../../../components/Form/Switch/Switch';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useUpdate } from '../../../hooks';
import InputField from '../../Form/InputField';

const StaticPageTableRow = ({
  tableData,
  updatePageStatus,
  saveEditedName,
  showSubpages,
  pagesListLang,
  extendPageContent,
  prepareRegularPageEdit,
  deletePage,
}) => {
  const [pages, setPages] = useState([]);

  useUpdate(() => {
    setPages(tableData);
  }, [tableData]);

  const handleInputChange = useCallback(
    (e, index) => {
      const pagesClone = [...pages];
      pagesClone[index].name = e.target.value;
      setPages(pagesClone);
    },
    [pages]
  );

  const setStatus = useCallback(
    (e, page, index) => {
      const pagesClone = [...pages];
      pagesClone[index].enabled = e.target.checked;
      setPages(pagesClone);
      updatePageStatus(e.target.checked, page, index);
    },
    [pages, updatePageStatus]
  );

  const editPage = useCallback(
    (index, status) => {
      const pagesClone = [...pages];
      pagesClone[index].editMode = status;
      setPages(pagesClone);
    },
    [pages]
  );

  const saveName = useCallback(
    (e, index) => {
      e.preventDefault();
      const pagesClone = [...pages];
      pagesClone[index].editMode = false;
      saveEditedName(pagesClone[index]);
      setPages(pagesClone);
    },
    [pages, saveEditedName]
  );

  const mapPages = useCallback(
    (page, index) => {
      let assignContentBtn, editRegularPageBtn;
      if (page.children) {
        if (page.children.length === 0) {
          assignContentBtn = (
            <Button
              cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
              disabled={page.resourceType !== 'ROOT' && !page.children}
              onClick={() => extendPageContent(page)}
            >
              <i className="fas fa-plus" /> Add subpage
            </Button>
          );
        } else if (page.children.length > 0) {
          if (page.children[0].resourceType === 'REGULAR') {
            editRegularPageBtn = (
              <Button
                cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
                onClick={() =>
                  prepareRegularPageEdit(
                    page.children[0].id,
                    page.children[0].url
                  )
                }
              >
                <i className="fas fa-edit" /> Edit Regular page
              </Button>
            );
          } else {
            assignContentBtn = (
              <Button
                cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
                disabled={page.resourceType !== 'ROOT' && !page.children}
                onClick={() => extendPageContent(page, pagesListLang)}
              >
                <i className="fas fa-plus" /> Add subpage
              </Button>
            );
          }
        }
      }
      return (
        <tr key={page.id}>
          <td>{index + 1}</td>
          <td>
            <i
              className={
                pagesListLang.value === 'en'
                  ? 'flag-icon flag-icon-gb'
                  : `flag-icon flag-icon-${pagesListLang.value}`
              }
            />
          </td>
          <td>
            {page.editMode ? (
              <div className="efb-form static-subpage-edit-input">
                <InputField
                  value={page.name}
                  name="name"
                  onChange={e => handleInputChange(e, index)}
                />
              </div>
            ) : page.name !== '' ? (
              page.name
            ) : (
              '/'
            )}
          </td>
          <td>
            <Switch
              isDisabled={page.editMode}
              name={page.language}
              isChecked={page.enabled}
              value={page.enabled}
              onChange={e => setStatus(e, page, index)}
            />
          </td>
          <td>{moment(page.createdOn).format('DD/MM/YYYY HH:mm:ss')}</td>
          <td>
            {page.createdOn === page.updatedOn
              ? '/'
              : moment(page.updatedOn).format('DD/MM/YYYY HH:mm:ss')}
          </td>
          <td>
            {page.editMode ? (
              <>
                <Button
                  cssClass="btn btn-sm btn-DEMO-del-val btn-primary"
                  onClick={e => saveName(e, index)}
                >
                  Save
                </Button>
                <Button
                  cssClass="btn btn-sm btn-DEMO-edit-val btn-secondary"
                  onClick={() => editPage(index, false)}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
                  onClick={() => editPage(index, true)}
                >
                  <i className="fas fa-edit" /> Edit page name
                </Button>
                {assignContentBtn} {editRegularPageBtn}
                {page.children ? (
                  page.children.length > 0 ? (
                    page.children[0].resourceType !== 'REGULAR' ? (
                      <Button
                        cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
                        onClick={() => showSubpages(page)}
                      >
                        <i className="fas fa-eye" /> Show subpages
                      </Button>
                    ) : null
                  ) : null
                ) : null}
                <Button
                  cssClass="btn btn-sm btn-outline-danger btn-DEMO-del-val"
                  onClick={() => deletePage(page.id)}
                >
                  <i className="fa fa-trash" /> Delete
                </Button>
              </>
            )}
          </td>
        </tr>
      );
    },
    [
      deletePage,
      editPage,
      extendPageContent,
      handleInputChange,
      pagesListLang,
      prepareRegularPageEdit,
      saveName,
      setStatus,
      showSubpages,
    ]
  );

  return pages.map(mapPages);
};

StaticPageTableRow.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  editPage: PropTypes.func,
  saveEditedName: PropTypes.func,
  showSubpages: PropTypes.func,
  tableData: PropTypes.array,
  tableStyle: PropTypes.string,
  tableType: PropTypes.string,
  updatePageStatus: PropTypes.func,
};

export default StaticPageTableRow;
