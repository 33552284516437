import React, { memo } from 'react';

export const Basketball = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Basketball ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141 141"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <circle className={'Ball'} cx="70.5" cy="70.5" r="70.5" />
        <path
          className={'Stripe'}
          d="M117.27,17.74l-29,29A66.07,66.07,0,0,1,71.82,0,70.26,70.26,0,0,1,117.27,17.74Z"
        />
        <path
          className={'Stripe'}
          d="M82.23,52.78,70.5,64.51,23.73,17.74A70.16,70.16,0,0,1,63.32.36,74.5,74.5,0,0,0,82.23,52.78Z"
        />
        <path
          className={'Stripe'}
          d="M64.51,70.5,52.78,82.23A74.49,74.49,0,0,0,.36,63.32,70.16,70.16,0,0,1,17.74,23.73Z"
        />
        <path
          className={'Stripe'}
          d="M46.78,88.22l-29,29A70.26,70.26,0,0,1,0,71.82,66.07,66.07,0,0,1,46.78,88.22Z"
        />
        <path
          className={'Stripe'}
          d="M69.18,141a70.25,70.25,0,0,1-45.45-17.74l29-29A66.11,66.11,0,0,1,69.18,141Z"
        />
        <path
          className={'Stripe'}
          d="M117.27,123.26a70.18,70.18,0,0,1-39.59,17.38,74.5,74.5,0,0,0-18.9-52.42L70.5,76.49Z"
        />
        <path
          className={'Stripe'}
          d="M94.22,52.78l29-29A70.26,70.26,0,0,1,141,69.18,66.11,66.11,0,0,1,94.22,52.78Z"
        />
        <path
          className={'Stripe'}
          d="M123.26,117.27,76.49,70.5,88.22,58.78a74.52,74.52,0,0,0,52.42,18.9A70.16,70.16,0,0,1,123.26,117.27Z"
        />
      </g>
    </svg>
  );
};

export default memo(Basketball);
