import { GET_RISK_CATEGORIES } from '../../actions/riskManagement/RiskCategory';

const initialState = {
  riskCategories: []
};

export const riskCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RISK_CATEGORIES.SUCCESS:
      return {
        ...state,
        riskCategories: action.payload
      };
    default:
      return state;
  }
};
