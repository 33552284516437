import axiosInstance from './axios-instance';
import { actionCreator, createRequestTypes } from './index';
import moment from 'moment';
import { addNotificationMessage } from './ui';

export const GET_CURRENCIES = createRequestTypes('GET_CURRENCIES');
export const GET_STATES = createRequestTypes('GET_STATES');
export const GET_TYPES = createRequestTypes('GET_TYPES');
export const GET_PAYMENTS = createRequestTypes('GET_PAYMENTS');
export const TRANSACTIONS = createRequestTypes('GET_TRANSACTIONS');
export const PENDINGTRANSACTIONS = createRequestTypes(
  'GET_PENDINGTRANSACTIONS'
);
export const GET_DOWNLOADED_CURRENCY = createRequestTypes(
  'GET_DOWNLOADED_CURRENCY'
);
export const CHANGE_CURRENCY_RATE = createRequestTypes('CHANGE_CURRENCY_RATE');
export const APPROVE_OR_REJECT = createRequestTypes('APPROVE_OR_REJECT');
export const GET_PAYMENT_PROVIDERS = createRequestTypes(
  'GET_PAYMENT_PROVIDERS'
);
export const GET_FILTERED_TRANSACTIONS = createRequestTypes(
  'GET_FILTERED_TRANSACTIONS'
);
export const AUTOMATIC_WITHDRAW_STATUS = createRequestTypes(
  'AUTOMATIC_WITHDRAW_STATUS'
);
export const GET_USED_CURRENCIES = createRequestTypes('GET_USED_CURRENCIES');
export const GET_CURRENCY_LIMIT = createRequestTypes('GET_CURRENCY_LIMIT');
export const GET_PAYMENT_PROVIDERS_FOR_BUSINESS_UNIT = createRequestTypes(
  'GET_PAYMENT_PROVIDERS_FOR_BUSINESS_UNIT'
);
export const ENABLE_OR_DISABLE_PAYMENT_PROVIDER = createRequestTypes(
  'ENABLE_OR_DISABLE_PAYMENT_PROVIDER'
);
export const GET_PREFERENCES = createRequestTypes('GET_PREFERENCES');

export const GET_AVAILABLE_CURRENCY = createRequestTypes(
  'GET_AVAILABLE_CURRENCY'
);

export const getCurrencies = () => async dispatch => {
  try {
    const res = await axiosInstance.get('/account/view/public/currencies');
    dispatch(actionCreator.success(GET_CURRENCIES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_CURRENCIES, ''));
  }
};

export const changeCurrencyRate = currency => async dispatch => {
  try {
    const res = await axiosInstance.put('currency/exchange/', currency);
    dispatch(actionCreator.success(CHANGE_CURRENCY_RATE, res));
    dispatch(
      addNotificationMessage(
        'Currency rate changed successfully',
        'success',
        'Success'
      )
    );
    dispatch(getDownloadedCurrency(moment()));
  } catch (err) {
    dispatch(actionCreator.failure(CHANGE_CURRENCY_RATE, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getDownloadedCurrency = date => async dispatch => {
  date = moment(date).format('YYYY-MM-DD');
  try {
    const res = await axiosInstance.get(
      `/currency/exchange/downloaded?date=${date}`
    );
    dispatch(actionCreator.success(GET_DOWNLOADED_CURRENCY, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_DOWNLOADED_CURRENCY, ''));
  }
};

export const getStates = () => async dispatch => {
  try {
    const res = await axiosInstance.get(
      '/account/view/admin/transaction-states'
    );
    dispatch(actionCreator.success(GET_STATES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_STATES, ''));
  }
};

export const getTransactions = filter => async dispatch => {
  try {
    let res = await axiosInstance.get(
      `/account/view/admin/transactions?${new URLSearchParams(
        filter
      ).toString()}`
    );
    res = {
      content: res.content.filter(
        trc => trc.type === 'DEPOSIT' || trc.type === 'WITHDRAW'
      ),
    };
    dispatch(actionCreator.success(TRANSACTIONS, res));
  } catch (err) {
    dispatch(actionCreator.failure(TRANSACTIONS, ''));
  }
};

export const getPendingTransaction = filter => async dispatch => {
  try {
    const res = await axiosInstance.get(
      `/account/view/admin/transactions/pending-withdraws?${new URLSearchParams(
        filter
      ).toString()}`
    );
    dispatch(actionCreator.success(PENDINGTRANSACTIONS, res));
  } catch (err) {
    dispatch(actionCreator.failure(PENDINGTRANSACTIONS, ''));
  }
};

export const emptyPendingTransaction = () => async dispatch => {
  try {
    dispatch(actionCreator.empty(PENDINGTRANSACTIONS));
  } catch (err) {
    dispatch(actionCreator.failure(PENDINGTRANSACTIONS, ''));
  }
};

export const emptyTransaction = () => async dispatch => {
  try {
    dispatch(actionCreator.empty(TRANSACTIONS));
  } catch (err) {
    dispatch(actionCreator.failure(TRANSACTIONS, ''));
  }
};

export const emptyCurrencies = () => async dispatch => {
  try {
    dispatch(actionCreator.empty(GET_DOWNLOADED_CURRENCY));
  } catch (err) {
    dispatch(actionCreator.failure(GET_DOWNLOADED_CURRENCY, ''));
  }
};

export const getTypes = () => async dispatch => {
  try {
    const res = await axiosInstance.get(
      '/account/view/admin/transaction-types'
    );
    dispatch(actionCreator.success(GET_TYPES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_TYPES, ''));
  }
};

export const getPayments = () => async dispatch => {
  try {
    const res = await axiosInstance.get('/payment/view/public/payments');
    dispatch(actionCreator.success(GET_PAYMENTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_PAYMENTS, ''));
  }
};

export const approveOrReject = (obj, id) => async dispatch => {
  try {
    const res = await axiosInstance.post(
      `/transaction/admin/${id}/approval`,
      obj
    );
    dispatch(actionCreator.success(APPROVE_OR_REJECT, res));
    dispatch(
      getPendingTransaction({
        pageNumber: 1,
        pageSize: 100000000,
      })
    );
  } catch (err) {
    dispatch(actionCreator.failure(APPROVE_OR_REJECT, ''));
  }
};

export const getPaymentProviders = businessUnit => async dispatch => {
  try {
    const res = await axiosInstance.get(
      `/manager/provider/${businessUnit}/get`
    );
    dispatch(actionCreator.success(GET_PAYMENT_PROVIDERS, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_PAYMENT_PROVIDERS, ''));
  }
};

const checkPaymentStatus = paymentId => dispatch => {
  const myInterval = setInterval(async () => {
    const res = await axiosInstance.get(
      `/payment/view/admin/transactions/${paymentId}/status`
    );

    if (res !== 'PENDING') {
      getAllPendingTransactions()(dispatch);
      clearInterval(myInterval);
    }
  }, 500);
};

export const resolvePayment = (paymentId, data) => async dispatch => {
  try {
    await axiosInstance.post(`/payment/admin/${paymentId}/acceptance`, data);
    checkPaymentStatus(paymentId)(dispatch);
    dispatch(
      addNotificationMessage(
        'Successfully resolved payment',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    dispatch(actionCreator.failure(GET_PAYMENT_PROVIDERS, ''));
  }
};

export const getAWStatus = () => async dispatch => {
  dispatch(actionCreator.request(AUTOMATIC_WITHDRAW_STATUS));
  try {
    const res = await axiosInstance.get(
      '/payment_provider/admin/manager/global/auto-withdraw/status'
    );
    dispatch(actionCreator.success(AUTOMATIC_WITHDRAW_STATUS, res));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    dispatch(actionCreator.failure(AUTOMATIC_WITHDRAW_STATUS, ''));
  }
};

export const changeAwGlobalStatus = status => async dispatch => {
  try {
    await axiosInstance.post(
      `/payment_provider/admin/manager/global/auto-withdraw/status/${status}`
    );
    dispatch(getAWStatus());
    dispatch(
      addNotificationMessage(
        `Successfully changed AW Global status to ${
          status ? 'active' : 'inactive'
        }`,
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getAllCurrencies = () => async dispatch => {
  dispatch(actionCreator.request(GET_USED_CURRENCIES));
  try {
    const res = await axiosInstance.get('/currency/exchange/used');
    dispatch(actionCreator.success(GET_USED_CURRENCIES, res));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    dispatch(actionCreator.failure(GET_USED_CURRENCIES, ''));
  }
};

export const getAwStatusByCurrency = currency => async dispatch => {
  dispatch(actionCreator.request(GET_CURRENCY_LIMIT));
  try {
    const res = await axiosInstance.get(
      '/payment_provider/admin/manager/global/auto-withdraw'
    );
    dispatch(actionCreator.success(GET_CURRENCY_LIMIT, res));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    dispatch(actionCreator.failure(GET_CURRENCY_LIMIT, ''));
  }
};

export const saveAwCurrencyLimit = limit => async dispatch => {
  try {
    await axiosInstance.post(
      '/payment_provider/admin/manager/global/auto-withdraw',
      limit
    );
    dispatch(
      addNotificationMessage(
        `Successfully changed AW ${limit.currencyCode} limit`,
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getPaymentProviderForBSUnits = bsUnit => async dispatch => {
  dispatch(actionCreator.request(GET_PAYMENT_PROVIDERS_FOR_BUSINESS_UNIT));
  try {
    const res = await axiosInstance.get(
      `/payment_provider/admin/manager/provider/${bsUnit}`
    );
    dispatch(
      actionCreator.success(GET_PAYMENT_PROVIDERS_FOR_BUSINESS_UNIT, res)
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    dispatch(
      actionCreator.failure(GET_PAYMENT_PROVIDERS_FOR_BUSINESS_UNIT, '')
    );
  }
};

export const getPreferences = id => async dispatch => {
  dispatch(actionCreator.request(GET_PREFERENCES));
  try {
    const res = await axiosInstance.get(
      `/payment_provider/admin/manager/provider/preferences/${id}`
    );
    dispatch(actionCreator.success(GET_PREFERENCES, res));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    dispatch(actionCreator.failure(GET_PREFERENCES, ''));
  }
};

export const addPreferences = paymentPreference => async dispatch => {
  try {
    await axiosInstance.post(
      '/payment_provider/admin/manager/provider/preferences/update',
      paymentPreference
    );
    dispatch(
      addNotificationMessage(
        'Successfully updated current preference!',
        'success',
        'Success'
      )
    );
    dispatch(getPreferences(paymentPreference.id));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updatePaymentProvider = payment => async dispatch => {
  try {
    await axiosInstance.post(
      '/payment_provider/admin/manager/provider/update',
      payment
    );
    dispatch(
      addNotificationMessage(
        'Successfully updated current payment provider!',
        'success',
        'Success'
      )
    );
    dispatch(getPaymentProviderForBSUnits(payment.businessUnit));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateCurrencyPerProvider = currencyObj => async dispatch => {
  try {
    await axiosInstance.post(
      '/payment_provider/admin/manager/provider/currency',
      currencyObj
    );
    dispatch(
      getAvailableCurrency(currencyObj.providerId, currencyObj.currencyCode)
    );
    dispatch(
      addNotificationMessage(
        'Successfully updated currency to payment provider!',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getAvailableCurrency = (
  providerId,
  currency
) => async dispatch => {
  dispatch(actionCreator.request(GET_AVAILABLE_CURRENCY));
  try {
    const res = await axiosInstance.get(
      `/payment_provider/admin/manager/provider/${providerId}/currency/${currency}`
    );
    dispatch(actionCreator.success(GET_AVAILABLE_CURRENCY, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_AVAILABLE_CURRENCY, ''));
  }
};

export const empetyAvailableCurrency = () => async dispatch => {
  try {
    dispatch(actionCreator.empty(GET_AVAILABLE_CURRENCY));
  } catch (err) {
    dispatch(actionCreator.failure(GET_AVAILABLE_CURRENCY, ''));
  }
};

export const enableWithdraw = (
  providerId,
  enabled,
  bsUnit
) => async dispatch => {
  try {
    await axiosInstance.post(
      `/payment_provider/admin/manager/provider/${providerId}/withdraw/enable/${enabled}`,
      {
        providerId,
        enabled,
      }
    );
    const enableOrDisable = enabled ? 'enabled' : 'disabled';
    dispatch(getPaymentProviderForBSUnits(bsUnit));
    dispatch(
      addNotificationMessage(
        `Withdraw is ${enableOrDisable} for this payment provider!`,
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const enableDeposit = (
  providerId,
  enabled,
  bsUnit
) => async dispatch => {
  try {
    await axiosInstance.post(
      `/payment_provider/admin/manager/provider/${providerId}/deposit/enable/${enabled}`,
      {
        providerId,
        enabled,
      }
    );
    const enableOrDisable = enabled ? 'enabled' : 'disabled';
    dispatch(getPaymentProviderForBSUnits(bsUnit));
    dispatch(
      addNotificationMessage(
        `Deposit is ${enableOrDisable} for this payment provider!`,
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getAllPendingTransactions = () => async dispatch => {
  dispatch(actionCreator.request(GET_FILTERED_TRANSACTIONS));
  try {
    const res = await axiosInstance.get(
      '/payment/view/admin/transactions?paymentStatus=PENDING'
    );
    dispatch(actionCreator.success(GET_FILTERED_TRANSACTIONS, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_FILTERED_TRANSACTIONS, ''));
  }
};

export const createOrUpdateFee = (feeObj, bsUnit) => async dispatch => {
  try {
    await axiosInstance.post(
      '/payment_provider/admin/manager/provider/fee',
      feeObj
    );
    dispatch(getPaymentProviderForBSUnits(bsUnit));
    dispatch(
      addNotificationMessage(
        'Successfully created fee to payment provider!',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};
