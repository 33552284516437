import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import CSVReader from 'react-csv-reader';
import { useUpdate } from '../../../hooks';

const CSVData = ({
  translations,
  language,
  setCsvTranslations,
  setImportedCSV,
}) => {
  const [csvData, setCsvData] = useState([]);
  const [csvKey, setCsvKey] = useState(0);

  useUpdate(() => {
    prepareCSVForExport(translations);
  }, [translations]);

  const prepareCSVForExport = useCallback(translations => {
    setCsvData([]);
    setTimeout(() => {
      const csvDataClone = [];
      if (translations && translations.length > 0) {
        translations.forEach(tr => {
          csvDataClone.push([tr.key, tr.value]);
        });
        setCsvData(csvDataClone);
      }
    }, 10);
  }, []);

  const importCSV = useCallback(
    data => {
      const importedTranslations = [];
      data.forEach((translation, index) => {
        if (translation[1]) {
          importedTranslations.push({
            id: index + 1,
            key: translation[0],
            value: translation[1],
          });
        }
      });
      setCsvTranslations(importedTranslations);
      setImportedCSV(true);
      setCsvKey(csvKey + 1);
    },
    [csvKey, setCsvTranslations, setImportedCSV]
  );

  const content = useMemo(() => {
    const headersArr = [['Translation Key'], ['Translation Value']];
    if (csvData) {
      return (
        <div className="col-md text-right">
          <CSVLink
            headers={headersArr}
            data={csvData}
            id="exportToCSV"
            className={'mr-1 ml-1 bet-scroller-btn ok-btn btn no-width'}
            filename={
              translations.length > 0 ? language + '_translations.csv' : ''
            }
          >
            <i className="fa fa-download" /> Export as CSV
          </CSVLink>

          <div
            className={
              'mr-1 ml-1 bet-scroller-btn ok-btn btn no-width csv-import-btn'
            }
            id="csv-input-wrapper"
            key={csvKey}
            style={{ position: 'relative' }}
          >
            <i className="fa fa-upload" /> Import from CSV
            <CSVReader
              className={'mr-1 ml-1 bet-scroller-btn ok-btn btn no-width'}
              onFileLoaded={importCSV}
              inputStyle={{
                color: 'red',
                opacity: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
      );
    }
  }, [csvData, csvKey, importCSV, language, translations.length]);

  return content;
};

CSVData.propTypes = {
  translations: PropTypes.array,
  csvResponse: PropTypes.func,
};

export default CSVData;
