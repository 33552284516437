import React from 'react';

export const BackOffice = (props) => {
    return (
	<span className='efb-icon'>
    	<svg className={`efb-icon--${props.size} ${props.cssClass}`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 128 128" >
	
        <path fill="#91BAE1" d="M79.2,0h38.3c5.8,0,10.5,4.7,10.5,10.5V34c0,5.8-4.7,10.5-10.5,10.5H79.2c-5.8,0-10.5-4.7-10.5-10.5V10.5
	        C68.8,4.7,73.5,0,79.2,0z"/>

        <path fill="#8697CB" d="M10,0h39.2c5.5,0,10,4.5,10,10v54c0,5.5-4.5,10-10,10H10C4.5,74,0,69.5,0,64V10C0,4.5,4.5,0,10,0z"/>
        <path fill="#556080" d="M10.8,83.6h37.7c5.9,0,10.8,4.8,10.8,10.8v22.9c0,5.9-4.8,10.8-10.8,10.8H10.8C4.8,128,0,123.2,0,117.2V94.3
	        C0,88.4,4.8,83.6,10.8,83.6z"/>

        <path fill="#FEB719" stroke="#FEB719" strokeLinejoin="round" strokeMiterlimit="10" d="M118,60.2c2.1,0,3.7,1.7,3.7,3.7v54
	        c0,2.1-1.7,3.7-3.7,3.7H78.8c-2.1,0-3.7-1.7-3.7-3.7V64c0-2.1,1.7-3.7,3.7-3.7H118 M118,54H78.8c-5.5,0-10,4.5-10,10v54
	        c0,5.5,4.5,10,10,10H118c5.5,0,10-4.5,10-10V64C128,58.5,123.5,54,118,54L118,54z"/>
    	</svg>
        
		{props.children}
	</span>
    )
}

export default BackOffice;