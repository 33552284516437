import { GET_PAGES, GET_STATIC_PAGE_IMAGES, GET_SUBPAGE_CONTENT, GET_REGULAR_PAGE_CONTENT, GET_COUNTRIES, GET_CURRENCIES } from '../../../redux/actions/cms/staticPages';

const initialState = {
    pages: [],
    images: [],
    subpageContent: {},
    regularPageContent: '',
    availableCountries: [],
    currencies: []
};

export const staticPagesReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_PAGES.SUCCESS:
            return {
                ...state,
                pages: action.payload
            }

        case GET_STATIC_PAGE_IMAGES.SUCCESS:
            return {
                ...state,
                images: action.payload
            }

        case GET_SUBPAGE_CONTENT.SUCCESS:
            return {
                ...state,
                subpageContent: action.payload
            }

        case GET_REGULAR_PAGE_CONTENT.SUCCESS:
            return {
                ...state,
                regularPageContent: action.payload
            }

        case GET_COUNTRIES.SUCCESS:
            return {
                ...state,
                availableCountries: action.payload
            }

        case GET_CURRENCIES.SUCCESS: 
            return {
                ...state,
                currencies: action.payload
            }

        default:
            return state;
    }
}