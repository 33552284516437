import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as CashoutManagerActions from '../../../../redux/actions/riskManagement/cashoutManager';
import Input from '../../../../components/Form/Input';
import {
  useStateForInput,
  useStateForSelect,
  useUnmount,
} from '../../../../hooks';
import { selectStyles } from '../../../CMS/customReactSelectStyle';
import { getCashoutManagerData } from '../../../../selectors';
import { Button } from '../../../../components/Layout';

const typeOptions = [
  { label: 'Single', value: '_BET_SINGLE' },
  { label: 'System', value: '_BET_SYSTEM' },
];

const CashoutCalculator = ({
  location,
  testCashout,
  clearFormulaResult,
  formulaResult,
}) => {
  const [type, setType] = useStateForSelect(typeOptions[0]);
  const [bet, setBet] = useStateForInput(undefined);
  const [timeCurrent, setTimeCurrent] = useStateForInput(undefined);
  const [timeTotal, setTimeTotal] = useStateForInput(undefined);
  const [coefficientInitial, setCoefficientInitial] = useStateForInput(
    undefined
  );
  const [coefficientCurrent, setCoefficientCurrent] = useStateForInput(
    undefined
  );

  const param = useMemo(
    () => new URLSearchParams(location.search).get('param'),
    [location.search]
  );

  useUnmount(() => {
    clearFormulaResult();
  });

  const calculateCashout = useCallback(() => {
    const cashoutType = param + type.value;

    testCashout(cashoutType, {
      testLine: {
        timeCurrent:
          timeCurrent !== undefined ? Number(timeCurrent) : undefined,
        timeTotal: timeTotal !== undefined ? Number(timeTotal) : undefined,
        coefficientInitial:
          coefficientInitial !== undefined
            ? Number(coefficientInitial)
            : undefined,
        coefficientCurrent:
          coefficientCurrent !== undefined
            ? Number(coefficientCurrent)
            : undefined,
      },
      bet: Number(bet) * 100,
    });
  }, [
    bet,
    coefficientCurrent,
    coefficientInitial,
    param,
    testCashout,
    timeCurrent,
    timeTotal,
    type.value,
  ]);

  return (
    <div className="efb-form m-auto w-75">
      <Select
        options={typeOptions}
        value={type}
        onChange={setType}
        styles={selectStyles}
      />

      {param === 'IN_PLAY' ? (
        <>
          <div className="mt-3 d-flex flex-row align-items-center">
            <label className="w-25 text-center" htmlFor="timeCurrent">
              Time Current (play time - in min)
            </label>
            <Input
              type="number"
              id="timeCurrent"
              name="timeCurrent"
              className="form-control w-75"
              value={timeCurrent}
              onChange={setTimeCurrent}
            />
          </div>

          <div className="mt-3 d-flex flex-row align-items-center">
            <label className="w-25 text-center" htmlFor="timeTotal">
              Time Total (in min)
            </label>
            <Input
              type="number"
              id="timeTotal"
              name="timeTotal"
              className="form-control w-75"
              value={timeTotal}
              onChange={setTimeTotal}
            />
          </div>
        </>
      ) : null}

      <div className="mt-3 d-flex flex-row align-items-center">
        <label className="w-25 text-center" htmlFor="coefficientInitial">
          Odd Initial
        </label>
        <Input
          type="number"
          step={0.01}
          id="coefficientInitial"
          name="coefficientInitial"
          className="form-control w-75"
          value={coefficientInitial}
          onChange={setCoefficientInitial}
        />
      </div>

      <div className="mt-3 d-flex flex-row align-items-center">
        <label className="w-25 text-center" htmlFor="coefficientCurrent">
          Odd Current
        </label>
        <Input
          type="number"
          step={0.01}
          id="coefficientCurrent"
          name="coefficientCurrent"
          className="form-control w-75"
          value={coefficientCurrent}
          onChange={setCoefficientCurrent}
        />
      </div>

      <div className="mt-3 d-flex flex-row align-items-center">
        <label className="w-25 text-center" htmlFor="bet">
          Bet
        </label>
        <Input
          type="number"
          id="bet"
          name="bet"
          className="form-control w-75"
          value={bet}
          onChange={setBet}
        />
      </div>

      <div className="d-flex flex-column align-items-center justify-content-center mt-5">
        {formulaResult ? (
          <div>
            <p>
              Result is:{' '}
              <span className="text-primary font-weight-bold h4">
                {Number(Number(formulaResult) / 100).toFixed(2)}
              </span>
            </p>
          </div>
        ) : null}

        <Button cssClass="efb-btn__main" onClick={calculateCashout}>
          Calculate
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  formulaResult: getCashoutManagerData(state).formulaResult,
});

const mapDispatchToProps = {
  testCashout: CashoutManagerActions.testCashout,
  clearFormulaResult: CashoutManagerActions.clearFormulaResult,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CashoutCalculator));
