import React, { Fragment, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Button from '../../../components/Layout/Button';
import { selectOptions } from './Utils';
import { selectStyles } from '../../CMS/customReactSelectStyle';
import { isEmpty } from '../../../utils/helpers';
import { useStateForSelect, useUpdate } from '../../../hooks';
import InputField from '../../../components/Form/InputField';

const CreateValidationType = ({
  types,
  languages,
  documentTypes,
  verificationDocuments,
  addNotificationMessage,
}) => {
  const allLangs = useMemo(() => {
    return languages.reduce((obj, lang) => {
      return {
        ...obj,
        [lang.languageCode]: '',
      };
    }, {});
  }, [languages]);

  const documentTypesOptions = useMemo(() => {
    return selectOptions(documentTypes);
  }, [documentTypes]);

  const [docType, setDocType] = useStateForSelect('');
  const [lang, setLang] = useState(allLangs);

  useUpdate(() => {
    const documentType = types.find(
      type => type.documentType === docType.value
    );
    if (documentType) {
      const newDocument = {};
      for (let document in documentType.names) {
        newDocument[document] = documentType.names[document].name;
      }
      setLang({ ...newDocument });
    } else {
      setLang(allLangs);
    }
  }, [docType]);
  
  const clearForm = useCallback(() => {
    setDocType('');
    setLang(allLangs);
  }, [setDocType, allLangs]);

  const handleChange = useCallback(
    e => {
      const { value, name } = e.target;
      setLang({
        ...lang,
        [name]: value,
      });
    },
    [lang]
  );

  const validateForm = useCallback(() => {
    if (isEmpty(docType)) {
      addNotificationMessage('Document type is required!', 'error', 'Error');
      return false;
    }
    if (Object.keys(lang).every(x => !lang[x])) {
      addNotificationMessage(
        'At least one field is required!',
        'error',
        'Error'
      );
      return false;
    }
    return true;
  }, [addNotificationMessage, docType, lang]);

  const saveAllDocuments = useCallback(() => {
    const documentObj = {
      names: {},
      type: docType.value,
    };
    for (let lng in lang) {
      if (lang[lng]) {
        documentObj.names = {
          ...documentObj.names,
          [lng]: {
            name: lang[lng].toLowerCase(),
            translated: true,
          },
        };
      }
    }
    if (validateForm()) {
      verificationDocuments(documentObj);
    }
  }, [lang, docType.value, validateForm, verificationDocuments]);

  const mapLangs = useCallback(
    item => {
      return (
        <div className="form-label-group col-md" key={item.languageCode}>
          <InputField
            id={item.languageCode}
            type="text"
            className="form-control efb-input"
            placeholder="Document name"
            value={lang[item.languageCode] || ''}
            name={item.languageCode}
            onChange={handleChange}
            label={
              <>
                {item.display_name}{' '}
                <i
                  className={`flag-icon flag-icon-${
                    item.languageCode === 'en' ? 'gb' : item.languageCode
                  }`}
                />
              </>
            }
          />
        </div>
      );
    },
    [handleChange, lang]
  );

  return (
    <Fragment>
      <h2 className="my-4">Add documents</h2>
      <Select
        onChange={setDocType}
        value={docType}
        options={documentTypesOptions}
        placeholder="Select document type"
        styles={selectStyles}
        className="my-4"
      />
      <div className="efb-form">
        <div className="form-row">{languages.map(mapLangs)}</div>
      </div>
      <hr />
      <div className="form-row">
        <Button onClick={clearForm} cssClass="efb-btn__main">
          Clear
        </Button>
        <Button onClick={saveAllDocuments} cssClass="efb-btn__main">
          Update
        </Button>
      </div>
    </Fragment>
  );
};

CreateValidationType.propTypes = {
  types: PropTypes.array,
  languages: PropTypes.array,
  documentTypes: PropTypes.array,
  verificationDocuments: PropTypes.func,
  addNotificationMessage: PropTypes.func,
};

export default CreateValidationType;