import React from 'react';
import Table from '../../../../components/Table/Table';

const MarketTypesTable = props => {
  const columns = [
    { name: 'Name', value: 'name', type: 'string', sortable: true },
    { name: 'Tournament', value: 'tournamentName', type: 'string' },
    { name: 'Category', value: 'categoryName', type: 'string' },
    { name: 'Sport', value: 'sportType', type: 'string' },
    { name: 'Market Type', value: 'marketType', type: 'string' },
    { name: 'Max Book Loss', value: 'mbl', type: 'string' },
    { name: 'Max Singles Takeout', value: 'mst', type: 'string' },
    { name: 'Max Takeout', value: 'maxTakeout', type: 'string' },
    { name: 'MSF - Count', value: 'msfCount', type: 'string' },
    { name: 'MSF - Duration (in minutes)', value: 'msfDuration', type: 'string' },
    { name: 'Actions', value: 'status', type: 'buttons' }
  ];
  const tableStyle =
    'sortable table table-hover table-striped efb-table efb-data-table';

  return (
    <Table
      columns={columns}
      tableStyle={tableStyle}
      tableType="marketTypesTable"
      {...props}
    />
  );
};

export default MarketTypesTable;
