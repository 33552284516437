import React, { Component } from 'react';

import Pagination from './Pagination';
import PropTypes from 'prop-types';
import SearchBar from './SearchBar';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import _ from 'lodash';

export class Table extends Component {
  state = {
    sorting: {
      column: this.props.sortBy || 'createdAt',
      order: this.props.order || 'desc',
    },
    search: '',
    currentPage: this.props.currentPage || 1,
    pageSize: this.props.pageSize || 10,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.pageSize !== this.props.pageSize) {
      this.setState({
        pageSize: this.props.pageSize,
      });
    }
    if (prevProps.currentPage !== this.props.currentPage) {
      this.setState({
        currentPage: this.props.currentPage,
      });
    }
    if (prevProps.data !== this.props.data) {
      const { totalCount } = this.getModifiedData();
      const lastPage = Math.ceil(
        this.props.itemsCount || totalCount / this.state.pageSize
      );

      if (this.state.currentPage === 0 || this.state.currentPage === 1) {
        this.handlePageChange(1);
      } else if (this.state.currentPage > lastPage) {
        this.handlePageChange(lastPage);
      }
    }
  }

  // search
  handleSearch = e => {
    this.setState({
      search: e.target.value,
      currentPage: 1,
    });
  };

  // change sort
  handleSort = sorting => {
    this.setState({ sorting: sorting });
  };

  // change page
  handlePageChange = page => {
    this.setState({ currentPage: page });
    if (this.props.onPageChange) {
      this.props.onPageChange(page);
    }
  };

  //search by word in all data
  searchWord = data => {
    if (this.state.search === '') {
      return data;
    }
    let result = data.filter(this.filteredData);
    return result;
  };

  filteredData = item => {
    let search = this.state.search.toLowerCase();
    let excludeKeys = this.props.excludeKeys || [];

    for (let key in item) {
      if (excludeKeys.includes(key) || !item[key]) {
        continue;
      }

      if (typeof item[key] === 'object') {
        if (!this.filteredData(item[key])) {
          continue;
        }
        return true;
      }

      if (Array.isArray(item[key])) {
        let nestedArray = item[key];
        for (const element of nestedArray) {
          if (!this.filteredData(element)) {
            continue;
          }
          return true;
        }
      }

      if (!isNaN(parseFloat(item[key])) && isFinite(item[key])) {
        if (
          Number(item[key] / 100)
            .toFixed(2)
            .toLowerCase()
            .indexOf(search) !== -1
        ) {
          return true;
        }
        if (item[key].toString().indexOf(search) !== -1) {
          return false;
        }
      }

      if (
        item.hasOwnProperty(key) &&
        item[key] &&
        item[key]
          .toString()
          .toLowerCase()
          .indexOf(search) !== -1
      ) {
        return true;
      }
    }
    return false;
  };

  // paginate data
  paginate = (items, currentPage, pageSize) => {
    const startIndex = (currentPage - 1) * pageSize;
    return _(items)
      .slice(startIndex)
      .take(pageSize)
      .value();
  };

  setPageSize = e => {
    this.setState({
      pageSize: Number(e.target.value),
      currentPage: 1,
    });
    if (this.props.onPageSizeChange) {
      this.props.onPageSizeChange(Number(e.target.value));
    }
  };

  getModifiedData = () => {
    const { sorting } = this.state;
    const { data } = this.props;

    // first search, second - sort, third - pegination
    let searchData = this.searchWord(data);

    const sorted = _.orderBy(searchData, [sorting.column], [sorting.order]);

    const peginatedData = this.paginate(
      sorted,
      this.state.currentPage,
      this.state.pageSize
    );

    return { totalCount: searchData.length, modifiedData: peginatedData };
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const {
      data,
      onLoadMoreData,
      totalElements,
      tableStyle,
      tableFontSize,
      pagination = true,
      search = true,
      fixedTableHeader,
      checkboxDiv,
    } = this.props;
    let { totalCount, modifiedData } = this.getModifiedData();
    if (data.length === 0) {
      return null;
    }

    return (
      <div className="efb-container-table-wrapper">
        <div className="efb-container-table">
          {/* <h3 className="efb-h3">Search Results: </h3> */}
          {search ? (
            <div className="form-row efb-container-table-control">
              <div className="form-label-group col-md-6">
                <SearchBar onChange={this.handleSearch} />
              </div>
              {this.props.children}
            </div>
          ) : null}
          {fixedTableHeader ? (
            <div className="fixed-table-header">
              <div className="table-header">
                <table className={tableStyle} style={tableFontSize}>
                  <TableHeader
                    sorting={this.state.sorting}
                    handleSort={this.handleSort}
                    columns={this.props.columns}
                  />
                </table>
                <div className={`table__body ${tableStyle} ${tableFontSize}`}>
                  <table>
                    <TableBody tableData={modifiedData} {...this.props} />
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <table className={tableStyle} style={tableFontSize}>
              <TableHeader
                sorting={this.state.sorting}
                handleSort={this.handleSort}
                columns={this.props.columns}
                checkboxDiv={checkboxDiv}
              />
              <TableBody tableData={modifiedData} {...this.props} />
            </table>
          )}

          {pagination ? (
            <Pagination
              itemsCount={this.props.itemsCount || totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
              setPageSize={this.setPageSize}
              onLoadMoreData={onLoadMoreData}
              totalElements={totalElements}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

Table.propTypes = {
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  itemsCount: PropTypes.number,
  columns: PropTypes.array.isRequired,
  tableStyle: PropTypes.string.isRequired,
  pagination: PropTypes.bool,
  currentPage: PropTypes.number,
};

export default Table;
