import {
  BUILDER_GET_COUNTRIES,
  BUILDER_GET_SPORTS,
  BUILDER_GET_TOURNAMENTS,
  BUILDER_GET_SEASONS,
  BUILDER_GET_COMPETITORS,
  GET_MARKET_TYPES,
  GET_MARKET_TYPE,
  GET_MARKET_CATEGORIES,
  GET_OUTCOMES,
  BUILDER_GET_EVENTS,
  GET_EVENT_MARKETS,
  GET_SPORT_OBJ,
  HOME_COMPETITORS,
  AWAY_COMPETITORS,
  RESET_DEFAULT_MARKET_STATUS,
  CREATE_MARKET,
} from '../../actions/cms/eventBuilder';

const initialState = {
  sports: [],
  countries: [],
  seasons: [],
  competitors: [],
  tournaments: [],
  marketCategories: [],
  marketTypes: [],
  outcomes: [],
  sportObj: {},
  feedProviderId: '',
  eventMarkets: [],
  events: [],
  feedApi: false,
  homeCompetitors: [],
  awayCompetitors: [],
  marketType: '',
};

export const eventBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUILDER_GET_SPORTS.SUCCESS:
      return {
        ...state,
        sports: action.payload,
      };

    case BUILDER_GET_SPORTS.EMPTY:
      return {
        ...state,
        sports: [],
      };

    case BUILDER_GET_COUNTRIES.SUCCESS:
      return {
        ...state,
        countries: action.payload,
      };

    case BUILDER_GET_TOURNAMENTS.SUCCESS:
      return {
        ...state,
        tournaments: action.payload,
      };

    case BUILDER_GET_SEASONS.SUCCESS:
      return {
        ...state,
        seasons: action.payload,
      };

    case BUILDER_GET_COMPETITORS.SUCCESS:
      return {
        ...state,
        competitors: action.payload,
      };

    case HOME_COMPETITORS.SUCCESS:
      return {
        ...state,
        homeCompetitors: action.payload,
      };

    case HOME_COMPETITORS.EMPTY:
      return {
        ...state,
        homeCompetitors: [],
      };

    case AWAY_COMPETITORS.SUCCESS:
      return {
        ...state,
        awayCompetitors: action.payload,
      };

    case AWAY_COMPETITORS.EMPTY:
      return {
        ...state,
        awayCompetitors: [],
      };

    case GET_MARKET_CATEGORIES.SUCCESS:
      return {
        ...state,
        marketCategories: action.payload,
      };

    case GET_MARKET_TYPES.SUCCESS:
      return {
        ...state,
        marketTypes: action.payload,
      };

    case GET_MARKET_TYPE.SUCCESS:
      return {
        ...state,
        marketType: action.payload,
      };

    case GET_MARKET_TYPE.EMPTY:
      return {
        ...state,
        marketType: '',
      };

    case GET_OUTCOMES.SUCCESS:
      return {
        ...state,
        outcomes: action.payload,
      };

    case GET_SPORT_OBJ.SUCCESS:
      return {
        ...state,
        sportObj: action.payload,
        feedApi: false,
      };

    case GET_SPORT_OBJ.FAILURE:
      return {
        ...state,
        sportObj: {},
      };

    case BUILDER_GET_COMPETITORS.REQUEST:
      return {
        ...state,
      };

    case GET_EVENT_MARKETS.SUCCESS:
      return {
        ...state,
        eventMarkets: action.payload,
      };

    case BUILDER_GET_EVENTS.SUCCESS:
      return {
        ...state,
        events: action.payload,
      };

    case RESET_DEFAULT_MARKET_STATUS.SUCCESS:
      return {
        ...state,
        updatedDefaultMarket: true,
      };

    case CREATE_MARKET.SUCCESS:
      return {
        ...state,
        marketType: '',
      };

    default:
      return state;
  }
};
