import React, { useState, useMemo, useCallback } from 'react';
import Modal from '../../../../components/NewModal/Modal';
import classes from './AccountHistoryModal.module.scss';
import FiltersMenu from './FiltersMenu/FiltersMenu';
import Table from '../../../../components/NewTable/Table';
import { getSvgFlag } from '../../../../utils/flags';
import { useFetch, useUpdate } from '../../../../hooks';

const accountTypes = {
  MAIN: 'Current Balance',
  LOCK_FUND: 'Locked Funds',
  FREE_BET: 'Free Balance',
};

const prettifyAccountNames = type => {
  return accountTypes[type];
};

const dataTable = [
  {
    ref: '55956905',
    txTime: '10/11/2019  09:34:42',
    txType: 'NSoftStake',
    operator: 'K.Kukov',
    debit: '0.50',
    credit: '0.50',
    balanceAfter: '33.98',
    spinRef: 'JO-8446120366',
    gameName: 'D4ef[50,34...',
  },
  {
    ref: '55956905',
    txTime: '10/11/2019  09:34:42',
    txType: 'NSoftStake',
    operator: 'K.Kukov',
    debit: '0.50',
    credit: '0.50',
    balanceAfter: '33.98',
    spinRef: 'JO-8446120366',
    gameName: 'D4ef[50,34...',
  },
  {
    ref: '55956905',
    txTime: '10/11/2019  09:34:42',
    txType: 'NSoftStake',
    operator: 'K.Kukov',
    debit: '0.50',
    credit: '0.50',
    balanceAfter: '33.98',
    spinRef: 'JO-8446120366',
    gameName: 'D4ef[50,34...',
  },
];

const AccountHistoryModal = () => {
  const [openedFiltersMenu, setOpenedFiltersMenu] = useState(true);
  const [userId, setUserId] = useState('');

  const customerId = new URLSearchParams(window.location.search).get(
    'accountHistoryCustomerId'
  );

  useUpdate(() => {
    setUserId(customerId);
  }, [customerId]);

  const { data: customer } = useFetch(`customer/view/admin/${userId}`);
  const { data: userSettings } = useFetch(
    `riskmgnt/view/admin/userSettings/findByCustomerId?customerId=${userId}`
  );
  const { data: accounts } = useFetch(`account/view/admin/accounts/${userId}`);

  // Todo: will be used later
  // const { data: accountHistory } = useFetch(
  // 	`account/view/admin/account/history/${customerId}?pageNumber=0&pageSize=0`
  // );

  const columns = useMemo(
    () => [
      { name: 'Ref.', display: account => account.ref },
      { name: 'Tx Time', display: account => account.txTime },
      { name: 'Tx. Type', display: account => account.txType },
      { name: 'Operator', display: account => account.operator },
      { name: 'Debit', display: account => account.debit },
      { name: 'Credit', display: account => account.credit },
      { name: 'BalanceAfter', display: account => account.balanceAfter },
      { name: 'Spin Ref.', display: account => account.spinRef },
      { name: 'Game Name', display: account => account.gameName },
    ],
    []
  );

  const renderAccount = useCallback(account => {
    return account.accountType === 'SPORT_BET' ||
      account.accountType === 'SPORT_BONUS' ||
      account.accountType === 'CASINO_BONUS' ? null : (
      <div className="col-md">
        <div className={classes.borderDivider}>
          <div>
            <p>{prettifyAccountNames(account.accountType)}:</p>
            <b>
              {Number(account['balance'] / 100).toFixed(2)}{' '}
              <span className="text-muted">{account['currencyCode']}</span>
            </b>
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <Modal className={classes.wrapper} showCloseBtn={false} isActive>
      <div className={`d-flex flex-row p-2 ${classes.header}`}>
        <div className="col-12">
          <div className="form-row">
            <div>
              <p>
                Account History:{' '}
                <b>
                  {customer
                    ? customer.gender === 'MALE'
                      ? 'Mr.'
                      : 'Mrs.'
                    : null}{' '}
                  <span className={classes.uppercase}>
                    {customer ? customer.lastName : ''}
                  </span>
                  , {customer ? customer.firstName : ''}
                </b>
              </p>
              <div className={classes.countryInfo}>
                <img
                  src={getSvgFlag(
                    customer ? customer.country.countryCode : 'bg'
                  )}
                  className={classes.countryIcon}
                  alt=""
                />
                <span className={classes.currency}>
                  {customer
                    ? customer.accounts.find(x => x.accountType === 'MAIN')
                        .currencyCode
                    : 'EUR'}
                </span>
              </div>
              <b className={classes.username}>
                [ {customer ? customer.user?.id : ''} /{' '}
                {customer ? customer.user?.username : ''} ]
              </b>
            </div>
          </div>
        </div>
      </div>
      <div className={'d-flex flex-row flex-grow-1 position-relative'}>
        <FiltersMenu opened close={() => setOpenedFiltersMenu(false)} />
        <div
          className={`flex-grow-1 position-absolute ${classes.tableWrapper}`}
          style={{
            marginLeft: openedFiltersMenu ? 308 : 0,
          }}
        >
          <div className={`flex-grow-1 mb-4 ${classes.tableWrapper}`}>
            <div className={`${classes.customerMoney}`}>
              {accounts ? accounts.map(renderAccount) : null}

              <div className="col-md">
                <div className={`${classes.borderDivider} ${classes.missing}`}>
                  <div className={classes.missing}>
                    <p>Available:</p>
                    <b>0 EUR</b>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className={classes.borderDivider}>
                  <div>
                    <p>Bet Factor:</p>
                    <b>{userSettings ? userSettings.betFactor : 0}</b>
                  </div>
                </div>
              </div>
              <div className="col-md-1">
                <div className={`${classes.borderDivider} border-0`}>
                  <div>
                    <p>Status:</p>
                    <span
                      className={`${classes.statusCircle} ${
                        customer && customer.status
                          ? classes.active
                          : classes.inactive
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Table columns={columns} data={dataTable} pagination={false} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AccountHistoryModal;
