import React from 'react';

export const IconFolderOpened = ({
  className,
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 19 14"
      {...otherProps}
    >
      <defs>
        <style>
          {`
          .a {
            fill:#cf8808;
          }
          .b {
            fill:#ffbe4a;
          }
          `}
        </style>
      </defs>
      <rect
        className="a"
        width="8"
        height="4"
        rx="1"
        transform="translate(2)"
      />
      <rect
        className="a"
        width="17"
        height="12"
        rx="1"
        transform="translate(2 2)"
      />
      <path
        className="b"
        d="M-1,2H14a1,1,0,0,1,1,1l2,8a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1L-2,3A1,1,0,0,1-1,2Z"
        transform="translate(2 2)"
      />
    </svg>
  );
};

export default IconFolderOpened;