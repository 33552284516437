import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import * as EventSearchActions from '../../redux/actions/cms/eventSearch';

import classes from './Sidebar.module.scss';
import Input from '../Form/Input';
import Button from '../Layout/Button';
import IconFolderOpened from '../Icons/UI/ui/IconFolderOpened';
import IconFolderSuspended from '../Icons/UI/ui/IconFolderSuspended';
import IconFolderClosed from '../Icons/UI/ui/IconFolderClosed';
import { useStateForInput, useUnmount } from '../../hooks';

import { IconSearch } from '../Icons/UI/ui/IconSearch';

const SearchRow = ({
  searchSportEvents,
  clearSearchSportEvents,
  setMatchStatus,
  matchStatus,
  entityType,
}) => {
  const [search, setSearch] = useStateForInput('');

  const handleOnKeyDown = useCallback(
    e => {
      if (e.keyCode === 13) {
        if (!search) {
          clearSearchSportEvents();
        } else {
          searchSportEvents(search);
        }
      }
    },
    [clearSearchSportEvents, search, searchSportEvents]
  );

  useUnmount(() => {
    clearSearchSportEvents();
  });

  const changeMatchStatus = useCallback(
    status => {
      setMatchStatus(matchStatus === status ? null : status);
    },
    [matchStatus, setMatchStatus]
  );

  return (
    <div className={classes.searchRow}>
      <div className="position-relative">
        <IconSearch className={classes.inputIcon} width={15} />
        <Input
          customInput
          placeholder={`Search ${entityType}`}
          className={classes.searchInput}
          value={search}
          onChange={setSearch}
          onKeyDown={handleOnKeyDown}
        />
      </div>
      <Button
        onClick={() => changeMatchStatus('ACTIVE')}
        cssClass={`${classes.roundBtn} ${
          matchStatus === 'ACTIVE' ? classes.active : null
        }`}
      >
        <IconFolderOpened />
      </Button>
      <Button
        onClick={() => changeMatchStatus('SUSPENDED')}
        cssClass={`${classes.roundBtn} ${
          matchStatus === 'SUSPENDED' ? classes.active : null
        }`}
      >
        <IconFolderSuspended />
      </Button>
      <Button
        onClick={() => changeMatchStatus('CLOSED')}
        cssClass={`${classes.roundBtn} ${
          matchStatus === 'CLOSED' ? classes.active : null
        }`}
      >
        <IconFolderClosed />
      </Button>
    </div>
  );
};

const mapDispatchToProps = {
  searchSportEvents: EventSearchActions.searchSportEvents,
  clearSearchSportEvents: EventSearchActions.clearSearchSportEvents,
};

export default connect(null, mapDispatchToProps)(SearchRow);
