import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CreateSport from './CreateSport';
import SportsTable from './SportsTable';

const Sports = ({ languages }) => {
  const [editingSport, setEditingSport] = useState(null);

  return (
    <>
      <h3>Sports</h3>
      <CreateSport
        languages={languages}
        editingSport={editingSport}
        clearEditingSport={() => setEditingSport(null)}
      />

      <hr />

      <SportsTable
        languages={languages}
        editSport={setEditingSport}
        editiingSport={editingSport}
      />
    </>
  );
};

Sports.propTypes = {
  languages: PropTypes.array.isRequired
};

export default Sports;
