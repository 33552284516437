import { addNotificationMessage } from '../ui';
import axiosInstance from '../axios-instance';

export const getCategoryById = id => getById(id, 'category');
export const getSportById = id => getById(id, 'sport');
export const getTournamentById = id => getById(id, 'tournament');
export const getEventById = id => getById(id, 'match');

const getById = (id, type) => async dispatch => {
  try {
    return await axiosInstance.get(`/sport/event/view/${type}/admin/${id}`);
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};
