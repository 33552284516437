import React from 'react';

const IconMobile = ({
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 9 16"
      {...otherProps}
    >
      <path
        d="M4687.881-2015.482c0,.755-.042,1.486.011,2.208a1.443,1.443,0,0,0,1.447,1.505q2.892,0,5.786,0a1.366,1.366,0,0,0,1.338-1.3c.073-.787.016-1.59.016-2.412Zm4.479,3.03a1.105,1.105,0,0,1-.977-1.182,1.092,1.092,0,0,1,1.014-1.163,1.1,1.1,0,0,1,.975,1.181A1.088,1.088,0,0,1,4692.36-2012.452Z"
        transform="translate(-4687.696 2027.585)"
        fill="#090909"
        opacity="0.36"
      />
      <path
        d="M4822.563-1989.281a1.018,1.018,0,0,0-.957,1.01,1,1,0,0,0,1,.987,1.016,1.016,0,0,0,.992-1A1.037,1.037,0,0,0,4822.563-1989.281Z"
        transform="translate(-4817.922 2002.238)"
        fill="#090909"
        opacity="0.59"
      />
      <path
        d="M4696.347-2468.467a1.308,1.308,0,0,0-.571-.147c-1.943-.01-3.885-.009-5.829,0a1.411,1.411,0,0,0-1.377,1.352c-.055.774-.012,1.558-.012,2.357h8.608c0-.759.028-1.5-.009-2.231A1.494,1.494,0,0,0,4696.347-2468.467Zm-5.419,1.445h3.862A14.475,14.475,0,0,1,4690.927-2467.022Z"
        transform="translate(-4688.352 2468.804)"
        fill="#090909"
        opacity="0.36"
      />
      <path
        d="M4691.686-2472.58a1.54,1.54,0,0,0-1.625-1.646q-2.865-.007-5.73,0a1.543,1.543,0,0,0-1.64,1.656q0,3.152,0,6.3h0q0,3.186,0,6.372a1.551,1.551,0,0,0,1.656,1.666H4690a1.543,1.543,0,0,0,1.683-1.682c0-.839,0-1.677,0-2.516Q4691.687-2467.5,4691.686-2472.58Zm-.2,13.09a1.3,1.3,0,0,1-1.347,1.1q-2.911,0-5.823,0a1.349,1.349,0,0,1-1.456-1.279c-.054-.612-.011-1.233-.011-1.874h8.653A19.046,19.046,0,0,1,4691.484-2459.49Zm.039-2.219h-8.671v-9.042h8.671Zm.007-9.212h-8.664c0-.679-.042-1.344.012-2a1.334,1.334,0,0,1,1.386-1.148q2.933,0,5.866,0a1.508,1.508,0,0,1,.574.125,1.266,1.266,0,0,1,.816,1.125C4691.558-2472.194,4691.529-2471.567,4691.529-2470.922Z"
        transform="translate(-4682.688 2474.229)"
        fill="#090909"
      />
      <path
        d="M4779.863-2419.521h-4.578A20.327,20.327,0,0,0,4779.863-2419.521Z"
        transform="translate(-4773.07 2421.303)"
        fill="#090909"
      />
      <path
        d="M4817.056-1994.468a1.179,1.179,0,0,0-1.2,1.163,1.181,1.181,0,0,0,1.156,1.182,1.175,1.175,0,0,0,1.2-1.164A1.175,1.175,0,0,0,4817.056-1994.468Zm-.012,2.167a1,1,0,0,1-1-.987,1.018,1.018,0,0,1,.956-1.01,1.036,1.036,0,0,1,1.037.993A1.015,1.015,0,0,1,4817.043-1992.3Z"
        transform="translate(-4812.357 2007.256)"
        fill="#090909"
      />
    </svg>
  );
};

export default IconMobile;