import React, { PureComponent } from 'react';
import { getAuthData } from '../selectors/index';
import { connect } from 'react-redux';
import { getUserRolesRoutePermisions } from '../redux/actions/rolesApi';
import { transformRoles } from './utils';

const Authorization = WrappedComponent => {
  return class extends PureComponent {
    state = {
      dashboardRoutes: []
    };

    componentDidMount() {
      this.checkAuthStatus();
      getUserRolesRoutePermisions().then(response => {
        let transformDashboard = transformRoles(response);
        this.setState({ dashboardRoutes: transformDashboard });
      });
    }
    componentDidUpdate() {
      this.checkAuthStatus();
    }

    checkAuthStatus = () => {
      const { isAuthenticated } = this.props;

      if (!isAuthenticated) {
        this.props.history.push('/');
      }
    };

    render() {
      return (
        <WrappedComponent
          dashboard={this.state.dashboardRoutes}
          {...this.props}
        />
      );
    }
  };
};

const mapStateToProps = state => {
  let isAuthenticated = getAuthData(state).isLogedIn;

  return {
    isAuthenticated
  };
};

export default WrapperComponent =>
  connect(mapStateToProps)(Authorization(WrapperComponent));
