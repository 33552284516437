import React from 'react';

const IconClock = ({
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 19 19" {...otherProps}
    >
      <g
        transform="translate(-284.021 -256.383)"
        opacity="0.3"
      >
        <path
          d="M293.521,256.383a9.5,9.5,0,1,0,9.5,9.5A9.511,9.511,0,0,0,293.521,256.383Zm0,16.625a7.125,7.125,0,1,1,7.125-7.125A7.133,7.133,0,0,1,293.521,273.008Z"
          fill="#1a1818"
        />
        <path
          d="M361.453,293.608h-2.769v-4.394a.831.831,0,0,0-.831-.831h0a.831.831,0,0,0-.831.831v5.225h0a.831.831,0,0,0,.831.831h3.6a.831.831,0,0,0,.831-.831h0A.831.831,0,0,0,361.453,293.608Z"
          transform="translate(-64.331 -28.2)"
          fill="#1a1818"
        />
        <path
          d="M309.779,328.191h-.363a.831.831,0,0,0,0,1.663h.363a.831.831,0,1,0,0-1.663Z"
          transform="translate(-21.647 -63.281)"
          fill="#1a1818"
        />
        <rect
          width="1.663"
          height="2.025"
          rx="0.831"
          transform="translate(292.69 270.394)"
          fill="#1a1818"
        />
      </g>
    </svg>
  );
};

export default IconClock;