import React, { useCallback } from 'react';
import CheckboxDiv from '../../../../components/Form/CheckboxDiv/CheckboxDiv';

const ByBetTypes = ({ systemTypes, betScroller, onChangeBetType }) => {
  const checkBetType = useCallback((e) => {
    const currentBetType = e.target.name;
    const filterBetType = [...betScroller.betTypes];
    onChangeBetType({
      ...betScroller,
      betTypes: filterBetType.includes(currentBetType)
        ? filterBetType.filter(type => type !== currentBetType)
        : [...betScroller.betTypes, currentBetType]
    });
  }, [betScroller, onChangeBetType]);

  return (
    <div className="m-auto m-2 overflow-scroll-box">
      {systemTypes.map(type => {
        return (
          <div
            className="d-flex"
            key={type}
          >
            <CheckboxDiv
              id={type}
              label={type}
              name={type}
              onChange={checkBetType}
              isChecked={betScroller.betTypes.includes(type)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ByBetTypes;