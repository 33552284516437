import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from './store';

import App from './App';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-day-picker/lib/style.css';
import './resources/styles/css/bootstrap/bootstrap.css';
import './resources/styles/css/bootstrap/bootstrap-grid.css';
import './resources/styles/css/bootstrap/bootstrap-reboot.css';
import './resources/styles/css/index.css';
import './resources/styles/flags/flag-icon.min.css';
import './polyfills';
import registerServiceWorker, { unregister } from './registerServiceWorker';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={configureStore()}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

if (process.env.NODE_ENV === 'development') {
  unregister();
} else if (process.env.NDOE_ENV === 'production') {
  registerServiceWorker();
}
