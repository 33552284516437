import { getUsersRequestType } from '../actions/usersApi';

const initialState = {
  users: [],
  totalPages: 0,
  totalElements: 0
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case getUsersRequestType.SUCCESS:
      return {
        ...state,
        users: action.payload.content || [action.payload],
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements
      };
    default:
      return state;
  }
};
