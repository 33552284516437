import React, { useCallback, useState } from 'react';
import Switch from '../../../../components/Form/Switch/Switch';
import InputField from '../../../../components/Form/InputField';
import PropTypes from 'prop-types';
import { useUpdate } from '../../../../hooks';

const PageForm = ({ pageObj, updateObj, languages }) => {
  const [staticPageObj, setStaticPageObj] = useState('');

  useUpdate(() => {
    setStaticPageObj(pageObj);
  }, [pageObj]);

  const handleInputChange = useCallback(
    e => {
      const { name, value, checked } = e.target;
      const pageObjClone = { ...staticPageObj };
      if (name === 'name') {
        pageObjClone.name = value;
      } else {
        pageObjClone.enabled[name].enabled = checked;
      }
      setStaticPageObj(staticPageObj);
      updateObj(pageObjClone);
    },
    [staticPageObj, updateObj]
  );

  return (
    <>
      <div className="efb-form">
        <h3 className="efb-h3">Page Form</h3>
        <hr />
        <div className="form-row">
          <div className="form-label-group col-md">
            <InputField
              label={'Page name'}
              value={staticPageObj.name}
              name={'name'}
              placeholder="Sport Name"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md">
            {languages.map((lang, idx) => {
              return (
                <Switch
                  key={'lang' + idx}
                  label={lang.displayName}
                  name={lang.languageCode}
                  isChecked={
                    staticPageObj
                      ? staticPageObj.enabled[lang.languageCode].enabled
                      : false
                  }
                  onChange={handleInputChange}
                />
              );
            })}
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

PageForm.propTypes = {
  pageObj: PropTypes.object,
  updateObj: PropTypes.func,
};

export default PageForm;
