import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useUpdate } from '../../../../hooks';
import InputField from '../../../../components/Form/InputField';

const Abbreviations = ({ competitorsObj, setCompetitorsObj, languages }) => {
  const [competitorAbbreviations, setCompetitorAbbreviations] = useState({});

  useUpdate(() => {
    setCompetitorAbbreviations(competitorsObj.abbreviations);
  }, [competitorsObj]);

  const handleInputChange = useCallback(
    e => {
      const { name, value } = e.target;
      const competitorAbbreviationsClone = { ...competitorAbbreviations };
      competitorAbbreviationsClone[name] = {
        ...competitorAbbreviationsClone[name],
        name: value,
      };
      setCompetitorAbbreviations(competitorAbbreviationsClone);
      setCompetitorsObj({
        ...competitorsObj,
        abbreviations: competitorAbbreviationsClone,
      });
    },
    [competitorAbbreviations, competitorsObj, setCompetitorsObj]
  );

  return (
    <>
      <h3 className="efb-h3">Abbreviations</h3>
      <div className="form-row">
        {languages.map((lang, idx) => {
          return (
            <div className="form-label-group col-md" key={'c' + idx}>
              <InputField
                type="text"
                value={
                  competitorAbbreviations[lang.languageCode]
                    ? competitorAbbreviations[lang.languageCode].name
                    : ''
                }
                placeholder="Sport Name"
                onChange={e => handleInputChange(e)}
                name={lang.languageCode}
                label={
                  <i
                    className={`flag-icon flag-icon-${
                      lang.languageCode === 'en' ? 'gb' : lang.languageCode
                    }`}
                  ></i>
                }
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

Abbreviations.propTypes = {
  updateObj: PropTypes.func,
  competitorsObj: PropTypes.object,
};

export default Abbreviations;
