import React from 'react';
import MainPages from './Pages/MainPages';
import SubpagesTable from './Pages/SubpagesTable';

const TabsContainer = ({
  tab,
  staticPageData,
  showSubpages,
  backToMainPages,
  pageObj,
  addSection,
  updateObj,
  submitPage,
  languages,
  getPages,
  editPage,
  updatePageStatus,
  saveEditedName,
  setContentLang,
  currentLang,
  subpages,
  uploadImage,
  contentType,
  updateContentTypeValue,
  getPageContent,
  getSubpageContent,
  cancelPageContentEditMode,
  updateContent,
  pagesListLang,
  editPageContent,
  pageContentEdit,
  extendPageContent,
  getRegularPageContent,
  updateRegularPageContent,
  showSubpageContent,
  assignSubpageContent,
  submitNewSubpageContent,
  deletePage,
  prepareSubpath,
  saveRowCellData,
  saveColumnCellData,
  serverError,
  updateSubpage,
}) => {
  let content;
  switch (tab) {
    case 'main_pages':
      content = (
        <MainPages
          showSubpages={showSubpages}
          pageObj={pageObj}
          submitPage={submitPage}
          updateObj={updateObj}
          languages={languages}
          saveEditedName={saveEditedName}
          currentLang={currentLang}
          pagesListLang={pagesListLang}
          setContentLang={setContentLang}
          pages={staticPageData.pages}
          uploadImage={uploadImage}
          images={staticPageData.images}
          editPage={editPage}
          contentType={contentType}
          updateContentTypeValue={updateContentTypeValue}
          getPages={getPages}
          updatePageStatus={updatePageStatus}
          getPageContent={getPageContent}
          cancelPageContentEditMode={cancelPageContentEditMode}
          editPageContent={editPageContent}
          pageContentEdit={pageContentEdit}
          extendPageContent={extendPageContent}
          getRegularPageContent={getRegularPageContent}
          regularPageContent={staticPageData.regularPageContent}
          updateRegularPageContent={updateRegularPageContent}
          availableCountries={staticPageData.availableCountries}
          currencies={staticPageData.currencies}
          deletePage={deletePage}
          prepareSubpath={prepareSubpath}
          serverError={serverError}
        />
      );
      break;

    case 'subpages':
      content = (
        <SubpagesTable
          backToMainPages={backToMainPages}
          pageObj={pageObj}
          addSection={addSection}
          subpages={subpages}
          subpageContent={staticPageData.subpageContent}
          showSubpageContent={showSubpageContent}
          assignSubpageContent={assignSubpageContent}
          images={staticPageData.images}
          uploadImage={uploadImage}
          submitNewSubpageContent={submitNewSubpageContent}
          contentType={contentType}
          updateContent={updateContent}
          getSubpageContent={getSubpageContent}
          saveRowCellData={saveRowCellData}
          saveColumnCellData={saveColumnCellData}
          updateSubpage={updateSubpage}
        />
      );
      break;

    default:
      return null;
  }
  return content;
};

export default TabsContainer;
