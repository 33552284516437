import React, { useMemo, useCallback } from 'react';
import Select from 'react-select';

import PermissionsModal from '../../../../components/Modal/PermissionsModal';
import AppViewPrivilegesUsers from './AppViewPrivilegesUsers';
import { transformRole } from '../Roles/rolesUtils';
import { useStateForSelect } from '../../../../hooks';

const roleToOption = role => ({ label: role.name, value: role.id });

const EditPermissionsModal = ({
  isActive,
  onClose,
  user,
  allRoles,
  updateRoles
}) => {
  const [roles, setRoles] = useStateForSelect(user.roles.map(roleToOption));

  const transformedUserRoles = useMemo(
    () =>
      roles
        ? roles.map(role =>
            transformRole(allRoles.find(userRole => userRole.id === role.value))
          )
        : [],
    [allRoles, roles]
  );

  const roleOptions = useMemo(() => allRoles.map(roleToOption), [allRoles]);

  const resetRoles = useCallback(() => {
    setRoles(user.roles.map(roleToOption));
  }, [setRoles, user.roles]);

  const submitRoles = useCallback(() => {
    updateRoles(
      user.id,
      roles.map(newRole => allRoles.find(role => role.id === newRole.value))
    );
    onClose();
  }, [allRoles, onClose, roles, updateRoles, user.id]);

  return (
    <PermissionsModal
      isActive={isActive}
      onClose={onClose}
      headerText={`Edit permissions for ${user.username}`}
      onClear={resetRoles}
      onSubmit={submitRoles}
    >
      <div className="efb-modal-permissions__content">
        <div className="efb-modal-permissions__content__select">
          <Select
            onChange={setRoles}
            value={roles}
            options={roleOptions}
            placeholder="Select roles"
            isMulti
          />
        </div>
        <AppViewPrivilegesUsers
          readOnly={true}
          transformedUserRoles={transformedUserRoles}
          userRoles={user.roles}
        />
      </div>
    </PermissionsModal>
  );
};

export default EditPermissionsModal;
