import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import SystemSettings from './SystemSettings/SystemSettings';
import Configuration from './Configuration/Configuration';
import BetHistory from './BetHistory/BetHistory';
import Referral from './Referral/Referral';
import Settlements from './Settlements/Settlements';
import CashoutManagerModule from './CashoutManager/CashoutManagerModule';
import Dashboard from './Dashboard/Dashboard';

const RiskManagementModule = props => {
  let RouteComponent;

  switch (props.match.params.module) {
    case 'system-settings':
      RouteComponent = () => <Route render={() => <SystemSettings />} />;
      break;
    case 'configuration':
      RouteComponent = () => <Route render={() => <Configuration />} />;
      break;
    case 'bet-history':
      RouteComponent = () => <Route render={() => <BetHistory />} />;
      break;
    case 'referral':
      RouteComponent = () => <Route render={() => <Referral />} />;
      break;
    case 'dash':
      RouteComponent = () => <Route render={() => <Dashboard />} />;
      break;
    case 'ctm':
      RouteComponent = () => (
        <Route render={() => <CashoutManagerModule {...props} />} />
      );
      break;
    case 'settlements-results':
      RouteComponent = () => (
        <Route render={() => <Settlements {...props} />} />
      );
      break;
    default:
      RouteComponent = () => <Redirect to="/adm" />;
  }
  return <Route path={`${props.match.path}`} component={RouteComponent} />;
};

export default RiskManagementModule;
