import React, { memo } from 'react';

export const WinterSports = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `WinterSports ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141 128.33"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <path
          className={'Figure'}
          d="M78.74,73.75c-4-2.92-7.94-5.71-11.8-8.62a64.05,64.05,0,0,1-8-6.46,14.47,14.47,0,0,1,3.49-22.44,1.49,1.49,0,0,1,1.83,0q12.92,8.7,25.87,17.33c.16.11.31.24.54.43L85.56,56.5l5,3.67c2.58,1.89,5.18,3.74,7.75,5.65,5.15,3.8,5.39,10.44.51,14.57q-10.7,9.06-21.43,18.09L75.8,99.81c.47.19.83.35,1.19.47q23.65,8.13,47.29,16.27a2,2,0,0,0,1.88-.19c2.1-1.31,4.21-2.6,6.36-3.83,3.53-2,7.55-.33,8.34,3.5a4.9,4.9,0,0,1-2.21,5.42c-3.42,2.2-6.92,4.28-10.45,6.31a5.34,5.34,0,0,1-4.53.11q-16.22-5.62-32.45-11.23Q60.51,106.08,29.78,95.54,16.94,91.12,4.11,86.68c-3.88-1.34-5.29-5.38-3-8.54a5.37,5.37,0,0,1,6.42-1.8c6.83,2.34,13.64,4.73,20.47,7.08q13.58,4.66,27.16,9.32a1.52,1.52,0,0,0,1.73-.26Q67.5,83.36,78.19,74.28C78.33,74.15,78.47,74,78.74,73.75Z"
        />
        <path
          className={'Figure'}
          d="M97.73,39c.37-1.7.67-3.27,1.06-4.8a1.15,1.15,0,0,0-.59-1.45c-3.67-2.41-7.31-4.86-11-7.31l-1.11-.78c2.13-1,4.15-2.06,6.19-3a25.78,25.78,0,0,1,4.18-1.75,11.24,11.24,0,0,1,12.63,5.09c1.88,3.14,3.9,6.33,4.08,10.11a26.14,26.14,0,0,1-.83,5.77c-.37,2-.8,4-1.26,6a1.4,1.4,0,0,0,.63,1.59c3.52,3,8,3.69,12.19,4.93,1.3.38,2.62.7,3.92,1.07A7.35,7.35,0,1,1,124,68.57q-11.95-3.24-23.87-6.6c-3.5-1-5.15-3.2-5.2-6.84a16.69,16.69,0,0,1,.25-3.33,1.7,1.7,0,0,0-.88-2c-7-4.62-14-9.29-20.94-13.94L35.25,10.35c-2.45-1.64-3.42-3.65-2.92-6A5.38,5.38,0,0,1,39.63.4a10.4,10.4,0,0,1,1.77,1L88.72,33C91.67,35,94.64,36.91,97.73,39Z"
        />
        <path
          className={'Figure'}
          d="M126.35,7.26a13.56,13.56,0,1,1-13.66,13.21A13.51,13.51,0,0,1,126.35,7.26Z"
        />
      </g>
    </svg>
  );
};

export default memo(WinterSports);
