import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { selectStyles } from '../../../../containers/CMS/customReactSelectStyle';
import { preferencesColumns, paymentManagerInputs } from '../transactionsUtils';
import { getTransactionData } from '../../../../selectors';
import { addNotificationMessage } from '../../../../redux/actions/ui';

import * as transactionsActions from '../../../../redux/actions/TransactionBuilder';

import Table from '../../../../components/NewTable/Table';
import InputField from '../../../../components/Form/InputField';
import SelectField from '../../../../components/Form/SelectField';
import Button from '../../../../components/Layout/Button';

const Preferences = ({
  preferencesValue,
  setPreferencesValue,
  preferences,
  currencieOptions,
  addPreferences,
  addNotificationMessage,
}) => {
  const handleChange = useCallback(
    e => {
      const { name, value } = e.target;
      setPreferencesValue({
        ...preferencesValue,
        [name]: name !== 'currencyCode' ? Number(value) : value,
      });
    },
    [preferencesValue, setPreferencesValue]
  );

  const validate = useCallback(preferencesValue => {
    const {
      depositMin,
      depositMax,
      withdrawMin,
      withdrawMax,
      currencyCode,
    } = preferencesValue;
    return {
      allFeildsError:
        !currencyCode ||
        !depositMin ||
        !depositMax ||
        !withdrawMin ||
        !withdrawMax
          ? 'All fields are required!'
          : null,
      depositMinError:
        depositMin >= depositMax
          ? 'Deposit Min can not be equal or bigger than deposit max'
          : null,
      withdrawMinError:
        withdrawMin > withdrawMax
          ? 'Withdraw Min can not be equal or bigger than deposit max'
          : null,
    };
  }, []);

  const addOrUpdatePreferences = useCallback(() => {
    const errors = validate(preferencesValue);
    const errorKey = Object.keys(errors).find(x => errors[x]);
    if (errors[errorKey]) {
      addNotificationMessage(errors[errorKey], 'error', 'Error');
    } else {
      const {
        depositMin,
        depositMax,
        withdrawMin,
        withdrawMax,
      } = preferencesValue;
      addPreferences({
        ...preferencesValue,
        depositMin: depositMin * 100,
        depositMax: depositMax * 100,
        withdrawMin: withdrawMin * 100,
        withdrawMax: withdrawMax * 100,
      });
    }
  }, [addNotificationMessage, addPreferences, preferencesValue, validate]);

  const paymentInputs = useCallback(
    payment => {
      return (
        <div className="col-2" key={payment.value}>
          <InputField
            value={preferencesValue[payment.value] || ''}
            type="number"
            name={payment.value}
            min="0"
            className="form-control"
            label={payment.label}
            placeholder={payment.label}
            onChange={handleChange}
          />
        </div>
      );
    },
    [handleChange, preferencesValue]
  );

  return (
    <div>
      <div className="form-label-group efb-form pt-3 m-auto">
        <div className="form-row d-flex justify-content-center pt-3 pb-5 px-0 m-2">
          <div className="col-2">
            <SelectField
              options={currencieOptions}
              onChange={handleChange}
              label="Currencies"
              name="currencyCode"
              placeholder="Currency"
              styles={selectStyles}
              value={preferencesValue.currencyCode || ''}
              className="ml-2"
            />
          </div>

          {paymentManagerInputs.map(paymentInputs)}

          <Button onClick={addOrUpdatePreferences} cssClass="btn btn-success">
            {preferences.some(
              pref => pref.currencyCode === preferencesValue.currencyCode
            )
              ? 'Save'
              : 'Create'}
          </Button>
        </div>
      </div>
      <div className="m-auto px-2">
        {preferences.length > 0 ? (
          <div className="payment-manager-table">
            <Table
              data={preferences}
              columns={preferencesColumns}
              pagination={false}
            />
          </div>
        ) : (
          <h4 className="text-center mb-5">No limits are configured</h4>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  preferences: getTransactionData(state).preferences,
});

const mapDispatchToProps = {
  addPreferences: transactionsActions.addPreferences,
  addNotificationMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
