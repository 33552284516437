import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const SettlementEventMarketOutcomes = React.memo(({ tableData: outcomes }) => {
    const mapEventMarkets = useCallback(
        (outcome, index) => (
            <tr key={outcome.outcomeId}>
                <td>{index + 1}.</td>
                <td>{outcome.names ? outcome.names.en.name : null}</td>
                <td>/</td>
            </tr>
        ),
        []
    );

    return outcomes.map(mapEventMarkets)
})

SettlementEventMarketOutcomes.PropTypes = {
    tableData: PropTypes.array
}

export default SettlementEventMarketOutcomes;