import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import EventInfo from './EventInfo/EventInfo';
import Market from './Market/Market';

const FiltersContainer = ({
	filterType,
	filters,
	getCompetitors,
	updateFilter,
	homeCompetitors,
	awayCompetitors,
	updateMatchStatusMessage,
	loaded,
	createEvent,
	marketCategories,
	marketTypes,
	createSportEventMarket,
	sportObj,
    backToBuilder,
    listOfSports,
    listOfSeasons,
    listOfCountries,
	listOfTournaments,
	languages
}) => {
	const content = useMemo(
		() => {
			switch (filterType) {
				case 'event_info':
					return (
						<EventInfo
							filterType={filterType}
							loaded={loaded}
							filters={filters.general}
							updateFilter={updateFilter}
							sports={listOfSports}
							seasons={listOfSeasons}
							countries={listOfCountries}
							getCompetitors={getCompetitors}
							updateMatchStatusMessage={updateMatchStatusMessage}
							createEvent={createEvent}
							homeCompetitors={homeCompetitors}
							awayCompetitors={awayCompetitors}
							tournaments={listOfTournaments}
						/>
					);

				case 'market_list':
					return (
						<>
							<Market
								filterType={filterType}
								filters={filters}
								marketTypes={marketTypes}
								marketCategories={marketCategories}
								createSportEventMarket={createSportEventMarket}
								loaded={loaded}
								sportObj={sportObj}
								backToBuilder={backToBuilder}
								updateFilter={updateFilter}
								languages={languages}
							/>
							<hr />
						</>
					);

				default:
					return null;
			}
		},
		[
			awayCompetitors,
			backToBuilder,
			createEvent,
			createSportEventMarket,
			filterType,
			filters,
			getCompetitors,
			homeCompetitors,
			loaded,
			marketCategories,
			marketTypes,
			sportObj,
			updateFilter,
            updateMatchStatusMessage,
            listOfCountries, 
            listOfSeasons, 
            listOfSports,
			listOfTournaments,
			languages
		]
	);

	return content;
};

FiltersContainer.propTypes = {
	updateFilter: PropTypes.func,
	filterType: PropTypes.string,
	filters: PropTypes.object,
	listOfSports: PropTypes.array,
	listOfCompetitors: PropTypes.array,
	listOfCountries: PropTypes.array,
	listOfSeasons: PropTypes.array,
	loaded: PropTypes.bool
};

export default FiltersContainer;
