import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../../components/Layout/Button';
import TableContentSubpaths from './TableContent/TableContentSubpaths';
import TableContentRegular from './TableContent/TableContentRegular';
import { tableSubpage } from '../../StaticContentObj';

class List extends Component {
  state = {
    tableColumns: [[{ key: 0, value: '' }]],
    tableKeysAndLabels: [{ key: 0, labelValue: '', keyValue: '' }],
    currencies: [],
    countries: [],
    currency: '',
    country: '',
    subpage: '',
    regularTables: [{ table: tableSubpage.tables, key: 0 }],
  };

  pageObjClone = subpage => ({
    ...this.props.pageObj,
    subpages: [
      {
        ...subpage,
        content: [
          {
            ...subpage.content[0],
            [this.props.currentLang.value]: {
              ...subpage.content[0][this.props.currentLang.value],
              tables: [
                ...subpage.content[0][this.props.currentLang.value].tables,
              ],
            },
          },
        ],
      },
    ],
  });

  componentDidMount() {
    this.prepareCurrenciesList();
    this.prepareCountriesList();
    this.setState({ subpage: this.props.subpage });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.subpage !== prevState.subpage) {
      return {
        subpage: nextProps.subpage,
      };
    }
    return null;
  }

  prepareCurrenciesList = () => {
    const currencyOptions = [];
    this.props.currencies.forEach(currency => {
      currencyOptions.push({ value: currency.code, label: currency.name });
    });
    this.setState({ currencies: currencyOptions });
  };

  prepareCountriesList = () => {
    const countriesOptions = [];
    this.props.availableCountries.forEach(country => {
      countriesOptions.push({
        value: country.code,
        label: country.names.en.name,
      });
    });
    this.setState({ countries: countriesOptions });
  };

  addTable = () => {
    const pageObjClone = this.pageObjClone(this.props.subpage);
    const regularTables = [...this.state.regularTables];
    let counter = regularTables.length;
    regularTables.push({ table: tableSubpage.tables, key: counter++ });
    pageObjClone.subpages[this.props.index].content[0][
      this.props.currentLang.value
    ].tables.push(tableSubpage.tables[0]);
    this.props.updateObj(pageObjClone);
    this.setState({ regularTables });
  };

  removeTable = tableIndex => {
    const pageObjClone = this.pageObjClone(this.props.subpage);
    const regularTables = [...this.state.regularTables];
    regularTables.splice(tableIndex, 1);
    pageObjClone.subpages[this.props.index].content[0][
      this.props.currentLang.value
    ].tables.splice(tableIndex, 1);
    this.props.updateObj(pageObjClone);
    this.setState({ regularTables });
  };

  render() {
    const { tableColumns, currencies, countries, regularTables } = this.state;
    const {
      subpage,
      currentLang,
      countryCurrencySubPath,
      index: subpageIndex,
      updateObj,
      pageObj,
    } = this.props;
    return (
      <Fragment>
        {countryCurrencySubPath ? (
          <Fragment>
            <TableContentSubpaths
              pageObj={pageObj}
              subpage={subpage}
              currentLang={currentLang}
              currencies={currencies}
              countries={countries}
              tableColumns={tableColumns}
              updateObj={updateObj}
              subpageIndex={subpageIndex}
            />
          </Fragment>
        ) : (
          <Fragment>
            {regularTables.map((table, index) => {
              return (
                <Fragment key={table.key}>
                  <Button
                    disabled={regularTables.length === 1}
                    cssClass="efb-btn__main float-right"
                    onClick={() => this.removeTable(index)}
                  >
                    <i className="fa fa-plus"></i> Remove table
                  </Button>
                  <TableContentRegular
                    tableIndex={index}
                    subpage={subpage}
                    currentLang={currentLang}
                    subpageIndex={subpageIndex}
                    addTable={this.addTable}
                    removeTable={this.removeTable}
                  />
                </Fragment>
              );
            })}
            <Button
              cssClass="efb-btn__main float-right"
              onClick={this.addTable}
            >
              <i className="fa fa-plus"></i> Add table
            </Button>
            <br /> <br /> <hr />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

List.propTypes = {
  availableCountries: PropTypes.array,
  contentType: PropTypes.string,
  currencies: PropTypes.array,
  currentLang: PropTypes.object,
  index: PropTypes.number,
  pageObj: PropTypes.object,
  subpage: PropTypes.object,
  updateObj: PropTypes.func,
};

export default List;
