import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import * as BetHistoryActions from '../../redux/actions/riskManagement/betHistory';
import * as EventsManagerActions from '../../redux/actions/cms/eventsManager';
import * as MarketCategoriesActions from '../../redux/actions/cms/marketCategories';
import * as MarketActions from '../../redux/actions/cms/markets';
import * as CustomerDataActions from '../../redux/actions/customer/customerData';
import * as ConfigurationActions from '../../redux/actions/riskManagement/configuration';
import * as RiskCategoryActions from '../../redux/actions/riskManagement/RiskCategory';
import * as UiActions from '../../redux/actions/ui';
import StakeFilter from './StakeFilter/StakeFilter';
import HistoryModal from '../HistoryModal/HistoryModal';
import ContextMenu from '../ContextMenu/ContextMenu';
import ActionHistoryModal from './ActionHistoryModal/ActionHistoryModal';
import MarketDetailsModal from './DetailsModal/MarketDetailsModal';
import EventDetailsModal from './DetailsModal/EventDetailsModal';
import Button from '../Layout/Button';
import classes from './TableSelector.module.scss';
import IconCog from '../Icons/UI/ui/IconCog';
import IconFolderLive from '../Icons/UI/ui/IconFolderLive';
import IconFolderOpened from '../Icons/UI/ui/IconFolderOpened';
import IconFolderClosed from '../Icons/UI/ui/IconFolderClosed';
import IconFolderRedArrow from '../Icons/UI/ui/IconFolderRedArrow';
import IconFolderSuspended from '../Icons/UI/ui/IconFolderSuspended';
import IconFolderGreenArrow from '../Icons/UI/ui/IconFolderGreenArrow';
import IconFolderBooked from '../Icons/UI/ui/IconFolderBooked';
import { getSportIcon } from '../../helpers/iconHelpers';
import { ICONS_SPORT_MAP } from '../Icons/UI/sports';
import { isEventLive } from './TableSelectorUtils';
import {
  getCustomerData,
  getEventsManagerData,
  getRiskCategoryData,
  getRiskConfigurationData,
  getBetHistoryData,
} from '../../selectors';
import { useMount, useUpdate } from '../../hooks';

/**
 * Tree table for searching sports, categories, tournaments, events, markets.
 * As you expand each entity next thing is being fetched
 * To use this component, pass in boolean variable of the last thing in the order to show
 * Example 1: <TableSelector sports={sports} /> => shows only sports
 * Example 2: <TableSelector sports={sports} showTournaments /> => shows everything till tournaments
 * --------------------------------------------------
 * entityType - sport | category | tournament | event | market
 * In case you want to get the fetched entity, you can get it from getEventsManagerData(state).[entityType] and push it onto an array
 * onEntityClick(entity, entityType) - Event hook when the entity row has been clicked
 * onEntityExpand(entity, entityType) - Event hook when clicked to expand entity
 * --------------------------------------------------
 * getters - pass in this if you want to make custom entity getter (fetch from different API, already fetched them, etc...)
 * This property should look like this (every field is optional):
 * {
 *   sport?: async (sportId) => Categories[],
 *   category?: async (categoryId) => Tournament[],
 *   tournament?: async (tournamentId) => Event[],
 *   event?: async (eventId) => Market[]
 * }
 * withoutContextMenu - pass in this if you don't want context menu in the tree.
 */
const TableSelector = ({
  onEntityClick,
  onEntityExpand,
  systemTypes,
  riskCategories,
  countries,
  producers,
  getters: gettersConfig,
  sports,
  showCategories,
  showTournaments,
  showEvents,
  showMarkets,
  addNotificationMessage,
  getSystemTypes,
  getRiskCategories,
  getCountries,
  getProducers,
  getCategories,
  getTournaments,
  getEvents,
  getMarkets,
  updateEvent,
  updateMarket,
  location,
  withoutContextMenu,
  filteredBetHistory,
  getFilteredBetHistory,
}) => {
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [openedContextMenu, setOpenedContextMenu] = useState(null);
  const [openedDetailsModal, setOpenedDetailsModal] = useState(null);
  const [openedActionHistory, setOpenedActionHistory] = useState(null);
  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const [contextMenuEntityType, setContextMenuEntityType] = useState('');
  const [openedEntities, setOpenedEntities] = useState({
    sport: [],
    category: [],
    tournament: [],
    event: [],
    market: [],
  });
  const [nextPerEntity, setNextPerEntity] = useState({
    sport: {},
    category: {},
    tournament: {},
    event: {},
    market: {},
  });

  useMount(() => {
    getSystemTypes();
    getRiskCategories();
    getCountries();
    getProducers();
  });

  useUpdate(() => {
    setSelectedEntity(null);
    setOpenedEntities({
      sport: [],
      category: [],
      tournament: [],
      event: [],
      market: [],
    });
    setNextPerEntity({
      sport: {},
      category: {},
      tournament: {},
      event: {},
      market: {},
    });
  }, [sports]);

  const entities = useMemo(
    () => [
      { name: 'category', show: showCategories },
      { name: 'tournament', show: showTournaments },
      { name: 'event', show: showEvents },
      { name: 'market', show: showMarkets },
    ],
    [showCategories, showEvents, showMarkets, showTournaments]
  );

  const getStatusIcon = useCallback(
    entity => {
      if (isEventLive(entity, producers)) {
        return IconFolderLive;
      }

      if (entity.bookingStatus === 'BOOKED') {
        return IconFolderBooked;
      }

      switch (entity.status || entity.marketStatus) {
        case 'ACTIVE':
          return IconFolderOpened;
        case 'DEACTIVATED':
          return IconFolderClosed;
        case 'SUSPENDED':
          return IconFolderSuspended;
        default:
          return null;
      }
    },
    [producers]
  );

  const getters = useMemo(
    () => ({
      sport: getCategories,
      category: getTournaments,
      tournament: getEvents,
      event: getMarkets,
      ...gettersConfig,
    }),
    [getCategories, getEvents, getMarkets, getTournaments, gettersConfig]
  );

  const getEventsForTournament = useCallback(
    async tournamentId => {
      const events = await getters.tournament(tournamentId);

      setNextPerEntity({
        ...nextPerEntity,
        tournament: {
          ...nextPerEntity.tournament,
          [tournamentId]: events,
        },
      });
    },
    [getters, nextPerEntity]
  );

  const getMarketsForEvent = useCallback(
    async eventId => {
      const markets = await getters.event(eventId);

      setNextPerEntity({
        ...nextPerEntity,
        event: {
          ...nextPerEntity.event,
          [eventId]: markets,
        },
      });
    },
    [getters, nextPerEntity]
  );

  const contextMenuRows = useMemo(
    () =>
      [
        {
          name: 'Open',
          Icon: IconFolderGreenArrow,
          onClick: async () => {
            // Activate entity
            if (
              openedContextMenu.status === 'ACTIVE' ||
              openedContextMenu.marketStatus === 'ACTIVE'
            ) {
              addNotificationMessage(
                'Entity is already active',
                'warning',
                'warning'
              );
            } else if (contextMenuEntityType === 'event') {
              await updateEvent(
                {
                  ...openedContextMenu,
                  competitionStatus: undefined,
                  status: 'ACTIVE',
                },
                openedContextMenu.id
              );

              await getEventsForTournament(openedContextMenu.tournamentId);
            } else if (contextMenuEntityType === 'market') {
              await updateMarket({
                ...openedContextMenu,
                marketStatus: 'ACTIVE',
              });

              await getMarketsForEvent(openedContextMenu.sportEventId);
            }

            setOpenedContextMenu(null);
          },
        },
        {
          name: 'Close',
          Icon: IconFolderRedArrow,
          onClick: async () => {
            // Deactivate entity
            if (
              openedContextMenu.status === 'DEACTIVATED' ||
              openedContextMenu.marketStatus === 'DEACTIVATED'
            ) {
              addNotificationMessage(
                'Entity is already deactivated',
                'warning',
                'warning'
              );
            } else if (contextMenuEntityType === 'event') {
              await updateEvent(
                {
                  ...openedContextMenu,
                  competitionStatus: undefined,
                  status: 'DEACTIVATED',
                },
                openedContextMenu.id
              );

              await getEventsForTournament(openedContextMenu.tournamentId);
            } else if (contextMenuEntityType === 'market') {
              await updateMarket({
                ...openedContextMenu,
                marketStatus: 'DEACTIVATED',
              });

              await getMarketsForEvent(openedContextMenu.sportEventId);
            }

            setOpenedContextMenu(null);
          },
        },
        contextMenuEntityType === 'market'
          ? {
              name: 'Suspend',
              Icon: IconFolderSuspended,
              onClick: async () => {
                // Suspend market
                await updateMarket({
                  ...openedContextMenu,
                  marketStatus: 'SUSPENDED',
                });

                await getMarketsForEvent(openedContextMenu.sportEventId);
                setOpenedContextMenu(null);
              },
            }
          : undefined,
        {
          name: 'Details',
          Icon: IconCog,
          onClick: () => {
            // Open details
            setOpenedDetailsModal(openedContextMenu);
            setOpenedContextMenu(null);
          },
        },
        {
          type: 'divider',
        },
        contextMenuEntityType === 'market'
          ? {
              name: 'Action History',
              onClick: () => {
                // Deactivate entity
                setOpenedActionHistory(openedContextMenu);
                setOpenedContextMenu(null);
              },
            }
          : undefined,
        {
          name: 'Bet History',
          onClick: () => {
            // Deactivate entity
            window.open(
              `?entityId=${openedContextMenu.id}&entityType=${contextMenuEntityType}`
            );
            setOpenedContextMenu(null);
          },
        },
      ].filter(row => row),
    [
      addNotificationMessage,
      contextMenuEntityType,
      getEventsForTournament,
      getMarketsForEvent,
      openedContextMenu,
      updateEvent,
      updateMarket,
    ]
  );

  const openContextMenu = useCallback(
    (e, entity, entityType) => {
      if (!withoutContextMenu) {
        e.preventDefault();

        if (!(['event', 'market'].indexOf(entityType) > -1)) {
          return;
        }

        setContextMenuPosition({ top: e.pageY + 'px', left: e.pageX + 'px' });
        setContextMenuEntityType(entityType);
        setOpenedContextMenu(entity);
      }
    },
    [withoutContextMenu]
  );

  const toggleEntity = useCallback(
    (entity, entityType) => {
      const openedEntity = openedEntities[entityType].find(
        entityId => entity.id === entityId
      );
      if (openedEntity) {
        setOpenedEntities({
          ...openedEntities,
          [entityType]: openedEntities[entityType].filter(
            entityId => entity.id !== entityId
          ),
        });
      } else {
        getters[entityType](entity.id).then(nextEntities => {
          setNextPerEntity({
            ...nextPerEntity,
            [entityType]: {
              ...nextPerEntity[entityType],
              [entity.id]: nextEntities,
            },
          });
          setOpenedEntities({
            ...openedEntities,
            [entityType]: [...openedEntities[entityType], entity.id],
          });
        });
      }
    },
    [getters, openedEntities, nextPerEntity]
  );

  const displayEntity = useCallback(
    (entity, entityIndex = 0) => {
      const entityType = entities[entityIndex].name;

      const shouldShowNext = entities
        .slice(entityIndex + 1)
        .some(entity => entity.show);

      const openedEntity = openedEntities[entityType].find(
        entityId => entity.id === entityId
      );
      const StatusIcon = getStatusIcon(entity);
      return (
        <Fragment key={entity.id}>
          <li
            className={`${
              openedContextMenu === entity || selectedEntity === entity
                ? classes.selected
                : null
            } ${onEntityClick ? 'pointer' : ''}`}
            onContextMenu={e => openContextMenu(e, entity, entityType)}
            onClick={() => {
              if (onEntityClick) {
                const res = onEntityClick(entity, entityType);
                if (res !== false) {
                  setSelectedEntity(entity);
                }
              }
            }}
          >
            {entityType !== 'market' ? (
              <Button
                cssClass={classes.openedBtn}
                onClick={e => {
                  e.stopPropagation();
                  if (onEntityExpand) {
                    onEntityExpand(entity, entityType);
                  }
                  toggleEntity(entity, entityType);
                }}
              >
                {openedEntity ? '-' : '+'}
              </Button>
            ) : null}

            {StatusIcon ? <StatusIcon width={15} height={15} /> : null}
            {entity.names.en.name}
          </li>
          {shouldShowNext &&
          openedEntity &&
          nextPerEntity[entityType][entity.id] &&
          nextPerEntity[entityType][entity.id].length ? (
            <ul>
              {nextPerEntity[entityType][entity.id].map(entity =>
                displayEntity(entity, entityIndex + 1)
              )}
            </ul>
          ) : null}
        </Fragment>
      );
    },
    [
      entities,
      openedEntities,
      getStatusIcon,
      openedContextMenu,
      selectedEntity,
      onEntityClick,
      nextPerEntity,
      openContextMenu,
      onEntityExpand,
      toggleEntity,
    ]
  );

  const displaySport = useCallback(
    sport => {
      const SportIcon = getSportIcon(sport, ICONS_SPORT_MAP);
      const openedSport = openedEntities.sport.find(
        sportId => sport.id === sportId
      );
      return (
        <Fragment key={sport.id}>
          <li
            key={sport.id}
            className={`${selectedEntity === sport ? classes.selected : null} ${
              onEntityClick ? 'pointer' : ''
            }`}
            onClick={() => {
              if (onEntityClick) {
                const res = onEntityClick(sport, 'sport');
                if (res !== false) {
                  setSelectedEntity(sport);
                }
              }
            }}
          >
            <Button
              cssClass={classes.openedBtn}
              onClick={e => {
                e.stopPropagation();
                if (onEntityExpand) {
                  onEntityExpand(sport, 'sport');
                }
                toggleEntity(sport, 'sport');
              }}
            >
              {openedSport ? '-' : '+'}
            </Button>
            {SportIcon ? (
              <SportIcon width={12} height={12} selected colorful />
            ) : null}
            {sport.names.en.name}
          </li>
          {entities.some(entity => entity.show) &&
          openedSport &&
          nextPerEntity.sport[sport.id] &&
          nextPerEntity.sport[sport.id].length ? (
            <ul>
              {nextPerEntity.sport[sport.id].map(entity =>
                displayEntity(entity)
              )}
            </ul>
          ) : null}
        </Fragment>
      );
    },
    [
      selectedEntity,
      onEntityClick,
      entities,
      nextPerEntity.sport,
      openedEntities.sport,
      onEntityExpand,
      toggleEntity,
      displayEntity,
    ]
  );

  const betHistoryColumns = useMemo(
    () => [
      {
        name: 'BC',
        display: entity => {
          return entity.type;
        },
      },
      { name: 'BU' },
      { name: 'User' },
      { name: 'Unit' },
      { name: 'Placem. Time' },
      { name: 'Bet Ref.' },
      { name: 'Win Unit Stake' },
      { name: 'Bet Type' },
      { name: 'Total Stake' },
      { name: 'Bet Return' },
      { name: 'Total Return' },
      { name: 'Bet Status' },
      { name: 'Ref. State' },
      { name: 'Selection Name', active: false },
      { name: 'Price', active: false },
      { name: 'Leg Status', active: false },
      { name: 'Leg Sett. Time', active: false },
      { name: 'Market', active: false },
      { name: 'Event', active: false },
      {
        name: <span>+ Add</span>,
        type: 'button',
        onClick: () => {
          console.log('clicked add');
        },
      },
    ],
    []
  );

  const filtersConfig = useMemo(() => {
    const countryOptions = [
      {
        label: 'All',
        value: 'all',
      },
      ...countries.map(country => ({
        label: country.names.en.name,
        value: country.id,
      })),
    ];

    const deviceOptions = [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Mobile',
        value: 'mobile',
      },
      {
        label: 'Web',
        value: 'web',
      },
    ];

    const riskCategoryOptions = riskCategories.map(riskCategory => ({
      label: (
        <span>
          {riskCategory.name} - {riskCategory.description}
        </span>
      ),
      value: riskCategory.id,
    }));

    const statusesOptions = [
      { label: 'Attempted', value: 'attempted' },
      { label: 'Closed & Winning', value: 'closedAndWinning' },
      { label: 'Open', value: 'open' },
      { label: 'Closed & Losing', value: 'closedAndLosing' },
      { label: 'Cancelled', value: 'cancelled' },
      { label: 'Cashed Out', value: 'cashedOut' },
    ];

    const betTypeOptions = systemTypes.map(betType => ({
      value: betType,
      label: betType,
    }));

    return [
      {
        name: 'country',
        label: 'Country',
        type: 'select',
        options: countryOptions,
        default: countryOptions[0],
      },
      {
        name: 'device',
        label: 'Device',
        type: 'radio',
        options: deviceOptions,
        default: deviceOptions[0],
      },
      {
        name: 'riskCategory',
        label: 'Client Category',
        type: 'checkbox',
        options: riskCategoryOptions,
      },
      {
        name: 'stake',
        label: 'Stake',
        type: 'custom',
        component: StakeFilter,
      },
      {
        name: 'status',
        label: 'Status',
        type: 'checkbox',
        options: statusesOptions,
      },
      {
        name: 'betType',
        label: 'Bet Type',
        type: 'checkbox',
        options: betTypeOptions,
        selectAllButton: true,
      },
      {
        label: 'Bets',
        type: 'dates',
        fields: [
          {
            name: 'betPlacedDate',
            label: 'Bet Placed',
            default: moment(),
          },
          {
            name: 'betSettledDate',
            label: 'Bet Settled',
            default: moment(),
          },
        ],
      },
    ];
  }, [countries, riskCategories, systemTypes]);

  const onHistoryFiltersChange = useCallback(
    filters => {
      const mapping = {
        market: 'marketId',
        event: 'sportEventId',
      };

      const entityId = new URLSearchParams(location.search).get('entityId');
      const entityTypeIdField =
        mapping[new URLSearchParams(location.search).get('entityType')];

      getFilteredBetHistory({ [entityTypeIdField]: entityId, ...filters });
    },
    [getFilteredBetHistory, location.search]
  );

  if (new URLSearchParams(location.search).get('entityId')) {
    return (
      <HistoryModal
        history={filteredBetHistory}
        columns={betHistoryColumns}
        filtersConfig={filtersConfig}
        onFiltersChange={onHistoryFiltersChange}
      />
    );
  }

  return (
    <>
      <ul className={classes.tableSelector}>
        {sports ? sports.map(displaySport) : null}
      </ul>
      {openedContextMenu ? (
        <ContextMenu
          position={contextMenuPosition}
          rows={contextMenuRows}
          onClose={() => setOpenedContextMenu(null)}
        />
      ) : null}
      {openedActionHistory ? (
        <ActionHistoryModal
          market={openedActionHistory}
          onClose={() => setOpenedActionHistory(null)}
        />
      ) : null}
      {openedDetailsModal ? (
        contextMenuEntityType === 'market' ? (
          <MarketDetailsModal
            market={openedDetailsModal}
            onClose={() => setOpenedDetailsModal(null)}
          />
        ) : (
          <EventDetailsModal
            event={openedDetailsModal}
            onClose={() => setOpenedDetailsModal(null)}
          />
        )
      ) : null}
    </>
  );
};

const mapStateToProps = state => ({
  filteredBetHistory: getBetHistoryData(state).filteredBetHistory,
  riskCategories: getRiskCategoryData(state).riskCategories,
  systemTypes: getRiskConfigurationData(state).systemTypes,
  countries: getCustomerData(state).customerDataReducer.countries,
  producers: getEventsManagerData(state).producers,
});

const mapDispatchToProps = {
  getFilteredBetHistory: BetHistoryActions.getFilteredBetHistory,

  getSystemTypes: ConfigurationActions.getSystemTypes,

  getCountries: CustomerDataActions.getCountries,

  getRiskCategories: RiskCategoryActions.getRiskCategories,

  getProducers: EventsManagerActions.getProducers,
  getCategories: EventsManagerActions.getCategories,
  getTournaments: EventsManagerActions.getTournaments,
  getEvents: EventsManagerActions.getEvents,
  getMarkets: MarketCategoriesActions.getMarkets,

  updateEvent: EventsManagerActions.updateMatchWebStatus,
  updateMarket: MarketActions.updateMarket,

  addNotificationMessage: UiActions.addNotificationMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableSelector));
