import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

import * as LiveStreamingActions from '../../redux/actions/LiveStreaming';
import BetradarEventsTable from './BetradarEventsTable';
import IMGEventsTable from './IMGEventsTable';
import Spinner from '../../components/Layout/Spinner/Spinner';
import { providers, streamStatuses } from './LiveStreamingUtils';
import { selectStyles } from '../CMS/customReactSelectStyle';
import { createLoadingSelector, getLiveStreamingData } from '../../selectors';
import { usePrevious, useStateForSelect, useUpdate } from '../../hooks';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const LiveStreaming = ({
  fetchEvents,
  streamingEvents,
  mappedEvents,
  isLoading,
  getMappedEvents,
}) => {
  const [provider, setProvider] = useStateForSelect(providers[0]);
  const [streamStatus, setStreamStatus] = useStateForSelect(streamStatuses[0]);
  const [from, setFrom] = useState(
    moment()
      .set({
        hours: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toDate()
  );
  const [to, setTo] = useState(
    moment()
      .add(1, 'day')
      .set({
        hours: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toDate()
  );
  const prevProvider = usePrevious(provider.value || null);

  useUpdate(() => {
    if (provider.value) {
      fetchEvents(provider.value);
      getMappedEvents(provider.value);
    }
  }, [fetchEvents, provider, provider.value]);

  const Table = useMemo(() => {
    switch (provider.value) {
      case 1:
        return BetradarEventsTable;
      case 2:
        return IMGEventsTable;
      default:
        return null;
    }
  }, [provider]);

  return (
    <div>
      <div className="form-row">
        <div className="col-6">
          <Select
            styles={selectStyles}
            placeholder="Select provider"
            options={providers}
            value={provider}
            onChange={data => setProvider(data)}
          />
        </div>
        <div className="col-6">
          <Select
            styles={selectStyles}
            placeholder="Select stream status"
            options={streamStatuses}
            value={streamStatus}
            onChange={setStreamStatus}
          />
        </div>
      </div>
      <div className="my-3 efb-form form-row">
        <div className="col-3 d-flex">
          <label htmlFor="from" className="m-0 ml-3 mr-2 align-self-center">
            From:
          </label>
          <div className="flex-grow-1">
            <DatePicker
              id="from"
              selected={from}
              onSelect={date => setFrom(date)}
              onChange={date => setFrom(date)}
              dateFormat="dd/MM/yyyy HH:mm"
              timeFormat="HH:mm"
              showTimeSelect
            />
          </div>
        </div>
        <div className="col-3 d-flex">
          <label htmlFor="to" className="m-0 ml-3 mr-2 align-self-center">
            To:
          </label>
          <div className="flex-grow-1">
            <DatePicker
              id="to"
              selected={to}
              onSelect={date => setTo(date)}
              onChange={date => setTo(date)}
              dateFormat="dd/MM/yyyy HH:mm"
              timeFormat="HH:mm"
              showTimeSelect
            />
          </div>
        </div>
      </div>
      {isLoading || provider.value !== prevProvider ? (
        <div className="mt-5">
          <Spinner size={50} active />
        </div>
      ) : Table ? (
        <Table
          from={from}
          to={to}
          streamStatus={streamStatus.value}
          streamingEvents={streamingEvents.filter(
            event =>
              moment(event.start_time) >= moment(from) &&
              moment(event.start_time) <= moment(to)
          )}
          mappedEvents={mappedEvents}
        />
      ) : null}
    </div>
  );
};
const mapStateToProps = state => ({
  mappedEvents: getLiveStreamingData(state).mappedEvents,
  streamingEvents: getLiveStreamingData(state).streamingEvents,
  isLoading: createLoadingSelector(['FETCH_STREAMING_EVENTS'])(state),
});

const mapDispatchToProps = {
  fetchEvents: LiveStreamingActions.fetchEvents,
  getMappedEvents: LiveStreamingActions.getMappedEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreaming);
