import {
  SPORT_TEMPLATES,
  SPORT_TYPES_TEMPLATES,
  SPORT_VIEWS,
  GET_ALL_BIR_DELAY,
  GET_MARKET_TYPES_VIEWS,
  GET_MARKET_SPORT_VIEWS,
  GET_MARKET_TYPES_CONFIGS,
  GET_SYSTEM_TYPES, GET_BET_TYPE_CONFIGS
} from '../../actions/riskManagement/configuration';

const initialState = {
  sportTemplatesData: [],
  sportTypes: [],
  sportViews: [],
  marketTypesViews: [],
  marketSportViews: [],
  marketTypesConfigs: [],
  systemTypes: [],
  betTypeConfigs: [],
  allBirDelay: [],
};

//use switch it's more readable
export const configurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SPORT_TEMPLATES.SUCCESS:
      return {
        ...state,
        sportTemplatesData: action.payload
      };
    case SPORT_TYPES_TEMPLATES.SUCCESS:
      return {
        ...state,
        sportTypes: action.payload
      };
    case SPORT_TEMPLATES.EMPTY:
      return {
        ...state,
        sportTemplatesData: []
      };

    case SPORT_TYPES_TEMPLATES.EMPTY:
      return {
        ...state,
        sportTypes: []
      };

    case SPORT_VIEWS.SUCCESS:
      return {
        ...state,
        sportViews: action.payload
      };

    case GET_MARKET_TYPES_VIEWS.SUCCESS:
      return {
        ...state,
        marketTypesViews: action.payload
      };

    case GET_MARKET_SPORT_VIEWS.SUCCESS:
      return {
        ...state,
        marketSportViews: action.payload
      };

    case GET_MARKET_TYPES_CONFIGS.SUCCESS:
      return {
        ...state,
        marketTypesConfigs: action.payload
      };

    case GET_SYSTEM_TYPES.SUCCESS:
      return {
        ...state,
        systemTypes: action.payload
      };

    case GET_BET_TYPE_CONFIGS.SUCCESS:
      return {
        ...state,
        betTypeConfigs: action.payload
      };

    case GET_ALL_BIR_DELAY.SUCCESS:
      return {
        ...state,
        allBirDelay: action.payload
      };

    default:
      return state;
  }
};
