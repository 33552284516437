import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useStateForInput, useStateForSelect } from '../../../hooks';
import Table from '../../../components/Table/Table';
import Input from '../../../components/Form/Input';
import { selectStyles } from '../../CMS/customReactSelectStyle';
import { Button } from '../../../components/Layout';
import Modal from '../../../components/Modal/Modal';
import CheckboxDiv from '../../../components/Form/CheckboxDiv/CheckboxDiv';

const columns = [
  { name: 'ID', value: 'id', type: 'number' },
  { name: '', value: 'checkBoxPendingStatuses', type: 'button' },
  { name: 'Selected date', value: 'selectedDate', type: 'string' },
  { name: 'Name', value: 'name', type: 'string' },
  { name: 'Status', value: 'status', type: 'string' },
  { name: 'Username', value: 'username', type: 'string' },
  { name: 'Activated By', value: 'admin', type: 'string' },
  { name: 'Activated On', value: 'activatedOn', type: 'date' },
  { name: 'Deactivated By', value: 'deactivatedBy', type: 'string' },
  {
    name: 'Deactivated Reason',
    value: 'deactivatedReason',
    type: 'string'
  },
  { name: 'Deactivated On', value: 'deactivatedOn', type: 'date' },
  { name: 'Amount Wagered', value: 'totalBetsBonusAmount', type: 'string' },
  { name: 'Amount won', value: 'totalWinsBonusAmount', type: 'string' },
  { name: 'Amount bonus', value: 'amount', type: 'string' },
  { name: 'Rolled Over / Target', value: 'target', type: 'string' },
  { name: 'Actions', value: '', type: 'buttons' }
];

const BonusStatusesForSelect = [
  {
    label: 'Waiting for deposit',
    value: 'WAITING_FOR_DEPOSIT'
  },
  {
    label: 'Deserved',
    value: 'DESERVED'
  },
  {
    label: 'Pending',
    value: 'PENDING'
  },
  {
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    label: 'Inactive',
    value: 'INACTIVE'
  },
  {
    label: 'Expired',
    value: 'EXPIRED'
  },
  {
    label: 'Finished',
    value: 'FINISHED'
  }
];

const BonusesStatus = ({
  bonuses,
  campaigns,
  getAllBonuses,
  approveCustomerBonus,
  rejectCustomerBonus
}) => {
  const [campaign, setCampaign] = useStateForSelect(null);
  const [bonusStatus, setBonusStatus] = useStateForSelect(null);
  const [bonusId, setBonusId] = useStateForInput('');
  const [bonusName, setBonusName] = useStateForInput('');
  const [reason, setReason, resetReason] = useStateForInput('');
  const [rejectPayload, setRejectPayload] = useState({});
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [disabledFor, setDisabledFor] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [isChecked, setIsChecked] = useState({});

  const searchBonusStatuses = useCallback(() => {
    const optional = Object.assign(
      {},
      campaign && { campaignId: campaign.value },
      bonusId && { bonusId },
      bonusName && { bonusName },
      bonusStatus && { bonusStatus: bonusStatus.value }
    );
    getAllBonuses(optional);
  }, [
    bonusId,
    bonusName,
    bonusStatus,
    campaign,
    getAllBonuses
  ]);

  const allPendingstatuses = useMemo(
    () => bonuses.map((bonus) => bonus.bonusStatus === 'PENDING' && bonus.id),
    [bonuses]
  );

  const checkBoxPendingStatuses = useCallback(() => {
    return bonuses.every(bonus => bonus.bonusStatus === 'PENDING') ? (
      <div className="d-flex align-items-center px-2">
        <CheckboxDiv
          isChecked={selectedStatuses.length === allPendingstatuses.length}
          label={
            selectedStatuses.length === allPendingstatuses.length
              ? 'Deselect all'
              : 'Select all'
          }
          className={'font-weight-bold'}
          id="select-all"
          onChange={() =>
            setSelectedStatuses(
              selectedStatuses.length === allPendingstatuses.length
                ? []
                : bonuses.map(bonus => bonus.id)
            )
          }
        />
      </div>
    ) : null;
  }, [allPendingstatuses, bonuses, selectedStatuses.length]);

  const resetModal = useCallback(() => {
    setOpenRejectModal(false);
    resetReason();
    setRejectPayload({});
  }, [resetReason]);

  const setRejectModal = useCallback(payload => {
    setOpenRejectModal(true);
    setRejectPayload(payload);
  }, []);

  const options = useMemo(() => {
    return {
      campaigns: campaigns.map(element => {
        return {
          value: element.id,
          label: element.name,
          type: 'campaigns'
        };
      })
    };
  }, [campaigns]);

  return (
    <>
      <h2>Bonus status</h2>
      <br />
      <div className="efb-form form-row">
        <div className="col">
          <Input
            placeholder="Bonus ID"
            onChange={setBonusId}
            value={bonusId}
            className="form-control"
            name="bonusId"
          />
        </div>
        <div className="col">
          <Input
            placeholder="Bonus Name"
            onChange={setBonusName}
            value={bonusName}
            className="form-control"
            name="bonusName"
          />
        </div>
        <div className="col">
          <Select
            onChange={setCampaign}
            options={options.campaigns}
            styles={selectStyles}
            placeholder={'Select campaigns'}
            name="campaigns"
          />
        </div>
        <div className="col">
          <Select
            onChange={setBonusStatus}
            options={BonusStatusesForSelect}
            value={bonusStatus}
            styles={selectStyles}
            placeholder="Select status"
            name="status"
            isClearable
          />
        </div>
        <div className="offset-1 col">
          <Button onClick={searchBonusStatuses} cssClass="efb-btn__main">
            Search
          </Button>
        </div>
        {selectedStatuses.length ? (
          <div className="col">
            <Button
              cssClass="efb-btn__main"
              onClick={() => {
                approveCustomerBonus(
                  selectedStatuses.map(bonusId => {
                    const bonus = bonuses.find(bonus => bonus.id === bonusId);
                    return {
                      bonusCustomerId: bonus.id,
                      customerId: bonus.customerId,
                      bonusAmount: bonus.bonusAmount,
                      bonusType: bonus.type
                    };
                  })
                );
                setBonusStatus(null);
                setSelectedStatuses([]);
              }}
            >
              Approve Selected
            </Button>
          </div>
        ) : null}
      </div>
      <Table
        columns={columns}
        data={bonuses}
        tableType="BonusStatusTable"
        tableStyle="sortable table table-hover table-striped efb-table efb-data-table"
        approveCustomerBonus={approveCustomerBonus}
        setDisabledFor={setDisabledFor}
        disabledFor={disabledFor}
        openRejectModal={setRejectModal}
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
        checkboxDiv={checkBoxPendingStatuses()} // It's property of Table component , not table-row component
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />

      <Modal
        headerText="Reject bonus"
        onClose={resetModal}
        isActive={openRejectModal}
      >
        <div className="efb-modal__content">
          <textarea
            name="reason"
            id="reason"
            cols="30"
            rows="10"
            value={reason}
            onChange={setReason}
            className="form-control"
            placeholder="Reason"
          />
          <Button
            cssClass="btn btn-block btn-danger mt-3"
            onClick={() => {
              rejectCustomerBonus({
                ...rejectPayload,
                rejectedReason: reason
              }).then(() => {
                resetModal();
              });
            }}
          >
            Reject
          </Button>
        </div>
      </Modal>
    </>
  );
};

BonusesStatus.propTypes = {
  campaigns: PropTypes.array,
  clearBonuses: PropTypes.func,
  editBonus: PropTypes.func,
  addNotificationMessage: PropTypes.func,
  getAllBonuses: PropTypes.func,
  statusDataForBonuses: PropTypes.array,
  approveCustomerBonus: PropTypes.func.isRequired,
  rejectCustomerBonus: PropTypes.func.isRequired
};

export default BonusesStatus;
