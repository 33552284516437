import React from 'react';

const IconSingle = ({
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 17 17"
      {...otherProps}
    >
      <path
        d="M1266.783,63.494V78.622h-15.127V63.494h15.127m.936-.936h-17v17h17v-17Z"
        transform="translate(-1250.719 -62.558)"
        fill="#535353"
      />
      <g transform="translate(6.538 4.589)">
        <path
          d="M1386.625,163.048h-1.409v-5.31a4.855,4.855,0,0,1-1.82,1.068v-1.279a4.076,4.076,0,0,0,1.2-.684,2.6,2.6,0,0,0,.887-1.176h1.143Z"
          transform="translate(-1383.396 -155.668)"
          fill="#535353"
        />
      </g>
    </svg>
  );
};

export default IconSingle;