import axiosInstance from '../axios-instance';
import { createRequestTypes, actionCreator } from '../index';
import { addNotificationMessage } from '../ui';

export const BUILDER_GET_COUNTRIES = createRequestTypes(
    'BUILDER_GET_COUNTRIES'
  ),
  BUILDER_GET_SPORTS = createRequestTypes('BUILDER_GET_SPORTS'),
  BUILDER_GET_SEASONS = createRequestTypes('BUILDER_GET_SEASONS'),
  BUILDER_GET_COMPETITORS = createRequestTypes('BUILDER_GET_COMPETITORS'),
  BUILDER_GET_TOURNAMENTS = createRequestTypes('BUILDER_GET_TOURNAMENTS'),
  BUILDER_GET_EVENTS = createRequestTypes('BUILDER_GET_EVENTS'),
  HOME_COMPETITORS = createRequestTypes('HOME_COMPETITORS'),
  AWAY_COMPETITORS = createRequestTypes('AWAY_COMPETITORS'),
  GET_EVENT_BUILDER_ERRORS = createRequestTypes('GET_EVENT_BUILDER_ERRORS'),
  GET_MARKET_CATEGORIES = createRequestTypes('GET_MARKET_CATEGORIES'),
  GET_MARKET_TYPES = createRequestTypes('GET_MARKET_TYPES'),
  GET_MARKET_TYPE = createRequestTypes('GET_MARKET_TYPE'),
  GET_OUTCOMES = createRequestTypes('GET_OUTCOMES'),
  GET_EVENT_MARKETS = createRequestTypes('GET_EVENT_MARKETS'),
  GET_SPORT_OBJ = createRequestTypes('GET_SPORT_OBJ'),
  RESET_DEFAULT_MARKET_STATUS = createRequestTypes(
    'RESET_DEFAULT_MARKET_STATUS'
  ),
  UPDATED_DEFAULT_MARKET = createRequestTypes('UPDATED_DEFAULT_MARKET'),
  CREATE_MARKET = createRequestTypes('CREATE_MARKET');

export const getSports = () => async dispatch => {
  dispatch(actionCreator.request(BUILDER_GET_SPORTS));
  try {
    dispatch(actionCreator.empty(BUILDER_GET_SPORTS));
    const res = await axiosInstance.get('/sport/event/view/sport/admin');
    dispatch(actionCreator.success(BUILDER_GET_SPORTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(BUILDER_GET_SPORTS, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const emptySports = () => {
  return dispatch => {
    dispatch(actionCreator.empty(BUILDER_GET_SPORTS));
  };
};

export const getCountries = sportId => async dispatch => {
  dispatch(actionCreator.request(BUILDER_GET_COUNTRIES));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/category/admin?sportId=${sportId}`
    );
    dispatch(actionCreator.success(BUILDER_GET_COUNTRIES, res));
  } catch (err) {
    dispatch(actionCreator.failure(BUILDER_GET_COUNTRIES, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getTournaments = countryId => async dispatch => {
  dispatch(actionCreator.request(BUILDER_GET_TOURNAMENTS));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/tournament/admin?categoryId=${countryId}`
    );
    dispatch(actionCreator.success(BUILDER_GET_TOURNAMENTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(BUILDER_GET_TOURNAMENTS, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getSeasons = id => async dispatch => {
  dispatch(actionCreator.request(BUILDER_GET_SEASONS));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/season/admin?categoryId=${id}`
    );
    dispatch(actionCreator.success(BUILDER_GET_SEASONS, res));
  } catch (err) {
    dispatch(actionCreator.failure(BUILDER_GET_SEASONS, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getEvents = id => async dispatch => {
  dispatch(actionCreator.request(BUILDER_GET_EVENTS));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/match/admin?tournamentId=${id}`
    );
    dispatch(actionCreator.success(BUILDER_GET_EVENTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(BUILDER_GET_EVENTS, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getCompetitors = (letter, sportId, side) => async dispatch => {
  if (side === 'HOME') {
    dispatch(actionCreator.request(HOME_COMPETITORS));
    try {
      const res = await axiosInstance.get(
        `/sport/event/view/competitor/admin/name?pattern=${letter}&sportId=${sportId}`
      );
      dispatch(actionCreator.success(HOME_COMPETITORS, res));
    } catch (err) {
      dispatch(actionCreator.failure(HOME_COMPETITORS, ''));
      dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    }
  } else {
    dispatch(actionCreator.request(AWAY_COMPETITORS));
    try {
      const res = await axiosInstance.get(
        `/sport/event/view/competitor/admin/name?pattern=${letter}&sportId=${sportId}`
      );
      dispatch(actionCreator.success(AWAY_COMPETITORS, res));
    } catch (err) {
      dispatch(actionCreator.failure(AWAY_COMPETITORS, ''));
      dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    }
  }
};

export const getMarketCategories = () => async dispatch => {
  dispatch(actionCreator.request(GET_MARKET_CATEGORIES));
  try {
    const res = await axiosInstance.get(
      '/market/view/market-type-category/admin'
    );
    dispatch(actionCreator.success(GET_MARKET_CATEGORIES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_MARKET_CATEGORIES, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const resetMarketCategories = () => async dispatch => {
  dispatch(actionCreator.success(GET_MARKET_CATEGORIES, []));
};

export const getMarketTypes = marketCatId => async dispatch => {
  dispatch(actionCreator.request(GET_MARKET_TYPES));
  try {
    const res = await axiosInstance.get(
      `/market/view/market-type/public?categoryId=${marketCatId}&lang=en`
    );
    dispatch(actionCreator.success(GET_MARKET_TYPES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_MARKET_TYPES, ''));
    dispatch(addNotificationMessage(err, 'error', 'Error'));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getOutcomes = marketTypeId => async dispatch => {
  actionCreator.request(GET_OUTCOMES);
  try {
    const res = await axiosInstance.get(
      `/market/view/outcome/admin?marketTypeId=${marketTypeId}`
    );
    dispatch(actionCreator.success(GET_OUTCOMES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_OUTCOMES, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const createEvent = event => async dispatch => {
  try {
    await axiosInstance.post('/sport/event/view/match/admin/', event);
    dispatch(getEvents(event.tournamentId));
    dispatch(actionCreator.empty(HOME_COMPETITORS));
    dispatch(actionCreator.empty(AWAY_COMPETITORS));
    dispatch(
      addNotificationMessage(
        'Sport Event has successfully created',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getSportObj = feedProviderId => async dispatch => {
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/match/admin/feed-provider/${feedProviderId}`
    );
    const sportEventId = res;
    dispatch(proceedToGetSportObj(sportEventId));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const proceedToGetSportObj = sportEventId => async dispatch => {
  dispatch(actionCreator.request(GET_SPORT_OBJ));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/match/admin/${sportEventId}`
    );
    dispatch(getMarketsForSportEvent(res));
    dispatch(actionCreator.success(GET_SPORT_OBJ, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_SPORT_OBJ, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getMarketTypeObjDetails = id => async dispatch => {
  dispatch(actionCreator.request(GET_MARKET_TYPE));
  try {
    const res = await axiosInstance.get(`/market/view/market-type/admin/${id}`);
    dispatch(actionCreator.success(GET_MARKET_TYPE, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_MARKET_TYPE, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getMarketsForSportEvent = sportObj => async dispatch => {
  dispatch(actionCreator.request(GET_EVENT_MARKETS));
  try {
    const res = await axiosInstance.get(
      `/market/view/market/admin?sportEventId=${sportObj.id}`
    );
    dispatch(actionCreator.success(GET_EVENT_MARKETS, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_EVENT_MARKETS, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const createMarket = (
  marketObj,
  sportObj,
  marketsLength
) => async dispatch => {
  dispatch(actionCreator.request(CREATE_MARKET));
  try {
    await axiosInstance.post('/market/market/', marketObj);
    dispatch(actionCreator.success(CREATE_MARKET, ''));

    const interval = setInterval(async () => {
      const res = await axiosInstance.get(
        `/market/view/market/admin?sportEventId=${sportObj.id}`
      );
      if (res.length === marketsLength + 1) {
        clearInterval(interval);
        dispatch(actionCreator.success(GET_EVENT_MARKETS, res));
      }
    }, 500);

    dispatch(
      addNotificationMessage(
        'Market has successfully created',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(actionCreator.failure(CREATE_MARKET, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateMarket = (market, marketPart) => async dispatch => {
  dispatch(actionCreator.request(RESET_DEFAULT_MARKET_STATUS));
  try {
    await axiosInstance.post('/market/market/', market);
    dispatch(
      addNotificationMessage(
        'You have successfully updated market',
        'success',
        'Success'
      )
    );
    if (marketPart === 'marketPrice') {
      dispatch(checkIsMarketPriceUpdated(market));
    } else {
      dispatch(actionCreator.success(RESET_DEFAULT_MARKET_STATUS, ''));
      dispatch(getMarketsForSportEvent({ id: market.sportEventAggregateId }));
    }
  } catch (err) {
    dispatch(actionCreator.failure(RESET_DEFAULT_MARKET_STATUS, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

const checkIsMarketPriceUpdated = market => async dispatch => {
  try {
    const interval = setInterval(async () => {
      let updated = true;
      const res = await axiosInstance.get(
        `/market/view/market/admin?sportEventId=${market.sportEventAggregateId}`
      );
      const currentMarketFromResponse = res.find(x => x.id === market.id);
      if (
        currentMarketFromResponse.outcomeBetInfos.length ===
        market.outcomeBetInfos.length
      ) {
        // checking is it updated on backend side
        currentMarketFromResponse.outcomeBetInfos.forEach((outcomeX, index) => {
          const activeOutcomeX = outcomeX.outcomeOdds.find(x => x.active);
          if (
            activeOutcomeX.odds !==
            market.outcomeBetInfos[index].outcomeOdds.find(x => x.active).odds
          ) {
            updated = false;
          }
        });
        if (updated) {
          dispatch(actionCreator.success(GET_EVENT_MARKETS, res));
          clearInterval(interval);
        }
      }
    }, 500);
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getSportObjWithId = id => async dispatch => {
  dispatch(actionCreator.request(GET_SPORT_OBJ));
  try {
    const res = await axiosInstance.get(`/sport/event/view/match/admin/${id}`);
    dispatch(actionCreator.success(GET_SPORT_OBJ, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_SPORT_OBJ, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const resetSelectors = () => {
  return dispatch => {
    dispatch(actionCreator.success(BUILDER_GET_COUNTRIES, []));
    dispatch(actionCreator.success(BUILDER_GET_SEASONS, []));
    dispatch(actionCreator.success(BUILDER_GET_TOURNAMENTS, []));
  };
};

export const updateEventStartTime = (data, tournamentId) => async dispatch => {
  try {
    await axiosInstance.post(`/sport/event/view/match/admin/${data.id}`, data);
    dispatch(getEvents(tournamentId));
    dispatch(
      addNotificationMessage(
        'Succesffully updated event start time',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const emptyMarketType = () => async dispatch => {
  dispatch(actionCreator.empty(GET_MARKET_TYPE));
};

export const updateMatchStatus = (
  event,
  id,
  tournamentId
) => async dispatch => {
  try {
    await axiosInstance.post(`/sport/event/view/match/admin/${id}`, event);
    dispatch(getEvents(tournamentId));
    dispatch(
      addNotificationMessage(
        'Successfully updated match status',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateDefaultMarket = (
  previousMarket,
  currentMarket
) => async dispatch => {
  dispatch(actionCreator.request(UPDATED_DEFAULT_MARKET));
  try {
    if (previousMarket) {
      await axiosInstance.post('/market/market', previousMarket);
      const timeout = 1000;
      let passedTime = 0;

      const getMarketInterval = setInterval(async () => {
        try {
          const response = await axiosInstance.get(
            `/market/market/${previousMarket.id}`
          );
          if (!response.defaultMarket) {
            clearInterval(getMarketInterval);
            await axiosInstance.post('/market/market', currentMarket);
          }
        } catch (err) {
          dispatch(addNotificationMessage(err.message, 'error', 'Error'));
        }
        passedTime += timeout;

        if (passedTime >= 3000) {
          clearInterval(getMarketInterval);
        }
      }, 1000);
    } else {
      await axiosInstance.post('/market/market', currentMarket);
    }
    dispatch(actionCreator.success(UPDATED_DEFAULT_MARKET));
    dispatch(
      addNotificationMessage(
        'Successfully updated default market',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(actionCreator.failure(UPDATED_DEFAULT_MARKET, err.message));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};
