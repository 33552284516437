import React, { useState } from 'react';

import CreateSeason from './CreateSeason';
import SeasonsTable from './SeasonsTable';

const Seasons = ({ languages }) => {
  const [editingSeason, setEditingSeason] = useState(null);
  const [sportId, setSportId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [tournamentId, setTournamentId] = useState(null);

  return (
    <>
      <h3>Seasons</h3>
      <CreateSeason
        languages={languages}
        editingSeason={editingSeason}
        clearEditingSeason={() => setEditingSeason(null)}
        setSportId={setSportId}
        setCategoryId={setCategoryId}
        setTournamentId={setTournamentId}
      />

      <hr />

      <SeasonsTable
        languages={languages}
        editSeason={setEditingSeason}
        editingSeason={editingSeason}
        sportId={sportId}
        categoryId={categoryId}
        tournamentId={tournamentId}
      />
    </>
  );
};

export default Seasons;
