import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { pendingTransactionsColumns } from '../transactionsUtils';
import { isEmpty } from '../../../../utils/helpers';
import { useStateForInput } from '../../../../hooks';

import PendingTransactionsSelector from './PendingTransactionsSelector';
import Button from '../../../../components/Layout/Button';
import Modal from '../../../../components/Modal/Modal';
import InputField from '../../../../components/Form/InputField';
import Table from '../../../../components/Table/Table';
import TableExports from '../../../../components/Table/TableExports';

const PendingTransactions = ({
  transactions,
  tab,
  states,
  approveOrReject,
  paymentMethods,
  emptyPendingTransaction,
  getPendingTransaction,
  getAccountHistory,
  addNotificationMessage,
}) => {
  const [activeModal, setActiveModal] = useState(false);
  const [comments, setComments, resetComments] = useStateForInput('');
  const [currentTransaction, setCurrentTransaction] = useState('');

  const viewFullSize = useCallback(validation => {
    setCurrentTransaction(validation);
    setActiveModal(true);
  }, []);

  const pendingAction = useCallback(
    approved => {
      if (isEmpty(comments)) {
        addNotificationMessage('Comment is required!', 'error', 'Error');
        return;
      }
      const { customerId, transactionId } = currentTransaction;
      const transactionApproval = {
        approved,
        comments,
        date: moment(),
        reason: 'reason',
        user_id: customerId,
      };
      approveOrReject(transactionApproval, transactionId);
      resetComments();
      setActiveModal(false);
    },
    [
      addNotificationMessage,
      approveOrReject,
      comments,
      currentTransaction,
      resetComments,
    ]
  );

  return (
    <Fragment>
      <div className="tab-pane card-body">
        <PendingTransactionsSelector
          states={states}
          paymentMethods={paymentMethods}
          getPendingTransaction={getPendingTransaction}
          emptyPendingTransaction={emptyPendingTransaction}
        />
        {transactions &&
        transactions.content &&
        transactions.content.length > 0 ? (
          <Fragment>
            <Table
              data={transactions.content}
              columns={pendingTransactionsColumns}
              tableStyle="sortable table table-hover table-striped efb-table efb-data-table"
              tableType="transactionTable"
              viewFullSize={viewFullSize}
              getAccountHistory={getAccountHistory}
              tab={tab}
            />
            <TableExports
              columns={pendingTransactionsColumns}
              tab={tab}
              transactions={transactions.content}
            />
          </Fragment>
        ) : null}
      </div>
      <Modal
        isActive={activeModal}
        headerText="Review"
        onClose={() => setActiveModal(false)}
      >
        <div className="p-5">
          <div className="form-label-group efb-form">
            <div className="col-md-10 m-auto">
              <InputField
                id="comments"
                type="text"
                name="comments"
                className="form-control"
                label="Comment"
                labelCss="label-ml"
                placeholder="Comments"
                onChange={setComments}
              />
            </div>
          </div>
          <div className="form-group d-flex justify-content-center">
            <Button
              onClick={() => pendingAction(true)}
              cssClass="efb-btn__main efb-btn__success"
            >
              Approve
            </Button>
            <Button
              onClick={() => pendingAction(false)}
              cssClass="efb-btn__main efb-btn__danger"
            >
              Reject
            </Button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

PendingTransactions.propTypes = {
  clearDate: PropTypes.func,
  states: PropTypes.array,
  submitDate: PropTypes.func,
  tab: PropTypes.string,
  transactions: PropTypes.object,
  paymentMethods: PropTypes.array,
  approveOrReject: PropTypes.func,
  emptyPendingTransaction: PropTypes.func,
  addNotificationMessage: PropTypes.func,
};

export default PendingTransactions;
