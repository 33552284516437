import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ShowMarketType from './ShowMarketType';
import * as ConfigurationActions from '../../../../redux/actions/riskManagement/configuration';
import * as UiActions from '../../../../redux/actions/ui';
import Button from '../../../../components/Layout/Button';
import MarketTypesTable from './MarketTypesTable';
import { getRiskConfigurationData } from '../../../../selectors';
import DeleteModal from './DeleteModal';

class MarketTypes extends PureComponent {
  state = {
    mode: '',
    marketTypeConfig: {}
  };

  componentDidMount() {
    this.props.getMarketTypesConfigs();
    this.props.getMarketSportViews();
    this.props.getMarketTypesViews();
  }

  createMarketTypeTemplate = () => {
    this.setState(
      {
        mode: 'create',
        marketTypeConfig: {}
      },
      () => {
        document
          .getElementById('showMarketType')
          .scrollIntoView({ behavior: 'smooth' });
      }
    );
  };

  editMarketTypeConfig = config => {
    this.setState(
      {
        mode: 'edit',
        marketTypeConfig: config
      },
      () => {
        document
          .getElementById('showMarketType')
          .scrollIntoView({ behavior: 'smooth' });
      }
    );
  };

  openDeleteModal = config => {
    this.setState({
      mode: 'delete',
      marketTypeConfig: config
    });
  };

  closeShow = () => {
    this.setState({
      mode: '',
      marketTypeConfig: {}
    });
  };

  deleteMarketTypeConfig = e => {
    this.props.deleteMarketTypeConfig(this.state.marketTypeConfig.id);
    this.closeShow();
  };

  handleSubmit = payload => {
    const valid = this.validatePayload(payload);
    if (!valid) {
      return;
    }

    if (this.state.mode === 'create') {
      this.props.createMarketTypeConfig(payload);
    } else if (this.state.mode === 'edit') {
      this.props.updateMarketTypeConfig(
        payload,
        this.state.marketTypeConfig.id
      );
    }
    this.closeShow();
  };

  validatePayload = payload => {
    if (payload.riskControlParameters.length <= 0) {
      this.props.addNotificationMessage(
        'There should be atleast one Risk Parameter',
        'error',
        'Error!'
      );
      return false;
    }
    if (
      !_.every(payload.riskControlParameters, rcp => {
        if (rcp.code === 'MSF_DURATION') {
          return _.isString(rcp.value);
        }
        return !_.isNaN(Number(rcp.value)) || _.isBoolean(rcp.value);
      })
    ) {
      this.props.addNotificationMessage(
        'Risk Parameters should be numbers',
        'error',
        'Error!'
      );
      return false;
    }
    if (!payload.sportId) {
      this.props.addNotificationMessage(
        'Sport is required!',
        'error',
        'Error!'
      );
      return false;
    }
    if (!payload.name) {
      this.props.addNotificationMessage('Name is required!', 'error', 'Error!');
      return false;
    }
    if (!payload.marketTypeId) {
      this.props.addNotificationMessage(
        'Market type is required!',
        'error',
        'Error!'
      );
      return false;
    }
    return true;
  };

  render() {
    const { marketTypeConfig, mode } = this.state;
    const {
      marketSportViews,
      marketTypesConfigs,
      marketTypesViews
    } = this.props;

    return (
      <div className="tab-pane card-body fade show active">
        <div className="form-row mb-3">
          <div className="col-md-6">
            <h3>Market Types</h3>
          </div>

          <div className="col-md-6 text-right">
            <Button
              cssClass="btn btn-outline-primary new-template"
              onClick={this.createMarketTypeTemplate}
            >
              <i className="fas fa-plus" /> New
            </Button>
          </div>
        </div>
        {mode === 'delete' ? (
          <DeleteModal
            onClose={this.closeShow}
            marketTypeConfig={marketTypeConfig}
            deleteMarketTypeConfig={this.deleteMarketTypeConfig}
          />
        ) : null}
        {marketSportViews.length && !(mode === 'edit' || mode === 'create') ? (
          <MarketTypesTable
            handleEdit={this.editMarketTypeConfig}
            handleDelete={this.openDeleteModal}
            marketSportViews={marketSportViews}
            marketTypesViews={marketTypesViews}
            data={marketTypesConfigs}
          />
        ) : null}
        {mode === 'edit' || mode === 'create' ? (
          <ShowMarketType
            marketTypeConfig={marketTypeConfig}
            marketSportViews={marketSportViews}
            handleSubmit={this.handleSubmit}
            closeShow={this.closeShow}
            mode={mode}
          />
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getMarketTypesConfigs: ConfigurationActions.getMarketTypesConfigs,
  getMarketSportViews: ConfigurationActions.getMarketSportViews,
  getMarketTypesViews: ConfigurationActions.getMarketTypesViews,
  createMarketTypeConfig: ConfigurationActions.createMarketTypeConfig,
  updateMarketTypeConfig: ConfigurationActions.updateMarketTypeConfig,
  deleteMarketTypeConfig: ConfigurationActions.deleteMarketTypeConfig,
  addNotificationMessage: UiActions.addNotificationMessage
};

const mapStateToProps = state => ({
  marketTypesConfigs: getRiskConfigurationData(state).marketTypesConfigs,
  marketSportViews: getRiskConfigurationData(state).marketSportViews,
  marketTypesViews: getRiskConfigurationData(state).marketTypesViews
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketTypes);
