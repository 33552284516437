import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';

import Switch from '../../../../components/Form/Switch/Switch';
import { selectStyles } from '../../../CMS/customReactSelectStyle';
import { useStateForSelect, useStateForSwitch } from '../../../../hooks';
import Button from '../../../../components/Layout/Button';

const BetTypeConfig = ({
  betTypeConfigs,
  systemTypes,
  createConfig,
  addNotificationMessage,
}) => {
  const [betType, setBetType] = useStateForSelect('');
  const [allow, setAllow] = useStateForSwitch(false);
  const [allowWithBanker, setAllowWithBanker] = useStateForSwitch(false);

  const typeOptions = useMemo(
    () =>
      systemTypes
        .map(type => ({ label: type }))
        .filter(
          type => !betTypeConfigs.find(config => config.type === type.label)
        ),
    [betTypeConfigs, systemTypes]
  );

  const submitConfig = useCallback(() => {
    if (!betType) {
      addNotificationMessage('Type is required!', 'warning', 'Warning!');
      return;
    }

    createConfig({ type: betType.label, allow, allowWithBanker }).then(() =>
      setBetType('')
    );
  }, [
    addNotificationMessage,
    allow,
    allowWithBanker,
    betType,
    createConfig,
    setBetType,
  ]);

  return (
    <div className="form-row align-items-center mt-5">
      <Select
        placeholder="Select bet type"
        styles={selectStyles}
        options={typeOptions}
        value={betType}
        onChange={setBetType}
        className="flex-grow-1 mr-3"
      />

      <Switch
        isChecked={allow}
        onChange={setAllow}
        label="Allow"
        name="allow"
      />

      <Switch
        isChecked={allowWithBanker}
        onChange={setAllowWithBanker}
        label="Allow with Banker"
        name="allowWithBanker"
      />

      <Button cssClass="efb-btn__main" onClick={submitConfig}>
        Create
      </Button>
    </div>
  );
};

export default BetTypeConfig;
