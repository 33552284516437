import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import classes from './OtherParticulars.module.scss';
import Select from 'react-select';
import { underlinedSelectStyles } from '../../../../../utils/selectStyles';
import CheckboxDiv from '../../../../../components/Form/CheckboxDiv/CheckboxDiv';
import Buttons from '../../../../../components/ModalButtons/Buttons';
import Input from '../../../../../components/Form/Input';
import CalendarDayPicker from '../../../../../components/CalendarDayPicker/CalendarDayPicker';
import * as CustomerActions from '../../../../../redux/actions/customer/customerManager';
import moment from 'moment';
import {
  useFetch,
  useStateForSelect,
  useStateForInput,
  useUpdate,
} from '../../../../../hooks';

const OtherParticulars = ({ customer, updateCustomerDocument, onClose }) => {
  const [openedIssueDateCalendar, setOpenedIssueDateCalendar] = useState(false);
  const [openedExpiryDateCalendar, setOpenedExpiryDateCalendar] = useState(
    false
  );

  const [issueDateActive, setIssueDateActive] = useState(true);
  const [expiryDateActive, setExpiryDateActive] = useState(true);

  const [issueDate, setIssueDate] = useState(Date.now());
  const [expiryDate, setExpiryDate] = useState(Date.now());
  const [documentType, setDocumentType] = useStateForSelect('');
  const [issuePlace, setIssuePlace] = useStateForInput('');

  const { data: documentTypes } = useFetch(
    `/customer/view/admin/documents/types`
  );
  const { data: customerDocuments } = useFetch(
    `/customer/view/admin/${customer.customerId}/documents`
  );

  useUpdate(() => {
    if (customerDocuments && customerDocuments.length > 0) {
      const doc = customerDocuments[0];
      setDocumentType({
        value: doc.verificationDocument.documentType,
        label: doc.verificationDocument.documentType,
      });
      setIssuePlace(doc.placeOfIssue ? doc.placeOfIssue : '');
      setIssueDate(doc.dateOfIssue ? new Date(doc.dateOfIssue) : Date.now());
      setExpiryDate(doc.validityDate ? new Date(doc.validityDate) : Date.now());
    }
  }, [customerDocuments]);

  const documentTypesMapped = useMemo(() => {
    return documentTypes
      ? documentTypes.map(type => {
          return { label: type.documentType, value: type.documentType };
        })
      : null;
  }, [documentTypes]);

  const handleDayClick = useCallback((day, direction) => {
    if (direction === 'from') {
      setIssueDate(day);
      setOpenedIssueDateCalendar(false);
    } else {
      setExpiryDate(day);
      setOpenedExpiryDateCalendar(false);
    }
  }, []);

  const updateDocument = useCallback(() => {
    const documentClone = { ...customerDocuments[0] };
    delete documentClone.verificationDocument;
    delete documentClone.cancelReason;
    delete documentClone.url;
    documentClone.documentType = documentType.value;
    documentClone.dateOfIssue = moment(issueDate).format(
      'YYYY-MM-DDTHH:mm:ss.SSSS[Z]'
    );
    documentClone.validityDate = moment(expiryDate).format(
      'YYYY-MM-DDTHH:mm:ss.SSSS[Z]'
    );
    documentClone.placeOfIssue = issuePlace;
    documentClone.documentNumber = '';
    updateCustomerDocument(documentClone);
  }, [
    customerDocuments,
    documentType.value,
    issueDate,
    expiryDate,
    issuePlace,
    updateCustomerDocument,
  ]);

  return (
    <>
      <div className={classes.wrapper}>
        <h1 className={classes.title}>Indentification</h1>
        <div className={classes.formLayout}>
          <label>ID Type:</label>
          <Select
            value={documentType}
            options={documentTypesMapped ? documentTypesMapped : []}
            placeholder="Document Types"
            styles={underlinedSelectStyles}
            onChange={setDocumentType}
          />
        </div>
        <div className={classes.formLayout}>
          <label>Customer ID:</label>
          <Input
            customInput
            disabled
            type="text"
            value={customer ? customer.customerId : 0}
            className="efb-input__underlined"
          />
        </div>
        <div className={classes.formLayout}>
          <label>Place of Issue:</label>
          <Input
            customInput
            type="text"
            value={issuePlace}
            onChange={setIssuePlace}
            className="efb-input__underlined"
          />
        </div>
        <div className={`${classes.formLayout} ${classes.extended}`}>
          <label>Date of Issue:</label>
          <div className={`${classes.formWrap}`}>
            <div className={classes.datePicker}>
              <CheckboxDiv
                isChecked={issueDateActive}
                onChange={() => setIssueDateActive(!issueDateActive)}
                value={issueDateActive}
                name={'issueDate'}
                id={'issueDate'}
              />
            </div>
            {openedIssueDateCalendar && issueDateActive ? (
              <CalendarDayPicker
                handleDayClick={e => handleDayClick(e, 'from')}
                from={issueDate}
              />
            ) : null}
            <p
              className={classes.pickedTime}
              style={{
                color: !issueDateActive ? 'rgba(112, 112, 112, 0.5)' : '',
              }}
              onClick={() =>
                setOpenedIssueDateCalendar(!openedIssueDateCalendar)
              }
            >
              {moment(issueDate).format('DD.MM.YYYY.')}
            </p>
          </div>
          <label>Date of Expiry:</label>
          <div className={`${classes.formWrap}`}>
            <div className={classes.datePicker}>
              <CheckboxDiv
                isChecked={expiryDateActive}
                onChange={() => setExpiryDateActive(!expiryDateActive)}
                value={expiryDateActive}
                name={'expiryDate'}
                id={'expiryDate'}
              />
            </div>
            {openedExpiryDateCalendar && expiryDateActive ? (
              <CalendarDayPicker
                handleDayClick={e => handleDayClick(e, 'to')}
                from={expiryDate}
              />
            ) : null}
            <p
              className={classes.pickedTime}
              style={{
                color: !expiryDateActive ? 'rgba(112, 112, 112, 0.5)' : '',
              }}
              onClick={() =>
                setOpenedExpiryDateCalendar(!openedExpiryDateCalendar)
              }
            >
              {moment(expiryDate).format('DD.MM.YYYY.')}
            </p>
          </div>
        </div>
      </div>
      <Buttons
        apply={updateDocument}
        ok={() => {
          updateDocument();
          onClose();
        }}
        cancel={onClose}
      />
    </>
  );
};

const mapDispatchToProps = {
  updateCustomerDocument: CustomerActions.updateCustomerDocument,
};

export default connect(null, mapDispatchToProps)(OtherParticulars);
