import React, { useCallback, useState } from 'react';
import Button from '../../../../components/Layout/Button';
import InputField from '../../../../components/Form/InputField';
import { useUpdate } from '../../../../hooks';

const EditCurrency = ({
  toEditCurrency,
  saveCurrentCurrencyRate,
  cancelEdit,
}) => {
  const [newRate, setNewRate] = useState(toEditCurrency.rate);

  useUpdate(() => {
    setNewRate(toEditCurrency.rate);
  }, [toEditCurrency]);

  const handleChange = useCallback(event => {
    setNewRate(event.target.value ? Number(event.target.value) : '');
  }, []);

  return (
    <div className="efb-form">
      <div className="form-row">
        <div className="form-label-group col">
          <InputField
            disabled={true}
            type="text"
            name="name"
            className="form-control"
            label="Name"
            placeholder="Name"
            value={toEditCurrency.name}
          />
        </div>
        <div className="form-label-group col">
          <InputField
            type="number"
            name="rate"
            className="form-control"
            label="Rate"
            placeholder="Rate"
            value={newRate}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-row">
        <Button onClick={cancelEdit} cssClass="efb-btn__main col-md">
          Cancel
        </Button>
        <Button
          disabled={!newRate || newRate === toEditCurrency.rate}
          onClick={() =>
            saveCurrentCurrencyRate({
              id: toEditCurrency.id,
              rate: toEditCurrency.rate,
              newRate,
            })
          }
          cssClass="efb-btn__main col-md"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditCurrency;
