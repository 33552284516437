export const submitContent = (
  content,
  countryCurrencySubpath,
  serverError,
  contentType
) => {
  const contentClone = { ...content };
  const langs = Object.keys(contentClone.enabled);

  if (contentClone.name && !serverError) {
    contentClone.subpages.forEach(subpage => {
      langs.map(lang => {
        if (countryCurrencySubpath) {
          delete subpage.content[0][lang].tables;
        } else {
          delete subpage.content[0][lang].subpages;
        }
        return subpage;
      });
      switch (contentType) {
        case 'RULES':
          langs.map(lang => {
            if (!subpage.content[0][lang].rules[0].name) {
              delete subpage.content[0][lang];
            }
            if (subpage.content[0][lang]) {
              delete subpage.content[0][lang].sections;
              delete subpage.content[0][lang].subpages;
            }
            return subpage;
          });
          break;

        case 'SECTIONS':
          langs.map(lang => {
            if (!subpage.content[0][lang].sections[0].name) {
              delete subpage.content[0][lang];
            }
            if (subpage.content[0][lang]) {
              delete subpage.content[0][lang].rules;
              delete subpage.content[0][lang].subpages;
            }
            return subpage;
          });
          break;

        case 'REGULAR':
          langs.map(lang => {
            if (!subpage.content[0][lang].html) {
              delete subpage.content[0][lang];
            }
            if (subpage.content[0][lang]) {
              delete subpage.content[0][lang].rules;
              delete subpage.content[0][lang].sections;
              delete subpage.content[0][lang].subpages;
              delete subpage.content[0][lang].tables;
            }
            return subpage;
          });
          break;

        case 'TABLES':
          langs.map(lang => {
            if (countryCurrencySubpath) {
              subpage.content[0][lang].subpages.map(subpath => {
                if (!subpath.tables[0].name) {
                  delete subpage.content[0][lang];
                }
                return subpage;
              });
            } else {
              if (!subpage.content[0][lang].tables[0].name) {
                delete subpage.content[0][lang];
              }
            }
            if (subpage.content[0][lang]) {
              delete subpage.content[0][lang].rules;
              delete subpage.content[0][lang].sections;
              delete subpage.content[0][lang].html;
            }
            return subpage;
          });
          break;

        default:
          return subpage.content[0];
      }
    });
  }

  return contentClone;
};

export const preparePageContentForEdit = (
  content,
  editType,
  pageName,
  pageLang,
  resourceType,
  countryCurrencySubpath
) => {
  let contentClone = { ...content };
  const langs = Object.keys(contentClone.enabled);
  const subpageArr = [];
  let sections, rules, tables;

  contentClone.subpages.forEach((subpage, index) => {
    switch (resourceType) {
      case 'RULES':
        langs.map(lang => {
          if (!subpage.content[0][lang].rules[0].name) {
            delete subpage.content[0][lang];
          }
          if (subpage.content[0][lang]) {
            delete subpage.content[0][lang].sections;
            delete subpage.content[0][lang].html;
            delete subpage.content[0][lang].subpages;
            delete subpage.content[0][lang].tables;
          }
          if (subpage.resourceType !== 'ROOT') {
            rules = subpage.content[0][pageLang.value].rules;
          }
          return subpage;
        });
        break;

      case 'SECTIONS':
        langs.map(lang => {
          if (!subpage.content[0][lang].sections[0].name) {
            delete subpage.content[0][lang];
          }
          if (subpage.content[0][lang]) {
            delete subpage.content[0][lang].rules;
            delete subpage.content[0][lang].html;
            delete subpage.content[0][lang].subpages;
            delete subpage.content[0][lang].tables;
          }
          return subpage;
        });
        if (subpage.resourceType !== 'ROOT') {
          sections = subpage.content[0][pageLang.value].sections;
        }
        break;

      case 'REGULAR':
        langs.map(lang => {
          if (!subpage.content[0][lang].html) {
            delete subpage.content[0][lang];
          }
          if (subpage.content[0][lang]) {
            delete subpage.content[0][lang].rules;
            delete subpage.content[0][lang].sections;
            delete subpage.content[0][lang].html;
            delete subpage.content[0][lang].tables;
          }
          return subpage;
        });
        break;

      case 'TABLES':
        langs.map(lang => {
          if (countryCurrencySubpath) {
            subpage.content[0][lang].subpages.map(subpath => {
              if (!subpath.tables[0].name) {
                delete subpage.content[0][lang];
              }
              return subpage;
            });
          } else {
            if (!subpage.content[0][lang].tables[0].name) {
              delete subpage.content[0][lang];
            }
          }
          if (subpage.resourceType !== 'ROOT') {
            countryCurrencySubpath
              ? (tables = subpage.content[0][pageLang.value].subpages[0].tables)
              : (tables = subpage.content[0][pageLang.value].tables);
          }
          if (subpage.content[0][lang]) {
            delete subpage.content[0][lang].rules;
            delete subpage.content[0][lang].sections;
            delete subpage.content[0][lang].html;
          }
          return subpage;
        });
        break;

      default:
        return subpage.content[0];
    }
    subpageArr.push(subpage.content[0][pageLang.value]);
    subpageArr[index].pageType = subpage.pageType;
    subpageArr[index].subpages = [];
  });

  if (sections) contentClone['sections'] = sections;
  if (rules) contentClone['rules'] = rules;
  if (tables) contentClone['tables'] = tables;

  if (resourceType !== 'ROOT') {
    delete contentClone.subpages;
  } else {
    contentClone.subpages = subpageArr;
  }

  contentClone.enabled = true;
  contentClone['changeType'] = editType;
  delete contentClone.name;

  return contentClone;
};

export const updateTableContent = (content, subpageIndex, pageObj) => {
  // TODO: will be used later

  const updateContent = {
    changeType: 'REPLACE',
    enabled: true,
    subpages: [
      {
        pageType: 'ROOT',
        enabled: true,
        name: 'deposit',
        subpages: [
          {
            name: 'bg',
            enabled: true,
            pageType: 'TABLES',
            tables: [
              {
                name: content.name,
                table: content.table,
              },
            ],
          },
        ],
      },
    ],
  };
  return updateContent;
};

export const updatePage = (isChecked, page) => ({
  enabled: isChecked,
  name: page.name,
});

export const updateSubpage = subpage => ({
  changeType: 'REPLACE',
  name: subpage.name,
  enabled: subpage.enabled,
});

export const prepareHtml = (html, index) => {
  html.replace(/"/g, '&quot;');
  html = html.split('');
  for (var i = index; i < html.length; i++) {
    if (html[i].charCodeAt(index) === 10) {
      html[i] = '\\n';
    } else if (html[i].charCodeAt(index) === 9) {
      html[i] = '\\t';
    }
  }
  html = html.join('');
  return html;
};

export const prepareImageList = images => {
  const modifiedImagesArr = images.reduce(
    (acc, image) => [...acc, { title: image.imageName, value: image.url }],
    []
  );
  return modifiedImagesArr;
};

export const prepareImage = file => {
  const formData = new FormData();
  formData.append('file', file.blob(), file.filename());
  return formData;
};

export const prepareSectionUpdate = data => ({
  name: data.name,
  headerTitle: data.headerTitle,
  htmlBody: data.htmlBody,
  id: data.id,
  type: data.resourceType,
});

export const prepareRuleUpdate = data => ({
  name: data.name,
  description: data.description,
  title: data.title,
  id: data.id,
  type: data.resourceType,
});

export const updateRegular = content => ({
  changeType: 'REPLACE',
  name: content.name,
  enabled: true,
  html: content.html,
});

export const updateSubpageName = subpages => {
  const content = {
    changeType: 'REPLACE',
    subpages: [],
  };
  subpages.forEach(subpage => {
    delete subpage.children;
    delete subpage.resourceType;
    delete subpage.enabled;
    delete subpage.editMode;
    delete subpage.url;
    content.subpages.push(subpage);
  });
  return content;
};

export const updateSubpageContent = (content, subpages, subpageIndex) => {
  const subpagesClone = [...subpages];

  const obj = {
    changeType: 'ADD',
    subpages: '',
  };

  const pageType = subpagesClone[subpageIndex].resourceType;

  subpagesClone[subpageIndex].pageType = pageType;
  subpagesClone.forEach((subpage, index) => {
    if (subpageIndex !== index) {
      subpages.splice(index, 1);
    }
  });

  subpagesClone.forEach((subpage, idx) => {
    delete subpage.id;
    delete subpage.children;
    delete subpage.resourceType;
    delete subpage.enabled;
    delete subpage.editMode;
    delete subpage.url;
  });

  subpagesClone[subpageIndex][
    pageType === 'RULES' ? 'rules' : 'sections'
  ] = content;
  obj.subpages = subpagesClone;

  return obj;
};

export const prepareRowCellUpdate = data => ({
  columnCells: [],
  rowCells: [
    {
      id: data.id,
      value: data.value,
    },
  ],
});

export const prepareColumnCellUpdate = data => ({
  columnCells: [
    {
      id: data.id,
      key: data.key,
      value: data.value,
    },
  ],
  rowCells: [],
});

export const prepareForRuleSectionUpdate = content => {
  const contentClone = { ...content };
  delete contentClone.id;
  delete contentClone.type;
  return contentClone;
};
