import { GET_IMAGES, GET_IMAGE_TYPES } from '../../actions/cms/images';

const initialState = {
    images: [],
    imageTypes: []
};

export const imagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_IMAGES.SUCCESS:
            return {
                ...state,
                images: action.payload
            }

        case GET_IMAGE_TYPES.SUCCESS:
            return {
                ...state,
                imageTypes: action.payload
            }

        default:
            return state;
    }
}