import React, { useState, useCallback } from 'react';

import classes from './CheckboxFilter.module.scss';
import CheckboxDiv from '../../../Form/CheckboxDiv/CheckboxDiv';

const CheckboxFilter = ({
  name,
  label,
  options,
  selectAllButton,
  default: defaultValue,
  onFilterChange: pushFilterChange,
  singleValue,
}) => {
  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    option => {
      if (!singleValue) {
        if (values.includes(option.value)) {
          setValues(values.filter(value => value !== option.value));
        } else {
          setValues([...values, option.value]);
        }
      } else {
        setValues([option.value]);
      }
      pushFilterChange({
        name,
        value: option.value,
        valueType: singleValue ? '' : 'array',
      });
    },
    [singleValue, name, pushFilterChange, values]
  );

  const handleMultiChange = useCallback(
    value => {
      setValues(value);
      pushFilterChange({
        name,
        value,
        valueType: 'array',
        replace: true,
      });
    },
    [name, pushFilterChange]
  );

  return (
    <div className={classes.checkbox}>
      <label>{label}:</label>
      {selectAllButton ? (
        <p
          className={classes.selectAll}
          onClick={() =>
            values.length === options.length
              ? handleMultiChange([])
              : handleMultiChange(options.map(option => option.value))
          }
        >
          {values.length === options.length ? 'de' : ''}select all
        </p>
      ) : null}
      <div className={classes.list}>
        {options.map(option => (
          <CheckboxDiv
            id={option.value}
            key={option.value}
            name={!singleValue ? option.value : name}
            label={option.label}
            isChecked={Boolean(values.find(value => value === option.value))}
            onChange={() => handleChange(option)}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckboxFilter;
