import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../../../../components/Form/InputField';

const CompetitorSearch = ({ searchCompetitors, sportId }) => {
  return (
    <div className="efb-form">
      <div className="form-row">
        <div className="form-label-group col-md">
          <InputField
            type="text"
            name="table-search"
            disabled={!sportId}
            placeholder="Competitor Search"
            onChange={e => searchCompetitors(e.target.value)}
            label="Search competitors (start typing competitor name...)"
          />
        </div>
      </div>
    </div>
  );
};

CompetitorSearch.propTypes = {
  searchCompetitors: PropTypes.func,
  sportId: PropTypes.string,
};

export default CompetitorSearch;
