import React, { useState } from 'react';
import Input from '../../../../components/Form/Input';
import Switch from '../../../../components/Form/Switch/Switch';
import PropTypes from 'prop-types';
import { useUpdate } from '../../../../hooks';

const BetslipModalCalculations = ({
  currentSystem,
  currentSelection,
  isRegularBetslip,
  referralType,
  findChangedStake,
  changeStake,
  changedOdds,
  getOddsInputValue,
  onOddsChange,
  odds,
  calculatedMultiBetPotWin,
  setPredefinedAmount,
  predefinedAmount,
  setCutAll,
  cutAll,
  currentTab,
  minutes,
  setMinutes,
  reachedPriceMin,
  setReachedPriceMin,
}) => {
  const [cutAllNumber, setCutAllNumber] = useState('');
  const [minPriceValue, setMinPriceValue] = useState(null);

  useUpdate(() => {
    setCutAllNumber(typeof cutAll === 'string' ? Number(cutAll) : cutAll);
    if (Number(cutAll) >= Number(minPriceValue)) {
      setReachedPriceMin(false);
      setMinPriceValue(null);
    }
    if (reachedPriceMin) setMinPriceValue(cutAll);
  }, [cutAll, reachedPriceMin]);

  return (
    <>
      <div className="col-md-3">
        <div className="selections-form">
          <strong>All selections</strong>
          <div className="efb-form">
            <div className="betslip-time-form no-text-selection">
              <ul className="all-selections-adjustements-list">
                <li>
                  <span className="label">Time:</span>
                  <div className="custom-referral-modal-input">
                    <span
                      onClick={() => setPredefinedAmount(predefinedAmount + 1)}
                    >
                      +
                    </span>
                    <Input
                      customInput
                      type="number"
                      min={0}
                      max={5}
                      value={predefinedAmount}
                      disabled={!currentSelection}
                      onChange={setPredefinedAmount}
                    />
                    <span
                      onClick={() =>
                        setPredefinedAmount(
                          predefinedAmount > 0
                            ? predefinedAmount - 1
                            : predefinedAmount
                        )
                      }
                    >
                      -
                    </span>
                  </div>{' '}
                </li>
                <li>
                  <span className="label">Cut All:</span>
                  <div className="custom-referral-modal-input">
                    <span
                      onClick={() => {
                        if (
                          !isRegularBetslip &&
                          currentTab.type !== 'SINGLE' &&
                          cutAllNumber < 0
                        ) {
                          setCutAll(cutAllNumber + 0.01);
                        }
                      }}
                    >
                      +
                    </span>
                    <Input
                      customInput
                      type="number"
                      disabled={
                        isRegularBetslip || currentTab.type === 'SINGLE'
                      }
                      step={0.01}
                      max={0}
                      min={minPriceValue}
                      value={Number(cutAllNumber).toFixed(2)}
                      onChange={e =>
                        setCutAll(parseFloat(e.target.value).toFixed(2))
                      }
                    />
                    <span
                      onClick={() => {
                        if (
                          !isRegularBetslip &&
                          currentTab.type !== 'SINGLE' &&
                          !reachedPriceMin
                        ) {
                          setCutAll(cutAllNumber - 0.01);
                        }
                      }}
                    >
                      -
                    </span>
                  </div>
                </li>
                <li>
                  <span className="label">Minutes: </span>
                  <Switch
                    className="mx-2"
                    isChecked={minutes}
                    onChange={setMinutes}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="table-responsive">
          <table className="betslip-modify-table">
            <thead>
              <tr>
                <th />
                {currentSystem ? (
                  currentSystem.type !== 'SINGLE' ? (
                    <th>Unit</th>
                  ) : null
                ) : null}
                <th>
                  {currentSystem
                    ? currentSystem.type === 'SINGLE'
                      ? 'Stake'
                      : 'Stake Total'
                    : null}
                </th>
                <th>Odds</th>
                <th>Pot. Winning</th>
                <th>Select. Pos.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Was:</td>
                <td>
                  {Number(
                    currentSystem ? currentSystem.bets[0].bet / 100 : 0
                  ).toFixed(2)}
                </td>
                {currentSystem ? (
                  currentSystem.type !== 'SINGLE' ? (
                    <td>{Number(currentSystem.systemBet / 100).toFixed(2)}</td>
                  ) : null
                ) : null}
                <td>
                  <strong>
                    {currentSelection ? currentSelection.outcomeOdds : '/'}
                  </strong>
                </td>
                <td>
                  {isRegularBetslip
                    ? Number(
                        currentSystem
                          ? referralType === 'BETSLIP_REFERRAL'
                            ? (currentSystem.systemBet / 100) *
                              currentSelection.outcomeOdds
                            : (currentSystem.bets[0].bet / 100) *
                              currentSelection.outcomeOdds
                          : 0
                      ).toFixed(2)
                    : Number(currentSystem.systemTakeout / 100).toFixed(2)}
                </td>
                <td className="red-text">/</td>
              </tr>
              <tr>
                <td>Modified:</td>
                <td style={{ position: 'relative' }}>
                  <Input
                    customInput
                    type="number"
                    value={findChangedStake}
                    min={1}
                    disabled={!currentSystem}
                    onChange={changeStake}
                  />
                </td>
                {currentSystem ? (
                  currentSystem.type !== 'SINGLE' ? (
                    <td>
                      {currentSystem
                        ? Number(
                            findChangedStake * currentSystem.bets.length
                          ).toFixed(2)
                        : '/'}
                    </td>
                  ) : null
                ) : null}
                <td>
                  <Input
                    customInput
                    type="number"
                    step={0.01}
                    disabled={!currentSelection}
                    maxLength={4}
                    min={1.01}
                    max={
                      changedOdds[currentSelection.selectionId] ||
                      currentSelection.outcomeOdds
                    }
                    value={getOddsInputValue()}
                    onChange={onOddsChange}
                  />
                </td>
                <td>
                  {odds && isRegularBetslip
                    ? Number(findChangedStake * odds).toFixed(2)
                    : Number(calculatedMultiBetPotWin).toFixed(2)}
                </td>
                <td className="red-text">/</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

BetslipModalCalculations.propTypes = {
  currentSystem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  currentSelection: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isRegularBetslip: PropTypes.bool,
  referralType: PropTypes.string,
  findChangedStake: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changeStake: PropTypes.func,
  changedOdds: PropTypes.object,
  getOddsInputValue: PropTypes.func,
  onOddsChange: PropTypes.func,
  odds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  calculatedMultiBetPotWin: PropTypes.number,
  setPredefinedAmount: PropTypes.func,
  predefinedAmount: PropTypes.number,
  setCutAll: PropTypes.func,
  cutAllNumber: PropTypes.number,
  currentTab: PropTypes.object,
  minutes: PropTypes.bool,
  setMinutes: PropTypes.func,
};

export default BetslipModalCalculations;
