import {
  SET_BETSCROLLER_ID,
  SET_BETSCROLLER_DATA,
  SET_BET_SCROLLER_EVENTS,
  SET_BET_SCROLLER_BUNITS,
  GET_BET_SCROLLERS,
  GET_BET_SCROLLERS_EVENTS,
  UPDATE_BET_SCROLLER,
} from '../../actions/riskManagement/betScroller';

const initialState = {
  betScrollerId: '',
  betScrollerData: [],
  betScrollers: [],
  events: [],
  bUnits: [],
  updatedBSScroller: [],
  betScrollerEvents: [],
};

//use switch it's more readable
export const betScrollerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BETSCROLLER_ID.SUCCESS:
      return {
        ...state,
        betScrollerId: action.payload,
      };
    case SET_BETSCROLLER_DATA.SUCCESS:
      return {
        ...state,
        betScrollerData: [action.payload, ...state.betScrollerData],
      };
    case GET_BET_SCROLLERS.SUCCESS:
      return {
        ...state,
        betScrollers: action.payload,
      };
    case SET_BET_SCROLLER_BUNITS.SUCCESS:
      return {
        ...state,
        bUnits: action.payload,
      };
    case SET_BET_SCROLLER_EVENTS.SUCCESS:
      return {
        ...state,
        events: action.payload,
      };
    case UPDATE_BET_SCROLLER.SUCCESS:
      return {
        ...state,
        updatedBSScroller: action.payload,
      };
    case GET_BET_SCROLLERS_EVENTS.SUCCESS:
      return {
        ...state,
        betScrollerEvents: action.payload,
      };
    default:
      return state;
  }
};
