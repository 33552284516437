import React, { useCallback } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as UiActions from '../../../../redux/actions/ui';
import * as NraScgActions from '../../../../redux/actions/reporting/nra-scg';

import Select from 'react-select';
import Input from '../../../../components/Form/Input';
import Button from '../../../../components/Layout/Button';

import { selectStyles } from '../../../CMS/customReactSelectStyle';
import { useStateForInput, useStateForSelect } from '../../../../hooks';
import { currencyConvert } from '../../../../utils/helpers';

const BetsPlaced = ({
  monthsOptions,
  getNraReports,
  addNotificationMessage,
}) => {
  const [personalId, setPersonalId] = useStateForInput('');
  const [selectedMonth, setSelectedMonth] = useStateForSelect('');
  const [regulatoryId, setRegulatoryId] = useStateForInput('');

  const generateReport = useCallback(async () => {
    if (selectedMonth && (personalId || regulatoryId)) {
      const data = await getNraReports({
        ...selectedMonth,
        personalId,
        regulatoryId,
      });

      const sheetData = [
        {
          Period: moment.utc(selectedMonth.from).format('MMMM'),
          'Personal Identificator': data.personalIdentityNumber,
          Currency: 'BGN',
          'Number of Bets': data.countBetsCasino + data.countBetsSport,
          'Amount of all bets placed': currencyConvert(
            data.totalBetCasino + data.totalBetSport
          ),
          'Amount of all winnings paid': currencyConvert(
            data.totalProfitCasino + data.totalProfitSport
          ),
          'Player ID NRA': data.napId,
          'Player ID SCG': data.dkhId,
        },
      ];

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const ws = XLSX.utils.json_to_sheet(sheetData);
      ws['!cols'] = [
        'Period',
        'Personal Identificator',
        'Currency',
        'Number of Bets',
        'Amount of all bets placed',
        'Amount of all winnings paid',
        'Player ID NRA',
        'Player ID SCG',
      ].map(type => ({ wch: type.length }));
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const file = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(file, 'BetsPlaced.xlsx');
    } else {
      addNotificationMessage(
        'Select a month and fill in either personal ID or regulatory ID',
        'warning',
        'Warning!'
      );
    }
  }, [
    addNotificationMessage,
    getNraReports,
    personalId,
    regulatoryId,
    selectedMonth,
  ]);

  return (
    <div className="efb-form">
      <p>
        3. Report for all bets palced in the central system based on personal
        identificator filter.
      </p>
      <Input
        name="personalId"
        placeholder="Enter personal identificator"
        className="form-control mb-3"
        value={personalId}
        onChange={setPersonalId}
      />

      <Input
        name="regulatoryId"
        placeholder="Enter regulatory ID"
        className="form-control mb-3"
        value={regulatoryId}
        onChange={setRegulatoryId}
      />

      <Select
        styles={selectStyles}
        onChange={setSelectedMonth}
        value={selectedMonth}
        options={monthsOptions}
        placeholder="Select month"
      />

      <Button cssClass="efb-btn__main btn-block mt-3" onClick={generateReport}>
        Generate Report
      </Button>
    </div>
  );
};

const mapStateToProps = {
  addNotificationMessage: UiActions.addNotificationMessage,
  getNraReports: NraScgActions.getNraReports,
};

export default connect(null, mapStateToProps)(BetsPlaced);
