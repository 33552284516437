import axiosInstance from './axios-instance';
import { addNotificationMessage } from './ui';
import { createRequestTypes, actionCreator } from '.';

export const GET_NAMES = createRequestTypes('GET_NAMES');
export const GET_TEMPLATES_BY_NAME = createRequestTypes(
  'GET_TEMPLATES_BY_NAME'
);
export const GET_PLACEHOLDERS = createRequestTypes('GET_PLACEHOLDERS');
export const GET_USER = createRequestTypes('GET_USER');

export const createMessageTemplate = (
  template,
  editMode,
  templateId
) => async dispatch => {
  try {
    if (!editMode) {
      await axiosInstance.post('/message/service/admin/templates', template);
      dispatch(
        addNotificationMessage(
          'You have successfully created template',
          'success',
          'Success'
        )
      );
    } else {
      await axiosInstance.put(
        `/message/service/admin/templates/${templateId}`,
        template
      );
      dispatch(
        addNotificationMessage(
          'You have successfully updated template',
          'success',
          'Success'
        )
      );
    }
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const getNames = () => async dispatch => {
  dispatch(actionCreator.request(GET_NAMES));
  try {
    const res = await axiosInstance.get(
      '/message/service/admin/templates/names'
    );
    dispatch(actionCreator.success(GET_NAMES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_NAMES, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getListByName = name => async dispatch => {
  dispatch(actionCreator.request(GET_TEMPLATES_BY_NAME));
  try {
    const res = await axiosInstance.get(
      `/message/service/admin/templates/search/findByName?name=${name.value}`
    );
    dispatch(actionCreator.success(GET_TEMPLATES_BY_NAME, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_TEMPLATES_BY_NAME, err.message));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getAllPlaceholders = () => async dispatch => {
  dispatch(actionCreator.request(GET_PLACEHOLDERS));
  try {
    const res = await axiosInstance.get(
      '/message/service/admin/templates/placeholders'
    );
    dispatch(actionCreator.success(GET_PLACEHOLDERS, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_PLACEHOLDERS, err.message));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const sendMessage = message => async dispatch => {
  try {
    await axiosInstance.post('/message/service/admin/messages', message);
    dispatch(
      addNotificationMessage(
        'You have successfully sent message',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};
