import React from 'react';
import PropTypes from 'prop-types';
import Platform from './Platform';
import Modal from '../../../../../components/Modal/Modal';

const GameImages = ({
  images,
  loading,
  casinoGameObj,
  getImages,
  updateChanges,
  finishUploadingImage,
  imageUploadPlatform,
  cancelImageUpload,
  imageUpload,
  activeImageSize,
  switchImageSize,
  currentGameImages,
}) => {
  return (
    <Modal
      isActive={imageUpload}
      onClose={cancelImageUpload}
      headerText={`${
        imageUploadPlatform === 'webImage' ? 'web' : 'mobile'
      } images - ${activeImageSize}`}
    >
      <div className="modal-wrapper casino-manager-modal text-center">
        <ul className="nav nav-tabs nav-fill" id="searchController">
          <li
            className="nav-item"
            onClick={e => switchImageSize(e, 'big', imageUploadPlatform)}
          >
            <span
              tabIndex="1"
              className={
                activeImageSize === 'big' ? 'nav-link active' : 'nav-link'
              }
            >
              <i className="fa fa-image"></i> Big image
            </span>
          </li>
          <li
            className="nav-item"
            onClick={e => switchImageSize(e, 'small', imageUploadPlatform)}
          >
            <span
              tabIndex="2"
              className={
                activeImageSize === 'small' ? 'nav-link active' : 'nav-link'
              }
            >
              <i className="fa fa-image small-image-icon"></i> Small image
            </span>
          </li>
        </ul>

        <div className="form-row cm-image-action-btns">
          <div className="col-md">
            <Platform
              imagePlatform={imageUploadPlatform}
              casinoGameObj={casinoGameObj}
              activeImageSize={activeImageSize}
              images={images}
              loading={loading}
              finishUploadingImage={finishUploadingImage}
              getImages={getImages}
              updateChanges={updateChanges}
              currentGameImages={currentGameImages}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

GameImages.propTypes = {
  casinoGameObj: PropTypes.object,
  getImages: PropTypes.func,
  images: PropTypes.array,
  loading: PropTypes.bool,
  updateChanges: PropTypes.func,
};

export default GameImages;
