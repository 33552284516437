import React, { memo } from 'react';

export const IconCalendar = ({
  className,
  fill,
  height,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 106.15 106.07"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <path d="M101.37,42.72V98.78a2.78,2.78,0,0,1-3,2.51H8.11a3.25,3.25,0,0,1-2.23-.84,2.29,2.29,0,0,1-.76-1.67V42.72h96.25m5-5H.12V98.78a7.76,7.76,0,0,0,8,7.51h90.3a7.77,7.77,0,0,0,8-7.51V37.72Z" />
      <path d="M98.41,6.29h-13V14a7.79,7.79,0,0,1-3.71,13.69,9,9,0,0,1-2.54,0A7.77,7.77,0,0,1,76,13.65V6.23H29.35v7.61a7.69,7.69,0,0,1,3,6.13,7.78,7.78,0,0,1-6.49,7.68,10.86,10.86,0,0,1-1.28.1,10.86,10.86,0,0,1-1.28-.1,7.79,7.79,0,0,1-3.36-13.94V6.29H8.18A8,8,0,0,0,.12,14.21v19H106.44v-19a8,8,0,0,0-8-8Z" />
      <path d="M24.62,18.64A2.35,2.35,0,0,0,27,16.3V2.36a2.36,2.36,0,1,0-4.71,0V16.3A2.35,2.35,0,0,0,24.62,18.64Z" />
      <path d="M80.61,19.66a2.33,2.33,0,0,0,2.33-2.34V3.38A2.35,2.35,0,1,0,78.25,3a2.94,2.94,0,0,0,0,.42V17.32a2.34,2.34,0,0,0,2.36,2.34Z" />
      <text
        style={{ fontSize: '54.09px' }}
        transform="translate(26.23 89.78)"
      >
        31
      </text>
    </svg>
  );
};

export default memo(IconCalendar);
