import React, { useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import classes from './ResponsibleGambling.module.scss';
import Buttons from '../../../../../components/ModalButtons/Buttons';
import DepositLimit from './DepositLimit';
import SelfExclusion from './SelfExclusion';

import * as CustomerActions from '../../../../../redux/actions/customer/customerManager';

import {
  useStateForSelect,
  useFetch,
  useStateForSwitch,
} from '../../../../../hooks';

const ResponsibleGambling = ({
  customer,
  updateDepositLimit,
  updateSelfRestrictions,
  onClose,
}) => {
  const [period, setPeriod] = useStateForSelect('');
  const [isActive, setIsActive, resetIsActive] = useStateForSwitch(false);
  const [limit, setLimit] = useState('');

  const mainAccId = useMemo(
    () =>
      customer && customer.accounts.find(acc => acc.accountType === 'MAIN').id,
    [customer]
  );

  const { data: depositLimit, refetch } = useFetch(
    `/account/view/admin/deposit/limit/account?accountId=${mainAccId}`
  );

  const saveChanges = useCallback(async () => {
    if (period && limit && isActive) {
      await updateDepositLimit({
        accountId: mainAccId,
        depositLimits: [
          {
            active: isActive,
            depositlimit: limit,
            periodType: period.value,
          },
        ],
      });
      await resetIsActive();
      await setLimit('');
      await setPeriod('');
      await refetch();
    }
  }, [
    limit,
    mainAccId,
    period,
    isActive,
    resetIsActive,
    refetch,
    updateDepositLimit,
    setPeriod,
  ]);

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.deposit}>
          <DepositLimit
            depositLimit={depositLimit}
            setPeriod={setPeriod}
            period={period}
            setLimit={setLimit}
            limit={limit}
            setIsActive={setIsActive}
            isActive={isActive}
            refetch={refetch}
          />
        </div>
        <div className={classes.exclusion}>
          <SelfExclusion
            customer={customer}
            updateSelfRestrictions={updateSelfRestrictions}
          />
        </div>
      </div>
      <Buttons
        apply={saveChanges}
        ok={() => {
          saveChanges();
          onClose();
        }}
        cancel={onClose}
      />
    </>
  );
};

const mapDispatchToProps = {
  updateDepositLimit: CustomerActions.updateDepositLimit,
  updateSelfRestrictions: CustomerActions.updateSelfRestrictions,
};

export default connect(null, mapDispatchToProps)(ResponsibleGambling);
