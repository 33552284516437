import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import InputField from '../../../../../components/Form/InputField';
import { useUpdate } from '../../../../../hooks';

const Outcomes = ({
  filters,
  index,
  handleInputChange,
  sportObjNames,
  languages,
}) => {
  const [competitor1, setCompetitor1] = useState({});
  const [competitor2, setCompetitor2] = useState({});

  useUpdate(() => {
    if (sportObjNames) {
      let comp1Clone = { ...competitor1 };
      let comp2Clone = { ...competitor2 };
      languages.forEach(lang => {
        if (sportObjNames[lang.languageCode]) {
          comp1Clone = {
            ...comp1Clone,
            [lang.languageCode]: {
              name: sportObjNames[lang.languageCode].name.split(' vs. ')[0],
              translated: true,
            },
          };
          comp2Clone = {
            ...comp2Clone,
            [lang.languageCode]: {
              name: sportObjNames[lang.languageCode].name.split(' vs. ')[1],
              translated: true,
            },
          };
        }
      });
      setCompetitor1(comp1Clone);
      setCompetitor2(comp2Clone);
    }
  }, [sportObjNames]);

  const showOutcomes = useCallback(
    (outcome, outcomeIndex) => {
      const descriptionTemplatesClone = { ...outcome.descriptionTemplates };
      // replacing {$competitor} with actual team names
      if (descriptionTemplatesClone.en && descriptionTemplatesClone.en.name) {
        if (descriptionTemplatesClone.en.name.includes('{$competitor1}')) {
          languages.forEach(lang => {
            if (descriptionTemplatesClone[lang.languageCode]) {
              descriptionTemplatesClone[
                lang.languageCode
              ].name = descriptionTemplatesClone[
                lang.languageCode
              ].name.replace(
                '{$competitor1}',
                competitor1[lang.languageCode].name
              );
            }
          });
        } else if (
          descriptionTemplatesClone.en.name.includes('{$competitor2}')
        ) {
          languages.forEach(lang => {
            if (descriptionTemplatesClone[lang.languageCode]) {
              descriptionTemplatesClone[
                lang.languageCode
              ].name = descriptionTemplatesClone[
                lang.languageCode
              ].name.replace(
                '{$competitor2}',
                competitor2[lang.languageCode].name
              );
            }
          });
        }
      }
      return (
        <div className="form-label-group col-md" key={outcomeIndex}>
          <InputField
            type="number"
            step=".01"
            min="0"
            className="form-control efb-input"
            disabled={filters.markets[index].assigned}
            name="odds"
            onChange={e => handleInputChange(e, index, outcomeIndex)}
            placeholder="odd"
          />
          <label htmlFor={outcome + index}>
            {descriptionTemplatesClone.en
              ? descriptionTemplatesClone.en.name
              : ''}
          </label>
        </div>
      );
    },
    [
      filters.markets,
      handleInputChange,
      index,
      competitor1,
      competitor2,
      languages,
    ]
  );

  return (
    <div className="efb-form">
      <div className="form-row">
        {filters.markets[index].outcomes
          ? filters.markets[index].outcomes.map(showOutcomes)
          : null}
      </div>
    </div>
  );
};

Outcomes.propTypes = {
  filters: PropTypes.object,
  index: PropTypes.number,
  handleInputChange: PropTypes.func,
};

export default Outcomes;
