import axiosInstance from '../axios-instance';
import { actionCreator, createRequestTypes } from '../index';
import { addNotificationMessage } from '../ui';

export const SET_BETSCROLLER_ID = createRequestTypes('SET_BETSCROLLER_ID'),
  DELETE_BET_SCROLLER = createRequestTypes('DELETE_BET_SCROLLER'),
  GET_BET_SCROLLERS = createRequestTypes('GET_BET_SCROLLERS'),
  SET_BETSCROLLER_DATA = createRequestTypes('SET_BETSCROLLER_DATA'),
  SET_BET_SCROLLER_EVENTS = createRequestTypes('SET_BET_SCROLLER_EVENTS'),
  SET_BET_SCROLLER_SPORTS = createRequestTypes('SET_BET_SCROLLER_SPORTS'),
  SET_BET_SCROLLER_BUNITS = createRequestTypes('SET_BET_SCROLLER_BUNITS'),
  UPDATE_BET_SCROLLER = createRequestTypes('UPDATE_BET_SCROLLER'),
  GET_BET_SCROLLERS_EVENTS = createRequestTypes('GET_BET_SCROLLERS_EVENTS');

export const getBetScrollers = () => async dispatch => {
  dispatch(actionCreator.request(GET_BET_SCROLLERS));
  try {
    const res = await axiosInstance.get(
      'betslip/view/betscroller/admin?paged=false&sort.sorted=false&sort.unsorted=false&unpaged=true'
    );
    dispatch(actionCreator.success(GET_BET_SCROLLERS, res.content));
  } catch (error) {
    dispatch(actionCreator.failure(GET_BET_SCROLLERS, error));
  }
};

export const setBetscrollerId = betscrollerId => dispatch => {
  dispatch(actionCreator.success(SET_BETSCROLLER_ID, betscrollerId));
};

export const deleteBetScroller = bsId => async dispatch => {
  dispatch(actionCreator.request(DELETE_BET_SCROLLER));
  try {
    await axiosInstance.delete(`/betslip/view/betscroller/admin/${bsId}`);

    dispatch(actionCreator.success(DELETE_BET_SCROLLER));
    dispatch(getBetScrollers());
    dispatch(
      addNotificationMessage(
        'Bet scroller deleted successfully!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(actionCreator.failure(DELETE_BET_SCROLLER, error));
    dispatch(
      addNotificationMessage(
        'Something went wrong while deleting bet scroller',
        'error',
        'Error!'
      )
    );
  }
};

export const addWsBinding = (bsId, session) => {
  let data = {
    session: `user.${session}`,
    prefix: 'betscroller',
    keys: [bsId],
  };
  return dispatch => {
    axiosInstance
      .post('/websocket-gateway/routings', data)
      .then(response => {
        console.log('ws binding success: ', response);
      })
      .catch(error => {
        console.log('ws binding error: ', error);
      });
  };
};

export const setBetScrollerData = bsData => {
  return dispatch => {
    dispatch(actionCreator.success(SET_BETSCROLLER_DATA, bsData));
  };
};

// New Design

export const getBetScrollerUnits = () => async dispatch => {
  dispatch(actionCreator.request(SET_BET_SCROLLER_BUNITS));
  try {
    const res = await axiosInstance.get('/customer/view/admin/businessUnit');
    dispatch(actionCreator.success(SET_BET_SCROLLER_BUNITS, res));
  } catch (error) {
    dispatch(actionCreator.failure(SET_BET_SCROLLER_BUNITS, error));
  }
};

export const getBetScrollerSports = () => async dispatch => {
  dispatch(actionCreator.request(SET_BET_SCROLLER_SPORTS));
  try {
    const res = await axiosInstance.get(
      '/betslip/view/betscroller/admin/sports'
    );
    dispatch(actionCreator.success(SET_BET_SCROLLER_SPORTS, res));
  } catch (error) {
    dispatch(actionCreator.failure(SET_BET_SCROLLER_SPORTS, error));
  }
};

export const createBetScrollerProfile = bsFilter => async dispatch => {
  dispatch(actionCreator.request(SET_BETSCROLLER_ID));
  try {
    const res = await axiosInstance.post(
      '/betslip/view/betscroller/admin',
      bsFilter
    );
    dispatch(actionCreator.success(SET_BETSCROLLER_ID, res.id));
    dispatch(getBetScrollers());
    dispatch(
      addNotificationMessage(
        'Bet Scroller was successfully created!',
        'success',
        'Success'
      )
    );
  } catch (error) {
    dispatch(actionCreator.failure(SET_BETSCROLLER_ID, error));
    dispatch(
      addNotificationMessage(
        'Bet Scroller is not created! ERROR!',
        'error',
        'Error'
      )
    );
  }
};

export const updateBScrollerProfile = betScroller => async dispatch => {
  dispatch(actionCreator.request(UPDATE_BET_SCROLLER));
  try {
    const res = await axiosInstance.put(
      `/betslip/view/betscroller/admin/${betScroller.id}`,
      betScroller
    );
    dispatch(actionCreator.success(UPDATE_BET_SCROLLER, res));
    dispatch(actionCreator.success(SET_BETSCROLLER_ID, betScroller.id));
    dispatch(getBetScrollers());
    dispatch(
      addNotificationMessage(
        'Bet Scroller was successfully updated!',
        'success',
        'Success!'
      )
    );
  } catch (error) {
    dispatch(actionCreator.failure(UPDATE_BET_SCROLLER, error));
    dispatch(
      addNotificationMessage(
        'Bet Scroller is not updated! ERROR!',
        'error',
        'Error!'
      )
    );
  }
};

export const getBetScrollerEvents = () => async dispatch => {
  dispatch(actionCreator.request(GET_BET_SCROLLERS_EVENTS));
  try {
    const res = await axiosInstance.get(
      '/betslip/view/betscroller/admin/events/search/typeLike?type=MATCH'
    );
    dispatch(actionCreator.success(GET_BET_SCROLLERS_EVENTS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_BET_SCROLLERS_EVENTS, error));
  }
};
