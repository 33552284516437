import React from 'react';

export const Afilliate = (props) => {
    return (
		<span className='efb-icon'>
			<svg className={`efb-icon--${props.size} efb-afilliate`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
				<path d="M26.5,19c7.3,0,12.7-8.2,7.4-15.8c-0.3-0.4-0.6-0.7-1-1C25.2-3.2,17,2.2,17,9.5c0,1.3,0.3,2.5,0.7,3.7l-4.5,2.4
					c-2.3-3-6.7-4.2-11-1.2c-0.2,0.1-0.4,0.3-0.5,0.5c-4.3,6-0.1,12.5,5.6,12.5c2.3,0,4.5-1.1,5.9-2.9l8.1,4.6
					c-0.5,1.6-0.3,3.6,1.2,5.5l0,0c5.6,4.3,12-2.1,7.7-7.7c-3-2.3-6.1-1.6-7.9,0.4l-8.1-4.6c0.3-0.8,0.5-1.7,0.5-2.6
					c0-0.9-0.2-1.8-0.5-2.6l4.5-2.4C20.5,17.5,23.4,19,26.5,19z M26.5,16.9c-1.7,0-3.3-0.6-4.6-1.6c0.9-1.6,2.7-2.6,4.6-2.6
					s3.6,1,4.6,2.6C29.8,16.3,28.2,16.9,26.5,16.9z M24.4,8.4c0-1.2,0.9-2.1,2.1-2.1s2.1,0.9,2.1,2.1s-0.9,2.1-2.1,2.1
					S24.4,9.6,24.4,8.4z M31.6,3.9C31.8,4,32,4.2,32.1,4.4c2.4,3.4,2.1,6.9,0.4,9.3c-0.7-1.1-1.8-1.9-2.9-2.5c1.3-1.4,1.6-3.7-0.1-5.9
					c-3.5-2.7-7.3-0.2-7.3,3.1c0,1.1,0.4,2.1,1.1,2.8c-1.2,0.5-2.2,1.4-2.9,2.5c-0.8-1.2-1.3-2.6-1.3-4.2C19.1,3.8,25.6-0.4,31.6,3.9z
					M7.4,25.3c-1,0-1.9-0.3-2.6-0.7c0.6-0.9,1.6-1.4,2.6-1.4s2.1,0.5,2.6,1.4C9.2,25.1,8.3,25.3,7.4,25.3z M6.3,20
					c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1S8,21.1,7.4,21.1S6.3,20.6,6.3,20z M11.6,23.2c-0.4-0.6-1-1-1.6-1.4
					c0.7-1.1,0.8-2.6-0.3-4.1c-2.6-2-5.5-0.2-5.5,2.3c0,0.7,0.2,1.3,0.5,1.8c-0.6,0.4-1.2,0.8-1.6,1.4c-1.4-1.8-1.6-4.5,0.4-7
					c4.3-3.3,9.1-0.3,9.1,3.9C12.7,21.2,12.3,22.3,11.6,23.2z M24.1,28.4c3.4-2.6,7.2,1.3,4.6,4.6C25.3,35.6,21.5,31.8,24.1,28.4z"/>
			</svg>
			{props.children}
		</span>
    )
}

export default Afilliate;