import React, { Fragment, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as UiActions from '../../../redux/actions/ui';
import * as ReferralActions from '../../../redux/actions/riskManagement/ReferralConsole';
import ReferralModal from '../../../components/Modal/ReferralModal';
import SelectionsTable from './SelectionsTable';
import ModalBetslipForm from './ModalBetslipForm';
import { useUpdate, useFetch } from '../../../hooks';
import { orderedSystems } from './ReferralUtils';
import ModalAccountInfo from './ModalAccountInfo';

const DetailsModal = ({
  betslip,
  onClose,
  changedOdds,
  setBetStatus,
  reofferBet,
  priceChange,
  addNotificationMessage,
  setBetslipStatus,
  referralType,
  systemReoffer,
}) => {
  const [selections, setSelections] = useState([]);
  const [stakeChanges, setStakeChanges] = useState([]);
  const [oddChanges, setOddChanges] = useState({});
  const [priceChanges, setPriceChanges] = useState([]);
  const [currentSelection, setCurrentSelection] = useState('');
  const [currentSelectionIndex, setCurrentSelectionIndex] = useState('');
  const [currentSystem, setCurrentSystem] = useState('');
  const [systemTabs, setSystemTabs] = useState([{ type: '' }]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [orderedSystemBets, setOrderedSystemBets] = useState('');

  const isRegularBetslip = useMemo(() => {
    const isRegular = betslip.systemBets.reduce((acc, systemBet) => {
      return systemBet.type !== 'SINGLE' ? false : true;
    }, false);
    return isRegular;
  }, [betslip.systemBets]);

  useUpdate(() => {
    if (betslip.selections.length > 0) {
      const systemBetsClone = [...betslip.systemBets];

      if (isRegularBetslip) {
        setSelections(betslip.selections);
        setCurrentSelection(betslip.selections[0]);
        setCurrentSelectionIndex(0);
        betslip.selections[0]['activeEdit'] = true;
        setCurrentSystem(systemBetsClone ? systemBetsClone[0] : null);
      } else {
        const ordering = {};

        for (let i = 0; i < orderedSystems.length; i++)
          ordering[orderedSystems[i]] = i;
        const systemTypes = systemBetsClone.reduce(
          (acc, system) => [...acc, { type: system.type, active: false }],
          []
        );

        const betslipSelections = [...betslip.selections];

        systemTypes.sort((a, b) => {
          return ordering[a.type] - ordering[b.type];
        });

        systemBetsClone.sort((a, b) => {
          return ordering[a.type] - ordering[b.type];
        });

        const systemType = systemTypes[0].type;

        setOrderedSystemBets(systemBetsClone);
        setSelections(
          systemType !== 'SINGLE'
            ? betslip.selections
            : systemBetsClone[0].bets[0].selection
        );
        systemBetsClone[0].bets[0].selection[0]['activeEdit'] = true;
        betslipSelections[0]['activeEdit'] = true;
        setCurrentSelection(
          systemType !== 'SINGLE'
            ? betslipSelections[0]
            : systemBetsClone[0].bets[0].selection[0]
        );
        setCurrentSelectionIndex(0);
        setCurrentSystem(systemBetsClone ? systemBetsClone[0] : null);
        systemTypes[0].active = true;
        setSystemTabs(systemTypes);
      }
    }
  }, [betslip, isRegularBetslip]);

  const { data: customer } = useFetch(
    `customer/view/admin/${betslip.customerId}`
  );

  const prepareSelectionForEdit = useCallback(
    (currentSelection, index) => {
      const selectionsClone = [...selections];
      selectionsClone.map(selection => {
        selection['activeEdit'] = false;
        return selection;
      });
      if (!selectionsClone[index].editingObj) {
        selectionsClone[index]['editingObj'] = {
          time: 0,
          amount: 0,
        };
      }

      setCurrentSelectionIndex(index);

      selectionsClone[index]['activeEdit'] = true;
      setSelections(selectionsClone);
      setCurrentSelection(currentSelection);

      if (
        !currentSystem ||
        (betslip.systemBets && currentSystem.type === 'SINGLE')
      ) {
        setCurrentSystem(
          betslip.systemBets[index] ? betslip.systemBets[index] : null
        );
      }
    },
    [betslip.systemBets, currentSystem, selections]
  );

  const updateSelectionOdd = useCallback(
    (odd, currentSelection) => {
      const selectionsClone = [...selections];
      const systemBetsClone = [...betslip.systemBets];

      // changing odds in system
      systemBetsClone.forEach(bet => {
        const selectionToChange = bet.bets[0].selection.find(
          x =>
            x.selectionId === selectionsClone[currentSelectionIndex].selectionId
        );
        if (selectionToChange) {
          selectionToChange['currentOdd'] = Number(odd).toFixed(2);
        }
      });

      // Changing odds in selections
      if (selectionsClone[currentSelectionIndex]) {
        selectionsClone[currentSelectionIndex]['currentOdd'] = Number(
          odd
        ).toFixed(2);
        setSelections(selectionsClone);
      }

      if (!isRegularBetslip && systemTabs[currentTabIndex].type === 'SINGLE') {
        const betslipSelectionsClone = [...betslip.selections];
        const selectionIndex = betslipSelectionsClone.findIndex(
          x => x.selectionId === currentSelection.selectionId
        );
        betslipSelectionsClone[selectionIndex]['currentOdd'] = Number(
          odd
        ).toFixed(2);
        setSelections([currentSelection]);
      }
    },
    [
      selections,
      betslip.systemBets,
      betslip.selections,
      currentSelectionIndex,
      isRegularBetslip,
      systemTabs,
      currentTabIndex,
    ]
  );

  const updateAllSelectionsOdds = useCallback(
    oddAdjustment => {
      const selections = [...betslip.selections];
      const systemBetsClone = [...orderedSystemBets];
      const singleSystem = systemBetsClone.find(x => x.type === 'SINGLE');
      if (singleSystem) {
        singleSystem.bets[0].selection[0]['currentOdd'] =
          oddAdjustment + singleSystem.bets[0].selection[0].outcomeOdds;
      }
      selections.reduce((acc, selection) => {
        return (selection['currentOdd'] =
          oddAdjustment + selection.outcomeOdds);
      }, []);
      setSelections(selections);
    },
    [betslip, orderedSystemBets]
  );

  const closeModal = useCallback(() => {
    const selectionsClone = [...selections];
    selectionsClone.map(selection => {
      selection['activeEdit'] = false;
      return selection;
    });
    setSelections(selectionsClone);
    setStakeChanges([]);
    onClose();
  }, [selections, onClose]);

  const closeModalAndSetBetStatus = useCallback(
    (betslipId, currentSystem, status) => {
      closeModal();
      setBetStatus(betslipId, currentSystem, status);
    },
    [closeModal, setBetStatus]
  );

  const chooseSystem = useCallback(
    index => {
      const systemTabsClone = [...systemTabs];
      const systemBetsClone = [...orderedSystemBets];
      const selectionsClone = [...betslip.selections];
      systemTabsClone.forEach(tab => {
        tab.active = false;
      });
      systemTabsClone[index].active = true;
      setSystemTabs(systemTabsClone);
      setCurrentSelectionIndex(0);
      setCurrentTabIndex(index);

      if (systemTabs[index].type === 'SINGLE') {
        systemBetsClone[index].bets[0].selection.map(selection => {
          selection['activeEdit'] = false;
          return selection;
        });
        systemBetsClone[index].bets[0].selection[0].activeEdit = true;
      } else {
        selectionsClone.map(selection => {
          selection['activeEdit'] = false;
          return selection;
        });
        selectionsClone[0].activeEdit = true;
      }

      if (systemBetsClone) {
        setCurrentSelection(systemBetsClone[index].bets[0].selection[0]);
        setCurrentSystem(systemBetsClone[index]);
        setSelections(
          systemTabsClone[index].type !== 'SINGLE'
            ? selectionsClone
            : systemBetsClone[index].bets[0].selection
        );
      }
    },
    [betslip.selections, orderedSystemBets, systemTabs]
  );

  return (
    <Fragment>
      <ReferralModal
        isActive
        onClose={closeModal}
        firstName={customer ? customer.firstName : ''}
        lastName={customer ? customer.lastName : ''}
        gender={customer ? customer.gender : ''}
        customerName={betslip.customerName}
        customerId={betslip.customerId}
        channel={betslip.channel}
        country={customer ? customer.country.countryCode : 'bg'}
        headerText="Bet Referral Details"
        big
      >
        <ModalAccountInfo
          referralType={referralType}
          systemReoffer={systemReoffer}
          betslip={betslip}
        />
        {systemTabs.length > 1 ? (
          <ul className="systems-list">
            {betslip.systemBets.findIndex(x => x.type !== 'SINGLE') > -1
              ? systemTabs.map((system, index) => {
                  return (
                    <li
                      key={index}
                      className={system.active ? 'selected' : ''}
                      onClick={() => chooseSystem(index)}
                    >
                      {system.type}
                    </li>
                  );
                })
              : null}
          </ul>
        ) : null}
        <div className="referral-custom-table-wrapper selections-modal-tbody-wrapper">
          <SelectionsTable
            selections={selections}
            oddChanges={oddChanges}
            setOddChanges={setOddChanges}
            priceChanges={priceChanges}
            setPriceChanges={setPriceChanges}
            changedOdds={changedOdds}
            prepareSelectionForEdit={prepareSelectionForEdit}
          />
        </div>
        <ModalBetslipForm
          betslip={betslip}
          currentSelection={currentSelection}
          changedOdds={changedOdds}
          addNotificationMessage={addNotificationMessage}
          priceChange={priceChange}
          reofferBet={reofferBet}
          stakeChanges={stakeChanges}
          updateSelectionOdd={updateSelectionOdd}
          setBetslipStatus={setBetslipStatus}
          currentSystem={currentSystem}
          setStakeChanges={setStakeChanges}
          setBetStatus={closeModalAndSetBetStatus}
          referralType={referralType}
          isRegularBetslip={isRegularBetslip}
          closeModal={closeModal}
          currentTab={systemTabs[currentTabIndex]}
          updateAllSelectionsOdds={updateAllSelectionsOdds}
        />
      </ReferralModal>
    </Fragment>
  );
};

DetailsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  betslip: PropTypes.object.isRequired,
  changedOdds: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  addNotificationMessage: UiActions.addNotificationMessage,
  setBetStatus: ReferralActions.setBetStatus,
  priceChange: ReferralActions.priceChange,
  reofferBet: ReferralActions.reofferBet,
};

export default connect(null, mapDispatchToProps)(DetailsModal);
