import React, { useState, useCallback } from 'react';
import useMount from '../../../../hooks/useMount';
import Switch from '../../../../components/Form/Switch/Switch';
import { useUpdate, useStateForSwitch } from '../../../../hooks';
import InputField from '../../../../components/Form/InputField';
import Button from '../../../../components/Layout/Button';
import PropTypes from 'prop-types';

const AutomaticWithdraw = ({
  getAWStatus,
  awStatus,
  changeAwGlobalStatus,
  getAllCurrencies,
  usedCurrencies,
  getAwStatusByCurrency,
  saveAwCurrencyLimit,
  currencyLimit,
}) => {
  const [awStatusActive, setAwStatusActive] = useStateForSwitch('');
  const [currencyArr, setCurrencyArr] = useState([]);

  useMount(() => {
    getAWStatus();
    getAllCurrencies();
    getAwStatusByCurrency();
  });

  useUpdate(() => {
    setCurrencyArr(currencyLimit);
  }, [usedCurrencies, currencyLimit]);

  useUpdate(() => {
    // checking explicitly if awStatusActive is not empty string, not true or false (same on line 78).
    // in this way we prevent unneccessary API call on inital load.
    if (awStatusActive !== '') changeAwGlobalStatus(awStatusActive);
  }, [awStatusActive]);

  const prepareCurrencyLimit = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      const currencyArrClone = [...currencyArr];
      if (currencyArrClone[index]) {
        const currencyIndex = currencyArrClone.findIndex(
          x => x.currencyCode === name
        );
        currencyArrClone[currencyIndex].autoWithdrawLimit = value * 100;
      } else {
        currencyArrClone.push({
          autoWithdrawLimit: value * 100,
          currencyCode: name,
        });
      }
      setCurrencyArr(currencyArrClone);
    },
    [currencyArr]
  );

  return (
    <div>
      <h3>
        Automatic Withdraw global status{' '}
        <span
          className={`bool-info-circle inline-block ${
            awStatus ? 'true' : 'false'
          }`}
        />
      </h3>
      <Switch
        isChecked={awStatusActive || awStatus}
        value={awStatus}
        onChange={setAwStatusActive}
      />
      <br />
      <br />
      <div className="efb-form">
        <div className="form-row">
          {usedCurrencies.map((currency, index) => {
            const currencyValue =
              currencyArr.length > 0
                ? currencyArr.find(x => x.currencyCode === currency.code)
                : null;
            return (
              <div className="form-label-group col-md" key={currency.code}>
                <InputField
                  type="number"
                  name={currency.code}
                  label={currency.code}
                  disabled={!awStatus}
                  min="0"
                  value={
                    currencyValue ? currencyValue.autoWithdrawLimit / 100 : ''
                  }
                  onChange={e => prepareCurrencyLimit(e, index)}
                />
                <br />
                <Button
                  disabled={!currencyValue || !awStatus}
                  cssClass="btn btn-primary"
                  onClick={() => saveAwCurrencyLimit(currencyValue)}
                >
                  <i className="fa fa-check-square" /> Save {currency.code}{' '}
                  limit
                </Button>
              </div>
            );
          })}
        </div>
      </div>
      <br />
    </div>
  );
};

AutomaticWithdraw.propTypes = {
  getAWStatus: PropTypes.func,
  changeAwGlobalStatus: PropTypes.func,
  getAllCurrencies: PropTypes.func,
  usedCurrencies: PropTypes.array,
  getAwStatusByCurrency: PropTypes.func,
  saveAwCurrencyLimit: PropTypes.func,
};

export default AutomaticWithdraw;
