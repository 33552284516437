import React, { useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as CustomerDataActions from '../../../../redux/actions/customer/customerData';
import classes from './MessageHistory.module.scss';
import Modal from '../../../../components/NewModal/Modal';
import Input from '../../../../components/Form/Input';
import Buttons from '../../../../components/ModalButtons/Buttons';
import CalendarDayPicker from '../../../../components/CalendarDayPicker/CalendarDayPicker';
import CheckboxDiv from '../../../../components/Form/CheckboxDiv/CheckboxDiv';
import Table from '../../../../components/NewTable/Table';

import { IconSearch } from '../../../../components/Icons/UI/ui/IconSearch';
import { IconCalendar } from '../../../../components/Icons/UI/ui/IconCalendar';
import { useUpdate, useFetch, useStateForSwitch } from '../../../../hooks';

const MessageHistory = ({
  isActive,
  onClose,
  customer,
  updateCustomerMessageStatus,
}) => {
  const [openedToCalendar, setOpenedToCalendar] = useState(false);
  const [openedFromCalendar, setOpenedFromCalendar] = useState(false);
  const [from, setFrom] = useState(Date.now());
  const [to, setTo] = useState(Date.now());
  const [fromDateActive, setFromDateActive] = useState(false);
  const [toDateActive, setToDateActive] = useState(false);
  const [seen, setSeen] = useStateForSwitch(false);
  const [deleted, setDeleted] = useStateForSwitch(false);
  const [pageNum, setPageNum] = useState(0);
  const [paginationList, setPaginationList] = useState([]);
  const [initialPagination, setInitialPagination] = useState(true);

  const { data: messages, refetch: fetchMessages } = useFetch(
    customer
      ? `/message/service/admin/messages/search/findByReceiverUserId?&page=${pageNum}&size=25&userId=${customer.user.id}`
      : null
  );

  useUpdate(() => {
    fetchMessages();
    if (pageNum > 0) setInitialPagination(false);
    if (pagination.length - pageNum > 2) {
      const sliced = pagination.slice(pageNum);
      setPaginationList(sliced);
    }
  }, [pageNum]);

  useUpdate(() => {
    if (seen) setDeleted(false);
  }, [seen]);

  useUpdate(() => {
    if (deleted) setSeen(false);
  }, [deleted]);

  useUpdate(() => {
    if (!fromDateActive) setFrom(Date.now());
    if (!toDateActive) setTo(Date.now());
  }, [fromDateActive, toDateActive]);

  useUpdate(() => {
    if (messages && messages.content.length > 0 && initialPagination) {
      let arr = [];
      for (let i = 0; i < messages.totalPages; i++) {
        arr = [...arr, i];
      }
      setPaginationList(arr.slice(0, 3));
    }
  }, [messages]);

  const handleDayClick = useCallback((day, direction) => {
    if (direction === 'from') {
      setFrom(day);
      setOpenedFromCalendar(false);
    } else {
      setTo(day);
      setOpenedToCalendar(false);
    }
  }, []);

  const checkIsDateTodayOrYesterday = useCallback(date => {
    if (
      moment(date).format('DD.MM.YYYY') ===
      moment(Date.now()).format('DD.MM.YYYY')
    ) {
      return 'Today';
    } else if (
      moment(Date.now()).format('DD') - moment(date).format('DD') ===
      1
    ) {
      return 'Yesterday';
    }
    return moment(date).format('DD.MM.YYYY');
  }, []);

  const changeStatus = useCallback(
    (message, status) => {
      if (message.status !== status && status === 'READ') {
        updateCustomerMessageStatus(message.id, 'READ');
        fetchMessages();
      }

      if (message.status !== status && status === 'DELETED') {
        updateCustomerMessageStatus(message.id, 'DELETED');
        fetchMessages();
      }
    },
    [fetchMessages, updateCustomerMessageStatus]
  );

  const columns = useMemo(
    () => [
      {
        name: 'Date',
        display: message =>
          moment.unix(message.createdDate).format('DD/MM/YYYY HH:mm'),
      },
      {
        name: 'Seen',
        display: message => (
          <span
            onClick={() => changeStatus(message, 'READ')}
            className={
              message.status === 'READ' ? classes.active : classes.inactive
            }
          />
        ),
      },
      {
        name: 'Deleted',
        display: message => (
          <span
            onClick={() => changeStatus(message, 'DELETED')}
            className={
              message.status === 'DELETED' ? classes.active : classes.inactive
            }
          />
        ),
      },
      { name: 'Subject', display: message => message.subject },
      { name: 'Message', display: message => message.body },
    ],
    [changeStatus]
  );

  const pagination = useMemo(() => {
    let arr = [];
    if (messages) {
      for (let i = 0; i < messages.totalPages; i++) {
        arr = [...arr, i];
      }
      return arr;
    }
    return [];
  }, [messages]);

  const incrementPage = useCallback(() => {
    if (messages.pageable.pageNumber !== pagination.length - 1) {
      setPageNum(pageNum + 1);
    }
  }, [messages, pageNum, pagination.length]);

  const decrementPage = useCallback(() => {
    if (messages.pageable.pageNumber !== 0) {
      setPageNum(pageNum - 1);
    }
  }, [messages, pageNum]);

  return (
    <Modal className={'px-3 py-2'} isActive={isActive} onClose={onClose}>
      <div className={classes.header}>
        <div>
          <p>Message History</p>
        </div>
        <div>
          <p className={classes.name}>{customer.firstLastName}</p>
          <p className={classes.id}>[{customer.publicId}]</p>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.divider}>
          <div className="position-relative">
            <IconSearch className={classes.inputIcon} width={15} />
            <Input
              customInput
              placeholder="Search"
              className={`${classes.missing} ${classes.searchInput}`}
            />
          </div>
          <div>
            <h1>Date</h1>
            <div className={classes.dateWrapper}>
              <div>
                <p className={classes.missing}>From:</p>
              </div>
              <div className={classes.dateBox}>
                <CheckboxDiv
                  isChecked={fromDateActive}
                  onChange={() => setFromDateActive(!fromDateActive)}
                  value={fromDateActive}
                  name={'fromDate'}
                  id={'fromDate'}
                />
                {openedFromCalendar && fromDateActive ? (
                  <CalendarDayPicker
                    handleDayClick={e => handleDayClick(e, 'from')}
                    from={from}
                  />
                ) : null}
                <div
                  onClick={() => {
                    setOpenedFromCalendar(
                      fromDateActive ? !openedFromCalendar : ''
                    );
                  }}
                >
                  <p
                    className={classes.pickedTime}
                    style={{
                      color: !fromDateActive ? 'rgba(112, 112, 112, 0.5)' : '',
                    }}
                  >
                    {checkIsDateTodayOrYesterday(from)}
                  </p>
                  <IconCalendar width={15} />
                </div>
              </div>
            </div>
            <div className={classes.dateWrapper}>
              <div>
                <p className={classes.missing}>To:</p>
              </div>
              <div className={classes.dateBox}>
                <CheckboxDiv
                  isChecked={toDateActive}
                  onChange={() => setToDateActive(!toDateActive)}
                  value={toDateActive}
                  name={'toDate'}
                  id={'toDate'}
                />
                {openedToCalendar && toDateActive ? (
                  <CalendarDayPicker
                    handleDayClick={e => handleDayClick(e, 'to')}
                    to={to}
                  />
                ) : null}
                <div
                  onClick={() => {
                    setOpenedToCalendar(toDateActive ? !openedToCalendar : '');
                  }}
                >
                  <p
                    className={classes.pickedTime}
                    style={{
                      color: !toDateActive ? 'rgba(112, 112, 112, 0.5)' : '',
                    }}
                  >
                    {checkIsDateTodayOrYesterday(to)}
                  </p>
                  <IconCalendar width={15} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h1>Status</h1>
            <div className={classes.checkboxWrapper}>
              <div>
                <CheckboxDiv
                  isChecked={seen}
                  onChange={() => setSeen(!seen)}
                  value={seen}
                  name={'seen'}
                  id={'seen'}
                />
                <p className={`${classes.missing}`}>Seen</p>
              </div>
              <div>
                <CheckboxDiv
                  isChecked={deleted}
                  onChange={() => setDeleted(!deleted)}
                  value={deleted}
                  name={'deleted'}
                  id={'deleted'}
                />
                <p className={`${classes.missing}`}>Deleted</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Table
            columns={columns}
            data={messages ? messages.content : []}
            pagination={false}
          />
          <p className={classes.pageCounter}>
            Page {messages ? messages.pageable.pageNumber + 1 : '1'} of{' '}
            {messages ? messages.totalPages : '1'}
          </p>

          <ul className={`${classes.pagination} no-text-selection`}>
            <li
              onClick={() => {
                setPageNum(0);
                setPaginationList(pagination.slice(0));
              }}
            >
              <span className={classes.paginationNav}> {'<<'} </span>
            </li>
            <li
              className={
                messages
                  ? messages.pageable.pageNumber === 0
                    ? classes.disabled
                    : ''
                  : ''
              }
            >
              <span onClick={decrementPage} className={classes.paginationNav}>
                {' '}
                {'<'}
              </span>
            </li>
            {paginationList
              ? paginationList.slice(0, 3).map(page => {
                  return (
                    <li
                      className={
                        page === messages.pageable.pageNumber
                          ? classes.active
                          : ''
                      }
                      onClick={() => setPageNum(page)}
                      key={page}
                    >
                      {page + 1}
                    </li>
                  );
                })
              : null}
            <li
              className={
                messages && paginationList
                  ? messages.pageable.pageNumber === paginationList.length - 1
                    ? classes.disabled
                    : ''
                  : ''
              }
            >
              <span
                onClick={incrementPage}
                className={`${classes.paginationNav}`}
              >
                {'>'}
              </span>
              <li
                onClick={() => {
                  setPageNum(pagination.length - 1);
                  setPaginationList(pagination.slice(pagination.length - 3));
                }}
              >
                <span className={classes.paginationNav}>{'>>'}</span>
              </li>
            </li>
          </ul>

          <div className="pt-4">
            <Buttons cancel={onClose} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  updateCustomerMessageStatus: CustomerDataActions.updateCustomerMessageStatus,
};

export default connect(null, mapDispatchToProps)(MessageHistory);
