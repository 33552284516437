import moment from 'moment';

import axiosInstance from '../axios-instance';
import { actionCreator, createRequestTypes } from '../index';
import { addNotificationMessage } from '../ui';

export const STS_GET_ALL_SPORTS = createRequestTypes('STS_GET_ALL_SPORTS'),
  STS_GET_TOURNAMENTS = createRequestTypes('STS_GET_TOURNAMENTS'),
  STS_GET_SEASONS = createRequestTypes('STS_GET_SEASONS'),
  STS_GET_SPORT_CATEGORIES = createRequestTypes('STS_GET_SPORT_CATEGORIES');

export const UPDATE_SPORT = createRequestTypes('UPDATE_SPORT');
export const CREATE_SPORT = createRequestTypes('CREATE_SPORT');
export const UPDATE_CATEGORY = createRequestTypes('UPDATE_CATEGORY');
export const CREATE_CATEGORY = createRequestTypes('CREATE_CATEGORY');

export const createSport = data => async dispatch => {
  dispatch(actionCreator.request(CREATE_SPORT));
  try {
    await axiosInstance.post('/sport/event/view/sport/admin/', {
      ...data,
      feedProducers: [
        { feedProviderId: 'efb:sport:' + moment().valueOf(), producerId: '14' },
      ],
    });
    dispatch(actionCreator.success(CREATE_SPORT));
    dispatch(getAllSports());
    dispatch(
      addNotificationMessage(
        'You have successfully created sport',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(actionCreator.failure(CREATE_SPORT));
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const updateSport = data => async dispatch => {
  dispatch(actionCreator.request(UPDATE_SPORT));
  try {
    await axiosInstance.post(`/sport/event/view/sport/admin/${data.id}`, data);
    dispatch(getAllSports());
    dispatch(actionCreator.success(UPDATE_SPORT));
    dispatch(
      addNotificationMessage(
        'You have successfully updated sport',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(actionCreator.failure(UPDATE_SPORT));
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const getAllSports = () => async dispatch => {
  try {
    const res = await axiosInstance.get('/sport/event/view/sport/admin');
    dispatch(actionCreator.success(STS_GET_ALL_SPORTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(STS_GET_ALL_SPORTS, ''));
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const updateSportStatus = sport => async dispatch => {
  try {
    await axiosInstance.post(
      `/sport/event/view/sport/admin/${sport.id}`,
      sport
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const updateRank = list => async dispatch => {
  try {
    await axiosInstance.post('/sport/event/view/ranking/admin', list);
    dispatch(
      addNotificationMessage(
        'Successfully updated rank!',
        'success',
        'Success!'
      )
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const getSportCategories = sportId => async dispatch => {
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/category/admin?sportId=${sportId}`
    );
    dispatch(actionCreator.success(STS_GET_SPORT_CATEGORIES, res));
  } catch (err) {
    dispatch(actionCreator.failure(STS_GET_SPORT_CATEGORIES, ''));
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const createTournament = tournament => async dispatch => {
  try {
    await axiosInstance.post('/sport/event/view/tournament/admin/', tournament);
    dispatch(
      addNotificationMessage(
        'You have successfully created tournament',
        'success',
        'Success'
      )
    );
    dispatch(getTournaments(tournament.categoryId));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const updateTournament = tournament => async dispatch => {
  try {
    await axiosInstance.post(
      `/sport/event/view/tournament/admin/${tournament.id}`,
      tournament
    );
    dispatch(
      addNotificationMessage(
        'You have successfully updated tournament',
        'success',
        'Success'
      )
    );
    dispatch(getTournaments(tournament.categoryId));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const getTournaments = categoryId => async dispatch => {
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/tournament/admin?categoryId=${categoryId}`
    );
    dispatch(actionCreator.success(STS_GET_TOURNAMENTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(STS_GET_TOURNAMENTS, ''));
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const updateTournamentStatus = tournament => async dispatch => {
  tournament.type = 'tournament';
  try {
    await axiosInstance.post(
      `/sport/event/view/tournament/admin/${tournament.id}`,
      tournament
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const createSeason = season => async dispatch => {
  try {
    await axiosInstance.post('/sport/event/view/season/admin/', season);
    dispatch(
      addNotificationMessage(
        'You have successfully created season',
        'success',
        'Success'
      )
    );
    dispatch(getSeasons(season.categoryId));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error!'));
  }
};

export const updateSeason = season => async dispatch => {
  try {
    await axiosInstance.post(
      `/sport/event/view/season/admin/${season.id}`,
      season
    );
    dispatch(
      addNotificationMessage(
        'You have successfully updated season',
        'success',
        'Success'
      )
    );
    dispatch(getSeasons(season.categoryId));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error!'));
  }
};

export const getSeasons = categoryId => async dispatch => {
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/season/admin?categoryId=${categoryId}`
    );
    dispatch(actionCreator.success(STS_GET_SEASONS, res));
  } catch (err) {
    dispatch(actionCreator.failure(STS_GET_SEASONS, ''));
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const updateSeasonStatus = season => async dispatch => {
  try {
    await axiosInstance.get(`/sport/event/view/season/admin/${season.id}`);
    dispatch(proceedToUpateSeasonStatus(season));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const proceedToUpateSeasonStatus = season => async dispatch => {
  try {
    await axiosInstance.post(
      `/sport/event/view/season/admin/${season.id}`,
      season
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const createCategory = (category, sportId) => async dispatch => {
  dispatch(actionCreator.request(CREATE_CATEGORY));
  try {
    await axiosInstance.post(
      `/sport/event/view/category/admin/${sportId}`,
      category
    );
    dispatch(actionCreator.success(CREATE_CATEGORY));
    dispatch(getSportCategories(sportId));
    dispatch(
      addNotificationMessage(
        'You have successfully added sport category',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(actionCreator.failure(CREATE_CATEGORY));
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};

export const updateCategory = (category, sportId) => async dispatch => {
  dispatch(actionCreator.request(UPDATE_CATEGORY));
  try {
    await axiosInstance.post(
      `/sport/event/view/category/admin/${sportId}/${category.id}`,
      category
    );
    dispatch(actionCreator.success(UPDATE_CATEGORY));
    dispatch(getSportCategories(sportId));
    dispatch(
      addNotificationMessage(
        'You have successfully updated sport category',
        'success',
        'Success'
      )
    );
  } catch (err) {
    dispatch(actionCreator.failure(UPDATE_CATEGORY));
    dispatch(addNotificationMessage(err.message, 'error', err.error));
  }
};
