import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  useStateForSelect,
  useStateForSwitch,
  useFetch,
} from '../../../../hooks';

import * as CustomerActions from '../../../../redux/actions/customer/customerManager';

import BusinessUnitSelector from '../../../../components/BusinessUnitSelector/BusinessUnitSelector';
import Button from '../../../../components/Layout/Button';
import Switch from '../../../../components/Form/Switch/Switch';
import moment from 'moment';

const SelfExclusionPerBU = ({ createConfigRestrictionPerBsUnit }) => {
  const [businessUnit, setBusinessUnit] = useStateForSelect('');
  const [enabled, setEnabled, resetEnabled] = useStateForSwitch(false);

  const { data: businessUnitconfig, refetch } = useFetch(
    '/customer/view/admin/section/restrictions/config'
  );

  const config = useMemo(() => {
    if (businessUnit) {
      const config = businessUnitconfig.find(
        conf => conf.businessUnit === businessUnit
      );
      if (config) {
        resetEnabled(config.active);
      }
      return config;
    }
    // eslint-disable-next-line
  }, [businessUnit, businessUnitconfig]);

  const createConfig = useCallback(async () => {
    if (businessUnit) {
      await createConfigRestrictionPerBsUnit({
        id: config ? config.id : null,
        businessUnit,
        active: enabled,
      });
      await refetch();
    }
  }, [
    businessUnit,
    config,
    createConfigRestrictionPerBsUnit,
    enabled,
    refetch,
  ]);

  return (
    <div className="p-5">
      <div className="form-row justify-content-center align-items-center p-3">
        <div className="col-8">
          <BusinessUnitSelector
            onChange={setBusinessUnit}
            placeholder={'Select Business Unit'}
            defaultBu={' '}
          />
        </div>
        <div className="d-flex justify-content-around pl-4">
          <Button
            cssClass="efb-btn__main"
            onClick={createConfig}
            disabled={businessUnit ? false : true}
          >
            {config ? 'Update' : 'Create'} config
          </Button>
        </div>
      </div>
      {businessUnit ? (
        <>
          <hr />
          {config ? (
            <>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr 1fr',
                  justifyItems: 'center',
                }}
              >
                <h5>Business Unit</h5>
                <h5>Created</h5>
                <h5>Updated</h5>
                <h5>Disable/Enable</h5>
                <h3 className="efb-h3">{businessUnit}</h3>
                <div>
                  {moment(config.createdOn).format('DD/MM/YYYY HH:mm:ss')}
                </div>
                <div>
                  {moment(config.updatedOn).format('DD/MM/YYYY HH:mm:ss')}
                </div>
                <div>
                  <Switch
                    name={businessUnit}
                    isChecked={enabled}
                    onChange={setEnabled}
                  />
                </div>
              </div>
              <hr />
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = {
  createConfigRestrictionPerBsUnit:
    CustomerActions.createConfigRestrictionPerBsUnit,
};

export default connect(null, mapDispatchToProps)(SelfExclusionPerBU);
