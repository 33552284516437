import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { DBCore as DBCoreIcon } from '../../components/Icons/Dashbord/DBCore';
import { CustomerSer as PlayerManagementIcon } from '../../components/Icons/Dashbord/CustomerSer';
import { CallCenter as CallCenterIcon } from '../../components/Icons/Dashbord/CallCenter';
import { BackOffice as BackOfficeIcon } from '../../components/Icons/Dashbord/BackOffice';
import { ContentMng as ContentMngIcon } from '../../components/Icons/Dashbord/ContentMng';
import { AdHock as AdHockIcon } from '../../components/Icons/Dashbord/AdHock';
import { BetToView as BetToViewIcon } from '../../components/Icons/Dashbord/BetToView';
import { DataWarehouse as DataWarehouseIcon } from '../../components/Icons/Dashbord/DataWarehouse';
import { CustomerSer as CustomerSerIcon } from '../../components/Icons/Dashbord/CustomerSer';
import { RiskMng as RiskMngIcon } from '../../components/Icons/Dashbord/RiskMng';
import { Affiliate as AffiliateIcon } from '../../components/Icons/Dashbord/Affiliate';

const DashbordItem = props => {
  const parent =
    props.match.params.moduleId &&
    props.items.find(({ url }) => url === props.match.params.moduleId);

  return parent ? (
    <DashbordItem items={parent.children} match={props.match} />
  ) : (
    props.items.map((item, i) => {
      let icon;
      switch (item.name) {
        case 'User Management':
          icon = (
            <DBCoreIcon size="xlg">
              <span className="efb-icon__text">{item.name}</span>
            </DBCoreIcon>
          );
          break;
        case 'Player Management':
        case 'Player Profile':
          icon = (
            <PlayerManagementIcon size="xlg">
              <span className="efb-icon__text">{item.name}</span>
            </PlayerManagementIcon>
          );
          break;
        case 'Call Center':
          icon = (
            <CallCenterIcon size="xlg">
              <span className="efb-icon__text">{item.name}</span>
            </CallCenterIcon>
          );
          break;
        case 'Back Office':
          icon = (
            <BackOfficeIcon size="xlg">
              <span className="efb-icon__text">{item.name}</span>
            </BackOfficeIcon>
          );
          break;
        case 'Content Management':
          icon = (
            <ContentMngIcon size="xlg">
              <span className="efb-icon__text">{item.name}</span>
            </ContentMngIcon>
          );
          break;
        case 'Ad-Hock':
          icon = (
            <AdHockIcon size="xlg">
              <span className="efb-icon__text">{item.name}</span>
            </AdHockIcon>
          );
          break;
        case 'Data Warehouse':
          icon = (
            <DataWarehouseIcon size="xlg">
              <span className="efb-icon__text">{item.name}</span>
            </DataWarehouseIcon>
          );
          break;
        case 'Bet to View Mapper':
          icon = (
            <BetToViewIcon size="xlg">
              <span className="efb-icon__text">{item.name}</span>
            </BetToViewIcon>
          );
          break;
        case 'Customer Service':
          icon = (
            <CustomerSerIcon size="xlg">
              <span className="efb-icon__text">{item.name}</span>
            </CustomerSerIcon>
          );
          break;
        case 'Risk Management':
          icon = (
            <RiskMngIcon size="xlg">
              <span className="efb-icon__text">{item.name}</span>
            </RiskMngIcon>
          );
          break;
        case 'Afiliate':
          icon = (
            <AffiliateIcon size="xlg">
              <span className="efb-icon__text">{item.name}</span>
            </AffiliateIcon>
          );
          break;
        default:
          icon = (
            <DBCoreIcon size="xlg">
              <span className="efb-icon__text">{item.name}</span>
            </DBCoreIcon>
          );
          break;
      }
      return (
        <Link
          className="efb-btn__dashboard"
          key={i}
          to={props.match.url + '/' + item.url}
        >
          {icon}
        </Link>
      );
    })
  );
};

class Dashbord extends Component {
  render() {
    return (
      <div className="efb-dashboard">
        {this.props.items ? (
          <DashbordItem {...this.props} />
        ) : (
          <div> This content is not available </div>
        )}
      </div>
    );
  }
}

export default withRouter(Dashbord);
