//Selectors are used to get data from Redux Store.
//Benefits: If we need to change the structure for the Redux Store
//we need to change it on one place not in every connect()
export const getAuthData = state => state.auth || {};
export const getUsersData = state => state.users || {};
export const getUiComponentsReducer = state => state.uiComponentsReducer || {};
export const getCustomerData = state => state.customerReducer || [];
export const getRolesData = state => state.rolesReducer.roles || [];
export const getApplications = state => state.rolesReducer.applications || [];
export const getNotification = state => state.notificationReducer || {};
export const getErrorMessage = actions => state => {
  const errors = actions.map(action => state.errorReducer[action]);
  return errors && errors[0] ? errors[0] : '';
};
export const createLoadingSelector = actions => state =>
  actions.some(action => state.loadingReducer[action]);

export const getLanguages = state => state.languagesReducer || {};

// Sport Events
export const getEventsData = state => state.events || [];
// CMS
export const getCMSData = state => state.cmsReducer || [];

export const getTopManager = state => getCMSData(state).topManagerReducer || {};

// Images
export const getImagesData = state => state.images || [];

// Translations
export const getTranslationsData = state => state.translations || [];
export const getRiskManagementData = state => state.riskManagementReducer || {};
export const getWsData = state => state.wsReducer || {};

// Event Search
export const getEventSearchData = state =>
  getCMSData(state).eventSearchReducer || {};
// Event Manager
export const getEventsManagerData = state =>
  getCMSData(state).eventsManagerReducer || {};
// Event Builder
export const getEventBuilderData = state =>
  getCMSData(state).eventBuilderReducer || [];

// STS Builder
export const getStsBuilderData = state =>
  getCMSData(state).stsBuilderReducer || {};

// Bir Delay Reducer
export const getBirDelayData = state => state.birDelayReducer || [];

// Transactions
export const getTransactionData = state => state.transactionReducer || [];

// Bonus system
export const getBonusSystemData = state => state.bonusSystemReducer || [];

// Customer validations
export const getCustomerValidationsData = state =>
  state.CustomerValidationsReducer || [];

// Message Templates
export const getMessageTemplatesData = state =>
  state.messageTemplatesReducer || [];

// Risk Management Configuration
export const getRiskConfigurationData = state =>
  getRiskManagementData(state).configurationReducer || {};

export const getCashoutManagerData = state => state.cashoutManagerReducer || [];

export const getRiskCategoryData = state =>
  getRiskManagementData(state).riskCategoryReducer || {};

export const getReferralConsoleData = state =>
  getRiskManagementData(state).referralConsoleReducer || {};

export const getUserSettingsData = state =>
  getRiskManagementData(state).userSettingsReducer || {};

export const getLiveStreamingData = state => state.liveStreamingReducer || {};

export const getCasinoData = state =>
  getCustomerData(state).casinoReducer || {};

export const getMarketCategoriesData = state =>
  getCMSData(state).marketCategoriesReducer;

export const getBetHistoryData = state =>
  getRiskManagementData(state).betHistoryReducer || {};
