import React, { Fragment, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import Button from '../../../../components/Layout/Button';
import InputField from '../../../../components/Form/InputField';

import { selectStyles } from '../../../../containers/CMS/customReactSelectStyle';
import { useStateForInput, useStateForSelect } from '../../../../hooks';
import { channelsOptions, selectOptions } from '../transactionsUtils';

const PendingTransactionsSelector = ({
  paymentMethods,
  getPendingTransaction,
  emptyPendingTransaction,
}) => {
  const [
    customerUsername,
    setCustomerUsername,
    resetUserName,
  ] = useStateForInput('');
  const [paymentMethod, setPaymentMethod] = useStateForSelect('');
  const [channel, setChannel] = useStateForSelect('');

  const preparePaymentsList = useMemo(() => selectOptions(paymentMethods), [
    paymentMethods,
  ]);

  const clearForm = useCallback(() => {
    resetUserName();
    setPaymentMethod('');
    setChannel('');
    emptyPendingTransaction();
  }, [emptyPendingTransaction, resetUserName, setChannel, setPaymentMethod]);

  return (
    <Fragment>
      <h2>Pending Withdraws</h2>
      <br />
      <div className="efb-form">
        <div className="form-row">
          <div className="form-label-group col-md">
            <InputField
              type="text"
              name="customerUsername"
              placeholder="Customer username"
              label="Customer username"
              onChange={setCustomerUsername}
              value={customerUsername}
            />
          </div>
          <div className="form-label-group col-md">
            <Select
              options={preparePaymentsList}
              onChange={setPaymentMethod}
              placeholder="Select payment method"
              styles={selectStyles}
              name="paymentMethod"
              label="Select payment method"
              value={paymentMethod}
            />
          </div>
          <div className="form-label-group col">
            <Select
              options={channelsOptions}
              onChange={setChannel}
              placeholder="Select channel"
              styles={selectStyles}
              name="channel"
              label="Select channel"
              value={channel}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="form-row">
        <Button onClick={clearForm} cssClass="efb-btn__main col-md">
          Clear
        </Button>
        <Button
          onClick={() =>
            getPendingTransaction({
              pageNumber: 1,
              pageSize: 100000000,
              paymentMethod: paymentMethod.value || '',
              channel: channel.value || '',
              ...(customerUsername ? { customerUsername } : {}),
            })
          }
          cssClass="efb-btn__main col-md"
        >
          Show result
        </Button>
      </div>
      <hr />
    </Fragment>
  );
};

PendingTransactionsSelector.propTypes = {
  getPendingTransaction: PropTypes.func,
  emptyPendingTransaction: PropTypes.func,
  paymentMethods: PropTypes.array,
};

export default PendingTransactionsSelector;
