import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { columnsForValidationTable } from './Utils';

import FullScreenImg from '../../../components/Layout/FullScreenImg';
import PopUpImg from '../../../components/Layout/PopUpImg';
import Button from '../../../components/Layout/Button';
import Modal from '../../../components/Modal/Modal';
import Table from '../../../components/Table/Table';
import DatePicker from 'react-datepicker';

const PendingValidations = ({
  pendingUsers,
  approve,
  reject,
  addNotificationMessage,
}) => {
  const [custumerForReview, setCustumerForReview] = useState([]);
  const [activeModal, setActiveModal] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  const [isPopUpImg, setPopUpImg] = useState(false);
  const [expiryDate, setExpiryDate] = useState('');

  const viewFullSize = useCallback(validation => {
    setCustumerForReview(validation);
    setActiveModal(true);
  }, []);

  const approveOrReject = useCallback(
    async (pendingAction, isApprove) => {
      if (!expiryDate && isApprove) {
        addNotificationMessage(
          'Expiry date can not be empty!',
          'error',
          'Error'
        );
        return;
      }
      const { customerId, id } = custumerForReview;
      await pendingAction({
        customerId,
        documentId: id,
        validityDate: expiryDate,
      });
      await setActiveModal(false);
      setExpiryDate('');
    },
    [addNotificationMessage, custumerForReview, expiryDate]
  );

  const closeFullScreen = useCallback(() => {
    setFullScreen(false);
    setPopUpImg(false);
    setActiveModal(true);
  }, []);

  const openFullScreen = useCallback(() => {
    setFullScreen(true);
    setActiveModal(false);
  }, []);

  const openPopUpImg = useCallback(() => {
    setPopUpImg(true);
    setActiveModal(false);
  }, []);

  const closeModal = useCallback(() => {
    setActiveModal(false);
    setExpiryDate('');
  }, []);

  return (
    <Fragment>
      {activeModal ? (
        <Modal isActive={activeModal} onClose={closeModal}>
          <div className="d-flex justify-content-center pt-3">
            {custumerForReview.url ? (
              <div className="customerImage position-relative">
                <img
                  src={custumerForReview.url}
                  alt="imagePreview"
                  className="modalImage"
                />
                <div className="bottom-border">
                  <div
                    className="customer-img-btns modalimg-btn"
                    onClick={openPopUpImg}
                  />
                  <div
                    className="customer-img-btns fullscreen-btn"
                    onClick={openFullScreen}
                  />
                </div>
              </div>
            ) : (
              <span>No image</span>
            )}
          </div>
          <div className="text-center">
            <p className="my-4">
              Selected document type:
              <strong>
                {custumerForReview.verificationDocument
                  ? custumerForReview.verificationDocument.documentType
                  : null}
              </strong>
            </p>
          </div>
          <div className="efb-form m-auto pb-4 d-flex justify-content-center">
            <div className="align-self-center mr-2">
              <strong>Expiry date:</strong>
            </div>
            <DatePicker
              selected={expiryDate}
              onChange={e => setExpiryDate(e)}
              minDate={moment()
                .add('days', 1)
                .toDate()}
              dateFormat="dd/MM/yyyy"
              popperPlacement="top-end"
              placeholderText="DD/MM/YYYY"
              isClearable
            />
          </div>
          <div className="form-group d-flex justify-content-center">
            <Button
              onClick={() => approveOrReject(approve, true)}
              cssClass="efb-btn__main"
            >
              Approve
            </Button>
            <Button
              onClick={() => approveOrReject(reject, false)}
              cssClass="efb-btn__main"
            >
              Reject
            </Button>
          </div>
        </Modal>
      ) : null}

      <FullScreenImg
        close={closeFullScreen}
        isFullScreen={isFullScreen}
        url={custumerForReview.url}
      />

      <PopUpImg
        close={closeFullScreen}
        isPopUpImg={isPopUpImg}
        url={custumerForReview.url}
      />

      <div className="tab-pane card-body">
        <h2 className="my-4 text-center">Pending verification</h2>
        <Table
          data={pendingUsers}
          columns={columnsForValidationTable}
          tableStyle="sortable table table-hover table-striped efb-table efb-data-table"
          tableType="validationsTable"
          viewFullSize={viewFullSize}
        />
      </div>
    </Fragment>
  );
};

PendingValidations.propTypes = {
  pendingUsers: PropTypes.array,
  approve: PropTypes.func,
  reject: PropTypes.func,
  addNotificationMessage: PropTypes.func,
};

export default PendingValidations;
