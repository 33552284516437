import React, { useMemo } from 'react';
import moment from 'moment';
import Countdown from 'react-countdown';
import { minPrice } from '../../../containers/RiskManagement/Referral/ReferralUtils';

const Row = ({ selection, prepareSelectionForEdit, index }) => {
  const priceDifference = useMemo(() => {
    if (Number(selection.currentOdd).toFixed(2) > minPrice) {
      if (!selection.currentOdd) {
        return selection.priceChange;
      } else {
        return Number(selection.currentOdd - selection.outcomeOdds).toFixed(2);
      }
    } else {
      return Number(minPrice - selection.outcomeOdds).toFixed(2);
    }
  }, [selection.currentOdd, selection.outcomeOdds, selection.priceChange]);

  return (
    <tr
      onClick={() => prepareSelectionForEdit(selection, index)}
      className={`${selection.activeEdit ? 'active-selection' : ''}`}
    >
      <td>{selection.sportName}</td>
      <td>{selection.eventName}</td>
      <td>{selection.marketName}</td>
      <td>{selection.outcomeOddsName}</td>
      <td>
        {Math.max(
          Number(selection.currentOdd || selection.outcomeOdds).toFixed(2),
          minPrice
        )}
      </td>
      <td>
        <div className="efb-form">
          {selection.currentOdd !== selection.outcomeOdds ? (
            <>
              {' '}
              <span className="odd-info-circle danger" /> {''}
            </>
          ) : null}
          {Number(selection.outcomeOdds).toFixed(2)}({priceDifference})
        </div>
      </td>
      <td>
        {selection.priceChangeExpireAt &&
        selection.priceChangeExpireAt !== 0 ? (
          <Countdown
            daysInHours
            date={moment.unix(selection.priceChangeExpireAt / 1000).format()}
          />
        ) : (
          selection.priceChangeExpireAt
        )}
      </td>
      <td>{selection.priceChangedBy || '/'}</td>
    </tr>
  );
};

const SelectionsTableRowNew = ({ tableData, ...props }) => {
  return tableData.map((selection, index) => (
    <Row
      key={selection.selectionId}
      index={index}
      selection={selection}
      {...props}
    />
  ));
};

export default React.memo(SelectionsTableRowNew);
