import React from 'react';
import PropTypes from 'prop-types';

const Textarea = ({
  placeholder,
  value,
  disabled,
  onChange,
  className,
  name,
}) => {
  return (
    <textarea
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      name={name}
      onChange={onChange}
      className={`efb-input efb-textarea  ${className}`}
    />
  );
};

Textarea.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
};

export default Textarea;
