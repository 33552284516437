import React, { useState, useCallback, useMemo } from 'react';
import ImagePreview from './ImagePreview';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { selectStyles } from '../../../utils/selectStyles';
import { useUpdate } from '../../../hooks';
import InputButton from '../../../components/Form/InputButton/InputButton';
import Button from '../../../components/Layout/Button';

const ImageForm = ({ imageTypes, getImages }) => {
  const [imageType, setImageType] = useState('');
  const [image, setImage] = useState('');
  const [file, setFile] = useState('');

  useUpdate(() => {
    if (imageType) getImages(imageType.value);
  }, [imageType]);

  const uploadImage = useCallback(
    e => {
      setFile('');
      if (image) uploadImage(imageType, image);
    },
    [image, imageType]
  );

  const imageTypeList = useMemo(() => {
    return imageTypes.reduce(
      (acc, type) => [...acc, { value: type, label: type }],
      []
    );
  }, [imageTypes]);

  return (
    <>
      <form onSubmit={uploadImage}>
        <div className="row">
          <div className="form-group col-md">
            <InputButton
              type="file"
              name="image"
              label={'Browse for image'}
              onChange={e => {
                if (e.target.files) {
                  setImage(e.target.files[0]);
                  setFile(URL.createObjectURL(e.target.files[0]));
                }
              }}
            />
            <hr />
            <Select
              onChange={setImageType}
              value={imageType}
              options={imageTypeList}
              placeholder="Select image type"
              styles={selectStyles}
            />
          </div>
        </div>
      </form>
      <div className={'form-group d-flex flex-row justify-content-end'}>
        <Button
          cssClass="efb-btn__main"
          onClick={() => {
            setFile('');
            if (image) uploadImage(imageType, image);
          }}
        >
          Upload
        </Button>
      </div>
      {file ? <ImagePreview image={file} /> : null}
    </>
  );
};

ImageForm.propTypes = {
  uploadImage: PropTypes.func,
  imageTypes: PropTypes.array,
};

export default ImageForm;
