import React from 'react';

export const BetToView = (props) => {
    return (
        <span className='efb-icon'>
            <svg className={`efb-icon--${props.size} efb-bettoview`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
                <path d="M33.2,5.7H2.8C1.3,5.7,0,6.9,0,8.5v19c0,1.5,1.3,2.8,2.8,2.8h30.4c1.5,0,2.8-1.3,2.8-2.8v-19C36,6.9,34.7,5.7,33.2,5.7z
                    M6.9,27.9H2.8c-0.2,0-0.4-0.2-0.4-0.4v-3.1h4.5L6.9,27.9z M6.9,22.1H2.4v-2.9h4.5L6.9,22.1z M6.9,16.8H2.4v-2.9h4.5L6.9,16.8z
                    M6.9,11.5H2.4V8.5c0-0.2,0.2-0.4,0.4-0.4h4.1V11.5z M26.7,27.9H9.3V8.1h17.3V27.9z M33.6,27.5c0,0.2-0.2,0.4-0.4,0.4h-4.1v-3.5
                    h4.5V27.5z M33.6,22.1h-4.5v-2.9h4.5V22.1z M33.6,16.8h-4.5v-2.9h4.5V16.8z M33.6,11.5h-4.5V8.1h4.1c0.2,0,0.4,0.2,0.4,0.4V11.5
                    L33.6,11.5z"/>
                <path d="M14.9,23l7.7-3.9c0.9-0.4,0.9-1.7,0-2.1L14.9,13c-0.8-0.4-1.7,0.2-1.7,1.1v7.9C13.2,22.8,14.1,23.4,14.9,23z M15.6,16
                    l3.9,2l-3.9,2V16z"/>
            </svg>
            {props.children}
        </span>
    )
}

export default BetToView;