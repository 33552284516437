import React from 'react';
import PropTypes from 'prop-types';
 
export const NavTabs = ({
  navTab,
  setConfigurationTab,
  tabs
}) => {
  return (
    <ul className="nav nav-tabs nav-fill" role="tablist">
      {Object.keys(tabs).map((tab, index) => (
        <li
          onClick={setConfigurationTab}
          className="nav-item"
          key={index}
        >
          <div
            name={tabs[tab]}
            tabIndex={index + 1}
            className={navTab === tabs[tab] ? 'nav-link active' : 'nav-link'}
          >
            {tab}
          </div>
        </li>
      ))}
    </ul>
  );
};
 
NavTabs.propTypes = {
  setConfigurationTab: PropTypes.func,
  navTab: PropTypes.string,
  tabs: PropTypes.object
};
 
export default NavTabs;