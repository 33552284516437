import React, { memo } from 'react';

export const Bowls = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Bowls ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 149.5 147.68"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g className={'Grayscale'}>
        <path
          className={'Figure'}
          d="M143.39,55.08A70.74,70.74,0,0,0,93.28,5,70.84,70.84,0,0,0,44,9.77q-3.18,1.56-6.23,3.45a72.26,72.26,0,0,0-7.08,5c-1.15.93-2.28,1.91-3.39,2.92-.75.7-1.5,1.4-2.24,2.13-.36.36-.69.74-1,1.1-.86.89-1.68,1.8-2.48,2.72A70.46,70.46,0,0,0,4.37,73.54s0,.07,0,.1v0a71.08,71.08,0,0,0,2.36,17.8,70.68,70.68,0,0,0,14.82,28c.43.49.86,1,1.31,1.46.72.79,1.45,1.57,2.21,2.33.37.37.75.73,1.12,1.08q1.66,1.61,3.41,3.06a70.22,70.22,0,0,0,8.22,6c1.85,1.15,3.75,2.2,5.68,3.17A70.73,70.73,0,0,0,145.78,72.93,70,70,0,0,0,143.39,55.08ZM107.06,33.92a5,5,0,1,1,0,7.09A5,5,0,0,1,107.06,33.92ZM89.58,24.65a5,5,0,1,1,4.63,6.92,5.1,5.1,0,0,1-1.9-.37A5,5,0,0,1,89.58,24.65ZM74.87,17.78a5,5,0,1,1,0,10h0a5,5,0,0,1,0-10ZM53.66,22.05a5,5,0,0,1,3.86,9.25,4.93,4.93,0,0,1-1.93.39,5,5,0,0,1-1.93-9.64Zm-30,30.11a5,5,0,1,1,4.63,6.92,4.92,4.92,0,0,1-1.9-.38A5,5,0,0,1,23.7,52.16ZM19.54,73.4a5,5,0,1,1,5,5h0A5,5,0,0,1,19.54,73.4ZM30.35,97.32a5.1,5.1,0,0,1-1.94.4,5,5,0,1,1,1.94-.4Zm5.34-63.18a5,5,0,1,1,0,7.09A5,5,0,0,1,35.69,34.14Zm7.25,78.45a5,5,0,1,1,0-7.09A5,5,0,0,1,42.94,112.59Zm17.46,9.3a5,5,0,0,1-4.63,3.1,5.16,5.16,0,0,1-1.91-.38,5,5,0,1,1,6.54-2.72Zm14.7,6.91a5,5,0,1,1,5-5A5,5,0,0,1,75.1,128.8Zm21.23-4.24A5,5,0,1,1,99,118,5,5,0,0,1,96.33,124.56ZM75.07,112.9a39.62,39.62,0,1,1,39.62-39.61A39.66,39.66,0,0,1,75.07,112.9Zm39.24-.38a5,5,0,1,1,0-7.09A5,5,0,0,1,114.31,112.52Zm5.39-63.37A5,5,0,1,1,117,55.72,5,5,0,0,1,119.7,49.15Zm6.65,45.38A5,5,0,1,1,123.63,88,5,5,0,0,1,126.35,94.53Zm4.2-21.35v.1a5,5,0,1,1-10,0V73.1a5,5,0,0,1,10,0Z"
        />
        <path
          className={'Figure'}
          d="M75.07,43.7a29.59,29.59,0,1,0,29.59,29.59A29.62,29.62,0,0,0,75.07,43.7Zm0,49a19.4,19.4,0,1,1,19.4-19.39A19.4,19.4,0,0,1,75.07,92.68Z"
        />
      </g>
      <g className={'WithColor'}>
        <path
          className={'OuterSegment'}
          d="M143.15,55.69a70.6,70.6,0,0,0-50-50,70.64,70.64,0,0,0-49.12,4.81A67.34,67.34,0,0,0,37.85,14a70.62,70.62,0,0,0-7.06,5q-1.72,1.39-3.38,2.92c-.76.69-1.51,1.39-2.24,2.12-.36.36-.69.73-1,1.1-.85.89-1.67,1.79-2.47,2.71A70.23,70.23,0,0,0,4.53,74.09v.15A70.63,70.63,0,0,0,6.89,92,70,70,0,0,0,21.66,119.9c.42.5.86,1,1.3,1.46.72.79,1.45,1.57,2.21,2.33.37.37.74.72,1.12,1.08,1.1,1.06,2.24,2.07,3.39,3a70.59,70.59,0,0,0,8.2,6q2.77,1.72,5.67,3.16a70.72,70.72,0,0,0,31.12,7.41,70.51,70.51,0,0,0,70.85-70.85A69.56,69.56,0,0,0,143.15,55.69Z"
        />
        <path
          className={'Holes'}
          d="M70.08,124.2a5,5,0,0,1,5-5h0a5,5,0,0,1,5,5h0a5,5,0,0,1-5,5h0A5,5,0,0,1,70.08,124.2ZM53.9,125a5,5,0,0,1-2.71-6.53h0a5,5,0,0,1,6.53-2.72h0a5,5,0,0,1,2.71,6.53h0a5,5,0,0,1-4.62,3.1h0A5.16,5.16,0,0,1,53.9,125Zm35.81-2.74a5,5,0,0,1,2.7-6.54h0a5,5,0,0,1,6.54,2.7h0a5,5,0,0,1-2.7,6.53h0a5,5,0,0,1-1.92.39h0A5,5,0,0,1,89.71,122.28ZM36,113a5,5,0,0,1,0-7.07h0A5,5,0,0,1,43,106h0A5,5,0,0,1,43,113h0a5,5,0,0,1-3.54,1.47h0A5,5,0,0,1,36,113Zm71.16-.08a5,5,0,0,1,0-7.07h0a5,5,0,0,1,7.07,0h0a5,5,0,0,1,0,7.07h0a5,5,0,0,1-3.54,1.47h0A5,5,0,0,1,107.11,113ZM23.92,95.12a5,5,0,0,1,2.69-6.54h0a5,5,0,0,1,6.54,2.69h0a5,5,0,0,1-2.69,6.54h0a5,5,0,0,1-1.93.39h0A5,5,0,0,1,23.92,95.12Zm95.72,2.6a5,5,0,0,1-2.7-6.53h0a5,5,0,0,1,6.53-2.71h0A5,5,0,0,1,126.18,95h0a5,5,0,0,1-4.63,3.08h0A5,5,0,0,1,119.64,97.72ZM19.68,74a5,5,0,0,1,5-5h0a5,5,0,0,1,5,5h0a5,5,0,0,1-5,5h0A5,5,0,0,1,19.68,74Zm100.69-.12v-.1h0v-.08h0a5,5,0,0,1,5-5h0a5,5,0,0,1,5,5h0v.12h0v.1h0a5,5,0,0,1-5,5h0A5,5,0,0,1,120.37,73.84ZM26.55,59.3a5,5,0,0,1-2.72-6.52h0a5,5,0,0,1,6.53-2.72h0a5,5,0,0,1,2.72,6.52h0a5,5,0,0,1-4.63,3.1h0A4.92,4.92,0,0,1,26.55,59.3Zm90.32-3h0a5,5,0,0,1,2.68-6.55h0a5,5,0,0,1,6.54,2.68h0A5,5,0,0,1,123.41,59h0a5,5,0,0,1-1.93.39h0A5,5,0,0,1,116.87,56.33ZM35.81,41.88a5,5,0,0,1,0-7.07h0a5,5,0,0,1,7.07,0h0a5,5,0,0,1,0,7.07h0a5,5,0,0,1-3.55,1.47h0A5,5,0,0,1,35.81,41.88ZM107,41.66a5,5,0,0,1,0-7.07h0a5,5,0,0,1,7.07,0h0a5,5,0,0,1,0,7.07h0a5,5,0,0,1-3.55,1.48h0A5,5,0,0,1,107,41.66ZM51,29.3a5,5,0,0,1,2.69-6.54h0a5,5,0,0,1,6.54,2.68h0A5,5,0,0,1,57.55,32h0a4.85,4.85,0,0,1-1.92.39h0A5,5,0,0,1,51,29.3Zm41.23,2.58a5,5,0,0,1-2.72-6.53h0A5,5,0,0,1,96,22.63h0a5,5,0,0,1,2.73,6.52h0a5,5,0,0,1-4.63,3.1h0A5.1,5.1,0,0,1,92.24,31.88ZM69.87,23.51a5,5,0,0,1,5-5h0a5,5,0,0,1,5,5h0a5,5,0,0,1-5,5h0A5,5,0,0,1,69.87,23.51Z"
        />
        <path
          className={'Holes'}
          d="M75,113.34a39.5,39.5,0,1,1,39.49-39.5A39.54,39.54,0,0,1,75,113.34Zm0-69a29.5,29.5,0,1,0,29.49,29.5A29.53,29.53,0,0,0,75,44.34Z"
        />
        <circle className={'Circle'} cx="75.03" cy="73.84" r="19.34" />
      </g>
    </svg>
  );
};

export default memo(Bowls);
