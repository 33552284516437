import React, { useState, useCallback } from 'react';
import Input from '../../../Form/Input';

const InputFilter = ({ name, label, onFilterChange: pushFilterChange }) => {
  const [value, setValue] = useState('');

  const handleChange = useCallback(
    newValue => {
      setValue(newValue.target.value);
      pushFilterChange({ name, value: newValue.target.value });
    },
    [name, pushFilterChange, setValue]
  );

  return (
    <div>
      <label>{label}:</label>
      <Input
        customInput
        value={value}
        onChange={handleChange}
        className={'efb-input__underlined'}
      />
    </div>
  );
};

export default InputFilter;
