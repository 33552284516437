import React from 'react';
import Input from '../Input';

const InputWithLabel = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  className,
  required = false,
}) => (
  <label className={'efb-input__label ' + className}>
    <span className="efb-input__label-text">{label}</span>
    <Input
      required={required}
      type={type}
      value={value}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
    />
  </label>
);

export default InputWithLabel;
