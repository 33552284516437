export const columnsParams = [
  { name: 'Series number', value: 'seriesNumber', type: 'string' },
  { name: 'Name', value: 'displayName', type: 'string' },
  { name: 'Value', value: 'p', type: 'string' },
  { name: 'Action', type: 'string' },
  { name: '' },
];

export const columnsModels = [
  { name: 'Range', value: 'coefficientRange', type: 'string' },
  { name: '' },
];

export const store = {
  dashboard: [
    {
      name: 'In Play Parameters',
      url: 'cipp',
    },
    {
      name: 'Prematch Parameters',
      url: 'cpp',
    },
    {
      name: 'Cashout Configurator',
      url: 'cc',
    },
  ],
};

export const updateFilterObj = (data, obj, updateFunc) => {
  const index = data.findIndex(x => x.seriesNumber === obj.seriesNumber);
  const copy = [...data];
  copy[index] = obj;
  updateFunc(copy);
};
