import React, { useState } from 'react';

import CreateTournament from './CreateTournament';
import TournamentsTable from './TournamentsTable';

const Tournaments = ({ languages }) => {
  const [editingTournament, setEditingTournament] = useState(null);
  const [sportId, setSportId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  return (
    <>
      <h3>Tournaments</h3>
      <CreateTournament
        languages={languages}
        editingTournament={editingTournament}
        clearEditingTournament={() => setEditingTournament(null)}
        setSportId={setSportId}
        setCategoryId={setCategoryId}
      />

      <hr />

      <TournamentsTable
        languages={languages}
        editTournament={setEditingTournament}
        editingTournament={editingTournament}
        sportId={sportId}
        categoryId={categoryId}
      />
    </>
  );
};

export default Tournaments;
