import axiosInstance from '../axios-instance';
import { createRequestTypes, actionCreator } from '../index';
import { addNotificationMessage } from '../ui';

export const GET_PAGES = createRequestTypes('GET_PAGES');
export const GET_STATIC_PAGE_IMAGES = createRequestTypes(
  'GET_STATIC_PAGE_IMAGES'
);
export const GET_UPLOADED_IMAGE = createRequestTypes('GET_UPLOADED_IMAGE');
export const GET_SUBPAGE_CONTENT = createRequestTypes('GET_SUBPAGE_CONTENT');
export const GET_REGULAR_PAGE_CONTENT = createRequestTypes(
  'GET_REGULAR_PAGE_CONTENT'
);
export const GET_COUNTRIES = createRequestTypes('GET_COUNTRIES');
export const GET_CURRENCIES = createRequestTypes('GET_CURRENCIES');

export const addPage = (page, lang) => async dispatch => {
  try {
    await axiosInstance.post('/content/admin/staticpages/', page);
    dispatch(
      addNotificationMessage(
        'You have successfully added page',
        'success',
        'Success'
      )
    );
    dispatch(getPagesByLanguage(lang));
    return true;
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
    return false;
  }
};

export const updatePageContent = (content, id) => async dispatch => {
  try {
    await axiosInstance.put(`/content/admin/staticpages/pages/${id}`, content);
    dispatch(
      addNotificationMessage(
        'You have successfully added page content',
        'success',
        'Success'
      )
    );
    return true;
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
    return false;
  }
};

export const getPagesByLanguage = lang => async dispatch => {
  dispatch(actionCreator.request(GET_PAGES));
  try {
    const res = await axiosInstance.get(
      `/content/admin/staticpages/${lang}/catalogue?inclDisabled=true`
    );
    dispatch(actionCreator.success(GET_PAGES, res));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateStaticPage = (data, pageId, lang) => async dispatch => {
  try {
    await axiosInstance.put(`/content/admin/staticpages/pages/${pageId}`, data);
    dispatch(
      addNotificationMessage(
        'You have successfully updated page',
        'success',
        'Success'
      )
    );
    dispatch(getPagesByLanguage(lang));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const getStaticPageImages = () => async dispatch => {
  try {
    const res = await axiosInstance.get(
      '/content/public/images/STATIC_PAGE_IMAGE'
    );
    dispatch(actionCreator.success(GET_STATIC_PAGE_IMAGES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_STATIC_PAGE_IMAGES, ''));
  }
};

export const uploadImage = (image, success, failure) => async dispatch => {
  try {
    const res = await axiosInstance.post(
      '/content/admin/image/upload?imageType=STATIC_PAGE_IMAGE',
      image
    );
    success(res.link);
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getSubpageContent = url => async dispatch => {
  try {
    const res = await axiosInstance.get(`${url}`);
    dispatch(actionCreator.success(GET_SUBPAGE_CONTENT, res));
  } catch (err) {
    dispatch(actionCreator.success(GET_SUBPAGE_CONTENT, err.message));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const updateSectionContent = (content, lang, id) => async dispatch => {
  try {
    await axiosInstance.put(
      `/content/admin/staticpages/sections/${id}`,
      content
    );
    dispatch(
      addNotificationMessage(
        'You have successfully updated subpage section',
        'success',
        'Success'
      )
    );
    dispatch(getPagesByLanguage(lang));
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const updateRulesContent = (content, lang, id) => async dispatch => {
  try {
    await axiosInstance.put(`/content/admin/staticpages/rules/${id}`, content);
    dispatch(
      addNotificationMessage(
        'You have successfully updated subpage rule',
        'success',
        'Success'
      )
    );
    dispatch(getPagesByLanguage(lang));
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const getRegularPageContent = url => async dispatch => {
  try {
    const res = await axiosInstance.get(`${url}`);
    dispatch(actionCreator.success(GET_REGULAR_PAGE_CONTENT, res));
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const updateRegularPageContent = (
  content,
  pageId,
  lang
) => async dispatch => {
  try {
    await axiosInstance.put(
      `/content/admin/staticpages/pages/${pageId}`,
      content
    );
    dispatch(
      addNotificationMessage(
        'You have successfully updated regular page',
        'success',
        'Success'
      )
    );
    dispatch(getPagesByLanguage(lang));
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const getCountries = () => async dispatch => {
  try {
    const res = await axiosInstance.get(
      '/customer/view/public/countries?locale=en'
    );
    dispatch(actionCreator.success(GET_COUNTRIES, res));
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const getCurrencies = () => async dispatch => {
  try {
    const res = await axiosInstance.get('/currency/exchange/available');
    dispatch(actionCreator.success(GET_CURRENCIES, res));
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const extendSubpageContent = (
  content,
  pageId,
  lang
) => async dispatch => {
  try {
    await axiosInstance.put(
      `/content/admin/staticpages/pages/${pageId}`,
      content
    );
    dispatch(
      addNotificationMessage(
        'You have successfully added content to this subpage',
        'success',
        'Success'
      )
    );
    dispatch(getPagesByLanguage(lang));
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const deletePage = (pageId, lang) => async dispatch => {
  try {
    await axiosInstance.delete(`/content/admin/staticpages/pages/${pageId}`);
    dispatch(
      addNotificationMessage(
        'You have successfully deleted page',
        'success',
        'Success'
      )
    );
    dispatch(getPagesByLanguage(lang));
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};

export const updateTable = data => async dispatch => {
  try {
    await axiosInstance.put('/content/admin/staticpages/table/cells', data);
    dispatch(
      addNotificationMessage(
        'You have successfully updated table cell',
        'success',
        'Success'
      )
    );
  } catch (err) {
    if (err.errors && typeof err.errors === 'object') {
      Object.keys(err.errors).forEach(error_code => {
        dispatch(
          addNotificationMessage(err.errors[error_code], 'error', 'Error')
        );
      });
    }
  }
};
