import React from 'react';

export const DBCore = (props) => {
    return (
        <span className='efb-icon'>
            <svg className={`efb-icon--${props.size} efb-dbcore`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
                <path d="M27.2,29.9v3.5c0,1.5,1.2,2.7,2.7,2.7h3.5c1.5,0,2.7-1.2,2.7-2.7v-3.5c0-1.5-1.2-2.7-2.7-2.7h-0.8V25c0-1.3-1.1-2.4-2.4-2.4
                    H18.9v-3.4c4.9-0.5,8.7-4.6,8.7-9.6C27.6,4.3,23.3,0,18,0c-5.3,0-9.6,4.3-9.6,9.6c0,5,3.8,9.1,8.7,9.6v3.4H5.9
                    c-1.3,0-2.4,1.1-2.4,2.4v2.3H2.7c-1.5,0-2.7,1.2-2.7,2.7l0,3.5C0,34.8,1.2,36,2.7,36h3.5c1.5,0,2.7-1.2,2.7-2.7v-3.5
                    c0-1.5-1.2-2.7-2.7-2.7H5.3V25c0-0.3,0.3-0.6,0.6-0.6h11.2v2.8h-0.8c-1.5,0-2.7,1.2-2.7,2.7v3.5c0,1.5,1.2,2.7,2.7,2.7h3.5
                    c1.5,0,2.7-1.2,2.7-2.7v-3.5c0-1.5-1.2-2.7-2.7-2.7h-0.8v-2.8h11.2c0.3,0,0.6,0.3,0.6,0.6v2.3h-0.8C28.4,27.2,27.2,28.4,27.2,29.9z
                    M24.4,9.5c0,3.6-2.9,6.4-6.4,6.4s-6.4-2.9-6.4-6.4S14.4,3,18,3S24.4,5.9,24.4,9.5z"/>
            </svg>
            {props.children}
        </span>
    )
}

export default DBCore;