import React from 'react';

export const CallCenter = (props) => {
    return (
        <span className='efb-icon'>
            <svg className={`efb-icon--${props.size} efb-callcenter`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
                <path d="M30.3,13.7v-1.4c0-3.3-1.2-6.4-3.3-8.7C24.8,1.3,21.8,0,18.6,0h-1.1C14.2,0,11.2,1.3,9,3.6C6.9,5.9,5.7,9,5.7,12.3v1.4
		            c-2.6,0.2-4.6,2.3-4.6,4.9v2.2c0,2.7,2.2,5,5,5h2.8c0.5,0,0.9-0.4,0.9-0.9V14.6c0-0.5-0.4-0.9-0.9-0.9H7.5v-1.4
		            c0-6,4.3-10.5,9.9-10.5h1.1c5.7,0,9.9,4.5,9.9,10.5v1.4h-1.3c-0.5,0-0.9,0.4-0.9,0.9v10.3c0,0.5,0.4,0.9,0.9,0.9h1.3
		            c-0.4,4.7-3.6,5.8-5.1,6.1c-0.4-1.3-1.6-2.2-3-2.2H18c-1.8,0-3.2,1.4-3.2,3.2S16.3,36,18,36h2.3c1.5,0,2.7-1,3.1-2.3
		            c0.7-0.1,1.9-0.4,3.1-1.1c1.6-1,3.6-2.9,3.8-6.9c2.6-0.2,4.7-2.3,4.7-4.9v-2.2C34.9,16,32.9,13.9,30.3,13.7z M7.9,23.9H6
		            c-1.7,0-3.1-1.4-3.1-3.1v-2.2c0-1.7,1.4-3.1,3.1-3.1h1.9V23.9z M20.3,34.2H18c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h2.3
		            c0.8,0,1.4,0.6,1.4,1.4S21,34.2,20.3,34.2z M33.1,20.8c0,1.7-1.4,3.1-3.1,3.1h-1.9v-8.4H30c1.7,0,3.1,1.4,3.1,3.1V20.8z"/>
            </svg>
            {props.children}
        </span>
    )
}

export default CallCenter;