import React, { useCallback } from 'react';
import moment from 'moment';
import { Button } from '../../Layout';
import CheckboxDiv from '../../Form/CheckboxDiv/CheckboxDiv';

const BonusStatusRow = ({
  tableData,
  approveCustomerBonus,
  openRejectModal,
  setDisabledFor,
  disabledFor,
  selectedStatuses,
  setSelectedStatuses,
}) => {
  const handleCheckbox = useCallback(
    id => {
      setSelectedStatuses(
        selectedStatuses.includes(id)
          ? selectedStatuses.filter(status => status !== id)
          : [...selectedStatuses, id]
      );
    },
    [selectedStatuses, setSelectedStatuses]
  );

  const renderRow = bonus => {
    return (
      <tr key={bonus.id}>
        <td>{bonus.bonusId}</td>
        {tableData.every(bonus => bonus.bonusStatus === 'PENDING') ? (
          <td className="text-center">
            <CheckboxDiv
              isChecked={selectedStatuses.includes(bonus.id)}
              label={
                selectedStatuses.includes(bonus.id) ? 'deselect' : 'select'
              }
              className={'font-weight-bold'}
              id={bonus.id}
              onChange={е => handleCheckbox(Number(bonus.id))}
            />
          </td>
        ) : null}
        <td></td>
        <td>
          {bonus.datePicket
            ? moment(bonus.datePicket).format('DD/MM/YYYY HH:mm:ss')
            : 'Not selected yet'}
        </td>
        <td>{bonus.bonusName}</td>
        <td>{bonus.bonusStatus}</td>
        <td>{bonus.customerUsername}</td>
        <td>{bonus.activatedBy}</td>
        <td>
          {bonus.dateActivated
            ? moment(bonus.dateActivated).format('DD/MM/YYYY HH:mm:ss')
            : 'Not activated'}
        </td>
        <td>{bonus.deactivatedBy}</td>
        <td>{bonus.deactivatedReason}</td>
        <td>
          {bonus.dateDeactivated
            ? moment(bonus.dateDeactivated).format('DD/MM/YYYY HH:mm:ss')
            : 'Not deactivated'}
        </td>
        <td>{Number(bonus.totalBetsBonusAmount / 100).toFixed(2)}</td>
        <td>{Number(bonus.totalWinsBonusAmount / 100).toFixed(2)}</td>
        <td>{Number(bonus.bonusAmount / 100).toFixed(2)}</td>
        <td>
          {Number(bonus.currentRolloutAmount / 100).toFixed(2)} /{' '}
          {Number(bonus.targetRolloutAmount / 100).toFixed(2)}
        </td>
        <td>
          <div className="form-row flex-nowrap">
            {['PENDING'].indexOf(bonus.bonusStatus) > -1 ? (
              <Button
                disabled={disabledFor.find(disFor => disFor === bonus.id)}
                onClick={() => {
                  approveCustomerBonus([
                    {
                      bonusCustomerId: bonus.id,
                      customerId: bonus.customerId,
                      bonusAmount: bonus.bonusAmount,
                      bonusType: bonus.type,
                    },
                  ]);
                  setDisabledFor([...disabledFor, bonus.id]);
                }}
                cssClass="btn btn-success"
              >
                Accept
              </Button>
            ) : null}
            {['PENDING', 'ACTIVE'].indexOf(bonus.bonusStatus) > -1 ? (
              <Button
                onClick={() =>
                  openRejectModal({
                    bonusCustomerId: bonus.id,
                    customerId: bonus.customerId,
                    bonusType: bonus.type,
                  })
                }
                cssClass="btn btn-danger"
              >
                Reject
              </Button>
            ) : null}
          </div>
        </td>
      </tr>
    );
  };

  return <>{tableData.map(renderRow)}</>;
};

export default React.memo(BonusStatusRow);
