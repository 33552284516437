import React, { memo } from 'react';

export const BeachFootball = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `BeachFootball ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141 120.32"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <path
          className={'Ball'}
          d="M120.34,49.84A49.84,49.84,0,1,1,70.5,0,49.83,49.83,0,0,1,120.34,49.84Z"
        />
        <path
          className={'Stripe'}
          d="M110.44,79.65a49.9,49.9,0,0,1-7.76,8.23l0-.07s.87-4,.9-4.17c4-16,1.65-20.51.85-21.52a2.37,2.37,0,0,0-2.09-.88h-.14c-6.46,0-13.35,14-16.36,22.44.82.61,1.83,1.39,2.85,2.24,4.75,3.94,5.52,5.91,5.65,7.32a3.18,3.18,0,0,1,0,.35c-1.13.62-2.29,1.19-3.47,1.72q-.82.37-1.66.71t-1.7.65l.92-2.57c0-.09,0-.16.06-.22-.7-1.14-3.62-3.73-6.78-6-5,.92-21.5,3.35-29-2.79a10.46,10.46,0,0,1-3.92-8.4c0-8.69,9-14.12,17.76-19.38C74.66,52.42,83,47.38,83,40.6a5.63,5.63,0,0,0-2.17-4.86c-5.08-3.88-17.74-2-23.61-.67A95.45,95.45,0,0,1,53,46.93c-4.4,9.83-9.37,14.81-14.76,14.81a10.79,10.79,0,0,1-8.77-3.95c-5.89-7.36-2.83-23.4,0-36.19a49.56,49.56,0,0,1,4-5.07,49,49,0,0,1,3.89-3.88c-.35,1.88-.87,4.24-1.56,7.29C33.36,30.6,29.36,48.41,34,54.17a4.9,4.9,0,0,0,4.23,1.77c5,0,10.85-13.06,13.54-22.67-2.67-3.58-9-12.63-9-18.05,0-8.08,11.7-11.67,16.15-11.67,11,0,23.62,8.79,32,14.6,2.77,1.93,6.2,4.32,7.32,4.66a3,3,0,0,0,1.91-.5c.16-.19.52-.82.54-2.64-.11-.42-1-2.84-7.69-11.31l0,0-3.69-4.7a49.75,49.75,0,0,1,13.63,8.29h0c3.05,4.45,3.59,6.35,3.59,7.64,0,7.49-4.57,9.07-8.41,9.07-2.21,0-4.81-1.75-10.49-5.7C79.78,17.52,68.06,9.36,58.89,9.36c-3.23,0-10.34,2.83-10.34,5.86,0,2.79,4.09,9.47,7.53,14.15,5.57-1.29,20.73-4.06,28.32,1.75a11.44,11.44,0,0,1,4.45,9.48c0,10.07-10.25,16.23-19.29,21.67-7.69,4.63-15,9-15,14.4a4.66,4.66,0,0,0,1.77,3.9c4.85,3.94,17.82,2.7,23.85,1.67a92.25,92.25,0,0,1,5.27-12c5.22-9.78,10.84-14.74,16.71-14.76A8.2,8.2,0,0,1,109,58.51C112,62.28,112.48,69.22,110.44,79.65Z"
        />
        <path
          className={'Stripe'}
          d="M120.2,53.67c0,.62-.1,1.24-.17,1.86a50.21,50.21,0,0,1-.91,5.38l-2.49-2.54a33.66,33.66,0,0,1-4.72-6.08c-3.86-6.9-1.79-25.55-1.76-25.73l.82-5.84a49.3,49.3,0,0,1,4.76,8.14h0c0,.38-.07.78-.1,1.21h0c-.44,5.91-.46,16.14,1.35,19.38A25.3,25.3,0,0,0,120.2,53.67Z"
        />
        <path
          className={'Sand'}
          d="M141,101.27v19H0v-24c0-9.54,6.91-18.75,19.49-21.53a56.78,56.78,0,0,0,90.24,16.08c4.26-1.52,7.51-2.81,12.46-2.81C133.6,88.1,141,94.08,141,101.27Z"
        />
      </g>
    </svg>
  );
};

export default memo(BeachFootball);
