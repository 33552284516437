import React, { useState, useCallback } from 'react';

import Modal from '../../../../components/Modal/Modal';
import InputWithLabel from '../../../../components/Form/InputWithLabel/InputWithLabel';
import Switch from '../../../../components/Form/Switch/Switch';
import { useUpdate } from '../../../../hooks';
import Button from '../../../../components/Layout/Button';

const EditUserModal = ({ isActive, user, onClose, updateUser }) => {
  const [description, setDescription] = useState('');
  const [userActive, setUserActive] = useState('');

  useUpdate(() => {
    clearForm();
  }, [user]);

  const clearForm = useCallback(() => {
    setDescription(user.description);
    setUserActive(user.active);
  }, [user.active, user.description]);

  const handleSubmit = useCallback(() => {
    updateUser(user.id, description, userActive);
    onClose();
  }, [description, onClose, updateUser, user.id, userActive]);

  return (
    <Modal
      isActive={isActive}
      onClose={onClose}
      headerText={`Edit user ${user.username}`}
    >
      <div className="efb-modal__content">
        <div className="form-row">
          <div className="col">
            <InputWithLabel
              label="Description:"
              value={description}
              onChange={e => setDescription(e.target.value)}
              type="text"
              required
            />
          </div>
        </div>
        <div className="form-row">
          <Switch
            label={userActive ? 'Active' : 'Inactive'}
            isChecked={userActive}
            onChange={e => setUserActive(e.target.checked)}
            name="userActive"
          />
        </div>
      </div>
      <div className="efb-modal__footer">
        <Button onClick={clearForm} cssClass="efb-btn__link">
          Clear
        </Button>
        <Button onClick={handleSubmit} cssClass="efb-btn__main">
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default EditUserModal;
