import React, { Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { selectFieldOptions } from '../transactionsUtils';
import { useStateForSelect, useUpdate } from '../../../../hooks';

import Preferences from './Preferences';
import Currencies from './Currencies';
import Fees from './Fees';
import Switch from '../../../../components/Form/Switch/Switch';
import Table from '../../../../components/NewTable/Table';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Layout/Button';
import BusinessUnitSelector from '../../../../components/BusinessUnitSelector/BusinessUnitSelector';

const PaymentManager = ({
  paymentProvidersPerBSUnit,
  getPaymentProviderForBSUnits,
  getPreferences,
  currencies,
  updatePaymentProvider,
  enableWithdraw,
  enableDeposit,
}) => {
  const [preferencesValue, setPreferencesValue] = useState({});
  const [providerId, setProviderId] = useState(null);
  const [isOpenPreferencies, setIsOpenPreferencis] = useState(false);
  const [isOpenCurrencies, setIsOpenCurrencies] = useState(false);
  const [isOpenFees, setIsOpenFees] = useState(false);
  const [titleFee, setTitleFee] = useState('');
  const [bsUnit, setBsUnit] = useStateForSelect('');
  const [isChecked, setIsChecked] = useState({
    web: '',
    mobile: '',
    enabled: '',
    paymentProviderId: '',
    businessUnit: '',
  });

  const currencieOptions = useMemo(
    () => selectFieldOptions(currencies, 'currency_code', 'display_name'),
    [currencies]
  );

  useUpdate(() => {
    if (bsUnit) {
      getPaymentProviderForBSUnits(bsUnit);
    }
  }, [bsUnit]);

  useUpdate(() => {
    if (isChecked.paymentProviderId) {
      const currnetPaymentProvider = paymentProvidersPerBSUnit.find(
        payment => payment.paymentProviderId === isChecked.paymentProviderId
      );
      updatePaymentProvider({
        ...currnetPaymentProvider,
        ...isChecked,
      });
    }
  }, [isChecked]);

  const paymentManagerColumns = useMemo(
    () => [
      {
        name: 'Business unit',
        display: entity => entity.businessUnit,
      },
      {
        name: 'Enabled/Disabled',
        display: entity => (
          <Switch
            smallSwitch
            name="enabled"
            isChecked={entity.enabled}
            onChange={e =>
              setIsChecked({
                id: entity.paymentProviderId,
                paymentProviderId: entity.paymentProviderId,
                enabled: e.target.checked,
                businessUnit: entity.businessUnit,
              })
            }
          />
        ),
      },
      {
        name: 'Mobile',
        display: entity => (
          <Switch
            smallSwitch
            name="mobile"
            isChecked={entity.mobile}
            onChange={e =>
              setIsChecked({
                id: entity.paymentProviderId,
                paymentProviderId: entity.paymentProviderId,
                mobile: e.target.checked,
                businessUnit: entity.businessUnit,
              })
            }
          />
        ),
      },
      {
        name: 'Web',
        display: entity => (
          <Switch
            smallSwitch
            name="web"
            isChecked={entity.web}
            onChange={e =>
              setIsChecked({
                id: entity.paymentProviderId,
                paymentProviderId: entity.paymentProviderId,
                web: e.target.checked,
                businessUnit: entity.businessUnit,
              })
            }
          />
        ),
      },
      {
        name: 'Deposit',
        display: entity => (
          <Switch
            smallSwitch
            name="deposit"
            isChecked={entity.depositEnabled}
            onChange={e => {
              enableDeposit(
                entity.paymentProviderId,
                e.target.checked,
                entity.businessUnit
              );
            }}
          />
        ),
      },
      {
        name: 'Withdraw',
        display: entity => (
          <Switch
            smallSwitch
            name="withdraw"
            isChecked={entity.withdrawEnabled}
            onChange={e => {
              enableWithdraw(
                entity.paymentProviderId,
                e.target.checked,
                entity.businessUnit
              );
            }}
          />
        ),
      },
      {
        name: 'Payment provider',
        display: entity => entity.paymentProviderName,
      },
      {
        name: 'Currencies',
        display: entity => (
          <Button
            cssClass="btn btn-info"
            onClick={() => {
              setProviderId(entity.paymentProviderId);
              setIsOpenCurrencies(true);
            }}
          >
            <i className="fas fa-info mr-2" />
            Currencies
          </Button>
        ),
      },
      {
        name: 'Fees',
        display: entity => (
          <Button
            cssClass="btn btn-info"
            onClick={() => {
              setProviderId(entity.paymentProviderId);
              setTitleFee(entity.paymentProviderName);
              setIsOpenFees(true);
            }}
          >
            <i className="fas fa-info mr-2" />
            Fees
          </Button>
        ),
      },
      {
        name: 'Preferences',
        display: entity => (
          <Button
            cssClass="btn btn-info"
            onClick={() => {
              setPreferencesValue({
                ...preferencesValue,
                providerId: entity.paymentProviderId,
                id: entity.paymentProviderId,
              });
              getPreferences(entity.paymentProviderId);
              setIsOpenPreferencis(true);
            }}
          >
            <i className="fas fa-info mr-2" />
            Limits
          </Button>
        ),
      },
    ],
    [enableDeposit, enableWithdraw, getPreferences, preferencesValue]
  );

  return (
    <Fragment>
      <h3 className="text-center my-4">Payment Manager</h3>
      <div className="efb-form d-flex flex-column align-items-center">
        <div className="form-row col-md-9">
          <div className="form-label-group col-md">
            <BusinessUnitSelector onChange={setBsUnit} defaultBu={' '} />
          </div>
        </div>
        <div className="col-md-9">
          <Table
            data={paymentProvidersPerBSUnit}
            columns={paymentManagerColumns}
            pagination={false}
          />
          <Modal
            headerText={'Limits for payment provider'}
            isActive={isOpenPreferencies}
            onClose={() => {
              setIsOpenPreferencis(false);
              setPreferencesValue({});
            }}
          >
            <Preferences
              preferencesValue={preferencesValue}
              setPreferencesValue={setPreferencesValue}
              currencieOptions={currencieOptions}
            />
          </Modal>
          <Modal
            headerText={'Currencies for payment provider'}
            isActive={isOpenCurrencies}
            onClose={() => setIsOpenCurrencies(false)}
          >
            <Currencies
              providerId={providerId}
              currencieOptions={currencieOptions}
            />
          </Modal>
          <Modal
            headerText={`Fees for ${titleFee}`}
            isActive={isOpenFees}
            onClose={() => setIsOpenFees(false)}
          >
            <Fees
              providerId={providerId}
              currencieOptions={currencieOptions}
              paymentProvidersPerBSUnit={paymentProvidersPerBSUnit}
              bsUnit={bsUnit}
            />
          </Modal>
        </div>
      </div>
    </Fragment>
  );
};

PaymentManager.propTypes = {
  preferences: PropTypes.array,
  currencies: PropTypes.array,
  paymentProvidersPerBSUnit: PropTypes.array,
  getPaymentProviderForBSUnits: PropTypes.func,
  getPreferences: PropTypes.func,
  addPreferences: PropTypes.func,
  updatePaymentProvider: PropTypes.func,
  addNotificationMessage: PropTypes.func,
};

export default PaymentManager;
