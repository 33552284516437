import React, { memo } from 'react';

export const Cycling = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Cycling ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141 76.74"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <path d="M24.93,29.88A21.93,21.93,0,1,1,3,51.81,22,22,0,0,1,24.93,29.88m0-3A24.93,24.93,0,1,0,49.85,51.81,24.93,24.93,0,0,0,24.93,26.89Z" />
        <path d="M24.92,53.35a1.5,1.5,0,0,1-1.38-2.08L44.85.91a1.5,1.5,0,1,1,2.76,1.16L26.3,52.44A1.5,1.5,0,0,1,24.92,53.35Z" />
        <path d="M116.07,29.88A21.93,21.93,0,1,1,94.14,51.81a22,22,0,0,1,21.93-21.93m0-3A24.93,24.93,0,1,0,141,51.81a24.93,24.93,0,0,0-24.93-24.93Z" />
        <path d="M81.68,53.35a1.5,1.5,0,0,1-1.38-2.08l20.35-48.1a1.5,1.5,0,0,1,2.76,1.16L83.06,52.44A1.5,1.5,0,0,1,81.68,53.35Z" />
        <path d="M116.09,53.35H81.68a1.5,1.5,0,1,1,0-3h34.41a1.5,1.5,0,1,1,0,3Z" />
        <path d="M116.09,53.35a1.5,1.5,0,0,1-1.37-.89L97.17,12.61a1.5,1.5,0,1,1,2.74-1.21l17.55,39.85a1.5,1.5,0,0,1-1.37,2.1Z" />
        <path d="M98.54,13.51H41.79a1.5,1.5,0,0,1,0-3H98.54a1.5,1.5,0,1,1,0,3Z" />
        <path d="M81.68,53.35a1.49,1.49,0,0,1-1.06-.44L40.73,13.06a1.5,1.5,0,1,1,2.11-2.12L82.74,50.8a1.5,1.5,0,0,1-1.06,2.55Z" />
        <polygon points="91.46 3.58 108.58 3.58 108.58 7.55 91.46 3.58" />
        <path d="M108.58,9.05a1.47,1.47,0,0,1-.34,0L91.12,5a1.5,1.5,0,0,1,.34-3h17.12a1.5,1.5,0,0,1,1.5,1.5v4a1.5,1.5,0,0,1-1.5,1.5Zm-4.06-4,2.56.59V5.08Z" />
        <path d="M28.91,11.14a4.73,4.73,0,0,1-5.07-5A6,6,0,0,1,30,0H46.23a1.5,1.5,0,1,1,0,3H30a3,3,0,0,0-3.14,3.14,1.8,1.8,0,0,0,2.08,2,1.5,1.5,0,0,1,0,3Z" />
        <circle className="cls-1" cx="82.25" cy="51.81" r="6.48" />
        <path d="M85.49,67.1A1.5,1.5,0,0,1,84,65.94L77.55,38.36a1.5,1.5,0,1,1,2.91-.68l6.48,27.58a1.5,1.5,0,0,1-1.46,1.84Z" />
        <path d="M82.75,39.24h-7a1.5,1.5,0,0,1,0-3h7a1.5,1.5,0,1,1,0,3Z" />
        <path d="M89,67.37h-7a1.5,1.5,0,0,1,0-3h7a1.5,1.5,0,0,1,0,3Z" />
      </g>
    </svg>
  );
};

export default memo(Cycling);
