import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

import * as ImagesActions from '../../../redux/actions/cms/images';
import * as BonusActions from '../../../redux/actions/BonusSystem';
import * as LanguagesActions from '../../../redux/actions/Languages';
import * as CasinoManagerActions from '../../../redux/actions/cms/casinoManager';
import Tabs from './Tabs';
import GamesTable from './GameList/GamesTable';
import GameRanking from './Rankings/GameRanking';
import Modal from '../../../components/Modal/Modal';
import GameImages from './Form/GameImages/GameImages';
import CasinoManagerForm from './Form/CasinoManagerForm';
import FullScreenLoader from '../../../components/Layout/FullScreenLoader/FullScreenLoader';
import { gameObj } from './Form/CasinoManagerObj';
import { addNotificationMessage } from '../../../redux/actions/ui';
import {
  casinoManagerObj,
  imageObj,
  updatedCasinoGameObj,
  editGameCasinoObj,
  checkForGameId,
} from './CasinoManagerUtils';
import {
  getCMSData,
  getLanguages,
  createLoadingSelector,
  getBonusSystemData,
} from '../../../selectors';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { useMount } from '../../../hooks';

const CasinoManager = ({
  getAllLanguages,
  getBusinessUnits,
  getAllGames,
  allGames,
  getImages,
  images,
  loading,
  languages,
  businessUnits,
  gameProviders,
  getGameProviders,
  createGame,
  updateGame,
  uploadImage,
  deleteImage,
  deleteGame,
  updateRank,
}) => {
  const [currentTab, setCurrentTab] = useState('form');
  const [casinoGameObj, setCasinoGameObj] = useState(gameObj);
  const [imageUpload, setImageUpload] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [previewImage, setPreviewImage] = useState({});
  const [activeModal, setActiveModal] = useState(false);
  const [imageUploadPlatform, setImageUploadPlatform] = useState('');
  const [activeImageSize, setActiveImageSize] = useState('big');
  const [gameImages, setGameImages] = useState([]);
  const [gameTitle, setGameTitle] = useState('');
  const [editingGameId, setEditingGameId] = useState('');
  const [currentGameImages, setCurrentGameImages] = useState('');
  const [gameBu, setGameBu] = useState('BG');

  useMount(() => {
    getAllLanguages();
    getBusinessUnits();
    getAllGames(gameBu);
    getGameProviders();
  });

  const getImagesForGame = useCallback(() => {
    getImages('CASINO_GAME');
  }, [getImages]);

  const updateChanges = useCallback(casinoGameObj => {
    setCasinoGameObj(casinoGameObj);
  }, []);

  const setFilterType = useCallback(filter => {
    setCurrentTab(filter);
  }, []);

  const submitForm = useCallback(
    async e => {
      e.preventDefault();
      const casinoGameObjClone = { ...casinoGameObj };
      let emptyIdProviderType = false;
      let emptyName = false;
      let emptyBu = false;
      if (casinoGameObjClone.names.length !== 0) {
        casinoGameObjClone.names.map(name => {
          if (!name.name) {
            emptyName = true;
          }
          return emptyName;
        });
      } else {
        emptyName = true;
      }

      if (casinoGameObjClone.businessUnits.length !== 0) {
        casinoGameObjClone.businessUnits.map(bu => {
          if (!bu.businessUnit) {
            emptyBu = true;
          }
          return emptyBu;
        });
      } else {
        emptyBu = true;
      }

      if (emptyName)
        addNotificationMessage('Game names cannot be empty', 'error', 'Error');
      if (emptyBu)
        addNotificationMessage(
          'Game Business units cannot be empty',
          'error',
          'Error'
        );

      if (
        !casinoGameObjClone.provider ||
        !casinoGameObjClone.gameType ||
        !casinoGameObjClone.gameId
      ) {
        emptyIdProviderType = true;
        addNotificationMessage(
          `Game 
        ${
          !casinoGameObjClone.provider
            ? `Provider${
                casinoGameObjClone.gameType && casinoGameObjClone.gameId
                  ? ''
                  : ','
              }`
            : ''
        } 
        ${
          !casinoGameObjClone.gameType
            ? `Type${casinoGameObj.gameId ? '' : ','}`
            : ''
        } 
        ${!casinoGameObjClone.gameId ? 'ID' : ''} cannot be empty`,
          'error',
          'Error'
        );
      }

      if (!emptyName && !emptyIdProviderType) {
        const createCasinoObj = casinoManagerObj(
          casinoGameObjClone,
          allGames.length
        );
        const updateCasinoObj = updatedCasinoGameObj(casinoGameObjClone);
        const isGameIdUnique = checkForGameId(
          casinoGameObjClone.gameId,
          allGames
        );
        let submitted = false;
        if (!isGameIdUnique && !editMode) {
          addNotificationMessage(
            'This game ID already exist',
            'error',
            'Error'
          );
        } else {
          submitted = await createGame(
            !editMode ? createCasinoObj : updateCasinoObj,
            editMode,
            gameBu
          );
        }
        if (submitted) {
          setCasinoGameObj(gameObj);
          setEditMode(false);
        }
      }
    },
    [allGames, casinoGameObj, createGame, editMode, gameBu]
  );

  const updateGameRank = useCallback(
    changingGame => {
      updateRank(updatedCasinoGameObj(changingGame));
    },
    [updateRank]
  );

  const deleteCasinoGame = useCallback(
    gameId => {
      confirmAlert({
        title: 'Confirm to delete',
        message: 'Are you sure?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              deleteGame(gameId, gameBu);
            },
          },
          {
            label: 'No',
          },
        ],
      });
    },
    [deleteGame, gameBu]
  );

  const prepareImageUpload = useCallback((game, platform) => {
    setImageUpload(true);
    setEditingGameId(game.gameId);
    setImageUploadPlatform(platform);
    setCurrentGameImages(game.images);
  }, []);

  const finishUploadingImage = useCallback(
    (casinoGameObj, platform, imageSize) => {
      if (casinoGameObj[platform][imageSize] !== '') {
        const finalImageObj = imageObj(
          casinoGameObj[platform],
          editingGameId !== '' ? editingGameId : casinoGameObj.gameId,
          platform,
          imageSize
        );
        uploadImage(finalImageObj, platform, imageSize, gameBu);
      } else {
        addNotificationMessage(
          `You firstly need to add ${
            platform === 'webImage' ? 'web' : 'mobile'
          } ${imageSize} image.`,
          'error',
          'Error'
        );
      }
    },
    [editingGameId, gameBu, uploadImage]
  );

  const cancelImageUpload = useCallback(() => {
    const casinoGameObjClone = { ...casinoGameObj };
    casinoGameObjClone.webImage = { big: '', small: '' };
    casinoGameObjClone.mobileImage = { big: '', small: '' };
    setImageUpload(false);
    setCasinoGameObj(casinoGameObjClone);
  }, [casinoGameObj]);

  const updateGameStatus = useCallback(
    updatedGame => {
      updateGame(updatedCasinoGameObj(updatedGame), gameBu);
    },
    [gameBu, updateGame]
  );

  const editGame = useCallback(game => {
    window.scrollTo(0, 0);
    const editCasinoGameObj = editGameCasinoObj(game);
    setCasinoGameObj(editCasinoGameObj);
    setEditMode(true);
  }, []);

  const cancelEdit = useCallback(() => {
    setEditMode(false);
    setCasinoGameObj(gameObj);
  }, []);

  const seeImage = useCallback((images, gameName) => {
    setGameImages(images);
    setActiveModal(true);
    setGameTitle(gameName.en ? gameName.en.name : '');
  }, []);

  const closeModal = useCallback(() => {
    setActiveModal(false);
    setPreviewImage({});
  }, []);

  const switchImageSize = useCallback(
    (e, imageSize, platform) => {
      e.preventDefault();
      const casinoGameObjClone = { ...casinoGameObj };
      casinoGameObjClone[platform][imageSize] = '';
      setActiveImageSize(imageSize);
      setCasinoGameObj(casinoGameObjClone);
    },
    [casinoGameObj]
  );

  const previewGameImage = useCallback(image => {
    const previewImageObj = { url: image.url };
    setPreviewImage(previewImageObj);
  }, []);

  const deleteGameImage = useCallback(
    (id, index) => {
      confirmAlert({
        title: 'Confirm to delete',
        message: 'Are you sure?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              deleteImage(id, gameBu);
              const casinoGameObjClone = { ...casinoGameObj };
              casinoGameObjClone.images.splice(index, 1);
              setCasinoGameObj(casinoGameObjClone);
            },
          },
          {
            label: 'No',
          },
        ],
      });
    },
    [casinoGameObj, deleteImage, gameBu]
  );

  const getGamesByBusinessUnit = useCallback(
    bu => {
      getAllGames(bu);
      setGameBu(bu);
    },
    [getAllGames]
  );

  return (
    <>
      <Tabs setFilterType={setFilterType} currentTab={currentTab} />
      <Modal
        myClassName="full-size-img-modal"
        headerText={`Image for game ${gameTitle}`}
        isActive={activeModal}
        onClose={closeModal}
      >
        {Object.keys(previewImage).length > 0 ? (
          <>
            <img src={previewImage.url} alt="" className="full-size-img" />
            <hr />
          </>
        ) : null}
        <div className="container">
          <div className="row">
            {gameImages.map(image => {
              return (
                <div className="col-md" key={image.url}>
                  <img
                    src={image.url}
                    alt=""
                    className="game-image"
                    width="200"
                    onClick={() => previewGameImage(image)}
                  />
                  <p>{`${image.channel} - ${image.gameImageSize}`}</p>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
      <FullScreenLoader isActive={loading} />
      {currentTab === 'form' ? (
        <>
          <CasinoManagerForm
            images={images}
            languages={languages}
            loading={loading}
            imageUpload={imageUpload}
            getImages={getImagesForGame}
            casinoGameObj={casinoGameObj}
            submitForm={submitForm}
            editMode={editMode}
            gameProviders={gameProviders}
            finishUploadingImage={finishUploadingImage}
            deleteImage={deleteGameImage}
            cancelEdit={cancelEdit}
            updateChanges={updateChanges}
          />
          <GamesTable
            allGames={allGames}
            casinoGameObj={casinoGameObj}
            updateGameStatus={updateGameStatus}
            deleteGame={deleteCasinoGame}
            previewImage={seeImage}
            prepareImageUpload={prepareImageUpload}
            editGame={editGame}
            editMode={editMode}
            getGamesByBusinessUnit={getGamesByBusinessUnit}
            languages={languages}
          />
          <GameImages
            images={images}
            loading={loading}
            imageUpload={imageUpload}
            cancelImageUpload={cancelImageUpload}
            finishUploadingImage={finishUploadingImage}
            getImages={getImagesForGame}
            imageUploadPlatform={imageUploadPlatform}
            activeImageSize={activeImageSize}
            casinoGameObj={casinoGameObj}
            switchImageSize={switchImageSize}
            currentGameImages={currentGameImages}
            updateChanges={updateChanges}
          />
        </>
      ) : (
        <GameRanking
          allGames={allGames}
          casinoGameObj={casinoGameObj}
          updateGameStatus={updateGameStatus}
          deleteGame={deleteCasinoGame}
          prepareImageUpload={prepareImageUpload}
          editGame={editGame}
          editMode={editMode}
          businessUnits={businessUnits}
          getGamesByBusinessUnit={getGamesByBusinessUnit}
          updateRank={updateGameRank}
        />
      )}
    </>
  );
};

CasinoManager.propTypes = {
  images: PropTypes.array,
  languages: PropTypes.array,
  loading: PropTypes.bool,
  casinoGameObj: PropTypes.object,
  submitForm: PropTypes.func,
  updateChanges: PropTypes.func,
};

const loadingReducer = createLoadingSelector([
  'GET_IMAGES',
  'IMAGE_UPLOAD',
  'GAME_UPDATE',
  'GET_ALL_GAMES',
]);

const mapStateToProps = state => ({
  languages: getLanguages(state).languages,
  businessUnits: getBonusSystemData(state).businessUnits,
  images: getCMSData(state).imagesReducer.images,
  loading: loadingReducer(state),
  allGames: getCMSData(state).casinoManagerReducer.allGames,
  gameProviders: getCMSData(state).casinoManagerReducer.gameProviders,
});

const mapDispatchToProps = {
  getBusinessUnits: BonusActions.getBusinessUnits,
  getAllLanguages: LanguagesActions.getAllLanguages,
  getImages: ImagesActions.getImages,
  addNotificationMessage,
  createGame: CasinoManagerActions.createGame,
  getAllGames: CasinoManagerActions.getAllGames,
  deleteGame: CasinoManagerActions.deleteGame,
  uploadImage: CasinoManagerActions.uploadImage,
  updateGame: CasinoManagerActions.updateGame,
  updateRank: CasinoManagerActions.updateRank,
  getGameProviders: CasinoManagerActions.getGameProviders,
  deleteImage: CasinoManagerActions.deleteImage,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CasinoManager)
);
