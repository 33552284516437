import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { DateUtils } from 'react-day-picker';
import { useUpdate } from '../../hooks';

import classes from './Sidebar.module.scss';
import Button from '../Layout/Button';
import CalendarDayPicker from '../CalendarDayPicker/CalendarDayPicker';
import { IconCalendar } from '../Icons/UI/ui/IconCalendar';

const FiltersRow = ({ setScope, setStartDate, setEndDate }) => {
  const [selectedStatus, setSelectedStatus] = useState('');
  // Prematch Calendar
  const [openCalendar, setOpenCalendar] = useState(false);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  useUpdate(() => {
    setScope(selectedStatus);
    setStartDate(
      from
        ? moment(from)
            .startOf('day')
            .utc(true)
            .unix()
        : null
    );
    setEndDate(
      to
        ? moment(to)
            .endOf('day')
            .utc(true)
            .unix()
        : null
    );
  }, [selectedStatus, from, to]);

  // Prematch Calendar
  const handleDayClick = useCallback(
    day => {
      const range = DateUtils.addDayToRange(day, { from, to });
      if (range.from && range.to) {
        setOpenCalendar(false);
      }
      setFrom(
        moment(range.from)
          .startOf('day')
          .utc(true)
          .toDate()
      );
      setTo(
        moment(range.to)
          .endOf('day')
          .utc(true)
          .toDate()
      );
    },
    [from, to]
  );

  return (
    <div className={classes.filtersRow}>
      {from && to ? (
        <div className={classes.selectedDate}>
          <div className={classes.date}>
            <span></span>
            {moment(from).format('DD/MM/YYYY')}
            {' - '}
            {moment(to).format('DD/MM/YYYY')}
            <IconCalendar
              onClick={() => {
                setOpenCalendar(!openCalendar);
              }}
              width={13}
              className="ml-1"
            />
            {openCalendar ? (
              <CalendarDayPicker
                handleDayClick={handleDayClick}
                from={from}
                to={to}
              />
            ) : null}
          </div>
          <Button
            cssClass={classes.clear}
            onClick={() => {
              setOpenCalendar(false);
              setFrom(null);
              setTo(null);
            }}
          >
            Clear
          </Button>
        </div>
      ) : (
        <div className={classes.statusGroup}>
          <Button
            onClick={() => setSelectedStatus('')}
            cssClass={selectedStatus === '' ? classes.active : ''}
          >
            All
          </Button>
          <Button
            onClick={() => setSelectedStatus('prematch')}
            cssClass={selectedStatus === 'prematch' ? classes.active : ''}
          >
            PRE
            <IconCalendar
              onClick={() => {
                setOpenCalendar(!openCalendar);
              }}
              width={13}
              className="ml-1"
            />
            {openCalendar ? (
              <CalendarDayPicker
                handleDayClick={handleDayClick}
                from={from}
                to={to}
              />
            ) : null}
          </Button>
          <Button
            onClick={() => setSelectedStatus('live')}
            cssClass={selectedStatus === 'live' ? classes.active : ''}
          >
            <div className={classes.live} />
            LIVE
          </Button>
        </div>
      )}
    </div>
  );
};

export default FiltersRow;
