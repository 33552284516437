import React, { useState } from 'react';

import classes from './Table.module.scss';
import Pagination from './Pagination';
import ColumnFilterDialog from './ColumnFilterDialog/ColumnFilterDialog';
import { useUpdate } from '../../hooks';

const Table = ({
  columns,
  data,
  rowClassName,
  onRowClick,
  onRowContextMenu,
  defaultPageSize = 10,
  defaultCurrentPage = 1,
  pagination = true,
}) => {
  const [tableData, setTableData] = useState(data);
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [openColumnFilterDialog, setOpenColumnFilterDialog] = useState(null);

  useUpdate(() => {
    if (tableData) {
      setTableData(data);
    }
  }, [data]);

  return (
    <div className="position-relative">
      <table className={classes.table}>
        <thead>
          <tr>
            {columns.map((col, colIndex) => (
              <th
                key={colIndex}
                onClick={
                  col.type === 'columnFilter'
                    ? e => {
                        openColumnFilterDialog
                          ? setOpenColumnFilterDialog(null)
                          : setOpenColumnFilterDialog({
                              coords: e.target.getBoundingClientRect(),
                              options: col.options,
                              activeOptions: col.activeOptions,
                              onColumnFilterChange: col.onColumnFilterChange,
                            });
                      }
                    : col.onClick
                }
                className={`py-1 ${classes.col} ${
                  col.type === 'columnFilter' ? classes.buttonCol : ''
                } ${col.active === false ? classes.inactiveCol : ''}`}
              >
                {col.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={classes.tableBody}>
          {tableData.length
            ? (pagination
                ? tableData.slice(
                    (currentPage - 1) * pageSize,
                    currentPage * pageSize
                  )
                : tableData
              ).map((row, index) => (
                <tr
                  key={row.id || index}
                  onClick={() => onRowClick && onRowClick(row)}
                  onContextMenu={e => {
                    if (onRowContextMenu) {
                      e.preventDefault();
                      onRowContextMenu(e, row);
                    }
                  }}
                  className={`${classes.row} ${
                    rowClassName ? rowClassName(row) : ''
                  } ${onRowClick ? 'pointer' : ''}`}
                >
                  {columns.map((col, colIndex) => (
                    <td
                      key={colIndex}
                      className={`${
                        col.type === 'columnFilter' ? 'border-0' : ''
                      }`}
                    >
                      {col.display ? col.display(row) : ''}
                    </td>
                  ))}
                </tr>
              ))
            : null}
        </tbody>
      </table>

      {openColumnFilterDialog ? (
        <ColumnFilterDialog
          coords={openColumnFilterDialog.coords}
          activeOptions={openColumnFilterDialog.activeOptions}
          options={openColumnFilterDialog.options}
          onColumnFilterChange={openColumnFilterDialog.onColumnFilterChange}
        />
      ) : null}

      {pagination && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          dataLength={data.length}
        />
      )}
    </div>
  );
};

export default Table;
