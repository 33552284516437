export const pageObj = {
  enabled: {
    bg: {
      enabled: true,
    },
    en: {
      enabled: true,
    },
    gr: {
      enabled: true,
    },
    ro: {
      enabled: true,
    },
    tr: {
      enabled: true,
    },
  },
  name: '',
  subpages: [
    {
      content: [
        {
          bg: {
            enabled: true,
            html: '',
            rules: [
              {
                description: '',
                name: '',
                title: '',
              },
            ],
            sections: [
              {
                headerTitle: '',
                htmlBody: '',
                name: '',
              },
            ],
            tables: [
              {
                name: '',
                table: [[''], [''], ['']],
              },
            ],
            subpages: [
              {
                enabled: true,
                name: '',
                pageType: 'TABLES',
                tables: [
                  {
                    name: '',
                    table: [[''], [''], ['']],
                  },
                ],
              },
            ],
          },
          en: {
            enabled: true,
            html: '',
            rules: [
              {
                description: '',
                name: '',
                title: '',
              },
            ],
            sections: [
              {
                headerTitle: '',
                htmlBody: '',
                name: '',
              },
            ],
            tables: [
              {
                name: '',
                table: [[''], [''], ['']],
              },
            ],
            subpages: [
              {
                enabled: true,
                name: '',
                pageType: 'TABLES',
                tables: [
                  {
                    name: '',
                    table: [[''], [''], ['']],
                  },
                ],
              },
            ],
          },
          gr: {
            enabled: true,
            html: '',
            rules: [
              {
                description: '',
                name: '',
                title: '',
              },
            ],
            sections: [
              {
                headerTitle: '',
                htmlBody: '',
                name: '',
              },
            ],
            tables: [
              {
                name: '',
                table: [[''], [''], ['']],
              },
            ],
            subpages: [
              {
                enabled: true,
                name: '',
                pageType: 'TABLES',
                tables: [
                  {
                    name: '',
                    table: [[''], [''], ['']],
                  },
                ],
              },
            ],
          },
          ro: {
            enabled: true,
            html: '',
            rules: [
              {
                description: '',
                name: '',
                title: '',
              },
            ],
            sections: [
              {
                headerTitle: '',
                htmlBody: '',
                name: '',
              },
            ],
            tables: [
              {
                name: '',
                table: [[''], [''], ['']],
              },
            ],
            subpages: [
              {
                enabled: true,
                name: '',
                pageType: 'TABLES',
                tables: [
                  {
                    name: '',
                    table: [[''], [''], ['']],
                  },
                ],
              },
            ],
          },
          tr: {
            enabled: true,
            html: '',
            rules: [
              {
                description: '',
                name: '',
              },
            ],
            sections: [
              {
                headerTitle: '',
                htmlBody: '',
                name: '',
              },
            ],
            tables: [
              {
                name: '',
                table: [[''], [''], ['']],
              },
            ],
            subpages: [
              {
                enabled: true,
                name: '',
                pageType: 'TABLES',
                tables: [
                  {
                    name: '',
                    table: [[''], [''], ['']],
                  },
                ],
              },
            ],
          },
        },
      ],
      pageType: '',
      name: '',
    },
  ],
};

export const subPagePushData = () => ({
  content: [
    {
      bg: {
        enabled: true,
        html: '',
        rules: [
          {
            description: '',
            name: '',
            title: '',
          },
        ],
        sections: [
          {
            headerTitle: '',
            htmlBody: '',
            name: '',
          },
        ],
        tables: [
          {
            name: '',
            table: [[''], [''], ['']],
          },
        ],
        subpages: [
          {
            enabled: true,
            name: '',
            pageType: 'TABLES',
            tables: [
              {
                name: '',
                table: [[''], [''], ['']],
              },
            ],
          },
        ],
      },
      en: {
        enabled: true,
        html: '',
        rules: [
          {
            description: '',
            name: '',
            title: '',
          },
        ],
        sections: [
          {
            headerTitle: '',
            htmlBody: '',
            name: '',
          },
        ],
        tables: [
          {
            name: '',
            table: [[''], [''], ['']],
          },
        ],
        subpages: [
          {
            enabled: true,
            name: '',
            pageType: 'TABLES',
            tables: [
              {
                name: '',
                table: [[''], [''], ['']],
              },
            ],
          },
        ],
      },
      gr: {
        enabled: true,
        html: '',
        rules: [
          {
            description: '',
            name: '',
            title: '',
          },
        ],
        sections: [
          {
            headerTitle: '',
            htmlBody: '',
            name: '',
          },
        ],
        tables: [
          {
            name: '',
            table: [[''], [''], ['']],
          },
        ],
        subpages: [
          {
            enabled: true,
            name: '',
            pageType: 'TABLES',
            tables: [
              {
                name: '',
                table: [[''], [''], ['']],
              },
            ],
          },
        ],
      },
      ro: {
        enabled: true,
        html: '',
        rules: [
          {
            description: '',
            name: '',
            title: '',
          },
        ],
        sections: [
          {
            headerTitle: '',
            htmlBody: '',
            name: '',
          },
        ],
        tables: [
          {
            name: '',
            table: [[''], [''], ['']],
          },
        ],
        subpages: [
          {
            enabled: true,
            name: '',
            pageType: 'TABLES',
            tables: [
              {
                name: '',
                table: [[''], [''], ['']],
              },
            ],
          },
        ],
      },
      tr: {
        enabled: true,
        html: '',
        rules: [
          {
            description: '',
            name: '',
            title: '',
          },
        ],
        sections: [
          {
            headerTitle: '',
            htmlBody: '',
            name: '',
          },
        ],
        tables: [
          {
            name: '',
            table: [[''], [''], ['']],
          },
        ],
        subpages: [
          {
            enabled: true,
            name: '',
            pageType: 'TABLES',
            tables: [
              {
                name: '',
                table: [[''], [''], ['']],
              },
            ],
          },
        ],
      },
    },
  ],
  pageType: '',
  name: '',
});

export const sectionsContent = {
  name: '',
  headerTitle: '',
  htmlBody: '',
};

export const rules = [
  {
    title: '',
    description: '',
    name: '',
  },
];

export const sections = [
  {
    headerTitle: '',
    htmlBody: '',
    name: '',
  },
];

export const tableSubpage = {
  enabled: true,
  name: '',
  pageType: 'TABLES',
  tables: [
    {
      name: '',
      table: [[''], [''], ['']],
    },
  ],
};
