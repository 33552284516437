import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Switch from '../../../../components/Form/Switch/Switch';
import BusinessUnitSelector from '../../../../components/BusinessUnitSelector/BusinessUnitSelector';
import { useUpdate, useStateForSelect } from '../../../../hooks';

const GameBu = ({ casinoGameObj, updateChanges, editMode }) => {
  const [businessUnits, setBusinessUnits] = useStateForSelect([]);
  const [casinoGame, setCasinoGame] = useState(casinoGameObj);
  const [editModeInitialLoad, setEditModeInitialLoad] = useState(false);

  useUpdate(() => {
    if (editMode) setEditModeInitialLoad(true);
  }, [editMode]);

  useUpdate(() => {
    setCasinoGame(casinoGameObj);
    if (editModeInitialLoad && casinoGameObj.businessUnits.length > 0) {
      const gameBusinessUnits = casinoGameObj.businessUnits.reduce(
        (acc, bu) => {
          return [...acc, { value: bu.businessUnit, label: bu.businessUnit }];
        },
        []
      );
      setBusinessUnits(gameBusinessUnits);
    }
    if (!editMode && casinoGameObj.businessUnits.length === 0) {
      setBusinessUnits([]);
    }
  }, [casinoGameObj, editModeInitialLoad, editMode]);

  useUpdate(() => {
    if (!editModeInitialLoad && businessUnits && businessUnits.length) {
      handleInputChange(businessUnits);
    }
    if (!businessUnits) {
      const casinoGameClone = { ...casinoGameObj };
      casinoGameClone.businessUnits = [];
      setCasinoGame(casinoGameClone);
      updateChanges(casinoGameClone);
    }
    setEditModeInitialLoad(false);
  }, [businessUnits]);

  const handleInputChange = useCallback(
    e => {
      let businessUnits = e;
      const casinoGameObjClone = { ...casinoGame };
      if (businessUnits) {
        if (casinoGameObjClone.businessUnits.length > businessUnits.length) {
          casinoGameObjClone.businessUnits = casinoGameObjClone.businessUnits.filter(
            entry1 => {
              return businessUnits.some(entry2 => {
                return entry1.businessUnit === entry2.value;
              });
            }
          );
        } else {
          businessUnits.forEach(bu => {
            const duplicatedBuIndex = casinoGameObjClone.businessUnits.findIndex(
              x => x.businessUnit === bu.value
            );
            if (duplicatedBuIndex === -1) {
              casinoGameObjClone.businessUnits = [
                ...casinoGameObjClone.businessUnits,
                { businessUnit: bu.value, enabled: false },
              ];
            }
            return casinoGameObjClone;
          });
        }
      } else {
        casinoGameObjClone.businessUnits = [];
        businessUnits = [];
        updateChanges(casinoGameObjClone);
      }
      setCasinoGame(casinoGameObjClone);
      updateChanges(casinoGameObjClone);
    },
    [casinoGame, updateChanges]
  );

  const changeBuStatus = e => {
    const casinoGameObjClone = { ...casinoGame };
    const { name, checked } = e.target;
    casinoGameObjClone.businessUnits.find(
      x => x.businessUnit === name
    ).enabled = checked;
    setCasinoGame(casinoGameObjClone);
    updateChanges(casinoGameObjClone);
  };

  return (
    <>
      <BusinessUnitSelector
        onChange={setBusinessUnits}
        defaultBu={businessUnits}
        isMulti
      />
      <br />
      {businessUnits
        ? businessUnits.map(bu => {
            const casinoGameClone = { ...casinoGame };
            const buObj =
              casinoGameClone.businessUnits.length > 0
                ? casinoGameClone.businessUnits.find(
                    x => x.businessUnit === bu.value
                  )
                : '';
            return (
              <Switch
                key={bu.value}
                onChange={changeBuStatus}
                name={bu.value}
                isChecked={
                  casinoGameClone.businessUnits.length > 0 && buObj
                    ? buObj.enabled
                    : false
                }
                value={
                  casinoGameClone.businessUnits.length > 0 && buObj
                    ? buObj.enabled
                    : false
                }
                label={`Enabled ${bu.value}`}
              />
            );
          })
        : []}
    </>
  );
};

GameBu.propTypes = {
  casinoGameObj: PropTypes.object,
  updateChanges: PropTypes.func,
};

export default GameBu;
