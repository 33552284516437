import React from 'react';

export const CMS = (props) => {
    return (
		<span className='efb-icon'>
			<svg className={`efb-icon--${props.size} efb-cms`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
				<path d="M31.1,0H9.8c-1,0-1.9,0.8-1.9,1.9v2.3h-3C3.9,4.2,3,5,3,6.1v28.1c0,1,0.8,1.9,1.9,1.9h21.3c1,0,1.9-0.8,1.9-1.9v-2.3h3
					c1,0,1.9-0.8,1.9-1.9V1.9C33,0.8,32.1,0,31.1,0z M26,33.4c0,0.3-0.2,0.5-0.5,0.5H5.8c-0.4,0-0.7-0.3-0.7-0.7V7
					c0-0.4,0.3-0.7,0.7-0.7h2.2v23.7c0,1,0.8,1.9,1.9,1.9H26L26,33.4L26,33.4z M30.9,29.2c0,0.3-0.2,0.5-0.5,0.5H10.5
					c-0.3,0-0.5-0.2-0.5-0.5V2.6c0-0.3,0.2-0.5,0.5-0.5h19.9c0.3,0,0.5,0.2,0.5,0.5L30.9,29.2L30.9,29.2z"/>
				<path d="M28,6.4H12.9c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1H28c0.6,0,1-0.5,1-1C29,6.8,28.6,6.4,28,6.4z"/>
				<path d="M28,11.3H12.9c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1H28c0.6,0,1-0.5,1-1C29,11.7,28.6,11.3,28,11.3z"/>
				<path d="M28,16.2H12.9c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1H28c0.6,0,1-0.5,1-1C29,16.6,28.6,16.2,28,16.2z"/>
				<path d="M28,21H12.9c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1H28c0.6,0,1-0.5,1-1C29,21.5,28.6,21,28,21z"/>
			</svg>
			{props.children}
		</span>
    )
}

export default CMS;