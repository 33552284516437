import React from 'react';
import Select from 'react-select';
import { selectStyles } from '../../../containers/CMS/customReactSelectStyle';
import Button from '../../Layout/Button';

const SystemSettingsRow = ({
  tableData,
  suspendOptions,
  onChangeSuspendDropdown,
  feedProviders,
  onChangeSystemDropDown,
  isEditMode,
  onDelete,
}) => (
  <>
    {tableData.length !== 0 ? (
      tableData.map(data => (
        <tr key={data.id}>
          <td>{data.targetName}</td>
          <td>
            <Select
              id="suspend"
              name="suspend"
              styles={selectStyles}
              isDisabled={!isEditMode}
              onChange={value => onChangeSuspendDropdown(value, data.id)}
              options={suspendOptions}
              value={suspendOptions.find(
                opt =>
                  opt.value.sort().join(',') ===
                  data.feedScopes.sort().join(',')
              )}
              defaultValue={suspendOptions[0]}
            />
          </td>
          <td>
            <Select
              name="id"
              styles={selectStyles}
              isDisabled={!isEditMode}
              options={feedProviders}
              value={
                data.feedProvider
                  ? feedProviders.find(feed => feed.id === data.feedProvider.id)
                  : feedProviders[0]
              }
              onChange={value => onChangeSystemDropDown(value, data.id)}
              defaultValue={feedProviders[0]}
            />
          </td>
          <td>
            {isEditMode && (
              <Button
                onClick={() => onDelete(data.id)}
                cssClass="btn btn-danger btn-xs"
              >
                <i className="fas fa-trash" /> Delete
              </Button>
            )}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td>No transactions found.</td>
      </tr>
    )}
  </>
);

export default React.memo(SystemSettingsRow);
