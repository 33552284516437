import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useUpdate } from '../../../../hooks';
import InputField from '../../../../components/Form/InputField';

const CompetitorName = ({ competitorsObj, setCompetitorsObj, languages }) => {
  const [competitorNames, setCompetitorNames] = useState({});

  useUpdate(() => {
    setCompetitorNames(competitorsObj.names);
  }, [competitorsObj]);

  const handleInputChange = useCallback(
    e => {
      const { name, value } = e.target;
      const competitorNamesClone = { ...competitorNames };
      competitorNamesClone[name] = {
        ...competitorNamesClone[name],
        name: value,
      };
      setCompetitorNames(competitorNamesClone);
      setCompetitorsObj({
        ...competitorsObj,
        names: competitorNamesClone,
      });
    },
    [competitorNames, competitorsObj, setCompetitorsObj]
  );

  return (
    <>
      <h3 className="efb-h3">Competitor name</h3>

      <div className="form-row">
        {languages.map((lang, idx) => {
          return (
            <div className="form-label-group col-md" key={'c' + idx}>
              <InputField
                type="text"
                value={
                  competitorNames[lang.languageCode]
                    ? competitorNames[lang.languageCode].name
                    : ''
                }
                placeholder="Sport Name"
                onChange={handleInputChange}
                name={lang.languageCode}
                label={
                  <i
                    className={`flag-icon flag-icon-${
                      lang.languageCode === 'en' ? 'gb' : lang.languageCode
                    }`}
                  ></i>
                }
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

CompetitorName.propTypes = {
  competitorNames: PropTypes.object,
  updateObj: PropTypes.func,
};

export default CompetitorName;
