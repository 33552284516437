import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';

import * as LanguagesActions from '../../../redux/actions/Languages';
import Tabs from './Tabs';
import Sports from './Sports/Sports';
import Categories from './Categories/Categories';
import Tournaments from './Tournaments/Tournaments';
import Seasons from './Seasons/Seasons';
import { getLanguages } from '../../../selectors';
import { useMount } from '../../../hooks';

const StsBuilder = ({ getAllLanguages, languages }) => {
  const [currentTab, setCurrentTab] = useState('sports');

  useMount(() => {
    getAllLanguages();
  });

  const availableTabs = useMemo(
    () => ({
      sports: <Sports languages={languages} />,
      categories: <Categories languages={languages} />,
      tournaments: <Tournaments languages={languages} />,
      seasons: <Seasons languages={languages} />,
    }),
    [languages]
  );

  return (
    <>
      <Tabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        availableTabs={Object.keys(availableTabs)}
      />
      <div className="mt-4 efb-container">
        {languages.length ? availableTabs[currentTab] : null}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  languages: getLanguages(state).languages,
});

const mapDispatchToProps = {
  getAllLanguages: LanguagesActions.getAllLanguages,
};

export default connect(mapStateToProps, mapDispatchToProps)(StsBuilder);
