import { FETCH_STREAMING_EVENTS, GET_MAPPED_EVENTS } from '../actions/LiveStreaming';

const initialState = {
  streamingEvents: [],
  mappedEvents: []
};

export const liveStreamingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MAPPED_EVENTS.SUCCESS:
      return {
        ...state,
        mappedEvents: action.payload
      };
    case FETCH_STREAMING_EVENTS.SUCCESS:
      return {
        ...state,
        streamingEvents: action.payload
      };
    default:
      return state;
  }
};
