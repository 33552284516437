import React from 'react';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Layout/Button';

const DeleteModal = ({ onClose, marketTypeConfig, deleteMarketTypeConfig }) => {
  return (
    <Modal isActive={true} onClose={onClose} headerText="Delete config">
      <h3 className="efb-h3 justify-content-center">
        Are you sure you want to delete?
      </h3>
      <p className="text-center">
        Name: <strong>{marketTypeConfig.name}</strong>
      </p>
      <section className="efb-modal-permissions__toolbar">
        <div className="efb-modal-permissions__toolbar__button-container">
          <Button onClick={deleteMarketTypeConfig} cssClass="efb-btn__link">
            YES
          </Button>
          <Button onClick={onClose} cssClass="efb-btn__link">
            NO
          </Button>
        </div>
      </section>
      <div className="efb-modal-permissions__decorator" />
    </Modal>
  );
};

export default DeleteModal;
