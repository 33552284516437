import React from 'react';
import Input from '../Input';

const InputIcon = ({
  iconName,
  name,
  type,
  disabled,
  value,
  placeholder,
  onChange,
}) => {
  return (
    <div className="input-icon">
      <i className={`fa fa-${iconName}`}></i>
      <Input
        className="icon-input-field"
        name={name}
        type={type}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        customInput
      />
    </div>
  );
};

export default InputIcon;
