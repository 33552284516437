import React from 'react';

export const Alert = (props) => {
    return (
		<span className='efb-icon'>
			<svg className={`efb-icon--${props.size} efb-alert`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
				<path d="M33.6,29.6c-3-3-4.6-6.6-4.6-10.7v-5.9c0-4.5-3-7.5-5.6-8.7c-0.7-0.3-1.5-0.6-2.3-0.8V3.3c0-1.8-1.5-3.3-3.3-3.3
					c-1.8,0-3.3,1.5-3.3,3.3v0.4c-0.7,0.2-1.4,0.4-2.1,0.7c-2.9,1.3-5.8,4.5-5.8,8.6V19c0,4.2-1.5,7.8-4.4,10.7
					c-0.2,0.2-0.2,0.4-0.2,0.7c0.1,0.2,0.3,0.4,0.5,0.5l6.2,1.1c1.2,0.2,2.2,0.4,3.2,0.5c1.1,2.2,3.4,3.7,5.8,3.7s4.7-1.4,5.8-3.7
					c1-0.1,2.1-0.3,3.2-0.5l6.2-1.1c0.2,0,0.4-0.2,0.5-0.5C33.9,30.1,33.8,29.8,33.6,29.6z M16.3,3.3c0-1,0.7-1.8,1.6-1.8
					c0.9,0,1.6,0.8,1.6,1.8v0.1c-1-0.2-2.1-0.2-3.2,0L16.3,3.3L16.3,3.3z M18,34c-1,0-2-0.6-2.6-1.5c0.9,0.1,1.8,0.2,2.6,0.2
					c0.9,0,1.7-0.1,2.6-0.2C20,33.4,19,34,18,34z M25.8,29.7c-1.1,0.2-2.1,0.4-3.1,0.5l0,0c-0.4,0.1-0.8,0.1-1.2,0.1h0
					c-0.4,0-0.7,0.1-1.1,0.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-0.9,0.1c-0.1,0-0.1,0-0.2,0c-0.7,0-1.4,0-2.2,0c-0.1,0-0.1,0-0.2,0
					c-0.3,0-0.6,0-0.9-0.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7-0.1-1.1-0.1c0,0,0,0,0,0c-0.4,0-0.8-0.1-1.2-0.1c0,0,0,0,0,0
					c-1-0.1-2-0.3-3.1-0.5l-4.5-0.8c2.3-2.8,3.5-6.2,3.5-10v-5.5c0-3.3,2.3-5.9,4.5-7c0.7-0.3,1.3-0.6,2-0.7c0,0,0,0,0,0
					c1.4-0.3,3-0.4,4.4,0c0,0,0,0,0,0c0.8,0.2,1.5,0.4,2.2,0.8c2.1,1,4.3,3.6,4.3,7v5.5c0,3.7,1.3,7.1,3.7,10L25.8,29.7z"/>
			</svg>
			{props.children}
		</span>
    )
}

export default Alert;