import React, { memo } from 'react';

export const Tennis = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Tennis ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141 141"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <circle className={'Ball'} cx="70.5" cy="70.5" r="70.5" />
        <path
          className={'Stripe'}
          d="M83.38,106.62c-.21.15-.4.3-.57.44A45.61,45.61,0,1,1,55.53,24.88,45.26,45.26,0,0,1,81.78,33.2l.19.14c.57.46,1.21.94,1.92,1.47l1,1h.4a53.77,53.77,0,0,0,30.41,9.35,53.6,53.6,0,0,0,19.14-3.49,70.26,70.26,0,0,0-4-7.58,45,45,0,0,1-15.18,2.59,45.28,45.28,0,0,1-26.32-8.37l-2-1.53L87,26.45l-.29-.14a54.09,54.09,0,1,0-31.2,98.28,53.58,53.58,0,0,0,32.36-10.76l.09-.07.46-.35a45.71,45.71,0,0,1,42.42-6.48,70.09,70.09,0,0,0,4-7.58,54.21,54.21,0,0,0-51.45,7.26Z"
        />
      </g>
    </svg>
  );
};

export default memo(Tennis);
