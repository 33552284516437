import React, { useState, useCallback, useMemo } from 'react';
import Modal from '../../../../../components/Modal/Modal';
import Table from '../../../../../components/NewTable/Table';
import classes from './UserFinder.module.scss';
import { useMount, useUpdate, useStateForInput } from '../../../../../hooks';
import Spinner from '../../../../../components/Layout/Spinner/Spinner';
import Button from '../../../../../components/Layout/Button';
import InputField from '../../../../../components/Form/InputField';

const UserFinder = ({
  activeUserFinderModal,
  getCustomersWithFilters,
  customers,
  closeModal,
  customersLoaded,
  setUser,
  getCustomersWithDebounce,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [username, setUsername] = useStateForInput('');

  useMount(() => {
    getCustomersWithFilters();
  });

  useUpdate(() => {
    getCustomersWithDebounce(username);
  }, [username]);

  useUpdate(() => {
    setCurrentPage(customers.number);
    setTotalPages(customers.totalPages);
  }, [customers]);

  const columns = useMemo(
    () => [
      { name: 'User ID', display: customer => customer.user?.id },
      { name: 'Username', display: customer => customer.user?.username },
      { name: 'First name', display: customer => customer.firstName },
      { name: 'Last name', display: customer => customer.lastName },
      {
        name: 'Action',
        display: customer => {
          return (
            <Button
              cssClass={'efb-btn__main'}
              onClick={() => {
                closeModal();
                setUser(customer);
              }}
            >
              Select user
            </Button>
          );
        },
      },
    ],
    [closeModal, setUser]
  );

  const infiniteScrollHandler = useCallback(
    async e => {
      if (
        e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        currentPage < totalPages
      ) {
        try {
          await getCustomersWithFilters({}, currentPage + 1);
        } catch (err) {
          setTotalPages(2);
        }
      }
    },
    [currentPage, getCustomersWithFilters, totalPages]
  );

  return (
    <Modal
      headerText="Find user"
      onClose={closeModal}
      isActive={activeUserFinderModal}
    >
      <div className={classes.searchWrapper}>
        <div className="efb-form">
          <InputField
            placeholder={'Find by username'}
            onChange={setUsername}
            value={username}
          />
          <small>
            <i className="fa fa-info-circle"></i> You need to type exact
            username to get result.
          </small>
        </div>
      </div>
      <div className={classes.results}>
        <div onScroll={infiniteScrollHandler} className={classes.table}>
          <Table
            columns={columns}
            data={customers.content}
            pagination={false}
          />
          {customersLoaded ? <Spinner size={20} active /> : null}
        </div>
      </div>
    </Modal>
  );
};

export default UserFinder;
