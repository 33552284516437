import React from 'react';
import { Button } from '../../../components/Layout';

class ManageBetButton extends React.PureComponent {
  removeTopBet = () => {
    this.props.removeTopBet(this.props.seasonId);
  };

  addTopBet = () => {
    this.props.addTopBet(this.props.seasonId);
  };

  render() {
    const { topBets, seasonId } = this.props;
    return (
      <>
        {topBets.find(bet => bet.highlightId === seasonId) ? (
          <Button
            onClick={this.removeTopBet}
            cssClass="btn btn-danger btn-md mr-3"
          >
            Remove from Top Bets
          </Button>
        ) : (
          <Button
            onClick={this.addTopBet}
            cssClass="btn btn-secondary btn-md mr-3"
          >
            Add to Top Bets
          </Button>
        )}
      </>
    );
  }
}

export default ManageBetButton;
