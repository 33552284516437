import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import * as UsersActions from '../../../../redux/actions/usersApi';
import Modal from '../../../../components/Modal/Modal';
import InputWithLabel from '../../../../components/Form/InputWithLabel/InputWithLabel';
import { useStateForInput, useStateForSwitch } from '../../../../hooks';
import Switch from '../../../../components/Form/Switch/Switch';
import Button from '../../../../components/Layout/Button';

const CreateUserModal = ({ isActive, onClose, createUser }) => {
  const [username, setUsername, clearUsername] = useStateForInput('');
  const [password, setPassword, clearPassword] = useStateForInput('');
  const [description, setDescription, clearDescription] = useStateForInput('');
  const [userActive, setUserActive, resetUserActive] = useStateForSwitch(true);

  const clearForm = useCallback(() => {
    clearUsername();
    clearPassword();
    clearDescription();
    resetUserActive();
  }, [clearDescription, clearPassword, clearUsername, resetUserActive]);

  const handleSubmit = useCallback(async () => {
    try {
      await createUser({ username, password, description, active: userActive });
      onClose();
    } catch (err) {}
  }, [createUser, description, onClose, password, userActive, username]);

  return (
    <Modal isActive={isActive} onClose={onClose} headerText="Create user">
      <div className="efb-modal__content">
        <div className="form-row">
          <div className="col">
            <InputWithLabel
              label="Username:"
              type="text"
              onChange={setUsername}
              value={username}
              name="username"
              required={true}
            />
          </div>
          <div className="col">
            <InputWithLabel
              label="Password:"
              type="password"
              onChange={setPassword}
              value={password}
              name="password"
              required={true}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col">
            <InputWithLabel
              label="Description:"
              type="text"
              onChange={setDescription}
              value={description}
              name="description"
              required={true}
            />
          </div>
        </div>
        <div className="form-row">
          <Switch
            label={userActive ? 'Active' : 'Inactive'}
            isChecked={userActive}
            onChange={setUserActive}
            name="userActive"
          />
        </div>
      </div>
      <div className="efb-modal__footer">
        <Button onClick={clearForm} cssClass="efb-btn__link">
          Clear
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!username || !password}
          cssClass="efb-btn__main"
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  createUser: UsersActions.createUser,
};

export default connect(null, mapDispatchToProps)(CreateUserModal);
