import React from 'react';

const IconStar = ({
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 15.222 14.609"
      {...otherProps}
    >
      <path
        d="M-396.731,420.666c.138-.806.265-1.557.394-2.308q.225-1.3.454-2.6a.416.416,0,0,0-.153-.4q-1.629-1.582-3.252-3.17c-.08-.078-.164-.152-.238-.235-.13-.148-.089-.266.107-.3q1.661-.244,3.323-.482c.487-.07.973-.15,1.461-.209a.5.5,0,0,0,.423-.322c.68-1.394,1.369-2.783,2.056-4.173.025-.05.045-.1.074-.149.1-.162.206-.165.308-.008a1.472,1.472,0,0,1,.075.149c.679,1.376,1.365,2.749,2.032,4.132a.624.624,0,0,0,.535.39q2.311.323,4.618.67c.091.014.177.308.109.374q-1.655,1.624-3.32,3.237a.631.631,0,0,0-.214.645c.279,1.538.531,3.081.788,4.623.013.076,0,.212-.034.228a.344.344,0,0,1-.259-.018c-.981-.509-1.959-1.026-2.937-1.541-.422-.222-.85-.435-1.265-.671a.505.505,0,0,0-.55,0c-1.361.726-2.729,1.441-4.1,2.159a1.271,1.271,0,0,1-.213.107C-396.658,420.838-396.721,420.755-396.731,420.666Z"
        transform="translate(399.601 -406.196)"
        fill="#ffbd13"
      />
    </svg>
  );
};

export default IconStar;