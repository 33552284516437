import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import * as BonusSystemActions from '../../redux/actions/BonusSystem';
import { selectStyles } from '../../containers/CMS/customReactSelectStyle';
import { getBonusSystemData } from '../../selectors';
import { useStateForSelect, useMount, useUpdate } from '../../hooks';

const BusinessUnitSelector = ({
  businessUnits,
  onChange,
  getBusinessUnits,
  reduxBusinessUnits,
  defaultBu,
  isMulti,
  placeholder,
}) => {
  const buOptions = useMemo(
    () =>
      (businessUnits || reduxBusinessUnits).map(bu => ({
        label: bu.name,
        value: bu.name,
      })),
    [businessUnits, reduxBusinessUnits]
  );

  const [selectedBu, setSelectedBu] = useStateForSelect(
    isMulti ? defaultBu : buOptions.find(bu => bu.value === (defaultBu || 'BG'))
  );

  useUpdate(() => {
    setSelectedBu(
      isMulti
        ? defaultBu
        : buOptions.find(bu => bu.value === (defaultBu || 'BG'))
    );
  }, [buOptions, defaultBu, isMulti]);

  useMount(() => {
    if (!businessUnits) {
      getBusinessUnits();
    }
  });

  const onBuChange = useCallback(
    data => {
      setSelectedBu(data);
      if (isMulti) {
        onChange(data);
      } else {
        onChange(data.value);
      }
    },
    [isMulti, onChange, setSelectedBu]
  );

  return (
    <Select
      isMulti={isMulti}
      onChange={onBuChange}
      value={selectedBu}
      placeholder={placeholder ? placeholder : 'Select Business Unit'}
      options={buOptions}
      styles={selectStyles}
    />
  );
};

const mapStateToProps = state => ({
  reduxBusinessUnits: getBonusSystemData(state).businessUnits,
});

const mapDispatchToProps = {
  getBusinessUnits: BonusSystemActions.getBusinessUnits,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessUnitSelector);
