export const sportTemplate = {
  categoryId: '',
  categoryName: '',
  code: '',
  id: '',
  view: {},
  riskControlParameters: [],
  typeId: '',
  typeName: ''
};

export const uniqueSportType = (array, type) => {
  const result = [];
  const map = new Map();
  for (const item of array) {
    if (!map.has(item[type])) {
      map.set(item[type], true); // set any value to Map
      result.push({
        ...item
      });
    }
  }

  return result;
};