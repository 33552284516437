import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserProfiles from './UserProfiles/UserProfiles';
import RolesContainer from './Roles/RolesContainer';

class DBCore extends Component {
  render() {
    let RouteComponent;
    switch (this.props.match.params.module) {
      case 'ra':
        RouteComponent = () => <Route render={() => <RolesContainer />} />;
        break;
      case 'up':
        RouteComponent = () => <Route render={() => <UserProfiles />} />;
        break;
      case 'ss':
        RouteComponent = () => <span>DBC - System Settings</span>;
        break;
      default:
        RouteComponent = () => <Redirect to="/adm" />;
    }
    return (
      <div style={{ marginTop: '15px', marginBottom: '5vh' }}>
        <Route path={`${this.props.match.path}`} component={RouteComponent} />
      </div>
    );
  }
}

export default DBCore;
