import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';

const EventSearchMarketOutcomes = ({ tableData }) => {
  const renderOutcomes = useCallback(outcome => {
    return (
      <Fragment key={outcome.id}>
        {outcome.outcomeOdds.map(odd => {
          return odd.active ? <td key={odd.id}>{odd.odds}</td> : null;
        })}
      </Fragment>
    );
  }, []);
  return <tr>{tableData.map(renderOutcomes)}</tr>;
};

EventSearchMarketOutcomes.propTypes = {
  tableData: PropTypes.array,
};

export default React.memo(EventSearchMarketOutcomes);
