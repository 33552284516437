import React, { useState, useCallback } from 'react';
import InputField from '../../../components/Form/InputField';
import Button from '../../../components/Layout/Button';
import LanguageSelector from '../../../components/LanguageSelector/LanguageSelector';
import { useUpdate } from '../../../hooks';
import classes from './Translations.module.scss';

const AddTranslations = ({
  importedCSV,
  saveChanges,
  csvTranslations,
  setImportedCSV,
  setLanguage,
  language,
}) => {
  const [newTranslations, setNewTranslations] = useState([
    { id: 0, key: '', value: '' },
  ]);

  useUpdate(() => {
    setNewTranslations(csvTranslations);
  }, [csvTranslations]);

  const removeNewTranslation = useCallback(
    index => {
      const newTranslationsClone = [...newTranslations];
      newTranslationsClone.splice(index, 1);
      setNewTranslations(newTranslationsClone);
    },
    [newTranslations]
  );

  const handleInputChange = useCallback(
    (e, index) => {
      const { value, name } = e.target;
      const newTranslationsClone = [...newTranslations];
      newTranslationsClone[index][name] = value;
      setNewTranslations(newTranslationsClone);
    },
    [newTranslations]
  );

  const mapNewTranslations = useCallback(
    (tr, index) => {
      return (
        <div key={tr.id} className={classes.addFormWrapper}>
          <div className="efb-form">
            <div className="form-row">
              <div className="col-md form-label-group">
                <InputField
                  onChange={e => handleInputChange(e, index)}
                  label={`Key ${index + 1}`}
                  name="key"
                  value={tr.key}
                  placeholder={`Key ${index + 1}`}
                />
              </div>
              <div className="col-md form-label-group">
                <InputField
                  name="value"
                  value={tr.value}
                  onChange={e => handleInputChange(e, index)}
                  label={`Value ${index + 1}`}
                  placeholder={`Value ${index + 1}`}
                />
              </div>
              <div className="col-md">
                <Button
                  cssClass={`mr-1 ml-1 bet-scroller-btn delete-btn btn no-width ${classes.deleteBtn}`}
                  onClick={() => removeNewTranslation(index)}
                >
                  <i className="fas fa-times" /> Remove
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    },
    [handleInputChange, removeNewTranslation]
  );

  return (
    <>
      {importedCSV ? (
        <div className={classes.fixedButtons}>
          <Button
            cssClass="mr-1 ml-1 bet-scroller-btn ok-btn btn"
            onClick={() => saveChanges(newTranslations)}
          >
            <i className="fa fa-save" />
          </Button>
          <Button
            cssClass="mr-1 ml-1 bet-scroller-btn delete-btn btn"
            onClick={() => {
              setImportedCSV(false);
              setNewTranslations([{ id: 0, key: '', value: '' }]);
            }}
          >
            <i className="fa fa-times" />
          </Button>
        </div>
      ) : null}
      <LanguageSelector defaultLang={language} onChange={setLanguage} />
      <hr />
      {newTranslations.map(mapNewTranslations)}
      <hr />
      <Button
        cssClass={'mr-1 ml-1 bet-scroller-btn cancel-btn btn no-width'}
        onClick={() => {
          setNewTranslations([
            ...newTranslations,
            { id: newTranslations.length, key: '', value: '' },
          ]);
        }}
      >
        <i className="fas fa-plus" /> New translation
      </Button>
      <Button
        cssClass={'mr-1 ml-1 bet-scroller-btn ok-btn btn no-width'}
        onClick={() => saveChanges(newTranslations)}
      >
        <i className="fa fa-save" /> Save{' '}
        {newTranslations.length === 1 ? 'translation' : 'translations'}
      </Button>
    </>
  );
};

export default AddTranslations;
