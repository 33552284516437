export const suspendOptions = [
    { label: 'Both', value: ['LIVE', 'PREMATCH'] },
    { label: 'In-play', value: ['LIVE'] },
    { label: 'Pre-match', value: ['PREMATCH'] }
];

export const feedProvider = [
    { label: 'Betradar Live', value: ['LIVE', 'PREMATCH'] },
    { label: 'Betgenius Live', value: ['LIVE'] },
    { label: 'Betradar Pre-Match', value: ['PREMATCH']}
];

export const tableStyle =
'sortable table table-hover table-striped efb-table efb-data-table';

export const columns = [
  {
    name: 'Target Name',
    value: 'targetName',
    type: 'string',
    sortable: true
  },
  { name: 'Suspend', value: 'suspend', type: 'string' },
  { name: 'Feed Provider', value: 'feedProvider', type: 'string' },
  { name: 'Actions', value: 'actions', type: 'buttons' }
];

export const isChecked = (systemSettings, str) =>
  systemSettings.feedScopes.some(scope => scope === str);

export const selectOptions = (allData, key) => {
      return allData.reduce((acc, curr) => {
          acc.push({
            value: curr.name,
            label: curr.name,
            [key]: curr.id
          })
          return acc;
        },
        []
      )
}

 