import React from 'react';
import PropTypes from 'prop-types';

const ImagePreview = ({ image }) => {
  return (
    <div className="image-preview">
      <div className="image" style={{ backgroundImage: `url(${image})` }}></div>
    </div>
  );
};

ImagePreview.propTypes = {
  image: PropTypes.string,
};

export default ImagePreview;
