import React from 'react';
import { Button } from '../../../components/Layout';

class ManageEventButton extends React.PureComponent {
  openEventModal = () => {
    this.props.openEventModal(this.props.seasonId);
  };

  removeTopEvent = () => {
    this.props.removeTopEvent(this.props.seasonId);
  };

  render() {
    const { topEvents, seasonId } = this.props;
    return (
      <>
        {topEvents.find(event => event.highlightId === seasonId) ? (
          <Button
            onClick={this.removeTopEvent}
            cssClass="btn btn-danger btn-md"
          >
            Remove from Top Events
          </Button>
        ) : (
          <Button
            onClick={this.openEventModal}
            cssClass="btn btn-secondary btn-md"
          >
            Add to Top Events
          </Button>
        )}
      </>
    );
  }
}

export default ManageEventButton;
