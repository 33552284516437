import {BETRADAR_PRODUCER_ID} from '../config/constants';

export const getBetRadarFeedProviderId = (sportProducers) => {
	const sportProducer =
		Array.isArray(sportProducers) &&
		sportProducers.find((producer) => producer.producerId === BETRADAR_PRODUCER_ID);

	return sportProducer && sportProducer.feedProviderId;
};

export const getSportIcon = (sport, sportIconsMap) => {
	const feedProviderId = sport && sport.feedProducers && getBetRadarFeedProviderId(sport.feedProducers);
	return sportIconsMap[feedProviderId] && sportIconsMap[feedProviderId].icon;
};
