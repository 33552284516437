import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminBar from '../components/Layout/AdminBar';
import Authorization from '../hoc/authorization';
import BredCrumbs from '../components/Layout/BreadCrumbs';
import ContentManagement from './CMS/ContentManagement';
import CustomerService from './CustomerService/CustomerService';
import CustomerValidation from './CustomerService/CustomerValidation/CustomerValidationBuilder';
import DBCore from './Moduls/DBCore/DBCore';
import Dashbord from './Moduls/Dashbord';
import LiveStreaming from './LiveStreaming/LiveStreaming';
import MainMenu from '../components/Layout/MainMenu';
import NotificationContainer from './NotificationContainer';
import RiskManagementModule from './RiskManagement/RiskManagementModule';
import AdHockModule from './AdHock/AdHockModule';
import WebSocketWrapper from './WebSocketWrapper';
import TransactionManagement from './BackOffice/Transaction/TransactionManagement';
import PlayerManagementModule from './PlayerManagement/PlayerManagementModule';

class Layout extends Component {
  //Don't delete
  store = {
    dashboard: [
      {
        name: 'User Management',
        url: 'dbc',
        children: [
          { name: 'User Profiles', url: 'up' },
          { name: 'Roles for all Apps', url: 'ra' },
        ],
      },
      {
        name: 'Player Management',
        url: 'pm',
        children: [
          // {name: 'Player Profile', url: 'profile'},
          { name: 'Player Manager', url: 'manager' },
        ],
      },
      { name: 'Afiliate', url: 'af', children: [] },
      {
        name: 'Content Management',
        url: 'cm',
        children: [
          { name: 'Top Manager', url: 'tp' },
          // { name: 'Events', url: 'ev' },
          { name: 'Event Builder', url: 'eb' },
          { name: 'Sport, Category, Tournament, Season Manager', url: 'sts' },
          { name: 'Competitor Builder', url: 'cb' },
          { name: 'Images', url: 'img' },
          { name: 'Translations', url: 'tr' },
          { name: 'Market Categories', url: 'mc' },
          { name: 'Static Content', url: 'sc' },
          { name: 'Casino Manager', url: 'cam' },
          { name: 'Events Search', url: 'es' },
          { name: 'Entity Merger', url: 'em' },
        ],
      },
      {
        name: 'Risk Management',
        url: 'rm',
        children: [
          { name: 'Betting Control', url: 'system-settings' },
          { name: 'Configuration', url: 'configuration' },
          { name: 'Bet History', url: 'bet-history' },
          { name: 'Risk Manager Dashboard', url: 'dash' },
          { name: 'Referral Console', url: 'referral' },
          { name: 'Cashout Manager', url: 'ctm' },
          { name: 'Settlements and Results', url: 'settlements-results' },
        ],
      },
      {
        name: 'Customer Service',
        url: 'cs',
        children: [
          { name: 'Bonus System', url: 'bonus-system' },
          { name: 'Customer Verification', url: 'cv' },
        ],
      },
      { name: 'Call Center', url: 'cc', children: [] },
      {
        name: 'Back Office',
        url: 'bo',
        children: [
          { name: 'Transactions', url: 'trc' },
          { name: 'Pending Withdraws', url: 'ptrc' },
          { name: 'Currency Rates', url: 'cr' },
          { name: 'Payment Manager', url: 'pm' },
          { name: 'Exceptions', url: 'ex' },
          { name: 'Automatic Withdraws', url: 'aw' },
          { name: 'Message Templates', url: 'mt' },
          { name: 'Self Exclusion', url: 'self-ex-per-bu' },
        ],
      },
      { name: 'Data Warehouse', url: 'dw', children: [] },
      {
        name: 'Ad-Hock',
        url: 'ah',
        children: [{ name: 'NRA/SCG', url: 'nra-scg' }],
      },
      { name: 'Live Streaming', url: 'ls', children: [] },
    ],
  };

  render() {
    const { match, location } = this.props;

    const css = (loc, pathArr) => {
      let css = 'efb-main';
      pathArr.map(path => loc.includes(path) && (css = 'efb-main__white'));
      return css;
    };

    const config = {
      screens: [
        '/up',
        '/profile',
        '/ra',
        '/manager',
        '/bet-scroller',
        '/configuration',
        '/system-settings',
        '/bet-history',
      ],
    };
    return (
      <Fragment>
        <NotificationContainer />
        <WebSocketWrapper />
        <AdminBar />
        <MainMenu />
        <main className={css(location.pathname, config.screens)}>
          <BredCrumbs />
          <section className="efb-container">
            <Switch>
              <Route
                path={`${match.path}/dbc/:module`}
                render={props => <DBCore {...props} />}
              />
              <Route
                path={`${match.path}/pm/:module`}
                render={props => <PlayerManagementModule {...props} />}
              />
              <Route
                path={`${match.path}/cs/:module`}
                render={props => <CustomerService {...props} />}
              />
              <Route
                path={`${match.path}/cm/:module`}
                render={props => <ContentManagement {...props} />}
              />
              <Route
                path={`${match.path}/bo/:module`}
                render={props => <TransactionManagement {...props} />}
              />
              <Route
                path={`${match.path}/cs/:module`}
                render={props => <CustomerValidation {...props} />}
              />
              <Route
                path={`${match.path}/rm/:module`}
                render={props => <RiskManagementModule {...props} />}
              />
              <Route
                path={`${match.path}/ah/:module`}
                render={props => <AdHockModule {...props} />}
              />
              <Route
                path={`${match.path}/ls`}
                render={() => <LiveStreaming />}
              />
              <Route
                path={`${match.path}/:moduleId`}
                render={() => <Dashbord items={this.store.dashboard} />}
              />
              <Route
                path={match.path}
                render={() => <Dashbord items={this.store.dashboard} />}
              />
            </Switch>
          </section>
        </main>
        <div className="efb-decorator" />
      </Fragment>
    );
  }
}

export default Authorization(Layout);
