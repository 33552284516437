// //////////////////// Commented code will be uncommented when API is ready

import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
// import Button from '../../../../components/Layout/Button';
import NoImageIcon from '../../../../resources/images/no-image.png';
import { setGameRanking } from '../CasinoManagerUtils';
import BusinessUnitSelector from '../../../../components/BusinessUnitSelector/BusinessUnitSelector';
import { useUpdate, useStateForSelect } from '../../../../hooks';

const GameRanking = ({ allGames, getGamesByBusinessUnit, updateRank }) => {
  const [games, setGames] = useState([]);
  const [businessUnit, setBusinessUnit] = useStateForSelect('BG');
  const [changingGame, setChangingGame] = useState([]);
  const [newIndex, setNewIndex] = useState('');
  const [startIndex, setStartIndex] = useState('');
  const [newOrder, setNewOrder] = useState([]);

  useUpdate(() => {
    orderByRank(allGames);
  }, [allGames]);

  useUpdate(() => {
    if (businessUnit) getGamesByBusinessUnit(businessUnit);
  }, [businessUnit]);

  const orderByRank = useCallback(
    list => {
      const listClone = [...list];

      // Assigning default rank for games which don't have any rank
      listClone.reduce((acc, game, index) => {
        if (
          !Object.keys(game.ranking).length ||
          !game.ranking[businessUnit.toLowerCase()]
        ) {
          return (game.ranking = {
            [businessUnit.toLowerCase()]: {
              rank: index,
              gameId: game.gameId,
              businessUnit: businessUnit.toLowerCase(),
            },
          });
        }
        return game;
      }, []);

      const newList = listClone.sort((a, b) => {
        if (
          a.ranking[businessUnit.toLowerCase()] &&
          b.ranking[businessUnit.toLowerCase()]
        ) {
          return (
            a.ranking[businessUnit.toLowerCase()].rank -
            b.ranking[businessUnit.toLowerCase()].rank
          );
        }
        return 0;
      });
      setGames(newList);
    },
    [businessUnit]
  );

  const dragStart = useCallback(
    (e, index) => {
      setStartIndex(index);
      setChangingGame(games[index]);
    },
    [games]
  );

  const onDrop = useCallback(
    e => {
      e.preventDefault();
      if (allGames.length > 1) {
        const changingGameClone = { ...changingGame };
        changingGameClone.ranking = setGameRanking(
          changingGameClone,
          newIndex,
          businessUnit.toLowerCase()
        );
        setGames(newOrder);
        setChangingGame(changingGameClone);
        updateRank(changingGameClone, businessUnit);
      }
    },
    [
      allGames.length,
      businessUnit,
      changingGame,
      newIndex,
      newOrder,
      updateRank,
    ]
  );

  const onDragEnter = useCallback(
    (e, index) => {
      e.preventDefault();
      const gamesClone = [...games];
      const changingGame = gamesClone[startIndex];
      const oldGame = gamesClone[index];
      gamesClone.splice(startIndex, 1);
      if (index < startIndex) {
        gamesClone.splice(index, 1);
        gamesClone[index] = changingGame;
        gamesClone.splice(index + 1, 1, oldGame);
      } else {
        gamesClone.splice(index - 1, 1);
        gamesClone[index] = changingGame;
        gamesClone.splice(index + 1, 1, oldGame);
      }
      setNewOrder(gamesClone);
      setNewIndex(index);

      // Maybe will be used later (previous functionality)
      // if (index > startIndex || index < startIndex) {
      //   // const changingGame = gamesClone[startIndex];
      //   // const oldGame = gamesClone[index];
      //   // gamesClone.splice(index, 1, changingGame);
      //   // gamesClone.splice(startIndex, 1, oldGame);
      //   // setNewOrder(gamesClone);
      //   // setNewIndex(index);
      // }
    },
    [games, startIndex]
  );

  const gameRendered = useCallback(
    (game, index) => {
      const gameImageIndex = game.images
        ? game.images.findIndex(x => x.channel === 'web')
        : 0;
      return (
        <Fragment key={game.gameId}>
          <div
            className="game-list-item"
            id={game.id}
            draggable="true"
            onDragStart={e => dragStart(e, index)}
            onDragEnter={e => onDragEnter(e, index)}
            onDragEnd={onDrop}
          >
            <div
              className="game-image"
              style={{
                backgroundImage: `url(${
                  gameImageIndex > -1 && game.images
                    ? game.images[gameImageIndex].url
                    : NoImageIcon
                })`,
              }}
            >
              <p className="capitalize game-name">
                {game.names.en ? game.names.en.name : ''}
              </p>
            </div>
          </div>
        </Fragment>
      );
    },
    [dragStart, onDragEnter, onDrop]
  );

  return (
    <Fragment>
      <br />
      <BusinessUnitSelector
        defaultBu={businessUnit}
        onChange={setBusinessUnit}
      />
      <hr />
      <div className="game-list-wrapper">{games.map(gameRendered)}</div>
    </Fragment>
  );
};

GameRanking.propTypes = {
  allGames: PropTypes.array,
  casinoGameObj: PropTypes.object,
  deleteGame: PropTypes.func,
  editGame: PropTypes.func,
  languages: PropTypes.array,
  prepareImageUpload: PropTypes.func,
  previewImage: PropTypes.func,
  rankError: PropTypes.func,
  sortByRank: PropTypes.func,
  updateGameStatus: PropTypes.func,
  updateRank: PropTypes.func,
};

export default GameRanking;
