import axios from 'axios';
import { getToken } from './index';
import { config } from '../../backoffice.config';

const axiosInstance = axios.create({
  paramsSerializer(params) {
    const searchParams = new URLSearchParams();
    for (const key of Object.keys(params)) {
      const param = params[key];
      if (Array.isArray(param)) {
        for (const p of param) {
          searchParams.append(key, p);
        }
      } else {
        searchParams.append(key, param);
      }
    }
    return searchParams.toString();
  },
  baseURL: `${config.domainUrl}/api/backend`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'X-Origin-BU': 'BG',
  },
});

axiosInstance.interceptors.request.use(config => {
  config.headers['Authorization'] = 'Bearer ' + getToken();
  if (config.method === 'post') config.headers['Platform-Origin'] = 'OPERATOR';
  return config;
});

axiosInstance.interceptors.response.use(
  res => {
    return Promise.resolve(res.data);
  },
  error => {
    if (!error) return;

    let errorData = error;

    if (
      typeof error.response !== 'undefined' &&
      typeof error.response.data !== 'undefined'
    ) {
      errorData = error.response.data;
    }

    return Promise.reject(errorData);
  }
);

export default axiosInstance;
