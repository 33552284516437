import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../components/Table/Table';

export class SportTemplatesTable extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.data === nextProps.data) {
      return false;
    } else {
      return true;
    }
  }

  tableStyle =
    'sortable table table-hover table-striped efb-table efb-data-table';

  columns = [
    { name: 'Name', value: 'name', type: 'string', sortable: true },
    { name: 'Tournament', value: 'typeName', type: 'string' },
    { name: 'Category', value: 'typeName', type: 'string' },
    { name: 'Sport', value: 'sportType', type: 'string' },
    { name: 'Max Takeout', value: 'maxTakeout', type: 'string' },
    { name: 'Actions', value: 'status', type: 'buttons' }
  ];

  render() {
    return (
      <div>
        <Table
          columns={this.columns}
          tableStyle={this.tableStyle}
          tableType="sportTemplates"
          {...this.props}
        />
      </div>
    );
  }
}

SportTemplatesTable.propTypes = {
  data: PropTypes.array,
  createSportTemplate: PropTypes.func,
  editHandler: PropTypes.func,
  showDeleteModal: PropTypes.func,
  changeStatus: PropTypes.func
};

export default SportTemplatesTable;
