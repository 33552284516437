import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { addNotificationMessage } from '../../../../../redux/actions/ui';
import { reject } from '../../../../../redux/actions/CustomerValidation';
import { useFetch, useStateForSwitch, useUpdate } from '../../../../../hooks';

import * as CustomerDataActions from '../../../../../redux/actions/customer/customerData';

import Table from '../../../../../components/NewTable/Table';
import Button from '../../../../../components/Layout/Button';
import Buttons from '../../../../../components/ModalButtons/Buttons';
import Switch from '../../../../../components/Form/Switch/Switch';
import RejectModal from './RejectModal';
import UploadImage from './UploadImage';
import classes from './Documents.module.scss';

const Documents = ({
  customer,
  updateCustomerDetails,
  reject,
  uploadAdminVerification,
  addNotificationMessage,
  onClose,
}) => {
  const [activeModal, setActiveModal] = useState(false);
  const [currentDocument, setCurrentDocument] = useState({});
  const [additionalVerification, setAdditionalVerification] = useStateForSwitch(
    false
  );

  const { data: documents, refetch: refetchDocuments } = useFetch(
    `/customer/view/admin/${customer.customerId}/documents-file`
  );

  const { data: customerDetails } = useFetch(
    `customer/view/admin/${customer.customerId}`
  );

  useUpdate(() => {
    if (customerDetails) {
      setAdditionalVerification(customerDetails.additionalVerification);
    }
  }, [customerDetails]);

  const columns = useMemo(
    () => [
      {
        name: 'Document',
        display: document => document.verificationDocument.documentType,
      },
      { name: 'Status', display: document => document.status },
      {
        name: 'Validation Date',
        display: document =>
          document.verifiedDate
            ? moment(document.verifiedDate).format('DD/MM/YYYY HH:mm:ss')
            : '',
      },
      {
        name: 'Expiry Date',
        display: document =>
          document.validityDate
            ? moment(document.validityDate).format('DD/MM/YYYY HH:mm:ss')
            : '',
      },
      {
        name: 'View File',
        display: document => (
          <a
            href={document.url}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.download}
          >
            View
          </a>
        ),
      },
      {
        name: 'Reject Verification',
        display: document => (
          <Button
            disabled={document.status === 'CANCELED'}
            cssClass={`${classes.reject} ${
              document.status === 'CANCELED' ? 'not-allowed' : ''
            }`}
            onClick={() => {
              setActiveModal(true);
              setCurrentDocument(document);
            }}
          >
            Reject
          </Button>
        ),
      },
    ],
    []
  );

  const saveChanges = useCallback(() => {
    updateCustomerDetails({
      additionalVerification,
      address: customerDetails.address,
      citizenship: customerDetails.citizenship,
      city: customerDetails.city,
      country: customerDetails.country,
      customerId: customer.customerId,
      dateOfBirth: customerDetails.dateOfBirth,
      email: customerDetails.email,
      region: customerDetails.region,
      gender: customerDetails.gender,
      middleName: customerDetails.middleName,
      firstName: customerDetails.firstName,
      lastName: customerDetails.lastName,
      mobilePhone: customerDetails.mobilePhone,
      personalIdentityNumber: customerDetails.personalIdentityNumber,
    });
  }, [
    additionalVerification,
    customer.customerId,
    customerDetails,
    updateCustomerDetails,
  ]);

  return (
    <>
      <div className={classes.wrapper}>
        {documents && documents.length > 0 && (
          <Table columns={columns} data={documents} pagination={false} />
        )}
        <UploadImage
          customerId={customer.customerId}
          refetchDocuments={refetchDocuments}
          uploadAdminVerification={uploadAdminVerification}
          addNotificationMessage={addNotificationMessage}
        />
        <div className="p-3">
          <Switch
            label="Additional verification"
            onChange={setAdditionalVerification}
            isChecked={additionalVerification}
            smallSwitch
          />
        </div>
        <RejectModal
          reject={reject}
          activeModal={activeModal}
          setActiveModal={setActiveModal}
          currentDocument={currentDocument}
          refetchDocuments={refetchDocuments}
        />
      </div>
      <Buttons
        apply={saveChanges}
        ok={() => {
          saveChanges();
          onClose();
        }}
        cancel={onClose}
      />
    </>
  );
};

const mapDispatchToProps = {
  updateCustomerDetails: CustomerDataActions.updateCustomerDetails,
  uploadAdminVerification: CustomerDataActions.uploadAdminVerification,
  addNotificationMessage,
  reject,
};

export default connect(null, mapDispatchToProps)(Documents);
