import React, { useState, useCallback } from 'react';
import Table from '../../../components/Table/Table';
import Modal from '../../../components/Modal/Modal';
import { useUpdate } from '../../../hooks';

const columns = [
  { name: 'Customer', value: 'customer', type: 'string' },
  { name: 'Stake', value: 'stake', type: 'string' },
  { name: 'Bet Type', value: 'betType', type: 'string' },
  { name: 'Takeout', value: 'takeout', type: 'string' },
  { name: 'Time out', value: 'timeOut', type: 'string' },
  { name: 'Betslip Actions', value: '', type: 'buttons' },
];

const ReferralBetTable = ({ data, removeReferralBetslip, ...props }) => {
  const [betTypeDetails, setBetTypeDetails] = useState(false);
  const [systemNames, setSystemNames] = useState([]);
  const [betslips, setBetslips] = useState([]);

  useUpdate(() => {
    if (data.length > 0) {
      setBetslips(data);
    }
  }, [data]);

  const showAllTypes = useCallback(
    index => {
      setBetTypeDetails(true);
      const betslips = [...data];
      const systemTypes = betslips[index].systemBets
        ? betslips[index].systemBets.reduce(
            (acc, system) => [...acc, system.type],
            []
          )
        : null;
      setSystemNames(systemTypes);
    },
    [data]
  );

  const removeBetslipFromList = useCallback(
    index => {
      const betslipsClone = [...betslips];
      betslipsClone.splice(index, 1);
      setBetslips(betslipsClone);
    },
    [betslips]
  );

  return (
    <>
      <Modal
        headerText={'Bet type list'}
        isActive={betTypeDetails}
        onClose={() => setBetTypeDetails(false)}
      >
        <div className="modal-wrapper">
          <ul className="text-left">
            {systemNames.map((name, index) => {
              return (
                <li key={index}>
                  {index + 1}. {name}
                </li>
              );
            })}
          </ul>
        </div>
      </Modal>
      <Table
        tableStyle="table custom-referal-table"
        tableType="referralBetTableNew"
        columns={columns}
        data={betslips}
        pagination={false}
        search={false}
        order="asc"
        pageSize={data.length}
        showAllTypes={showAllTypes}
        removeReferralBetslip={removeReferralBetslip}
        removeBetslipFromList={removeBetslipFromList}
        {...props}
      />
    </>
  );
};

export default ReferralBetTable;
