import axiosInstance from '../axios-instance';
import { createRequestTypes, actionCreator } from '../index';
import { addNotificationMessage } from '../ui';

export const GET_EVENT_SPORT_INFO = createRequestTypes('GET_EVENT_SPORT_INFO');
export const GET_MARKET = createRequestTypes('GET_MARKET');
export const GET_SPORTS_TOURNAMENTS_CATEGORIES_EVENTS = createRequestTypes('GET_SPORTS_TOURNAMENTS_CATEGORIES_EVENTS');
export const GET_MARKETS = createRequestTypes('GET_MARKETS');
export const GET_EVENT = createRequestTypes('GET_EVENT');

export const getEventSportInfo = (sportId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_EVENT_SPORT_INFO));
	try {
		const res = await axiosInstance.get(`/sport/event/view/sport/admin/${sportId}`);
		dispatch(actionCreator.success(GET_EVENT_SPORT_INFO, res));
	} catch (err) {
		dispatch(addNotificationMessage(err.message, 'error', 'Error'));
		dispatch(actionCreator.failure(GET_EVENT_SPORT_INFO, err));
	}
};

export const getMarket = (id) => async (dispatch) => {
	dispatch(actionCreator.request(GET_MARKET));
	try {
		const res = await axiosInstance.get(`/market/market/${id}`);
		dispatch(actionCreator.success(GET_MARKET, res));
	} catch (err) {
		dispatch(addNotificationMessage(err.message, 'error', 'Error'));
		dispatch(actionCreator.failure(GET_MARKET, err));
	}
};

export const settleMarket = (market, sportEventId, currentStatus) => async (dispatch) => {
	try {
		const res = await axiosInstance.post(`/market/market/betsettle`, market);
		if (!res.errors) {
			dispatch(addNotificationMessage('You have successfully updated market', 'success', 'Success'));
			dispatch(getMarkets(sportEventId, currentStatus));
		}
	} catch (err) {
		if (err.errors && typeof err.errors === 'object') {
			Object.keys(err.errors).forEach((error_code) => {
				dispatch(addNotificationMessage(err.errors[error_code], 'error', 'Error'));
			});
		}
	}
};

export const getPlacedBetData = () => async (dispatch) => {
	try {
		const res = await axiosInstance.get('/sport/event/view/sport/admin/placed-bet-filtered?locale=en');
		dispatch(actionCreator.success(GET_SPORTS_TOURNAMENTS_CATEGORIES_EVENTS, res));
	} catch (err) {
		dispatch(addNotificationMessage(err.message, 'error', 'Error'));
	}
};

export const getMarkets = (sportEventId, status) => async (dispatch) => {
	try {
		const res = await axiosInstance.get(
			`/market/view/market/admin/placed-bet-filtered?locale=en&marketStatus=${status
				? status
				: 'ACTIVE'}&sportEventId=${sportEventId}`
		);
		dispatch(actionCreator.success(GET_MARKETS, res));
	} catch (err) {
		dispatch(addNotificationMessage(err.message, 'error', 'Error'));
	}
};

export const getEvent = (eventId) => async (dispatch) => {
	try {
		const res = await axiosInstance.get(`/sport/event/view/match/admin/${eventId}`);
		dispatch(actionCreator.success(GET_EVENT, res));
	} catch (err) {
		dispatch(addNotificationMessage(err.message, 'error', 'Error'));
	}
};

export const rollbackSettledMarket = (market, sportEventId, currentStatus) => async (dispatch) => {
	try {
		axiosInstance.post(`/market/market/rollbackbetsettle`, market);
		dispatch(addNotificationMessage('You have successfully rolled back market', 'success', 'Success'));
		dispatch(getMarkets(sportEventId, currentStatus));
	} catch (err) {
		dispatch(addNotificationMessage(err.message, 'error', 'Error'));
	}
};
