import { combineReducers } from 'redux';

import { stsBuilderReducer } from './stsBuilderReducer';
import { imagesReducer } from './imagesReducer';
import { marketCategoriesReducer } from './marketCategoriesReducer';
import { eventBuilderReducer } from './eventBuilderReducer';
import { eventsManagerReducer } from './eventsManagerReducer';
import { translationsReducer } from './translationsReducer';
import { topManagerReducer } from './topManagerReducer';
import { competitorReducer } from './competitorReducer';
import { casinoManagerReducer } from './casinoManagerReducer';
import { staticPagesReducer } from './staticPagesReducer';
import { eventSearchReducer } from './eventSearchReducer';

export const cmsReducer = combineReducers({
	stsBuilderReducer,
	imagesReducer,
	marketCategoriesReducer,
	eventBuilderReducer,
	eventsManagerReducer,
	translationsReducer,
	topManagerReducer,
	competitorReducer,
	casinoManagerReducer,
  staticPagesReducer,
  eventSearchReducer
});
