import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import Select from 'react-select';
import { connect } from 'react-redux';
import * as CashoutManagerActions from '../../../../redux/actions/riskManagement/cashoutManager';
import Switch from '../../../../components/Form/Switch/Switch';
import Button from '../../../../components/Layout/Button';
import classes from './CashoutConfigurator.module.scss';
import { selectStyles } from '../../../CMS/customReactSelectStyle';
import {
  useFetch,
  useStateForSelect,
  useStateForSwitch,
} from '../../../../hooks';

const BetTypeConfigurator = ({ createOrUpdateBetTypeConfig }) => {
  const [betType, setBetType] = useStateForSelect('');
  const [enabled, setEnabled, resetEnabled] = useStateForSwitch(false);
  const { data: betTypes } = useFetch('/betslip/view/admin/system-types');

  const { data: betTypeConfig, refetch } = useFetch(
    '/cashout/admin/config/betType'
  );

  const betTypeOptions = useMemo(() => {
    return (
      betTypes &&
      betTypes.map(el => ({
        value: el,
        label: el,
      }))
    );
  }, [betTypes]);

  const config = useMemo(() => {
    if (betType) {
      const config = betTypeConfig.find(conf => conf.betType === betType.value);
      if (config) {
        resetEnabled(config.enabled);
      }
      return config;
    }
    // eslint-disable-next-line
  }, [betType, betTypeConfig]);

  const createConfig = useCallback(async () => {
    await createOrUpdateBetTypeConfig({
      changedDate: moment(),
      createdDate: moment(),
      betType: betType.value,
      id: config ? config.id : null,
      enabled,
    });
    await refetch();
  }, [betType, config, createOrUpdateBetTypeConfig, enabled, refetch]);

  if (!betTypes) return null;
  return (
    <div className="p-5">
      <div className="form-row justify-content-center">
        <Select
          onChange={setBetType}
          value={betType}
          options={betTypeOptions}
          placeholder="Select Bet Type"
          styles={selectStyles}
          className="my-4 col-md-8"
        />
      </div>
      {betType ? (
        <>
          <div className="d-flex justify-content-around pt-3">
            <h3 className="efb-h3">{betType.value}</h3>
            <Button cssClass="efb-btn__main" onClick={createConfig}>
              {config ? 'Update' : 'Create'} config
            </Button>
          </div>
          <hr />
          {config ? (
            <>
              <div
                className={classes.config}
                style={{ justifyItems: 'center' }}
              >
                <h5>Bet Type</h5>
                <h5>Id</h5>
                <h5>Disable/Enable</h5>
                <div>{config.betType}</div>
                <div>{config.id}</div>
                <div>
                  <Switch
                    id="config"
                    name="config"
                    isChecked={enabled}
                    onChange={setEnabled}
                  />
                </div>
              </div>
              <hr />
            </>
          ) : (
            <div className="d-flex justify-content-center py-4">
              <h3 className="efb-h3">There is no config for this bet type!</h3>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = {
  createOrUpdateBetTypeConfig:
    CashoutManagerActions.createOrUpdateBetTypeConfig,
};

export default connect(null, mapDispatchToProps)(BetTypeConfigurator);
