import {
  BOOK,
  GET_DESTINATION_PROVIDER_ENTITY,
  GET_PRODUCERS,
  GET_SOURCE_PROVIDER_ENTITY,
  MANAGER_GET_CATEGORIES,
  MANAGER_GET_EVENTS,
  MANAGER_GET_SEASONS,
  MANAGER_GET_SPORTS,
  MANAGER_GET_TOURNAMENTS
} from '../../actions/cms/eventsManager';

const initialState = {
  sourceEntities: [],
  destinationEntities: [],
  sports: [],
  categories: [],
  tournaments: [],
  seasons: [],
  events: [],
  producers: [],
  season: '',
  eventStatusChangeError: false,
  eventIndex: '',
  bookError: {}
};

export const eventsManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SOURCE_PROVIDER_ENTITY.SUCCESS:
      return {
        ...state,
        sourceEntities: action.payload
      };

    case GET_DESTINATION_PROVIDER_ENTITY.SUCCESS:
      return {
        ...state,
        destinationEntities: action.payload
      };

    case MANAGER_GET_SPORTS.SUCCESS:
      return {
        ...state,
        sports: action.payload
      };

    case MANAGER_GET_CATEGORIES.SUCCESS:
      return {
        ...state,
        categories: action.payload
      };

    case MANAGER_GET_TOURNAMENTS.SUCCESS:
      return {
        ...state,
        tournaments: action.payload
      };

    case MANAGER_GET_SEASONS.SUCCESS:
      return {
        ...state,
        seasons: action.payload
      };

    case MANAGER_GET_EVENTS.SUCCESS:
      return {
        ...state,
        events: action.payload
      };

    case GET_PRODUCERS.SUCCESS:
      return {
        ...state,
        producers: action.payload
      };

    case BOOK.SUCCESS:
      return {
        ...state,
        bookError: {}
      };

    case BOOK.FAILURE:
      return {
        ...state,
        bookError: action.payload
      };

    default:
      return state;
  }
};
