import { GET_ALL_GAMES, GAME_PROVIDERS } from '../../actions/cms/casinoManager';

const initialState = {
    allGames: [],
    gameProviders: [],
    gamesByLanguage: []
}

export const casinoManagerReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ALL_GAMES.SUCCESS:
            return {
                ...state,
                allGames: action.payload
            }

        case GAME_PROVIDERS.SUCCESS:
            return {
                ...state,
                gameProviders: action.payload
            }

        default:
            return state;
    }
}