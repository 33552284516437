import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../components/Table/Table';
import { useUpdate, useStateForSelect } from '../../../../hooks';
import BusinessUnitSelector from '../../../../components/BusinessUnitSelector/BusinessUnitSelector';

const columns = [
  { name: 'No' },
  { name: 'Business Unit' },
  { name: 'Game Title' },
  { name: 'Platform' },
  { name: 'Provider' },
  { name: 'Image' },
  { name: 'Active' },
  { name: 'Action' },
];

const GamesTable = ({
  allGames,
  getGamesByBusinessUnit,
  updateGameStatus,
  deleteGame,
  prepareImageUpload,
  editGame,
  editMode,
  previewImage,
}) => {
  const [games, setGames] = useState([]);
  const [businessUnit, setBusinessUnit] = useStateForSelect('BG');
  const [currentBu, setCurrentBu] = useState('');

  useUpdate(() => {
    setGames(allGames);
  }, [allGames]);

  useUpdate(() => {
    if (businessUnit) {
      getGamesByBusinessUnit(businessUnit);
      setCurrentBu(businessUnit);
    }
  }, [businessUnit]);

  return (
    <>
      <hr />
      <BusinessUnitSelector
        defaultBu={businessUnit}
        onChange={setBusinessUnit}
      />

      <Table
        columns={columns}
        data={games}
        currentBu={currentBu}
        prepareImageUpload={prepareImageUpload}
        deleteGame={deleteGame}
        editMode={editMode}
        updateGameStatus={updateGameStatus}
        editGame={editGame}
        previewImage={previewImage}
        tableType="casinoGameTable"
        tableStyle="sortable table table-hover table-striped efb-table events-search-table"
      />
    </>
  );
};

GamesTable.propTypes = {
  allGames: PropTypes.array,
  casinoGameObj: PropTypes.object,
  deleteGame: PropTypes.func,
  editGame: PropTypes.func,
  languages: PropTypes.array,
  prepareImageUpload: PropTypes.func,
  previewImage: PropTypes.func,
  rankError: PropTypes.func,
  sortByRank: PropTypes.func,
  updateGameStatus: PropTypes.func,
  updateRank: PropTypes.func,
};

export default GamesTable;
