import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';

import {
  transactionsColumns,
  transactionsColumnsDetails,
} from '../transactionsUtils';

import TransactionSelector from './TransactionSelector';
import Table from '../../../../components/Table/Table';
import TableExports from '../../../../components/Table/TableExports';
import Modal from '../../../../components/Modal/Modal';

const Transactions = ({
  transactions,
  getTransactions,
  currencies,
  paymentMethods,
  types,
  states,
  tab,
  emptyTransaction,
}) => {
  const [selectedTransaction, setSelectedTransaction] = useState({});

  const showDetails = transaction => setSelectedTransaction(transaction || {});

  return (
    <div className="tab-pane card-body">
      <TransactionSelector
        emptyTransaction={emptyTransaction}
        currencies={currencies}
        paymentMethods={paymentMethods}
        types={types}
        states={states}
        getTransactions={getTransactions}
      />
      <Modal
        myClassName="marketTypeSelector"
        headerText={'Detail for transaction'}
        isActive={Object.keys(selectedTransaction).length > 0}
        onClose={() => setSelectedTransaction({})}
        small={true}
        type="transactions"
      >
        <Table
          data={[selectedTransaction]}
          columns={transactionsColumnsDetails}
          showDetails={showDetails}
          tab={tab}
          tableStyle="table table-hover table-striped efb-table efb-data-table"
          tableType="transactionTable"
          pagination={false}
          search={false}
          detail={true}
        />
      </Modal>
      {transactions ? (
        <Fragment>
          <Table
            data={transactions.content}
            columns={transactionsColumns}
            itemsCount={transactions.content.length}
            showDetails={showDetails}
            tab={tab}
            tableStyle="sortable table table-hover table-striped efb-table efb-data-table"
            tableType="transactionTable"
          />
          <TableExports
            columns={transactionsColumns}
            tab={tab}
            transactions={transactions.content}
          />
        </Fragment>
      ) : null}
    </div>
  );
};

Transactions.propTypes = {
  currencies: PropTypes.array,
  getTransactions: PropTypes.func,
  emptyTransaction: PropTypes.func,
  paymentMethods: PropTypes.array,
  states: PropTypes.array,
  tab: PropTypes.string,
  transactions: PropTypes.object,
  types: PropTypes.array,
};

export default Transactions;
