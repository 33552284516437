import React from 'react';

const IconFolderRedArrow = ({
  className,
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      className={className}
      viewBox="0 0 22 20.322" {...otherProps}>
      <g transform="translate(0 0.594)">
        <g transform="translate(0 2.036)">
          <rect
            width="10"
            height="6"
            rx="1"
            transform="translate(0 -0.308)"
            fill="#cf8808"
          />
          <rect
            width="22"
            height="15"
            rx="1"
            transform="translate(0 2.692)"
            fill="#ffbe4a"
          />
        </g>
        <g transform="translate(5.84 13.373) rotate(-90)">
          <path
            d="M11.383,5.994C7.722,11.255-1.84,8.259.312,0"
            transform="matrix(-0.985, -0.174, 0.174, -0.985, 11.21, 10.46)"
            fill="none"
            stroke="#c70000"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <g
            transform="translate(13.373 11.357) rotate(159)"
            fill="#fff"
          >
            <path
              d="M 0.7317740917205811 0.9999724626541138 C 0.64913409948349 0.9999724626541138 0.5947241187095642 0.9577124714851379 0.5696440935134888 0.9325224757194519 C 0.5244541168212891 0.8871424794197083 0.4997241199016571 0.825892448425293 0.5000041127204895 0.7600624561309814 C 0.5005841255187988 0.6225025057792664 0.6098141074180603 0.510112464427948 0.7434940934181213 0.5095424652099609 L 2.957924127578735 0.5000025033950806 C 3.041614055633545 0.4999924898147583 3.096024036407471 0.5422524809837341 3.121104001998901 0.5674424767494202 C 3.166294097900391 0.6128224730491638 3.1910240650177 0.6740725040435791 3.190744161605835 0.7399024963378906 C 3.190164089202881 0.8774624466896057 3.080934047698975 0.9898524880409241 2.947254180908203 0.9904224872589111 L 0.7328240871429443 0.9999624490737915 L 0.7317740917205811 0.9999724626541138 Z"
              stroke="none"
            />
            <path
              d="M 0.7317681908607483 1.499969363212585 C 0.3255814611911774 1.4999680519104 -0.001741299754939973 1.168412327766418 4.107360837224405e-06 0.7579324841499329 C 0.001764107379131019 0.3463624715805054 0.3336641192436218 0.01130247581750154 0.741334080696106 0.009542476385831833 L 2.955774068832397 2.47623438554001e-06 C 2.956844091415405 -2.116661107720574e-06 2.957911252975464 -4.398765668156557e-06 2.958980083465576 -4.398765668156557e-06 C 3.365166664123535 -4.398765668156557e-06 3.692489624023438 0.3315526247024536 3.690744161605835 0.7420324683189392 C 3.688984155654907 1.153602480888367 3.357084035873413 1.488662481307983 2.949414014816284 1.490422487258911 L 0.7349740862846375 1.499962449073792 C 0.7339041829109192 1.499967098236084 0.7328370809555054 1.499969363212585 0.7317681908607483 1.499969363212585 Z"
              stroke="none"
              fill="#c70000"
            />
          </g>
          <g
            transform="translate(9.979 8.669) rotate(69)"
            fill="#fff"
          >
            <path
              d="M 2.987307786941528 0.9858525395393372 L 2.986147880554199 0.9858425259590149 L 0.7505277991294861 0.9763025641441345 C 0.6129677891731262 0.9757225513458252 0.5005778074264526 0.8664925694465637 0.5000078082084656 0.7328125238418579 C 0.4996477961540222 0.6501125693321228 0.5416477918624878 0.5955325365066528 0.566717803478241 0.5703525543212891 C 0.6118878126144409 0.5249825716018677 0.6729978322982788 0.4999925494194031 0.7387678027153015 0.4999925494194031 L 2.975527763366699 0.5095425248146057 C 3.113087892532349 0.510122537612915 3.225477695465088 0.6193525195121765 3.22604775428772 0.7530325651168823 C 3.226407766342163 0.8357325196266174 3.184407711029053 0.8903225660324097 3.159327745437622 0.9155025482177734 C 3.114157915115356 0.9608725309371948 3.053067922592163 0.9858525395393372 2.987307786941528 0.9858525395393372 Z"
              stroke="none"
            />
            <path
              d="M 2.987285375595093 1.485849499702454 C 2.986206293106079 1.485849380493164 2.985097885131836 1.485847115516663 2.984017848968506 1.48584258556366 L 0.7483978271484375 1.47630250453949 C 0.33682781457901 1.474542498588562 0.001767811016179621 1.142642498016357 7.811050636519212e-06 0.7349725365638733 C -0.00174756976775825 0.3283824622631073 0.328697681427002 -4.36958316640812e-06 0.7387703657150269 -4.36958316640812e-06 C 0.7398494482040405 -4.36958316640812e-06 0.7409576177597046 -2.048416035904665e-06 0.742037832736969 2.544479457355919e-06 L 2.977657794952393 0.00954254437237978 C 3.389227867126465 0.01130254473537207 3.724287748336792 0.343202531337738 3.72604775428772 0.7508725523948669 C 3.727803230285645 1.157462596893311 3.397357940673828 1.485864758491516 2.987285375595093 1.485849499702454 Z"
              stroke="none"
              fill="#c70000"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconFolderRedArrow;