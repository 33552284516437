import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../components/Layout/Alert/Alert';
import Delete from '../../../components/Icons/Delete';
import ImageModal from '../../../components/Modal/ImageModal/ImageModal';
import { confirmAlert } from 'react-confirm-alert';

const ImageList = ({ images, deleteImage }) => {
  const [activeModal, setActiveModal] = useState(false);
  const [imageIndex, setImageIndex] = useState('');

  const deleteImageAlert = useCallback(
    (imageId, imageType) => {
      confirmAlert({
        title: 'Confirm to delete',
        message: 'Are you sure?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              deleteImage(imageId, imageType);
            },
          },
          {
            label: 'No',
          },
        ],
      });
    },
    [deleteImage]
  );

  const mapImages = useCallback(
    (image, index) => {
      return (
        <li className="image-list-item" key={image.id}>
          <img
            className="image"
            src={image.url}
            alt={image.imageName}
            width="250"
            onClick={() => {
              setActiveModal(true);
              setImageIndex(index);
            }}
          />
          <div className="image-info-wrapper">
            <div className="options">
              <ul className="icon-list">
                <li
                  className="icon-list-item"
                  onClick={() => deleteImageAlert(image.id, image.imageType)}
                >
                  <Delete size="md" />
                </li>
              </ul>
            </div>
          </div>
        </li>
      );
    },
    [deleteImageAlert]
  );

  return (
    <>
      <ImageModal
        isActive={activeModal}
        myClassName="full-size-img-modal"
        onClose={() => setActiveModal(false)}
        images={images}
        imageIndex={imageIndex}
        setImageIndex={setImageIndex}
      />
      <div className="image-category-wrapper">
        <ul className="image-list">{images.map(mapImages)}</ul>
        <h4>
          {images.length === 0 ? (
            <Alert type={'info'}>
              There is no images for choosen image type.
            </Alert>
          ) : null}
        </h4>
      </div>
    </>
  );
};

ImageList.propTypes = {
  images: PropTypes.array,
  deleteImage: PropTypes.func,
};

export default ImageList;
