import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectField from '../../../../../components/Form/SelectField';

const EventStatus = ({ filters, handleInputChange }) => {
  const prepareOptions = useMemo(() => {
    return [
      { id: 'ACTIVE', name: 'ACTIVE' },
      { id: 'DEACTIVATED', name: 'DEACTIVATED' },
    ];
  }, []);
  return (
    <div className="efb-form hide-label">
      <SelectField
        value={filters.status}
        options={prepareOptions}
        placeholder={'Event Visibility'}
        name="status"
        onChange={handleInputChange}
      />
    </div>
  );
};

EventStatus.propTypes = {
  handleInputChange: PropTypes.func,
};

export default EventStatus;
