import React from 'react';

export const ContentMng = (props) => {
    return (
	<span className='efb-icon'>
    	<svg className={`efb-icon--${props.size} ${props.cssClass}`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 128 128" >
	 
        <path fill="#556080" d="M86.3,9.8h-72c-1,0-1.9,0.8-1.9,1.9v114.4c0,1,0.8,1.9,1.9,1.9h89.8c1,0,1.9-0.8,1.9-1.9V29.5L86.3,9.8z"/>
        <path fill="#ECF0F1" d="M95.8,0h-72c-1,0-1.9,0.8-1.9,1.9v114.4c0,1,0.8,1.9,1.9,1.9h89.8c1,0,1.9-0.8,1.9-1.9V19.7L95.8,0z"/>
        <polygon fill="#8697CB" points="36.7,39.4 48,20.6 59.3,39.4 "/>
        <polygon fill="#91BAE1" points="46.1,39.4 61.1,13.1 76.1,39.4 "/>

        <path fill="#FEB719" d="M98.3,68.9H39.2c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5h59.1c1.4,0,2.5,1.1,2.5,2.5
            C100.7,67.8,99.6,68.9,98.3,68.9z"/>
        <path fill="#FEB719" d="M98.3,83.7H39.2c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5h59.1c1.4,0,2.5,1.1,2.5,2.5
            C100.7,82.6,99.6,83.7,98.3,83.7z"/>
        <path fill="#FEB719" d="M71.2,98.5h-32c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5h32c1.4,0,2.5,1.1,2.5,2.5
            C73.7,97.4,72.6,98.5,71.2,98.5z"/>

        <path fill="#CCD1D9" d="M95.8,0v17.8c0,1,0.8,1.9,1.9,1.9h17.8L95.8,0z"/>

    	</svg>     
		{props.children}
	</span>
    )
}

export default ContentMng;