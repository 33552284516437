import axiosInstance from '../axios-instance';
import { createRequestTypes, actionCreator } from '../index';
import { addNotificationMessage } from '../ui';

export const GET_MARKETS_BY_SPORT_EVENT = createRequestTypes(
  'GET_MARKETS_BY_SPORT_EVENT'
);
export const EVENT_SEARCH_GET_COUNTRIES = createRequestTypes(
  'EVENT_SEARCH_GET_COUNTRIES'
);
export const EVENT_SEARCH_GET_SPORTS = createRequestTypes(
  'EVENT_SEARCH_GET_SPORTS'
);
export const EVENT_SEARCH_GET_TOURNAMENTS = createRequestTypes(
  'EVENT_SEARCH_GET_TOURNAMENTS'
);
export const EVENT_SEARCH_GET_EVENTS = createRequestTypes(
  'EVENT_SEARCH_GET_EVENTS'
);
export const SEARCH_SPORT_EVENTS = createRequestTypes('SEARCH_SPORT_EVENTS');

export const getSports = () => async dispatch => {
  dispatch(actionCreator.request(EVENT_SEARCH_GET_SPORTS));
  try {
    dispatch(actionCreator.empty(EVENT_SEARCH_GET_SPORTS));
    const res = await axiosInstance.get('/sport/event/view/sport/admin');
    dispatch(actionCreator.success(EVENT_SEARCH_GET_SPORTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(EVENT_SEARCH_GET_SPORTS, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getCountries = sportId => async dispatch => {
  dispatch(actionCreator.request(EVENT_SEARCH_GET_COUNTRIES));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/category/admin?sportId=${sportId}`
    );
    dispatch(actionCreator.success(EVENT_SEARCH_GET_COUNTRIES, res));
  } catch (err) {
    dispatch(actionCreator.failure(EVENT_SEARCH_GET_COUNTRIES, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getTournaments = countryId => async dispatch => {
  dispatch(actionCreator.request(EVENT_SEARCH_GET_TOURNAMENTS));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/tournament/admin?categoryId=${countryId}`
    );
    dispatch(actionCreator.success(EVENT_SEARCH_GET_TOURNAMENTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(EVENT_SEARCH_GET_TOURNAMENTS, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getEvents = (id, date) => async dispatch => {
  dispatch(actionCreator.request(EVENT_SEARCH_GET_EVENTS));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/match/admin?${
        date ? `startTimeAfter=${date}&` : ''
      }tournamentId=${id}`
    );
    dispatch(actionCreator.success(EVENT_SEARCH_GET_EVENTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(EVENT_SEARCH_GET_EVENTS, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getMarketsBySportEvent = sportEventId => async dispatch => {
  dispatch(actionCreator.request(GET_MARKETS_BY_SPORT_EVENT));
  try {
    const res = await axiosInstance.get(
      `/market/view/market/admin?sportEventId=${sportEventId}`
    );
    dispatch(actionCreator.success(GET_MARKETS_BY_SPORT_EVENT, res));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const searchSportEvents = text => async dispatch => {
  dispatch(actionCreator.request(SEARCH_SPORT_EVENTS));
  try {
    const res = await axiosInstance.get(
      '/sport/event/view/sport-event/admin/search',
      {
        params: {
          lang: 'en',
          t: text,
        },
      }
    );
    dispatch(actionCreator.success(SEARCH_SPORT_EVENTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(SEARCH_SPORT_EVENTS, err.message));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const clearSearchSportEvents = () => dispatch => {
  dispatch(actionCreator.empty(SEARCH_SPORT_EVENTS));
};
