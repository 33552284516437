import { createRequestTypes, actionCreator } from '../';
import axiosInstance from '../axios-instance';
import { addNotificationMessage } from '../ui';

export const UDPATE_USER_SETTINGS = createRequestTypes('UPDATE_USER_SETTINGS');
export const GET_USER_SETTINGS = createRequestTypes('GET_USER_SETTINGS');

export const updateUserSettings = (payload, settingsId) => async dispatch => {
  dispatch(actionCreator.request(UDPATE_USER_SETTINGS));
  try {
    await axiosInstance.put(
      `/riskmgnt/view/admin/userSettings/${settingsId}`,
      payload
    );
    dispatch(actionCreator.success(UDPATE_USER_SETTINGS));
    dispatch(
      addNotificationMessage('User settings saved!', 'success', 'Success!')
    );
  } catch (error) {
    dispatch(actionCreator.failure(UDPATE_USER_SETTINGS));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getUserSettings = customerId => async dispatch => {
  dispatch(actionCreator.request(GET_USER_SETTINGS));
  try {
    const res = await axiosInstance.get(
      `/riskmgnt/view/admin/userSettings/findByCustomerId?customerId=${customerId}`
    );
    dispatch(actionCreator.success(GET_USER_SETTINGS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_USER_SETTINGS));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};
