import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useFetch } from '../../../hooks';

const ModalAccountInfo = ({ referralType, systemReoffer, betslip }) => {
  const accountMap = useMemo(() => {
    return {
      MAIN: 'Main Balance',
      LOCK_FUND: 'Locked Funds',
      SPORT_BET: 'Sport Bets',
      SPORT_BONUS: 'Sport Bonus',
      CASINO_BONUS: 'Casino Bonus',
      FREE_BET: 'Free Bet',
    };
  }, []);

  const { data: accounts } = useFetch(
    `account/view/admin/accounts/${betslip.customerId}`
  );
  const { data: customerSettings } = useFetch(
    `riskmgnt/view/admin/userSettings/findByCustomerId?customerId=${betslip.customerId}`
  );

  const { data: customerNotes } = useFetch(
    `/customer/view/admin/${betslip.customerId}/note`
  );

  return (
    <div className="account-info-wrapper">
      <div className="row">
        <div className="col-md-8">
          <ul className="accounts-list">
            {accounts
              ? accounts.map(account =>
                  account.accountType !== 'SPORT_BONUS' &&
                  account.accountType !== 'CASINO_BONUS' ? (
                    <li
                      key={account.accountType}
                      className="d-flex justify-content-between"
                    >
                      <span>{accountMap[account.accountType]}:</span>
                      <strong>
                        {Number(account.balance / 100).toFixed(2)} EUR
                      </strong>
                    </li>
                  ) : null
                )
              : null}
            <li>
              <span>Bet Factor: </span>
              <strong>
                {customerSettings
                  ? Number(customerSettings.betFactor).toFixed(2)
                  : '/'}
              </strong>
            </li>
          </ul>
        </div>
        <div className="col-md-4">
          <h5>Note!</h5>
          <ul>
            {customerNotes && customerNotes.length > 0
              ? customerNotes.map((note, index) => {
                  return <li key={index}>{note.note}</li>;
                })
              : '/'}
          </ul>
        </div>
        <div className="col-md">
          <span>
            <i className="fa fa-info-circle" /> Referral type: {referralType}{' '}
            {referralType === 'BETSLIP_REFFERAL_WITH_REOFFER' ? (
              <>
                | <i className="fa fa-exclamation-triangle" /> Reoffer reason:{' '}
                {systemReoffer.reason}
              </>
            ) : (
              ''
            )}
          </span>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md">
          <ul className="more-details-list">
            <li className="more-details-list-item">
              <span>
                <i className="fa fa-clock" /> Created at:{' '}
                {moment(betslip.createdAd).format('DD.MM.YYYY, HH:mm:ss')}
              </span>
            </li>

            <li className="more-details-list-item">
              <Link
                target="_blank"
                to={`/adm/rm/bet-history?customerId=${betslip.customerId}`}
              >
                <i className="fa fa-history" /> Bet History
              </Link>
            </li>
            <li className="more-details-list-item">
              <Link
                target="_blank"
                to={`/adm/pm/manager?clientId=${betslip.customerId}&tab=clientProfile`}
              >
                <i className="fa fa-user-alt" /> Client Profile
              </Link>
            </li>
            <li className="more-details-list-item">
              <Link
                target="_blank"
                to={`/adm/pm/manager?clientId=${betslip.customerId}&tab=clientRisk`}
              >
                <i className="fa fa-user-alt" /> Client Risk
              </Link>
            </li>
            <li className="more-details-list-item text-danger">
              {/* Will be used later */}
              {/* <Link className='text-danger'> */}
              <i className="fa fa-user-alt" /> Show book
              {/* </Link> */}
            </li>
          </ul>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default ModalAccountInfo;
