import React, { useCallback, useState, useMemo } from 'react';
import Button from '../../../../../../components/Layout/Button';
import TextEditor from '../TextEditor';
import {
  useStateForSelect,
  useUpdate,
  useStateForInput,
} from '../../../../../../hooks';
import Select from 'react-select';
import { selectStyles } from '../../../../../CMS/customReactSelectStyle';
import BusinessUnitSelector from '../../../../../../components/BusinessUnitSelector/BusinessUnitSelector';
import LanguageSelector from '../../../../../../components/LanguageSelector/LanguageSelector';

const MessageTemplateForm = ({
  names,
  placeholders,
  getListByName,
  createMessageTemplate,
  templates,
}) => {
  const [name, setName] = useStateForSelect('');
  const [text, setText] = useStateForInput('');
  const [businessUnit, setBusinessUnit] = useStateForSelect('BG');
  const [language, setLanguage] = useStateForSelect('en');
  const [editTemplateId, setEditTemplateId] = useState('');
  const [templateForEdit, setTemplateForEdit] = useState('');
  const [editMode, setEditMode] = useState(false);

  useUpdate(() => {
    if (templates.length && businessUnit && language) {
      const templateForEdit = templates.find(x =>
        x.language === language &&
        x.businessUnits.find(bu => bu.name === businessUnit)
          ? x.businessUnits.find(bu => bu.name === businessUnit).name ===
            businessUnit
          : ''
      );
      setTemplateForEdit(templateForEdit);
    }
  }, [templates, businessUnit, language]);

  useUpdate(() => {
    if (templateForEdit) {
      setText(templateForEdit.text);
      setEditTemplateId(templateForEdit.id);
      setTemplateForEdit(templateForEdit);
      setEditMode(true);
    } else {
      setEditMode(false);
      setText('');
      setEditTemplateId('');
      setTemplateForEdit('');
    }
  }, [templateForEdit]);

  useUpdate(() => {
    getListByName(name);
  }, [name]);

  const createTemplate = useCallback(() => {
    const templateObj = {
      name: name.value,
      text,
      language,
      businessUnits: [
        {
          domain: businessUnit,
          name: businessUnit,
        },
      ],
    };
    createMessageTemplate(templateObj, editMode, editTemplateId);
    setTemplateForEdit('');
    setName('');
    setText('');
    setBusinessUnit('BG');
    setLanguage('en');
    setEditTemplateId('');
    if (editMode) setEditMode(false);
  }, [
    businessUnit,
    createMessageTemplate,
    editMode,
    editTemplateId,
    language,
    name.value,
    setBusinessUnit,
    setLanguage,
    setName,
    setText,
    text,
  ]);

  const namesList = useMemo(() => {
    return names
      ? names.map(name => {
          return {
            label: name,
            value: name,
          };
        })
      : [];
  }, [names]);

  return (
    <div>
      <h3>Message Templates</h3>
      <div>
        <div className="row">
          <div className="col-md">
            <BusinessUnitSelector
              defaultBu={businessUnit}
              onChange={setBusinessUnit}
            />
          </div>
          <div className="col-md">
            <LanguageSelector defaultLang={language} onChange={setLanguage} />
          </div>
          <div className="col-md">
            <Select
              value={name}
              onChange={setName}
              options={namesList}
              styles={selectStyles}
              className="mb-3"
              placeholder="Select Name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <TextEditor
              text={text}
              htmlBody={setText}
              placeholders={placeholders}
            />
            <small>
              <i className="fa fa-info-circle"></i> You can find list of
              placeholders in link toolbar.
            </small>
            <br />
            <br />
            <div className="form-group d-flex flex-row justify-content-start">
              <Button cssClass="efb-btn__main" onClick={createTemplate}>
                {templateForEdit ? 'Update' : 'Create'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageTemplateForm;
