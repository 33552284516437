import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import * as NraScgActions from '../../../../redux/actions/reporting/nra-scg';
import * as UiActions from '../../../../redux/actions/ui';
import * as CustomerActions from '../../../../redux/actions/customer/customerData';
import { useStateForInput } from '../../../../hooks';
import Input from '../../../../components/Form/Input';
import Button from '../../../../components/Layout/Button';
import moment from 'moment';

const CustomerDetails = ({
  getPlayerReports,
  getCustomerCitizenship,
  addNotificationMessage,
}) => {
  const [personalId, setPersonalId] = useStateForInput('');
  const [regulatoryId, setRegulatoryId] = useStateForInput('');

  const generateReport = useCallback(async () => {
    if (personalId || regulatoryId) {
      const data = await getPlayerReports({ personalId, regulatoryId });
      const promisedData =
        data &&
        [data].map(async entry => {
          const citizenship =
            entry.nationality &&
            (await getCustomerCitizenship(entry.nationality));
          return {
            Name: entry.firstName,
            'Middle Name': entry.middleName,
            Surname: entry.surnameName,
            Nationality: citizenship
              ? citizenship.nationalityNames.en.name
              : null,
            'Personal Identificator': entry.egn,
            'Type of ID document':
              entry.documents.length > 0
                ? entry.documents[0].document_type
                : null,
            'Number of ID document':
              entry.documents.length > 0
                ? entry.documents[0].documentNumber
                : null,
            'Date of issue of ID document':
              entry.documents.length > 0
                ? moment(entry.documents[0].issueDate).format('DD/MM/YYYY')
                : null,
            'Residential Address': entry.residentialAddress,
            'Current Address': entry.currentAddress,
            'Player ID NRA': entry.napId,
            'Player ID SCG': entry.dkhId,
          };
        });

      promisedData &&
        Promise.all(promisedData).then(sheetData => {
          const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          const ws = XLSX.utils.json_to_sheet(sheetData);
          ws['!cols'] = [
            'Name',
            'Middle Name',
            'Surname',
            'Nationality',
            'Personal Identificator',
            'Type of ID document',
            'Number of ID document',
            'Date of issue of ID document',
            'Residential Address',
            'Current Address',
            'Player ID NRA',
            'Player ID SCG',
          ].map(type => ({ wch: type.length }));
          const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
          const excelBuffer = XLSX.write(wb, {
            bookType: 'xlsx',
            type: 'array',
          });
          const file = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(file, 'Player Details.xlsx');
        });
    } else {
      addNotificationMessage(
        'Either field is required!',
        'warning',
        'Warning!'
      );
    }
  }, [
    addNotificationMessage,
    getCustomerCitizenship,
    getPlayerReports,
    personalId,
    regulatoryId,
  ]);

  return (
    <div className="efb-form">
      <p>
        2. Customer details Report - report that generate customer details based
        on Personal Identification Number filter or other identificator allowed
      </p>
      <Input
        name="personalId"
        placeholder="Enter personal identificator"
        className="form-control mb-3"
        value={personalId}
        onChange={setPersonalId}
      />

      <Input
        name="regulatoryId"
        placeholder="Enter regulatory ID"
        className="form-control"
        value={regulatoryId}
        onChange={setRegulatoryId}
      />

      <Button cssClass="efb-btn__main btn-block mt-3" onClick={generateReport}>
        Generate Report
      </Button>
    </div>
  );
};

const mapDispatchToProps = {
  getPlayerReports: NraScgActions.getPlayerReports,
  addNotificationMessage: UiActions.addNotificationMessage,
  getCustomerCitizenship: CustomerActions.getCustomerCitizenship,
};

export default connect(null, mapDispatchToProps)(CustomerDetails);
