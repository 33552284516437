import {
	GET_EVENT_SPORT_INFO,
	GET_MARKET,
	GET_SPORTS_TOURNAMENTS_CATEGORIES_EVENTS,
	GET_MARKETS,
	GET_EVENT
} from '../../actions/riskManagement/settlement';

const initalState = {
	eventMarkets: [],
	eventSportInfo: {},
	market: {},
	markets: [],
	placedBetsFiltered: {}
};

export const settlementsReducer = (state = initalState, action) => {
	switch (action.type) {
		case GET_EVENT_SPORT_INFO.SUCCESS:
			return {
				...state,
				eventSportInfo: action.payload
			};

		case GET_MARKET.SUCCESS:
			return {
				...state,
				market: action.payload
			};

		case GET_SPORTS_TOURNAMENTS_CATEGORIES_EVENTS.SUCCESS:
			return {
				...state,
				placedBetsFiltered: action.payload
			};

		case GET_MARKETS.SUCCESS:
			return {
				...state,
				markets: action.payload
			};

		case GET_EVENT.SUCCESS:
			return {
				...state,
				event: action.payload
			}

		default:
			return state;
	}
};
