import React from 'react';
import PropTypes from 'prop-types';
import classes from './DragAndDrop.module.scss';

const DragAndDrop = ({
  data,
  children,
  onDrop,
  onDragStart,
  onDragOver,
  onDragEnter,
  onDragLeave
}) => {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  const customOnDragStart = e => {
    const { index } = e.target.dataset;
    e.dataTransfer.setData('index', index);
    if (onDragStart) onDragStart(index, e);
  };

  const customOnDragOver = e => {
    e.preventDefault();
    if (onDragOver) onDragOver(e);
  };

  const customOnDragEnter = e => {
    const tr = getClosest(e.target, 'tr');
    if (tr) {
      tr.classList.add(classes['draganddrop-placeholder']);
    }
    if (onDragEnter) onDragEnter(e);
  };

  const customOnDragLeave = e => {
    const tr = getClosest(e.target, 'tr');
    if (tr) {
      tr.classList.remove(classes['draganddrop-placeholder']);
    }
    if (onDragLeave) onDragLeave(e);
  };

  const getClosest = (el, tag, className = null) => {
    tag = tag.toUpperCase();
    do {
      if (
        el.tagName === tag &&
        (!className || el.classList.value.indexOf(className) > -1)
      ) {
        return el;
      }
    } while ((el = el.parentElement));

    return null;
  };

  const customOnDrop = e => {
    customOnDragLeave(e);
    const startIndex = e.dataTransfer.getData('index');
    const endIndex = rows.findIndex(cat => cat.id === getClosest(e.target, 'tr').dataset.id);
    const newData = reorder(rows, startIndex, endIndex);
    onDrop(newData);
  };

  const reorder = (list, from, to) => {
    const result = Array.from(list);
    result.splice(to, 0, result.splice(from, 1)[0]);
    return result;
  };

  const draggableProps = {
    draggable: true,
    onDragStart: customOnDragStart,
    onDragEnter: customOnDragEnter,
    onDragLeave: customOnDragLeave
  };

  const droppableProps = {
    onDragOver: customOnDragOver,
    onDrop: customOnDrop
  };

  return children({
    draggableProps,
    droppableProps
  });
};

DragAndDrop.propTypes = {
  data: PropTypes.array.isRequired,
  onDrop: PropTypes.func.isRequired,
  onDragStart: PropTypes.func,
  onDragOver: PropTypes.func,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func
};

export default DragAndDrop;
