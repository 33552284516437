import React from 'react';
import Alert from '../Icons/Alert';

export const BreadCrumbs = () => {
  return (
    <section className="efb-breadcrumbs">
      <div className="efb-container">
        <nav className="efb-breadcrumbs__nav">Main Dashbord/</nav>
        <aside className="efb-breadcrumbs__section">
          <h2>
            <span>Thin</span> Base
          </h2>
          <Alert size="xs" />
        </aside>
      </div>
    </section>
  );
};

export default BreadCrumbs;
