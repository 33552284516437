import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../../components/Form/Checkbox';
import AppViewPrivilegesRoles from '../Roles/AppViewPrivilegesRoles';

class AppViewPrivilegesUsers extends Component {
  generateActionsColumn = view => {
    let actionsColumn;
    switch (view.actions.length) {
      case 0:
        actionsColumn = (
          <Fragment>
            <Checkbox
              type="userActivity"
              readOnly={true}
              isActive={false}
              name="read"
              label="Read"
              disabled={this.props.readOnly}
              onChange={() => this.handleActionsChange('read', view.label)}
            />
            <Checkbox
              type="userActivity"
              readOnly={true}
              isActive={false}
              name="write"
              label="Write"
              disabled={this.props.readOnly}
              onChange={() => this.handleActionsChange('write', view.label)}
            />
          </Fragment>
        );
        break;
      case 1:
        actionsColumn = (
          <Fragment>
            <Checkbox
              type="userActivity"
              readOnly={this.props.readOnly}
              isActive={true}
              name="read"
              label="Read"
              disabled={this.props.readOnly}
              onChange={() => this.handleActionsChange('read', view.label)}
            />
            <Checkbox
              type="userActivity"
              readOnly={this.props.readOnly}
              isActive={false}
              name="write"
              label="Write"
              disabled={this.props.readOnly}
              onChange={() => this.handleActionsChange('write', view.label)}
            />
          </Fragment>
        );
        break;
      case 2:
        actionsColumn = (
          <Fragment>
            <Checkbox
              type="userActivity"
              readOnly={this.props.readOnly}
              isActive={true}
              name="read"
              label="Read"
              disabled={this.props.readOnly}
              onChange={() => this.handleActionsChange('read', view.label)}
            />
            <Checkbox
              type="userActivity"
              readOnly={this.props.readOnly}
              isActive={true}
              name="write"
              label="Write"
              disabled={this.props.readOnly}
              onChange={() => this.handleActionsChange('write', view.label)}
            />
          </Fragment>
        );
        break;
      default:
        actionsColumn = null;
    }
    return actionsColumn;
  };

  render() {
    let { transformedUserRoles } = this.props;

    return (
      <Fragment>
        <section className="efb-app-view-priv-header">
          <span style={{ width: '25%' }}>Application</span>
          <span style={{ width: '25%' }}>View</span>
          <span style={{ width: '25%' }}>Privileges/Actions</span>
          <span style={{ width: '25%' }}>Role</span>
        </section>
        <section className="efb-app-view-priv-container">
          {transformedUserRoles.map((transformedRole, idx) => {
            return transformedRole.role === 'SERVICE-ROLE' ? (
              <section
                className="efb-app-view-priv-container role-section"
                key={idx}
              >
                <section style={{ width: '20%' }}>--</section>
                <section
                  style={{ flexDirection: 'column', width: '50%' }}
                  className="efb-app-view-priv"
                >
                  <section className="efb-app-view-priv__view-priv">
                    <div className="efb-app-view-priv__view-priv__view">
                      <span>--</span>
                    </div>
                    <div className="efb-app-view-priv__view-priv__actions">
                      <span> </span>
                      <div>--</div>
                    </div>
                  </section>
                </section>
                <section style={{ width: '20%' }}>
                  {transformedRole.role}
                </section>
              </section>
            ) : (
              Object.keys(transformedRole['applications']).map((key, inx) => (
                <section
                  className="efb-app-view-priv-container role-section"
                  key={inx}
                >
                  <Fragment>
                    <section style={{ width: '20%' }}>{key}</section>
                    <section
                      style={{ flexDirection: 'column', width: '50%' }}
                      className="efb-app-view-priv"
                    >
                      {Object.keys(transformedRole['applications'][key]).map(
                        (key2, idx2) => (
                          <section
                            className="efb-app-view-priv__view-priv"
                            key={idx2}
                          >
                            <div
                              className={
                                transformedRole['applications'][key][key2]
                                  .actions.length
                                  ? 'efb-app-view-priv__view-priv__view'
                                  : 'efb-app-view-priv__view-priv__view disabled'
                              }
                            >
                              <span>
                                {
                                  transformedRole['applications'][key][key2]
                                    .label
                                }
                              </span>
                            </div>
                            <div className="efb-app-view-priv__view-priv__actions">
                              <span> {transformedRole.domainObject} </span>
                              <div>
                                {this.generateActionsColumn(
                                  transformedRole['applications'][key][key2]
                                )}
                              </div>
                            </div>
                          </section>
                        )
                      )}
                    </section>
                    <section style={{ width: '20%' }}>
                      {transformedRole.role}
                    </section>
                  </Fragment>
                </section>
              ))
            );
          })}
        </section>
      </Fragment>
    );
  }
}

AppViewPrivilegesRoles.propTypes = {
  role: PropTypes.object,
  readOnly: PropTypes.bool,
  userRoles: PropTypes.array,
  transformedUserRoles: PropTypes.array
};

export default AppViewPrivilegesUsers;
