import React, { useState } from 'react';

import classes from './HistoryModal.module.scss';
import FiltersMenu from './FiltersMenu/FiltersMenu';
import Modal from '../NewModal/Modal';
import Table from '../NewTable/Table';
import Footer from './Footer/Footer';
import Header from './Header/Header';

const HistoryModal = ({
  filtersConfig,
  columns,
  history,
  onFiltersChange,
  headerTitle,
  footerData,
  openMenu = false,
  footer = false,
  pagination = false,
}) => {
  const [openedFiltersMenu, setOpenedFiltersMenu] = useState(openMenu);
  return (
    <Modal className={classes.wrapper} showCloseBtn={false} isActive>
      <Header
        openedFiltersMenu={openedFiltersMenu}
        setOpenedFiltersMenu={setOpenedFiltersMenu}
        headerTitle={headerTitle}
      />
      <div className="d-flex flex-row flex-grow-1 position-relative modal">
        <FiltersMenu
          config={filtersConfig}
          opened={openedFiltersMenu}
          close={() => setOpenedFiltersMenu(false)}
          onFiltersChange={onFiltersChange}
        />
        <div
          className={`${classes.sidebar} flex-grow-1 position-absolute`}
          style={{ marginLeft: openedFiltersMenu ? 300 : 0 }}
        >
          <div className={`flex-grow-1 ${!footer ? 'mb-4' : ''}`}>
            <Table columns={columns} data={history} pagination={pagination} />
          </div>
        </div>
        {footerData ? <Footer footerData={footerData} /> : null}
      </div>
    </Modal>
  );
};

export default HistoryModal;
