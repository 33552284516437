import { action } from '.';
//Requests (TODO: At some point in needs to go in folder 'middleware/requests.js');

export const SET_WS_FRAME_OBJECT = 'SET_WS_FRAME_OBJECT',
  SET_WS_CONNECTION_STATUS = 'SET_WS_CONNECTION_STATUS';

export const wsActions = {
  setWSFrameObj: payload => action(SET_WS_FRAME_OBJECT, { payload }),
  setWsConnectionStatus: payload =>
    action(SET_WS_CONNECTION_STATUS, { payload })
};

export const setWSFrameObj = frameObj => {
  return dispatch => {
    dispatch(wsActions.setWSFrameObj(frameObj));
  };
};

export const setWsConnectionStatus = status => {
  return dispatch => {
    dispatch(wsActions.setWsConnectionStatus(status));
  };
};
