import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Switch from '../../../../components/Form/Switch/Switch';
import Table from '../../../../components/NewTable/Table';
import Modal from '../../../../components/Modal/Modal';
import InputField from '../../../../components/Form/InputField';
import moment from 'moment';
import Button from '../../../../components/Layout/Button';
import { updateEventDate } from '../EventBuilderUtils';

const EventsTable = ({
  events,
  addMarketToEvent,
  updateEventStartTime,
  filters,
  updateMatchStatus,
}) => {
  const [activeModal, setActiveModal] = useState(false);
  const [editingEvent, setEditingEvent] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventTime, setEventTime] = useState('');

  const changeEventStatus = useCallback(
    event => {
      updateMatchStatus({
        ...event,
        status: event.status === 'ACTIVE' ? 'DEACTIVATED' : 'ACTIVE',
      });
    },
    [updateMatchStatus]
  );

  const columns = [
    { name: 'Event', display: entity => entity.names.en.name },
    {
      name: 'Match Status',
      display: entity =>
        entity.competitionStatus.matchStatusType.names.en.name ||
        entity.competitionStatus.competitionStatus,
    },
    {
      name: 'Active',
      display: entity => (
        <Switch
          defIsChecked={entity.status === 'ACTIVE'}
          onChange={() => changeEventStatus(entity)}
        />
      ),
    },
    {
      name: 'Start',
      display: entity =>
        moment(entity.scheduledTime).format('DD/MM/YY HH:mm:ss'),
    },
    {
      name: 'Action',
      display: entity => (
        <>
          <Button
            disabled={entity.feedProducers[0].producerId !== '14'}
            cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
            onClick={() => addMarketToEvent(entity)}
          >
            <i className="fas fa-plus" /> Add markets
          </Button>

          <Button
            disabled={entity.feedProducers[0].producerId !== '14'}
            cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
            onClick={() => editEventDate(entity)}
          >
            <i className="fas fa-calendar" /> Edit event date
          </Button>
        </>
      ),
    },
  ];

  const editEventDate = useCallback(event => {
    setActiveModal(true);
    setEditingEvent(event);
    setEventDate(moment(event.scheduledTime).format('YYYY-MM-DD'));
    setEventTime(moment(event.scheduledTime).format('HH:mm'));
  }, []);

  const handleDateChange = useCallback(e => {
    const { name, value } = e.target;
    if (name === 'time') {
      setEventTime(value);
    }
    if (name === 'date') {
      setEventDate(value);
    }
  }, []);

  const updateEvent = useCallback(() => {
    setActiveModal(false);
    updateEventStartTime(
      updateEventDate(eventDate, eventTime, editingEvent),
      editingEvent.tournamentId
    );
  }, [eventDate, eventTime, editingEvent, updateEventStartTime]);

  return (
    <Fragment>
      <Modal
        isActive={activeModal}
        onClose={() => setActiveModal(false)}
        headerText={editingEvent.names ? editingEvent.names.en.name : null}
      >
        <div className="modal-wrapper">
          <br />
          <div className="efb-form">
            <div className="form-row">
              <div className="form-label-group col-md">
                <InputField
                  type="date"
                  name="date"
                  label="Date"
                  value={eventDate}
                  className="form-control efb-input"
                  onChange={handleDateChange}
                />
              </div>
              <div className="form-label-group col-md">
                <InputField
                  type="time"
                  name="time"
                  label="Time"
                  value={eventTime}
                  className="form-control efb-input"
                  onChange={handleDateChange}
                />
              </div>
            </div>
            <hr />
            <Button cssClass="efb-btn__main" onClick={updateEvent}>
              Save
            </Button>
          </div>
        </div>
      </Modal>

      <h3 className="efb-h3">Events list</h3>
      <Table columns={columns} data={events} />
    </Fragment>
  );
};

EventsTable.propTypes = {
  events: PropTypes.array,
  addMarketToEvent: PropTypes.func,
  filters: PropTypes.object,
  updateEventStartTime: PropTypes.func,
};

export default EventsTable;
