import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../components/Table/Table';
import Modal from '../../../../components/Modal/Modal';
import { useUpdate } from '../../../../hooks';
import FullScreenLoader from '../../../../components/Layout/FullScreenLoader/FullScreenLoader';
import { Button } from '../../../../components/Layout';

const columns = [
  { name: 'No.' },
  { name: 'Market' },
  { name: 'Created on' },
  { name: 'Default Market' },
  { name: 'Status (Active / Inactive)' },
  { name: 'Action' },
];

const MarketsTable = ({
  eventMarkets,
  proceedToUpdateMarketStatus,
  loaded,
  updateMarketOdds,
  updateDefaultMarket,
}) => {
  const [markets, setMarkets] = useState([]);
  const [market, setMarket] = useState('');
  const [outcomes, setOutcomes] = useState([]);
  const [specifiers, setSpecifiers] = useState([]);
  const [activeOutcomesModal, setActiveOutcomesModal] = useState(false);
  const [activeSpecifiersModal, setActiveSpecifiersModal] = useState(false);
  const [outcomesTableHeader, setOutcomesTableHeader] = useState([]);
  const [specifiersTableHeader, setSpecifiersTableHeader] = useState([]);
  const [currentMarket, setCurrentMarket] = useState('');

  useUpdate(() => {
    setMarkets(eventMarkets);
  }, [eventMarkets]);

  const showMarketOdds = useCallback(market => {
    const outcomesArr = market.outcomeBetInfos.reduce(
      (acc, val) => [...acc, val],
      []
    );
    setMarket(market);
    const tableHeader = outcomesArr.reduce(
      (acc, val) => [...acc, { name: val.names.en.name }],
      []
    );
    setOutcomes(outcomesArr);
    setActiveOutcomesModal(true);
    setOutcomesTableHeader(tableHeader);
    setCurrentMarket(market);
  }, []);

  const showMarketSpecifiers = useCallback(market => {
    setActiveSpecifiersModal(true);
    const specifiersArr = Object.keys(market.specifiers).reduce(
      (acc, val, index) => [...acc, Object.values(market.specifiers)[index]],
      []
    );
    const tableHeader = Object.keys(market.specifiers).reduce(
      (acc, val) => [...acc, { name: val }],
      []
    );
    setSpecifiers(specifiersArr);
    setSpecifiersTableHeader(tableHeader);
    setCurrentMarket(market);
  }, []);

  const saveOdds = useCallback(
    e => {
      market.outcomeBetInfos = outcomes;
      updateMarketOdds(market);
      setActiveOutcomesModal(false);
    },
    [market, outcomes, updateMarketOdds]
  );

  return (
    <Fragment>
      <FullScreenLoader isActive={loaded} />
      <Modal
        headerText={currentMarket ? currentMarket.names.en.name : ''}
        isActive={activeOutcomesModal}
        onClose={() => setActiveOutcomesModal(false)}
      >
        <div className="modal-wrapper efb-form">
          <Table
            columns={outcomesTableHeader}
            data={outcomes}
            updateOutcomes={setOutcomes}
            tableType="eventBuilderMarketOutcomes"
            tableStyle="sortable table table-striped efb-table"
          />
          <br />
          <Button cssClass="efb-btn__main" onClick={saveOdds}>
            Save
          </Button>
        </div>
      </Modal>
      <Modal
        headerText={currentMarket ? currentMarket.names.en.name : ''}
        isActive={activeSpecifiersModal}
        onClose={() => setActiveSpecifiersModal(false)}
      >
        <div className="modal-wrapper">
          <Table
            columns={specifiersTableHeader}
            data={specifiers}
            tableType="eventBuilderMarketSpecifiers"
            tableStyle="sortable table table-hover table-striped efb-table"
          />
        </div>
      </Modal>
      <Table
        columns={columns}
        data={markets}
        proceedToUpdateMarketStatus={proceedToUpdateMarketStatus}
        showMarketOdds={showMarketOdds}
        showMarketSpecifiers={showMarketSpecifiers}
        updateDefaultMarket={updateDefaultMarket}
        tableType="eventBuilderMarkets"
        tableStyle="sortable table table-hover table-striped efb-table events-search-table"
      />
    </Fragment>
  );
};

MarketsTable.propTypes = {
  eventMarkets: PropTypes.array,
  proceedToUpdateMarketStatus: PropTypes.func,
  loaded: PropTypes.bool,
};

export default MarketsTable;
