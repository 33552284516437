import React, { useMemo } from 'react';
import Select from 'react-select';
import { selectStyles } from '../../customReactSelectStyle';
import { useStateForSelect, useUpdate } from '../../../../hooks';

const CompetitorSportSelector = ({
  sports,
  competitorsObj,
  setCompetitorsObj,
  setSportId,
}) => {
  const [sport, setSport] = useStateForSelect('');

  useUpdate(() => {
    setCompetitorsObj({ ...competitorsObj, sportId: sport.value });
    setSportId(sport.value);
  }, [sport]);

  useUpdate(() => {
    if (!competitorsObj.sportId) setSport('');
  }, [competitorsObj]);

  const sportsList = useMemo(() => {
    return sports
      ? sports.reduce(
          (acc, sport) => [
            ...acc,
            {
              value: sport.id,
              label: sport.names.en ? sport.names.en.name : '',
            },
          ],
          []
        )
      : [];
  }, [sports]);

  return (
    <>
      <Select
        options={sportsList}
        onChange={setSport}
        value={sport}
        placeholder="Select Sport"
        styles={selectStyles}
      />
    </>
  );
};

export default CompetitorSportSelector;
