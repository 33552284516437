// import { LOGIN, AUTH_LOGOUT } from '../redux/actions/request';
import { AUTH, AUTH_LOGOUT } from '../../redux/actions/authentication';

const initialState = {
  isLogedIn: false,
  token: {}
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH.SUCCESS:
      return {
        ...state,
        isLogedIn: true,
        token: {
          ...action.payload
        }
      };
    case AUTH_LOGOUT.SUCCESS:
      return {
        ...state,
        isLogedIn: false,
        token: {}
      };
    default:
      return state;
  }
};

// export const authReducer = (state = initialState, action) => {
// 	if (action.type === LOGIN.REQUEST) {
// 		return { ...state, loading: true };
// 	}

// 	if (action.type === LOGIN.SUCCESS) {
// 		return {
// 			...state,
// 			isLogedIn: true,
// 			loading: false,
// 			token: {
// 				...action.response
// 			}
// 		};
// 	}

// 	if (action.type === LOGIN.FAILURE) {
// 		return { ...state, loading: false, error: action.error };
// 	}

// 	if (action.type === AUTH_LOGOUT) {
// 		return {
// 			...state,
// 			isLoggedIn: false,
// 			loading: false,
// 			token: {}
// 		};
// 	}
// 	return state;
// };
