import React, { Fragment } from 'react';
import Button from '../../Layout/Button';

const SportTypesRow = ({ tableData, editHandler }) => {
  return (
    <Fragment>
      {tableData.length !== 0 ? (
        tableData.map((data, inx) => (
          <tr key={inx}>
            <td>{data.code}</td>
            <td>{data.name}</td>
            <td>
              <i
                className={
                  data.options.includes('FOOTBALL')
                    ? 'fas fa-check text-success'
                    : 'fas fa-times'
                }
              />
            </td>
            <td>
              <i
                className={
                  data.options.includes('NUMBERS')
                    ? 'fas fa-check text-success'
                    : 'fas fa-times'
                }
              />
            </td>
            <td>
              <Button
                onClick={() => editHandler(data)}
                cssClass="btn btn-outline-secondary btn-xs m-2"
              >
                <i className="fas fa-edit" /> Edit
              </Button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td>No transactions found.</td>
        </tr>
      )}
    </Fragment>
  );
};

export default React.memo(SportTypesRow);
