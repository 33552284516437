import React, { useCallback } from 'react';
import classes from './ContextMenu.module.scss';

const ContextMenu = ({ rows, position, onClose }) => {
  const displayRow = useCallback(
    (row, i) => {
      let text;
      if (row.type === 'divider') {
        text = <hr />;
      } else if (row.Icon) {
        text = (
          <div className="d-flex flex-row align-items-center">
            <row.Icon height={15} width={15} className="mr-2" />
            {row.name}
          </div>
        );
      } else {
        text = row.name;
      }

      return (
        <li
          onClick={e => {
            if (row.onClick) {
              onClose();
              row.onClick(e);
            }
          }}
          key={i}
        >
          {text}
        </li>
      );
    },
    [onClose]
  );

  return (
    <div
      className={classes.background}
      style={{ height: document.body.scrollHeight }}
      onClick={onClose}
    >
      <ul
        className={classes.menu}
        style={{ ...position }}
        onClick={e => e.stopPropagation()}
      >
        {rows.map(displayRow)}
      </ul>
    </div>
  );
};

export default ContextMenu;
