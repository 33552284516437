import { ADD_NOTIFICATION, EMPTY_NOTIFICATION } from '../actions/ui';

const initialState = {
  message: '',
  level: '',
  title: '',
  position: '',
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return Object.assign({}, state, {
        message: action.payload.message,
        level: action.payload.level,
        title: action.payload.title,
        position: action.payload.position,
      });
    case EMPTY_NOTIFICATION:
      return initialState;
    default:
      return state;
  }
};
