import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useMount } from '../../../../hooks/index';
import { connect } from 'react-redux';
import {
  createLoadingSelector,
  getCashoutManagerData,
} from '../../../../selectors/index';
import * as CashoutManagerActions from '../../../../redux/actions/riskManagement/cashoutManager';
import CashoutModelsTable from '../CashoutTables/CashoutModelsTable';
import CashoutCoefficientTable from '../CashoutTables/CashoutCoefficientTable';

import Button from '../../../../components/Layout/Button';

const CashoutPrematchParameter = ({
  getPrematchModels,
  getPrematchMultipleModels,
  getPrematchMultipleStaticModels,
  getSystemStaticParameters,
  updatePrematchModels,
  updatePrematchMultipleModels,
  updatePrematchMultipleStaticModels,
  updateSystemStaticParameters,
  modelsPrematch,
  prematchMultipleModels,
  prematchMultipleStaticModels,
  systemStaticParameters,
  isLoading,
  history,
}) => {
  useMount(() => {
    getPrematchModels();
    getPrematchMultipleModels();
    getPrematchMultipleStaticModels();
    getSystemStaticParameters();
  });
  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          onClick={() =>
            history.push({
              pathname: '/adm/rm/ctm/calc',
              search: '?param=PRE_MATCH',
            })
          }
          cssClass="efb-btn__main"
        >
          Calculator
        </Button>
      </div>

      <CashoutModelsTable
        title={'Models'}
        models={modelsPrematch}
        update={updatePrematchModels}
        isLoading={isLoading}
      />
      <CashoutModelsTable
        title={'Multiple Models'}
        models={prematchMultipleModels}
        update={updatePrematchMultipleModels}
        isLoading={isLoading}
      />
      <CashoutCoefficientTable
        title={'Static Multiple Models'}
        data={prematchMultipleStaticModels}
        update={updatePrematchMultipleStaticModels}
        isLoading={isLoading}
      />
      <CashoutCoefficientTable
        title={'System Static Parameters'}
        data={systemStaticParameters}
        update={updateSystemStaticParameters}
        isLoading={isLoading}
      />
    </>
  );
};

const loadingReducer = createLoadingSelector([
  'GET_PREMATCH_MODELS',
  'GET_PREMATCH_MULTIPLE_MODELS',
  'GET_PREMATCH_MULTIPLE_STATIC_MODELS',
  'GET_SYSTEM_STATIC_PARAMETERS',
]);

const mapStateToProps = state => ({
  isLoading: loadingReducer(state),
  modelsPrematch: getCashoutManagerData(state).modelsPrematch,
  prematchMultipleModels: getCashoutManagerData(state).prematchMultipleModels,
  prematchMultipleStaticModels: getCashoutManagerData(state)
    .prematchMultipleStaticModels,
  systemStaticParameters: getCashoutManagerData(state).systemStaticParameters,
});

const mapDispatchToProps = {
  getPrematchModels: CashoutManagerActions.getPrematchModels,
  getPrematchMultipleModels: CashoutManagerActions.getPrematchMultipleModels,
  getPrematchMultipleStaticModels:
    CashoutManagerActions.getPrematchMultipleStaticModels,
  getSystemStaticParameters: CashoutManagerActions.getSystemStaticParameters,
  updatePrematchModels: CashoutManagerActions.updatePrematchModels,
  updatePrematchMultipleModels:
    CashoutManagerActions.updatePrematchMultipleModels,
  updatePrematchMultipleStaticModels:
    CashoutManagerActions.updatePrematchMultipleStaticModels,
  updateSystemStaticParameters:
    CashoutManagerActions.updateSystemStaticParameters,
};

CashoutPrematchParameter.propTypes = {
  getPrematchMultipleModels: PropTypes.func,
  getPrematchModels: PropTypes.func,
  getPrematchMultipleStaticModels: PropTypes.func,
  getSystemStaticParameters: PropTypes.func,
  updateSystemStaticParameters: PropTypes.func,
  updatePrematchMultipleStaticModels: PropTypes.func,
  updatePrematchMultipleModels: PropTypes.func,
  updatePrematchModels: PropTypes.func,
  modelsPrematch: PropTypes.array,
  prematchMultipleStaticModels: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CashoutPrematchParameter));
