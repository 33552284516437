import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { selectStyles } from '../../../../containers/CMS/customReactSelectStyle';
import {
  useStateForInput,
  useStateForSwitch,
  useUpdate,
} from '../../../../hooks';
import { addNotificationMessage } from '../../../../redux/actions/ui';
import { feesColumns } from '../transactionsUtils';

import * as transactionsActions from '../../../../redux/actions/TransactionBuilder';

import InputField from '../../../../components/Form/InputField';
import SelectField from '../../../../components/Form/SelectField';
import Button from '../../../../components/Layout/Button';
import Table from '../../../../components/NewTable/Table';
import Switch from '../../../../components/Form/Switch/Switch';

const Fees = ({
  paymentProvidersPerBSUnit,
  providerId,
  bsUnit,
  currencieOptions,
  createOrUpdateFee,
  addNotificationMessage,
}) => {
  const [currency, setCurrency] = useState('');
  const [fees, setFees] = useState('');

  const [depositAmount, setDepositAmount] = useStateForInput('');
  const [depositThreshold, setDepositThreshold] = useStateForInput('');
  const [depositThresholdAmount, setDepositTresholdAmount] = useStateForInput(
    false
  );
  const [depositType, setDeposityType] = useState('');
  const [depositAvailable, setDepositAvailable] = useStateForSwitch(false);

  const [withdrawAmount, setWithdrawAmount] = useStateForInput('');
  const [withdrawThreshold, setWithdrawThreshold] = useStateForInput('');
  const [
    withdrawThresholdAmount,
    setWithdrawThresholdAmount,
  ] = useStateForInput('');
  const [withdrawType, setWithdrawType] = useState('');
  const [withdrawAvailable, setWitdrawtAvailable] = useStateForSwitch(false);

  useUpdate(() => {
    const currentProvider = paymentProvidersPerBSUnit.find(
      provider => provider.paymentProviderId === providerId
    );
    if (currentProvider) {
      setFees(currentProvider.fees);
    }
  }, [paymentProvidersPerBSUnit]);

  const clearFees = useCallback(() => {
    setDepositAmount('');
    setDepositAvailable(false);
    setDepositThreshold('');
    setDepositTresholdAmount('');
    setWithdrawAmount('');
    setWithdrawThreshold('');
    setWithdrawThresholdAmount('');
    setWitdrawtAvailable(false);
  }, [
    setDepositAmount,
    setDepositAvailable,
    setDepositThreshold,
    setDepositTresholdAmount,
    setWitdrawtAvailable,
    setWithdrawAmount,
    setWithdrawThreshold,
    setWithdrawThresholdAmount,
  ]);

  const createFee = useCallback(async () => {
    if (!currency) {
      addNotificationMessage('Currency is required!', 'error', 'Error');
    } else {
      await createOrUpdateFee(
        {
          currencyCode: currency,
          providerId,
          depositAvailable,
          depositAmount: !depositAvailable ? 0 : depositAmount,
          depositThreshold: !depositAvailable ? 0 : depositThreshold,
          depositThresholdAmount: !depositAvailable
            ? 0
            : depositThresholdAmount,
          depositType: !depositAvailable ? 'TAX' : depositType,
          withdrawAvailable,
          withdrawAmount: !withdrawAvailable ? 0 : withdrawAmount,
          withdrawThreshold: !withdrawAvailable ? 0 : withdrawThreshold,
          withdrawThresholdAmount: !withdrawAvailable
            ? 0
            : withdrawThresholdAmount,
          withdrawType: !withdrawAvailable ? 'TAX' : withdrawType,
        },
        bsUnit
      );
      await clearFees();
    }
  }, [
    bsUnit,
    currency,
    clearFees,
    depositAmount,
    depositThreshold,
    depositThresholdAmount,
    depositType,
    withdrawAmount,
    withdrawThreshold,
    withdrawThresholdAmount,
    withdrawType,
    addNotificationMessage,
    createOrUpdateFee,
    depositAvailable,
    providerId,
    withdrawAvailable,
  ]);

  return (
    <div className="form-label-group efb-form p-3 m-auto">
      <div className="form-row d-flex justify-content-center p-5">
        <div className="col-8">
          <SelectField
            options={currencieOptions}
            onChange={e => setCurrency(e.target.value)}
            label="Currencies"
            name="currencyCode"
            placeholder="Currency"
            styles={selectStyles}
            value={currency}
          />
        </div>
      </div>
      <div>
        <b>Deposit Fees:</b>{' '}
      </div>
      <div className="form-row d-flex justify-content-center py-4">
        <div className="col-xl-2">
          <InputField
            name="depositAmount"
            value={depositAmount}
            type="number"
            min="0"
            className="form-control"
            label={'Fee Amount'}
            placeholder={'Fee Amount'}
            onChange={setDepositAmount}
            disabled={!depositAvailable ? true : false}
          />
        </div>
        <div className="col-xl-3">
          <InputField
            value={depositThreshold}
            name="depositThreshold"
            type="number"
            min="0"
            className="form-control"
            label={'Treshold Amount'}
            placeholder={'Treshold Amount'}
            onChange={setDepositThreshold}
            disabled={!depositAvailable ? true : false}
          />
        </div>
        <div className="col-xl-3">
          <InputField
            value={depositThresholdAmount}
            type="number"
            name="depositThresholdAmount"
            min="0"
            className="form-control"
            label={'Treshold Fee Amount'}
            placeholder={'Treshold Fee Amount'}
            onChange={setDepositTresholdAmount}
            disabled={!depositAvailable ? true : false}
          />
        </div>
        <div className="col">
          <SelectField
            options={[
              { id: 'TAX', name: 'TAX' },
              { id: 'PERCENT', name: 'PERCENT' },
            ]}
            onChange={e => setDeposityType(e.target.value)}
            label="Fee type"
            name="feeType"
            placeholder="Fee type"
            styles={selectStyles}
            value={depositType}
          />
        </div>
        <div className="col">
          <Switch
            name="Enable/Disable"
            label="Enable/Disable"
            isChecked={depositAvailable}
            onChange={setDepositAvailable}
          />
        </div>
      </div>
      <div>
        <b>Withdraw Fees:</b>{' '}
      </div>
      <div className="form-row d-flex justify-content-center py-4">
        <div className="col-xl-2">
          <InputField
            value={withdrawAmount}
            type="number"
            name="withdrawAmount"
            min="0"
            className="form-control"
            label={'Fee Amount'}
            placeholder={'Fee Amount'}
            onChange={setWithdrawAmount}
            disabled={!withdrawAvailable ? true : false}
          />
        </div>
        <div className="col-xl-3">
          <InputField
            value={withdrawThreshold}
            type="number"
            name="withdrawThreshold"
            min="0"
            className="form-control"
            label={'Treshold Amount'}
            placeholder={'Treshold Amount'}
            onChange={setWithdrawThreshold}
            disabled={!withdrawAvailable ? true : false}
          />
        </div>
        <div className="col-xl-3">
          <InputField
            value={withdrawThresholdAmount}
            type="number"
            name="withdrawThresholdAmount"
            min="0"
            className="form-control"
            label={'Treshold Fee Amount'}
            placeholder={'Treshold Fee Amount'}
            onChange={setWithdrawThresholdAmount}
            disabled={!withdrawAvailable ? true : false}
          />
        </div>
        <div className="col">
          <SelectField
            options={[
              { id: 'TAX', name: 'TAX' },
              { id: 'PERCENT', name: 'PERCENT' },
            ]}
            onChange={e => setWithdrawType(e.target.value)}
            label="Fee type"
            name="feeType"
            placeholder="Fee type"
            styles={selectStyles}
            value={withdrawType}
          />
        </div>
        <div className="col">
          <Switch
            name="Enable/Disable"
            label="Enable/Disable"
            isChecked={withdrawAvailable}
            onChange={setWitdrawtAvailable}
          />
        </div>
      </div>
      <div className="text-center">
        <Button cssClass="btn btn-success" onClick={createFee}>
          Save
        </Button>
      </div>
      <div className="m-auto py-3">
        {fees.length > 0 ? (
          <div className="payment-manager-table">
            <Table data={fees} columns={feesColumns} pagination={false} />
          </div>
        ) : (
          <h4 className="text-center mb-5">No fees are configured</h4>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  createOrUpdateFee: transactionsActions.createOrUpdateFee,
  addNotificationMessage,
};

export default connect(null, mapDispatchToProps)(Fees);
