import React, { Fragment, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../components/NewTable/Table';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/Layout/Button';
import Select from 'react-select';
import { selectStyles } from '../../../utils/selectStyles';
import { useStateForSelect } from '../../../hooks';
import { isEmpty } from '../../../utils/helpers';

const ChangeValidations = ({
  paymentMethods,
  addType,
  removeType,
  types,
  getAllDocuments,
  allDocumentsTypes,
  addNotificationMessage,
}) => {
  const [documentType, setDocumentType] = useStateForSelect('');
  const [activeModal, setActiveModal] = useState(false);
  const [payment, setPayment] = useState('');

  const documentTypesOptions = useMemo(() => {
    return types.map(el => ({
      value: el.documentType,
      label: el.documentType,
    }));
  }, [types]);

  const viewFullSize = useCallback(
    async paymentMethod => {
      await getAllDocuments(paymentMethod);
      setPayment(paymentMethod);
      setActiveModal(true);
    },
    [getAllDocuments]
  );

  const validateDocTypes = useCallback(
    (type, isAdding) => {
      if (isEmpty(type)) {
        addNotificationMessage('Document type is required!', 'error', 'Error');
        return false;
      }
      if (
        allDocumentsTypes[0].verificationDocuments.some(
          doc => doc.documentType === documentType.value
        ) &&
        isAdding
      ) {
        addNotificationMessage(
          'Document type allready exists!',
          'error',
          'Error'
        );
        return false;
      }

      if (
        allDocumentsTypes[0].verificationDocuments.length >= 1 &&
        payment === 'COMMON' &&
        isAdding
      ) {
        addNotificationMessage(
          'System method! Must be set only one Document Type (ID)!',
          'error',
          'Error'
        );
        return false;
      }
      return true;
    },
    [addNotificationMessage, allDocumentsTypes, documentType, payment]
  );

  const addOrRemove = useCallback(
    (type, action, isAdding) => {
      if (validateDocTypes(type, isAdding)) {
        action({
          verificationType: payment,
          documentType: type,
        });
      }
    },
    [payment, validateDocTypes]
  );

  const columnsForPaymentsMethod = useMemo(
    () => [
      { name: 'Payment Method', display: entity => entity.type },
      {
        name: 'Actions',
        display: entity => (
          <Button
            cssClass="btn btn-danger btn-md"
            onClick={() => viewFullSize(entity.type)}
          >
            <i className="fas fa-info" /> Details
          </Button>
        ),
      },
    ],
    [viewFullSize]
  );

  const columnsForTypes = useMemo(
    () => [
      { name: 'Document Type', display: entity => entity.documentType },
      {
        name: 'Inactive/Active',
        display: entity => (
          <Button
            cssClass="btn btn-danger btn-md"
            onClick={() => addOrRemove(entity.documentType, removeType, false)}
          >
            <i className="fa fa-trash" /> Remove type
          </Button>
        ),
      },
    ],
    [addOrRemove, removeType]
  );

  return (
    <Fragment>
      <h2 className="py-4 text-center">Change verification types</h2>

      <Modal
        isActive={activeModal}
        onClose={() => {
          setActiveModal(false);
          setPayment('');
        }}
      >
        <div className="text-center my-3">
          Chosen payment method: <strong>{payment}</strong>
        </div>
        {payment === 'COMMON' ? (
          <div className="text-center mt-3">
            <span className="text-danger border border-danger p-3">System method! Must be set only one Document Type (ID)!</span>
          </div>
        ) : null}

        <div className="d-flex my-5">
          <div className="form-label-group col-md-5 m-auto">
            <Select
              onChange={setDocumentType}
              value={documentType}
              options={documentTypesOptions}
              placeholder="Select document type"
              styles={selectStyles}
            />
          </div>
        </div>

        <div className="d-flex justify-content-center my-3">
          <Button
            cssClass="btn btn-md"
            onClick={() => addOrRemove(documentType.value, addType, true)}
          >
            <i className="fas fa-plus" /> Add Type
          </Button>
        </div>
        {allDocumentsTypes.length > 0 &&
        allDocumentsTypes[0].verificationDocuments.length > 0 ? (
          <div className="efb-modal__content mr-5 ml-5">
            <Table
              data={allDocumentsTypes.reduce(
                (acc, doc) => [...acc, ...doc.verificationDocuments],
                []
              )}
              columns={columnsForTypes}
              pagination={false}
            />
          </div>
        ) : (
          <h6 className="text-center">
            There is no document type for this payment method!
          </h6>
        )}
      </Modal>

      <div className="row d-flex justify-content-center">
        <div className="col-md-8 border-right">
          <Table
            data={paymentMethods}
            columns={columnsForPaymentsMethod}
            pagination={false}
          />
        </div>
      </div>
    </Fragment>
  );
};

ChangeValidations.propTypes = {
  types: PropTypes.array,
  paymentMethods: PropTypes.array,
  allDocumentsTypes: PropTypes.array,
  addType: PropTypes.func,
  removeType: PropTypes.func,
  addNotificationMessage: PropTypes.func,
};

export default ChangeValidations;
