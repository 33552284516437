import React, { Component, Fragment } from 'react';

export class TableHeader extends Component {

	renderSortIcon = column => {
		const { sorting } = this.props;

    if (sorting.column !== column) return null;
    if (sorting.order === 'asc') return <i className="fas fa-sort-down" />;
    return <i className="fas fa-sort-up" />;
  };

  sortColumn = sort => {
    const sorting = { ...this.props.sorting };

    if (sorting.column === sort) {
      sorting.order = sorting.order === 'desc' ? 'asc' : 'desc';
    } else {
      sorting.column = sort;
      sorting.order = 'desc';
    }

    this.props.handleSort(sorting);
	};

	renderCheckBox = () => {
		const { checkboxDiv } = this.props;
		if(checkboxDiv){
			return {
				name: 'checkBoxPendingStatuses',
				value: checkboxDiv
			}
		}
	}

  render() {
		const { columns } = this.props;
    return (
      <thead className="thead-light">
        <tr>
          {columns.map((column, inx) => (
            <Fragment key={inx}>
              {this.renderCheckBox() && inx === 1 ? (
                <th>{this.renderCheckBox().value}</th>
              ) : null}
              <th
                data-sortable={column.sortable}
                key={inx}
                className="pointer"
                onClick={() =>
                  column.sortable ? this.sortColumn(column.value) : null
                }
              >
                {column.name} {this.renderSortIcon(column.value)}{' '}
              </th>
            </Fragment>
          ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
