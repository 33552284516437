import React, { Fragment } from 'react';

const SelectField = ({
  label,
  name,
  value,
  onChange,
  disabled,
  placeholder,
  options,
  labelClass = ''
}) => {
  return (
    <Fragment>
      <select
        name={name}
        className="form-control efb-input"
        disabled={disabled}
        value={value}
        onChange={onChange}
      >
        <option value="">{placeholder}</option>
        {options.map((option, idx) => {
          return (
            <option key={idx} value={option.id}>
              {option.name}
            </option>
          );
        })}
      </select>
      <label htmlFor={name} className={labelClass}>
        {label}
      </label>
    </Fragment>
  );
};

export default SelectField;
