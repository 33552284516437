import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../components/Table/Table';

const CompetitorTable = ({ competitors, editCompetitor }) => {
  const columns = useMemo(() => {
    return [{ name: 'No' }, { name: 'Competitor' }, { name: 'Action' }];
  }, []);
  return (
    <Table
      search={false}
      columns={columns}
      data={competitors}
      editCompetitor={editCompetitor}
      tableType="competitorsTable"
      tableStyle="sortable table table-hover table-striped efb-table events-search-table"
    />
  );
};

CompetitorTable.propTypes = {
  competitors: PropTypes.array,
};

export default CompetitorTable;
