import React from 'react';

const Input = ({
  type,
  name,
  id,
  value,
  defaultValue,
  onChange,
  onKeyDown,
  placeholder,
  accept,
  className,
  isChecked,
  required = false,
  disabled = false,
  readOnly = false,
  step = 1,
  max = null,
  min = null,
  customInput,
}) => (
  <input
    className={customInput ? className : `efb-input ${className}`}
    type={type}
    value={value}
    defaultValue={defaultValue}
    onChange={onChange}
    onKeyDown={onKeyDown}
    name={name}
    id={id}
    accept={accept}
    placeholder={placeholder}
    required={required}
    disabled={disabled}
    step={step}
    max={max}
    min={min}
    checked={isChecked}
    readOnly={readOnly}
  />
);

export default Input;
