import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classes from './ImageModal.module.scss';
import PropTypes from 'prop-types';

import Close from '../../Icons/Close';

const ImageModal = ({
  isActive,
  onClose,
  imageIndex,
  setImageIndex,
  images,
}) => {
  const timeout = { enter: 500, exit: 300 };

  return (
    <TransitionGroup className="efb-modal__animation" appear={true}>
      {isActive ? (
        <CSSTransition timeout={timeout}>
          <section className={'efb-modal'}>
            <div className={classes.closeIconWrapper} onClick={onClose}>
              <Close size="lg" color={'#fff'} />
            </div>
            <div className={`${classes.modalWrapper} efb-modal__overlay`}>
              <div
                className={`efb-modal__wrapper ${classes.transparentBackground}`}
              >
                <div className={classes.imageWrapper}>
                  {images.length > 1 ? (
                    <>
                      <span
                        className={`${classes.arrowIcon} ${classes.leftArrow}`}
                        onClick={() => {
                          if (imageIndex !== 0) {
                            setImageIndex(imageIndex - 1);
                          }
                        }}
                      >
                        <i className="fa fa-arrow-left" />
                      </span>
                      <span
                        className={`${classes.arrowIcon} ${classes.rightArrow}`}
                        onClick={() => {
                          if (images.length - 1 !== imageIndex) {
                            setImageIndex(imageIndex + 1);
                          }
                        }}
                      >
                        <i className="fa fa-arrow-right" />
                      </span>
                    </>
                  ) : null}

                  <img
                    src={images[imageIndex].url}
                    alt={images[imageIndex].fileName}
                  />
                </div>
              </div>
            </div>
          </section>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  );
};

ImageModal.propTypes = {
  images: PropTypes.array,
};

export default ImageModal;
