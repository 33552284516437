import React, { memo } from 'react';

export const Snooker = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Snooker ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141 141"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <circle cx="70.5" cy="128.05" r="12.95" />
        <circle cx="41.73" cy="128.05" r="12.95" />
        <circle cx="12.95" cy="128.05" r="12.95" />
        <circle cx="128.05" cy="128.05" r="12.95" />
        <circle cx="99.27" cy="128.05" r="12.95" />
        <circle cx="70.5" cy="70.5" r="12.95" />
        <circle cx="41.73" cy="70.5" r="12.95" />
        <circle cx="99.27" cy="70.5" r="12.95" />
        <circle cx="70.5" cy="12.95" r="12.95" />
        <circle cx="84.89" cy="41.73" r="12.95" />
        <circle cx="56.11" cy="41.73" r="12.95" />
        <circle cx="84.89" cy="99.27" r="12.95" />
        <circle cx="56.11" cy="99.27" r="12.95" />
        <circle cx="27.34" cy="99.27" r="12.95" />
        <circle cx="113.66" cy="99.27" r="12.95" />
      </g>
    </svg>
  );
};

export default memo(Snooker);
