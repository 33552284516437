import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../../../../../components/Form/InputField';

const StartTimes = ({ filters, handleInputChange }) => {
	return (
		<div className="efb-form">
			<br />
			<div className="form-row">
				<div className="form-label-group col-md">
					<InputField
						type="date"
						name="scheduledStart"
						value={filters['scheduledStart']}
						min="1000-01-01"
						max="3000-12-31"
						className="form-control efb-input"
						onChange={handleInputChange}
					/>
					<label htmlFor="scheduledStart">Date</label>
				</div>
				<div className="form-label-group col-md">
					<InputField
						type="time"
						name="actualStart"
						value={filters['actualStart']}
						className="form-control efb-input"
						onChange={handleInputChange}
					/>
					<label htmlFor="actualStart">Starting time</label>
				</div>
			</div>
		</div>
	);
};

StartTimes.propTypes = {
	filters: PropTypes.object,
	handleInputChange: PropTypes.func
};

export default StartTimes;
