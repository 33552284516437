import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Layout/Button';

const MarketCategoriesTableRow = ({ category, index, editCategory, viewMarketTypes, draggableProps, moveToTop }) => {
	return (
		<tr {...draggableProps} data-index={index} data-id={category.id}>
			<td>{index + 1}.</td>
			<td>{category.names.en ? category.names.en.name : 'No available name'}</td>
			<td>
				<Button
					cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
					onClick={() => viewMarketTypes(category)}
				>
					<i className="fas fa-eye" /> View MT
				</Button>
				<Button
					cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
					onClick={() => editCategory(category)}
				>
					<i className="fas fa-pen" /> Edit
				</Button>

				{index !== 0 ? (
					<Button
						cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
						onClick={() => moveToTop(category, index)}
					>
						<i className="fas fa-arrow-up" /> Move to top
					</Button>
				) : null}
			</td>
		</tr>
	);
};

MarketCategoriesTableRow.propTypes = {
	category: PropTypes.object,
	index: PropTypes.number,
	deleteCategory: PropTypes.func,
	editCategory: PropTypes.func,
	viewMarketTypes: PropTypes.func
};

export default MarketCategoriesTableRow;
