import { SET_WS_FRAME_OBJECT, SET_WS_CONNECTION_STATUS } from "../actions/wsActions";

const initialState = {
	wsFrame: {},
	wsConnectionStatus: false
};

//use switch it's more readable
export const wsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_WS_FRAME_OBJECT:
			return {
				...state,
				wsFrame: action.payload
			};
		case SET_WS_CONNECTION_STATUS:
			return {
				...state,
				wsConnectionStatus: action.payload
			}
		default: return state
	}
};