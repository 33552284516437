import React, { useMemo } from 'react';
import ByCountry from './MenuContents/ByCountry';
import ByDevice from './MenuContents/ByDevice';
import BySports from './MenuContents/BySports';
import AddEvents from './MenuContents/AddEvents';
import ByRiskCategories from './MenuContents/ByRiskCategories';
import ByBetTypes from './MenuContents/ByBetTypes';
import Main from './MenuContents/Main';
import { filterMenu } from './BetScrollerUtils';

const BetScrollerMenuManager = ({
  menuPosition,
  currentBetScroller,
  setCurrentBetScroller,
  bUnits,
  sports,
  systemTypes,
  riskCategories,
  betScrollerEvents,
}) => {
  const content = useMemo(() => {
    switch (filterMenu[menuPosition]) {
      case 'Main':
        return (
          <Main
            betScroller={currentBetScroller}
            onChangeBetStake={setCurrentBetScroller}
          />
        );
      case 'By Country':
        return (
          <ByCountry
            bUnits={bUnits}
            betScroller={currentBetScroller}
            onChangeCountry={setCurrentBetScroller}
          />
        );
      case 'By Device':
        return (
          <ByDevice
            betScroller={currentBetScroller}
            onChangeDevice={setCurrentBetScroller}
          />
        );
      case 'By Sports':
        return (
          <BySports
            sports={sports}
            betScroller={currentBetScroller}
            onChangeSport={setCurrentBetScroller}
          />
        );

      case 'Add Events':
        return (
          <AddEvents
            betScroller={currentBetScroller}
            betScrollerEvents={betScrollerEvents}
            onChangeEvents={setCurrentBetScroller}
          />
        );
      case 'By Risk Category':
        return (
          <ByRiskCategories
            betScroller={currentBetScroller}
            onChangeRisk={setCurrentBetScroller}
            riskCategories={riskCategories}
          />
        );
      case 'By Bet Types':
        return (
          <ByBetTypes
            systemTypes={systemTypes}
            betScroller={currentBetScroller}
            onChangeBetType={setCurrentBetScroller}
          />
        );
      default:
        return '';
    }
  }, [
    menuPosition,
    currentBetScroller,
    setCurrentBetScroller,
    bUnits,
    sports,
    riskCategories,
    systemTypes,
    betScrollerEvents,
  ]);

  return content;
};

export default BetScrollerMenuManager;
