import React, { Fragment, useState, useCallback } from 'react';
import Modal from '../../../../components/Modal/Modal';
import PropTypes from 'prop-types';
import Button from '../../../../components/Layout/Button';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import { useUpdate } from '../../../../hooks';

const GameEditImages = ({
  casinoGameObj,
  updateChanges,
  finishUploadingImage,
  deleteImage,
}) => {
  const [imagePreviewModal, setImagePreviewModal] = useState(false);
  const [image, setImage] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [imageReplaceModal, setImageReplaceModal] = useState(false);
  const [casinoObj, setCasinoObj] = useState('');
  const [size, setSize] = useState('');
  const [imageRatio, setImageRatio] = useState({
    aspect: 1.78 / 1,
    x: 0,
    y: 0,
    width: 150,
    height: 150,
    minWidth: 150,
    maxWidth: 250,
  });
  const [shouldCrop, setShouldCrop] = useState(false);
  const [imagePlatform, setImagePlatform] = useState('');
  const [imageRef, setImageRef] = useState('');

  useUpdate(() => {
    setCasinoObj(casinoGameObj);
  }, [casinoGameObj]);

  const onChange = useCallback(
    e => {
      const casinoGameObjClone = { ...casinoObj };
      if (casinoGameObjClone[imagePlatform]) {
        casinoGameObjClone[imagePlatform][size] = {
          name: e.target.files[0],
          url: { url: e.target.files[0] },
        };
        setCasinoObj(casinoGameObjClone);
        setImage(URL.createObjectURL(e.target.files[0]));
        updateChanges(casinoGameObjClone);
      }
    },
    [casinoObj, imagePlatform, size, updateChanges]
  );

  const getCroppedPhoto = useCallback((image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        const fileUrl = window.URL.createObjectURL(blob);
        window.URL.revokeObjectURL(blob);
        blob.url = fileUrl;
        resolve(blob);
      }, 'image/jpeg');
    });
  }, []);

  const setCroppedImage = useCallback(async () => {
    const casinoGameObjClone = { ...casinoObj };
    if (imageRef) {
      const croppedImageUrl = await getCroppedPhoto(
        imageRef,
        imageRatio,
        casinoObj[imagePlatform][size].name.name
      );
      casinoGameObjClone[imagePlatform][size].url = croppedImageUrl;
      setCasinoObj(casinoGameObjClone);
      updateChanges(casinoGameObjClone);
    }
  }, [
    casinoObj,
    getCroppedPhoto,
    imagePlatform,
    imageRatio,
    imageRef,
    size,
    updateChanges,
  ]);

  const chooseImage = useCallback(
    (e, platform, size) => {
      e.preventDefault();
      const channel =
        platform === 'WEB' || platform === 'web' ? 'webImage' : 'mobileImage';
      const imageRatioClone = { ...imageRatio };
      if (platform === 'MOBILE' && size === 'BIG')
        imageRatioClone.aspect = 60 / 20;
      if (platform === 'MOBILE' && size === 'SMALL')
        imageRatioClone.aspect = 40 / 21;
      if (platform === 'WEB' && size === 'BIG')
        imageRatioClone.aspect = 1.78 / 1;
      if (platform === 'WEB' && size === 'SMALL')
        imageRatioClone.aspect = 2 / 1;
      setImageReplaceModal(true);
      setImagePlatform(channel);
      setSize(size);
      setImageRatio(imageRatioClone);
    },
    [imageRatio]
  );

  const mapImages = useCallback(
    (image, index) => {
      return (
        <li className="game-images-list-item" key={image.id}>
          <img
            src={image.url}
            alt=""
            onClick={() => {
              setPreviewImage(image);
              setImagePreviewModal(true);
            }}
          />
          <p className="image-desc capitalize">
            <i
              className={
                image.channel === 'mobile'
                  ? 'fa fa-mobile-alt'
                  : 'fa fa-desktop'
              }
            />{' '}
            {`${image.channel} - ${image.gameImageSize}`}
          </p>
          <span
            className="delete-icon"
            onClick={() => deleteImage(image.id, index)}
          >
            <i className="fas fa-times-circle" />
          </span>
        </li>
      );
    },
    [deleteImage]
  );

  const checkResolution = useCallback(
    e => {
      const img = e.target;
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      if (width < imageRatio.minWidth || height < imageRatio.minHeight) {
        setShouldCrop(false);
      } else {
        setShouldCrop(true);
      }
    },
    [imageRatio.minHeight, imageRatio.minWidth]
  );

  const closeModal = useCallback(
    modal => {
      const casinoGameObjClone = { ...casinoObj };
      casinoGameObjClone.webImage = { big: '', small: '' };
      casinoGameObjClone.mobileImage = { big: '', small: '' };
      updateChanges(casinoGameObjClone);
      if (modal === 'imagePreviewModal') setImagePreviewModal(false);
      if (modal === 'imageReplaceModal') setImageReplaceModal(false);
      setImage('');
    },
    [casinoObj, updateChanges]
  );

  const uploadImage = useCallback(
    e => {
      e.preventDefault();
      finishUploadingImage(casinoObj, imagePlatform, size);
      const casinoGameObjClone = { ...casinoObj };
      setImage('');
      setShouldCrop(false);
      setCasinoObj(casinoGameObjClone);
      setImageReplaceModal(false);
    },
    [casinoObj, finishUploadingImage, imagePlatform, size]
  );

  return (
    <Fragment>
      <Modal
        myClassName="full-size-img-modal"
        headerText={`${previewImage.channel} - ${previewImage.gameImageSize}`}
        isActive={imagePreviewModal}
        onClose={() => closeModal('imagePreviewModal')}
      >
        <img
          className="full-size-img"
          src={previewImage !== '' ? previewImage.url : previewImage}
          alt=""
        />
      </Modal>
      {casinoObj[imagePlatform] ? (
        <Modal
          isActive={imageReplaceModal}
          headerText={'Upload image'}
          onClose={() => closeModal('imageReplaceModal')}
        >
          <div className="modal-wrapper casino-manager-modal text-center">
            <div className="container">
              <div className="row">
                <div className="container align-items-center justify-content-start">
                  <div className="efb-btn__main file-wrapper">
                    <i className="fas fa-box-open" /> Browse...
                    <input type="file" name="big" onChange={onChange} />
                  </div>
                </div>
              </div>
            </div>

            <Fragment>
              {shouldCrop ? (
                <ReactCrop
                  src={image}
                  onComplete={setCroppedImage}
                  onChange={crop => setImageRatio(crop)}
                  onImageLoaded={image => setImageRef(image)}
                  crop={imageRatio}
                />
              ) : (
                <img
                  src={image}
                  alt=""
                  className="thumbnail-photo"
                  onLoad={checkResolution}
                />
              )}
              <img
                src={image}
                alt=""
                onLoad={checkResolution}
                className="d-none"
              />
            </Fragment>
            <hr />
            {shouldCrop ? (
              <div className="image-thumbnails">
                <br />
                <div
                  className={
                    casinoObj[imagePlatform][size] === '' ? 'd-none' : 'image'
                  }
                >
                  <img
                    src={
                      casinoObj[imagePlatform][size]
                        ? casinoObj[imagePlatform][size].url.url
                        : ''
                    }
                    alt=""
                  />
                </div>
              </div>
            ) : null}
            <Button
              cssClass="efb-btn__main"
              disabled={
                casinoObj[imagePlatform][size] === '' ||
                !casinoObj[imagePlatform][size]
              }
              onClick={uploadImage}
            >
              <i className="fa fa-upload" /> Upload
            </Button>
          </div>
        </Modal>
      ) : null}

      <hr />
      <ul className="game-images-list">
        {casinoObj.images ? casinoObj.images.map(mapImages) : null}

        {casinoObj.images && casinoObj.web ? (
          casinoObj.images.findIndex(
            x => x.channel === 'WEB' && x.gameImageSize === 'BIG'
          ) === -1 ? (
            <li className="game-images-list-item">
              <div className="game-image-edit-box">
                <div className="content big">
                  <Button onClick={e => chooseImage(e, 'WEB', 'BIG')}>
                    <i className="fa fa-desktop" />
                    <br />
                    <p>web big</p>
                  </Button>
                </div>
              </div>
            </li>
          ) : null
        ) : null}

        {casinoObj.images && casinoObj.web ? (
          casinoObj.images.findIndex(
            x => x.channel === 'WEB' && x.gameImageSize === 'SMALL'
          ) === -1 ? (
            <li className="game-images-list-item">
              <div className="game-image-edit-box">
                <div className="content small">
                  <Button onClick={e => chooseImage(e, 'WEB', 'SMALL')}>
                    <i className="fa fa-desktop" />
                    <br />
                    <p>web small</p>
                  </Button>
                </div>
              </div>
            </li>
          ) : null
        ) : null}

        {casinoObj.images && casinoObj.mobile ? (
          casinoObj.images.findIndex(
            x => x.channel === 'MOBILE' && x.gameImageSize === 'BIG'
          ) === -1 ? (
            <li className="game-images-list-item">
              <div className="game-image-edit-box">
                <div className="content big">
                  <Button onClick={e => chooseImage(e, 'MOBILE', 'BIG')}>
                    <i className="fa fa-mobile-alt" />
                    <br />
                    <p>mobile big</p>
                  </Button>
                </div>
              </div>
            </li>
          ) : null
        ) : null}

        {casinoObj.images && casinoObj.mobile ? (
          casinoObj.images.findIndex(
            x => x.channel === 'MOBILE' && x.gameImageSize === 'SMALL'
          ) === -1 ? (
            <li className="game-images-list-item">
              <div className="game-image-edit-box">
                <div className="content small">
                  <Button onClick={e => chooseImage(e, 'MOBILE', 'SMALL')}>
                    <i className="fa fa-mobile-alt" />
                    <br />
                    <p>mobile small</p>
                  </Button>
                </div>
              </div>
            </li>
          ) : null
        ) : null}
      </ul>
    </Fragment>
  );
  // }
};

GameEditImages.propTypes = {
  casinoGameObj: PropTypes.object,
  deleteImage: PropTypes.func,
};

export default GameEditImages;
