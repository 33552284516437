import React from 'react';

import Switch from '../../Form/Switch/Switch';
import Button from '../../Layout/Button';

const BetTypesRow = ({ tableData, updateConfig, deleteConfig }) => {
  const renderRow = config => {
    return (
      <tr key={config.id}>
        <td>{config.type}</td>
        <td>
          <Switch
            label="Allow"
            name="allow"
            onChange={e =>
              updateConfig(config.id, {
                ...config,
                [e.target.name]: e.target.checked,
              })
            }
            isChecked={config.allow}
          />
          <Switch
            label="Allow with Banker"
            name="allowWithBanker"
            onChange={e =>
              updateConfig(config.id, {
                ...config,
                [e.target.name]: e.target.checked,
              })
            }
            isChecked={config.allowWithBanker}
          />
        </td>
        <td>
          <Button
            onClick={() => deleteConfig(config.id)}
            cssClass="btn btn-danger"
          >
            Delete
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <>
      {tableData.length !== 0 ? (
        tableData.map(renderRow)
      ) : (
        <tr>
          <td colSpan={2}>No configs found.</td>
        </tr>
      )}
    </>
  );
};

export default React.memo(BetTypesRow);
