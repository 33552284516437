import React from 'react';

export const Delete = (props) => {
    return (
        <span className='efb-icon'>
            <svg className={`efb-icon--${props.size} efb-delete`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
                <path d="M22.2,2.2L22.2,2.2C22.2,1,21.2,0,20,0h-4c-1.2,0-2.2,1-2.2,2.2v0c0,0.5-0.4,0.9-0.9,0.9H6.3c-1.1,0-2,0.9-2,2v3.5
                    C4.3,9,4.6,9.3,5,9.3h0.1c0.4,0,0.7,0.3,0.7,0.7c0.3,5.6,1.3,22.5,1.5,25.5c0,0.3,0.2,0.5,0.5,0.5h20.3c0.3,0,0.5-0.2,0.5-0.5
                    c0.2-3,1.2-20,1.5-25.5c0-0.4,0.3-0.7,0.7-0.7H31c0.4,0,0.7-0.3,0.7-0.7V5.1c0-1.1-0.9-2-2-2h-6.6C22.6,3.1,22.2,2.7,22.2,2.2z
                    M17,1.3h2c0.5,0,0.9,0.4,0.9,0.9v0c0,0.5-0.4,0.9-0.9,0.9h-2c-0.5,0-0.9-0.4-0.9-0.9v0C16.1,1.7,16.5,1.3,17,1.3z M25.5,33.7
                    H10.5c-0.5,0-0.9-0.4-0.9-0.9c-0.3-5-1.1-18.2-1.4-23.1c0-0.2-0.2-0.4,0-0.4c5.4,0,14.2-0.1,19.6,0c0.2,0,0,0.2,0,0.4
                    c-0.3,4.9-1.1,18.1-1.4,23.1C26.4,33.3,26,33.7,25.5,33.7z M29.3,6.2C29.3,6.6,29,7,28.6,7H7.4C7,7,6.7,6.6,6.7,6.2v0
                    c0-0.4,0.3-0.7,0.7-0.7h21.2C29,5.5,29.3,5.8,29.3,6.2L29.3,6.2z"/>		
                <path d="M18,11.8L18,11.8c0.6,0,1.2,0.5,1.2,1.2v17.3c0,0.6-0.5,1.2-1.2,1.2h0c-0.6,0-1.2-0.5-1.2-1.2V13
                    C16.8,12.3,17.4,11.8,18,11.8z"/>		
                <path d="M11.9,11.8L11.9,11.8c0.6,0,1.2,0.5,1.2,1.1l0.9,17.3c0,0.6-0.5,1.2-1.1,1.2l0,0c-0.6,0-1.2-0.5-1.2-1.1l-0.9-17.3
                    C10.8,12.4,11.3,11.9,11.9,11.8z"/>		
                <path d="M21.9,30.3L22.8,13c0-0.6,0.6-1.1,1.2-1.1h0c0.6,0,1.1,0.6,1.1,1.2l-0.9,17.3c0,0.6-0.6,1.1-1.2,1.1h0
                    C22.4,31.5,21.9,30.9,21.9,30.3z"/>
            </svg>
            {props.children}
        </span>
    )
}

export default Delete;