import React, { useState, useCallback } from 'react';
import uniqBy from 'lodash/uniqBy';
import { connect } from 'react-redux';

import * as SctManagerActions from '../../redux/actions/cms/sctManager';
import * as EventsManagerActions from '../../redux/actions/cms/eventsManager';

import SearchRow from './SearchRow';
import FiltersRow from './FiltersRow';
import classes from './Sidebar.module.scss';
import TableSelector from '../TableSelector/TableSelector';

import { useMount, useUpdate } from '../../hooks';
import { getEventSearchData, getEventsManagerData } from '../../selectors';

const types = ['sport', 'category', 'tournament', 'match'];

const Sidebar = ({
  sports,
  searchResults,
  getSports,
  getCategories,
  getTournaments,
  getEvents,
  getCategoryById,
  getTournamentById,
  getEventById,
  treeProps,
  resultType = 'match',
}) => {
  const [scope, setScope] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [entities, setEntities] = useState({});
  const [matchStatus, setMatchStatus] = useState(null);

  // const { data: producers } = useFetch('sport/event/view/producer/public');

  const getEntities = useCallback(
    async (id, fetch, results, type) => {
      const nextType = types[types.indexOf(type) + 1];

      return await Promise.all(
        results
          .filter(res => res.result[type].id === id)
          .map(async res => {
            if (resultType === nextType) {
              return await fetch(res.result.id);
            } else {
              return await fetch(res.result[nextType].id);
            }
          })
      );
    },
    [resultType]
  );

  useMount(() => {
    getSports();
  });

  useUpdate(() => {
    if (searchResults.length <= 0 && !scope && !matchStatus) {
      setEntities({});
      return;
    }

    if (scope || matchStatus) {
      const filters = JSON.parse(
        JSON.stringify({
          marketStatus: matchStatus || undefined,
          scope: scope || undefined,
          startDate: startDate || undefined,
          endDate: endDate || undefined,
        })
      );

      (async () => {
        setEntities({
          sports: await getSports(filters),
          sport: async id => await getCategories(id, filters),
          category: async id => await getTournaments(id, filters),
          tournament: async id => await getEvents(id, filters),
        });
      })();
      return;
    }

    const matches = searchResults.filter(
      res => res.result.type === resultType && res.highlights['names.en.name']
    );

    const mapping = [
      { name: 'sport', fetchFun: getCategoryById },
      { name: 'category', fetchFun: getTournamentById },
      { name: 'tournament', fetchFun: getEventById },
    ];

    const res = {
      ...mapping.reduce(
        (acc, entity) => ({
          ...acc,
          [entity.name]: async id =>
            uniqBy(
              await getEntities(id, entity.fetchFun, matches, entity.name),
              'id'
            ),
        }),
        {}
      ),
      sports: uniqBy(matches, 'result.sport.id').map(match =>
        sports.find(sport => sport.id === match.result.sport.id)
      ),
    };

    setEntities(res);
  }, [searchResults, scope, matchStatus, startDate, endDate]);

  return (
    <div className={classes.sidebar}>
      {/* SEARCH ROW */}
      <SearchRow
        entityType={resultType}
        matchStatus={matchStatus}
        setMatchStatus={setMatchStatus}
      />
      {/* FILTERS ROW */}
      <FiltersRow
        setScope={setScope}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      {/* MARKET SELECT */}
      <TableSelector
        getters={entities}
        sports={entities.sports || sports}
        {...treeProps}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  sports: getEventsManagerData(state).sports,
  searchResults: getEventSearchData(state).searchSportEvents,
});

const mapDispatchToProps = {
  getSports: EventsManagerActions.getSports,
  getCategories: EventsManagerActions.getCategories,
  getTournaments: EventsManagerActions.getTournaments,
  getEvents: EventsManagerActions.getEvents,
  getCategoryById: SctManagerActions.getCategoryById,
  getTournamentById: SctManagerActions.getTournamentById,
  getEventById: SctManagerActions.getEventById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
