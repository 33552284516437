import React from 'react';
import Table from '../../../components/Table/Table';
import { columns, tableStyle } from './utils';
import PropTypes from 'prop-types';

export const SystemSettingsTable = (props) => {
    return (
      <Table
        columns={columns}
        tableStyle={tableStyle}
        tableType="systemSettingsRow"
        {...props}
        pageSize={10}
        sortBy="createdDate"
      />
    );
}

SystemSettingsTable.propTypes = {
  data: PropTypes.array,
  onDelete: PropTypes.func,
  onChangeSystemSwitch: PropTypes.func,
  onChangeSystemDropDown: PropTypes.func,
  feedProviders: PropTypes.array,
  isEditMode: PropTypes.bool
};

export default SystemSettingsTable;
