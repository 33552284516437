import React, { useState, useCallback } from 'react';
import Button from '../../Layout/Button';
import InputField from '../../Form/InputField';
import { useUpdate } from '../../../hooks';
import Switch from '../../Form/Switch/Switch';

const StaticSubpageTableRow = ({
  tableData,
  assignSubpageContent,
  showChildren,
  childrenLevel,
  editContent,
  editSubpageName,
  updateSubpage,
}) => {
  const [subpages, setSubpages] = useState([]);

  useUpdate(() => {
    setSubpages(tableData);
  }, [tableData]);

  const handleInputChange = useCallback(
    (e, index) => {
      const { value, name, checked } = e.target;
      const subapgesClone = [...subpages];
      subapgesClone[index][name] = name === 'name' ? value : checked;
      if (name === 'enabled') {
        updateSubpage(subapgesClone[index]);
      }
      setSubpages(subapgesClone);
    },
    [subpages, updateSubpage]
  );

  const saveName = useCallback(
    index => {
      const subapgesClone = [...subpages];
      subapgesClone[index].editMode = false;
      updateSubpage(subapgesClone[index]);
      setSubpages(subapgesClone);
    },
    [subpages, updateSubpage]
  );

  const mapSubpages = useCallback(
    (subpage, index) => {
      return (
        <tr key={subpage.resourceType === 'Table' ? subpage.name : subpage.id}>
          <td>{index + 1}</td>
          <td>{subpage.resourceType}</td>
          <td>
            {subpage.editMode ? (
              <div className="efb-form static-subpage-edit-input">
                <InputField
                  value={subpage.name}
                  name="name"
                  onChange={e => handleInputChange(e, index)}
                />
              </div>
            ) : (
              subpage.name
            )}
          </td>
          <td>
            <Switch
              value={subpage.enabled}
              isChecked={subpage.enabled}
              name="enabled"
              onChange={e => handleInputChange(e, index)}
            />
          </td>
          <td>
            {!subpage.editMode ? (
              subpage.children ? (
                <>
                  <Button
                    cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
                    disabled={
                      subpage.resourceType === 'REGULAR' ||
                      subpage.resourceType === 'ROOT' ||
                      childrenLevel !== 0
                    }
                    onClick={() => assignSubpageContent(subpage, index)}
                  >
                    <i className="fas fa-plus"></i> Assign content
                  </Button>

                  <Button
                    cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
                    disabled={!subpage.children}
                    onClick={() => showChildren(subpage)}
                  >
                    <i className="fas fa-eye"></i> Show content
                  </Button>
                  <Button
                    cssClass={
                      'btn btn-sm btn-outline-secondary btn-demo-edit-val'
                    }
                    onClick={() => editSubpageName(true, index)}
                  >
                    <i className="fas fa-edit"></i> Edit Subpage Name
                  </Button>
                </>
              ) : (
                <Button
                  cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
                  disabled={subpage.children}
                  onClick={() => editContent(subpage, index)}
                >
                  <i className="fas fa-edit"></i> Edit content
                </Button>
              )
            ) : (
              <>
                <Button
                  cssClass="btn btn-sm btn-DEMO-del-val btn-primary"
                  onClick={() => saveName(index)}
                >
                  Save
                </Button>
                <Button
                  cssClass="btn btn-sm btn-DEMO-edit-val btn-secondary"
                  onClick={() => editSubpageName(false, index)}
                >
                  Cancel
                </Button>
              </>
            )}
          </td>
        </tr>
      );
    },
    [
      assignSubpageContent,
      childrenLevel,
      editContent,
      editSubpageName,
      handleInputChange,
      saveName,
      showChildren,
    ]
  );

  return subpages.map(mapSubpages);
};

export default StaticSubpageTableRow;
