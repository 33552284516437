import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import * as CustomerDataActions from '../../../../../redux/actions/customer/customerData';
import classes from './General.module.scss';
import Input from '../../../../../components/Form/Input';
import Button from '../../../../../components/Layout/Button';
import { useStateForInput } from '../../../../../hooks';

const AccountInfo = ({ customer, updatePassword }) => {
  const [password, setPassword] = useStateForInput('');
  const [changingPassword, setChangingPassword] = useState(false);

  const savePassword = useCallback(() => {
    updatePassword({
      customerId: customer.customerId,
      newPassword: password,
    });
    setChangingPassword(false);
    setPassword('');
  }, [customer.customerId, password, setPassword, updatePassword]);

  return (
    <div>
      <h1>Account Info</h1>
      <div>
        <div>
          <label>Username:</label>
          <div>
            <Input
              customInput
              disabled
              value={customer.user?.username}
              className="efb-input__underlined"
            />
          </div>
        </div>

        <div className={classes.accountInfo}>
          <label>Price Ref.:</label>
          <div>
            <Input
              customInput
              disabled
              value={moment(customer.dateOfBirth).format('DD/MM/YYYY')}
              className="efb-input__underlined"
            />
          </div>
        </div>

        <div>
          <label>Password:</label>
          <div>
            {changingPassword ? (
              <>
                <Input
                  customInput
                  value={password}
                  onChange={setPassword}
                  className="efb-input__underlined"
                />
                <Button cssClass={classes.saveButton} onClick={savePassword}>
                  Save
                </Button>
              </>
            ) : (
              <>
                <div className="bg-danger p-2">
                  <Button
                    cssClass="btn btn-secondary text-dark btn-sm"
                    onClick={() => console.log('Reset password')}
                  >
                    Reset
                  </Button>
                </div>
                <Button
                  cssClass="btn btn-secondary text-dark btn-sm"
                  onClick={() => setChangingPassword(true)}
                >
                  Change
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  updatePassword: CustomerDataActions.updatePassword,
};

export default connect(null, mapDispatchToProps)(AccountInfo);
