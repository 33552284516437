import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import * as StsActions from '../../../../redux/actions/cms/stsBuilder';
import * as UiActions from '../../../../redux/actions/ui';
import { selectStyles } from '../../customReactSelectStyle';
import { useStateForSelect, useMount, useUpdate } from '../../../../hooks';
import { getStsBuilderData } from '../../../../selectors';
import StsCreateForm from '../StsCreateForm';
import { mapSts } from '../StsUtils';

const CreateTournament = ({
  languages,
  setSportId,
  setCategoryId,
  editingTournament,
  clearEditingTournament,
  sports,
  getAllSports,
  categories,
  getSportCategories,
  getTournaments,
  createTournament,
  updateTournament,
  addNotificationMessage
}) => {
  const [selectedSport, setSelectedSport] = useStateForSelect('');
  const [selectedCategory, setSelectedCategory] = useStateForSelect('');

  const sportOptions = useMemo(() => sports.map(mapSts), [sports]);

  const categoriesOptions = useMemo(() => categories.map(mapSts), [categories]);

  useMount(getAllSports);

  useUpdate(() => getSportCategories(selectedSport.value), [
    selectedSport.value
  ]);

  useUpdate(() => getTournaments(selectedCategory.value), [
    selectedCategory.value
  ]);

  const submitTournament = useCallback(
    (names, status) => {
      if (!selectedSport.value || !selectedCategory.value || !names.en.name) {
        addNotificationMessage(
          'Sport, Category and name in English are required!',
          'warning',
          'Warning!'
        );
        return;
      }
      const tournament = {
        names,
        status
      };
      if (editingTournament) {
        updateTournament({ ...editingTournament, ...tournament });
      } else {
        createTournament({
          ...tournament,
          sportId: selectedSport.value,
          competitors: [],
          categoryId: selectedCategory.value,
          liveCoverage: true,
          tournamentType: 'tournament',
          currentProducerId: '14',
          feedProducers: [{feedProviderId: `efb:tournament:${new Date().getTime()}`, producerId: '14'}],
          type: 'tournament',
          feedProviders: {},
          streamingProviders: [],
        });
      }
    },
    [
      addNotificationMessage,
      createTournament,
      editingTournament,
      selectedCategory.value,
      selectedSport.value,
      updateTournament
    ]
  );

  return (
    <div className="efb-form mt-3">
      <div className="form-row mb-4">
        <Select
          placeholder="Select Sport"
          value={selectedSport}
          onChange={data => {
            setSelectedSport(data);
            setSportId(data.value);
          }}
          styles={selectStyles}
          options={sportOptions}
          className="col"
        />
        <Select
          placeholder="Select Category"
          value={selectedCategory}
          onChange={data => {
            setSelectedCategory(data);
            setCategoryId(data.value);
          }}
          styles={selectStyles}
          options={categoriesOptions}
          className="col"
        />
      </div>

      <StsCreateForm
        sts="tournament"
        languages={languages}
        editingSts={editingTournament}
        onClear={clearEditingTournament}
        onSubmit={submitTournament}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  sports: getStsBuilderData(state).sports,
  categories: getStsBuilderData(state).categories
});

const mapDispatchToProps = {
  getAllSports: StsActions.getAllSports,
  getSportCategories: StsActions.getSportCategories,
  getTournaments: StsActions.getTournaments,
  createTournament: StsActions.createTournament,
  updateTournament: StsActions.updateTournament,
  addNotificationMessage: UiActions.addNotificationMessage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTournament);
