import { actionCreator, createRequestTypes } from './index';
import { addNotificationMessage } from './ui';
import axiosInstance from './axios-instance';

export const GET_TYPES = createRequestTypes('GET_TYPES');
export const GET_DOCUMENT_TYPES = createRequestTypes('GET_DOCUMENT_TYPES');
export const GET_PAYMENT_METHODS = createRequestTypes('GET_PAYMENT_METHODS');
export const VERIFICATION = createRequestTypes('VERIFICATION');
export const AFTER_VERIFICATION = createRequestTypes('AFTER_VERIFICATION');
export const GET_PENDING_USERS = createRequestTypes('GET_PENDING_USERS');
export const GET_ALL_DOCUMENTS = createRequestTypes('GET_ALL_DOCUMENTS');

export const getDocumentTypes = () => async dispatch => {
  dispatch(actionCreator.request(GET_DOCUMENT_TYPES));
  try {
    const res = await axiosInstance.get('/customer/view/public/documents/document-types');
    dispatch(actionCreator.success(GET_DOCUMENT_TYPES, res));
  }
  catch (err){
    dispatch(actionCreator.failure(GET_DOCUMENT_TYPES, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
}

export const getTypes = () => async dispatch => {
  dispatch(actionCreator.request(GET_TYPES));
  try {
    const res = await axiosInstance.get('/customer/view/admin/documents/types');
    dispatch(actionCreator.success(GET_TYPES, res));
  }
  catch (err){
    dispatch(actionCreator.failure(GET_TYPES, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
}

export const getPaymentMethods = () => async dispatch => {
  dispatch(actionCreator.request(GET_PAYMENT_METHODS));
  try {
    const res = await axiosInstance.get('/customer/view/admin/documents/verification-types');
    dispatch(actionCreator.success(GET_PAYMENT_METHODS, res));
  }
  catch (err){
    dispatch(actionCreator.failure(GET_PAYMENT_METHODS, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
}

export const getDocsAfterVerification = (paymentMethod, lang) => async dispatch => {
  dispatch(actionCreator.request(AFTER_VERIFICATION));
  try {
    const res = await axiosInstance.get(
      `/customer/view/public/documents/verification-documents?locale=${lang}&verificationType=${paymentMethod}`
    );
    dispatch(actionCreator.success(AFTER_VERIFICATION, res));
  }
  catch (err){
    dispatch(actionCreator.failure(AFTER_VERIFICATION, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const verificationDocuments = obj => async dispatch => {
  dispatch(actionCreator.request(VERIFICATION));
  try {
    const res = await axiosInstance.post(
      '/customer/view/admin/documents/names',
      obj
    );
    dispatch(actionCreator.success(VERIFICATION, res));
    dispatch(
      addNotificationMessage(
        'Successful updated document!',
        'success',
        'Success!'
      )
    );
    dispatch(getTypes());
  } catch (err) {
    if (err) {
      dispatch(actionCreator.failure(VERIFICATION, err));
      dispatch(
        addNotificationMessage(Object.values(err.errors)[0], 'error', 'Error')
      );
    }
  }
};

export const getPendingsUsers = () => async dispatch => {
  dispatch(actionCreator.request(GET_PENDING_USERS));
  try {
    const res = await axiosInstance.get(
      '/customer/view/admin/documents/pending'
    );
    dispatch(actionCreator.success(GET_PENDING_USERS, res));
  }
  catch (err){
    dispatch(actionCreator.failure(GET_PENDING_USERS, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
}

const checkDocumentStatus = obj => dispatch => {
  const myInterval = setInterval(async () => {
    const res = await axiosInstance.get(
      `/customer/view/admin/${obj.customerId}/documents`
    );
    const doc = res.find(document => document.id === obj.documentId);
    
    if (doc.status !== 'PENDING') {
      getPendingsUsers()(dispatch);
      clearInterval(myInterval);
    }
  }, 500);
} 

export const approve = obj => async dispatch => {
  try {
    await axiosInstance.post(
      '/customer/admin/document/approve', obj
    );
    checkDocumentStatus(obj)(dispatch);
    dispatch(
      addNotificationMessage(
        'Customer document is approved!',
        'success',
        'Success!'
      )
    );
  }
  catch (err){
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
} 

export const reject = obj => async dispatch => {
  try {
    await axiosInstance.post(
      '/customer/admin/document/reject', obj
    );
    checkDocumentStatus(obj)(dispatch);
    dispatch(
      addNotificationMessage(
        'Customer document is rejected!',
        'success',
        'Success!'
      )
    );
  }
  catch (err){
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
} 

export const addType = (typeObj) => async dispatch => {
  try {
    await axiosInstance.post(
      '/customer/view/admin/documents/add-type', typeObj
    );
    dispatch(
      addNotificationMessage(
        'Document type is activated!',
        'success',
        'Success!'
      )
    );
    dispatch(getAllDocuments(typeObj.verificationType))
  }
  catch (err){
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
} 

export const removeType = (typeObj) => async dispatch => {
  try {
    await axiosInstance.post(
      '/customer/view/admin/documents/remove-type', typeObj
    );
    dispatch(
      addNotificationMessage(
        'Document type is deactiveted!',
        'success',
        'Success!'
      )
    );
    dispatch(getAllDocuments(typeObj.verificationType))
  }
  catch (err){
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
} 

export const getAllDocuments = (paymentMethod) => async dispatch => {
  dispatch(actionCreator.request(GET_ALL_DOCUMENTS));
  try {
    const res = await axiosInstance.get(`/customer/view/admin/documents/verification-types?type=${paymentMethod}`);
    dispatch(actionCreator.success(GET_ALL_DOCUMENTS, res));
  }
  catch (err){
    dispatch(actionCreator.failure(GET_ALL_DOCUMENTS, err));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
}


