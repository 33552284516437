import React, { useMemo, useState, useCallback } from 'react';

import Input from '../../Form/Input';
import RadioButton from '../../Form/RadioButton/RadioButton';
import { useStateForInput, useMount } from '../../../hooks';

import classes from './StakeFilter.module.scss';

const StakeFilter = ({ onFilterChange: pushFilterChange }) => {
  const [stake, setStake] = useStateForInput('all');
  const [totalStake, setTotalStake] = useState({
    from: '',
    to: '',
  });
  const [totalReturn, setTotalReturn] = useState({
    from: '',
    to: '',
  });

  useMount(() => {
    pushFilterChange({
      name: 'stake',
      value: 'all',
    });
  });

  const stakeOptions = useMemo(
    () => [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Money',
        value: 'money',
      },
      {
        label: 'Free Bet',
        value: 'freeBet',
      },
    ],
    []
  );

  const handleStakeChange = useCallback(
    e => {
      setStake(e);
      pushFilterChange({ name: 'stake', value: e.target.value });
    },
    [pushFilterChange, setStake]
  );

  return (
    <div className={classes.stake}>
      <div className={classes.header}>
        <label>Stake</label>
        <div className={classes.radioGroup}>
          {stakeOptions.map((option, i) => (
            <RadioButton
              id={`stake-${option.value}-${i}`}
              key={`stake-${option.value}-${i}`}
              name="stake"
              value={option.value}
              label={option.label}
              isChecked={stake === option.value}
              onChange={handleStakeChange}
            />
          ))}
        </div>
      </div>
      <div className={classes.totals}>
        <div>
          <p>Total Stake</p>
          <Input
            customInput
            type="number"
            value={totalStake.from}
            onChange={e => {
              setTotalStake({
                ...totalStake,
                from: e.target.value,
              });
              pushFilterChange({
                name: 'totalStake',
                value: {
                  ...totalStake,
                  from: e.target.value,
                },
              });
            }}
          />
          <p>to</p>
          <Input
            customInput
            type="number"
            value={totalStake.to}
            onChange={e => {
              setTotalStake({
                ...totalStake,
                to: e.target.value,
              });
              pushFilterChange({
                name: 'totalStake',
                value: {
                  ...totalStake,
                  to: e.target.value,
                },
              });
            }}
          />
        </div>
        <div>
          <p>Total Return</p>
          <Input
            customInput
            type="number"
            value={totalReturn.from}
            onChange={e => {
              setTotalReturn({
                ...totalReturn,
                from: e.target.value,
              });
              pushFilterChange({
                name: 'totalReturn',
                value: {
                  ...totalReturn,
                  from: e.target.value,
                },
              });
            }}
          />
          <p>to</p>
          <Input
            customInput
            type="number"
            value={totalReturn.to}
            onChange={e => {
              setTotalReturn({
                ...totalReturn,
                to: e.target.value,
              });
              pushFilterChange({
                name: 'totalReturn',
                value: {
                  ...totalReturn,
                  to: e.target.value,
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StakeFilter;
