import {
  ACCOUNT_HISTORY,
  BET_TYPES,
  COUNTRIES,
  GET_DOCUMENTS_FOR_CUSTOMER,
  GET_DOCUMENTS_FILE_FOR_CUSTOMER,
  GET_CUSTOMERS_BY_VERIFICATIONS,
} from '../../actions/customer/customerData';

const initialState = {
  accountHistory: {
    totalElements: 0,
    totalPages: 0,
    content: [],
  },
  customerFileDocuments: [],
  customerDocuments: [],
  betTypes: [],
  countries: [],
  customersAfterVerification: [],
};

export const customerDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_HISTORY.EMPTY:
      return {
        ...state,
        accountHistory: {
          totalElements: 0,
          totalPages: 0,
          content: [],
        },
      };
    case ACCOUNT_HISTORY.SUCCESS:
      return {
        ...state,
        accountHistory: {
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          content: action.payload.content,
        },
      };
    case GET_DOCUMENTS_FILE_FOR_CUSTOMER.SUCCESS:
      return {
        ...state,
        customerFileDocuments: action.payload,
      };
    case GET_DOCUMENTS_FOR_CUSTOMER.SUCCESS:
      return {
        ...state,
        customerDocuments: action.payload,
      };
    case BET_TYPES.SUCCESS:
      return { ...state, betTypes: action.payload };
    case COUNTRIES.SUCCESS:
      return { ...state, countries: action.payload };
    case GET_CUSTOMERS_BY_VERIFICATIONS.SUCCESS:
      return { ...state, customersAfterVerification: action.payload };
    default:
      return state;
  }
};
