import React, { Fragment } from 'react';

const InputField = ({
  label,
  labelCss,
  name,
  type,
  value,
  onChange,
  onKeyDown,
  disabled,
  placeholder,
  id,
  step,
  min = undefined,
  max = undefined,
}) => (
  <Fragment>
    <input
      name={name}
      step={step}
      type={type}
      className="form-control efb-input"
      disabled={disabled}
      value={value || ''}
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={onKeyDown}
      id={id || name}
      min={min}
      max={max}
    />
    <label htmlFor={name} className={labelCss}>
      {label}
    </label>
  </Fragment>
);

export default InputField;
