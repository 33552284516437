import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/Layout/Button';
import Switch from '../../../../components/Form/Switch/Switch';
import { useUpdate } from '../../../../hooks';

const MarketTypesTableRow = ({
  mt,
  index,
  businessUnit,
  draggableProps,
  marketCatName,
  removeFromMarketCategory,
  addNotificationMessage,
  saveOpenSelection,
}) => {
  const [openSelection, setOpenSelection] = useState('');

  useUpdate(() => {
    if (mt.openSelection[businessUnit]) {
      setOpenSelection(mt.openSelection[businessUnit].openSelection);
    } else {
      setOpenSelection(false);
    }
  }, [mt]);

  const changeOpenSelection = useCallback(
    e => {
      if (!businessUnit) {
        addNotificationMessage('Select businessUnit first!', 'error', 'Error!');
        return;
      }
      saveOpenSelection({
        businessUnit: businessUnit,
        marketTypeId: mt.id,
        openSelection: e.target.checked,
      });
      setOpenSelection(e.target.checked);
    },
    [addNotificationMessage, businessUnit, mt.id, saveOpenSelection]
  );

  return (
    <tr {...draggableProps} data-index={index} data-id={mt.id}>
      <td>{index + 1}.</td>
      <td>{mt.descriptionTemplates.en.name}</td>
      <td>
        <div className="d-flex">
          <Switch
            label="Expanded?"
            value={openSelection}
            isChecked={openSelection}
            onChange={changeOpenSelection}
          />
          <Button
            cssClass="btn btn-sm btn-outline-danger"
            onClick={() => removeFromMarketCategory(mt)}
          >
            <i className="fas fa-trash-alt" /> &nbsp; Remove from '
            {marketCatName}'
          </Button>
        </div>
      </td>
    </tr>
  );
};

MarketTypesTableRow.propTypes = {
  addNotificationMessage: PropTypes.func.isRequired,
  saveOpenSelection: PropTypes.func.isRequired,
  mt: PropTypes.object.isRequired,
  draggableProps: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  marketCatName: PropTypes.string.isRequired,
  businessUnit: PropTypes.string,
};

export default MarketTypesTableRow;
