import React, { useState, useCallback } from 'react';
import { useUpdate } from '../../../../../hooks';
import InputField from '../../../../../components/Form/InputField';
import PropTypes from 'prop-types';

export const Specifiers = ({ filters, updateFilter }) => {
	const [ specifiersList, setSpecifiersList ] = useState([]);

	useUpdate(
		() => {
			if (Object.keys(filters.specifiers).length > 0) {
				const specifiersList = Object.keys(filters.specifiers).reduce(
					(acc, val, index) => [ ...acc, { [val]: '', type: Object.values(filters.specifiers)[index] } ],
					[]
				);
				setSpecifiersList(specifiersList);
			} else setSpecifiersList([]);
		},
		[ filters ]
	);

	const handleInputChange = useCallback(
		(e, index) => {
			const { name, value } = e.target;
			const specifiersClone = [ ...specifiersList ];
			const filtersClone = { ...filters };
			specifiersClone[index][name] = value;
			filtersClone.markets[0].specifiers = { ...filtersClone.markets[0].specifiers, [name]: value };
			setSpecifiersList(specifiersClone);
			updateFilter(filtersClone);
		},
		[ specifiersList, filters, updateFilter ]
	);

	return (
		<div className="efb-form">
			<div className="form-row">
				{specifiersList.map((specifier, index) => {
					const key = Object.keys(specifier)[0];
					return (
						<div className="form-label-group col-md" key={index}>
							<InputField
								type={specifier.type === 'string' ? 'text' : 'number'}
								min={specifier.type !== 'string' ? '0' : ''}
								className="form-control efb-input"
								name={key}
								onChange={(e) => handleInputChange(e, index)}
								placeholder="Specifier"
							/>
							<label htmlFor={key}>{key}</label>
						</div>
					);
				})}
			</div>
		</div>
	);
};

Specifiers.propTypes = {
	filters: PropTypes.object,
	updateFilter: PropTypes.func
};

export default Specifiers;
