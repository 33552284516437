import React from 'react';

const IconMultiple = ({
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 22.129 17"
      {...otherProps}
    >
      <path
        d="M1675.1,59.915c.5.237.992.479,1.492.71q4.632,2.138,9.267,4.272c.04.018.079.038.125.061a.068.068,0,0,1,0,.121l-.1.051q-5.34,2.464-10.683,4.922a.462.462,0,0,1-.343,0q-5.332-2.454-10.657-4.922c-.031-.014-.061-.03-.1-.049a.068.068,0,0,1,0-.121l.974-.453L1674.8,60a.847.847,0,0,0,.124-.076Zm-8.954,5.042a.068.068,0,0,0,0,.122h0q4.378,2.025,8.758,4.045a.374.374,0,0,0,.277-.012q4.278-1.964,8.551-3.939c.062-.029.124-.059.194-.093a.068.068,0,0,0,0-.121l-.078-.039q-4.316-1.992-8.636-3.977a.521.521,0,0,0-.385.009c-1.628.743-3.251,1.5-4.876,2.25C1668.69,63.783,1667.432,64.362,1666.142,64.957Z"
        transform="translate(-1663.986 -59.915)"
        fill="#464646"
      />
      <path
        d="M1684.6,290.355c-.387.18-.721.337-1.056.492q-4.919,2.269-9.839,4.539a.394.394,0,0,1-.369.012q-5.3-2.461-10.614-4.911c-.075-.035-.147-.075-.251-.129.093-.049.156-.086.223-.117.745-.346,1.492-.687,2.235-1.038a.4.4,0,0,1,.393,0c.255.131.518.245.82.385l-1.626.759c.094.05.15.085.21.112q4.263,1.972,8.524,3.947a.557.557,0,0,0,.52,0q4.26-1.977,8.527-3.94c.067-.031.132-.068.234-.12l-1.637-.76c.334-.156.634-.3.939-.432a.271.271,0,0,1,.2.014C1682.867,289.555,1683.7,289.942,1684.6,290.355Z"
        transform="translate(-1662.472 -278.438)"
        fill="#464646"
      />
      <path
        d="M1684.438,217.274l-1.309-.606a.088.088,0,0,1,0-.16c.271-.127.523-.246.778-.357a.24.24,0,0,1,.174.01c.8.364,1.589.732,2.408,1.111a.088.088,0,0,1,.005.157l-.031.016q-5.362,2.474-10.727,4.939a.49.49,0,0,1-.362-.014q-4.394-2.019-8.781-4.053l-1.835-.851-.075-.037a.088.088,0,0,1,0-.158l1.49-.695c.246-.114.5-.21.737-.343a.518.518,0,0,1,.562.006c.174.1.357.177.558.269a.088.088,0,0,1,0,.16l-1.3.6a.088.088,0,0,0,0,.158l.074.036q4.3,1.991,8.611,3.977a.4.4,0,0,0,.3.006q4.364-2,8.721-4.016h0A.088.088,0,0,0,1684.438,217.274Z"
        transform="translate(-1664.536 -208.841)"
        fill="#464646"
      />
    </svg>
  );
};

export default IconMultiple;