import React from 'react';
import classes from './Header.module.scss';
import IconBurgerMenu from '../../Icons/UI/ui/IconBurgerMenu';

const Header = ({ openedFiltersMenu, setOpenedFiltersMenu, headerTitle }) => {
  return (
    <div className={classes.header}>
      <div className="pl-4 pr-2">
        <IconBurgerMenu
          onClick={() => setOpenedFiltersMenu(!openedFiltersMenu)}
          className="pointer"
          width={16}
          height={16}
        />
      </div>
      {headerTitle}
    </div>
  );
};

export default React.memo(Header);
