export const underlinedSelectStyles = {
  container: styles => ({
    ...styles,
    width: '100%',
    fontSize: 11,
  }),
  control: () => ({
    borderBottom: 'solid 1px rgba(112, 112, 112, 0.56)',
    display: 'flex',
    height: 20,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: styles => ({
    ...styles,
    margin: 0,
  }),
  menu: styles => ({
    ...styles,
    top: 'none',
    width: 'none',
    position: 'fixed',
  }),
};

export const selectStyles = {
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: '#f7f7f7',
      border: '2px solid #feb719',
      borderColor: isFocused ? '#feb719 !important' : '#feb719 !important',
      boxShadow: isFocused ? '#feb719 !important' : '#feb719 !important',
      lineHeight: '1rem',
      borderRadius: '4px',
      cursor: 'text',
      padding: '0.3rem',
    };
  },
  input: styles => ({ ...styles, maxHeight: '26px' }),
  placeholder: styles => ({ ...styles, paddingTop: '2px' }),
  menu: styles => ({
    ...styles,
    backgroundColor: '#f7f7f7',
    border: '2px solid #feb719',
    borderRadius: '4px',
    zIndex: '1051',
    position: 'fixed',
    top: 'none',
    width: 'none',
  }),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#feb719 !important' : '#f7f7f7 !important',
      color: isFocused ? 'white' : 'black',
    };
  },
};

export const selectStylesAbsolute = {
  ...selectStyles,
  menu: styles => ({
    ...styles,
    position: 'absolute',
  }),
};
