import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import * as MarketActions from '../../../../redux/actions/cms/markets';
import classes from './RiskManager.module.scss';
import IconPen from '../../../../components/Icons/UI/ui/IconPen';
import Button from '../../../../components/Layout/Button';
import Table from '../../../../components/NewTable/Table';
import { useFetch } from '../../../../hooks';
import IconStar from '../../../../components/Icons/UI/ui/IconStar';
import IconStarHollow from '../../../../components/Icons/UI/ui/IconStarHollow';
import IconMobile from '../../../../components/Icons/UI/ui/IconMobile';
import IconDesktop from '../../../../components/Icons/UI/ui/IconDesktop';
import IconSingle from '../../../../components/Icons/UI/ui/IconSingle';
import IconMultiple from '../../../../components/Icons/UI/ui/IconMultiple';
import IconNoFreeBet from '../../../../components/Icons/UI/ui/IconNoFreeBet';
import IconFreeBet from '../../../../components/Icons/UI/ui/IconFreeBet';
import IconClock from '../../../../components/Icons/UI/ui/IconClock';

const RiskManager = ({ marketId, takeOverMarket }) => {
  const { data: market } = useFetch(`/market/view/market/admin/${marketId}`);
  const { data: event } = useFetch(
    market ? `/sport/event/view/match/admin/${market.sportEventId}` : null
  );
  const { data: businessUnits } = useFetch('/customer/view/admin/businessUnit');

  const columns = useMemo(
    () => [
      { name: 'Selection name', display: entity => entity.names.en.name },
      { name: 'EU Price', display: entity => entity.outcomeOdds[0].odds },
      { name: 'Stake' },
      { name: 'Liability' },
      { name: 'LiabilityG' },
      { name: 'LiabilityC' },
      { name: 'Bets' },
      { name: 'Single Takeout' },
      { name: 'Max Book Loss' },
      { name: 'Max Mult CC L' },
      { name: 'Max Mult CC M' },
      { name: 'Max Mult CC H' },
      { name: 'Bulk Change' },
      { name: 'Rule Creator' },
      { name: 'Expire Time' },
    ],
    []
  );

  const handleTakeOver = useCallback(() => {
    takeOverMarket(marketId);
  }, [marketId, takeOverMarket]);

  if (!market || !event || !businessUnits) {
    return <div className={classes.wrapper} />;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.info}>
        <div>
          <IconStar
            className="mx-1"
            width={15}
            height={15}
          />
          <div className={classes.betting}>
            <IconStarHollow
              className="mx-1"
              width={15}
              height={15}
            />
            <div className={classes.text}>Match Betting</div>
            <div className={classes.percent}>
              100%
              <IconPen
                height={15}
                width={15}
              />
            </div>
          </div>
          <div className={classes.name}>
            {market.names.en.name}{' '}
            {moment(event.scheduledTime).format('DD/MM/YYYY HH:mm')}
          </div>
        </div>
        <div>
          <div className={classes.feedProvider}>[{market.feedProviderId}]</div>
          <div className={classes.lastUser}>
            Last user: <strong>ME</strong>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between p-1"
        style={{ backgroundColor: '#eeeeee' }}
      >
        <div className={classes.filters}>
          <div>
            <span>Country:</span>
            {businessUnits.map(unit => (
              <i
                title={unit}
                // onClick={checkCountry}
                className={`flag-icon flag-icon-${unit.name.toLowerCase()} flag-size`}
              />
            ))}
          </div>
          <div>
            <span>Device:</span>
            <IconMobile
              height={15}
              width={15}
            />
            <IconDesktop
              width={15}
              height={15}
            />
          </div>
          <div>
            <span>Bet Type:</span>
            <IconSingle
              height={15}
              width={15}
            />
            <IconMultiple
              height={15}
              width={15}
            />
          </div>
          <div>
            <span>Stake:</span>
            <IconNoFreeBet
              width={15}
              height={15}
            />
            <IconFreeBet
              width={15}
              height={15}
            />
          </div>
        </div>
        <div className={classes.controls}>
          <Button
            onClick={handleTakeOver}
            cssClass={`btn ${classes.btnSuccess}`}
          >
            Take Over
          </Button>
          <IconClock height={15} width={15} />
        </div>
      </div>
      <Table data={market.outcomeBetInfos} columns={columns} pagination={false} />
    </div>
  );
};

const mapDispatchToProps = {
  takeOverMarket: MarketActions.takeOverMarket,
};

export default connect(null, mapDispatchToProps)(RiskManager);
