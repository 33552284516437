import React, { useState, useCallback } from 'react';
import Button from '../../../../components/Layout/Button';
import Input from '../../../../components/Form/Input';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal/Modal';
import InputField from '../../../../components/Form/InputField';
import TextEditor from '../ContentCreator/TextEditor';
import { prepareSectionUpdate, prepareRuleUpdate } from '../StaticContentUtils';
import { useUpdate } from '../../../../hooks';

const ContentEditor = ({
  contentType,
  subpageContent,
  updateContent,
  saveRowCellData,
  saveColumnCellData,
  activeContentEditModal,
  images,
  uploadImage,
  closeModal,
}) => {
  const [content, setContent] = useState([]);
  const [tableContent, setTableContent] = useState([]);
  const [subContent, setSubContent] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);

  useUpdate(() => {
    if (activeContentEditModal) setInitialLoad(true);
  }, [activeContentEditModal]);

  const handleInputChange = useCallback(
    (e, tableColumnIndex, tableRowIndex) => {
      if (!initialLoad) {
        const { value, name } = e.target;
        let contentClone = { ...content };
        switch (contentType) {
          case 'Section':
            contentClone[name] = value;
            break;

          case 'Rule':
            contentClone[name === 'htmlBody' ? 'description' : name] = value;
            break;

          case 'Table':
            let tableContentClone;
            const subpageContent = { ...subContent };
            tableContentClone = subContent.table.slice(1);
            if (name === 'tableContent') {
              tableContentClone[tableColumnIndex][tableRowIndex].value = value;
              tableContentClone[tableColumnIndex][tableRowIndex][
                'edited'
              ] = true;
              setTableContent(tableContentClone);
            } else {
              subpageContent.table[tableColumnIndex][tableRowIndex][
                name
              ] = value;
              subpageContent.table[tableColumnIndex][tableRowIndex][
                name === 'key' ? 'editedKey' : 'editedLabel'
              ] = true;
              setSubContent(subpageContent);
            }
            break;

          default:
            return null;
        }
        setContent(contentClone);
      }
    },
    [content, contentType, initialLoad, subContent]
  );

  useUpdate(() => {
    let content, tableContent;
    if (subpageContent) {
      switch (contentType) {
        case 'Section':
          content = prepareSectionUpdate(subpageContent);
          break;

        case 'Rule':
          content = prepareRuleUpdate(subpageContent);
          break;

        case 'Table':
          content = [...subpageContent.table];
          content.splice(0, 1);
          tableContent = content;
          break;

        default:
          return null;
      }
      setContent(content);
      setTableContent(tableContent);
      setSubContent(subpageContent);
      setInitialLoad(false);
    }
  }, [subpageContent]);

  const updateSubpageContent = useCallback(
    content => {
      setInitialLoad(true);
      updateContent(content, contentType);
      closeModal();
    },
    [closeModal, contentType, updateContent]
  );

  const saveRowData = useCallback(
    (rowData, tableColumnIndex, tableRowIndex) => {
      const tableContentClone = [...tableContent];
      tableContentClone[tableColumnIndex][tableRowIndex].edited = false;
      setTableContent(tableContentClone);
      saveRowCellData(rowData);
    },
    [saveRowCellData, setTableContent, tableContent]
  );

  const saveColumnCell = useCallback(
    (columnData, tableColumnIndex, tableRowIndex, keyOrLabel) => {
      const subpageContentClone = { ...subpageContent };
      subpageContentClone.table[tableColumnIndex][tableRowIndex][
        keyOrLabel === 'key' ? 'editedKey' : 'editedLabel'
      ] = false;
      setSubContent(subpageContentClone);
      saveColumnCellData(columnData);
    },
    [saveColumnCellData, setSubContent, subpageContent]
  );

  return (
    <>
      {contentType !== 'Table' ? (
        <Modal
          isActive={activeContentEditModal}
          headerText="Edit"
          onClose={() => {
            closeModal();
            setInitialLoad(true);
          }}
          myClassName="efb-form"
        >
          <div className="modal-wrapper">
            <div className="form-row">
              <div className="form-label-group col-md">
                <InputField
                  type="text"
                  name="name"
                  label={'Name'}
                  value={content.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-label-group col-md">
                <InputField
                  type="text"
                  name={contentType === 'Section' ? 'headerTitle' : 'title'}
                  label={'Header Title'}
                  value={
                    contentType === 'Section'
                      ? content.headerTitle
                      : !content.title
                      ? ''
                      : content.title
                  }
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <TextEditor
              htmlBody={handleInputChange}
              images={images}
              uploadImage={uploadImage}
              value={
                contentType === 'Section'
                  ? content.htmlBody
                  : content.description
              }
            />
            <br />
            <hr />
            <div className="form-group d-flex flex-row justify-content-start">
              <Button
                cssClass="efb-btn__main"
                onClick={() => updateSubpageContent(content)}
              >
                Update
              </Button>
              <Button
                cssClass="efb-btn__main"
                onClick={() => {
                  setInitialLoad(true);
                  closeModal();
                }}
              >
                {' '}
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      ) : (
        // Table page
        <Modal
          isActive={activeContentEditModal}
          onClose={closeModal}
          headerText="Edit"
          myClassName="efb-form"
        >
          <div className="modal-wrapper table-responsive">
            <small className="float-left">
              <i className="fa fa-info-circle"></i> When you enter new value,
              press the checkmark button to save changes. When you save the new
              value, checkmark will be again greyed out.
            </small>
            <br />
            <table className="table table-hover table-striped static-content-edit-table">
              <thead>
                <tr>
                  <th>Label</th>
                  {Object.keys(subpageContent).length > 0
                    ? subpageContent.table[0].map((label, index) => {
                        return (
                          <th key={label.id}>
                            <Input
                              name="value"
                              value={label.value}
                              onChange={e => handleInputChange(e, 0, index)}
                            />{' '}
                            <Button
                              cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
                              disabled={!label.editedLabel}
                              onClick={() =>
                                saveColumnCellData(label, 0, index, 'label')
                              }
                            >
                              <i className="fa fa-check-square"></i>
                            </Button>
                          </th>
                        );
                      })
                    : null}
                </tr>
                <tr>
                  <td>Key</td>
                  {Object.keys(subpageContent).length > 0
                    ? subpageContent.table[0].map((key, index) => {
                        return (
                          <th key={key.id}>
                            <Input
                              name="key"
                              value={key.key}
                              onChange={e => handleInputChange(e, 0, index)}
                            />{' '}
                            <Button
                              cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
                              disabled={!key.editedKey}
                              onClick={() =>
                                saveColumnCell(key, 0, index, 'key')
                              }
                            >
                              <i className="fa fa-check-square"></i>
                            </Button>
                          </th>
                        );
                      })
                    : null}
                </tr>
              </thead>
              <tbody>
                {tableContent.length > 0
                  ? tableContent.map((tableColumnData, tableColumnIndex) => {
                      return (
                        <tr key={tableColumnIndex}>
                          <td>Row data {tableColumnIndex + 1}</td>
                          {tableColumnData.map((rowData, tableRowIndex) => {
                            return (
                              <td key={rowData.id}>
                                <Input
                                  name="tableContent"
                                  value={rowData.value}
                                  onChange={e =>
                                    handleInputChange(
                                      e,
                                      tableColumnIndex,
                                      tableRowIndex
                                    )
                                  }
                                />{' '}
                                <Button
                                  cssClass="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
                                  disabled={!rowData.edited}
                                  onClick={() =>
                                    saveRowData(
                                      rowData,
                                      tableColumnIndex,
                                      tableRowIndex
                                    )
                                  }
                                >
                                  <i className="fa fa-check-square"></i>
                                </Button>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </Modal>
      )}
      <br />
    </>
  );
};

ContentEditor.propTypes = {
  addSection: PropTypes.func,
  backToMainPages: PropTypes.func,
  getSubpageSections: PropTypes.func,
  languages: PropTypes.array,
  pageObj: PropTypes.object,
  removeSection: PropTypes.func,
  setContentLang: PropTypes.func,
  submitContent: PropTypes.func,
  submitSubpage: PropTypes.func,
  subpageSections: PropTypes.array,
  updateObj: PropTypes.func,
};

export default ContentEditor;
