import React from 'react';
import { useFetch } from '../../../../hooks';
import classes from './CustomerDetails.module.scss';

const CustomerCasinoCalculations = ({ customerId }) => {
  const { data: casinoStats } = useFetch(
    'casino-backoffice/admin/casino/quick-stats',
    {
      customerId,
    },
    [customerId]
  );

  if (!casinoStats) return null;

  return (
    <div className="col-md-4">
      <div className={classes.header}>
        <p>Casino</p>
      </div>
      <div className={classes.body}>
        <div className="d-flex">
          <div className="col-md-5">
            <p>Avg. Bet (Slot/Live Casino)</p>
          </div>
          <div className="col-md-4 text-right">
            <p>{casinoStats.avarageBetCasino}</p>
          </div>
          <div className="col-md-3 text-right">
            <b>{casinoStats.avarageBetLiveCasino}</b>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <p>Stake (Slot)</p>
          </div>
          <div className="col-md-4 text-right">
            <p>{casinoStats.bet}</p>
          </div>
          <div className="col-md-3" />
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <p>Payout (Slot)</p>
          </div>
          <div className="col-md-4 text-right">
            <p>{casinoStats.payoutCasinoGames}</p>
          </div>
          <div className="col-md-3" />
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <p>Net W/L (Slot)</p>
          </div>
          <div className="col-md-4 text-right">
            <p>{casinoStats.netWinLossCasinoGames}</p>
          </div>
          <div className="col-md-3 text-right">
            <b>{casinoStats.netWinLossCasinoGamePercentage} %</b>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <p>Stake (Casino)</p>
          </div>
          <div className="col-md-4 text-right">
            <p>{casinoStats.stakeLiveCasino}</p>
          </div>
          <div className="col-md-3" />
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <p>Payout (Casino)</p>
          </div>
          <div className="col-md-4 text-right">
            <p>{casinoStats.payoutLiveCasino}</p>
          </div>
          <div className="col-md-3" />
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <p>Net W/L (Casino)</p>
          </div>
          <div className="col-md-4 text-right">
            <p>{casinoStats.netWinLossLiveCasino}</p>
          </div>
          <div className="col-md-3 text-right">
            <b>{casinoStats.netWinLossLiveCasinoPercentage} %</b>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <p>Net W/L 30 days (Casino + Slot)</p>
          </div>
          <div className="col-md-4 text-right">
            <p>{casinoStats.netWinLossCasinoAndLiveForThirtyDays}</p>
          </div>
          <div className="col-md-3 text-right">
            <b>{casinoStats.netWinLossThirtyDaysPercentage} %</b>
          </div>
        </div>
      </div>
      <div className={classes.sum}>
        <div className="d-flex">
          <div className="col-md-5">
            <b>Stake (Casino + Slot)</b>
          </div>
          <div className="col-md-4 text-right">
            <p>{casinoStats.stake}</p>
          </div>
          <div className="col-md-3" />
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <b>Payout (Casino + Slot)</b>
          </div>
          <div className="col-md-4 text-right">
            <p>{casinoStats.payout}</p>
          </div>
          <div className="col-md-3" />
        </div>
        <div className="d-flex">
          <div className="col-md-5">
            <b>Net W/L (Casino + Slot)</b>
          </div>
          <div className="col-md-4 text-right">
            <p className={classes.greenNumber}>
              {casinoStats.netWinLossCasinoAndLive}
            </p>
          </div>
          <div className="col-md-3 text-right">
            <b>{casinoStats.netWinLossPercentage} %</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCasinoCalculations;
