import React, { Fragment, useState, useMemo } from 'react';
import SportConfigurator from './SportConfigurator';
import BetTypeConfigurator from './BetTypeConfigurator';
import RiskCategoryConfigurator from './RiskCategoryConfigurator';
import MarketEventConfigurator from './MarketEventConfigurator';
import MarketTypeConfigurator from './MarketTypeConfigurator';
import { withRouter } from 'react-router-dom';
import { useQueryParams } from '../../../../hooks';

const CashoutConfigurator = ({ location, history }) => {
  const tabs = useMemo(
    () => [
      { name: 'Sport Configurator', Component: SportConfigurator },
      { name: 'Bet Type Configurator', Component: BetTypeConfigurator },
      {
        name: 'RiskCategory Configurator',
        Component: RiskCategoryConfigurator,
      },
      { name: 'Market/Event Configurator', Component: MarketEventConfigurator },
      { name: 'Market Type Configurator', Component: MarketTypeConfigurator },
    ],
    []
  );
  const { query, setQueryParams } = useQueryParams(location, history);

  const currentTab = useMemo(
    () => tabs.find(tab => tab.name === query.get('tab')),
    [query, tabs]
  );
  const [selectedTab, setSelectedTab] = useState(currentTab || tabs[0]);

  return (
    <Fragment>
      <ul className="nav nav-tabs nav-fill" role="tablist">
        {tabs.map((tab, index) => (
          <li
            onClick={() => {
              setSelectedTab(tab);
              setQueryParams({ tab: tab.name });
            }}
            className="nav-item"
            key={tab.name}
          >
            <div
              name={tab.name}
              tabIndex={index + 1}
              className={
                selectedTab.name === tab.name ? 'nav-link active' : 'nav-link'
              }
            >
              {tab.name}
            </div>
          </li>
        ))}
      </ul>
      <selectedTab.Component />
    </Fragment>
  );
};

export default withRouter(CashoutConfigurator);
