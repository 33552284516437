export const compObj = {
  abbreviations: {},
  countryCode: '',
  names: {},
  feedProducers: [
    {
      feedProviderId: '',
      producerId: '14',
    },
  ],
  type: 'competitor',
  lastUpdateOrigin: 'FEED',
  sportId: '',
};
