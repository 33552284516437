import * as BS from '../actions/BonusSystem';

const initialState = {
  sports: [],
  categories: [],
  tournaments: [],
  businessUnits: [],
  campaigns: [],
  events: [],
  bonuses: [],
  bonusConfigs: [],
  bonusTypes: []
};

export const bonusSystemReducer = (state = initialState, action) => {
  switch (action.type) {
    case BS.GET_SPORTS.SUCCESS:
      return {
        ...state,
        sports: action.payload
      };

    case BS.GET_BONUS_TYPES.SUCCESS:
      return {
        ...state,
        bonusTypes: action.payload
      };

    case BS.GET_BONUS_CONFIGS.SUCCESS:
      return {
        ...state,
        bonusConfigs: action.payload
      };

    case BS.GET_CATEGORIES.SUCCESS:
      return {
        ...state,
        categories: [...state.categories, ...action.payload]
      };

    case BS.GET_CATEGORIES.EMPTY:
      return {
        ...state,
        categories: []
      };

    case BS.GET_TOURNAMENTS.SUCCESS:
      return {
        ...state,
        tournaments: [...state.tournaments, ...action.payload]
      };

    case BS.GET_TOURNAMENTS.EMPTY:
      return {
        ...state,
        tournaments: []
      };

    case BS.GET_EVENTS.EMPTY:
      return {
        ...state,
        events: []
      };

    case BS.GET_EVENTS.SUCCESS:
      return {
        ...state,
        events: [...state.events, ...action.payload]
      };

    case BS.GET_BONUSES.SUCCESS:
      return {
        ...state,
        bonuses: action.payload
      };

    case BS.GET_BONUSES.EMPTY:
      return {
        ...state,
        bonuses: []
      };

    case BS.GET_BUSINESS_UNITS.SUCCESS:
      return {
        ...state,
        businessUnits: action.payload
      };

    case BS.BONUS_STATUS_UPDATED.SUCCESS:
      return {
        ...state,
        bonuses: state.bonuses.map(bonus => bonus.id === action.payload.bonusCustomerId ? {
          ...bonus,
          bonusStatus: action.payload.newStatus
        } : bonus)
      };

    case BS.GET_CAMPAIGNS.SUCCESS:
      return {
        ...state,
        campaigns: action.payload
      };

    default:
      return state;
  }
};
