import { languages } from '../../../config/constants';

export const eventObj = data => {
  const scheduledStart = new Date(
    data.scheduledStart + ' ' + data.actualStart
  ).getTime();
  let eventObj = {
    sportId: data.sport,
    seasonId: data.season,
    tournamentId: data.tournament,
    homeCompetitorId: data.homeCompetitorId,
    awayCompetitorId: data.awayCompetitorId,
    scheduledTime: scheduledStart,
    names: data.names,
    sportEventBookingStatus: 'UNAVAILABLE',
    fixture: {
      nextLiveTime: scheduledStart,
      startTime: scheduledStart,
    },
    marketSize: 0,
    feedProducers: [
      {
        feedProviderId: `efb:match:${new Date().getTime()}`,
        producerId: '14',
      },
    ],
    currentProducerId: '14',
    competitionStatus: {
      type: 'match_status',
      competitionStatus: 'NOTSTARTED',
      sportEventReportingStatus: 'UNKNOWN',
      properties: {},
      matchStatusId: '0',
      matchStatusDescriptions: {
        en: { name: '', translated: true },
        bg: { name: '', translated: true },
      },
    },
    type: 'match',
    status: data.status,
  };

  return eventObj;
};

export const updateEventDate = (date, time, event) => {
  const scheduledStart = new Date(`${date} ${time}`);
  const eventObj = {
    id: event.id,
    sportId: event.sportId,
    seasonId: event.seasonId,
    tournamentId: event.tournamentId,
    homeCompetitorId: event.homeCompetitorId,
    awayCompetitorId: event.awayCompetitorId,
    scheduledTime: scheduledStart,
    names: event.names,
    sportEventBookingStatus: 'UNAVAILABLE',
    fixture: {
      nextLiveTime: scheduledStart,
      startTime: scheduledStart,
    },
    marketSize: 0,
    feedProducers: event.feedProducers,
    currentProducerId:
      event.feedProducers.length > 0 ? event.feedProducers[0].producerId : '',
    type: 'match',
    status: event.status,
  };
  return eventObj;
};

export const updateMarketObj = (market, sportObj, specifiers) => {
  const marketClone = { ...market };
  const marketTypeCategories = [];
  const marketOutcomes = marketClone.outcomeBetInfos;
  let objStrings = {};
  let areOutcomeOddsEmpty = false;

  marketClone.marketType.categories.forEach(category => {
    marketTypeCategories.push({
      names: {},
      id: category.id,
      feedProviderId: '',
    });
  });

  marketClone['sportAggregateId'] = sportObj.sportId;
  marketClone['sportEventAggregateId'] = marketClone.sportEventId;
  marketClone['sportEventFeedProviderId'] =
    sportObj.feedProducers.length > 0
      ? sportObj.feedProducers[0].feedProviderId
      : '';
  marketClone['marketTypeCategories'] = marketTypeCategories;
  marketClone['marketTypeAggregateId'] = marketClone.marketType.id;
  marketClone['betslipsForSettlement'] = [];
  marketClone['producerId'] = market.producerId ? market.producerId : '14';
  marketClone['ownerId'] = 'BR';
  marketClone['specifiers'] = specifiers ? specifiers : marketClone.specifiers;

  // Transforming array of object names to list of strings in object
  marketOutcomes.forEach(outcome => {
    if (outcome.names.en.name) {
      languages.forEach(lang => {
        if (outcome.names[lang] !== undefined) {
          objStrings = {
            ...objStrings,
            [lang]: outcome.names[lang].name,
          };
        }
      });
      outcome.names = objStrings;
    }
    outcome['rollbackedSettlements'] = [];
    if (outcome.outcomeOdds.length > 0) {
      const marketOutcomeOdds = outcome.outcomeOdds[0];
      marketOutcomeOdds['aggregateId'] = outcome.id;
      marketOutcomeOdds['probability'] = marketOutcomeOdds['probability']
        ? marketOutcomeOdds['probability']
        : 0.1040581469;
      delete marketOutcomeOdds.id;
      delete marketOutcomeOdds.createdOn;
      delete marketOutcomeOdds.lastUpdatedOn;
    } else {
      return (areOutcomeOddsEmpty = true);
    }
  });

  delete marketClone['createdOn'];
  delete marketClone['lastUpdatedOn'];
  delete marketClone['tournamentId'];
  delete marketClone.marketType;

  if (!areOutcomeOddsEmpty) {
    return marketClone;
  } else {
    return {};
  }
};

export const marketObj = (sportEventObj, market, marketType) => {
  let homeCompetitor = {},
    awayCompetitor = {};
  const marketTypeCategories = [];

  const feedProviderId = `sr:market:${
    sportEventObj.feedProducers.length > 0
      ? sportEventObj.feedProducers[0].feedProviderId
      : ''
  }:${marketType.feedProviderId}`;

  marketType.categories.forEach(category => {
    marketTypeCategories.push({
      names: {},
      id: category.id,
      feedProviderId: '',
    });
  });

  // Separate sport event names
  languages.forEach(lang => {
    if (sportEventObj.names[lang] !== undefined) {
      homeCompetitor = {
        ...homeCompetitor,
        [lang]: sportEventObj.names[lang].name.split(' vs. ')[0],
      };
      awayCompetitor = {
        ...awayCompetitor,
        [lang]: sportEventObj.names[lang].name.split(' vs. ')[1],
      };
    }
  });

  let objStrings = {};
  let outcomes = [],
    key,
    index;

  // Transform list of descriptionNames objects to list of strings in object (no 'competitor' descriptionNames)
  market.outcomes.forEach(outcome => {
    languages.forEach(lang => {
      if (outcome.descriptionTemplates[lang] !== undefined) {
        if (!outcome.descriptionTemplates[lang].name.includes('competitor')) {
          index = market.outcomes.findIndex(x => x.id === outcome.id);
          objStrings = {
            ...objStrings,
            [lang]: outcome.descriptionTemplates[lang].name,
          };
          return index;
        }
      }
    });
    if (index !== undefined) {
      market.outcomes[index].descriptionTemplates = objStrings;
    }
  });

  // Generate competitor names and populate array of outcome odds
  market.outcomes.forEach(outcome => {
    key = outcome.id;
    const outcomeName = outcome.descriptionTemplates.en;

    if (sportEventObj.type !== 'season') {
      if (outcomeName.name !== undefined) {
        if (outcomeName.name.includes('competitor1')) {
          outcome.descriptionTemplates = homeCompetitor;
        } else if (outcomeName.name.includes('competitor2')) {
          outcome.descriptionTemplates = awayCompetitor;
        }
      }
    }

    outcomes = [
      ...outcomes,
      {
        names: outcome.descriptionTemplates,
        outcomeId: key,
        outcomeOdds: [
          {
            aggregateId: key,
            active: true,
            probability: '',
            feedProviderCreatedTimestamp: null,
            odds: parseFloat(outcome.odds),
            playerOutcome: false,
            oddsChangeReason: 'NORMAL',
          },
        ],
        rollbackedSettlements: [],
      },
    ];
  });

  const marketObj = {
    ownerId: 'BR',
    marketTypeAggregateId: marketType.id,
    betslipsForSettlement: [],
    favourite: true,
    specifiers: market.specifiers ? market.specifiers : {},
    feedProviderId: feedProviderId,
    feedProviderCreatedTimestamp: Date.now(),
    sportEventAggregateId: sportEventObj.id,
    sportAggregateId: sportEventObj.sportId,
    sportEventFeedProviderId:
      sportEventObj.feedProducers.length > 0
        ? sportEventObj.feedProducers[0].feedProviderId
        : '',
    names: marketType.descriptionTemplates,
    marketStatus: 'ACTIVE',
    marketTypeCategories: marketTypeCategories,
    defaultMarket: false,
    producerId: '14',
    outcomeBetInfos: outcomes,
  };

  return marketObj;
};

export const validateMarketForm = filters => {
  let outcomesMissing = false;
  if (!filters.markets[0].marketCategory) {
    return 'You must select market category.';
  }
  if (!filters.markets[0].marketType) {
    return 'You must select market type.';
  }
  filters.markets[0].outcomes.forEach(outcome => {
    if (!outcome.odds || outcome.odds === '') {
      outcomesMissing = true;
    }
  });
  if (outcomesMissing)
    return 'You need to insert outcome odds for this market.';
  if (
    Object.keys(filters.markets[0].specifiers).length <
    Object.keys(filters.specifiers).length
  ) {
    return 'You need to insert specifiers for this market.';
  }
};

export const validateEventForm = eventForm => {
  if (!eventForm.sport) return 'You need to select sport.';
  if (!eventForm.country) return 'You need to select country.';
  if (!eventForm.tournament) return 'You need to select tournament.';
  if (Object.keys(eventForm.home).length === 0)
    return 'You need to select home team.';
  if (Object.keys(eventForm.away).length === 0)
    return 'You need to select away team.';
  if (!eventForm.scheduledStart) return 'You need to select scheduled start.';
  if (!eventForm.actualStart) return 'You need to select scheduled time.';
  if (!eventForm.status) return 'You need to select event status.';
};

export const updateEventStatus = event => ({
  sportId: event.sportId,
  seasonId: event.seasonId,
  tournamentId: event.tournamentId,
  homeCompetitorId: event.homeCompetitorId,
  awayCompetitorId: event.awayCompetitorId,
  scheduledTime: event.scheduledTime,
  names: event.names,
  sportEventBookingStatus: 'UNAVAILABLE',
  fixture: {
    nextLiveTime: event.scheduledTime,
    startTime: event.scheduledTime,
  },
  marketSize: 0,
  feedProducers: event.feedProducers,
  currentProducerId:
    event.feedProducers.length > 0 ? event.feedProducers[0].producerId : '',
  type: 'match',
  status: event.status,
});
