import axiosInstance from '../axios-instance';
import { createRequestTypes, actionCreator } from '../index';
import { addNotificationMessage } from '../ui';

export const GET_IMAGES = createRequestTypes('GET_IMAGES');
export const GET_IMAGE_TYPES = createRequestTypes('GET_IMAGE_TYPES');

export const getImageTypes = () => async dispatch => {
  dispatch(actionCreator.request(GET_IMAGE_TYPES));
  try {
    const res = await axiosInstance.get('/content/admin/image-types');
    dispatch(actionCreator.success(GET_IMAGE_TYPES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_IMAGE_TYPES, err.message));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const uploadImage = (imageType, image) => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('file', image);
    await axiosInstance.post(
      `/content/admin/image/upload?imageType=${imageType}`,
      formData
    );
    dispatch(getImages(imageType));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const getImages = imageType => async dispatch => {
  dispatch(actionCreator.request(GET_IMAGES));
  try {
    const res = await axiosInstance.get(`/content/public/images/${imageType}`);
    dispatch(actionCreator.success(GET_IMAGES, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_IMAGES, ''));
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const deleteImage = (imageId, imageType) => async dispatch => {
  try {
    await axiosInstance.delete(`/content/admin/image/${imageId}`);
    dispatch(getImages(imageType));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};
