import React from 'react';

export const AdHock = (props) => {
    return (
	<span className='efb-icon'>
    	<svg className={`efb-icon--${props.size} ${props.cssClass}`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 128 128" >
	
        <path fill="#3A556A" d="M113.2,111.8H6.8c-3.3,0-6,2.7-6,6s2.7,6,6,6h106.4c3.3,0,6-2.7,6-6S116.5,111.8,113.2,111.8z"/>
        <path fill="#BFBFBF" d="M13.3,103.2c7.3,0,13.3-6,13.3-13.3c0-2.8-0.9-5.4-2.4-7.6l9.5-17.7c0.1,0,0.2,0,0.2,0c2,0,4-0.5,5.7-1.3
            l20.7,15.9C60.1,80,60,80.8,60,81.7C60,89,65.9,95,73.3,95s13.3-6,13.3-13.3c0-2.7-0.8-5.2-2.2-7.3l21.4-37.5c0.2,0,0.4,0,0.6,0
            c7.3,0,13.3-6,13.3-13.3s-6-13.3-13.3-13.3c-7.3,0-13.3,6-13.3,13.3c0,2.7,0.8,5.2,2.2,7.3L73.9,68.4c-0.2,0-0.4,0-0.6,0
            c-2,0-4,0.5-5.7,1.3L46.9,53.7c0.1-0.8,0.2-1.6,0.2-2.4c0-7.3-6-13.3-13.3-13.3s-13.3,6-13.3,13.3c0,2.8,0.9,5.4,2.4,7.6l-9.5,17.7
            c-0.1,0-0.2,0-0.2,0C5.9,76.6,0,82.5,0,89.9C0,97.2,5.9,103.2,13.3,103.2z"/>

        <circle fill="#FEB719" cx="105.5" cy="26.6" r="22.5"/>
        <circle fill="#91BAE1" cx="14" cy="89.7" r="13.8"/>
        <circle fill="#91BAE1" cx="73" cy="81.6" r="13.8"/>
        <circle fill="#91BAE1" cx="34.3" cy="51.1" r="13.8"/>
        <ellipse fill="#FFFFFF" cx="105.8" cy="26.3" rx="13.6" ry="13"/>

    	</svg>
        
		{props.children}
	</span>
    )
}

export default AdHock;