import React, { Fragment, useMemo } from 'react';

const CashoutModelTableRow = ({ tableData, getModel, isEditable }) => {
  const ranges = useMemo(
    () => ({
      ONE_TWO: '1-2',
      TWO_THREE: '2-3',
      THREE_FOUR: '3-4',
      FOUR_FIVE: '4-5',
      FIVE_SIX: '5-6',
      SIX_SEVEN: '6-7',
      SEVEN_EIGHT: '7-8',
      EIGHT_NINE: '8-9',
      NINE_TEN: '9-10',
      TEN_ELEVEN: '11-12',
      ELEVEN_TWELVE: '11-12',
      ABOVE_TWELVE: '12-',
    }),
    []
  );

  const renderRow = model => {
    const onKeyDown = event => {
      const { name, value } = event.target;
      model[name] = value;
      getModel(model);
    };

    return (
      <tr key={model.displayName}>
        <td className="pb-sm-2 pt-2 pl-sm-5 text-left">
          {model.displayName ||
            ranges[model.coefficientRange] ||
            'Not available name'}
        </td>
        <td
          className={
            isEditable
              ? 'pt-sm-1 pb-sm-1 pr-md-0 text-left'
              : 'pt-sm-2 pb-sm-2 pr-md-5 text-left'
          }
        >
          <div className="form-row flex-nowrap">
            {Object.keys(model).map((key, idx) => {
              return key.length <= 2 ? (
                <Fragment key={idx}>
                  <div className="ml-2 mr-2 pb-sm-1 pt-1 font-weight-bold align-self-center">
                    {key}
                  </div>
                  {isEditable ? (
                    <div className="form-label-group col-md-3 my-0">
                      <input
                        type="number"
                        step="0.1"
                        name={key}
                        className="form-control"
                        placeholder={key}
                        onChange={onKeyDown}
                      />
                      <label className="top-fix greyed" htmlFor="table-search">
                        Model Parameters
                      </label>
                    </div>
                  ) : (
                    <div className="ml-2 mr-2 pb-sm-1 pt-1 col-md-3 align-self-center">
                      {model[key]}
                    </div>
                  )}
                </Fragment>
              ) : null;
            })}
          </div>
        </td>
      </tr>
    );
  };
  return tableData.map(renderRow);
};

export default React.memo(CashoutModelTableRow);
