import React from 'react';

export const Edit = (props) => {
    return (
        <span className='efb-icon'>
            <svg className={`efb-icon--${props.size} efb-edit`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
            <path d="M34.7,33.2H1.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h33.5c0.7,0,1.3-0.6,1.3-1.3S35.4,33.2,34.7,33.2z"/>
            <path d="M0,22.1l0,6c0,0.3,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.4,0.9,0.4l5.9,0c0.3,0,0.6-0.1,0.9-0.4L28.5,8.4c0.5-0.5,0.5-1.3,0-1.8
                l-5.9-5.9c-0.5-0.5-1.3-0.5-1.8,0l-4.1,4.1L0.4,21.2C0.2,21.4,0,21.7,0,22.1L0,22.1z M21.8,3.4l4.1,4.1l-2.3,2.3l-4.1-4.1
                L21.8,3.4z M2.6,22.6L17.7,7.5l4.1,4.1L6.7,26.7l-4.2,0L2.6,22.6L2.6,22.6z"/>
            </svg>
            {props.children}
        </span>
    )
}

export default Edit;