import React, { memo } from 'react';

export const VirtualDogs = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  const icon = (
    <>
      <path
        d="M17.96,29.92c-0.03-0.36-0.03-0.36,0-0.61c7.93,0,14.35-6.43,14.35-14.35S25.89,0.61,17.96,0.61S3.61,7.03,3.61,14.96
	c-0.28,0.03-0.25,0.03-0.61,0C3,6.7,9.71,0,17.96,0s14.96,6.69,14.96,14.96S26.22,29.92,17.96,29.92L17.96,29.92z"
      />
      <path
        d="M0.01,20.83c0.03-0.22,0.12-0.42,0.2-0.62c0.11-0.26,0.22-0.53,0.34-0.79c0.03-0.05,0.05-0.1,0.08-0.15
	c0.05-0.09,0.11-0.09,0.16,0c0.05,0.1,0.09,0.22,0.13,0.34C1,19.9,1.01,20.16,0.98,20.45c-0.03,0.18-0.09,0.37-0.12,0.57
	c0,0.29,0.14,0.63,0.8,1.18c0.26,0.21,0.38,0.28,0.62,0.3c0.17,0.03,0.34,0.03,0.5,0c0.13-0.03,0.28-0.05,0.39-0.1
	c0.41-0.17,0.78-0.4,1.16-0.63c0.07-0.04,0.13-0.09,0.2-0.15c0.16-0.12,0.29-0.26,0.42-0.42c0.16-0.18,0.3-0.38,0.45-0.58
	c0.26-0.36,0.41-0.75,0.41-1.19c0-0.22,0.01-0.45,0-0.67c-0.01-0.45,0.05-0.9,0.12-1.34c0.04-0.26,0.08-0.53,0.13-0.79
	c0.09-0.47,0.25-0.92,0.49-1.34C6.7,15,6.87,14.7,7.06,14.42c0.2-0.3,0.41-0.58,0.67-0.84c0.26-0.25,0.54-0.47,0.84-0.66
	c0.2-0.12,0.41-0.22,0.63-0.3c0.34-0.13,0.7-0.25,1.05-0.34c0.3-0.08,0.61-0.13,0.92-0.13s1.46,0,2.19,0.45
	c0.74,0.41,1.48,0.69,2.25,1c0.38,0.16,0.78,0.28,1.2,0.34c0.3,0.05,0.62,0.09,0.93,0.08c0.18-0.01,0.38-0.05,0.57-0.08
	c0.04,0,0.09-0.01,0.13-0.01c0.28-0.01,0.54-0.09,0.8-0.18c0.01,0,0.04-0.01,0.05-0.03c0.13-0.12,0.28-0.22,0.4-0.34
	c0.38-0.42,0.75-0.84,1.12-1.26c0.28-0.3,0.57-0.59,0.84-0.88c0.21-0.24,0.42-0.47,0.63-0.7c0.03-0.03,0.04-0.05,0.08-0.09
	c-0.05,0-0.08,0-0.12,0c-0.17,0.03-0.32,0.01-0.47-0.07c-0.01,0-0.26-0.07-0.36-0.3c-0.01-0.01-0.01-0.22,0.13-0.36
	c0.21-0.28,0.99-0.37,1.05-0.36c0.15,0.01,0.75,0,0.87,0.01c0.05,0,0.43,0.08,0.49,0.07c0.46-0.03,0.92,0,1.37,0.13
	c0.2,0.05,0.37,0.15,0.53,0.28c0.18,0.16,0.38,0.33,0.57,0.49c0.25,0.2,0.53,0.33,0.8,0.45c0.28,0.1,0.55,0.21,0.83,0.32
	c0.3,0.12,0.63,0.18,0.95,0.25c0.13,0.03,0.28,0.04,0.41,0.07c0.13,0.01,0.22,0.07,0.26,0.21c0,0.01,0,0.09,0,0.12
	c-0.03,0.15-0.09,0.25-0.18,0.37c-0.05,0.07-0.1,0.15-0.12,0.24c0,0.03-0.15,0.24-0.21,0.32c-0.15,0.2-0.33,0.33-0.57,0.4
	c-0.24,0.05-0.47,0.09-0.71,0.1c-0.25,0.01-0.5,0.01-0.75,0.03c-0.26,0.01-0.53,0.03-0.78,0.12c-0.04,0.01-0.07,0.03-0.1,0.04
	c-0.21,0.08-0.41,0.18-0.62,0.25c-0.47,0.17-0.88,0.44-1.26,0.74c-0.28,0.21-0.5,0.46-0.71,0.74c-0.28,0.36-0.5,0.75-0.71,1.15
	c-0.34,0.65-0.65,1.32-1.01,1.95c-0.03,0.05-0.07,0.1-0.1,0.16c-0.18,0.26-0.37,0.53-0.55,0.78c-0.1,0.15-0.21,0.28-0.33,0.41
	c-0.13,0.16-0.3,0.29-0.47,0.42c-0.26,0.21-0.54,0.37-0.84,0.53c-0.29,0.15-0.59,0.29-0.87,0.45c-0.33,0.18-0.66,0.36-1.01,0.49
	c-0.2,0.08-0.38,0.16-0.58,0.24c-0.13,0.05-0.25,0.1-0.37,0.18c-0.24,0.15-0.41,0.37-0.57,0.59c0,0.01,0,0.01-0.01,0.04
	c0.1,0.03,0.21,0.05,0.32,0.07c0.36,0.07,0.71,0.1,1.08,0.13c0.16,0.01,0.32,0.04,0.46,0.09c0.32,0.1,0.63,0.22,0.93,0.34
	c0.12,0.04,0.24,0.09,0.33,0.18c0.1,0.09,0.22,0.15,0.34,0.18c0.04,0.01,0.07,0.03,0.09,0.04c0.08,0.04,0.15,0.08,0.2,0.16
	c0.08,0.13,0.08,0.26,0.07,0.41c0,0.03-0.04,0.05-0.07,0.07s-0.07,0.01-0.1,0.01c-0.13-0.03-0.26-0.04-0.4-0.08
	c-0.13-0.04-0.25-0.04-0.38-0.03c-0.18,0.01-0.37,0.03-0.55,0.03c-0.13,0-0.24-0.07-0.29-0.2c-0.01-0.04-0.04-0.08-0.05-0.12
	c-0.09-0.16-0.21-0.25-0.4-0.26c-0.16-0.01-0.32-0.03-0.47-0.04c-0.55-0.07-1.11-0.13-1.66-0.2c-0.07-0.01-0.12,0-0.16,0.07
	c-0.2,0.29-0.4,0.57-0.59,0.84c-0.01,0.01-0.01,0.03-0.03,0.05c0.03,0.01,0.04,0.03,0.07,0.03c0.36,0.13,0.72,0.24,1.11,0.3
	c0.36,0.05,0.72,0.08,1.08,0.1c0.17,0.01,0.34,0.04,0.51,0.1c0.32,0.12,0.62,0.26,0.92,0.41c0.1,0.05,0.2,0.1,0.29,0.18
	c0.12,0.1,0.24,0.17,0.38,0.22c0.03,0.01,0.05,0.03,0.08,0.04c0.13,0.07,0.22,0.17,0.24,0.33c0,0.07,0.01,0.13,0.01,0.18
	c0,0.1-0.04,0.13-0.13,0.13c-0.08,0-0.15-0.01-0.22-0.04c-0.22-0.05-0.45-0.1-0.68-0.09c-0.16,0.01-0.33-0.01-0.49-0.04
	c-0.15-0.01-0.24-0.09-0.28-0.24c-0.03-0.09-0.08-0.17-0.12-0.25c-0.04-0.08-0.12-0.1-0.2-0.12c-0.08-0.01-0.15-0.01-0.22-0.03
	c-0.53-0.04-1.05-0.09-1.58-0.13c-0.47-0.04-0.95-0.07-1.42,0.04c-0.07,0.01-0.1,0.04-0.13,0.1c-0.12,0.26-0.24,0.54-0.34,0.8
	c-0.1,0.25-0.22,0.49-0.42,0.67c-0.13,0.13-0.26,0.26-0.4,0.38c-0.18,0.17-0.37,0.37-0.5,0.58c-0.13,0.22-0.26,0.45-0.38,0.66
	c-0.08,0.13-0.17,0.25-0.32,0.32c-0.34,0.18-0.69,0.37-1.03,0.54c-0.12,0.07-0.25,0.12-0.41,0.1c-0.13-0.01-0.25-0.01-0.37-0.07
	c-0.13-0.07-0.17-0.2-0.05-0.3c0.08-0.08,0.15-0.16,0.2-0.25s0.13-0.16,0.24-0.17c0.04,0,0.08-0.03,0.1-0.05
	c0.05-0.05,0.1-0.1,0.15-0.16c0.05-0.07,0.12-0.15,0.17-0.21c0.07-0.08,0.15-0.13,0.25-0.16c0.09-0.03,0.18-0.03,0.26,0.05
	c0.13-0.3,0.34-0.54,0.53-0.79c0.09-0.12,0.17-0.22,0.26-0.34c0.13-0.2,0.21-0.4,0.17-0.65c-0.03-0.13,0.03-0.25,0.13-0.34
	c0.21-0.17,0.38-0.38,0.51-0.62c0.09-0.16,0.17-0.32,0.25-0.47c-0.04-0.08-0.07-0.15-0.08-0.2c-0.18-0.51-0.37-1.04-0.55-1.55
	c-0.04-0.09-0.08-0.2-0.12-0.3c-0.1,0.09-0.21,0.16-0.3,0.24c-0.33,0.26-0.61,0.58-0.88,0.9c-0.17,0.2-0.37,0.37-0.59,0.47
	c-0.16,0.07-0.3,0.15-0.46,0.21c-0.26,0.1-0.51,0.24-0.74,0.42c-0.18,0.15-0.36,0.32-0.54,0.46c-0.09,0.08-0.2,0.16-0.3,0.2
	c-0.1,0.05-0.24,0.08-0.36,0.1c-0.21,0.05-0.42,0.12-0.64,0.17c-0.15,0.04-0.28,0.07-0.42,0.09c-0.16,0.03-0.3-0.01-0.45-0.1
	c-0.05-0.03-0.09-0.08-0.12-0.13c-0.04-0.07-0.03-0.13,0.04-0.17c0.04-0.03,0.07-0.04,0.1-0.07c0.13-0.08,0.28-0.1,0.38-0.21
	c0.01-0.01,0.03-0.01,0.04-0.01c0.1-0.01,0.17-0.08,0.24-0.16c0.03-0.03,0.05-0.07,0.08-0.09c0.1-0.1,0.21-0.21,0.36-0.25
	c0.08-0.03,0.17-0.05,0.25,0.01c0.08,0.07,0.08,0.07,0.16,0c0.21-0.2,0.44-0.34,0.69-0.5c0.07-0.04,0.12-0.07,0.18-0.1
	c0.12-0.08,0.22-0.16,0.34-0.25c0.13-0.1,0.22-0.24,0.26-0.41c0.04-0.18,0.15-0.3,0.32-0.37c0.29-0.12,0.54-0.29,0.76-0.51
	c0.36-0.34,0.72-0.69,1.08-1.03c0.01-0.01,0.03-0.03,0.04-0.04c-0.01-0.03-0.01-0.05-0.03-0.08c-0.09-0.22-0.18-0.45-0.28-0.67
	c-0.09-0.22-0.18-0.45-0.29-0.67c-0.07-0.13-0.16-0.25-0.33-0.26c-0.08,0-0.15,0-0.22,0c-0.32,0.04-0.63,0.03-0.95-0.01
	c-0.22-0.03-0.44-0.05-0.66-0.07c-0.29-0.03-0.58-0.04-0.87-0.05c-0.34-0.01-0.63-0.15-0.89-0.38c-0.17-0.15-0.34-0.28-0.51-0.42
	c-0.13-0.12-0.22-0.13-0.4-0.17c-0.01,0,0,0.17,0.03,0.32c0.03,0.21,0.03,0.38,0.01,0.58c-0.03,0.34-0.1,0.66-0.28,0.96
	c-0.2,0.34-0.42,0.67-0.67,0.96c-0.16,0.2-0.34,0.37-0.55,0.5c-0.37,0.25-0.75,0.49-1.16,0.67c-0.17,0.08-0.36,0.16-0.55,0.2
	c-0.16,0.03-0.32,0.05-0.47,0.07c-0.43,0.03-0.86,0-1.26-0.17C1.25,22.95,1,22.78,0.79,22.54c-0.25-0.28-0.5-0.58-0.68-0.91
	C0.04,21.42-0.03,21.08,0.01,20.83L0.01,20.83z M14.72,21.68c0.07-0.12,0.04-0.24,0.07-0.37c-0.08,0.05-0.15,0.09-0.21,0.13
	C14.62,21.52,14.67,21.59,14.72,21.68z"
      />
    </>
  );

  return (
    <svg
      x="0px"
      y="0px"
	  className={
        `VirtualDogs ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.92 29.92"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      {icon}
    </svg>
  );
};

export default memo(VirtualDogs);
