import axiosInstance from '../axios-instance';
import { createRequestTypes, actionCreator } from '..';
import { addNotificationMessage } from '../ui';

export const GET_TOP_BETS = createRequestTypes('GET_TOP_BETS');
export const GET_TOP_EVENTS = createRequestTypes('GET_TOP_EVENTS');
export const GET_TOP_COUPONS = createRequestTypes('GET_TOP_COUPONS');

export const BETS_SUGGESTED_LOAD = createRequestTypes('BETS_SUGGESTED_LOAD');
export const EVENT_SUGGESTED_LOAD = createRequestTypes('EVENT_SUGGESTED_LOAD');
export const COUPON_SUGGESTED_LOAD = createRequestTypes(
  'COUPON_SUGGESTED_LOAD'
);

export const TOP_COUPON_ADD = createRequestTypes('TOP_COUPON_ADD');
export const TOP_BET_ADD = createRequestTypes('TOP_BET_ADD');
export const TOP_EVENT_ADD = createRequestTypes('TOP_EVENT_ADD');

export const TOP_COUPON_REMOVE = createRequestTypes('TOP_COUPON_REMOVE');
export const TOP_BET_REMOVE = createRequestTypes('TOP_BET_REMOVE');
export const TOP_EVENT_REMOVE = createRequestTypes('TOP_EVENT_REMOVE');

export const getTopBets = language => getTop('BET', GET_TOP_BETS, language);

export const getTopEvents = language =>
  getTop('EVENT', GET_TOP_EVENTS, language);

export const getTopCoupons = language =>
  getTop('COUPON', GET_TOP_COUPONS, language);

const getTop = (type, requestType, language) => async dispatch => {
  dispatch(actionCreator.request(requestType));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/highlight/admin/${type}/${language}`
    );

    dispatch(actionCreator.success(requestType, res));
  } catch (err) {
    if (err.status === 404) {
      dispatch(actionCreator.success(requestType, []));
    } else {
      dispatch(actionCreator.failure(requestType, err.message));
    }
  }
};

const findEventById = (events, eventId) => {
  return events.find(topBet => topBet.highlightId === eventId);
};

export const removeTopCoupon = (id, bsUnit, topCoupons) => async dispatch => {
  const topCouponObj = findEventById(topCoupons, id);

  dispatch(actionCreator.request(TOP_COUPON_REMOVE));
  try {
    await axiosInstance.delete('/sport/event/view/highlight/admin', {
      data: [
        {
          ...topCouponObj,
        },
      ],
    });

    dispatch(actionCreator.success(TOP_COUPON_REMOVE));
    getTopCoupons(bsUnit)(dispatch);
  } catch (err) {
    dispatch(actionCreator.failure(TOP_COUPON_REMOVE, err.message));
  }
};

export const removeTopBet = (id, bsUnit, topBets) => async dispatch => {
  const topBetObj = findEventById(topBets, id);

  dispatch(actionCreator.request(TOP_BET_REMOVE));
  try {
    await axiosInstance.delete('/sport/event/view/highlight/admin', {
      data: [
        {
          ...topBetObj,
        },
      ],
    });

    dispatch(actionCreator.success(TOP_BET_REMOVE));
    getTopBets(bsUnit)(dispatch);
  } catch (err) {
    dispatch(actionCreator.failure(TOP_BET_REMOVE, err.message));
  }
};

export const removeTopEvent = (id, bsUnit, topEvents) => async dispatch => {
  const topEventObj = findEventById(topEvents, id);

  dispatch(actionCreator.request(TOP_EVENT_REMOVE));
  try {
    await axiosInstance.delete('/sport/event/view/highlight/admin', {
      data: [
        {
          ...topEventObj,
        },
      ],
    });

    dispatch(actionCreator.success(TOP_EVENT_REMOVE));
    getTopEvents(bsUnit)(dispatch);
  } catch (err) {
    dispatch(actionCreator.failure(TOP_EVENT_REMOVE, err.message));
  }
};

const addTop = (
  type,
  requestType,
  updateFunc,
  id,
  language,
  image = null
) => async dispatch => {
  dispatch(actionCreator.request(requestType));

  try {
    let resImage;
    if (image) {
      let formData = new FormData();
      formData.append('file', image, image.name);
      resImage = await axiosInstance.post(
        '/content/admin/image/upload?imageType=BANNER',
        formData
      );
    }

    const res = await axiosInstance.post('/sport/event/view/highlight/admin', [
      {
        type,
        businessUnit: language,
        highlightId: id,
        uri: resImage ? resImage.uri : null,
      },
    ]);

    dispatch(actionCreator.success(requestType, res));
    updateFunc(language)(dispatch);
  } catch (err) {
    if (err.errors) {
      for (let message of Object.values(err.errors)) {
        dispatch(addNotificationMessage(message, 'error', 'Error!'));
      }
    } else {
      dispatch(addNotificationMessage(err.message, 'error', 'Error!'));
    }
    dispatch(actionCreator.failure(requestType, { message: err.message }));
  }
};

export const addTopCoupon = (id, language) =>
  addTop('COUPON', TOP_COUPON_ADD, getTopCoupons, id, language);
export const addTopBet = (id, language) =>
  addTop('BET', TOP_BET_ADD, getTopBets, id, language);
export const addTopEvent = (id, image, language) =>
  addTop('EVENT', TOP_EVENT_ADD, getTopEvents, id, language, image);

const loadSuggested = (
  type,
  actionType,
  language,
  page,
  size
) => async dispatch => {
  dispatch(actionCreator.request(actionType));
  try {
    const res = await axiosInstance.get(
      `/sport/event/view/${
        type === 'COUPON' ? 'tournament' : 'match'
      }/admin/highlights/${type}/suggestions`,
      {
        params: { lang: language, page, size },
      }
    );
    dispatch(actionCreator.success(actionType, res));
  } catch (err) {
    if (err.status === 404) {
      dispatch(actionCreator.success(actionType, []));
    } else {
      dispatch(
        actionCreator.failure(actionType, {
          message: err.message,
        })
      );
    }
  }
};

export const loadSuggestedBets = (language, page, size) =>
  loadSuggested('BET', BETS_SUGGESTED_LOAD, language, page, size);
export const loadSuggestedEvents = (language, page, size) =>
  loadSuggested('EVENT', EVENT_SUGGESTED_LOAD, language, page, size);
export const loadSuggestedCoupons = (language, page, size) =>
  loadSuggested('COUPON', COUPON_SUGGESTED_LOAD, language, page, size);
