import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { columnsParams, updateFilterObj } from '../CashoutManagerUtils';
import { useUpdate } from '../../../../hooks';

import FullScreenLoader from '../../../../components/Layout/FullScreenLoader/FullScreenLoader';
import Table from '../../../../components/Table/Table';
import Arrow from '../../../../components/Icons/Arrow';

const CashoutCoefficientTable = ({ data, update, title, isLoading }) => {
  const [openTable, setOpenTable] = useState(false);

  useUpdate(() => {
    document.getElementById(title).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, [title, openTable]);

  return (
    <>
      <div
        onClick={() => setOpenTable(!openTable)}
        className="d-flex justify-content-between pointer border-bottom"
        id={title}
      >
        <h2 className="my-4">{title}</h2>
        <div
          style={{
            transform: `rotate(${openTable ? '180deg' : '0deg'})`,
            alignSelf: 'flex-end',
          }}
        >
          <Arrow size="xs" />
        </div>
      </div>
      {openTable && data && data.length > 0 ? (
        <Table
          data={data}
          columns={columnsParams}
          tableStyle="sortable table table-hover table-striped efb-table efb-data-table"
          tableType="cashoutCoefficientTable"
          setCashout={obj => updateFilterObj(data, obj, update)}
          placeHolder={'Coefficient value'}
          pageSize={data.length}
          pagination={false}
        />
      ) : null}
      <FullScreenLoader isActive={isLoading} />
    </>
  );
};

CashoutCoefficientTable.propTypes = {
  update: PropTypes.func,
  data: PropTypes.array,
  title: PropTypes.string,
};

export default CashoutCoefficientTable;
