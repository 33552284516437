import { action, actionCreator } from '.';
//Requests (TODO: At some point in needs to go in folder 'middleware/requests.js');
import axiosInstance from './axios-instance';
import { addNotificationMessage } from './ui';

const REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  GET_USERS_FAILURE = 'GET_USERS_FAILURE';

const createRequestTypes = base => {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
};

export const getUsersRequestType = createRequestTypes('USERS');
export const CREATE_USER = createRequestTypes('CREATE_USER');
export const UPDATE_USER = createRequestTypes('UPDATE_USER');
export const UPDATE_USER_DESCRIPTION = createRequestTypes(
  'UPDATE_USER_DESCRIPTION'
);
export const UPDATE_USER_STATUS = createRequestTypes('UPDATE_USER_STATUS');
export const UPDATE_USER_ROLES = createRequestTypes('UPDATE_USER_ROLES');

export const actions = {
  request: () => action(getUsersRequestType.REQUEST),
  success: payload => action(getUsersRequestType.SUCCESS, { payload }),
  failure: error => action(getUsersRequestType.FAILURE, { error }),
};

export const getAll = (pageNumber = 1) => {
  const params = {
    pageNumber,
    pageSize: 100,
  };
  return dispatch => {
    dispatch(actions.request());
    axiosInstance
      .get('/usermgnt/view/user/admin', { params: params })
      .then(response => {
        return dispatch(actions.success(response));
      })
      .catch(error => {
        console.log(error);
        return dispatch({ type: GET_USERS_FAILURE, payload: error });
      });
  };
};

export const getUserByUsername = username => {
  return async dispatch => {
    dispatch(actions.request());
    try {
      const res = await axiosInstance.get(
        `/usermgnt/view/user/admin/${username}`
      );
      dispatch(actions.success(res));
    } catch (error) {
      dispatch(addNotificationMessage(error.error, 'error', 'Error!'));
      dispatch(actions.failure(error.error));
    }
  };
};

export const getUserById = id => async dispatch => {
  let res;
  try {
    res = await axiosInstance.get(`usermgnt/view/user/admin/id/${id}`);
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
  return res;
};

export const createUser = userBody => async dispatch => {
  dispatch(actionCreator.request(CREATE_USER));
  try {
    await axiosInstance.post('/usermgnt/user/admin', userBody);
    dispatch(
      addNotificationMessage(
        'Successfully created user!',
        'success',
        'Success!'
      )
    );
    dispatch(actionCreator.success(CREATE_USER));
  } catch (error) {
    for (const message of Object.values(error.errors)) {
      dispatch(addNotificationMessage(message, 'error', 'Error!'));
    }
    dispatch(actionCreator.failure(CREATE_USER));
    throw error;
  }
};

export const updateUser = (id, description, isActive) => async dispatch => {
  dispatch(actionCreator.request(UPDATE_USER));
  dispatch(editUserDescription(id, description));
  dispatch(editUserStatus(id, isActive));
  dispatch(
    addNotificationMessage('Successfully updated user!', 'success', 'Success!')
  );
  dispatch(actionCreator.success(UPDATE_USER));
};

const editUserDescription = (id, description) => async dispatch => {
  dispatch(actionCreator.request(UPDATE_USER_DESCRIPTION));
  try {
    await axiosInstance.patch(
      `/usermgnt/user/admin/${id}/description/${description}`
    );
    dispatch(actionCreator.success(UPDATE_USER_DESCRIPTION));
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    dispatch(actionCreator.failure(UPDATE_USER_DESCRIPTION));
  }
};

const editUserStatus = (id, isActive) => async dispatch => {
  dispatch(actionCreator.request(UPDATE_USER_STATUS));
  try {
    await axiosInstance.patch(`/usermgnt/user/admin/${id}/active/${isActive}`);
    dispatch(actionCreator.success(UPDATE_USER_STATUS));
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    dispatch(actionCreator.failure(UPDATE_USER_STATUS));
  }
};

export const addRoleToUser = (userId, role) => async dispatch => {
  dispatch(actionCreator.request(UPDATE_USER_ROLES));
  try {
    await axiosInstance.post(`/usermgnt/user/admin/${userId}/role`, role);
    dispatch(
      addNotificationMessage(
        "Successfully updated user's roles",
        'success',
        'Success!'
      )
    );
    dispatch(actionCreator.success(UPDATE_USER_ROLES));
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    dispatch(actionCreator.failure(UPDATE_USER_ROLES));
  }
};
