import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import ContextMenu from '../../../components/ContextMenu/ContextMenu';
import Spinner from '../../../components/Layout/Spinner/Spinner';
import Table from '../../../components/NewTable/Table';
import AccountHistoryModal from './AccountHistoryModal/AccountHistoryModal';
import TransactionHistoryModal from './TransactionHistoryModal/TransactionHistoryModal';
import Additional from './ClientDataModal/Additional/Additional';
import ClientDataModal from './ClientDataModal/ClientDataModal';
import Documents from './ClientDataModal/Documents/Documents';
import General from './ClientDataModal/General/General';
import OtherParticulars from './ClientDataModal/OtherParticulars/OtherParticulars';
import PaymentDetails from './ClientDataModal/PaymentDetails/PaymentDetails';
import ResponsibleGambling from './ClientDataModal/ResponsibleGaming/ResponsibleGambling';
import CustomerDetails from './CustomerDetails/CustomerDetails';
import DepositWithdraw from './DepositWithdraw/DepositWithdraw';
import MessageHistory from './MessageHistory/MessageHistory';
import ActionLog from './ActionLog/ActionLog';
import Journal from './Journal/Journal';
import Sidebar from './Sidebar/Sidebar';
import classes from './PlayerManager.module.scss';

import { getCustomerData } from '../../../selectors';
import { getSvgFlag } from '../../../utils/flags';
import { useFetch, useContextLocation, useUpdate } from '../../../hooks';

const tabs = [
  { name: 'General', Component: General },
  { name: 'Additional', Component: Additional },
  { name: 'Payment Details', Component: PaymentDetails },
  { name: 'Responsible Gambling', Component: ResponsibleGambling },
  { name: 'Documents', Component: Documents },
  { name: 'Other Particulars', Component: OtherParticulars },
];

const PlayerManager = ({ customers }) => {
  const [openedContextMenu, setOpenedContextMenu] = useContextLocation(null);
  const [customer, setCustomer] = useState(null);
  const [openedClientDataModal, setOpenedClientDataModal] = useState(null);
  const [openedJournalModal, setOpenedJournalModal] = useState(null);
  const [openedDepositModal, setOpenedDepositModal] = useState(null);
  const [messageHistoryModal, setMessageHistoryModal] = useState(null);
  const [searchCustomersWithFilters, setSearchCustomersWithFilters] = useState(
    null
  );
  const [totalPages, setTotalPages] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [actionLogModal, setActionLogModal] = useState(null);

  const clientId = new URLSearchParams(window.location.search).get('clientId');
  const tabParam = new URLSearchParams(window.location.search).get('tab');
  const { data: fetchedCustomer } = useFetch(
    clientId ? `customer/view/admin/${clientId}` : null
  );

  useUpdate(() => {
    if (customers.length) {
      if (!clientId) {
        const customerId = new URLSearchParams(window.location.search).get(
          'customerId'
        );
        const currentCustomer = customers.find(
          customer => customer.customerId === customerId
        );
        setCustomer(currentCustomer || customers[0]);
      }
      // Client risk tab param
      if (tabParam && tabParam === 'clientRisk' && clientId) {
        setOpenedClientDataModal(tabs.find(x => x.name === 'Additional'));
      }
    }
  }, [customers, clientId]);

  useUpdate(() => {
    if (fetchedCustomer) {
      // Client risk tab param
      if (tabParam && tabParam === 'clientRisk') {
        setOpenedClientDataModal(tabs.find(x => x.name === 'Additional'));
      }
      setCustomer(fetchedCustomer);
    }
  }, [fetchedCustomer]);

  const columns = useMemo(
    () => [
      { name: 'efbet ID', display: customer => customer.publicId },
      { name: 'Username', display: customer => customer.user?.username },
      {
        name: 'Country',
        display: customer => (
          <img
            src={getSvgFlag(customer.country.countryCode)}
            className={classes.countryIcon}
            alt={customer.country.countryCode}
          />
        ),
      },
      { name: 'Email', display: customer => customer.email },
      {
        name: 'Balance',
        display: customer =>
          Number(
            customer.accounts.find(x => x.accountType === 'MAIN')
              ? customer.accounts.find(x => x.accountType === 'MAIN').balance /
                  100
              : 0
          ).toFixed(2),
      },
      { name: 'First Name', display: customer => customer.firstName },
      { name: 'Last Name', display: customer => customer.lastName },
      { name: 'ID', display: customer => customer.customerId },
      { name: 'Phone', display: customer => customer.mobilePhone },
      {
        name: 'Profile Created',
        display: customer =>
          moment(customer.createdOn).format('DD/MM/YYYY HH:mm:ss'),
      },
    ],
    []
  );

  const contextRows = useMemo(
    () => [
      ...tabs.map(tab => ({
        name: tab.name,
        onClick: () => {
          setCustomer(openedContextMenu);
          setOpenedClientDataModal(tab);
        },
      })),
      {
        type: 'divider',
      },
      {
        name: 'Settlement Account',
        onClick: () => {
          window.open(`?transactionHistoryCustomerId=${customer.customerId}`);
        },
      },
      {
        name: 'Journal',
        onClick: () => {
          setOpenedJournalModal(true);
        },
      },
      {
        name: 'Manual Deposit / Withdraw',
        onClick: () => {
          setOpenedDepositModal(true);
        },
      },
      {
        name: 'Message History',
        onClick: () => {
          setMessageHistoryModal(true);
        },
      },
      {
        name: 'Account History',
        onClick: () => {
          window.open(`?accountHistoryCustomerId=${customer.customerId}`);
        },
      },
      {
        name: 'Bet History',
        onClick: () => {
          window.open(
            `/adm/rm/bet-history?customerId=${customer.customerId}&entityType=customer`
          );
        },
      },
      {
        name: 'Action Log',
        onClick: () => {
          setActionLogModal(true);
        },
      },
    ],
    [openedContextMenu, customer]
  );

  const infiniteScrollHandler = useCallback(
    async e => {
      if (
        e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        currentPage < totalPages
      ) {
        setLoading(true);
        try {
          const {
            totalPages: newTotalPages,
          } = await searchCustomersWithFilters(currentPage + 1);
          setTotalPages(newTotalPages);
        } catch (err) {
          setTotalPages(2);
        }
        setLoading(false);
      }
    },
    [currentPage, searchCustomersWithFilters, totalPages]
  );

  return (
    <div className={classes.wrapper}>
      <Sidebar
        setSearchCustomersWithFilters={func =>
          setSearchCustomersWithFilters(() => func)
        }
        setTotalPages={setTotalPages}
        setCurrentPage={setCurrentPage}
      />
      <div className={classes.results}>
        <div onScroll={infiniteScrollHandler} className={classes.table}>
          <Table
            columns={columns}
            data={
              fetchedCustomer &&
              (tabParam === 'clientProfile' || tabParam === 'clientRisk')
                ? [fetchedCustomer]
                : customers
            }
            onRowClick={setCustomer}
            onRowContextMenu={setOpenedContextMenu}
            rowClassName={row =>
              customer && row.customerId === customer.customerId
                ? classes.highlightedRow
                : ''
            }
            pagination={false}
          />
          {loading ? <Spinner size={20} active /> : null}
        </div>
        <CustomerDetails customer={customer} />
      </div>
      {openedJournalModal ? (
        <Journal
          isActive={Boolean(openedJournalModal)}
          customer={customer}
          onClose={() => setOpenedJournalModal(null)}
        />
      ) : null}
      {openedDepositModal ? (
        <DepositWithdraw
          isActive={Boolean(openedDepositModal)}
          customer={customer}
          onClose={() => setOpenedDepositModal(null)}
        />
      ) : null}
      {messageHistoryModal ? (
        <MessageHistory
          isActive={Boolean(messageHistoryModal)}
          customer={customer}
          onClose={() => setMessageHistoryModal(null)}
        />
      ) : null}
      {openedClientDataModal ? (
        <ClientDataModal
          isActive={Boolean(openedClientDataModal)}
          openedTab={openedClientDataModal}
          tabs={tabs}
          customerId={customer.customerId}
          onClose={() => setOpenedClientDataModal(null)}
        />
      ) : null}
      {actionLogModal ? (
        <ActionLog
          isActive={Boolean(actionLogModal)}
          onClose={() => setActionLogModal(null)}
          customer={customer}
        />
      ) : null}
      {openedContextMenu ? (
        <ContextMenu
          onClose={() => setOpenedContextMenu(null)}
          position={openedContextMenu.contextLocation}
          rows={contextRows}
          customer={openedContextMenu}
        />
      ) : null}

      {new URLSearchParams(window.location.search).get(
        'accountHistoryCustomerId'
      ) ? (
        <AccountHistoryModal />
      ) : null}

      {new URLSearchParams(window.location.search).get(
        'transactionHistoryCustomerId'
      ) ? (
        <TransactionHistoryModal />
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  customers: getCustomerData(state).customerManagerReducer.filteredCustomers
    .content,
});

export default connect(mapStateToProps)(PlayerManager);
