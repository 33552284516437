import axiosInstance from '../axios-instance';
import { createRequestTypes, actionCreator } from '../index';
import { addNotificationMessage } from '../ui';

export const GET_TRANSLATIONS = createRequestTypes('GET_TRANSLATIONS'),
  FILTER_TRANSLATIONS = createRequestTypes('FILTER_TRANSLATIONS');

export const getFilteredTranslations = lang => async dispatch => {
  dispatch(actionCreator.request(FILTER_TRANSLATIONS));
  try {
    const res = await axiosInstance.get(`/content/public/translations/${lang}`);
    dispatch(
      actionCreator.success(FILTER_TRANSLATIONS, {
        response: res,
        lang: lang,
      })
    );
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    dispatch(actionCreator.failure(FILTER_TRANSLATIONS, ''));
  }
};

export const addTranslations = (translation, editMode) => async dispatch => {
  try {
    await axiosInstance.post('/content/admin/translation', translation);
    dispatch(
      addNotificationMessage(
        `Successfully ${editMode ? 'updated' : 'added'} translation`,
        'success',
        'Success'
      )
    );
    dispatch(getFilteredTranslations(translation.language));
    return true;
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
    return false;
  }
};

export const insertTranslations = (sameKey, translations) => async dispatch => {
  try {
    await axiosInstance.post('/content/admin/translation', translations);
    dispatch(getFilteredTranslations(translations.language));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};

export const deleteTranslations = translation => async dispatch => {
  try {
    await axiosInstance.delete('/content/admin/translation', {
      data: translation,
    });
    dispatch(getFilteredTranslations(translation.language));
  } catch (err) {
    dispatch(addNotificationMessage(err.message, 'error', 'Error'));
  }
};
