import React, { useCallback } from 'react';

const CompetitorsTableRow = React.memo(({ tableData, editCompetitor }) => {
  const renderRow = useCallback(
    (competitor, index) => (
      <tr key={competitor.id}>
        <td>{index + 1}.</td>
        <td className="capitalize">
          {competitor.names.en
            ? competitor.names.en.name
            : 'No available name on English'}
        </td>
        <td>
          <span
            className="btn btn-sm btn-outline-secondary btn-DEMO-edit-val"
            onClick={() => editCompetitor(competitor)}
          >
            <i className="fas fa-edit"></i>&nbsp; Edit
          </span>
        </td>
      </tr>
    ),

    [editCompetitor]
  );
  return tableData.map(renderRow);
});

export default CompetitorsTableRow;
