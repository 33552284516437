import {
  GET_REFERRAL_FOR_USER,
  ADD_REFERRAL_BETSLIP,
  REMOVE_REFERRAL_BETSLIP,
  RECEIVE_ODDS_CHANGE,
  REMOVE_SYSTEM_BETSLIP,
  GET_ACTIVE_OPERATORS,
  GET_REFERRAL_TYPE,
  GET_SYSTEM_REOFFER,
  GET_CUSTOMER_COUNTRY
} from '../../actions/riskManagement/ReferralConsole';
import { removeSystemFromBetslip } from '../../../helpers/betslipHelpers';

const initialState = {
  referral: {},
  changedOdds: {},
  betslips: [],
  countActive: 0,
  referralType: '',
  systemReoffer: '',
  customerCountries: []
};

export const referralConsoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REFERRAL_FOR_USER.SUCCESS:
      return {
        ...state,
        referral: action.payload
      };
    case ADD_REFERRAL_BETSLIP.SUCCESS:
      return {
        ...state,
        betslips: [...state.betslips, action.payload]
      };
    case REMOVE_REFERRAL_BETSLIP.SUCCESS:
      return {
        ...state,
        betslips: state.betslips.filter(
          betslip => betslip.betslipId !== action.payload
        )
      };
    case REMOVE_SYSTEM_BETSLIP.SUCCESS:
      return {
        ...state,
        betslips: removeSystemFromBetslip(state.betslips, action.payload)
      };
    case RECEIVE_ODDS_CHANGE.SUCCESS:
      return {
        ...state,
        changedOdds: {
          ...state.changedOdds,
          ...action.payload
        }
      };
    case GET_ACTIVE_OPERATORS.SUCCESS:
      return {
        ...state,
        countActive: action.payload
      };
    case GET_REFERRAL_TYPE.SUCCESS:
      return {
        ...state,
        referralType: action.payload
      }
    case GET_SYSTEM_REOFFER.SUCCESS:
      return {
        ...state,
        systemReoffer: action.payload
      }
    case GET_CUSTOMER_COUNTRY.SUCCESS:
      return {
        ...state,
        customerCountries: [...state.customerCountries, action.payload]
      }
    default:
      return state;
  }
};
