import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SockJS from 'sockjs-client';
import PropTypes from 'prop-types';
import webstomp from 'webstomp-client';
import * as ReferralConsoleActions from '../redux/actions/riskManagement/ReferralConsole';
import { getToken } from '../redux/actions';
import {
  setWsConnectionStatus,
  setWSFrameObj,
} from '../redux/actions/wsActions';
import { logout } from '../redux/actions/authentication';
import { setBetScrollerData } from '../redux/actions/riskManagement/betScroller';
import {
  transformMultipleBetslip,
  transformSingleBetslip,
} from './RiskManagement/BetScroller/BetScrollerUtils';
import { config } from '../backoffice.config';
import { transformBetslipToSystems } from './RiskManagement/Referral/ReferralUtils';

const SOCKET_READY = 'SOCKET_READY',
  BETSLIP_REFFERAL = 'BETSLIP_REFFERAL',
  BETSLIP_REFFERAL_WITH_REOFFER = 'BETSLIP_REFFERAL_WITH_REOFFER',
  BETSLIP_CANCELED = 'BETSLIP_CANCELED',
  BETSLIP_ACCEPTED = 'BETSLIP_ACCEPTED',
  BETSLIP_REOFFER = 'BETSLIP_REOFFER',
  BETSLIP_RISK_REJECTED = 'BETSLIP_RISK_REJECTED',
  BETSLIP_RISK_VALIDATED = 'BETSLIP_RISK_VALIDATED',
  BETSLIP_AUTOMATIC_ACCEPTED_REOFFER = 'BETSLIP_AUTOMATIC_ACCEPTED_REOFFER',
  BETSLIP_ACCEPTED_AFTER_REFERRAL = 'BETSLIP_ACCEPTED_AFTER_REFERRAL',
  BETSLIP_CANCELED_AFTER_REFERRAL = 'BETSLIP_CANCELED_AFTER_REFERRAL',
  BETSLIP_ACCEPTED_AFTER_REOFFER = 'BETSLIP_ACCEPTED_AFTER_REOFFER',
  ODDS_CHANGE = 'ODDS_CHANGE';

class WebSocketWrapper extends Component {
  state = {
    stompClient: null,
    frame: {},
  };

  componentDidMount() {
    this.connect();
  }

  componentWillUnmount() {
    this.disconnect();
  }

  connect = () => {
    let url = `${config.wsUrl}?access_token=${encodeURIComponent(getToken())}`,
      socket = new SockJS(url),
      stompClient = webstomp.over(socket);

    this.setState({ stompClient: stompClient });
    stompClient.connect(
      { Authorization: `Bearer ${getToken()}` },
      this._onConnected,
      this._onError
    );
  };

  disconnect = () => {
    if (this.state.stompClient !== null) {
      this.state.stompClient.disconnect();
    }
  };

  _onError = () => {
    this.props.logout(this.props.history);
  };

  _onConnected = frame => {
    this.setState({ frame: frame });
    this.props.setWSFrameObj(frame);
    let { stompClient } = this.state;
    stompClient.subscribe(
      `/topic/user.${frame.headers['session']}`,
      this.receiveWsData
    );

    stompClient.debug = function(str) {
      console.log('debug ' + str);
      if (str === '<<< PONG') {
        console.log('first PONG');
        stompClient.debug = () => {};
      }
    };
  };

  betScrollerData = data => {
    const payload = data ? JSON.parse(data.body).payload : {};
    const type = data ? JSON.parse(data.body).type : {};

    if (payload.bet) {
      const transformedPayload =
        payload.bet.length > 1
          ? transformMultipleBetslip(payload)
          : transformSingleBetslip(payload);

      this.props.setBetScrollerData({
        ...transformedPayload,
        type,
      });
    }
  };

  receiveWsData = data => {
    // here I'll receive the incoming data from the ws connection, I'll have to dispatch to the reducer everytime something comes in.
    const msgType = data && data.body ? JSON.parse(data.body).type : '';
    switch (msgType) {
      case SOCKET_READY:
        console.log('Message: ', JSON.parse(data.body).type);
        this.props.setWsConnectionStatus(true);
        break;

      case BETSLIP_RISK_VALIDATED:
      case BETSLIP_AUTOMATIC_ACCEPTED_REOFFER:
      case BETSLIP_ACCEPTED_AFTER_REFERRAL:
      case BETSLIP_CANCELED_AFTER_REFERRAL:
      case BETSLIP_ACCEPTED_AFTER_REOFFER:
        this.betScrollerData(data);
        break;

      case BETSLIP_REFFERAL:
        const referralPayload = data ? JSON.parse(data.body).payload : {};
        const transformedReferralPayload = transformBetslipToSystems(
          referralPayload
        );
        this.props.addReferralBetslip(transformedReferralPayload);
        this.props.getReferralType(data ? JSON.parse(data.body).type : '');
        this.betScrollerData(data);
        break;

      case BETSLIP_REFFERAL_WITH_REOFFER:
        const systemReofferPayload = data ? JSON.parse(data.body).payload : {};
        const preparedPayload = transformBetslipToSystems(
          systemReofferPayload.betslip,
          systemReofferPayload.reoffer
        );
        this.props.addReferralBetslip(preparedPayload);
        this.props.getReferralType(data ? JSON.parse(data.body).type : '');
        this.props.getSystemReofferInfo(systemReofferPayload.reoffer);
        this.betScrollerData(data);
        break;

      case ODDS_CHANGE:
        const oddsChangePayload = data ? JSON.parse(data.body).payload : {};
        this.props.handleOddsChange(oddsChangePayload);
        break;

      case BETSLIP_RISK_REJECTED:
        const betslipStatusPayload = data ? JSON.parse(data.body).payload : {};
        this.props.removeReferralBetslip(betslipStatusPayload.betslipId);
        this.betScrollerData(data);
        break;

      case BETSLIP_REOFFER:
      case BETSLIP_CANCELED:
      case BETSLIP_ACCEPTED:
        const systemStatusPayload = data ? JSON.parse(data.body).payload : {};
        this.props.removeSystemBetslip(systemStatusPayload);
        this.betScrollerData(data);
        break;

      default:
        break;
    }
  };

  render() {
    return <Fragment />;
  }
}

WebSocketWrapper.propTypes = {
  setWSFrameObj: PropTypes.func,
  setWsConnectionStatus: PropTypes.func,
};

const mapDispatchToProps = {
  logout,
  setWSFrameObj,
  setWsConnectionStatus,
  setBetScrollerData,
  addReferralBetslip: ReferralConsoleActions.addReferralBetslip,
  getReferralType: ReferralConsoleActions.getReferralType,
  removeReferralBetslip: ReferralConsoleActions.removeReferralBetslip,
  removeSystemBetslip: ReferralConsoleActions.removeSystemBetslip,
  handleOddsChange: ReferralConsoleActions.handleOddsChange,
  getSystemReofferInfo: ReferralConsoleActions.getSystemReofferInfo,
};

export default withRouter(connect(null, mapDispatchToProps)(WebSocketWrapper));
