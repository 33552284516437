import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../components/Table/Table';

export class BirDelayTable extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.data === nextProps.data) {
      return false;
    } else {
      return true;
    }
  }

  tableStyle =
    'sortable table table-hover table-striped efb-table efb-data-table';

  columns = [
    { name: 'Sport', value: 'sportType', type: 'string' },
    { name: 'Tournament', value: 'typeName', type: 'string' },
    { name: 'Category', value: 'typeName', type: 'string' },
    { name: 'Duration', value: 'duration', type: 'string' },
    { name: 'Actions', value: 'status', type: 'buttons' },
  ];

  filterDefaultData = () => {
    const { data } = this.props;
    const cloneData = [...data].filter(
      event =>
        event.sportId !== '*' &&
        event.categoryId !== '*' &&
        event.tournamentId !== '*'
    );
    return cloneData;
  };

  render() {
    return (
      <div>
        <Table
          data={this.filterDefaultData()}
          columns={this.columns}
          tableStyle={this.tableStyle}
          tableType="birDelay"
          editHandler={this.props.editHandler}
          showDeleteModal={this.props.showDeleteModal}
          sportViews={this.props.sportViews}
        />
      </div>
    );
  }
}

BirDelayTable.propTypes = {
  data: PropTypes.array,
  editHandler: PropTypes.func,
  showDeleteModal: PropTypes.func,
};

export default BirDelayTable;
