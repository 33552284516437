import React, { memo } from 'react';

export const Football = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Football ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.01 141"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <circle className={'Sides'} cx="70.51" cy="70.5" r="70.37" />
        <path d="M62.28.49A70.6,70.6,0,0,0,0,68.8H0C0,68.89,0,69,0,69A70.49,70.49,0,1,0,62.28.49ZM105.05,113.2c-3.27-.5-10.93-2-16.49-5.39-.54-9.94-1.61-27-1.79-29.75,3.77-3.15,13.86-11.7,17.46-15.92,5.71,1.5,16.87,4.64,22.65,7.11,2.18,6.67,2.07,14.75,1.74,19.53C123.22,101.25,109.47,110.48,105.05,113.2ZM57.49,65.71c-8,4.5-21.41,11.48-24.86,13.27-3.31-1.84-10.87-6.19-14.28-9.49-1.81-14.31,1.82-27.81,3-31.7,4.07-3.41,13.54-10.91,20.54-13,3.59,2,13.29,7.82,19.74,13.79C60.77,43.82,58,60.6,57.49,65.71ZM8.65,80.65a16.62,16.62,0,0,1-3.74-4.84A65.5,65.5,0,0,1,15.45,34.48l2.87,3.66a83.43,83.43,0,0,0-2.91,30.3C13.57,70.94,10.12,76,8.65,80.65ZM63.21,36.26c-6.53-6-15.76-11.49-19.61-13.69,1.54-2.95,7.58-10.36,7.58-10.36.19-.05,19.05-5.11,33.22-2.56,4.3,1.72,11.2,4.87,14.08,8.35-2.62,4.91-6.16,11.32-8.58,15.3C84.8,32.82,68.44,34,63.21,36.26ZM127.1,66.31c-6.66-2.72-17.61-5.7-21.83-6.81-1.62-3.25-10-20-13-24.7,2.83-4.61,6.67-11.69,8.58-15.26,3.74,1.37,15.87,6.78,15.87,6.78a94.52,94.52,0,0,1,15.21,31A97.68,97.68,0,0,1,127.1,66.31Zm7.31-10.56a95.65,95.65,0,0,0-9.95-22.94A65.32,65.32,0,0,1,134.71,56ZM82,5.68l-.22.76C67.57,4.92,51.2,9.3,50.45,9.5l0,.06-3.92-.37A65.83,65.83,0,0,1,82,5.68ZM8.4,84c.95,3.84,4.35,16,10.94,22.72.24,1.48.7,4,1.31,6.71a65.4,65.4,0,0,1-15-31.81A18.07,18.07,0,0,0,8.4,84Zm16.32,33.73a80.78,80.78,0,0,1-2.28-9.53c2.88.08,9.11,0,14-1.9,0,0-2.51-18.94-2.35-24.9,3.61-1.87,16.57-8.62,24.54-13.09C63,71.58,78.93,78,84,79.11c.27,4.29,1.18,18.88,1.69,28.08-2,1.93-7.94,6.93-20.38,11.83-9.64-1.5-29.49-15.17-29.68-15.3l.74,2.57-1.81.32c.76.51,18.53,12.55,29,14.92,1.22,2.66,3.7,8,5.16,10.76a31.31,31.31,0,0,1-12.49,2.47A66.07,66.07,0,0,1,24.71,117.73Zm41.61,18.46a23.34,23.34,0,0,0,5.74-2.51c4.68-.45,15.3-1.59,20.61-3a7.11,7.11,0,0,0,2.94.68,66,66,0,0,1-29.28,4.84Zm40.17-10.59c-5.41,2.45-8.68,3-10.57,2.92a130.09,130.09,0,0,0,9.43-12.24c2.47-1.42,17.15-10.2,24.34-23.32l2.61.18A66.25,66.25,0,0,1,106.49,125.61Z" />
      </g>
    </svg>
  );
};

export default memo(Football);
