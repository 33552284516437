import React, { useCallback } from 'react';
import { channels } from '../BetScrollerUtils';

const ByDevice = ({ betScroller, onChangeDevice}) => {
  const checkDevice = useCallback((e) => {
    const currentDevice = e.target.title;
    const filterChannels = [...betScroller.businessChannels];
    onChangeDevice({
      ...betScroller,
      businessChannels: filterChannels.includes(currentDevice)
        ? filterChannels.filter(channel => channel !== currentDevice)
        : [...betScroller.businessChannels, currentDevice]
    });
  },[betScroller, onChangeDevice]);

  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      {channels.map(channel => {
       return (
         <div
           key={channel}
           className={` position-relative m-1 ${
             betScroller.businessChannels.includes(channel)
               ? 'border-checked'
               : 'box-checked-class'
           }`}
         >
           <div
             className={`${channel.toLowerCase()}-img background-img position-relative`}
             title={channel}
             onClick={checkDevice}
           >
             <div className="title-filter-icon">{channel}</div>
           </div>
           {betScroller.businessChannels.includes(channel) ? (
             <i className="fas fa-check ml-1 icon-check" />
           ) : null}
         </div>
       );
      })}
    </div>
  );
};


export default ByDevice