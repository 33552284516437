export const gameObj = {
  active: false,
  demoAvailable: false,
  realAvailable: false,
  web: false,
  mobile: false,
  businessUnits: [],
  gameId: '',
  gameType: '',
  jackpot: false,
  modes: [],
  images: [],
  names: [],
  new: false,
  numberOfLines: 0,
  popular: false,
  provider: '',
  ranking: {
    en: { rank: 0 },
    bg: { rank: 0 },
    tr: { rank: 0 },
    gr: { rank: 0 },
    ro: { rank: 0 },
  },
  webImage: {
    big: '',
    small: '',
  },
  mobileImage: {
    big: '',
    small: '',
  },
};
