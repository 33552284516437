import axios from 'axios';
import { createRequestTypes, actionCreator } from './index';
import qs from 'qs';
import { config } from '../../backoffice.config';

export const AUTH = createRequestTypes('AUTH');
export const AUTH_LOGOUT = createRequestTypes('AUTH_LOGOUT');

export const authentication = (username, password, history) => {
  return dispatch => {
    dispatch(actionCreator.request(AUTH));
    const client_id = 'BACKEND-SERVICE';
    const client_secret = '123456';

    const data = {
      username,
      password,
      client_id,
      grant_type: 'password',
      scope: 'backend-service',
    };

    const params = {
      headers: {
        Authorization: `Basic ${btoa(`${client_id}:${client_secret}`)}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify(data),
      method: 'POST',
      url: config.local
        ? 'http://localhost:9500/oauth/token'
        : 'http://164.92.173.250:30282/oauth/token',
    };

    axios(params)
      .then(response => {
        generateLocalStorageItem(response.data, username);
        dispatch(actionCreator.success(AUTH, response.data));
        history.push('/adm');
      })
      .catch(err => {
        console.log(err.response);
        let mockError = {
          message: 'Bad credentials.',
        };
        dispatch(actionCreator.failure(AUTH, mockError));
      });
  };
};

export const checkAuthStatus = history => {
  return dispatch => {
    const token = localStorage.getItem('currentUser');
    if (token) {
      dispatch(actionCreator.success(AUTH, JSON.parse(token)));
    }
  };
};

export const logout = history => {
  return dispatch => {
    localStorage.removeItem('currentUser');
    dispatch(actionCreator.success(AUTH_LOGOUT));
    history.push('/');
  };
};

const generateLocalStorageItem = (authResponse, username) => {
  let userLocalStorage = Object.assign({}, authResponse);
  userLocalStorage.username = username;
  localStorage.setItem('currentUser', JSON.stringify(userLocalStorage));
};
