import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';

import * as StsActions from '../../../../redux/actions/cms/stsBuilder';
import StsTable from '../StsTable';
import InputField from '../../../../components/Form/InputField';
import LanguageSelector from '../../../../components/LanguageSelector/LanguageSelector';
import { useStateForInput, useUpdate } from '../../../../hooks';
import { getStsBuilderData } from '../../../../selectors';
import { handleSearch } from '../StsUtils';

const SeasonsTable = ({
  languages,
  seasons,
  editSeason,
  editingSeason,
  updateSeason
}) => {
  const [search, setSearch] = useStateForInput('');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [seasonsForTable, setSeasonsForTable] = useState(seasons);

  useUpdate(() => {
    setSeasonsForTable(handleSearch(seasons, search, selectedLanguage));
  }, [search]);

  useUpdate(() => {
    setSeasonsForTable(seasons);
  }, [seasons]);

  const updateStatus = useCallback(
    (season, status) => {
      updateSeason({
        ...season,
        status
      });
    },
    [updateSeason]
  );

  return (
    <>
      <LanguageSelector
        languages={languages}
        onChange={setSelectedLanguage}
        defaultLang={selectedLanguage}
      />
      <div className="efb-form my-3">
        <InputField
          id="search"
          name="search"
          placeholder="Search"
          type="text"
          className="form-control"
          value={search}
          onChange={setSearch}
        />
      </div>

      <StsTable
        data={seasonsForTable}
        updateStatus={updateStatus}
        editSts={editSeason}
        editingSts={editingSeason}
        ranking={false}
      />
    </>
  );
};

const mapStateToProps = state => ({
  seasons: getStsBuilderData(state).seasons
});

const mapDispatchToProps = {
  updateSeason: StsActions.updateSeason
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonsTable);
