import React from 'react';

export const Filter = (props) => {
    return (
        <span className='efb-icon'>
            <svg className={`efb-icon--${props.size} efb-filter`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
            <path d="M35.9,2c-0.2-0.4-0.6-0.6-1-0.6H1.1c-0.4,0-0.8,0.2-1,0.6C-0.1,2.4,0,2.9,0.2,3.2l13,15.8v14.5c0,0.4,0.2,0.7,0.5,0.9
	            c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.3,0,0.5-0.1l7.3-3.6c0.4-0.2,0.6-0.6,0.6-1l0-10.9l13-15.8C36,2.9,36.1,2.4,35.9,2z M20.8,17.9
	            c-0.2,0.2-0.2,0.4-0.2,0.7l0,10.6l-5.1,2.5V18.6c0-0.3-0.1-0.5-0.2-0.7L3.4,3.6h29.2L20.8,17.9z"/>
            </svg>
            {props.children}
        </span>
    )
}

export default Filter;