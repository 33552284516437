import React, { memo } from 'react';

export const VirtualSoccer = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  const icon = (
    <>
      <path
        d="M15.23,0C6.82,0,0,6.81,0,15.22c0.37,0.03,0.34,0.03,0.61,0c0-8.06,6.54-14.6,14.6-14.61c8.07,0,14.61,6.55,14.61,14.61
	c0,8.07-6.54,14.61-14.61,14.61c-0.03,0.25-0.03,0.25,0,0.61c8.41,0,15.22-6.82,15.23-15.23C30.45,6.82,23.63,0,15.23,0L15.23,0z
	 M14.19,2.58c-0.02,0-0.05,0-0.07,0c-0.71,0.04-1.08,0.34-1.28,0.66c-0.2,0.32-0.19,1.03-0.13,1.34c-0.39-0.11-0.2,0.58-0.18,0.72
	c0.03,0.14,0.23,0.42,0.37,0.48c0.01,0.88-0.02,1.66-0.12,1.76c-0.13,0.14-0.41,0.12-0.85,0.43c-0.19,0.14-0.72,0.11-1.16,0.41
	C10.32,8.68,9.77,9.44,9.5,9.73c-0.54,0.58-1.11,1.4-1.48,1.76c0.04,0.17,0.17,0.09,0.14,0.17c-0.44,0.24-1.05,0.82-1.33,1.01
	c-0.09,0.06-0.65,0.83-0.72,0.94c-0.07,0.12-1.37,0.92-1.4,1.14c-0.01,0.1-0.21,0.69-0.19,0.82s0.1,0.48,0.12,0.57
	c0.02,0.09,0.1,0.16,0.1,0.16s0.18,0.04,0.23,0c0.04-0.04-0.03-0.34,0.06-0.31c0.09,0.03,0.37-0.03,0.46-0.13
	c0.09-0.11,0.07-0.11,0.03-0.17s-0.42,0.03-0.45-0.04c-0.03-0.05-0.15-0.39-0.03-0.39c0.34,0,0.41-0.25,0.64-0.24
	c-0.07,0.17,0.01,0.12,0.04,0.16c0.02,0.04,0.13,0.08,0.2,0.06c0.08,0,0.18-0.29,0.36-0.46c0.07-0.06,0.11-0.31,0.21-0.44
	c0.1-0.14,1.31-0.45,1.76-0.94c0.2-0.23,0.72-0.66,0.84-0.72c0.04,0.04,0.06,0.04,0.09,0.07c0.26-0.33,1.97-1.73,2.26-1.56
	c0.28,0.23,0.44,0.73,0.47,1.04c0.06,0.66-0.28,1.9-0.7,2.44c-0.17,0.22-0.49,0.92-0.68,1.26c-0.04,0.08,0.05,0.12,0.01,0.16
	c-0.25,0.16-2.69,3.1-2.79,3.48s0.09,0.36,0.12,0.55c-0.16,0.37-0.2,0.48-0.24,0.53c-0.05,0.05-0.06,0-0.39,0.25
	c-0.68,0.53-0.99,2.61-1.36,2.88c-0.32,0.24-1.14,0.3-1.73,0.68S2.91,25.52,3.34,26c0.58,0.64,1.99-0.46,2.45-0.74
	s1.27-0.58,1.34-0.79c0.12-0.36,0.04-0.39-0.01-0.53c0.25-0.47,1.31-0.83,1.72-1.33c0.42-0.5,0.26-0.49,0.34-0.61
	c0.08-0.13,0.44-0.34,0.61-0.52c0.2,0.03,0.5,0.03,0.59-0.03c0.23-0.11,1.7-2.36,2.47-2.29c0.51,0.04,0.3,2.42,0.39,2.6
	c0.1,0.17,0.3,0.16,0.36,0.33c-0.03,0.31-0.03,0.41-0.08,0.66c-0.03,0.16-0.04,0.32-0.26,0.56c-0.03,0.03-0.11,0-0.14,0.04
	c-0.18,0.21-0.34,0.5-0.45,0.87c-0.55,1.85,0.17,1.42-0.66,4.53c-0.07,0.12-0.19,0.52-0.27,0.78c0.02,0.23-0.06,0.44-0.13,0.5
	c-0.12,0.12-0.13,0.83-0.08,0.91s0.15,0,0.15,0.25c0,0.12,0.12,0.34,0.24,0.42c0.12,0.08,0.26,0.06,0.26,0.06s0.18-0.22,0.27-0.19
	c0.12,0.04,0.56,0.58,0.72,0.54c0.12-0.03,0.18-0.17,0.23-0.23c0.02,0,0.1,0.03,0.19,0c0.07,0,0.12-0.12,0.2-0.13
	c0.08,0,0.13,0.09,0.21,0.09c0.07,0,0.15-0.04,0.18,0c0.07,0.06,0.04,0.17,0.17,0.16c0.12,0,0.08-0.12,0.13-0.21
	c0.12-0.2,0.04-0.24,0.04-0.33c-0.08-0.33-0.36-0.59-0.69-0.93c-0.26-0.27-0.55-0.35-0.65-0.54c-0.05-0.1-0.04-0.27,0-0.42
	c0.12-0.49,0.58-1.4,0.58-1.4s1.17-2.39,1.29-2.82c0.19-0.66,0.35-1.36,0.24-1.43c0.07-0.59,0.46-1.04,0.55-1.42
	c0.23-0.06,0.34-0.22,0.47-0.39c0.09-0.12,0.16-0.39,0.15-0.56c-0.01-0.12,0.08-2.01-0.08-3.26c0.19-0.23,0.07-0.8,0.13-0.93
	c0.09-0.17,0.15-0.96,0.26-2c0.07-0.69,0.21-1.44,0.29-2.16c0.14-1.3,0.21-2.45,0.29-2.52c0.06-0.05,0.74,0.6,1.62,0.91
	c0.02,0,0.17,0,0.29,0c0.39,0.19,0.8,0.25,0.94,0.34c0.66,0.3,2.15,0.15,3.41,0.45c0.32,0.25,0.99,0.17,0.99,0.17
	s0.13,0.3,0.33,0.25c0.05,0,0.12,0,0.17-0.04c0.03,0,0.02-0.06,0.05-0.08c0.07-0.04,0.2-0.09,0.26-0.14
	c0.03-0.03,0.04-0.1,0.07-0.13c0.04-0.04,0.11-0.03,0.15-0.07c0.04-0.05,0.01-0.1,0.04-0.16c0.01-0.05,0.12-0.08,0.12-0.13
	c0.01-0.06,0.07-0.21-0.11-0.28c-0.07-0.19-0.21-0.4-0.27-0.44c-0.09-0.06-0.48,0-0.77,0.04s-0.53,0.08-0.73,0.09
	c-0.69,0-2.31-0.81-2.96-0.85c-0.32-0.03-0.6-0.14-0.83-0.27c0-0.16-0.02-0.25-0.02-0.25s-0.72-0.64-1.42-1.28
	c-0.53-0.48-0.92-0.95-1.4-1.13C16.82,7.38,16.52,7.53,16,7.41c-0.6-0.14-0.72-0.13-1.03-0.25c-0.07-0.23-0.04-0.36-0.04-0.63
	C15.1,6.38,15.12,6,15.22,5.76c0.18-0.09,0.16-0.34,0.2-0.5c0.04-0.16,0.14-0.37,0.1-0.52c-0.04-0.16-0.17-0.06-0.17-0.06
	s0.15-0.85,0.05-1.15C15.32,3.23,14.94,2.59,14.19,2.58L14.19,2.58z M23.01,13.5c-2.86,0-5.18,2.32-5.18,5.18s2.32,5.18,5.18,5.18
	s5.18-2.32,5.18-5.18S25.87,13.5,23.01,13.5z M20.17,15.91h0.92v0.93h-0.92V15.91z M21.78,16.08h4.39v0.6h-4.39L21.78,16.08z
	 M20.17,17.39h0.92v0.92h-0.92V17.39z M21.78,17.56h4.39v0.6h-4.39L21.78,17.56z M20.17,18.87h0.92v0.92h-0.92V18.87z M21.78,19.04
	h4.39v0.6h-4.39L21.78,19.04z M20.17,20.35h0.92v0.93h-0.92V20.35z M21.78,20.52h4.39v0.6h-4.39L21.78,20.52z M8.2,27.59
	c-0.95,0-1.72,0.77-1.72,1.72c0,0.95,0.77,1.72,1.72,1.72s1.73-0.77,1.72-1.72C9.93,28.36,9.16,27.59,8.2,27.59z"
      />
    </>
  );

  return (
    <svg
      x="0px"
      y="0px"
	  className={
        `VirtualSoccer ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.45 32.01"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      {icon}
    </svg>
  );
};

export default memo(VirtualSoccer);
