import React from 'react';
import Table from '../../../../components/Table/Table';

const RiskCategoriesTable = props => {
  const tableStyle =
    'sortable table table-hover table-striped efb-table efb-data-table';

  const columns = [
    { name: 'Name', value: 'name', type: 'string', sortable: true },
    { name: 'Description', value: 'description', type: 'string' },
    {
      name: 'Max bets for referral',
      value: 'referralMandatoryCnt',
      type: 'number'
    },
    { name: 'Autopilot Limit', value: 'autopilotLimit', type: 'number' },
    { name: 'Skip Referral', value: 'skipReferral', type: 'boolean' },
    { name: 'Directly To Referral', value: 'autopilotAware', type: 'boolean' },
    { name: 'Actions', value: 'actions', type: 'buttons' }
  ];
  return (
    <Table
      tableType="riskCategoriesTable"
      tableStyle={tableStyle}
      columns={columns}
      {...props}
    />
  );
};

export default RiskCategoriesTable;
