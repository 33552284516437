import React, { memo } from 'react';

export const ElectronicSports = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `ElectronicSports ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141 95.48"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <path
          className={'Controller'}
          d="M140.36,75.21a16.13,16.13,0,0,0-.54-2.69,16.49,16.49,0,0,0-.54-2.69,16.86,16.86,0,0,0-.54-2.69,17.41,17.41,0,0,0-.54-2.69,17.64,17.64,0,0,0-.54-2.69,18.29,18.29,0,0,0-.54-2.69,18.49,18.49,0,0,0-.54-2.7,19.49,19.49,0,0,0-.54-2.69A19.55,19.55,0,0,0,135.5,51a20,20,0,0,0-.54-2.69,20.52,20.52,0,0,0-.54-2.69,20.9,20.9,0,0,0-.54-2.7,21.44,21.44,0,0,0-.5-2.54,22,22,0,0,0-.56-2.87,22.19,22.19,0,0,0-.54-2.69,22.57,22.57,0,0,0-.54-2.69,23.27,23.27,0,0,0-.54-2.69,23.38,23.38,0,0,0-.54-2.68,23.58,23.58,0,0,0-.54-2.7,24.11,24.11,0,0,0-.54-2.69A24.94,24.94,0,0,0,90.94,4.28H50.06A24.94,24.94,0,0,0,11.41,21.35,24.11,24.11,0,0,0,10.87,24a23.58,23.58,0,0,0-.54,2.7,23.38,23.38,0,0,0-.54,2.68,23.27,23.27,0,0,0-.54,2.69,22.57,22.57,0,0,0-.54,2.69,22.19,22.19,0,0,0-.54,2.69,22,22,0,0,0-.56,2.87,21.52,21.52,0,0,0-.5,2.54,21,21,0,0,0-.54,2.7A20.52,20.52,0,0,0,6,48.29,20,20,0,0,0,5.5,51,19.55,19.55,0,0,0,5,53.67a19.43,19.43,0,0,0-.54,2.69,18.44,18.44,0,0,0-.54,2.7,18.24,18.24,0,0,0-.54,2.69,17.59,17.59,0,0,0-.54,2.69,17.36,17.36,0,0,0-.54,2.69,16.86,16.86,0,0,0-.54,2.69,16.49,16.49,0,0,0-.54,2.69,16.17,16.17,0,0,0-.54,2.69,15.83,15.83,0,0,0,29.6,11,16.59,16.59,0,0,0,1.33-2.38,16.83,16.83,0,0,0,1.34-2.38,17.21,17.21,0,0,0,1.33-2.38,17.3,17.3,0,0,0,1.34-2.38,18,18,0,0,0,1.34-2.39,18.52,18.52,0,0,0,1.34-2.39,18.6,18.6,0,0,0,1.35-2.4A19.31,19.31,0,0,0,41,67.11a19.81,19.81,0,0,0,1.34-2.39l.1-.16a15.13,15.13,0,0,0,17.52-9.88H81.15a15.15,15.15,0,0,0,17.46,9.89l.09.14A19.81,19.81,0,0,0,100,67.11a19.36,19.36,0,0,0,1.35,2.4,18.55,18.55,0,0,0,1.35,2.4,18.52,18.52,0,0,0,1.34,2.39,18,18,0,0,0,1.34,2.39,17.47,17.47,0,0,0,1.34,2.38,17.16,17.16,0,0,0,1.33,2.38,16.83,16.83,0,0,0,1.34,2.38,16.48,16.48,0,0,0,1.33,2.38,15.83,15.83,0,0,0,29.6-11Z"
        />
        <circle className={'ButtonTop'} cx="107.66" cy="12.16" r="4.33" />
        <circle
          className={'ButtonBottom'}
          cx="107.66"
          cy="32.57"
          r="4.34"
        />
        <circle className={'ButtonLeft'} cx="97.46" cy="22.36" r="4.33" />
        <circle
          className={'BottomRight'}
          cx="117.87"
          cy="22.36"
          r="4.33"
        />
        <polygon points="47.15 18.3 37.57 18.3 37.57 8.72 29.45 8.72 29.45 18.3 19.87 18.3 19.87 26.42 29.45 26.42 29.45 36 37.57 36 37.57 26.42 47.15 26.42 47.15 18.3" />
        <circle cx="45.6" cy="49.73" r="10.97" />
        <circle cx="95.47" cy="49.73" r="10.97" />
        <path d="M64.52,28.88a2.19,2.19,0,0,1-2.19,2.19h-3a2.19,2.19,0,0,1-2.19-2.19h0A2.19,2.19,0,0,1,59.3,26.7h3a2.19,2.19,0,0,1,2.19,2.18Z" />
        <path d="M82.88,28.88a2.19,2.19,0,0,1-2.19,2.19h-3a2.19,2.19,0,0,1-2.19-2.19h0a2.18,2.18,0,0,1,2.19-2.18h3a2.19,2.19,0,0,1,2.19,2.18Z" />
      </g>
    </svg>
  );
};

export default memo(ElectronicSports);
