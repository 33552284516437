import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import TransactionBuilder from './TransactionBuilder';
import Messages from './MessageTemplates/Messages';
import SelfExclusionPerBu from './SelfExclusionPerBu/SelfExclusionPerBu';

const TransactionManagement = ({ match }) => {
  let module;
  switch (match.params.module) {
    case 'trc':
      module = () => (
        <Route
          render={() => <TransactionBuilder selectedTab="transactions" />}
        />
      );
      break;

    case 'ptrc':
      module = () => (
        <Route
          render={() => (
            <TransactionBuilder selectedTab="pendingTransactions" />
          )}
        />
      );
      break;

    case 'cr':
      module = () => (
        <Route
          render={() => <TransactionBuilder selectedTab="currencyRates" />}
        />
      );
      break;

    case 'pm':
      module = () => (
        <Route
          render={() => <TransactionBuilder selectedTab="paymentManager" />}
        />
      );
      break;

    case 'ex':
      module = () => (
        <Route render={() => <TransactionBuilder selectedTab="exceptions" />} />
      );
      break;

    case 'aw':
      module = () => (
        <Route
          render={() => <TransactionBuilder selectedTab="automaticWithdraw" />}
        />
      );
      break;

    case 'mt':
      module = () => <Route render={() => <Messages />} />;
      break;

    case 'self-ex-per-bu':
      module = () => <Route render={() => <SelfExclusionPerBu />} />;
      break;

    default:
      module = () => <Redirect to="/adm" />;
  }

  return <Route path={match.path} component={module} />;
};

export default TransactionManagement;
