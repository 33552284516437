import React, { Component, Fragment } from 'react';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Layout/Button';
import InputWithLabel from '../../../../components/Form/InputWithLabel/InputWithLabel';
import { createRole, editRole } from '../../../../redux/actions/rolesApi';
import { constructRoleObject, roleObj, transformRole } from './rolesUtils';
import PropTypes from 'prop-types';
import AppViewPrivilegesRoles from './AppViewPrivilegesRoles';
import CreateRolePrivileges from './CreateRolePrivileges';

class RolesModal extends Component {
  state = {
    newRole: JSON.parse(JSON.stringify(roleObj)),
    transformedRole: transformRole(this.props.role),
  };

  resetForm = () => {
    this.props.updateRoleForEdit({});
  };

  handleClickSubmit = () => {
    if (this.props.role.id) {
      let updatedRole = constructRoleObject(
        this.state.transformedRole,
        this.props.role
      );
      editRole(updatedRole)
        .then(response => {
          this.props.toggleRolesModal(false);
          this.props.updateRoleForEdit(updatedRole);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      console.log(this.state.newRole);
      let role = this.state.newRole.role;
      createRole(this.state.newRole)
        .then(response => {
          this.props.toggleRolesModal(false);
          let message = `Successfully created the role ${role}`;
          this.props.addNotificationMessage(
            message,
            'success',
            'Create role Success!'
          );
        })
        .catch(err => {});
    }
  };

  updateDescription = e => {
    const name = e.target.name,
      value = e.target.value;
    let roleChange = Object.assign({}, this.props.role);
    roleChange[name] = value;
    this.props.updateRoleForEdit(roleChange);
  };

  handleUserInputCreate = e => {
    const name = e.target.name,
      value = e.target.value;
    let roleChange = Object.assign({}, this.state.newRole);
    roleChange[name] = value;
    this.setState({ newRole: roleChange });
  };

  componentDidMount() {
    if (this.props.modalType === 'create') {
      this.props.fetchApplications();
    }
  }

  handleUpdateRole = role => this.setState({ transformedRole: role });
  updateNewRole = role => this.setState({ newRole: role });

  render() {
    let { isRolesModalOpen, toggleRolesModal, role } = this.props,
      { newRole, transformedRole } = this.state,
      roleForm;

    if (role.id) {
      roleForm = (
        <Fragment>
          <InputWithLabel
            label="Description:"
            type="text"
            onChange={event => this.updateDescription(event)}
            value={role.description}
            name="description"
          />
          <AppViewPrivilegesRoles
            transformedRole={transformedRole}
            updateRole={this.handleUpdateRole}
          />
        </Fragment>
      );
    } else {
      roleForm = (
        <Fragment>
          <InputWithLabel
            label="Role:"
            type="text"
            onChange={event => this.handleUserInputCreate(event)}
            value={newRole.role}
            name="name"
          />
          <InputWithLabel
            label="Description:"
            type="text"
            onChange={event => this.handleUserInputCreate(event)}
            value={newRole.description}
            name="description"
          />
          <CreateRolePrivileges
            newRole={newRole}
            updateNewRole={this.updateNewRole}
          />
        </Fragment>
      );
    }
    return (
      <Modal
        isActive={isRolesModalOpen}
        type="table"
        onClose={() => toggleRolesModal(false)}
        headerText={`Role ${role.name ? role.name : ''} Information`}
      >
        <div className="efb-modal__content table">{roleForm}</div>
        <footer className="efb-modal__footer">
          <Button onClick={this.resetForm} cssClass="efb-btn__link">
            Clear
          </Button>
          <Button onClick={this.handleClickSubmit} cssClass="efb-btn__main">
            Submit
          </Button>
        </footer>
      </Modal>
    );
  }
}

RolesModal.propTypes = {
  isRolesModalOpen: PropTypes.bool,
  toggleRolesModal: PropTypes.func,
  role: PropTypes.object,
  updateRoleForEdit: PropTypes.func,
  updateRoleCheckboxes: PropTypes.func,
  fetchApplications: PropTypes.func,
  modalType: PropTypes.string,
  addNotificationMessage: PropTypes.func,
};

export default RolesModal;
