import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Button from '../../../components/Layout/Button';
import { selectStyles } from '../../CMS/customReactSelectStyle';

const Selected = ({
  betSettings,
  closeSelect,
  addSportSettings,
  handleSelectedInputChange,
  sportTypesView,
  feedProviders,
  suspendOptions,
  selectOptions,
  handleSelectedSuspendDropdown,
}) => {
  const sportsSelectOptions = useMemo(() => {
    return sportTypesView.map(view => ({
      label: view.sportName,
      value: view.sportId,
      targetId: view.sportId,
    }));
  }, [sportTypesView]);

  const feedProviderOptions = useMemo(() => {
    return selectOptions(feedProviders, 'id');
  }, [feedProviders, selectOptions]);

  return (
    <div
      className="container-template container-template-sports"
      id="selected-container"
    >
      <form
        onSubmit={addSportSettings}
        className="efb-form efb-form-template-sports"
      >
        <fieldset>
          <div className="form-row">
            <div className="form-label-group col-lg-6">
              <Select
                name="targetId"
                required={true}
                styles={selectStyles}
                placeholder="Choose sport type"
                onChange={handleSelectedInputChange}
                options={sportsSelectOptions}
                // value={sportsSelectOptions.value}
                // defaultValue={sportsSelectOptions[0]}
              />
            </div>
            <div className="form-label-group col-lg-6">
              <Select
                name="id"
                styles={selectStyles}
                placeholder="Feed Provider"
                onChange={handleSelectedInputChange}
                options={feedProviderOptions}
                value={feedProviderOptions.value}
              />
            </div>
          </div>
        </fieldset>
        <fieldset>
          <Select
            id="suspend"
            name="suspend"
            styles={selectStyles}
            onChange={handleSelectedSuspendDropdown}
            options={suspendOptions}
            value={suspendOptions.find(
              opt =>
                opt.value.sort().join(',') ===
                betSettings.feedScopes.sort().join(',')
            )}
            // defaultValue={suspendOptions[0]}
          />
        </fieldset>
        <div className="form-group d-flex flex-row justify-content-end">
          <Button
            cssClass={'efb-btn__light'}
            children={'Cancel'}
            onClick={closeSelect}
          />
          <Button
            name="save"
            type="submit"
            cssClass={'efb-btn__main'}
            children={'Add setting'}
          />
        </div>
      </form>
    </div>
  );
};

Selected.propTypes = {
  betSettings: PropTypes.object,
  sportTypesView: PropTypes.array,
  closeSelect: PropTypes.func,
  handleSelectedInputChange: PropTypes.func,
  handleSelectedSuspendDropdown: PropTypes.func,
  onChangeSystemDropDown: PropTypes.func,
  feedProviders: PropTypes.array,
  addSportSettings: PropTypes.func,
};

export default Selected;
