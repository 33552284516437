import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

import * as EventsManagerActions from '../../../redux/actions/cms/eventsManager';
import classes from './Merger.module.scss';
import { selectStyles } from '../customReactSelectStyle';
import { useStateForInput, useStateForSelect, useUpdate } from '../../../hooks';
import { getEventsManagerData } from '../../../selectors';
import { Button } from '../../../components/Layout';
import InputWithLabel from '../../../components/Form/InputWithLabel/InputWithLabel';
import List from '../../../components/List/List';

const Merger = ({
  entityFormatter,
  entityType,
  sourceEntities,
  destinationEntities,
  merge,
  getSourceEntity,
  getDestinationEntity,
}) => {
  const sourceProducerOptions = useMemo(
    () => [{ label: 'Betgenius Live', value: '13' }],
    []
  );

  const destinationProducerOptions = useMemo(
    () => [{ label: 'Betradar Pre-Match', value: '3' }],
    []
  );

  const [source, setSource] = useState(null);
  const [destination, setDestination] = useState(null);
  const [sourceProducer, setSourceProducer] = useStateForSelect(
    sourceProducerOptions[0]
  );
  const [destinationProducer, setDestinationProducer] = useStateForSelect(
    destinationProducerOptions[0]
  );
  const [sourceSearch, setSourceSearch] = useStateForInput('');
  const [destinationSearch, setDestinationSearch] = useStateForInput('');
  const [sourceSportOptions, setSourceSportOptions] = useState([]);
  const [destinationSportOptions, setDestinationSportOptions] = useState([]);
  const [fullSourceSportOptions, setFullSourceSportOptions] = useState([]);
  const [
    fullDestinationSportOptions,
    setFullDestinationSportOptions,
  ] = useState([]);

  useUpdate(() => {
    Promise.all(
      sourceEntities.map(async entity => ({
        label: entityFormatter
          ? await entityFormatter(entity)
          : entity.names.en.name,
        value: entity,
      }))
    ).then(res => {
      setSourceSportOptions(res);
      setFullSourceSportOptions(res);
    });
  }, [sourceEntities]);

  useUpdate(() => {
    setSourceSportOptions(
      fullSourceSportOptions.filter(
        entity =>
          entity.label.toLowerCase().indexOf(sourceSearch.toLowerCase()) > -1
      )
    );
  }, [sourceSearch]);

  useUpdate(() => {
    Promise.all(
      destinationEntities.map(async entity => ({
        label: entityFormatter
          ? await entityFormatter(entity)
          : entity.names.en.name,
        value: entity,
      }))
    ).then(res => {
      setDestinationSportOptions(res);
      setFullDestinationSportOptions(res);
    });
  }, [destinationEntities]);

  useUpdate(() => {
    setDestinationSportOptions(
      fullDestinationSportOptions.filter(
        entity =>
          entity.label.toLowerCase().indexOf(destinationSearch.toLowerCase()) >
          -1
      )
    );
  }, [destinationSearch]);

  useUpdate(() => {
    if (
      sourceProducer &&
      destinationProducer &&
      sourceProducer.value &&
      destinationProducer.value
    ) {
      getSourceEntity(
        sourceProducer.value,
        destinationProducer.value,
        entityType
      );
      getDestinationEntity(
        destinationProducer.value,
        sourceProducer.value,
        entityType
      );
    }
  }, [sourceProducer, destinationProducer]);

  return (
    <>
      <div className={`efb-form ${classes.merger} mb-3`}>
        <Select
          styles={selectStyles}
          options={sourceProducerOptions}
          value={sourceProducer}
          onChange={setSourceProducer}
          className="w-100"
        />
        <h3 className="text-primary">-></h3>
        <Select
          styles={selectStyles}
          options={destinationProducerOptions}
          value={destinationProducer}
          onChange={setDestinationProducer}
          className="w-100"
        />
      </div>
      {sourceProducer && destinationProducer ? (
        <div className={`efb-form ${classes.merger}`}>
          <div className="w-100" style={{ height: 600 }}>
            <InputWithLabel
              placeholder={`Search ${sourceProducer.label} ${entityType}`}
              label={`Search ${sourceProducer.label} ${entityType}`}
              onChange={setSourceSearch}
              value={sourceSearch}
            />
            <List
              onChange={item =>
                item === source ? setSource(null) : setSource(item)
              }
              currentItem={source}
              items={sourceSportOptions}
            />
          </div>
          <h3 className="text-primary">-></h3>
          <div className="w-100" style={{ height: 600 }}>
            <InputWithLabel
              placeholder={`Search ${destinationProducer.label} ${entityType}`}
              label={`Search ${destinationProducer.label} ${entityType}`}
              onChange={setDestinationSearch}
              value={destinationSearch}
            />
            <List
              onChange={item =>
                item === destination
                  ? setDestination(null)
                  : setDestination(item)
              }
              currentItem={destination}
              items={destinationSportOptions}
            />
          </div>
        </div>
      ) : null}
      {source && destination ? (
        <div className="d-flex justify-content-center mt-5">
          <Button
            cssClass="efb-btn__main"
            onClick={() =>
              merge(
                source.value.id,
                destination.value.id,
                entityType,
                sourceProducer.value,
                destinationProducer.value
              )
            }
          >
            Merge
          </Button>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = state => ({
  sourceEntities: getEventsManagerData(state).sourceEntities,
  destinationEntities: getEventsManagerData(state).destinationEntities,
});

const mapDispatchToProps = {
  merge: EventsManagerActions.merge,
  getSourceEntity: EventsManagerActions.getSourceEntity,
  getDestinationEntity: EventsManagerActions.getDestinationEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(Merger);
