import React from 'react';

const IconNoFreeBet = ({
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 17 17"
      {...otherProps}
    >
      <path
        d="M3140.437-759.98v.612a2.563,2.563,0,0,0-1.475.513l1.219,1.219a1.207,1.207,0,0,1,.611-.147,2.289,2.289,0,0,1,1.6.833l1.182-1.151a4.989,4.989,0,0,0-.976-.808,3.739,3.739,0,0,0-1.007-.379v-.692Z"
        transform="translate(-3132.222 762.988)"
        fill="#707070"
      />
      <path
        d="M3135.771-716v-1.635a3.855,3.855,0,0,0,1.052-.359l-1.223-1.223a1.322,1.322,0,0,1-.447.076,3.11,3.11,0,0,1-2.167-1.194l-1.164,1.194a5.825,5.825,0,0,0,1.433,1.139,3.655,3.655,0,0,0,1.365.4v1.6Z"
        transform="translate(-3126.405 730.687)"
        fill="#707070"
      />
      <path
        d="M3111.086-744.252a7.026,7.026,0,0,1-7.018-7.018,6.975,6.975,0,0,1,1.051-3.688l-1.072-1.072a8.449,8.449,0,0,0-1.462,4.76,8.509,8.509,0,0,0,8.5,8.5,8.451,8.451,0,0,0,4.76-1.462l-1.072-1.072A6.975,6.975,0,0,1,3111.086-744.252Z"
        transform="translate(-3102.586 759.77)"
        fill="#707070"
      />
      <path
        d="M3128.408-774.731a7.026,7.026,0,0,1,7.018,7.018,6.973,6.973,0,0,1-.928,3.482l1.078,1.078a8.444,8.444,0,0,0,1.333-4.56,8.51,8.51,0,0,0-8.5-8.5,8.447,8.447,0,0,0-4.56,1.333l1.078,1.079A6.971,6.971,0,0,1,3128.408-774.731Z"
        transform="translate(-3119.908 776.213)"
        fill="#707070"
      />
      <path
        d="M3122.754-758.065l-2.488-2.488-3.03-3.03-1.13-1.13-1.305-1.305-1.843-1.843-1.058-1.058-.668-.668-1.132,1.133.648.648,1.055,1.055,6.833,6.833,1.2,1.2,1.81,1.81,1.055,1.055.648.648,1.133-1.133-.668-.668Z"
        transform="translate(-3108.708 770.815)"
        fill="#707070"
      />
    </svg>
  );
};

export default IconNoFreeBet;