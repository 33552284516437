import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
// import EventsManager from './EventsManager/EventsManager';
import Images from './Images/Images';
import MarketCategories from './MarketCategories/MarketCategories';
import Translations from './Translations/Translations';
import EventBuilder from './EventBuilder/EventBuilder';
import StsBuilder from './STS Builder/StsBuilder';
import CompetitorBuilder from './CompetitorBuilder/CompetitorBuilder';
import TopManager from './TopManager/TopManager';
import StaticContent from './StaticContent/StaticContent';
import CasinoManager from './CasinoManager/CasinoManager';
import EventsSearch from './EventsSearch/EventsSearch';
import CCSSTVMergerModule from './CCSSTVMerger/CCSSTVMergerModule';

class ContentManagement extends Component {
  render() {
    let module;
    switch (this.props.match.params.module) {
      case 'tp':
        module = () => <Route render={() => <TopManager />} />;
        break;

      // case 'ev':
      //   module = () => <Route render={() => <EventsManager />} />;
      //   break;

      case 'img':
        module = () => <Route render={() => <Images />} />;
        break;

      case 'tr':
        module = () => <Route render={() => <Translations />} />;
        break;

      case 'mc':
        module = () => <Route render={() => <MarketCategories />} />;
        break;

      case 'eb':
        module = () => <Route render={() => <EventBuilder />} />;
        break;

      case 'sts':
        module = () => <Route render={() => <StsBuilder />} />;
        break;

      case 'cb':
        module = () => <Route render={() => <CompetitorBuilder />} />;
        break;

      case 'sc':
        module = () => <Route render={() => <StaticContent />} />;
        break;

      case 'cam':
        module = () => <Route render={() => <CasinoManager />} />;
        break;

      case 'es':
        module = () => <Route render={() => <EventsSearch />} />;
        break;

      case 'em':
        module = () => (
          <Route render={() => <CCSSTVMergerModule {...this.props} />} />
        );
        break;

      default:
        module = () => <Redirect to="/adm" />;
    }

    return <Route path={`${this.props.match.path}`} component={module} />;
  }
}

export default ContentManagement;
