import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import {
  useMount,
  useUpdate,
  useStateForSwitch,
  useStateForInput,
  useFetch,
} from '../../../hooks';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import BetslipModalCalculations from './Form/BetslipModalCalculations';
import BetslipModalActionBtns from './Form/BetslipModalActionBtns';
import { minPrice } from './ReferralUtils';

const ModalBetslipForm = ({
  currentSelection,
  betslip,
  changedOdds,
  addNotificationMessage,
  priceChange,
  reofferBet,
  stakeChanges,
  updateSelectionOdd,
  setBetslipStatus,
  currentSystem,
  setStakeChanges,
  setBetStatus,
  referralType,
  isRegularBetslip,
  closeModal,
  currentTab,
  updateAllSelectionsOdds,
}) => {
  const [oddChanges, setOddChanges] = useState({});
  const [priceChanges, setPriceChanges] = useState([]);
  const [initialPriceChange, setInititalPriceChange] = useState(true);
  const [odds, setOdds] = useState('');
  const [minutes, setMinutes] = useStateForSwitch(false);
  const [predefinedAmount, setPredefinedAmount] = useStateForInput(0);
  const [stake, setStake] = useState(
    currentSystem ? currentSystem.systemBet / 100 : 0
  );
  const [isStakeChanged, setIsStakeChanged] = useState(false);
  const [outcomeId, setOutcomeId] = useState('');
  const [cutAll, setCutAll] = useState(0);
  const [reachedPriceMin, setReachedPriceMin] = useState(false);

  const { data: market } = useFetch(
    `/market/view/market/admin/${currentSelection.marketId}`
  );
  const [potWin, setPotWin] = useState('');

  useMount(() => {
    setOddChanges({});
    setOdds('');
  });

  useUpdate(() => {
    if (oddChanges && Object.keys(oddChanges).length > 0) {
      if (Object.values(oddChanges).every(x => x === minPrice)) {
        setReachedPriceMin(true);
      }
    }
  }, [oddChanges]);

  useUpdate(() => {
    const summedArr = betslip.systemBets.reduce(
      (acc, val) => acc + val.systemTakeout,
      0
    );
    setPotWin(summedArr);
  }, [betslip]);

  useUpdate(() => {
    const cutAllValue = typeof cutAll === 'string' ? Number(cutAll) : cutAll;
    if (Number(cutAll).toFixed(2) !== '0.00') {
      let oddsObj = {};
      let priceChangesArr = [];
      [...betslip.selections].forEach(selection => {
        oddsObj = {
          ...oddsObj,
          [selection.selectionId]: Math.max(
            Number(cutAllValue + selection.outcomeOdds).toFixed(2),
            minPrice
          ),
        };
        priceChangesArr = [
          ...priceChangesArr,
          {
            feedProviderId: selection.outcomeBetInfoFeedProviderId,
            duration: buildIsoDuration(),
            marketId: selection.marketId,
            newPrice:
              Number(selection.currentOdd).toFixed(2) > minPrice
                ? Number(cutAll).toFixed(2)
                : Number(minPrice - selection.outcomeOdds).toFixed(2),
            outcomeId: outcomeId,
          },
        ];
      });
      setPriceChanges(priceChangesArr);
      setOddChanges(oddsObj);
    } else {
      setOddChanges({});
    }
    updateAllSelectionsOdds(Number(cutAllValue));
  }, [cutAll, predefinedAmount]);

  useUpdate(() => {
    if (market) {
      const outcomeInfo = market.outcomeBetInfos.find(
        x => x.names.bg.name === currentSelection.outcomeOddsName
      );
      setOutcomeId(outcomeInfo ? outcomeInfo.outcomeId : '');
    }
  }, [market]);

  useUpdate(() => {
    setIsStakeChanged(false);
  }, [currentSystem]);

  useUpdate(() => {
    setInititalPriceChange(true);
    setOdds(currentSelection.currentOdd || currentSelection.outcomeOdds);
    if (!isStakeChanged && currentSystem) {
      setStake(currentSystem.bets[0].bet / 100);
      if (currentSystem && referralType === 'BETSLIP_REFFERAL_WITH_REOFFER') {
        if (stakeChanges.length === 0) {
          setStakeChanges([
            {
              bet: currentSystem.systemBet,
              systemType: currentSystem.type,
              betId:
                currentSystem.type === 'SINGLE'
                  ? currentSystem.bets[0].betId
                  : undefined,
            },
          ]);
        }
      }
      if (!currentSystem) {
        setStake(0);
      }
    }
  }, [currentSelection, currentSystem, isStakeChanged]);

  const buildIsoDuration = useCallback(
    () => `PT${predefinedAmount}${minutes ? 'M' : 'S'}`,
    [minutes, predefinedAmount]
  );

  useUpdate(() => {
    if (!initialPriceChange) {
      if (currentSelection) {
        const priceChangeObject = priceChanges.find(
          x => x.marketId === currentSelection.marketId
        );
        if (priceChangeObject) {
          const priceChangesClone = [...priceChanges];
          const indexOfExistingObject = priceChanges.findIndex(
            x => x.marketId === currentSelection.marketId
          );
          priceChangesClone[indexOfExistingObject].newPrice = Number(
            odds - currentSelection.outcomeOdds
          ).toFixed(2);
          priceChangesClone[
            indexOfExistingObject
          ].duration = buildIsoDuration();
          setPriceChanges(priceChangesClone);
        } else {
          setPriceChanges([
            ...priceChanges,
            {
              feedProviderId: currentSelection.outcomeBetInfoFeedProviderId,
              duration: buildIsoDuration(),
              marketId: currentSelection.marketId,
              newPrice: Number(odds - currentSelection.outcomeOdds).toFixed(2),
              outcomeId: outcomeId,
            },
          ]);
        }
      }
    }
  }, [odds, buildIsoDuration, predefinedAmount, minutes]);

  const initialTime = useMemo(() => {
    const timeoutSecs = moment
      .duration(moment(betslip.expireAt) - moment(betslip.createdAt), 'seconds')
      .asSeconds();
    const prolongedSecs = moment
      .duration(moment() - moment(betslip.createdAt), 'seconds')
      .asSeconds();

    return prolongedSecs < timeoutSecs ? timeoutSecs - prolongedSecs : 0;
  }, [betslip.createdAt, betslip.expireAt]);

  const onOddsChange = useCallback(
    e => {
      setInititalPriceChange(false);
      if (
        e.target.value >
        (changedOdds[currentSelection.selectionId] ||
          currentSelection.outcomeOdds)
      ) {
        setOdds(
          changedOdds[currentSelection.selectionId] ||
            currentSelection.outcomeOdds
        );
      } else if (Number(e.target.value) === 0) {
        setOdds('');
      } else if (e.target.value.length > 6) {
        return;
      } else {
        setOdds(Number(e.target.value));
      }
      const oddChangesClone = { ...oddChanges };
      if (
        currentSelection.currentOdd &&
        Number(e.target.value) === currentSelection.outcomeOdds
      ) {
        delete oddChangesClone[currentSelection.selectionId];
        setOddChanges(oddChangesClone);
      } else {
        setOddChanges({
          ...oddChanges,
          [currentSelection.selectionId]: Number(e.target.value),
        });
      }
      updateSelectionOdd(e.target.value, currentSelection, currentSystem);
    },
    [
      changedOdds,
      currentSelection,
      currentSystem,
      oddChanges,
      updateSelectionOdd,
    ]
  );

  const sendReoffer = useCallback(() => {
    if (Object.values(oddChanges).some(odd => Number(odd) < minPrice)) {
      addNotificationMessage(
        `Odd can't be less than ${minPrice}`,
        'warning',
        'Warning!'
      );
      return;
    }

    if (Object.keys(oddChanges).length) {
      const changedPrice = priceChanges.reduce((acc, val) => {
        if (Number(val.newPrice).toFixed(0) === '0') {
          return false;
        } else {
          return true;
        }
      }, []);
      const priceChangesClone = [...priceChanges];
      priceChangesClone.forEach((price, index) => {
        if (price.newPrice === '0.00') priceChangesClone.splice(index, 1);
      });
      if (changedPrice) priceChange(priceChangesClone);
    }

    reofferBet(betslip.betslipId, {
      betSystems: stakeChanges,
      selectionOdds: oddChanges,
    });
  }, [
    oddChanges,
    reofferBet,
    betslip.betslipId,
    stakeChanges,
    addNotificationMessage,
    priceChanges,
    priceChange,
  ]);

  const findSystem = useCallback(
    (type, betId) => {
      if (currentSystem) {
        return (
          currentSystem.type === type &&
          (currentSystem.type === 'SINGLE'
            ? currentSystem.bets[0].betId === betId
            : true)
        );
      }
    },
    [currentSystem]
  );

  const findChangedStake = useMemo(() => {
    let foundedStake;
    if (currentSystem) {
      const system = currentSystem.type;
      if (stakeChanges.length > 0) {
        if (system !== 'SINGLE') {
          if (stakeChanges.find(x => x.systemType === currentSystem.type)) {
            foundedStake = Number(
              stakeChanges.find(x => x.systemType === currentSystem.type).bet /
                100
            );
          }
        } else {
          if (stakeChanges.find(x => x.betId === currentSystem.bets[0].betId)) {
            foundedStake = Number(
              stakeChanges.find(x => x.betId === currentSystem.bets[0].betId)
                .bet / 100
            );
          }
        }
      }
    } else {
      foundedStake = Number(0).toFixed(2);
    }
    return foundedStake ? foundedStake : stake;
  }, [currentSystem, stake, stakeChanges]);

  const getOddsInputValue = useCallback(() => {
    const selection = betslip.selections.find(
      x => x.selectionId === currentSelection.selectionId
    );
    if (selection) {
      if (odds !== selection.currentOdd) {
        return Number(selection.currentOdd).toFixed(2);
      } else {
        return Number(odds).toFixed(2);
      }
    }
  }, [betslip.selections, currentSelection.selectionId, odds]);

  const calculateTotalPotWinAfterChange = useCallback(
    systemStake => {
      const betslipClone = { ...betslip };
      const systemBets = [...betslipClone.systemBets];
      const changedSystemBet = systemBets.find(x => x.id === currentSystem.id);
      if (changedSystemBet) {
        changedSystemBet['changedStake'] =
          systemStake * currentSystem.systemOdds * 100;
        const total = systemBets.reduce((acc, val) => {
          return acc + (val.changedStake || val.systemTakeout);
        }, 0);
        setPotWin(total);
      }
    },
    [betslip, currentSystem]
  );

  const calculatedMultiBetPotWin = useMemo(() => {
    if (currentSystem) {
      return findChangedStake * currentSystem.systemOdds;
    }
  }, [currentSystem, findChangedStake]);

  const changeStake = useCallback(
    e => {
      let foundChange;
      setIsStakeChanged(true);
      let newStake;
      if (currentSystem.type === 'DOUBLES') {
        newStake =
          e.target.value > currentSystem.systemBet / 100
            ? currentSystem.systemBet / 100
            : e.target.value;
      } else {
        newStake =
          e.target.value > currentSystem.bets[0].bet / 100
            ? currentSystem.bets[0].bet / 100
            : e.target.value;
      }

      if (
        (foundChange = stakeChanges.find(change =>
          findSystem(change.systemType, change.betId)
        ))
      ) {
        setStakeChanges(
          stakeChanges
            .map(change => {
              if (foundChange === change) {
                if (Number(newStake) === currentSystem.bets[0].bet / 100) {
                  return undefined;
                }
                return { ...change, bet: Number(newStake * 100).toFixed(0) };
              }
              return change;
            })
            .filter(a => a)
        );
      } else if (newStake !== currentSystem.bets[0].bet / 100) {
        setStakeChanges([
          ...stakeChanges,
          {
            bet: Number(newStake * 100).toFixed(0),
            systemType: currentSystem.type,
            betId:
              currentSystem.type === 'SINGLE'
                ? currentSystem.bets[0].betId
                : undefined,
          },
        ]);
      }
      calculateTotalPotWinAfterChange(newStake);
      setStake(newStake ? Number(newStake) : '');
    },
    [
      currentSystem,
      stakeChanges,
      calculateTotalPotWinAfterChange,
      findSystem,
      setStakeChanges,
    ]
  );

  return (
    <div className="betslip-modal-info-wrapper">
      <div className="row">
        <div className="col-md-3">
          <div className="modal-timer">
            <CountdownCircleTimer
              isPlaying
              duration={initialTime / 1000}
              trailColor={'#fff'}
              size={160}
              strokeWidth="16"
              strokeLinecap="stroke"
              colors={[['#00c06a'], ['#ff0000', 0.1]]}
              onComplete={() => {
                closeModal();
              }}
            >
              {({ remainingTime }) => <span>{remainingTime}</span>}
            </CountdownCircleTimer>
          </div>
        </div>
        <BetslipModalCalculations
          currentSystem={currentSystem}
          currentSelection={currentSelection}
          isRegularBetslip={isRegularBetslip}
          referralType={referralType}
          findChangedStake={findChangedStake}
          changeStake={changeStake}
          changedOdds={changedOdds}
          getOddsInputValue={getOddsInputValue}
          onOddsChange={onOddsChange}
          odds={odds}
          calculatedMultiBetPotWin={calculatedMultiBetPotWin}
          setPredefinedAmount={setPredefinedAmount}
          predefinedAmount={predefinedAmount}
          setCutAll={setCutAll}
          cutAll={cutAll}
          currentTab={currentTab}
          minutes={minutes}
          setMinutes={setMinutes}
          reachedPriceMin={reachedPriceMin}
          setReachedPriceMin={setReachedPriceMin}
        />
      </div>
      <BetslipModalActionBtns
        isRegularBetslip={isRegularBetslip}
        potWin={potWin}
        currentSystem={currentSystem}
        setBetStatus={setBetStatus}
        referralType={referralType}
        stakeChanges={stakeChanges}
        oddChanges={oddChanges}
        setBetslipStatus={setBetslipStatus}
        betslip={betslip}
        sendReoffer={sendReoffer}
      />
    </div>
  );
};

export default ModalBetslipForm;
