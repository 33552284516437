import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import * as StsActions from '../../../../redux/actions/cms/stsBuilder';
import * as CompetitorActions from '../../../../redux/actions/cms/competitorBuilder';
import StsCreateForm from '../StsCreateForm';
import { useMount, useStateForSelect, useUpdate } from '../../../../hooks';
import { getCMSData, getStsBuilderData } from '../../../../selectors';
import { selectStyles } from '../../customReactSelectStyle';
import * as UiActions from '../../../../redux/actions/ui';
import { mapSts } from '../StsUtils';

const CreateCategory = ({
  languages,
  countryCodes,
  editingCategory,
  setSportId,
  clearEditingCategory,
  sports,
  getAllSports,
  getSportCategories,
  getCountryCodes,
  createCategory,
  updateCategory,
  addNotificationMessage
}) => {
  const countryCodesOptions = useMemo(() => countryCodes.map(country => ({
    value: country.countryCode,
    label: country.countryCode + (Object.keys(country.names).length > 0 ? ` - ${country.names.en.name}` : '')
  })), [countryCodes]);

  const [selectedSport, setSelectedSport] = useStateForSelect('');
  const [countryCode, setCountryCode] = useStateForSelect('');

  const sportOptions = useMemo(() => sports.map(mapSts), [sports]);

  useUpdate(() => {
    getSportCategories(selectedSport.value);
    clearEditingCategory();
  }, [selectedSport.value]);

  useUpdate(() => {
    if (editingCategory) {
      setCountryCode(countryCodesOptions.find(option => option.value === editingCategory.country.countryCode));
    }
  }, [editingCategory]);

  useMount(() => {
    getAllSports();
    getCountryCodes();
  });

  const submitCategory = useCallback(
    (names, status) => {
      if (!selectedSport.value || !names.en.name) {
        addNotificationMessage(
          'Sport and name in English are required!',
          'warning',
          'Warning!'
        );
        return;
      }

      const category = {
        names, status, country: { countryCode: countryCode.value }
      };
      if (editingCategory) {
        updateCategory(
          { ...editingCategory, ...category },
          selectedSport.value
        );
      } else {
        createCategory({
          ...category, feedProducers: [
            { feedProviderId: `efbet:category:${Date.now()}`, producerId: '14' }
          ]
        }, selectedSport.value);
      }
      clearEditingCategory();
      setCountryCode('');
    },
    [
      addNotificationMessage,
      clearEditingCategory,
      countryCode,
      createCategory,
      editingCategory,
      selectedSport.value,
      updateCategory,
      setCountryCode
    ]
  );

  return (
    <div className="efb-form mt-3">
      <Select
        placeholder="Select Sport"
        value={selectedSport}
        onChange={data => {
          setSelectedSport(data);
          setSportId(data.value);
        }}
        styles={selectStyles}
        options={sportOptions}
        className="mb-3"
      />

      <hr />

      <div className="form-label-group">
        <Select
          styles={selectStyles}
          value={countryCode}
          onChange={setCountryCode}
          options={countryCodesOptions}
          placeholder="Select country"
          isClearable
        />
      </div>

      <StsCreateForm
        sts="category"
        languages={languages}
        editingSts={editingCategory}
        onClear={() => {
          clearEditingCategory();
          setCountryCode('');
        }}
        onSubmit={submitCategory}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  sports: getStsBuilderData(state).sports,
  countryCodes: getCMSData(state).competitorReducer.countryCodes
});

const mapDispatchToProps = {
  getAllSports: StsActions.getAllSports,
  getSportCategories: StsActions.getSportCategories,
  createCategory: StsActions.createCategory,
  updateCategory: StsActions.updateCategory,
  getCountryCodes: CompetitorActions.getCountryCodes,
  addNotificationMessage: UiActions.addNotificationMessage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCategory);
