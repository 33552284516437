import React, { Fragment } from 'react';
import Table from '../../../components/Table/Table';
import EventSelectionInfo from './EventSelectionInfo';
import PropTypes from 'prop-types';

const columns = [
	{ name: 'Event', value: 'lang', type: 'string', sortable: true },
	{ name: 'Match status', value: 'lang', type: 'string' },
	{ name: 'Start', value: 'start', type: 'string' },
	{ name: 'Action', value: 'action', type: 'string' }
];

const EventsList = ({
	events,
	sportName,
	countryName,
	tournamentName,
	getEventMarkets,
	sportIcon,
	clearEventMarkets,
	filterDate
}) => {
	return (
		<Fragment>
			<EventSelectionInfo
				sportName={sportName}
				countryName={countryName}
				tournamentName={tournamentName}
				filterDate={filterDate}
			/>
			<Table
				columns={columns}
				data={events}
				getEventMarkets={getEventMarkets}
				sportIcon={sportIcon}
				clearEventMarkets={clearEventMarkets}
				tableType="eventsSearchTable"
				tableStyle="sortable table table-hover table-striped efb-table events-search-table"
			/>
		</Fragment>
	);
};

EventsList.propTypes = {
	events: PropTypes.array,
	sportName: PropTypes.string,
	countryName: PropTypes.string,
	tournamentName: PropTypes.string,
	getEventMarkets: PropTypes.func,
	clearEventMarkets: PropTypes.func
};

export default EventsList;
