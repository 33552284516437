import React, { useMemo, useState, useCallback } from 'react';

import classes from './FiltersMenu.module.scss';
import SelectFilter from './SelectFilter/SelectFilter';
import RadioFilter from './RadioFilter/RadioFilter';
import RadioFilters from './RadioFilters/RadioFilters';
import CheckboxFilter from './CheckboxFilter/CheckboxFilter';
import DatesFilter from './DatesFilter/DatesFilter';
import InputFilter from './InputFilter/InputFilter';
import InputsFilter from './InputsFilter/InputsFilter';
import SearchFilter from './SearchFilter/SearchFilter';
import Button from '../../Layout/Button';

import { debounce } from '../../../utils/helpers';
import { useUpdate } from '../../../hooks';

const FiltersMenu = ({
  config,
  opened,
  close,
  onFiltersChange: pushFiltersChange,
}) => {
  const [filters, setFilters] = useState({
    ...config.reduce((acc, filter) => {
      if (!filter.default) return acc;
      return {
        ...acc,
        [filter.name]: filter.default.value || filter.default,
      };
    }, {}),
  });

  const onFilterChange = useCallback(
    debounce(({ name, value, valueType, replace }) => {
      setFilters({
        ...filters,
        [name]:
          filters[name] && Array.isArray(filters[name])
            ? replace
              ? value
              : filters[name].indexOf(value) > -1
              ? [...filters[name].filter(val => val !== value)]
              : [...filters[name], value]
            : valueType === 'array' && !Array.isArray(value)
            ? [value]
            : value,
      });
    }, 400),
    [filters]
  );

  useUpdate(() => {
    pushFiltersChange(filters);
  }, [filters]);

  const configTypeMapping = useMemo(
    () => ({
      select: SelectFilter,
      radio: RadioFilter,
      radios: RadioFilters,
      checkbox: CheckboxFilter,
      dates: DatesFilter,
      input: InputFilter,
      inputs: InputsFilter,
      search: SearchFilter,
      custom: null,
    }),
    []
  );

  return (
    <div
      className={classes.wrapper}
      style={{
        marginLeft: opened ? 0 : -300,
      }}
    >
      <div className={classes.menu}>
        {config.map((filter, i) => {
          if (!configTypeMapping[filter.type] && !filter.component) return null;

          const Component = filter.component || configTypeMapping[filter.type];

          return (
            <Component key={i} onFilterChange={onFilterChange} {...filter} />
          );
        })}

        <div>
          <Button cssClass={classes.backButton} onClick={close}>
            {'<'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FiltersMenu;
