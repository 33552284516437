import React, { Fragment, useCallback, useMemo } from 'react';
import Select from 'react-select';
import { selectStyles } from '../../../customReactSelectStyle';
import Spinner from '../../../../../components/Layout/Spinner/Spinner';
import PropTypes from 'prop-types';
import { languages } from '../../../../../config/constants';
import { useUpdate, useStateForSelect } from '../../../../../hooks';

const CompetitorSelector = ({
  getCompetitors,
  loaded,
  homeCompetitors,
  awayCompetitors,
  filters,
  updateFilter,
}) => {
  const [homeCompetitor, setHomeCompetitor] = useStateForSelect('');
  const [awayCompetitor, setAwayCompetitor] = useStateForSelect('');

  useUpdate(() => {
    if (!filters.sport) {
      setHomeCompetitor('');
      setAwayCompetitor('');
    }
  }, [filters]);

  useUpdate(() => {
    if (Object.keys(homeCompetitor).length > 0)
      prepareHomeCompetitor(homeCompetitor);
  }, [homeCompetitor]);

  useUpdate(() => {
    if (Object.keys(awayCompetitor).length > 0)
      prepareAwayCompetitor(awayCompetitor);
  }, [awayCompetitor]);

  const createEventName = useCallback(
    filtersClone => {
      // Generate custom names based on selected competitors(Home vs Away)
      languages.forEach(lang => {
        if (filtersClone.home[lang] && filtersClone.away[lang]) {
          filtersClone.names = {
            ...filtersClone.names,
            [lang]: {
              name: `${filtersClone.home[lang].name} vs. ${filtersClone.away[lang].name}`,
            },
          };
        }
      });
      updateFilter(filtersClone, 'general');
    },
    [updateFilter]
  );

  const homeCompetitorList = useMemo(() => {
    return homeCompetitors.reduce(
      (acc, val) => [
        ...acc,
        { value: { names: val.names, id: val.id }, label: val.names.en.name },
      ],
      []
    );
  }, [homeCompetitors]);

  const awayCompetitorList = useMemo(() => {
    return awayCompetitors.reduce(
      (acc, val) => [
        ...acc,
        { value: { names: val.names, id: val.id }, label: val.names.en.name },
      ],
      []
    );
  }, [awayCompetitors]);

  const resetHomeCompetitor = () => {
    setHomeCompetitor('');
  };
  const resetAwayCompetitor = () => {
    setAwayCompetitor('');
  };

  const prepareHomeCompetitor = useCallback(
    value => {
      const filtersClone = { ...filters };
      if (value.label) {
        filtersClone.home = value.value.names;
        filtersClone.homeCompetitorId = value.value.id;
        createEventName(filtersClone);
      } else {
        filtersClone.home = {};
        filtersClone.homeCompetitorId = '';
      }
      updateFilter(filtersClone, 'general');
    },
    [filters, updateFilter, createEventName]
  );

  const prepareAwayCompetitor = useCallback(
    value => {
      const filtersClone = { ...filters };
      if (value.label) {
        filtersClone.away = value.value.names;
        filtersClone.awayCompetitorId = value.value.id;
        createEventName(filtersClone);
      } else {
        filtersClone.away = {};
        filtersClone.awayCompetitorId = '';
      }
      updateFilter(filtersClone, 'general');
    },
    [updateFilter, filters, createEventName]
  );

  return (
    <Fragment>
      <div className="form-row">
        <div className="col-md">
          <Select
            value={homeCompetitor}
            onInputChange={e => getCompetitors(e, 'HOME')}
            options={homeCompetitorList}
            onChange={setHomeCompetitor}
            placeholder="Home Team (start typing team name...)"
            styles={selectStyles}
          />
          <span className="reset-competitor" onClick={resetHomeCompetitor}>
            <i className="fa fa-times" />
          </span>
          <small>
            <i className="fa fa-info-circle" /> Search will only work if you
            type at least 3 letters
          </small>
        </div>

        {loaded ? (
          <div className="vsWord">
            <Spinner active={loaded} size="30" />
          </div>
        ) : (
          <h4 className="vsWord">VS</h4>
        )}

        <div className="col-md">
          <Select
            value={awayCompetitor}
            onInputChange={e => getCompetitors(e, 'AWAY')}
            options={awayCompetitorList}
            onChange={setAwayCompetitor}
            placeholder="Away Team (start typing team name...)"
            styles={selectStyles}
          />
          <span className="reset-competitor" onClick={resetAwayCompetitor}>
            <i className="fa fa-times" />
          </span>
          <small>
            <i className="fa fa-info-circle" /> Search will only work if you
            type at least 3 letters
          </small>
        </div>
      </div>
    </Fragment>
  );
};

CompetitorSelector.propTypes = {
  homeCompetitors: PropTypes.array,
  awayCompetitors: PropTypes.array,
  getCompetitors: PropTypes.func,
  handleInputChange: PropTypes.func,
  loaded: PropTypes.bool,
};

export default CompetitorSelector;
