import React, { useRef } from 'react';
import NotificationSystem from 'react-notification-system';
import { connect } from 'react-redux';

import * as UiActions from '../redux/actions/ui';
import { getNotification } from '../selectors';
import { useUpdate } from '../hooks';

const style = {
  NotificationItem: {
    DefaultStyle: {
      margin: '20px 10px',
    },
  },
  MessageWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
      fontSize: '19px',
    },
  },
  Title: {
    DefaultStyle: {
      fontSize: '17px',
      margin: '0 0 10px 0',
      padding: 0,
      fontWeight: 'bold',
    },
  },
};
const autoDismiss = 4;

const NotificationContainer = ({ notification, emptyNotificationMessage }) => {
  const notificationSystemRef = useRef(null);

  useUpdate(() => {
    if (notification.message) {
      const { message, level, title, position } = notification;
      notificationSystemRef.current.addNotification({
        message,
        level,
        title,
        position: position || 'tr',
        autoDismiss,
      });
      emptyNotificationMessage();
    }
  }, [notification]);

  return (
    <NotificationSystem
      ref={ref => (notificationSystemRef.current = ref)}
      style={style}
    />
  );
};

const mapStateToProps = state => ({
  notification: getNotification(state),
});

const mapDispatchToProps = {
  addNotificationMessage: UiActions.addNotificationMessage,
  emptyNotificationMessage: UiActions.emptyNotificationMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationContainer);
