import React, { useState } from 'react';
import classes from './ClientDataModal.module.scss';
import Modal from '../../../../components/NewModal/Modal';
import { useFetch } from '../../../../hooks';

const ClientDataModal = ({
  isActive,
  onClose,
  customerId,
  openedTab,
  tabs,
}) => {
  const [currentTab, setCurrentTab] = useState(openedTab);

  const { data: customer, refetch: refetchCustomer } = useFetch(
    `/customer/view/admin/${customerId}`
  );

  if (!customer) return null;

  return (
    <Modal className="px-3 py-2" isActive={isActive} onClose={onClose}>
      <div className={classes.wrapper}>
        <div className={classes.layout}>
          <strong>Client Data</strong>
          <div
            className={`d-flex justify-content-between ${classes.customerInfo}`}
          >
            <strong>{customer.firstLastName}</strong>
            <strong className="mr-3">[ {customer.publicId} ]</strong>
          </div>
        </div>
        <div className={classes.layout} style={{ flex: 1 }}>
          <div className={`pt-4 ${classes.menu}`}>
            {tabs.map(tab => (
              <div
                key={tab.name}
                onClick={() => setCurrentTab(tab)}
                className={currentTab === tab ? classes.active : ''}
              >
                {tab.name}
              </div>
            ))}
          </div>
          <div className={classes.currentTab}>
            <currentTab.Component
              customer={customer}
              refetchCustomer={refetchCustomer}
              onClose={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ClientDataModal;
