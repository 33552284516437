import React, { useMemo } from 'react';
import moment from 'moment';

import IncomingBetsWinnings from './Reportings/IncomingBetsWinning';
import CustomerDetails from './Reportings/CustomerDetails';
import BetsPlaced from './Reportings/BetsPlaced';

const NraScg = () => {
  const monthsOptions = useMemo(() => {
    const dateEnd = moment.utc().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const dateStart = moment
      .utc()
      .subtract(10, 'years')
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    const months = [];

    while (dateEnd >= dateStart) {
      let option = {
        label: dateStart.format('MMMM YYYY'),
        from: dateStart.toISOString(),
      };
      dateStart.add(1, 'month');
      option.to = dateStart.toISOString();
      months.unshift(option);
    }

    return months;
  }, []);

  return (
    <div className="d-flex flex-row mt-5">
      <div className="col border-right">
        <IncomingBetsWinnings monthsOptions={monthsOptions} />
      </div>
      <div className="col border-right">
        <CustomerDetails />
      </div>
      <div className="col">
        <BetsPlaced monthsOptions={monthsOptions} />
      </div>
    </div>
  );
};

export default NraScg;
