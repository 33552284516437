import React from 'react';
import classes from './General.module.scss';
import Input from '../../../../../components/Form/Input';

const IdInfo = ({ customer }) => {
  return (
    <div>
      <h1>ID Info</h1>
      <div className={classes.idInfo}>
        <div>
          <label>Country:</label>
          <div>
            <Input
              customInput
              disabled
              value={customer.country.names.en.name}
              className="efb-input__underlined"
            />
          </div>
        </div>

        <div>
          <label>Citizenship:</label>
          <div>
            <Input
              customInput
              disabled
              value={customer.citizenship.names.en.name}
              className="efb-input__underlined"
            />
          </div>
        </div>

        <div>
          <label>Region:</label>
          <div>
            <Input
              customInput
              disabled
              value={customer.region.names.en.name}
              className="efb-input__underlined"
            />
          </div>
        </div>

        <div>
          <label>City:</label>
          <div>
            <Input
              customInput
              disabled
              value={customer.city.names.en.name}
              className="efb-input__underlined"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdInfo;
