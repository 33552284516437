import axiosInstance from '../axios-instance';
import { createRequestTypes, actionCreator } from '../index';

export const BET_HISTORY = createRequestTypes('BET_HISTORY');

export const getFilteredBetHistory = filters => async dispatch => {
  dispatch(actionCreator.request(BET_HISTORY));
  try {
    const res = await axiosInstance.post(
      '/betslip/view/admin/bet/history',
      filters
    );
    dispatch(actionCreator.success(BET_HISTORY, res));
  } catch (error) {
    dispatch(actionCreator.failure(BET_HISTORY, error));
  }
};
