import React from 'react';
import _ from 'lodash';
import Button from '../../Layout/Button';

export const BirDelayRow = ({
  tableData,
  editHandler,
  showDeleteModal,
  sportViews,
}) => {
  const renderRow = data => {
    const { sportId, categoryId, tournamentId } = _.pick(data, [
      'sportId',
      'categoryId',
      'tournamentId',
    ]);
    let sportName = '',
      categoryName = '',
      tournamentName = '';

    if (tournamentId) {
      const sportView = sportViews.find(
        sportView => sportView.tournamentId === tournamentId
      );
      tournamentName = sportView.tournamentName;
      categoryName = sportView.categoryName;
      sportName = sportView.sportName;
    } else if (categoryId) {
      const sportView = sportViews.find(
        sportView => sportView.categoryId === categoryId
      );
      categoryName = sportView.categoryName;
      sportName = sportView.sportName;
    } else if (sportId) {
      const sportView = sportViews.find(
        sportView => sportView.sportId === sportId
      );
      sportName = sportView.sportName;
    }

    const currentData = {
      id: data.id,
      name: sportName,
      duration: data.duration,
      template: { ...data },
    };

    return (
      <tr key={data.id}>
        <td>{sportName}</td>
        <td>{tournamentName}</td>
        <td>{categoryName}</td>
        <td>{data.duration.match(/\d+/g).join('')}</td>
        <td>
          <Button
            onClick={() => editHandler(currentData)}
            cssClass="btn btn-outline-secondary btn-xs mr-1"
          >
            <i className="fas fa-edit" /> Edit
          </Button>
          <Button
            onClick={() => showDeleteModal(currentData)}
            cssClass="btn btn-danger btn-xs"
          >
            <i className="fas fa-trash" /> Delete
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <>
      {tableData.length !== 0 ? (
        tableData.map(renderRow)
      ) : (
        <tr>
          <td>No transactions found.</td>
        </tr>
      )}
    </>
  );
};

export default React.memo(BirDelayRow);
