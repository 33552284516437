import React, { useMemo } from 'react';
import Table from '../../components/Table/Table';

const BetradarEventsTable = ({ streamingEvents, mappedEvents, streamStatus }) => {
  const columns = useMemo(
    () => [
      { name: 'Competitors', value: 'competitors', type: 'string' },
      { name: 'Start time', value: 'start_time', type: 'string' },
      { name: 'Sport', value: 'sportName', type: 'string' },
      { name: 'Country', value: 'categoryName', type: 'string' },
      { name: 'Tournament', value: 'tournamentName', type: 'string' }
    ],
    []
  );

  return (
    <Table
      excludeKeys={[
        'id',
        'sport_event_id',
        'start_time',
        'event_status',
        'contents'
      ]}
      data={streamingEvents.filter(stream => {
        const isMapped = mappedEvents.find(event => event.streamingProviders.find(
          provider => provider.streamId === stream.contents[0].streams[0].id));

        if ((!isMapped && streamStatus === 'mapped') || (isMapped && streamStatus === 'unmapped')) {
          return false;
        }

        return true;
      })}
      mappedEvents={mappedEvents}
      columns={columns}
      tableStyle="sortable table table-hover table-striped efb-table efb-data-table"
      tableType="betradarEventsTable"
      pageSize="50"
    />
  );
};

export default BetradarEventsTable;
