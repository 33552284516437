import React, { memo } from 'react';

export const Golf = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Golf ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 99.71 141"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <circle className={'Ball'} cx="49.85" cy="49.85" r="49.85" />
        <path
          className={'Ball'}
          d="M78.36,98.77A49.38,49.38,0,0,1,57,107.2a49.46,49.46,0,0,1-14.31,0,49.46,49.46,0,0,1-21.42-8.48l-6.85,6.85A49.55,49.55,0,0,0,42.7,119.83V141H57V119.83a49.71,49.71,0,0,0,28.22-14.2Z"
        />
        <path d="M75.87,7.32a7.45,7.45,0,0,1-1.42,0C70.87,7,67.2,4.92,66.55,3a.3.3,0,0,1,0-.08A49.33,49.33,0,0,1,75.87,7.32Z" />
        <path d="M28,16.62a8.22,8.22,0,0,1-4.68,6,9.29,9.29,0,0,1-3.42.93c-4,.28-6.54-2.57-5.89-6a7.17,7.17,0,0,1,1.27-2.86A9.42,9.42,0,0,1,21.82,11C25.5,10.59,28.42,13.13,28,16.62Z" />
        <path d="M38.49,37.71A8.4,8.4,0,0,1,34,45a8.06,8.06,0,0,1-3.69.92,7.64,7.64,0,0,1-7.71-8,8.1,8.1,0,0,1,1-3.59,8.36,8.36,0,0,1,7.12-4.4A7.56,7.56,0,0,1,38.49,37.71Z" />
        <path d="M15.39,38.14a8.7,8.7,0,0,1-4,7.07,6.14,6.14,0,0,1-3.07.91c-3.65.06-6.3-3.28-6.18-7.38a8.85,8.85,0,0,1,.77-3.41,7.15,7.15,0,0,1,5.9-4.42C12.53,30.66,15.54,33.84,15.39,38.14Z" />
        <path d="M26.48,59.8a7.46,7.46,0,0,1-4,6.94,6.87,6.87,0,0,1-3.38.73,8,8,0,0,1-7.42-8,8.28,8.28,0,0,1,.68-3.53,6.82,6.82,0,0,1,6.27-4.28C22.68,51.68,26.38,55.36,26.48,59.8Z" />
        <path d="M5.27,59.24a7.18,7.18,0,0,1-2.78,6.19A4.17,4.17,0,0,1,2.34,65,49,49,0,0,1,.46,56.64a47.58,47.58,0,0,1-.42-5C2.82,52.23,5.19,55.43,5.27,59.24Z" />
        <path d="M50.69,59.95a8,8,0,0,1-4.46,7.17,8.3,8.3,0,0,1-12-7.23,8,8,0,0,1,.85-3.7,8.12,8.12,0,0,1,7.29-4.48A8.29,8.29,0,0,1,50.69,59.95Z" />
        <path d="M39.19,83.92a5.24,5.24,0,0,1-3.62,5.21,8.46,8.46,0,0,1-3.23.56c-4-.06-7.61-2.55-8.08-6a5.27,5.27,0,0,1,.44-2.93,6.66,6.66,0,0,1,6.39-3.55C35.33,77.26,39,80.47,39.19,83.92Z" />
        <path d="M16.57,87c-.51-.46-1-.92-1.5-1.4a49.5,49.5,0,0,1-5.56-6.42c-.71-1-1.39-2-2-3A5.65,5.65,0,0,1,9.67,76a9.37,9.37,0,0,1,7.77,7.21A4.49,4.49,0,0,1,16.57,87Z" />
        <path d="M61.86,83.88a6.45,6.45,0,0,1-4.46,5.3,9.6,9.6,0,0,1-3.38.62c-4,0-7.37-2.37-7.41-5.82a5.57,5.57,0,0,1,.82-2.91,8.26,8.26,0,0,1,7-3.71C58.83,77.31,62.14,80.41,61.86,83.88Z" />
        <path d="M83.14,82.81a8.38,8.38,0,0,1-4.66,5.68,9.18,9.18,0,0,1-3.15.92c-3.68.37-6.6-2.18-6.19-5.67a6.84,6.84,0,0,1,1.14-3,9.42,9.42,0,0,1,7-4C81.21,76.51,83.8,79.35,83.14,82.81Z" />
        <path d="M50.28,97.48c0,1-.82,1.73-2,2.19l-1,0a50.3,50.3,0,0,1-9.94-1.53h0a2.36,2.36,0,0,1-.26-.66,2.23,2.23,0,0,1,.41-1.74c.89-1.32,3.16-2.37,5.9-2.36C47.22,93.36,50.34,95.54,50.28,97.48Z" />
        <path d="M69.71,95.59c-1.19.52-2.39,1-3.63,1.42a48.89,48.89,0,0,1-8.72,2.13,1.75,1.75,0,0,1-.63-1.68,3.3,3.3,0,0,1,1-1.73,9.73,9.73,0,0,1,6.53-2.44C66.93,93.25,69,94.28,69.71,95.59Z" />
        <path
          className={'Ball'}
          d="M75.87,7.32a7.45,7.45,0,0,1-1.42,0C70.87,7,67.2,4.92,66.55,3a.3.3,0,0,1,0-.08A49.33,49.33,0,0,1,75.87,7.32Z"
        />
        <path
          className={'Ball'}
          d="M24.48,19.52a7.45,7.45,0,0,1-1.15,3.13,9.29,9.29,0,0,1-3.42.93c-4,.28-6.54-2.57-5.89-6a7.17,7.17,0,0,1,1.27-2.86,8.65,8.65,0,0,1,3.16-1C22.11,13.34,24.9,15.84,24.48,19.52Z"
        />
        <path
          className={'Ball'}
          d="M34.92,41.25A8.39,8.39,0,0,1,34,45a8.06,8.06,0,0,1-3.69.92,7.64,7.64,0,0,1-7.71-8,8.1,8.1,0,0,1,1-3.59,7.9,7.9,0,0,1,3.58-1A7.61,7.61,0,0,1,34.92,41.25Z"
        />
        <path
          className={'Ball'}
          d="M12.25,41.62a9.2,9.2,0,0,1-.83,3.59,6.12,6.12,0,0,1-3.07.91c-3.65.06-6.3-3.28-6.18-7.38a8.86,8.86,0,0,1,.77-3.41,5.78,5.78,0,0,1,2.9-1C9.38,34.08,12.35,37.31,12.25,41.62Z"
        />
        <path
          className={'Ball'}
          d="M23.21,63.2a7.73,7.73,0,0,1-.68,3.55,6.87,6.87,0,0,1-3.38.73,8,8,0,0,1-7.42-8,8.28,8.28,0,0,1,.68-3.53,6.11,6.11,0,0,1,3.19-.83C19.51,55.17,23,58.84,23.21,63.2Z"
        />
        <path
          className={'Ball'}
          d="M2.66,62.36A8.8,8.8,0,0,1,2.34,65,49,49,0,0,1,.46,56.64,9.06,9.06,0,0,1,2.66,62.36Z"
        />
        <path
          className={'Ball'}
          d="M47.12,63.47a7.75,7.75,0,0,1-.89,3.65,8.3,8.3,0,0,1-12-7.23,8,8,0,0,1,.85-3.7,8,8,0,0,1,3.73-.9A8.31,8.31,0,0,1,47.12,63.47Z"
        />
        <path
          className={'Ball'}
          d="M36.12,86.53a4.37,4.37,0,0,1-.55,2.6,8.46,8.46,0,0,1-3.23.56c-4-.06-7.61-2.55-8.08-6a5.27,5.27,0,0,1,.44-2.93A7.44,7.44,0,0,1,28,80.06C32.18,80.17,35.83,83.26,36.12,86.53Z"
        />
        <path
          className={'Ball'}
          d="M15.07,85.56a49.5,49.5,0,0,1-5.56-6.42,9.71,9.71,0,0,1,5.55,6.37S15.07,85.55,15.07,85.56Z"
        />
        <path
          className={'Ball'}
          d="M58.41,86.56a4.91,4.91,0,0,1-1,2.62,9.6,9.6,0,0,1-3.38.62c-4,0-7.37-2.37-7.41-5.82a5.57,5.57,0,0,1,.82-2.91A8.89,8.89,0,0,1,51,80.31C55.32,80.3,58.64,83.29,58.41,86.56Z"
        />
        <path
          className={'Ball'}
          d="M79.76,85.76a6.53,6.53,0,0,1-1.28,2.72,9.14,9.14,0,0,1-3.15.92c-3.68.37-6.6-2.18-6.19-5.67a6.84,6.84,0,0,1,1.14-3,8.89,8.89,0,0,1,3.48-.84C77.84,79.8,80.41,82.46,79.76,85.76Z"
        />
        <path
          className={'Ball'}
          d="M47.44,98.88a1.32,1.32,0,0,1-.21.75,50.3,50.3,0,0,1-9.94-1.53h0a2.36,2.36,0,0,1-.26-.66,2.23,2.23,0,0,1,.41-1.74,9.89,9.89,0,0,1,3-.44C44.27,95.29,47.39,97.24,47.44,98.88Z"
        />
        <path
          className={'Ball'}
          d="M66.08,97a48.89,48.89,0,0,1-8.72,2.13,1.75,1.75,0,0,1-.63-1.68,3.3,3.3,0,0,1,1-1.73,11.65,11.65,0,0,1,3.19-.48A7,7,0,0,1,66.08,97Z"
        />
      </g>
    </svg>
  );
};

export default memo(Golf);
