import React, { memo } from 'react';

export const VirtualHorses = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  const icon = (
    <>
      <path
        d="M21.16,29.92c-0.03-0.36-0.03-0.36,0-0.61c7.93,0,14.35-6.43,14.35-14.35S29.09,0.61,21.16,0.61S6.8,7.03,6.8,14.96
	c-0.28,0.03-0.25,0.03-0.61,0C6.2,6.7,12.9,0,21.16,0s14.96,6.69,14.96,14.96S29.42,29.92,21.16,29.92L21.16,29.92z"
      />
      <path
        d="M15.44,14.38c-0.17-0.03-0.38-0.05-0.51-0.16c-0.34-0.26-0.49-0.65-0.47-1.08c0.01-0.22,0.09-0.45,0.1-0.67
	c0.01-0.3,0.13-0.53,0.36-0.72c0.33-0.29,0.62-0.61,1-0.83c0.79-0.46,1.61-0.84,2.54-0.94c0.18-0.01,0.93-0.09,1.43-0.07
	c0-0.07,0-0.13,0-0.2c0-0.08-0.03-0.17-0.01-0.25c0.05-0.37,0.17-0.72,0.42-1.01c0.2-0.24,0.45-0.41,0.76-0.44
	c0.5-0.03,0.91,0.17,1.2,0.58c0.17,0.24,0.25,0.5,0.29,0.79c0.01,0.12,0.09,0.26,0.24,0.33c0,0.01-0.01,0.03-0.01,0.03
	c-0.13,0-0.26,0-0.4,0c0,0.01-0.05,0.12-0.1,0.16c0,0.01,0,0.07-0.01,0.09c-0.08,0.15-0.15,0.3-0.24,0.42
	c-0.05,0.07-0.09,0.12-0.09,0.2c0,0.07-0.05,0.12-0.12,0.1s-0.09,0.01-0.12,0.07c-0.03,0.04-0.05,0.08-0.09,0.1
	c-0.07,0.08-0.1,0.13-0.05,0.25c0.09,0.18,0.17,0.38,0.25,0.58c0.05,0.12,0.09,0.24,0.13,0.37c0.1-0.07,0.2-0.12,0.29-0.17
	c0-0.01,0-0.03,0-0.05c-0.01-0.16,0.04-0.28,0.17-0.34s0.26-0.04,0.38,0.07c0.01,0.01,0.04,0.04,0.07,0.04
	c0.1-0.01,0.22-0.01,0.33-0.04c0.2-0.07,0.38-0.09,0.57-0.01c0.07,0.03,0.12,0.01,0.18-0.04c0.2-0.16,0.4-0.32,0.62-0.45
	c0.67-0.42,1.34-0.82,2.01-1.23c0.13-0.08,0.28-0.16,0.43-0.26c-0.43-0.18-0.82-0.4-1.01-0.84c0.4,0.09,0.8,0.2,1.2,0.29
	c0.04-0.2,0.05-0.21,0.22-0.15c0.1,0.04,0.97,0.3,1.13,0.4c0.03,0.01,0.04,0.01,0.05,0.01c0.26-0.05,0.49,0.07,0.71,0.17
	c0.76,0.38,1.38,0.94,1.96,1.57c0.16,0.18,0.36,0.34,0.61,0.42c0.08,0.03,0.13,0.09,0.2,0.15c0.04,0.04,0.08,0.09,0.12,0.12
	c0.25,0.17,0.51,0.34,0.75,0.53c0.3,0.24,0.63,0.45,0.76,0.86c0.04,0.12,0.08,0.24,0.04,0.37c-0.07,0.17-0.08,0.36-0.08,0.53
	c0,0.08,0,0.16-0.03,0.24c-0.05,0.24-0.15,0.45-0.42,0.5c-0.03,0-0.05,0.05-0.08,0.07c-0.09,0.07-0.17,0.13-0.26,0.18
	c-0.05,0.03-0.12,0.04-0.17,0.07c-0.18,0.1-0.37,0.15-0.57,0.07c-0.17-0.07-0.29-0.2-0.4-0.33c-0.09-0.12-0.2-0.2-0.34-0.22
	c-0.08-0.01-0.15-0.03-0.21-0.04c-0.12-0.04-0.21-0.01-0.3,0.07c-0.08,0.08-0.18,0.15-0.28,0.2c-0.38,0.26-0.79,0.45-1.24,0.54
	c-0.55,0.1-1.11,0.08-1.65-0.03c-0.26-0.05-0.51-0.1-0.79-0.05c-0.03,0-0.04,0-0.07,0c-0.12-0.03-0.16,0.03-0.2,0.13
	c-0.21,0.47-0.42,0.95-0.62,1.42c-0.01,0.04-0.24,0.46-0.28,0.54c-0.57,1.04-1.07,2.11-1.78,3.07c-0.26,0.36-0.46,0.75-0.62,1.16
	c-0.15,0.34-0.33,0.67-0.61,0.94c-0.09,0.08-0.18,0.16-0.26,0.24c0.09,0.24,0.18,0.47,0.28,0.7c0.17,0.44,0.25,0.9,0.49,1.3
	c0.2,0.36,0.4,0.72,0.61,1.08c0.12,0.2,0.21,0.41,0.18,0.65c-0.01,0.18-0.09,0.33-0.21,0.47c-0.13,0.16-0.29,0.21-0.49,0.22
	c-0.45,0.03-0.91,0.05-1.36,0.12c-0.32,0.04-0.62,0.16-0.93,0.24c-0.45,0.12-0.84,0.05-1.23-0.22c-0.24-0.17-0.5-0.28-0.75-0.41
	c-0.03-0.01-0.07-0.03-0.09-0.05c-0.28-0.2-0.55-0.41-0.83-0.61c0,0-0.01,0.03-0.01,0.04c-0.05,0.3-0.1,0.59-0.16,0.9
	c-0.08,0.45-0.3,0.8-0.71,1.03c-0.38,0.2-0.71,0.46-1.03,0.75c-0.29,0.26-0.58,0.54-0.87,0.79c-0.2,0.17-0.43,0.22-0.7,0.2
	c-0.18-0.03-0.38,0-0.57,0.01c-0.13,0-0.28,0.03-0.41,0.01c-0.21-0.01-0.41-0.05-0.61-0.09c-0.09-0.01-0.18-0.07-0.18-0.17
	c0-0.13,0-0.28,0.05-0.4c0.08-0.18,0.2-0.36,0.32-0.5c0.12-0.14,0.26-0.28,0.49-0.24c0.03,0,0.07-0.01,0.08-0.04
	c0.13-0.22-0.07-0.67-0.34-0.72c0.01,0.03,0.03,0.05,0.03,0.07c-0.22-0.05-0.49-0.15-0.62-0.43c-0.07-0.12-0.13-0.25-0.22-0.34
	c-0.41-0.41-0.83-0.8-1.25-1.21c-0.34-0.33-0.7-0.65-0.92-1.05c-0.12-0.2-0.15-0.42,0.01-0.65c0.13-0.18,0.25-0.37,0.36-0.57
	c0.12-0.22,0.24-0.45,0.34-0.67c0.12-0.25,0.24-0.49,0.34-0.74c0.03-0.07,0.04-0.13,0.05-0.2c-0.05,0.03-0.08,0.05-0.1,0.09
	c-0.5,0.55-1.04,1.08-1.7,1.45c-0.15,0.08-0.26,0.2-0.41,0.32c0.33,0.43,0.63,0.87,0.96,1.28c0.29,0.36,0.62,0.67,0.94,1.01
	c0.21,0.24,0.34,0.51,0.34,0.84c0,0.1,0.01,0.21,0.01,0.3c0.03,0.3,0.04,0.59,0.24,0.84c0.01,0.01,0.01,0.03,0.01,0.04
	c0.01,0.33,0.03,0.65,0.04,0.97c0,0.04-0.05,0.1-0.09,0.12c-0.07,0.03-0.16,0.05-0.22,0.04c-0.21-0.04-0.43-0.09-0.63-0.17
	c-0.24-0.08-0.41-0.26-0.55-0.47c-0.04-0.05-0.07-0.15-0.04-0.21c0.05-0.2,0.13-0.38,0.3-0.51c0.04-0.03,0.07-0.08,0.09-0.12
	c0.03-0.05,0.04-0.1,0.07-0.15c0.09-0.13,0.05-0.42-0.08-0.53c0,0.03-0.01,0.04-0.01,0.05c-0.07-0.07-0.12-0.13-0.18-0.2
	c-0.01,0.03-0.01,0.04-0.01,0.07c-0.13-0.09-0.24-0.22-0.26-0.38c-0.03-0.24-0.15-0.42-0.3-0.59c-0.33-0.34-0.66-0.68-0.98-1.04
	c-0.32-0.35-0.67-0.69-0.95-1.08c-0.13-0.2-0.16-0.5,0-0.7c0.16-0.21,0.34-0.42,0.49-0.65c0.26-0.37,0.5-0.74,0.76-1.11
	c0.12-0.17,0.13-0.33,0.01-0.5c-0.09-0.13-0.18-0.26-0.29-0.38c-0.28-0.33-0.45-0.71-0.55-1.13c-0.05-0.22-0.12-0.46-0.17-0.69
	c-0.09-0.42-0.13-0.86-0.08-1.29c0.01-0.07,0.03-0.13,0.03-0.21c-0.01,0-0.03-0.01-0.04-0.01c-0.09,0.15-0.2,0.29-0.29,0.43
	c-0.1,0.17-0.2,0.36-0.33,0.51c-0.22,0.26-0.47,0.51-0.72,0.78c-0.05,0.05-0.1,0.1-0.18,0.08c-0.03-0.01-0.08,0.01-0.11,0.04
	c-0.08,0.05-0.14,0.12-0.21,0.17c-0.51,0.42-1.09,0.72-1.74,0.88c-0.28,0.07-0.64,0.15-0.96,0.07c0.14-0.01,0.57-0.09,0.63-0.15
	c-0.17,0.03-0.47,0.03-0.66-0.04c0.11-0.01,0.29-0.08,0.29-0.08c-0.29,0.01-1-0.01-1.09-0.1c0.21-0.01,0.74-0.08,0.86-0.15
	c-0.14,0.01-0.68,0.04-0.97-0.04c0-0.01,0.24-0.03,0.36-0.05c-0.25-0.05-0.51-0.08-0.72-0.15c0-0.01,0.07-0.04,0.11-0.04
	c-0.04-0.01-0.07-0.03-0.09-0.03c-0.16-0.07-0.33-0.1-0.49-0.18c-0.14-0.09-0.28-0.21-0.46-0.25C1.44,19.03,1.41,19,1.37,18.99
	c0-0.01,0.2,0.01,0.21,0c-0.04-0.03-0.08-0.04-0.12-0.07c-0.07-0.04-0.13-0.08-0.2-0.12c-0.28-0.17-0.64-0.45-0.83-0.63
	c-0.08-0.1-0.16-0.2-0.24-0.3c-0.01-0.01-0.04-0.08-0.04-0.1c0.14,0.08,0.33,0.18,0.5,0.22C0.61,17.93,0.11,17.6,0,17.28
	c0.12,0.05,0.18,0.13,0.36,0.17c-0.05-0.04-0.13-0.16-0.17-0.21c0.17,0.05,0.26,0.08,0.45,0.1c-0.07-0.05-0.12-0.12-0.14-0.15
	c0.12,0.07,0.24,0.13,0.36,0.2c0.01-0.03,0-0.09-0.03-0.16c0.05,0.01,0.22,0.05,0.25,0.07c-0.16-0.13-0.33-0.28-0.49-0.42
	C0.82,17,1.05,17.1,1.29,17.24c0.24,0.12,0.7,0.37,0.7,0.37c-0.03-0.04-0.05-0.07-0.08-0.1c0.05,0.01,0.38,0.29,1.28,0.38
	c0.5,0.01,0.97-0.1,1.45-0.21c0.03,0,0.13-0.07,0.18-0.09c0.22-0.1,0.43-0.21,0.64-0.33c0.18-0.09,0.36-0.21,0.55-0.26
	c0.53-0.15,1.03-0.34,1.53-0.59c0.08-0.04,0.15-0.1,0.22-0.16c0.1-0.07,0.22-0.15,0.33-0.21c0.16-0.08,0.32-0.14,0.47-0.2
	c0.18-0.05,0.4-0.07,0.58-0.1c0.05-0.01,0.1-0.03,0.15-0.05c0.54-0.51,1.18-0.83,1.91-1.01c1.18-0.25,3.36-0.01,4.57,0.59
	c-0.01-0.1-0.03-0.18-0.04-0.26c-0.03-0.16,0.05-0.25,0.21-0.25s0.3,0.03,0.43,0.12c0.13,0.09,0.28,0.17,0.42,0.25
	c0.07,0.04,0.13,0.04,0.18-0.01c0.1-0.09,0.21-0.2,0.33-0.3C16.67,14.62,16.07,14.49,15.44,14.38L15.44,14.38z M15.55,28.21
	c0.28-0.26,0.55-0.53,0.82-0.79c0.08-0.08,0.15-0.18,0.18-0.29c0.09-0.25,0.21-0.51,0.25-0.78c0.04-0.36,0.03-0.71,0.01-1.07
	c-0.01-0.55-0.03-1.11,0.15-1.65c0.05-0.15,0.01-0.21-0.15-0.26c-0.55-0.18-1.05-0.45-1.54-0.75c-0.36-0.22-0.7-0.44-1.05-0.67
	c-0.09,0.13-0.17,0.26-0.26,0.4c-0.25,0.33-0.49,0.66-0.75,0.97c-0.15,0.17-0.33,0.33-0.51,0.47c-0.29,0.24-0.55,0.49-0.79,0.78
	c-0.05,0.07-0.01,0.1,0.03,0.15c0.24,0.29,0.46,0.59,0.74,0.86c0.34,0.34,0.71,0.67,1.08,0.97c0.28,0.24,0.5,0.49,0.63,0.83
	c0.12,0.29,0.28,0.55,0.5,0.78c0.1,0.1,0.2,0.25,0.3,0.38C15.32,28.43,15.44,28.33,15.55,28.21L15.55,28.21z M18.58,25.9
	c0.22-0.12,0.46-0.24,0.7-0.34c0.13-0.05,0.28-0.1,0.41-0.13c0.04-0.01,0.1,0.04,0.13,0.08c0.1,0.18,0.17,0.38,0.15,0.61
	c-0.03,0.16,0.17,0.42,0.33,0.45c0.04,0,0.08-0.03,0.13-0.05c-0.01-0.04-0.03-0.07-0.04-0.1c0.17,0.01,0.32,0.04,0.47,0.03
	c0.18-0.01,0.36-0.05,0.54-0.09c0.28-0.07,0.55-0.15,0.82-0.22c0.01,0,0.03-0.01,0.05-0.03c-0.05-0.04-0.09-0.08-0.13-0.1
	c-0.34-0.28-0.65-0.59-0.92-0.93c-0.37-0.46-0.75-0.91-1.12-1.37c-0.05-0.07-0.09-0.07-0.16-0.04c-0.21,0.08-0.37,0.2-0.49,0.4
	c-0.38,0.62-0.82,1.2-1.12,1.87c-0.03,0.04-0.04,0.09-0.07,0.17C18.38,26.01,18.47,25.96,18.58,25.9z M18.8,13.14
	c0.22,0.15,0.43,0.3,0.66,0.44c0.17,0.12,0.37,0.21,0.54,0.33c0.15,0.09,0.26,0.21,0.4,0.3c0.03,0.01,0.07,0.03,0.1,0.03
	c0.3-0.04,0.53-0.2,0.75-0.4c0.07-0.05,0.13-0.12,0.2-0.17c-0.07-0.08-0.12-0.15-0.18-0.21c-0.01-0.01-0.07-0.01-0.1,0
	c-0.28,0.08-0.55,0.2-0.83,0.26c-0.41,0.08-0.74-0.08-1-0.4c-0.13-0.16-0.26-0.33-0.4-0.49c-0.01-0.03-0.05-0.04-0.08-0.04
	c-0.16,0.04-0.32,0.08-0.47,0.12C18.54,13,18.68,13.06,18.8,13.14z M30.31,14.43c-0.09-0.07-0.2-0.07-0.3-0.03
	c-0.53,0.21-1.04,0.42-1.57,0.62c-0.12,0.05-0.25,0.07-0.38,0.12c0.9,0.13,1.71-0.03,2.44-0.59C30.43,14.51,30.36,14.47,30.31,14.43
	z M28.13,14.5c-0.25,0.03-0.46-0.03-0.66-0.17c-0.03-0.01-0.05-0.04-0.1-0.08c-0.15,0.21-0.29,0.42-0.43,0.65
	c0.2,0.08,0.37,0.12,0.57,0.08c0.43-0.08,0.86-0.2,1.26-0.36c0.03-0.01,0.05-0.03,0.08-0.04c0-0.01,0-0.03,0-0.04
	C28.59,14.53,28.35,14.47,28.13,14.5L28.13,14.5z M20.3,12.72c0.05-0.03,0.1-0.05,0.16-0.08s0.12-0.05,0.18-0.08
	c-0.08-0.1-0.16-0.2-0.24-0.3C20.2,12.41,20.2,12.43,20.3,12.72z"
      />
    </>
  );

  return (
    <svg
      x="0px"
      y="0px"
	  className={
        `VirtualHorses ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36.12 29.97"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      {icon}
    </svg>
  );
};

export default memo(VirtualHorses);
