import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useMount } from '../../../../hooks/index';
import { connect } from 'react-redux';
import {
  createLoadingSelector,
  getCashoutManagerData,
} from '../../../../selectors/index';

import * as CashoutManagerActions from '../../../../redux/actions/riskManagement/cashoutManager';

import CashoutCoefficientTable from '../CashoutTables/CashoutCoefficientTable';
import CashoutModelsTable from '../CashoutTables/CashoutModelsTable';
import Button from '../../../../components/Layout/Button';

const CashoutInPlayParameters = ({
  getCoefficients,
  getTimes,
  getInPlayModels,
  getMultipleCoefficients,
  getMultipleModels,
  getMultipleTimes,
  updateCoefficients,
  updateTimes,
  updateInPlayModels,
  updateMultipleCoefficients,
  updateMultipleModels,
  updateMultipleTimes,
  coefficients,
  times,
  modelsInplay,
  multipleModels,
  multipleCoefficients,
  multipleTimes,
  isLoading,
  history,
}) => {
  useMount(() => {
    getCoefficients();
    getTimes();
    getInPlayModels();
    getMultipleCoefficients();
    getMultipleModels();
    getMultipleTimes();
  });
  return (
    <>
      {/* {Redirect to Cashout Calculator Inplay} */}
      <div className="d-flex justify-content-end">
        <Button
          onClick={() =>
            history.push({
              pathname: '/adm/rm/ctm/calc',
              search: '?param=IN_PLAY',
            })
          }
          cssClass="efb-btn__main"
        >
          Calculator
        </Button>
      </div>

      {/* Model, Odd, Time Tables */}
      <CashoutModelsTable
        title={'Model Parameters'}
        models={modelsInplay}
        update={updateInPlayModels}
        isLoading={isLoading}
      />
      <CashoutCoefficientTable
        title={'Odd Parameters'}
        data={coefficients}
        update={updateCoefficients}
        isLoading={isLoading}
      />
      <CashoutCoefficientTable
        title={'Time Parameters'}
        data={times}
        update={updateTimes}
        isLoading={isLoading}
      />
      <div className="py-5"></div>

      {/* Multiple Model, Multiple Odd, Multiple Time Tables */}
      <CashoutModelsTable
        title={'Multiple Model Parameters'}
        models={multipleModels}
        update={updateMultipleModels}
        isLoading={isLoading}
      />
      <CashoutCoefficientTable
        title={'Multiple Odd Parameters'}
        data={multipleCoefficients}
        update={updateMultipleCoefficients}
        isLoading={isLoading}
      />
      <CashoutCoefficientTable
        title={'Multiple Time Parameters'}
        data={multipleTimes}
        update={updateMultipleTimes}
        isLoading={isLoading}
      />
    </>
  );
};

const loadingReducer = createLoadingSelector([
  'GET_COEFFICIENT',
  'GET_TIMES',
  'GET_INPLAY_MODELS',
  'GET_MULTIPLE_COEFFICIENTS',
  'GET_MULTIPLE_MODELS',
  'GET_MULTIPLE_TIMES',
]);

const mapStateToProps = state => ({
  coefficients: getCashoutManagerData(state).coefficients,
  modelsInplay: getCashoutManagerData(state).modelsInplay,
  times: getCashoutManagerData(state).times,
  multipleCoefficients: getCashoutManagerData(state).multipleCoefficients,
  multipleModels: getCashoutManagerData(state).multipleModels,
  multipleTimes: getCashoutManagerData(state).multipleTimes,
  isLoading: loadingReducer(state),
});

const mapDispatchToProps = {
  getCoefficients: CashoutManagerActions.getCoefficients,
  getTimes: CashoutManagerActions.getTimes,
  getInPlayModels: CashoutManagerActions.getInPlayModels,
  getMultipleModels: CashoutManagerActions.getMultipleModels,
  getMultipleCoefficients: CashoutManagerActions.getMultipleCoefficients,
  getMultipleTimes: CashoutManagerActions.getMultipleTimes,
  updateCoefficients: CashoutManagerActions.updateCoefficients,
  updateTimes: CashoutManagerActions.updateTimes,
  updateInPlayModels: CashoutManagerActions.updateInPlayModels,
  updateMultipleCoefficients: CashoutManagerActions.updateMultipleCoefficients,
  updateMultipleModels: CashoutManagerActions.updateMultipleModels,
  updateMultipleTimes: CashoutManagerActions.updateMultipleTimes,
};

CashoutInPlayParameters.propTypes = {
  getMultipleTimes: PropTypes.func,
  getMultipleModels: PropTypes.func,
  getMultipleCoefficients: PropTypes.func,
  getCoefficients: PropTypes.func,
  getTimes: PropTypes.func,
  getInPlayModels: PropTypes.func,
  updateCoefficients: PropTypes.func,
  updateMultipleModels: PropTypes.func,
  updateTimes: PropTypes.func,
  updateInPlayModels: PropTypes.func,
  updateMultipleTimes: PropTypes.func,
  coefficients: PropTypes.array,
  times: PropTypes.array,
  models: PropTypes.array,
  multipleModels: PropTypes.array,
  multipleTimes: PropTypes.array,
  multipleCoefficients: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CashoutInPlayParameters));
