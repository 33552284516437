import React, { useState, useCallback } from 'react';
import TextEditor from '../TextEditor';
import Button from '../../../../../components/Layout/Button';
import InputField from '../../../../../components/Form/InputField';
import PropTypes from 'prop-types';
import { prepareHtml } from '../../StaticContentUtils';
import { sections } from '../../StaticContentObj';
import { useUpdate } from '../../../../../hooks';

const Sections = ({
  index,
  currentLang,
  updateObj,
  images,
  uploadImage,
  subpageContent,
  pageObj,
}) => {
  const [sectionsList, setSectionsList] = useState([
    { section: sections, key: 0 },
  ]);
  const [staticPageObj, setStaticPageObj] = useState({});

  useUpdate(() => {
    setStaticPageObj(pageObj);
  }, [pageObj]);

  const handleInputChange = useCallback(
    (e, i) => {
      const { name, value } = e.target;
      const pageObjClone = { ...staticPageObj };
      const section =
        pageObjClone.subpages[index].content[0][currentLang.value].sections[i];
      section[name] = name === 'htmlBody' ? prepareHtml(value, index) : value;
      updateObj(pageObjClone);
      const sectionsClone = [...sectionsList];
      sectionsClone[i].section[name] = value;
      setSectionsList(sectionsClone);
      setStaticPageObj(pageObjClone);
    },
    [currentLang.value, index, sectionsList, staticPageObj, updateObj]
  );

  const addSection = useCallback(
    e => {
      e.preventDefault();
      const pageObjClone = { ...staticPageObj };
      const sectionsClone = [...sectionsList];
      let counter = sectionsList.length;
      sectionsClone.push({ section: sections, key: counter++ });
      pageObjClone.subpages[index].content[0][currentLang.value].sections.push({
        headerTitle: '',
        name: '',
        htmlBody: '',
      });
      updateObj(pageObjClone);
      setSectionsList(sectionsClone);
      setStaticPageObj(pageObjClone);
    },
    [currentLang.value, index, sectionsList, staticPageObj, updateObj]
  );

  const removeSection = useCallback(
    sectionIndex => {
      const pageObjClone = { ...staticPageObj };
      const sectionsClone = [...sectionsList];
      sectionsClone.splice(sectionIndex, 1);
      pageObjClone.subpages[index].content[0][
        currentLang.value
      ].sections.splice(sectionIndex, 1);
      updateObj(pageObjClone);
      setSectionsList(sectionsClone);
      setStaticPageObj(pageObjClone);
    },
    [currentLang.value, index, sectionsList, staticPageObj, updateObj]
  );

  const mapSections = useCallback(
    (section, index) => {
      return (
        <div key={section.key}>
          <div className="efb-form">
            <div className="form-row">
              <div className="form-label-group col-md">
                <InputField
                  type="text"
                  name="name"
                  label={'Name'}
                  value={section.name}
                  placeholder="name"
                  onChange={e => handleInputChange(e, index)}
                />
              </div>
              <div className="form-label-group col-md">
                <InputField
                  type="text"
                  name="headerTitle"
                  label={'Header Title'}
                  placeholder="title"
                  value={section.headerTitle}
                  onChange={e => handleInputChange(e, index)}
                />
              </div>
            </div>
            <TextEditor
              index={index}
              initialValue={section.htmlBody}
              htmlBody={handleInputChange}
              images={images}
              uploadImage={uploadImage}
              content={subpageContent}
            />
            <br />
            <Button
              cssClass="efb-btn__main"
              onClick={() => removeSection(index)}
            >
              <i className="fa fa-trash"></i> Remove section {index + 1}
            </Button>
            <br />
          </div>
        </div>
      );
    },
    [handleInputChange, images, removeSection, subpageContent, uploadImage]
  );

  return (
    <>
      {sectionsList.map(mapSections)}
      <div className="form-group d-flex flex-row justify-content-end">
        <Button cssClass="efb-btn__main" onClick={addSection}>
          <i className="fa fa-plus"></i>
          Add section
        </Button>
      </div>
      <hr />
    </>
  );
};

Sections.propTypes = {
  pageObj: PropTypes.object,
  updateObj: PropTypes.func,
  subpage: PropTypes.object,
};

export default Sections;
