import React from 'react';

const IconFolderClosed = ({
  className,
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 17 14"
      className={className}
      {...otherProps}
    >
      <defs>
        <style>{`.a{fill:#cf8808;}.b{fill:#ffbe4a;}`}</style>
      </defs>
      <rect
        className="a"
        width="8"
        height="3"
        rx="1"
      />
      <rect
        className="b"
        width="17"
        height="13"
        rx="1"
        transform="translate(0 1)"
      />
    </svg>
  );
};

export default IconFolderClosed;