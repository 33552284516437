import React, { useMemo } from 'react';
import NraScg from './NraScg/NraScg';

const AdHockModule = ({ match }) => {
  const RouteComponent = useMemo(() => {
    switch (match.params.module) {
      case 'nra-scg':
        return NraScg;
      default:
        return '';
    }
  }, [match.params.module]);

  return <RouteComponent />;
};

export default AdHockModule;
