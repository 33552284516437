import React from 'react';
import logo from '../../resources/images/logo.png';

export const LoginHeader = () => {
  return (
    <header className="efb-login__form-header">
      <img src={logo} alt="efbet logo" width={210} height={50} />
      <h1>Login to get going</h1>
    </header>
  );
};

export default LoginHeader;
