import React, { Fragment, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/Layout/Button';
import { selectFieldOptions } from '../transactionsUtils';
import { selectStyles } from '../../../../containers/CMS/customReactSelectStyle';
import InputField from '../../../../components/Form/InputField';
import SelectField from '../../../../components/Form/SelectField';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const TransactionSelector = ({
  emptyTransaction,
  currencies,
  paymentMethods,
  types,
  states,
  getTransactions,
}) => {
  const [filter, setFilter] = useState({});
  const [dateTo, setDateTo] = useState('');
  const [dateFrom, setDateFrom] = useState('');

  const currencieOptions = useMemo(
    () => selectFieldOptions(currencies, 'currency_code', 'display_name'),
    [currencies]
  );

  const selectOptions = useCallback(option => selectFieldOptions(option), []);

  const handleChange = useCallback(
    event => {
      const { name, value } = event.target;
      setFilter({
        ...filter,
        [name]: value,
      });
    },
    [filter]
  );

  const modifyFilteredTransaction = useCallback(() => {
    getTransactions({
      ...filter,
      pageNumber: 1,
      pageSize: 100000000,
      dateFrom: dateFrom
        ? moment(dateFrom)
            .startOf('day')
            .utc(true)
            .toISOString()
        : '',
      dateTo: dateTo
        ? moment(dateTo)
            .endOf('day')
            .utc(true)
            .toISOString()
        : '',
      amountFrom: filter.amountFrom ? filter.amountFrom * 100 : '',
      amountTo: filter.amountTo ? filter.amountTo * 100 : '',
    });
  }, [dateFrom, dateTo, filter, getTransactions]);

  const clearForm = useCallback(() => {
    emptyTransaction();
    setFilter({});
  }, [emptyTransaction]);

  return (
    <Fragment>
      <h2>Transactions</h2>
      <br />
      <div className="efb-form">
        <div className="form-row">
          <div className="form-label-group col">
            <SelectField
              options={selectOptions(types).filter(
                type => type.name === 'DEPOSIT' || type.name === 'WITHDRAW'
              )}
              onChange={handleChange}
              placeholder="Select type"
              label="Select type"
              styles={selectStyles}
              name="transactionType"
              value={filter['transactionType'] || ''}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col">
            <DatePicker
              selected={dateFrom}
              onChange={e => setDateFrom(e)}
              maxDate={dateTo}
              dateFormat="dd/MM/yyyy"
              popperPlacement="bottom-start"
              placeholderText="Date From:  DD/MM/YYYY"
              isClearable
            />
          </div>
          <div className="col">
            <DatePicker
              selected={dateTo}
              onChange={e => setDateTo(e)}
              minDate={dateFrom}
              dateFormat="dd/MM/yyyy"
              popperPlacement="bottom-start"
              placeholderText="Date To:  DD/MM/YYYY"
              isClearable
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="form-label-group col">
            <SelectField
              name="transactionStatus"
              options={selectOptions(states)}
              onChange={handleChange}
              placeholder="Select status"
              label="Select status"
              styles={selectStyles}
              value={filter['transactionStatus'] || ''}
            />
          </div>
          <div className="form-label-group col">
            <SelectField
              name="currencyCode"
              options={currencieOptions}
              onChange={handleChange}
              placeholder="Select currency"
              label="Select currency"
              styles={selectStyles}
              value={filter['currencyCode'] || ''}
            />
          </div>
          <div className="form-label-group col">
            <InputField
              type="text"
              name="externalAccountId"
              className="form-control"
              label="Ext. Account No"
              placeholder="Ext. Account No"
              onChange={handleChange}
              value={filter['externalAccountId'] || ''}
            />
          </div>
          <div className="form-label-group col">
            <InputField
              type="text"
              name="customerUsername"
              className="form-control"
              label="Customer username"
              placeholder="Customer username"
              onChange={handleChange}
              value={filter['customerUsername'] || ''}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-label-group col">
            <SelectField
              name="paymentMethod"
              options={selectOptions(paymentMethods)}
              onChange={handleChange}
              placeholder="Select payment provider"
              label="Select payment provider"
              styles={selectStyles}
              value={filter['paymentMethod'] || ''}
            />
          </div>
          <div className="form-label-group col">
            <InputField
              type="number"
              name="amountFrom"
              className="form-control col-md"
              label="Amount from"
              placeholder="Amount from"
              min="0"
              onChange={handleChange}
              value={filter['amountFrom'] || ''}
            />
          </div>
          <div className="form-label-group col">
            <InputField
              type="number"
              name="amountTo"
              className="form-control col-md"
              min="0"
              label="Amount to"
              placeholder="Amount to"
              onChange={handleChange}
              value={filter['amountTo'] || ''}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="form-row">
        <Button onClick={clearForm} cssClass="efb-btn__main col-md">
          Clear
        </Button>
        <Button
          onClick={modifyFilteredTransaction}
          cssClass="efb-btn__main col-md"
        >
          Show result
        </Button>
      </div>
      <hr />
    </Fragment>
  );
};

TransactionSelector.propTypes = {
  currencies: PropTypes.array,
  emptyTransaction: PropTypes.func,
  getTransactions: PropTypes.func,
  paymentMethods: PropTypes.array,
  states: PropTypes.array,
  types: PropTypes.array,
};

export default TransactionSelector;
