import React, { Component } from 'react';
import Button from '../Layout/Button';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
const { vfs } = vfsFonts.pdfMake;

class TableExports extends Component {
  state = {
    content: {},
  };

  handleCSVExport = () => this.props.transactions.length !== 0;

  exportTableToPDF = () => {
    const tableData = [...this.props.transactions];
    const documentDefinition = {
      pageSize: 'A2',
      pageOrientation: 'landscape',
      content: [
        { text: 'Transactions table' },
        '\n',
        this.constructPdfTable(tableData, this.state.content.pdfColumns),
      ],
      styles: {
        tableHeader: {
          fontSize: 15,
          fontWeight: 'bold',
          fillColor: '#bfbfbf',
        },
      },
    };

    pdfMake.vfs = vfs;
    pdfMake.createPdf(documentDefinition).download('transactions.pdf');
  };

  constructPdfTable = (data, columns) => {
    return {
      table: {
        headerRows: 1,
        dontBreakRows: true,
        body: this.buildTableBody(data, columns),
      },
    };
  };

  buildTableBody = (data, columns) => {
    const body = [];
    body.push(columns);
    data.forEach(row => {
      const dataRow = [];
      columns.forEach(column => {
        if (row[column.key]) {
          dataRow.push(row[column.key].toString());
        } else {
          dataRow.push('');
        }
      });

      body.push(dataRow);
    });
    return body;
  };

  componentDidMount() {
    let cloneColumns = [...this.props.columns];
    let content = { headersArr: [], pdfColumns: [] };
    cloneColumns.forEach(el => {
      if (el.value) {
        content.headersArr.push({
          label: el.name,
          key: el.value,
          outputFormat: el.outputFormat,
        });
        content.pdfColumns.push({
          text: el.name,
          style: 'tableHeader',
          key: el.value,
          outputFormat: el.outputFormat,
        });
      }
    });
    this.setState({ content });
  }

  render() {
    const { transactions } = this.props;
    if (transactions.length === 0) return null;

    return (
      <div className="efb-data-table__export">
        <Button
          onClick={this.exportTableToPDF.bind(this)}
          disabled={transactions.length === 0}
          cssClass="efb-btn__light"
        >
          Export to PDF
        </Button>
        {transactions.length ? (
          <CSVLink
            style={{ color: '#FEB719' }}
            separator={';'}
            className={
              transactions.length === 0
                ? 'efb-btn__light disabled'
                : 'efb-btn__light'
            }
            onClick={() => this.handleCSVExport()}
            data={transactions}
            headers={this.state.content.headersArr}
            filename="transactions.csv"
            uFEFF={false}
          >
            Export to CSV
          </CSVLink>
        ) : (
          <Button cssClass="efb-btn__light">Export to CSV</Button>
        )}
      </div>
    );
  }
}

TableExports.propTypes = {
  transactions: PropTypes.array.isRequired,
  tab: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
};

export default TableExports;
