import React from 'react';
import PlayerManager from './PlayerManager/PlayerManager';

const PlayerManagementModule = ({ match }) => {
  switch (match.params.module) {
    case 'manager':
      return <PlayerManager />;
    default:
      return null;
  }
};

export default PlayerManagementModule;
