import { GET_USER_SETTINGS } from '../../actions/riskManagement/UserSettings';

const initialState = {
  userSettings: {}
};

export const userSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_SETTINGS.SUCCESS:
      return {
        ...state,
        userSettings: action.payload
      };
    default:
      return state;
  }
};
