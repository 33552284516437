import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import DBCore from '../Icons/DBCore';
import Afilliate from '../Icons/Afilliate';
import CMS from '../Icons/CMS';
import BetToView from '../Icons/BetToView';
import CustomerSer from '../Icons/CustomerSer';
import CallCenter from '../Icons/CallCenter';
import DataWarehouse from '../Icons/DataWarehouse';
import AdHook from '../Icons/AdHock';

const MainMemuLink = ({ item, match }) => {
  let MenuIcon;

  switch (item.url) {
    case 'dbc':
      MenuIcon = DBCore;
      break;
    case 'af':
      MenuIcon = Afilliate;
      break;
    case 'cm':
      MenuIcon = CMS;
      break;
    case 'ls':
      MenuIcon = BetToView;
      break;
    case 'cs':
      MenuIcon = CustomerSer;
      break;
    case 'cc':
      MenuIcon = CallCenter;
      break;
    case 'dw':
      MenuIcon = DataWarehouse;
      break;
    case 'ah':
      MenuIcon = AdHook;
      break;
    case 'pm':
      MenuIcon = CMS;
      break;
    default:
      MenuIcon = DBCore;
  }

  return (
    <li title={item.text} className="efb-main-menu__list-item">
      <NavLink to={`${match.url}/${item.url}`}>
        <MenuIcon size="sm">
          <span className="efb-icon__text">{item.text}</span>
        </MenuIcon>
      </NavLink>
    </li>
  );
};

class MainMenu extends Component {
  state = {
    navigation: [
      { text: 'DB Core Admin', url: 'dbc' },
      { text: 'Player Management', url: 'pm' },
      { text: 'Afilliate', url: 'af' },
      { text: 'Content Management', url: 'cm' },
      { text: 'Risk Management', url: 'rm' },
      { text: 'Customer Service', url: 'cs' },
      { text: 'Call Center', url: 'cc' },
      { text: 'Back Office', url: 'bo' },
      { text: 'Data Warehouse', url: 'dw' },
      { text: 'Ad-Hock', url: 'ah' },
      { text: 'Live Streaming', url: 'ls' }
    ]
  };

  render() {
    return (
      <nav className="efb-main-menu">
        <ul className="efb-container efb-main-menu__list">
          {this.state.navigation.map((item, i) => (
            <MainMemuLink key={i} item={item} {...this.props} />
          ))}
        </ul>
      </nav>
    );
  }
}

export default withRouter(MainMenu);
