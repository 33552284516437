import React from 'react';

import Table from '../../../components/NewTable/Table';
import { Button } from '../../../components/Layout';

const CurrentBets = ({ rowClassName, topBets, removeTopBet, language }) => {
  const columnConfig = [
    { name: 'Event', display: entity => entity.highlightName },
    { name: 'Sport', display: entity => entity.sportName },
    { name: 'Country', display: entity => entity.categoryName },
    { name: 'Tournament', display: entity => entity.tournamentName },
    {
      name: '',
      display: entity => (
        <Button
          cssClass="btn btn-danger btn-md mr-3"
          onClick={() => removeTopBet(entity.highlightId, language, topBets)}
        >
          Remove bet from Top
        </Button>
      ),
    },
  ];

  if (!topBets.length) {
    return <h4 className="text-center">No top bets</h4>;
  }

  return (
    <Table rowClassName={rowClassName} data={topBets} columns={columnConfig} />
  );
};

export default CurrentBets;
