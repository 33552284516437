import { createRequestTypes, actionCreator } from '..';
import axiosInstance from '../axios-instance';
import { addNotificationMessage } from '../ui';

export const GET_CASINO_GAMES = createRequestTypes('GET_CASINO_GAMES');
export const GET_CASINO_PROVIDERS = createRequestTypes('GET_CASINO_PROVIDERS');

export const getCasinoGames = () => async dispatch => {
  dispatch(actionCreator.request(GET_CASINO_GAMES));
  try {
    const res = await axiosInstance.get('/casino-backoffice/admin/game-names');
    dispatch(actionCreator.success(GET_CASINO_GAMES, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_CASINO_GAMES, error));
    addNotificationMessage(error.message, 'error', 'Error!');
  }
};

export const getCasinoProviders = () => async dispatch => {
  dispatch(actionCreator.request(GET_CASINO_PROVIDERS));
  try {
    const res = await axiosInstance.get(
      '/casino-backoffice/public/casino-provider'
    );
    dispatch(actionCreator.success(GET_CASINO_PROVIDERS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_CASINO_PROVIDERS, error));
    addNotificationMessage(error.message, 'error', 'Error!');
  }
};
