import { LANGUAGES } from '../actions/Languages';

const initialState = {
  languages: []
};

export const languagesReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case LANGUAGES.SUCCESS:
      return {
        ...state,
        languages: payload
      };
    default:
      return state;
  }
};
