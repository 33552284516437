import React, { Component } from 'react';
// More About Routing 4 and common misakes can be found here: https://css-tricks.com/react-router-4/
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { authentication } from './redux/actions/authentication';
import { getAuthData, getErrorMessage } from './selectors';
import Login from './containers/Login';
import Layout from './containers/Layout';
import { checkAuthStatus } from './redux/actions/authentication';

class App extends Component {
	constructor(props) {
		super(props);

		this.props.checkAuthStatus(this.props.history);
	}

	render() {
		return (
			<Switch>
				<Route exact path="/" render={() => <Login {...this.props} />} />
				<Route path="/adm" render={(props) => <Layout {...props} />} />
				<Route render={() => <h1>404</h1>} />
			</Switch>
		);
	}
}

const getAuthError = getErrorMessage([ 'LOGIN' ]);

const mapStateToProps = (state) => {
	const authData = getAuthData(state),
		authErrorMessage = getAuthError(state);
	return {
		authData,
		authErrorMessage
	};
};
const mapDispatchToProps = {
	authentication,
	checkAuthStatus
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
