import React, { Fragment, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';

import * as SctManagerActions from '../../../redux/actions/cms/sctManager';
import Button from '../../../components/Layout/Button';
import InputField from '../../../components/Form/InputField';
import Switch from '../../../components/Form/Switch/Switch';
import { selectStyles } from '../../CMS/customReactSelectStyle';
import { useStateForSelect, useUpdate } from '../../../hooks';
import { isEmpty } from '../../../utils/helpers';

const constantNames = {
  businessUnit: 'businessUnits',
  Sports: 'sport',
  Categories: 'category',
  Tournaments: 'tournament',
  Events: 'event',
  Campaigns: 'campaign',
  CasinoGames: 'casinoGame',
  CasinoProviders: 'casinoProvider',
  MarketTypes: 'marketTypes',
};

const CreateSportBonus = React.memo(
  ({
    createBonus: cb,
    businessUnits: businessU,
    casinoGames,
    casinoProviders,
    languages,
    sports,
    allMarketTypes,
    bonusTypes,
    bonusConfigs,
    categories,
    tournaments,
    events,
    getConfigs,
    getCategoryById,
    getTournamentById,
    getEventById,
    getCategoriesByManyIds,
    getTournamentsByManyIds,
    getEventsByManyIds,
    campaigns,
    addNotificationMessage,
    clearCategories,
    clearTournaments,
    clearEvents,
  }) => {
    const options = useMemo(() => {
      return {
        businessU: businessU.map(element => {
          return {
            value: element.id,
            label: element.name,
            type: constantNames.businessUnit,
          };
        }),
        sports: sports.map(element => {
          return {
            value: element.id,
            label: element.names.en.name,
            type: constantNames.Sports,
          };
        }),
        events: events.map(element => {
          return {
            value: element.id,
            label: element.names.en.name,
            type: constantNames.Events,
          };
        }),
        categories: categories.map(element => {
          return {
            value: element.id,
            label: element.names.en.name,
            type: constantNames.Categories,
          };
        }),
        tournaments: tournaments.map(element => {
          return {
            value: element.id,
            label: element.sportName
              ? `${element.sportName} -> ${element.names.en.name}`
              : element.names.en.name,
            type: constantNames.Tournaments,
          };
        }),
        campaigns: campaigns.map(element => {
          return {
            value: element.id,
            label: element.name,
            type: constantNames.Campaigns,
          };
        }),
        casinoGames: casinoGames.map(game => ({
          label: game.name,
          value: game.gameId,
          type: constantNames.CasinoGames,
        })),
        casinoProviders: casinoProviders.map(provider => ({
          label: provider,
          value: provider,
          type: constantNames.CasinoProviders,
        })),
        bonusTypes: bonusTypes.map(type => ({
          label:
            type[0] +
            type
              .slice(1)
              .split('_')
              .map(word => word.toLowerCase())
              .join(' '),
          value: type,
        })),
        languages: languages.map(lang => ({
          value: lang.languageCode,
          label: lang.displayName,
        })),
        allMarketTypes: allMarketTypes
          ? allMarketTypes.map(type => ({
              value: type.id,
              label: type.descriptionTemplates.en.name,
            }))
          : [],
      };
    }, [
      bonusTypes,
      businessU,
      campaigns,
      casinoGames,
      casinoProviders,
      categories,
      events,
      languages,
      sports,
      tournaments,
      allMarketTypes,
    ]);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [active, setActive] = useState(true);
    const [activationExpirationDays, setActivationExpirationDays] = useState(
      ''
    );
    const [rolloutExpirationDays, setRolloutExpirationDays] = useState('');
    const [businessUnits, setBusinessUnit] = useStateForSelect([]);
    const [enabledBusinessUnits, setEnabledBusinessUnits] = useState({});
    const [maxAmount, setMaxAmount] = useState('');
    const [percent, setPercent] = useState('');
    const [minFlipCoefficient, setMinFlipCoefficient] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [flips, setFlips] = useState('');
    const [campaignId, setCampaignId] = useStateForSelect('');

    const [nameLanguages, setNameLanguages] = useStateForSelect([]);
    const [bonusType, setBonusType] = useStateForSelect(options.bonusTypes[0]);
    const [
      selectedCasinoGamesList,
      setSelectedCasinoGamesList,
    ] = useStateForSelect([]);
    const [
      selectedCasinoProvidersList,
      setSelectedCasinoProvidersList,
    ] = useStateForSelect([]);
    const [selectedSportsList, setSelectedSportsList] = useStateForSelect([]);
    const [selectedMarketTypes, setSelectedMarketTypes] = useStateForSelect([]);
    const [
      selectedCategoriesList,
      setSelectedCategoriesList,
    ] = useStateForSelect([]);
    const [
      selectedTournamentsList,
      setSelectedTournamentsList,
    ] = useStateForSelect([]);
    const [
      selectedSportEventsList,
      setSelectedSportEventsList,
    ] = useStateForSelect([]);

    const maxExpirationDays = useMemo(() => {
      if (!startDate || !endDate) {
        return 0;
      }
      return Math.floor(
        moment
          .duration(
            moment(
              `${moment(endDate)
                .utc(true)
                .format('DD/MM/YYYY')} ${endTime}`,
              'DD/MM/YYYY HH:mm'
            ).diff(
              moment(
                `${moment(startDate)
                  .utc(true)
                  .format('DD/MM/YYYY')} ${startTime}`,
                'DD/MM/YYYY HH:mm'
              )
            )
          )
          .asDays()
      );
    }, [endDate, endTime, startDate, startTime]);

    useUpdate(() => {
      clearFields();
      if (campaignId.value) {
        getConfigs(campaignId.value);
      }
    }, [campaignId.value]);

    const config = useMemo(
      () => bonusConfigs.find(config => config.bonusType === bonusType.value),
      [bonusConfigs, bonusType.value]
    );

    const clearFields = useCallback(() => {
      setStartDate('');
      setStartTime('');
      setEndDate('');
      setEndTime('');
      setActivationExpirationDays('');
      setRolloutExpirationDays('');
      setActive(true);
      setMaxAmount('');
      setPercent('');
      setFlips('');
      setMinFlipCoefficient('');
      setNameLanguages([]);
      setBusinessUnit([]);
      setSelectedCasinoGamesList([]);
      setSelectedCasinoProvidersList([]);
      setSelectedMarketTypes([]);
    }, [
      setBusinessUnit,
      setNameLanguages,
      setSelectedCasinoGamesList,
      setSelectedCasinoProvidersList,
      setSelectedMarketTypes,
    ]);

    useUpdate(() => {
      if (bonusConfigs.length) {
        if (!config) {
          clearFields();
          return;
        }
        setStartDate(moment.utc(config.startDate).format('DD/MM/YYYY') || '');
        setStartTime(moment.utc(config.startDate).format('HH:mm:ss') || '');
        setEndDate(moment.utc(config.endDate).format('DD/MM/YYYY') || '');
        setEndTime(moment.utc(config.endDate).format('HH:mm:ss') || '');
        setActivationExpirationDays(config.activationExpirationDays || 0);
        setRolloutExpirationDays(config.rolloutExpirationDays || 0);
        setActive(config.isActive || true);
        setMaxAmount(config.maxAmount / 100 || '');
        setPercent(config.percent || '');
        setFlips(config.flips || '');
        setMinFlipCoefficient(config.minFlipCoefficient || '');
        setNameLanguages(
          Object.keys(config.names).map(langCode => ({
            name: config.names[langCode].name,
            label: options.languages.find(lang => lang.value === langCode)
              ? options.languages.find(lang => lang.value === langCode).label
              : '',
            value: config.names[langCode].locale,
            title: config.names[langCode].title,
            description: config.names[langCode].description,
            subTitle: config.names[langCode].subTitle,
          }))
        );
        setBusinessUnit(
          config.businessUnits &&
            config.businessUnits.map(bu =>
              options.businessU.find(option => option.label === bu.businessUnit)
            )
        );
        setEnabledBusinessUnits(
          config.businessUnits &&
            config.businessUnits.reduce(
              (acc, bu) => ({
                ...acc,
                [bu.businessUnit]: bu.enabled,
              }),
              {}
            )
        );
        setSelectedSportsList(
          config.sports.map(sport => {
            const foundSport = sports.find(sp => sp.id === sport.sportId);
            return {
              value: foundSport.id,
              label: foundSport.names.en.name,
            };
          })
        );
        Promise.all(
          config.categories.map(async category => {
            const foundCategory = await getCategoryById(
              category.sportEventCategoryId
            );
            return {
              value: foundCategory.id,
              label: foundCategory.names.en.name,
            };
          })
        ).then(categories => setSelectedCategoriesList(categories));
        Promise.all(
          config.tournaments.map(async tournament => {
            const foundTournament = await getTournamentById(
              tournament.tournamentId
            );
            return {
              value: foundTournament.id,
              label: foundTournament.names.en.name,
            };
          })
        ).then(tournaments => setSelectedTournamentsList(tournaments));
        Promise.all(
          config.sportEvents.map(async event => {
            const foundEvent = await getEventById(event.sportEventId);
            return {
              value: foundEvent.id,
              label: foundEvent.names.en.name,
            };
          })
        ).then(events => setSelectedSportEventsList(events));
        setSelectedMarketTypes(
          config.marketTypes.map(marketType =>
            options.allMarketTypes.find(
              option => option.value === marketType.marketType
            )
          )
        );
        setSelectedCasinoGamesList(
          config.casinoGames.map(game =>
            options.casinoGames.find(
              option => option.value === game.casinoGameId
            )
          )
        );
        setSelectedCasinoProvidersList(
          config.casinoProviders.map(provider =>
            options.casinoProviders.find(
              option => option.value === provider.casinoProvider
            )
          )
        );
      }
    }, [bonusType.value, bonusConfigs, config]);

    const checkValidBonus = useCallback(
      obj => {
        const sendError = objectName => {
          addNotificationMessage(
            `Select ${objectName}!`,
            'warning',
            'Warning!'
          );
        };

        if (obj.names.length === 0) {
          sendError('name');
          return false;
        }
        if (obj.names.some(name => Object.values(name).some(text => !text))) {
          addNotificationMessage('Fill in all names', 'warning', 'Warning');
          return false;
        }
        if (obj.names.findIndex(x => x.locale === 'en') === -1) {
          addNotificationMessage('English name missing', 'warning', 'Warning');
          return false;
        }
        if (isEmpty(obj.startDate) || isEmpty(obj.startDate)) {
          sendError('date');
          return false;
        }
        if (
          !(bonusType.label.indexOf('Casino') > -1) &&
          obj.minFlipCoefficient < 1.01
        ) {
          addNotificationMessage(
            'Min rollover odd is 1.01',
            'warning',
            'Warning!'
          );
          return false;
        }
        if (!obj.businessUnits.length) {
          sendError('businness unit');
          return false;
        }
        if (isEmpty(obj.campaignId)) {
          sendError('campaign');
          return false;
        }
        if (isEmpty(obj.bonusType)) {
          sendError('bonus type');
          return false;
        }
        if (obj.percent === -1) {
          sendError('percent');
          return false;
        }
        if (obj.maxAmount === -1) {
          sendError('max amount');
          return false;
        }
        if (isEmpty(obj.flips)) {
          sendError('flips');
          return false;
        }
        if (obj.activationExpirationDays === -1) {
          sendError('expiration time');
          return false;
        }
        return true;
      },
      [addNotificationMessage, bonusType]
    );

    const mapSelectedProperty = useCallback(
      (value, propName) => ({ [propName]: value }),
      []
    );

    const createBonus = useCallback(() => {
      let bonusForCrate = {
        activationExpirationDays: Number(activationExpirationDays),
        rolloutExpirationDays: Number(rolloutExpirationDays),
        active,
        bonusType: bonusType.value,
        businessUnits: businessUnits
          ? businessUnits.length > 0
            ? businessUnits.map(bu => ({
                businessUnit: bu.label,
                businessUnitEnabled: enabledBusinessUnits[bu.label],
              }))
            : ''
          : '',
        campaignId: Number(campaignId.value) || '',
        startDate: moment.utc(
          `${moment(moment(startDate, 'DD/MM/YYYY').toDate())
            .utc(true)
            .format('DD/MM/YYYY')} ${startTime}`,
          'DD/MM/YYYY HH:mm'
        ),
        endDate: moment.utc(
          `${moment(moment(endDate, 'DD/MM/YYYY').toDate())
            .utc(true)
            .format('DD/MM/YYYY')} ${endTime}`,
          'DD/MM/YYYY HH:mm'
        ),
        flips: Number(flips),
        maxAmount: Number(maxAmount * 100),
        minFlipCoefficient: Number(minFlipCoefficient),
        names: nameLanguages.map(nameLang => ({
          name: nameLang.name,
          locale: nameLang.value,
          title: nameLang.title,
          description: nameLang.description,
          subTitle: nameLang.subTitle,
        })),
        marketTypes: setSelectedMarketTypes,
        percent: Number(percent),
      };
      bonusForCrate =
        bonusType.value === 'SPORT_BONUS_AFTER_DEPOSIT'
          ? Object.assign(bonusForCrate, {
              sportEvents: selectedSportEventsList
                ? selectedSportEventsList.map(element =>
                    mapSelectedProperty(element.value, 'sportEventId')
                  )
                : [],
              sports: selectedSportsList
                ? selectedSportsList.map(element =>
                    mapSelectedProperty(element.value, 'sportId')
                  )
                : [],
              marketTypes: selectedMarketTypes
                ? selectedMarketTypes.map(element =>
                    mapSelectedProperty(element.value, 'marketTypeId')
                  )
                : [],
              tournaments: selectedTournamentsList
                ? selectedTournamentsList.map(element =>
                    mapSelectedProperty(element.value, 'tournamentId')
                  )
                : [],
              categories: selectedCategoriesList
                ? selectedCategoriesList.map(element =>
                    mapSelectedProperty(element.value, 'categoryId')
                  )
                : [],
            })
          : Object.assign(bonusForCrate, {
              casinoGames: selectedCasinoGamesList
                ? selectedCasinoGamesList.map(element =>
                    mapSelectedProperty(element.value, 'casinoGameId')
                  )
                : [],
              casinoProviders: selectedCasinoProvidersList
                ? selectedCasinoProvidersList.map(element =>
                    mapSelectedProperty(element.value, 'casinoProvider')
                  )
                : [],
            });

      if (checkValidBonus(bonusForCrate)) {
        bonusForCrate.startDate = bonusForCrate.startDate.toISOString();
        bonusForCrate.endDate = bonusForCrate.endDate.toISOString();
        if (config) {
          bonusForCrate.id = config.id;
        }
        cb(bonusForCrate);
      }
    }, [
      activationExpirationDays,
      rolloutExpirationDays,
      active,
      bonusType.value,
      businessUnits,
      campaignId.value,
      startDate,
      startTime,
      endDate,
      endTime,
      flips,
      maxAmount,
      minFlipCoefficient,
      nameLanguages,
      setSelectedMarketTypes,
      percent,
      selectedSportEventsList,
      selectedSportsList,
      selectedMarketTypes,
      selectedTournamentsList,
      selectedCategoriesList,
      selectedCasinoGamesList,
      selectedCasinoProvidersList,
      checkValidBonus,
      enabledBusinessUnits,
      mapSelectedProperty,
      config,
      cb,
    ]);

    useUpdate(() => {
      clearCategories();
      getCategoriesByManyIds(selectedSportsList);
    }, [selectedSportsList]);

    useUpdate(() => {
      clearTournaments();
      getTournamentsByManyIds(selectedCategoriesList);
    }, [selectedCategoriesList]);

    useUpdate(() => {
      clearEvents();
      getEventsByManyIds(selectedTournamentsList);
    }, [selectedTournamentsList]);

    const validateNumChange = useCallback(e => {
      const { value, min = undefined, max = undefined } = e.target;

      if (min !== '' && Number(value) < Number(min)) {
        return '';
      }
      if (max !== '' && Number(value) > Number(max)) {
        return max;
      }

      return value;
    }, []);

    const handleNameChange = useCallback(
      (e, lang) => {
        setNameLanguages([
          ...nameLanguages.map(nameLang => {
            if (nameLang.value === lang) {
              return {
                ...nameLang,
                [e.target.name]: e.target.value,
              };
            }
            return nameLang;
          }),
        ]);
      },
      [nameLanguages, setNameLanguages]
    );

    return (
      <Fragment>
        <div className="form-row align-items-center">
          <h2>Create sport Bonus</h2>
          <small className="text-danger ml-3">
            * All fields are required! *
          </small>
        </div>
        <br />
        <div className="efb-form">
          <div className="form-row">
            <div className="form-label-group col">
              <Select
                onChange={setCampaignId}
                options={options.campaigns}
                styles={selectStyles}
                placeholder={`Select ${constantNames.Campaigns}`}
                name={constantNames.Campaigns}
              />
            </div>
            <div className="form-label-group col">
              <Select
                onChange={setBonusType}
                value={bonusType}
                options={options.bonusTypes}
                styles={selectStyles}
                placeholder="Select bonus type"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-label-group col">
              <Select
                onChange={setNameLanguages}
                value={nameLanguages}
                options={options.languages}
                styles={selectStyles}
                placeholder="Select languages"
                isMulti
              />
            </div>
          </div>
          {nameLanguages && nameLanguages.length ? (
            <div className="form-row">
              {nameLanguages.map(lang => (
                <div
                  key={`name-${lang.value}`}
                  className="form-label-group col "
                >
                  {lang.label}{' '}
                  <i
                    className={
                      lang.value === 'en'
                        ? 'flag-icon flag-icon-gb'
                        : `flag-icon flag-icon-${lang.value}`
                    }
                  />
                  <div className="d-flex flex-column position-relative my-3">
                    <InputField
                      type="text"
                      name={'name'}
                      className="form-control"
                      label={'Name'}
                      placeholder={'Name'}
                      value={lang.name}
                      onChange={e => handleNameChange(e, lang.value)}
                    />
                  </div>
                  <div className="d-flex flex-column position-relative mb-3">
                    <InputField
                      type="text"
                      name={'title'}
                      className="form-control"
                      label={'Title'}
                      placeholder={'Title'}
                      value={lang.title}
                      onChange={e => handleNameChange(e, lang.value)}
                    />
                  </div>
                  <div className="d-flex flex-column position-relative mb-3">
                    <InputField
                      type="text"
                      name={'description'}
                      className="form-control"
                      label={'Description'}
                      placeholder={'Description'}
                      value={lang.description}
                      onChange={e => handleNameChange(e, lang.value)}
                    />
                  </div>
                  <div className="d-flex flex-column position-relative mb-3">
                    <InputField
                      type="text"
                      name={'subTitle'}
                      className="form-control"
                      label={'Subtitle'}
                      placeholder={'Subtitle'}
                      value={lang.subTitle}
                      onChange={e => handleNameChange(e, lang.value)}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          <div className="form-row">
            <div className="form-label-group col">
              <InputField
                type="number"
                name="ActivationExpirationTime"
                className="form-control"
                placeholder="Activation Expiration Time (days)"
                label="Activation Expiration Time (days)"
                min={1}
                max={maxExpirationDays || 1}
                value={activationExpirationDays}
                onChange={e =>
                  setActivationExpirationDays(validateNumChange(e))
                }
              />
            </div>
            <div className="form-label-group col">
              <InputField
                type="number"
                name="rolloutExpirationDays"
                className="form-control"
                placeholder="Rollout Expiration Time (days)"
                label="Rollout Expiration Time (days)"
                min={1}
                max={maxExpirationDays || 1}
                value={rolloutExpirationDays}
                onChange={e => setRolloutExpirationDays(validateNumChange(e))}
              />
            </div>
            <div className="form-label-group col">
              <Switch
                label="Inactive | Active"
                defIsChecked={active}
                name="IsActive"
                onChange={e => setActive(e.target.checked)}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col">
              <DatePicker
                placeholderText="Start date:  DD/MM/YYYY"
                dateFormat="dd/MM/yyyy"
                selected={Date.parse(startDate)}
                onChange={e => setStartDate(e)}
                popperPlacement="top-end"
                minDate={moment().toDate()}
                isClearable
              />
            </div>
            <div className="form-label-group col">
              <InputField
                type="time"
                name="startTime"
                value={startTime}
                onChange={e => setStartTime(e.target.value)}
                className="form-control"
                label="Start time"
              />
            </div>
            <div className="col">
              <DatePicker
                placeholderText="End date:  DD/MM/YYYY"
                dateFormat="dd/MM/yyyy"
                selected={Date.parse(endDate)}
                value={endDate}
                onChange={e => setEndDate(e)}
                popperPlacement="top-end"
                minDate={moment(startDate).toDate()}
                isClearable
              />
            </div>
            <div className="form-label-group col">
              <InputField
                type="time"
                name="endTime"
                onChange={e => setEndTime(e.target.value)}
                className="form-control"
                label="End time"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-label-group col">
              <Select
                onChange={setBusinessUnit}
                options={options.businessU}
                styles={selectStyles}
                placeholder={'Select Business unit'}
                value={businessUnits}
                name={constantNames.businessUnit}
                isMulti
              />
            </div>
            {businessUnits &&
              businessUnits.map(bu => (
                <div key={bu.label} className="d-flex align-items-center">
                  <Switch
                    isChecked={enabledBusinessUnits[bu.label] || false}
                    label={`Enabled ${bu.label}`}
                    name={`enabled-${bu.label}`}
                    onChange={e =>
                      setEnabledBusinessUnits({
                        ...enabledBusinessUnits,
                        [bu.label]: e.target.checked,
                      })
                    }
                  />
                </div>
              ))}
            <div className="form-label-group col">
              <InputField
                type="number"
                name="maxAmount"
                className="form-control"
                min={1}
                label="Max amount"
                placeholder="Max amount"
                value={maxAmount}
                onChange={e => setMaxAmount(validateNumChange(e))}
              />
            </div>
            <div className="form-label-group col">
              <InputField
                type="number"
                name="percent"
                className="form-control"
                label="Percent"
                placeholder="Percent"
                value={percent}
                min={1}
                onChange={e => setPercent(validateNumChange(e))}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-label-group col">
              <InputField
                type="number"
                name="flip"
                className="form-control"
                label="Minimal rollover"
                placeholder="Min flips"
                min={1}
                value={flips}
                onChange={e => setFlips(validateNumChange(e))}
              />
            </div>
            {!(bonusType.label.indexOf('Casino') > -1) ? (
              <div className="form-label-group col">
                <InputField
                  type="number"
                  name="minFlipCoefficient"
                  className="form-control"
                  label="Minimal rollover odd"
                  placeholder="Min flip coefficient"
                  min={1}
                  value={minFlipCoefficient}
                  onChange={e => setMinFlipCoefficient(validateNumChange(e))}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="form-row">
          {bonusType.value === 'SPORT_BONUS_AFTER_DEPOSIT' ? (
            <>
              <h3 className="m-auto pb-3">
                Exclude Market Types, Sports, Categories, Tournaments or Events
              </h3>
              <div className="form-label-group col-md-12">
                <Select
                  onChange={setSelectedMarketTypes}
                  value={selectedMarketTypes}
                  options={options.allMarketTypes}
                  styles={selectStyles}
                  placeholder={`Select ${constantNames.MarketTypes}`}
                  name={constantNames.MarketTypes}
                  isMulti={true}
                />
              </div>
              <div className="form-label-group col">
                <Select
                  onChange={setSelectedSportsList}
                  value={selectedSportsList}
                  options={options.sports}
                  styles={selectStyles}
                  placeholder={`Select ${constantNames.Sports}`}
                  name={constantNames.Sports}
                  isMulti={true}
                />
              </div>
              <div className="form-label-group col">
                <Select
                  onChange={setSelectedCategoriesList}
                  value={selectedCategoriesList}
                  options={options.categories}
                  styles={selectStyles}
                  placeholder={`Select ${constantNames.Categories}`}
                  name={constantNames.Categories}
                  isMulti={true}
                />
              </div>
              <div className="form-label-group col">
                <Select
                  onChange={setSelectedTournamentsList}
                  value={selectedTournamentsList}
                  options={options.tournaments}
                  styles={selectStyles}
                  placeholder={`Select ${constantNames.Tournaments}`}
                  name={constantNames.Tournaments}
                  isMulti={true}
                />
              </div>
              <div className="form-label-group col">
                <Select
                  onChange={setSelectedSportEventsList}
                  value={selectedSportEventsList}
                  options={options.events}
                  styles={selectStyles}
                  placeholder={`Select ${constantNames.Events}`}
                  name={constantNames.Events}
                  isMulti={true}
                />
              </div>
            </>
          ) : (
            <>
              <div className="form-label-group col">
                <Select
                  onChange={setSelectedCasinoGamesList}
                  value={selectedCasinoGamesList}
                  options={options.casinoGames}
                  styles={selectStyles}
                  placeholder={'Select casino games'}
                  name={constantNames.CasinoGames}
                  isMulti={true}
                />
              </div>
              <div className="form-label-group col">
                <Select
                  onChange={setSelectedCasinoProvidersList}
                  value={selectedCasinoProvidersList}
                  options={options.casinoProviders}
                  styles={selectStyles}
                  placeholder={'Select casino providers'}
                  name={constantNames.CasinoProviders}
                  isMulti={true}
                />
              </div>
            </>
          )}
        </div>
        <hr />
        <div className="form-row">
          <Button onClick={createBonus} cssClass="efb-btn__main col-md">
            {config ? 'Update' : 'Create'} Bonus
          </Button>
        </div>
        <hr />
      </Fragment>
    );
  }
);

CreateSportBonus.propTypes = {
  clearDate: PropTypes.func,
  currencies: PropTypes.array,
  getFilteredTransaction: PropTypes.func,
  payments: PropTypes.array,
  states: PropTypes.array,
  types: PropTypes.array,
  casinoGames: PropTypes.array,
  casinoProviders: PropTypes.array,
};

const mapDispatchToProps = {
  getCategoryById: SctManagerActions.getCategoryById,
  getTournamentById: SctManagerActions.getTournamentById,
  getEventById: SctManagerActions.getEventById,
};

export default connect(null, mapDispatchToProps)(CreateSportBonus);
