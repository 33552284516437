import React, { useMemo } from 'react';

import classes from './DetailsModal.module.scss';
import Modal from '../../NewModal/Modal';
import Switch from '../../Form/Switch/Switch';
import { useFetch } from '../../../hooks';
import Button from '../../Layout/Button';

const MarketDetailsModal = ({ onClose, market }) => {
  const { data: marketType } = useFetch(
    `/market/view/market-type/admin/${market.marketType.id}`
  );
  const margin = useMemo(() => {
    let result = 0;
    market.outcomeBetInfos.forEach(betInfo =>
      betInfo.outcomeOdds.forEach(outcome => (result += 1 / outcome.odds))
    );
    return (result - 1) * 100;
  }, [market]);

  if (!marketType) {
    return null;
  }

  return (
    <Modal isActive={true} onClose={onClose}>
      <div className="p-3">
        <div className={classes.header}>
          <p>
            Market Edit: <strong>{market.names.en.name}</strong>
          </p>
          <p className="mx-3">[{market.feedProviderId}]</p>
        </div>

        <div className={classes.info}>
          <div>
            <div>Market Type:</div>
            <div>{marketType.descriptionTemplates.en.name}</div>
          </div>
        </div>

        <table className={classes.whiteTable}>
          <thead>
            <tr>
              <th className="w-50">Selections</th>
              <th>{margin.toFixed(2)}%</th>
              <th>
                Prot. Price <Switch isChecked />
              </th>
            </tr>
          </thead>
          <tbody>
            {market.outcomeBetInfos.map(betInfo => (
              <tr key={betInfo.id}>
                <td>{betInfo.names.en.name}</td>
                <td>{betInfo.outcomeOdds[0].odds}</td>
                <td>
                  <Switch isChecked />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className={classes.buttons}>
          <Button cssClass={`btn ${classes.btnSuccess}`}>OK</Button>
          <Button cssClass={`btn ${classes.btnSecondary}`}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
};

export default MarketDetailsModal;
