import React, { useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';

import BetScrollerMenu from './BetScrollerMenu';
import Button from '../../../components/Layout/Button';
import BetScrollerMenuManager from './BetScrollerMenuManager';
import Modal from '../../../components/NewModal/Modal';

import { useMount } from '../../../hooks';
import {
  filterMenu,
  menuesForSelectAll,
  validateBetScroller,
} from './BetScrollerUtils';

import {
  getCMSData,
  getCustomerData,
  getRiskCategoryData,
  getRiskManagementData,
} from '../../../selectors';

import * as BetScrollerActions from '../../../redux/actions/riskManagement/betScroller';
import * as RiskCategoryActions from '../../../redux/actions/riskManagement/RiskCategory';
import * as EventSearchActions from '../../../redux/actions/cms/eventSearch';
import * as CustomerDataActions from '../../../redux/actions/customer/customerData';

const BetScrollerFilters = ({
  ownId,
  openFilter,
  betScrollersCopy,
  bUnits,
  sports,
  systemTypes,
  riskCategories,
  betScrollerEvents,
  createBetScrollerProfile,
  currentBetScroller,
  setCurrentBetScroller,
  setBetScrollerForTable,
  updateBScrollerProfile,
  deleteBetScroller,
  setOpenFilter,
  getBetScrollerUnits,
  getRiskCategories,
  getSports,
  getBetTypes,
  getBetScrollerEvents,
  addNotificationMessage,
}) => {
  const [menuPosition, setMenuPosition] = useState(0);

  useMount(() => {
    getBetScrollerUnits();
    getRiskCategories();
    getSports();
    getBetTypes();
    getBetScrollerEvents();
  });

  const currentMenu = useMemo(
    () => menuesForSelectAll[filterMenu[menuPosition]],
    [menuPosition]
  );

  const closeFilter = useCallback(() => {
    setOpenFilter(false);
    setMenuPosition(0);
  }, [setOpenFilter]);

  const createAndUpdateBScroller = useCallback(() => {
    if (
      validateBetScroller(
        betScrollersCopy,
        currentBetScroller,
        addNotificationMessage
      )
    ) {
      if (currentBetScroller.id) {
        updateBScrollerProfile(currentBetScroller);
      } else {
        createBetScrollerProfile(currentBetScroller);
      }
      setBetScrollerForTable(currentBetScroller);
      closeFilter();
    }
  }, [
    betScrollersCopy,
    currentBetScroller,
    addNotificationMessage,
    setBetScrollerForTable,
    closeFilter,
    updateBScrollerProfile,
    createBetScrollerProfile,
  ]);

  const deleteBScroller = useCallback(() => {
    deleteBetScroller(currentBetScroller.id);
    closeFilter();
  }, [deleteBetScroller, currentBetScroller, closeFilter]);

  const selectAllByMenu = useCallback(
    selectedMenu => {
      switch (selectedMenu) {
        case 'businessUnits':
          return bUnits.map(bUnit => bUnit.name);
        case 'sports':
          return sports.map(sport => ({
            id: sport.id,
            code: sport.names.en.name,
          }));
        case 'betTypes':
          return systemTypes.map(type => type);
        case 'riskCategories':
          return riskCategories.map(risk => risk.id);
        default:
          return '';
      }
    },
    [bUnits, riskCategories, sports, systemTypes]
  );

  const selectAll = useCallback(() => {
    const allSelected = selectAllByMenu(currentMenu);
    setCurrentBetScroller({
      ...currentBetScroller,
      [currentMenu]:
        currentBetScroller[currentMenu].length !== allSelected.length
          ? allSelected
          : [],
    });
  }, [currentBetScroller, currentMenu, selectAllByMenu, setCurrentBetScroller]);

  return (
    <Modal
      isActive={openFilter}
      onClose={closeFilter}
      className={'form-row modal-bet-scroller-content py-2'}
    >
      <BetScrollerMenu
        filterMenu={filterMenu}
        menuPosition={menuPosition}
        setMenuPosition={setMenuPosition}
        onChangeName={setCurrentBetScroller}
        betScroller={currentBetScroller}
        ownId={ownId}
      />
      <div
        className={`col-md-8 d-flex flex-lg-column justify-content-between py-2 pr-3 ${
          currentBetScroller.createdUserId !== ownId && currentBetScroller.id
            ? 'not-allowed'
            : ''
        }`}
      >
        <BetScrollerMenuManager
          menuPosition={menuPosition}
          currentBetScroller={currentBetScroller}
          setCurrentBetScroller={setCurrentBetScroller}
          bUnits={bUnits}
          sports={sports}
          systemTypes={systemTypes}
          riskCategories={riskCategories}
          betScrollerEvents={betScrollerEvents}
        />

        <div className="d-flex align-self-end">
          {menuesForSelectAll[filterMenu[menuPosition]] ? (
            <div className="mr-1 ml-1 select-all" onClick={selectAll}>
              {currentBetScroller[currentMenu].length !==
              selectAllByMenu(currentMenu).length
                ? 'select all'
                : 'deselect all'}
            </div>
          ) : null}
          <Button
            cssClass="mr-1 ml-1 bet-scroller-btn ok-btn btn"
            onClick={createAndUpdateBScroller}
          >
            OK
          </Button>
          <Button
            cssClass="mr-1 ml-1 bet-scroller-btn cancel-btn btn"
            onClick={closeFilter}
          >
            Cancel
          </Button>
          {currentBetScroller.id ? (
            <Button
              cssClass="mr-1 ml-1 bet-scroller-btn delete-btn btn"
              onClick={deleteBScroller}
            >
              Delete
            </Button>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  bUnits: getRiskManagementData(state).betScrollerReducer.bUnits,
  betScrollerEvents: getRiskManagementData(state).betScrollerReducer
    .betScrollerEvents,
  riskCategories: getRiskCategoryData(state).riskCategories,
  systemTypes: getCustomerData(state).customerDataReducer.betTypes,
  sports: getCMSData(state).eventSearchReducer.sports,
});

const mapDispatchToProps = {
  getBetScrollerUnits: BetScrollerActions.getBetScrollerUnits,
  getBetScrollerEvents: BetScrollerActions.getBetScrollerEvents,
  updateBScrollerProfile: BetScrollerActions.updateBScrollerProfile,
  createBetScrollerProfile: BetScrollerActions.createBetScrollerProfile,
  deleteBetScroller: BetScrollerActions.deleteBetScroller,
  getRiskCategories: RiskCategoryActions.getRiskCategories,
  getSports: EventSearchActions.getSports,
  getBetTypes: CustomerDataActions.getBetTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(BetScrollerFilters));
