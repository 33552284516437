import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createLoadingSelector } from '../selectors/index';

import Spinner from '../components/Layout/Spinner/Spinner';
import FormErrors from '../components/Form/FormErrors';
import LoginHeader from '../components/Login/LoginHeader';
import Input from '../components/Form/Input';

export class Login extends Component {
  state = {
    username: 'serviceuser',
    password: '123456',
    formErrors: { email: '', username: '', password: '', form: '' },
    emailValid: false,
    passwordValid: false,
    usernameValid: false,
    formValid: false,
  };

  validateField = (fieldName, value) => {
    //TODO: Cleanup validation logic and to create HOC for handling forms
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let usernameValid = this.state.usernameValid;
    let passwordValid = this.state.passwordValid;
    let formValid = this.state.formValid;

    switch (fieldName) {
      case 'username':
        usernameValid = value.length >= 4;
        fieldValidationErrors.username = usernameValid
          ? ''
          : 'Username is too short';
        break;
      case 'email':
        //for later use
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
        break;
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid
          ? ''
          : 'Password is too short';
        break;
      case 'form':
        fieldValidationErrors.form = formValid
          ? ''
          : 'Form is not Valid please check your input';
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
      },
      this.validateForm
    );
  };

  validateForm = () => {
    this.setState({
      formValid: this.state.usernameValid && this.state.passwordValid,
    });
  };

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  handleSubmit = (e, username, password) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });

    if (username.length >= 4 && password.length >= 6) {
      // Calling login action
      this.props.authentication(username, password, this.props.history);
    }
  };

  render() {
    const { authErrorMessage, isLoading } = this.props;
    if (authErrorMessage) {
      console.log('ERROR: ' + authErrorMessage);
    }
    return (
      <Fragment>
        <main className="efb-login">
          <div className="efb-container-column">
            <section className="efb-login__form">
              <LoginHeader />
              <Spinner
                size="50"
                content={<div> Loading... </div>}
                active={isLoading}
              />
              {/*TODO: To take out the form in it's own Component*/}
              {!isLoading && (
                <form
                  name="form"
                  onSubmit={event =>
                    this.handleSubmit(
                      event,
                      this.state.username,
                      this.state.password
                    )
                  }
                >
                  <FormErrors formErrors={this.state.formErrors} />
                  <Input
                    type="text"
                    value={this.state.username}
                    onChange={event => this.handleUserInput(event)}
                    name="username"
                    placeholder="User Name"
                  />
                  <Input
                    type="password"
                    value={this.state.password}
                    onChange={event => this.handleUserInput(event)}
                    name="password"
                    placeholder="Password"
                  />
                  <button className="efb-btn__main">Login Action</button>
                </form>
              )}
            </section>
            {/* All static Texts to be moved to translations file in config folder */}
            <div className="efb-copyrights">
              All rights reserved. Efbet - 2018
            </div>
          </div>
        </main>
        <div className="efb-decorator" />
        {/* { authData.isLogedIn && <Redirect to='/adm' /> } */}
      </Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector(['AUTH']);

const mapStateToProps = state => {
  let isLoading = loadingSelector(state);
  return {
    isLoading,
  };
};

export default connect(mapStateToProps, null)(Login);
