import React, { memo } from 'react';
// import { classer } from '../../../../services/helpers/helpers';

export const IconSearch = ({
  className,
  fill,
  height,
  width,
  ...otherProps
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 402.94 396.44"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <path d="M151,302C67.74,302,0,234.26,0,151S67.74,0,151,0,302,67.74,302,151,234.26,302,151,302Zm0-272A121,121,0,1,0,272,151,121.14,121.14,0,0,0,151,30Z" />
      <rect
        x="308.38"
        y="213.4"
        width="32"
        height="208.95"
        rx="16"
        transform="translate(-129.76 322.47) rotate(-45)"
      />
    </svg>
  );
};

export default memo(IconSearch);
