import React, { Fragment, useCallback, useState } from 'react';
import Table from '../../../components/Table/Table';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal/Modal';
import Alert from '../../../components/Layout/Alert/Alert';

const columns = [
  { name: 'Market', value: 'lang', type: 'string', sortable: true },
  { name: 'Market status', value: 'lang', type: 'string' },
  { name: 'View outcomes', value: 'outcomes', type: 'string' },
];

const EventMarkets = ({
  sportEventMarkets,
  currentEventName,
  eventMarketsTable,
  loading,
}) => {
  const [outcomesModalActive, setOutcomesModalActive] = useState(false);
  const [outcomeBetInfos, setOutcomeBetInfos] = useState([]);
  const [outcomeColumns, setOutcomeColumns] = useState([]);
  const [currentMarketName, setMarketName] = useState('');

  const viewOutcomes = useCallback((outcomes, marketName) => {
    setOutcomesModalActive(true);
    const outcomesArr = Object.keys(outcomes).reduce(
      (acc, val, index) => [...acc, Object.values(outcomes)[index]],
      []
    );
    const outcomeTableColumns = Object.keys(outcomes).reduce(
      (acc, val, index) => [
        ...acc,
        { name: Object.values(outcomes)[index].names.en.name, type: 'string' },
      ],
      []
    );
    setOutcomeColumns(outcomeTableColumns);
    setOutcomeBetInfos(outcomesArr);
    setMarketName(marketName);
  }, []);

  const closeModal = useCallback(() => {
    setOutcomesModalActive(false);
  }, []);

  return (
    <div className="event-markets">
      <Modal
        headerText={currentMarketName}
        isActive={outcomesModalActive}
        onClose={closeModal}
      >
        <div className="modal-wrapper">
          <Table
            columns={outcomeColumns}
            data={outcomeBetInfos}
            tableType="eventSearchMarketOutcomes"
            tableStyle="sortable table table-hover table-striped efb-table events-search-table"
          />
        </div>
      </Modal>
      {sportEventMarkets.length > 0 ? (
        <Fragment>
          <br />
          <h3 className="efb-h3">
            Event Markets <b>{currentEventName}</b>
          </h3>
          <Table
            columns={columns}
            data={sportEventMarkets}
            viewOutcomes={viewOutcomes}
            tableType="eventSearchMarketTable"
            tableStyle="sortable table table-hover table-striped efb-table events-search-table"
          />
        </Fragment>
      ) : null}
      {eventMarketsTable && !loading ? (
        sportEventMarkets.length === 0 ? (
          <div className="no-markets-msg">
            <Alert type="info">
              <i className="fa fa-exclamation-triangle" /> There is no markets
              for <b>{currentEventName}</b>
            </Alert>
          </div>
        ) : null
      ) : null}
    </div>
  );
};

EventMarkets.propTypes = {
  sportEventMarkets: PropTypes.array,
  currentEventName: PropTypes.string,
  eventMarketsTable: PropTypes.bool,
  loading: PropTypes.bool,
};

export default EventMarkets;
