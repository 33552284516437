import React from 'react';

export const Affiliate = (props) => {
    return (
	<span className='efb-icon'>
    	<svg className={`efb-icon--${props.size} ${props.cssClass}`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 128 128" >
	
        <path fill="#BDC3C7" d="M60.3,51l-14.9,8.1l0,29.8l27,15.4l3.4-6.1l-27-15.4l0-17.5l14.9-8.1L60.3,51z"/>

        <path fill="#ECF0F1" d="M81.1,57.8l-10.8,5.9c-0.6,0.3-1.2,0.8-1.7,1.2c6.3,5.4,14.4,8.6,23.3,8.6c8.8,0,16.9-3.2,23.2-8.5
            c-0.6-0.5-1.2-0.9-1.9-1.3L101.5,58c-1.5-0.8-2.4-2.3-2.4-4v-4.6c0.3-0.4,0.7-0.9,1.1-1.4c1.6-2.2,2.8-4.7,3.6-7.3
            c1.5-0.5,2.6-1.8,2.6-3.5v-4.9c0-1.1-0.5-2-1.2-2.7v-7.1c0,0,1.4-11-13.3-11s-13.3,11-13.3,11v7.1c-0.7,0.7-1.2,1.6-1.2,2.7v4.9
            c0,1.3,0.7,2.4,1.7,3.1c1.2,5.3,4.4,9.1,4.4,9.1v4.5C83.4,55.6,82.5,57.1,81.1,57.8z"/>

        <path fill="#556080" d="M92.4,0.6C72.5,0.2,56,16.3,55.7,36.5C55.5,47.9,60.5,58.2,68.6,65c0.5-0.5,1.1-0.9,1.7-1.2l10.8-5.9
            c1.4-0.8,2.3-2.3,2.3-3.9v-4.5c0,0-3.2-3.8-4.4-9.1c-1-0.7-1.7-1.8-1.7-3.1v-4.9c0-1.1,0.5-2,1.2-2.7v-7.1c0,0-1.4-11,13.3-11
            s13.3,11,13.3,11v7.1c0.7,0.7,1.2,1.6,1.2,2.7v4.9c0,1.6-1.1,3-2.6,3.5c-0.8,2.6-2,5.1-3.6,7.3c-0.4,0.6-0.8,1-1.1,1.4V54
            c0,1.7,0.9,3.2,2.4,4l11.6,5.8c0.7,0.4,1.3,0.8,1.9,1.3c7.8-6.6,12.8-16.4,13-27.4C128.3,17.6,112.4,0.9,92.4,0.6z"/>

        <path fill="#ECF0F1" d="M17.6,88.3l-7.5,4.1c-0.4,0.2-0.8,0.5-1.2,0.9c4.4,3.7,10,5.9,16.2,5.9c6.1,0,11.7-2.2,16.1-5.8
            c-0.4-0.3-0.8-0.6-1.3-0.9l-8-4c-1-0.5-1.7-1.6-1.7-2.7v-3.2c0.2-0.3,0.5-0.6,0.8-1c1.1-1.5,1.9-3.3,2.5-5c1-0.3,1.8-1.3,1.8-2.4
            v-3.4c0-0.7-0.3-1.4-0.8-1.9V64c0,0,1-7.6-9.3-7.6S15.9,64,15.9,64v4.9C15.3,69.3,15,70,15,70.7v3.4c0,0.9,0.5,1.7,1.2,2.1
            c0.8,3.7,3,6.3,3,6.3v3.1C19.2,86.7,18.6,87.7,17.6,88.3z"/>

        <path fill="#556080" d="M25.6,49.2C11.7,49,0.2,60.1,0,74c-0.1,7.9,3.4,15,9,19.7c0.4-0.3,0.8-0.6,1.2-0.8l7.5-4.1
            c1-0.5,1.6-1.6,1.6-2.7v-3.1c0,0-2.2-2.6-3-6.3c-0.7-0.5-1.2-1.2-1.2-2.1v-3.4c0-0.7,0.3-1.4,0.8-1.9v-4.9c0,0-1-7.6,9.3-7.6
            s9.3,7.6,9.3,7.6v4.9c0.5,0.5,0.8,1.1,0.8,1.9v3.4c0,1.1-0.8,2.1-1.8,2.4c-0.6,1.8-1.4,3.5-2.5,5c-0.3,0.4-0.5,0.7-0.8,1v3.2
            c0,1.2,0.7,2.2,1.7,2.7l8,4c0.5,0.2,0.9,0.5,1.3,0.9c5.4-4.5,8.9-11.3,9.1-18.9C50.6,60.9,39.5,49.5,25.6,49.2z"/>

        <circle fill="#FEB719" cx="88.9" cy="109.2" r="18.2"/>
        <ellipse fill="#FFFFFF" cx="89.1" cy="108.9" rx="11.1" ry="10.6"/>

    	</svg>
        
		{props.children}
	</span>
    )
}

export default Affiliate;