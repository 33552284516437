import React from 'react';

import Table from '../../../components/NewTable/Table';
import { Button } from '../../../components/Layout';

const CurrentEvents = ({
  rowClassName,
  topEvents,
  removeTopEvent,
  language,
}) => {
  const columnConfig = [
    { name: 'Event', display: entity => entity.highlightName },
    { name: 'Sport', display: entity => entity.sportName },
    { name: 'Country', display: entity => entity.categoryName },
    { name: 'Tournament', display: entity => entity.tournamentName },
    {
      name: 'Banner',
      display: entity => (
        <img src={entity.uri} width={100} height={50} alt="" />
      ),
    },
    {
      name: '',
      display: entity => (
        <Button
          cssClass="btn btn-danger btn-md mr-3"
          onClick={() =>
            removeTopEvent(entity.highlightId, language, topEvents)
          }
        >
          Remove event from Top
        </Button>
      ),
    },
  ];

  if (!topEvents.length) {
    return <h4 className="text-center">No top events</h4>;
  }

  return (
    <Table
      rowClassName={rowClassName}
      data={topEvents}
      columns={columnConfig}
    />
  );
};

export default CurrentEvents;
