import React from 'react';
import Button from '../Layout/Button';
import Delete from '../Icons/Delete';
import Filter from '../Icons/Filter';
import Add from '../Icons/Add';
import PropTypes from 'prop-types';
import Close from '../Icons/Close';

const Toolbar = ({onClickFilter, onClickNew, hideNewBtn, usedFilters, resetUsedFilters, removeFilter}) => (
    <section className='efb-toolbar-filter'>
        <div className='efb-btn-group'>
            <Button cssClass='efb-btn__light' onClick={() => onClickFilter()}>
                <Filter size='sm'>
                    <span className='efb-icon__text'>Filter</span>
                </Filter>
            </Button>
            <section className='efb-toolbar-filter__info'>
                <span>Filtered by: </span>
	            {
	            	usedFilters && Object.keys(usedFilters).length !== 0 ? (
	            		Object.keys(usedFilters).map((filter, idx) => {
							return (
								<div className='efb-span' key={idx}>
									<div className='efb-span__text'>
										{filter}
									</div>
									<div onClick={() => removeFilter(filter)} className='efb-span__icon'>
										<Close size='xxs'/>
									</div>
								</div>
							)
			            })
		            ) : (
			            <strong>No filters applied</strong>
		            )
	            }

            </section>
            {
                resetUsedFilters ? (<Button onClick={() => resetUsedFilters()} cssClass='efb-btn__light'><Delete size='sm'/></Button>) :
                    (<Button cssClass='efb-btn__light'><Delete size='sm'/></Button>)
            }

        </div>
        {
            !hideNewBtn ? (
	            <Button cssClass='efb-btn__light' onClick={() => onClickNew()}>
		            <Add size='sm'>
			            <span className='efb-icon__text'>New</span>
		            </Add>
	            </Button>
            ) : null
        }

    </section>
);

Toolbar.propTypes = {
	onClickFilter: PropTypes.func,
	onClickNew: PropTypes.func,
    hideNewBtn: PropTypes.bool,
	usedFilters: PropTypes.object,
	resetUsedFilters: PropTypes.func
};

export default Toolbar;