import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const SettlementMarketDetails = React.memo(({ tableData: outcomes }) => {
	const mapEventMarkets = useCallback(
		(outcome, index) => (
			<tr key={outcome.outcomeId}>
				<td>{index + 1}.</td>
				<td>{outcome.outcomeName}</td>
				<td>{outcome.outcomeResult}</td>
				<td>{outcome.lastUpdatedOn}</td>
				<td>
					<span className={`bool-info-circle centered ${outcome.winning ? 'true' : 'false'}`} />
				</td>
			</tr>
		),
		[]
	);

	return outcomes.map(mapEventMarkets);
});

SettlementMarketDetails.propTypes = {
	tableData: PropTypes.array
};

export default SettlementMarketDetails;
