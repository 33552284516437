import React, { useMemo, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getFilteredBetHistory } from '../../../redux/actions/riskManagement/betHistory';
import { getRiskManagementData } from '../../../selectors/index';
import { useFetch, useUpdate } from '../../../hooks';

import HistoryModal from '../../../components/HistoryModal/HistoryModal';
import Header from './Header';

const BetHistory = ({ getFilteredBetHistory, betHistory }) => {
  const [filters, setFilters] = useState({});
  const [currentCustomerId, setCurrentCustomerId] = useState('');

  const { data: betStatuses } = useFetch('/betslip/view/admin/bet-statuses');
  const { data: betTypes } = useFetch('/betslip/view/admin/system-types');

  const customerId = useMemo(
    () => new URLSearchParams(window.location.search).get('customerId'),
    []
  );

  const convertCents = useCallback(num => Number(num / 100).toFixed(2), []);

  const [activeColumns, setActiveColumns] = useState([
    'BC',
    'Placem. Time',
    'Bet Ref.',
    'Win Unit Stake',
    'Bet Type',
    'Total Stake',
    'Bet Return',
    'Total Return',
    'Bet Status',
    'Ref. State',
    'Selection Name',
    'Price',
    'Leg Status',
    'Leg Sett. Time',
    'Market',
    'Event',
  ]);

  const allColumnOptions = useMemo(
    () => [
      {
        name: 'BC',
        display: entity => (
          <i
            className={`fa fa-${entity.BC === 'WEB' ? 'desktop' : 'mobile'}`}
          />
        ),
      },
      {
        name: 'Placem. Time',
        display: entity =>
          moment(entity.placementTime).format('DD/MM/YYYY HH:mm:ss'),
      },
      { name: 'Bet Ref.', display: entity => entity.betReference },
      {
        name: 'Win Unit Stake',
        display: entity => convertCents(entity.unitStake),
      },
      { name: 'Bet Type', display: entity => entity.betType },
      {
        name: 'Total Stake',
        display: entity => convertCents(entity.totalStake),
      },
      {
        name: 'Bet Return',
        display: entity => convertCents(entity.selections[0].betReturn),
      },
      {
        name: 'Total Return',
        display: entity => convertCents(entity.totalReturn),
      },
      {
        name: 'Bet Status',
        display: entity => entity.selections[0].betStatus,
      },
      { name: 'Ref. State', display: entity => null },
      {
        name: 'Selection Name',
        display: entity => entity.selections[0].selectionName,
      },
      {
        name: 'Price',
        display: entity => entity.selections[0].price,
      },
      {
        name: 'Leg Status',
        display: entity => entity.selections[0].legStatus,
      },
      {
        name: 'Leg Sett. Time',
        display: entity =>
          entity.selections[0].legSettlementTime
            ? moment(entity.selections[0].legSettlementTime).format(
                'DD/MM/YYYY'
              )
            : null,
      },
      {
        name: 'Market',
        display: entity => entity.selections[0].market,
      },
      {
        name: 'Event',
        display: entity => entity.selections[0].event,
      },
      {
        name: 'Bet ID',
        display: entity => entity.betReference,
      },
    ],
    [convertCents]
  );

  const betHistoryColumns = useMemo(
    () => [
      ...allColumnOptions
        .map(option =>
          activeColumns.indexOf(option.name) > -1 ? option : null
        )
        .filter(option => option),
      {
        name: '+ Add',
        type: 'columnFilter',
        options: allColumnOptions,
        activeOptions: activeColumns,
        onColumnFilterChange: setActiveColumns,
      },
    ],
    [activeColumns, allColumnOptions]
  );

  const statuses = useMemo(
    () =>
      betStatuses
        ? betStatuses.map(status => ({ value: status, label: status }))
        : [],
    [betStatuses]
  );

  const types = useMemo(
    () =>
      betTypes ? betTypes.map(type => ({ value: type, label: type })) : [],
    [betTypes]
  );

  const filtersConfig = useMemo(
    () => [
      {
        name: 'betReference',
        label: 'Slip/Bet Ref.',
        type: 'search',
      },
      {
        ...(!customerId
          ? { name: 'customerId', label: 'Customer Id', type: 'input' }
          : {}),
      },
      {
        name: 'status',
        label: 'Status',
        type: 'checkbox',
        options: statuses,
        singleValue: false,
        selectAllButton: true,
      },
      {
        name: 'betType',
        label: 'Bet Type',
        type: 'checkbox',
        options: types,
        singleValue: false,
        selectAllButton: true,
      },
      {
        label: 'Bets',
        type: 'dates',
        fields: [
          {
            name: 'placementTime',
            label: 'Bet Placed',
            default: moment(),
          },
          {
            name: 'betSettled',
            label: 'Bet Settled',
            default: moment(),
          },
        ],
      },
    ],
    [customerId, statuses, types]
  );

  useUpdate(() => {
    const checkId = customerId || filters.customerId;
    if (checkId) {
      getFilteredBetHistory({
        customerId: checkId,
        ...filters,
        placementTime: filters.placementTime
          ? moment(filters.placementTime)
              .startOf('day')
              .utc(true)
              .toISOString()
          : '',
        betSettled: filters.betSettled
          ? moment(filters.betSettled)
              .endOf('day')
              .utc(true)
              .toISOString()
          : '',
      });
      setCurrentCustomerId(checkId);
    } else {
      setCurrentCustomerId('');
    }
  }, [filters]);

  return (
    <HistoryModal
      columns={betHistoryColumns}
      history={betHistory.bets || []}
      filtersConfig={filtersConfig}
      onFiltersChange={setFilters}
      openMenu={true}
      headerTitle={
        <Header customerId={currentCustomerId} title={'Bet History: '} />
      }
      footerData={betHistory}
    />
  );
};

const mapStateToProps = state => ({
  betHistory: getRiskManagementData(state).betHistoryReducer.filteredBetHistory,
});

const mapDispatchToProps = { getFilteredBetHistory };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(BetHistory));
