import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { connect } from 'react-redux';

import * as CustomerDataActions from '../../../../../redux/actions/customer/customerData';
import * as RiskCategoryActions from '../../../../../redux/actions/riskManagement/RiskCategory';
import * as ConfigurationActions from '../../../../../redux/actions/riskManagement/configuration';
import classes from './FiltersMenu.module.scss';
import RadioButton from '../../../../../components/Form/RadioButton/RadioButton';
import Input from '../../../../../components/Form/Input';
import CheckboxDiv from '../../../../../components/Form/CheckboxDiv/CheckboxDiv';
import CalendarDayPicker from '../../../../../components/CalendarDayPicker/CalendarDayPicker';
import { useStateForSelect, useFetch, useUpdate } from '../../../../../hooks';
import {
  getCustomerData,
  getRiskCategoryData,
  getRiskConfigurationData,
} from '../../../../../selectors';
import { IconCalendar } from '../../../../../components/Icons/UI/ui/IconCalendar';
import { underlinedSelectStyles } from '../../../../../utils/selectStyles';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

const FiltersMenu = ({ opened }) => {
  const [openedFromCalendar, setOpenedFromCalendar] = useState(false);
  const [openedToCalendar, setOpenedToCalendar] = useState(false);
  // Todo: will be used later
  // const [ hours, setHours ] = useState('');
  const [fromDateActive, setFromDateActive] = useState(false);
  const [toDateActive, setToDateActive] = useState(false);
  const [from, setFrom] = useState(Date.now());
  const [to, setTo] = useState(Date.now());
  const [transactionTypesList, setTransactionTypesList] = useStateForSelect('');

  const { data: transactionTypes } = useFetch(
    '/account/view/admin/transaction-types'
  );
  useUpdate(() => {
    if (transactionTypes) {
      const list = transactionTypes.reduce(
        (acc, val) => [...acc, { label: val, value: val }],
        []
      );
      setTransactionTypesList(list);
    }
  }, [transactionTypes]);

  useUpdate(() => {
    if (!fromDateActive) setFrom(Date.now());
    if (!toDateActive) setTo(Date.now());
  }, [fromDateActive, toDateActive]);

  const handleDayClick = useCallback((day, direction) => {
    if (direction === 'from') {
      setFrom(day);
      setOpenedFromCalendar(false);
    } else {
      setTo(day);
      setOpenedToCalendar(false);
    }
  }, []);

  const checkIsDateTodayOrYesterday = useCallback(date => {
    if (
      moment(date).format('DD.MM.YYYY') ===
      moment(Date.now()).format('DD.MM.YYYY')
    ) {
      return 'Today';
    } else if (
      moment(Date.now()).format('DD') - moment(date).format('DD') ===
      1
    ) {
      return 'Yesterday';
    }
    return moment(date).format('DD.MM.YYYY');
  }, []);

  return (
    <div
      style={{
        marginLeft: opened ? 0 : -315,
      }}
      className={classes.menu}
    >
      <div className={`${classes.account} ${classes.divider}`}>
        <label>Account:</label>
        <div className={classes.radioGroup}>
          <RadioButton id="all" label="All" name="all" />
          <RadioButton id="main" label="Main" name="main" />
          <RadioButton id="free" label="Free" name="free" />
        </div>
      </div>

      <div className={`${classes.transaction} ${classes.divider}`}>
        <label>Transaction</label>
        <div className={classes.labelInput}>
          <label className={classes.missing}>Class</label>
          <Select placeholder={'Payouts'} styles={underlinedSelectStyles} />
        </div>
        <div className={classes.labelInput}>
          <label>Type</label>
          <Select
            styles={underlinedSelectStyles}
            options={transactionTypesList}
            placeholder="Transaction Type"
          />
        </div>
      </div>

      <div className={`${classes.inlineInputsWrapper} ${classes.divider}`}>
        <div className={`${classes.labelInput}`}>
          <label className={classes.missing}>Reference</label>
          <Input customInput className={'efb-input__underlined'} />
        </div>
      </div>
      <div className={`${classes.inlineInputsWrapper} ${classes.divider}`}>
        <div className={`${classes.labelInput}`}>
          <label className={classes.missing}>Description</label>
          <Input customInput className={'efb-input__underlined'} />
        </div>
      </div>

      <div className={`${classes.txTime} ${classes.divider}`}>
        <label>TX time</label>
        <div className={classes.labelInput}>
          <label>From:</label>
          <div className={classes.dateBox}>
            <CheckboxDiv
              isChecked={fromDateActive}
              onChange={() => setFromDateActive(!fromDateActive)}
              value={fromDateActive}
              name={'fromDate'}
              id={'fromDate'}
            />
            {openedFromCalendar && fromDateActive ? (
              <CalendarDayPicker
                handleDayClick={e => handleDayClick(e, 'from')}
                from={from}
              />
            ) : null}
            <div
              onClick={() => {
                setOpenedFromCalendar(
                  fromDateActive ? !openedFromCalendar : ''
                );
              }}
            >
              <p
                className={classes.pickedTime}
                style={{
                  color: !fromDateActive ? 'rgba(112, 112, 112, 0.5)' : '',
                }}
              >
                {checkIsDateTodayOrYesterday(from)}
              </p>
              <IconCalendar width={15} />
            </div>
          </div>
        </div>
        <div className={classes.labelInput}>
          <label>To:</label>
          <div className={classes.dateBox}>
            <CheckboxDiv
              isChecked={toDateActive}
              onChange={() => setToDateActive(!toDateActive)}
              value={toDateActive}
              name={'toDate'}
              id={'toDate'}
            />
            {openedToCalendar && toDateActive ? (
              <CalendarDayPicker
                handleDayClick={e => handleDayClick(e, 'to')}
                from={from}
              />
            ) : null}
            <div
              onClick={() => {
                setOpenedToCalendar(toDateActive ? !openedToCalendar : '');
              }}
            >
              <p
                className={classes.pickedTime}
                style={{
                  color: !toDateActive ? 'rgba(112, 112, 112, 0.5)' : '',
                }}
              >
                {checkIsDateTodayOrYesterday(to)}
              </p>
              <IconCalendar width={15} />
            </div>
          </div>
        </div>
        <div className={classes.labelInput}>
          <label className={classes.missing}>Hourly:</label>
          <div className={classes.dateBox}>
            <CheckboxDiv />
            <div className={classes.timePickerWrapper}>
              <TimePicker
                showSecond={false}
                defaultValue={moment()}
                use12Hours={false}
                clearText={''}
                // onChange={(e) => setHours(moment(e).format('HH:mm'))}
                clearIcon={false}
                popupStyle={{
                  position: 'fixed',
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={`${classes.invoice} ${classes.divider}`}>
        <label className={classes.missing}>Invoice VS Payment</label>
        <div className={classes.checkboxWrapper}>
          <CheckboxDiv label="Matched" />
          <CheckboxDiv label="Unmatched" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  countries: getCustomerData(state).customerDataReducer.countries,
  riskCategories: getRiskCategoryData(state).riskCategories,
  systemTypes: getRiskConfigurationData(state).systemTypes,
});

const mapDispatchToProps = {
  getCountries: CustomerDataActions.getCountries,
  getRiskCategories: RiskCategoryActions.getRiskCategories,
  getSystemTypes: ConfigurationActions.getSystemTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersMenu);
