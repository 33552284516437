import React from 'react';

const IconFreeBet = ({
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 16.844 16.844" {...otherProps}
    >
      <g transform="translate(5.367 2.98)">
        <path
          d="M3008.695-758.118l-1.171,1.141a2.268,2.268,0,0,0-1.583-.825,1.113,1.113,0,0,0-.749.246.729.729,0,0,0-.288.567.786.786,0,0,0,.255.54,2.938,2.938,0,0,0,1.007.582,8.534,8.534,0,0,1,1.844.952,2.7,2.7,0,0,1,.764.946,2.809,2.809,0,0,1,.267,1.237,2.846,2.846,0,0,1-.285,1.268,2.528,2.528,0,0,1-.734.922,3.25,3.25,0,0,1-1.292.516v1.62h-1.141v-1.583a3.624,3.624,0,0,1-1.353-.4,5.761,5.761,0,0,1-1.419-1.128l1.153-1.183a3.081,3.081,0,0,0,2.147,1.183,1.257,1.257,0,0,0,.907-.367,1.164,1.164,0,0,0,.379-.864,1.171,1.171,0,0,0-.243-.734,2.656,2.656,0,0,0-.958-.643,11.65,11.65,0,0,1-1.953-1.059,2.644,2.644,0,0,1-.761-.88,2.237,2.237,0,0,1-.252-1.04,2.114,2.114,0,0,1,.667-1.568,2.494,2.494,0,0,1,1.686-.7v-.606h1.141v.685a3.7,3.7,0,0,1,1,.376A4.932,4.932,0,0,1,3008.695-758.118Z"
          transform="translate(-3002.817 759.98)"
          fill="#707070"
        />
      </g>
      <path
        d="M2982-759.369a8.431,8.431,0,0,1-8.422-8.422,8.431,8.431,0,0,1,8.422-8.422,8.432,8.432,0,0,1,8.422,8.422A8.432,8.432,0,0,1,2982-759.369Zm0-15.375a6.961,6.961,0,0,0-6.953,6.953,6.961,6.961,0,0,0,6.953,6.953,6.961,6.961,0,0,0,6.953-6.953A6.961,6.961,0,0,0,2982-774.744Z"
        transform="translate(-2973.581 776.213)"
        fill="#707070"
      />
    </svg>
  );
};

export default IconFreeBet;
