import React from 'react';
import _ from 'lodash';
import { riskValueTransform } from '../../../containers/RiskManagement/Configuration/SportTemplates/utils';
import Button from '../../Layout/Button';

const MarketTypesRow = ({
  tableData,
  marketSportViews,
  marketTypesViews,
  handleEdit,
  handleDelete,
}) => {
  const renderRow = item => {
    const { sportId, categoryId, tournamentId } = _.pick(item, [
      'sportId',
      'categoryId',
      'tournamentId',
    ]);
    let sportName = '',
      categoryName = '',
      tournamentName = '';

    const marketType = marketTypesViews.find(
      marketView => marketView.id === item.marketTypeId
    );

    if (tournamentId) {
      const sportView = marketSportViews.find(
        sportView => sportView.tournamentId === tournamentId
      );
      tournamentName = sportView.tournamentName;
      categoryName = sportView.categoryName;
      sportName = sportView.sportName;
    } else if (categoryId) {
      const sportView = marketSportViews.find(
        sportView => sportView.categoryId === categoryId
      );
      categoryName = sportView.categoryName;
      sportName = sportView.sportName;
    } else if (sportId) {
      const sportView = marketSportViews.find(
        sportView => sportView.sportId === sportId
      );
      sportName = sportView.sportName;
    }

    return (
      <tr key={item.id}>
        <td>{item.name}</td>
        <td>{tournamentName}</td>
        <td>{categoryName}</td>
        <td>{sportName}</td>
        <td>{marketType ? marketType.name : null}</td>
        <td>{riskValueTransform(item.riskControlParameters, 'MBL')}</td>
        <td>{riskValueTransform(item.riskControlParameters, 'MST')}</td>
        <td>{riskValueTransform(item.riskControlParameters, 'MAX_TAKEOUT')}</td>
        <td>{riskValueTransform(item.riskControlParameters, 'MSF_COUNT')}</td>
        <td>
          {riskValueTransform(item.riskControlParameters, 'MSF_DURATION')
            .slice(0, -1)
            .slice(2)}
        </td>
        <td>
          <Button
            onClick={() => handleEdit(item)}
            cssClass="btn btn-outline-secondary btn-xs mr-1"
          >
            <i className="fas fa-edit" /> Edit
          </Button>
          <Button
            onClick={() => handleDelete(item)}
            cssClass="btn btn-danger btn-xs"
          >
            <i className="fas fa-trash" /> Delete
          </Button>{' '}
        </td>
      </tr>
    );
  };

  return (
    <>
      {tableData.length !== 0 ? (
        tableData.map(renderRow)
      ) : (
        <tr>
          <td>No transactions found.</td>
        </tr>
      )}
    </>
  );
};

export default React.memo(MarketTypesRow);
