import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as ConfigurationActions from '../../../../redux/actions/riskManagement/configuration';
import * as UiActions from '../../../../redux/actions/ui';

import BetTypeConfig from './BetTypeConfig';
import Table from '../../../../components/Table/Table';
import { getRiskConfigurationData } from '../../../../selectors';

export class BetTypes extends PureComponent {
  componentDidMount() {
    this.props.getSystemTypes();
    this.props.getBetTypeConfigs();
  }

  columns = [
    { name: 'Name', value: 'name', type: 'string', sortable: true },
    { name: 'Status', value: 'status', type: 'buttons' },
    { name: 'Actions', value: '', type: 'buttons' },
  ];

  render() {
    const {
      systemTypes,
      betTypeConfigs,
      createBetTypeConfig,
      addNotificationMessage,
      updateBetTypeConfig,
      deleteBetTypeConfig,
    } = this.props;

    return (
      <div className="tab-pane card-body fade show active">
        <h3>Bet Types</h3>
        <BetTypeConfig
          systemTypes={systemTypes}
          addNotificationMessage={addNotificationMessage}
          createConfig={createBetTypeConfig}
          betTypeConfigs={betTypeConfigs}
        />
        <Table
          columns={this.columns}
          tableStyle={
            'sortable table table-hover table-striped efb-table efb-data-table'
          }
          tableType="betTypesTable"
          updateConfig={updateBetTypeConfig}
          deleteConfig={deleteBetTypeConfig}
          data={betTypeConfigs}
        />
      </div>
    );
  }
}

BetTypes.propTypes = {
  systemTypes: PropTypes.array,
};

const mapStateToProps = state => ({
  systemTypes: getRiskConfigurationData(state).systemTypes,
  betTypeConfigs: getRiskConfigurationData(state).betTypeConfigs,
});

const mapDispatchToProps = {
  getSystemTypes: ConfigurationActions.getSystemTypes,
  getBetTypeConfigs: ConfigurationActions.getBetTypeConfigs,
  createBetTypeConfig: ConfigurationActions.createBetTypeConfig,
  updateBetTypeConfig: ConfigurationActions.updateBetTypeConfig,
  deleteBetTypeConfig: ConfigurationActions.deleteBetTypeConfig,
  addNotificationMessage: UiActions.addNotificationMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BetTypes);
