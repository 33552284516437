import React from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import BonusSystem from './BonusSystem/BonusSystemBuilder';
import CustomerValidationBuilder from './CustomerValidation/CustomerValidationBuilder';

const CustomerService = ({ match }) => {
  let module;
  switch (match.params.module) {
    case 'bonus-system':
      module = () => <Route render={() => <BonusSystem />} />;
      break;
    case 'cv':
      module = () => <Route render={() => <CustomerValidationBuilder />} />;
      break;
    default:
      module = () => <Redirect to="/adm" />;
  }

  return <Route exact path={`${match.path}`} component={module} />;
};

export default withRouter(CustomerService);
