import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import * as LanguagesActions from '../../redux/actions/Languages';
import { selectStyles } from '../../containers/CMS/customReactSelectStyle';
import { getLanguages } from '../../selectors';
import { useStateForSelect, useMount, useUpdate } from '../../hooks';

const LanguageSelector = ({
  languages,
  onChange,
  getAllLanguages,
  reduxLanguages,
  defaultLang,
  isMulti,
  placeholder,
  moreData,
}) => {
  const languageOptions = useMemo(
    () =>
      (languages || reduxLanguages).map(lang => ({
        label: lang.displayName,
        value: lang.languageCode,
      })),
    [languages, reduxLanguages]
  );

  const [selectedLanguage, setSelectedLanguage] = useStateForSelect('');

  useUpdate(() => {
    if ((defaultLang && Array.isArray(defaultLang)) || moreData) {
      setSelectedLanguage(defaultLang);
    } else {
      setSelectedLanguage(
        languageOptions.find(lang => lang.value === (defaultLang || 'en')) || ''
      );
    }
  }, [defaultLang, selectedLanguage]);

  useMount(() => {
    if (!languages) {
      getAllLanguages();
    }
  });

  const onLanguageChange = useCallback(
    data => {
      setSelectedLanguage(data);
      if (isMulti || moreData) {
        onChange(data);
      } else {
        onChange(data.value);
      }
    },
    [isMulti, moreData, onChange, setSelectedLanguage]
  );

  return (
    <Select
      placeholder={placeholder ? placeholder : 'Select Language'}
      onChange={onLanguageChange}
      value={selectedLanguage}
      options={languageOptions}
      styles={selectStyles}
      isMulti={isMulti}
    />
  );
};

const mapStateToProps = state => ({
  reduxLanguages: getLanguages(state).languages,
});

const mapDispatchToProps = {
  getAllLanguages: LanguagesActions.getAllLanguages,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
