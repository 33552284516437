import React, { memo } from 'react';

export const Darts = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={
        `Darts ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''}`
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141 141"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <circle cx="70.5" cy="70.5" r="70.5" />
        <path d="M61.14,63.68l-.39.56c-.11.18-.22.36-.32.54l-.17.31a2.84,2.84,0,0,0-.17.32c-.09.19-.18.39-.27.59a.12.12,0,0,1,0,.06c-.09.21-.17.41-.24.63a5.38,5.38,0,0,0-.18.56,3.4,3.4,0,0,0-.1.37,4.44,4.44,0,0,0-.13.56c0,.17-.07.35-.1.53l-.23,0-22-3.46A33.92,33.92,0,0,1,43,50.47l18,13.07Z" />
        <path d="M61.14,77.32l-.19.14L43,90.53a34.09,34.09,0,0,1-6.1-14.73l22-3.46.23,0c0,.18.06.36.1.53a4.45,4.45,0,0,0,.13.56,3.45,3.45,0,0,0,.1.37,5.41,5.41,0,0,0,.18.56c.07.22.15.42.24.63a.12.12,0,0,1,0,.06l.27.59a2.83,2.83,0,0,0,.17.32l.17.31c.1.18.21.36.32.54Z" />
        <path d="M68.69,81.95l0,.23-3.46,22A34,34,0,0,1,50.47,98l13.07-18,.14-.19.56.39.54.32.31.17.32.17.59.27.06,0c.21.09.41.17.63.24a4.84,4.84,0,0,0,.56.18l.37.1a5.36,5.36,0,0,0,.56.13Z" />
        <path d="M90.53,98h0a34,34,0,0,1-14.71,6.1l-3.46-22,0-.23.53-.1a5.16,5.16,0,0,0,.56-.13l.37-.1a4.63,4.63,0,0,0,.56-.18,6.47,6.47,0,0,0,.63-.24l.06,0,.59-.27.32-.17.31-.17.54-.32.56-.39.14.19Z" />
        <path d="M104.13,75.8A34.1,34.1,0,0,1,98,90.53l-18-13.07-.19-.14.39-.56c.11-.18.22-.36.32-.54l.17-.31a2.77,2.77,0,0,0,.17-.32l.27-.59a.13.13,0,0,1,0-.06c.09-.21.17-.41.24-.63a4.67,4.67,0,0,0,.18-.56,3.54,3.54,0,0,0,.1-.37,4,4,0,0,0,.13-.56c0-.17.07-.35.1-.53l.23,0Z" />
        <path d="M104.13,65.2l-22,3.46-.23,0c0-.18-.06-.36-.1-.53a4,4,0,0,0-.13-.56,3.49,3.49,0,0,0-.1-.37,4.65,4.65,0,0,0-.18-.56c-.07-.22-.15-.42-.24-.63a.13.13,0,0,1,0-.06c-.09-.2-.18-.4-.27-.59a2.78,2.78,0,0,0-.17-.32l-.17-.31c-.1-.18-.21-.36-.32-.54l-.39-.56.19-.14L98,50.47h0A34,34,0,0,1,104.13,65.2Z" />
        <path d="M90.53,43l-13.07,18-.14.19-.56-.39-.54-.32-.31-.17-.32-.17L75,59.82l-.06,0a6.58,6.58,0,0,0-.63-.24,4.63,4.63,0,0,0-.56-.18l-.37-.1a5.16,5.16,0,0,0-.56-.13l-.53-.1,0-.23,3.46-22A34,34,0,0,1,90.53,43Z" />
        <path d="M68.69,59.05l-.53.1a5.36,5.36,0,0,0-.56.13l-.37.1a4.84,4.84,0,0,0-.56.18c-.22.07-.42.15-.63.24l-.06,0-.59.27-.32.17a2.43,2.43,0,0,0-.31.17l-.54.32-.56.39-.14-.19L50.47,43a34.12,34.12,0,0,1,14.73-6.1l3.46,22Z" />
        <path d="M64.32,31.29a39.59,39.59,0,0,0-17.18,7.12L35.4,22.25a59.51,59.51,0,0,1,25.8-10.7h0Z" />
        <path d="M105.62,22.25,93.86,38.41a39.62,39.62,0,0,0-17.18-7.12l3.11-19.74h0A59.75,59.75,0,0,1,105.62,22.25Z" />
        <path d="M129.44,61.2h0l-19.73,3.11a39.53,39.53,0,0,0-7.12-17.18L118.75,35.4A59.55,59.55,0,0,1,129.44,61.2Z" />
        <path d="M129.44,79.79h0a59.55,59.55,0,0,1-10.69,25.8L102.59,93.86a39.52,39.52,0,0,0,7.12-17.18Z" />
        <path d="M105.6,118.74a59.51,59.51,0,0,1-25.8,10.7h0l-3.11-19.73a39.62,39.62,0,0,0,17.18-7.12Z" />
        <path d="M64.32,109.71l-3.11,19.73A59.6,59.6,0,0,1,35.4,118.75L47.14,102.6A39.69,39.69,0,0,0,64.32,109.71Z" />
        <path d="M38.4,93.86,22.25,105.6A59.59,59.59,0,0,1,11.56,79.8h0l19.73-3.11A39.61,39.61,0,0,0,38.4,93.86Z" />
        <path d="M38.41,47.14a39.53,39.53,0,0,0-7.12,17.18L11.55,61.21h0A59.76,59.76,0,0,1,22.25,35.39Z" />
        <path d="M10.83,70.5a61.33,61.33,0,0,1,.72-9.29L6,60.33A66.05,66.05,0,0,0,6,80.67l5.58-.88A59.86,59.86,0,0,1,10.83,70.5Zm11.42,35.1-4.56,3.32a65.64,65.64,0,0,0,14.39,14.39l3.32-4.56A60.06,60.06,0,0,1,22.25,105.6Zm57.55,23.84a59.61,59.61,0,0,1-18.58,0L60.33,135a66,66,0,0,0,20.34,0ZM135,60.33l-5.58.88a59.47,59.47,0,0,1,0,18.58l5.58.88a66.35,66.35,0,0,0,0-20.34ZM108.92,17.7l-3.31,4.56A59.35,59.35,0,0,1,118.75,35.4l4.56-3.32A65.11,65.11,0,0,0,108.92,17.7Zm9.82,87.91a59.31,59.31,0,0,1-13.14,13.14l3.32,4.56c1.38-1,2.72-2.05,4-3.16.64-.56,1.28-1.12,1.91-1.7.78-.72,1.54-1.46,2.28-2.22a66.54,66.54,0,0,0,6.17-7.3ZM70.5,5.2A65.88,65.88,0,0,0,60.33,6l.88,5.57a60.32,60.32,0,0,1,9.29-.73,61.56,61.56,0,0,1,9.29.72L80.67,6A65.84,65.84,0,0,0,70.5,5.2ZM32.08,17.7a66.53,66.53,0,0,0-7.3,6.17c-.38.37-.76.75-1.12,1.13s-.74.77-1.1,1.16c-.58.63-1.14,1.27-1.69,1.9-1.11,1.3-2.15,2.63-3.16,4l4.56,3.31A59.77,59.77,0,0,1,35.4,22.25Z" />
        <path d="M43,90.53,38.4,93.86a39.91,39.91,0,0,0,8.74,8.74L50.47,98A33.84,33.84,0,0,1,43,90.53Zm55.06,0A34,34,0,0,1,90.53,98l3.33,4.58a39.73,39.73,0,0,0,8.73-8.73Zm11.68-26.21-5.58.88a34.56,34.56,0,0,1,0,10.61l5.58.88a40.18,40.18,0,0,0,0-12.36ZM70.5,30.81a39.62,39.62,0,0,0-6.18.48l.88,5.58a34.59,34.59,0,0,1,10.61,0l.88-5.58A39.63,39.63,0,0,0,70.5,30.81Zm-23.36,7.6a39.71,39.71,0,0,0-8.74,8.73L43,50.47A34,34,0,0,1,50.47,43Zm46.72,0L90.53,43A33.55,33.55,0,0,1,98,50.47l4.58-3.33A39.53,39.53,0,0,0,93.86,38.41ZM75.81,104.13a34.59,34.59,0,0,1-10.61,0l-.88,5.58a40.18,40.18,0,0,0,12.36,0ZM36.87,65.2l-5.58-.88a40.18,40.18,0,0,0,0,12.36l5.58-.88a34.56,34.56,0,0,1,0-10.61Z" />
        <path d="M76.68,31.29l-.88,5.58a34.59,34.59,0,0,0-10.61,0l-.88-5.58a40.18,40.18,0,0,1,12.36,0Z" />
        <path d="M80.67,6l-.88,5.56a61.56,61.56,0,0,0-9.29-.72,60.32,60.32,0,0,0-9.29.73L60.33,6A66,66,0,0,1,80.67,6Z" />
        <path d="M76.68,109.71a40.18,40.18,0,0,1-12.36,0l.88-5.58a34.59,34.59,0,0,0,10.61,0Z" />
        <path d="M80.67,135a66,66,0,0,1-20.34,0l.88-5.57a59.61,59.61,0,0,0,18.58,0Z" />
        <path d="M10.83,70.5a59.86,59.86,0,0,0,.73,9.29L6,80.67A66.05,66.05,0,0,1,6,60.33l5.57.88A61.33,61.33,0,0,0,10.83,70.5Z" />
        <path d="M36.87,65.2a34.56,34.56,0,0,0,0,10.61l-5.58.88a40.18,40.18,0,0,1,0-12.36Z" />
        <path d="M110.19,70.5a39.64,39.64,0,0,1-.48,6.18l-5.58-.88a34.56,34.56,0,0,0,0-10.61l5.58-.88A39.65,39.65,0,0,1,110.19,70.5Z" />
        <path d="M135.8,70.5A66.05,66.05,0,0,1,135,80.67l-5.58-.88a59.47,59.47,0,0,0,0-18.58l5.58-.88A66.06,66.06,0,0,1,135.8,70.5Z" />
        <path d="M35.4,118.75l-3.32,4.56a65.64,65.64,0,0,1-14.39-14.39l4.56-3.32A60.06,60.06,0,0,0,35.4,118.75Z" />
        <path d="M50.47,98l-3.33,4.58a39.91,39.91,0,0,1-8.74-8.74L43,90.53A33.84,33.84,0,0,0,50.47,98Z" />
        <path d="M102.59,47.14,98,50.47A33.55,33.55,0,0,0,90.53,43l3.33-4.58A39.53,39.53,0,0,1,102.59,47.14Z" />
        <path d="M123.3,32.08l-4.56,3.32a59.35,59.35,0,0,0-13.13-13.14l3.31-4.56A65.11,65.11,0,0,1,123.3,32.08Z" />
        <path d="M102.59,93.86a39.73,39.73,0,0,1-8.73,8.73L90.53,98A34,34,0,0,0,98,90.53Z" />
        <path d="M123.3,108.92a66.54,66.54,0,0,1-6.17,7.3c-.75.76-1.5,1.51-2.28,2.22-.63.58-1.27,1.14-1.91,1.7-1.29,1.11-2.63,2.15-4,3.16l-3.32-4.56a59.31,59.31,0,0,0,13.14-13.14Z" />
        <path d="M32.08,17.7l3.32,4.56A59.77,59.77,0,0,0,22.25,35.39L17.7,32.08c1-1.39,2.05-2.72,3.16-4,.56-.64,1.12-1.28,1.69-1.9.36-.39.73-.78,1.1-1.16s.75-.76,1.12-1.13A66.53,66.53,0,0,1,32.08,17.7Z" />
        <path d="M47.14,38.41,50.47,43A34,34,0,0,0,43,50.47l-4.58-3.33A39.71,39.71,0,0,1,47.14,38.41Z" />
        <path
          className={'Center'}
          d="M76.6,70.5a4.28,4.28,0,0,1,0,.45.3.3,0,0,1,0,.06q0,.21,0,.42s0,0,0,0,0,.19-.05.28,0,.2-.07.29l-.05.2a2.58,2.58,0,0,1-.1.29,2.53,2.53,0,0,1-.13.33.11.11,0,0,0,0,0l-.14.31-.09.17-.09.16-.17.28-.2.29,0,.06c-.08.11-.16.22-.25.32l-.32.35-.35.32-.31.25-.06,0-.29.2-.28.17-.16.09-.16.09-.31.14h0l-.33.13-.29.1-.2.05-.29.07-.28.05h0l-.42,0h-.06l-.44,0-.45,0H70l-.42,0h0l-.28-.05L69,76.41l-.2-.05-.29-.1-.33-.13h0L67.81,76l-.16-.09-.16-.09-.28-.17-.29-.2-.06,0c-.11-.08-.22-.16-.31-.25l-.35-.32-.32-.35c-.09-.1-.17-.2-.25-.32l0-.06-.21-.29-.17-.28-.09-.16L65,73.18l-.15-.31a.1.1,0,0,0,0,0,3.26,3.26,0,0,1-.13-.33,2.58,2.58,0,0,1-.1-.29l-.05-.2c0-.09,0-.19-.07-.29s0-.18-.05-.28,0,0,0,0,0-.28,0-.42a.3.3,0,0,1,0-.06,5.18,5.18,0,0,1,0-.89.3.3,0,0,1,0-.06s0,0,0-.05,0-.06,0-.08,0-.19,0-.29a0,0,0,0,0,0,0c0-.09,0-.19.05-.28s0-.2.07-.29l.05-.2a2.58,2.58,0,0,1,.1-.29,3.48,3.48,0,0,1,.13-.33v0l.15-.31.09-.17.09-.16.17-.28.21-.29,0-.06c.08-.11.16-.21.25-.31l.32-.35.35-.32c.1-.09.2-.17.31-.25l.06,0,.29-.2.28-.17.16-.09.16-.09.31-.14h0l.33-.13.29-.1.2-.05.29-.07.28-.05h0l.29,0h.2l.45,0,.44,0h.2l.29,0h0l.28.05.29.07.2.05.29.1.33.13h0l.31.14.16.09.16.09.28.17.29.2.06,0,.31.25.35.32.32.35c.09.1.17.2.25.31l0,.06.2.29c.06.09.12.19.17.28l.09.16.09.17.14.31v0a2.67,2.67,0,0,1,.13.33,2.58,2.58,0,0,1,.1.29l.05.2c0,.09,0,.19.07.29s0,.18.05.28a0,0,0,0,0,0,0c0,.1,0,.2,0,.29s0,.06,0,.08,0,0,0,.05a.3.3,0,0,1,0,.06A4.28,4.28,0,0,1,76.6,70.5Z"
        />
      </g>
    </svg>
  );
};

export default memo(Darts);
