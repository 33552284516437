export const isEqualObjects = (firstObj, secondObj) => {
  if (Object.keys(firstObj).length !== Object.keys(secondObj).length) {
    return false;
  }

  for (const key in firstObj) {
    if (firstObj[key] !== secondObj[key]) {
      if (
        (firstObj[key] === null && secondObj[key] === '') ||
        (firstObj[key] === '' && secondObj[key] === null)
      ) {
      } else {
        return false;
      }
    }
  }

  return true;
};

export const debounce = (func, wait) => {
  let timeout;
  return function(...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const currencyConvert = amount => {
  return amount === 0
    ? amount
    : Number(parseFloat(amount / 100).toFixed(2)).toLocaleString('en', {
        minimumFractionDigits: 2,
      });
};

export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const isImage = file => {
  const ext =
    file &&
    file.type
      .split('/')
      .pop()
      .toLowerCase();
  const errors = {
    emptyFile: !file || isEmpty(file.name) ? 'File can not be empty!' : null,
    wrongFile:
      ext !== 'jpeg' &&
      ext !== 'jpg' &&
      ext !== 'png' &&
      ext !== 'bmp' &&
      ext !== 'gif'
        ? 'Please select a valid image file'
        : null,
    bigSize: file.size > 1024000 ? 'The image is too large!' : null,
  };
  const errorKey = Object.keys(errors).find(x => errors[x]);
  return errors[errorKey];
};

export default isEmpty;
