import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { selectStyles } from '../../../CMS/customReactSelectStyle';

const SelectMarketType = ({
  mode,
  marketType,
  onSelectChange,
  marketTypesViews
}) => {
  return (
    <>
      {mode === 'create' || (mode === 'edit' && marketType) ? (
        <div className="col-lg-6">
          <Select
            name="marketType"
            placeholder="Select market type"
            isDisabled={mode === 'edit'}
            value={marketType}
            onChange={onSelectChange}
            options={marketTypesViews}
            styles={selectStyles}
          />
        </div>
      ) : null}
    </>
  );
};

SelectMarketType.propTypes = {
  mode: PropTypes.string.isRequired,
  marketType: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  marketTypesViews: PropTypes.array.isRequired,
  onSelectChange: PropTypes.func.isRequired
};

export default SelectMarketType;
