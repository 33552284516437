import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const EventSelectionInfo = ({ sportName, countryName, tournamentName, filterDate }) => {
	const formattedDate = filterDate ? moment(filterDate).format('DD-MMM-YY') : '';
	return (
		<div className="event-selction-info-wrapper">
			<span>{sportName || 'None sport is selected yet'}</span> > {' '}
			<span>{countryName || 'None country is selected yet'}</span> > {' '}
			<span>{tournamentName || 'None tournament is selected yet'}</span>
			{filterDate ? <span> > from {formattedDate}</span> : null}
		</div>
	);
};

EventSelectionInfo.propTypes = {
	sportName: PropTypes.string,
	countryName: PropTypes.string,
	tournamentName: PropTypes.string
};

export default EventSelectionInfo;
