import React from 'react';
import moment from 'moment';

import Button from '../../Layout/Button';

const Row = ({
  event: streamEvent,
  openMappingModal,
  mappedEvents,
  updateEvent,
  openDetailsModal,
}) => {
  const event = mappedEvents.find(ev =>
    ev.streamingProviders.find(provider => provider.streamId === streamEvent.id)
  );

  return (
    <tr
      style={{ background: event ? 'green' : '', color: event ? 'white' : '' }}
    >
      <td>{streamEvent.name}</td>
      <td>{moment(streamEvent.start_time).format('DD/MM/YYYY HH:mm:ss')}</td>
      <td>
        {moment(streamEvent.start_time) < moment() ? 'Started' : 'Not started'}
      </td>
      <td>{streamEvent.first_level_category.name}</td>
      <td>{streamEvent.second_level_category.name}</td>
      <td>{streamEvent.third_level_category.name}</td>
      <td>
        <div className="d-flex justify-content-center">
          {event ? (
            <div className="form-row flex-nowrap">
              <Button
                onClick={() => updateEvent(event)}
                cssClass="efb-btn__main"
              >
                Unmap
              </Button>
              <Button
                onClick={() => openDetailsModal(event)}
                cssClass="efb-btn__main"
              >
                Details
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => openMappingModal(streamEvent)}
              cssClass="efb-btn__main"
            >
              Map
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
};

const IMGEventsTableRow = ({
  tableData,
  openMappingModal,
  openDetailsModal,
  mappedEvents,
  updateEvent,
}) => {
  return tableData.map(event => (
    <Row
      key={event.id}
      openMappingModal={openMappingModal}
      openDetailsModal={openDetailsModal}
      event={event}
      updateEvent={updateEvent}
      mappedEvents={mappedEvents}
    />
  ));
};

export default React.memo(IMGEventsTableRow);
