import {
  GET_CURRENCIES,
  GET_STATES,
  GET_TYPES,
  GET_PAYMENTS,
  PENDINGTRANSACTIONS,
  TRANSACTIONS,
  GET_DOWNLOADED_CURRENCY,
  CHANGE_CURRENCY_RATE,
  GET_PAYMENT_PROVIDERS,
  GET_FILTERED_TRANSACTIONS,
  AUTOMATIC_WITHDRAW_STATUS,
  GET_USED_CURRENCIES,
  GET_CURRENCY_LIMIT,
  GET_PREFERENCES,
  GET_AVAILABLE_CURRENCY,
  GET_PAYMENT_PROVIDERS_FOR_BUSINESS_UNIT,
} from '../actions/TransactionBuilder';

const initialState = {
  currencies: [],
  states: [],
  types: [],
  payments: [],
  paymentsProviders: [],
  downloadedCurrency: undefined,
  transactions: undefined,
  pendingTransactions: undefined,
  filteredTransactions: [],
  awStatus: false,
  usedCurrencies: [],
  currencyLimit: '',
  paymentProvidersPerBSUnit: [],
  preferences: [],
  availableCurrency: null,
};

export const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENCIES.SUCCESS:
      return {
        ...state,
        currencies: action.payload,
        downloadedCurrency: undefined,
      };

    case GET_DOWNLOADED_CURRENCY.SUCCESS:
      return {
        ...state,
        downloadedCurrency: action.payload,
      };

    case CHANGE_CURRENCY_RATE.SUCCESS:
      return {
        ...state,
      };

    case GET_STATES.SUCCESS:
      return {
        ...state,
        states: action.payload,
      };

    case GET_TYPES.SUCCESS:
      return {
        ...state,
        types: action.payload,
      };

    case GET_PAYMENTS.SUCCESS:
      return {
        ...state,
        payments: action.payload,
      };

    case PENDINGTRANSACTIONS.SUCCESS:
      return {
        ...state,
        pendingTransactions: action.payload,
      };

    case PENDINGTRANSACTIONS.EMPTY:
      return {
        ...state,
        pendingTransactions: undefined,
      };

    case TRANSACTIONS.SUCCESS:
      return {
        ...state,
        transactions: action.payload,
      };

    case TRANSACTIONS.EMPTY:
      return {
        ...state,
        transactions: undefined,
      };

    case GET_DOWNLOADED_CURRENCY.EMPTY:
      return {
        ...state,
        downloadedCurrency: [],
      };

    case GET_PAYMENT_PROVIDERS.SUCCESS:
      return {
        ...state,
        paymentsProviders: action.payload,
      };

    case GET_FILTERED_TRANSACTIONS.SUCCESS:
      return {
        ...state,
        filteredTransactions: action.payload,
      };

    case AUTOMATIC_WITHDRAW_STATUS.SUCCESS:
      return {
        ...state,
        awStatus: action.payload.autoWithdrawEnabled,
      };

    case GET_USED_CURRENCIES.SUCCESS:
      return {
        ...state,
        usedCurrencies: action.payload,
      };

    case GET_CURRENCY_LIMIT.SUCCESS:
      return {
        ...state,
        currencyLimit: action.payload === '' ? 0 : action.payload,
      };

    case GET_PAYMENT_PROVIDERS_FOR_BUSINESS_UNIT.SUCCESS:
      return {
        ...state,
        paymentProvidersPerBSUnit: action.payload,
      };

    case GET_PREFERENCES.SUCCESS:
      return {
        ...state,
        preferences: action.payload,
      };

    case GET_AVAILABLE_CURRENCY.SUCCESS:
      return {
        ...state,
        availableCurrency: action.payload,
      };
    case GET_AVAILABLE_CURRENCY.EMPTY:
      return {
        ...state,
        availableCurrency: null,
      };

    default:
      return state;
  }
};
