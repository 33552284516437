import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';

import * as StsActions from '../../../../redux/actions/cms/stsBuilder';
import * as UiActions from '../../../../redux/actions/ui';
import { selectStyles } from '../../customReactSelectStyle';
import { useStateForSelect, useMount, useUpdate } from '../../../../hooks';
import { getStsBuilderData } from '../../../../selectors';
import StsCreateForm from '../StsCreateForm';
import InputField from '../../../../components/Form/InputField';
import Switch from '../../../../components/Form/Switch/Switch';
import { mapSts } from '../StsUtils';
import useStateForSwitch from '../../../../hooks/useStateForSwitch';
import useStateForInput from '../../../../hooks/useStateForInput';

const CreateSeason = ({
  languages,
  setSportId,
  setCategoryId,
  setTournamentId,
  editingSeason,
  clearEditingSeason,
  sports,
  getAllSports,
  categories,
  getSportCategories,
  tournaments,
  getTournaments,
  getSeasons,
  createSeason,
  updateSeason,
  addNotificationMessage,
}) => {
  const [selectedSport, setSelectedSport] = useStateForSelect('');
  const [selectedCategory, setSelectedCategory] = useStateForSelect('');
  const [selectedTournament, setSelectedTournament] = useStateForSelect('');
  const [
    scheduledTime,
    setScheduledTime,
    resetScheduledTime,
  ] = useStateForInput('');
  const [
    scheduledEndTime,
    setScheduledEndTime,
    resetScheduledEndTime,
  ] = useStateForInput('');
  const [year, setYear] = useState(
    moment()
      .get('year')
      .toString()
  );
  const [current, setCurrent] = useStateForSwitch(false);

  useUpdate(() => {
    if (editingSeason) {
      setScheduledEndTime(
        moment(editingSeason.scheduledEndTime).format('YYYY-MM-DD') || ''
      );
      setScheduledTime(
        moment(editingSeason.scheduledTime).format('YYYY-MM-DD') || ''
      );
      setYear(
        editingSeason.year ||
          moment()
            .get('year')
            .toString()
      );
      setCurrent(editingSeason.current || false);
    }
  }, [editingSeason]);

  const sportOptions = useMemo(() => sports.map(mapSts), [sports]);

  const categoriesOptions = useMemo(() => categories.map(mapSts), [categories]);

  const tournamentsOptions = useMemo(() => tournaments.map(mapSts), [
    tournaments,
  ]);

  useMount(getAllSports);

  useUpdate(() => getSportCategories(selectedSport.value), [
    selectedSport.value,
  ]);

  useUpdate(() => getTournaments(selectedCategory.value), [
    selectedCategory.value,
  ]);

  useUpdate(() => getSeasons(selectedCategory.value), [selectedCategory.value]);

  const submitSeason = useCallback(
    (names, status) => {
      if (
        !editingSeason &&
        (!selectedSport.value ||
          !selectedCategory.value ||
          !selectedTournament.value ||
          !names.en.name ||
          !year ||
          !scheduledTime ||
          !scheduledEndTime)
      ) {
        addNotificationMessage(
          'Current, Year, Times, Sport, Category, Tournament and name in English are required!',
          'warning',
          'Warning!'
        );
        return;
      }

      const season = {
        names,
        status,
      };
      if (editingSeason) {
        updateSeason({ ...editingSeason, ...season, current });
      } else {
        createSeason({
          ...season,
          current,
          year,
          type: 'season',
          scheduledTime: moment
            .utc(scheduledTime)
            .set({
              hour: 0,
              minute: 0,
              seconds: 0,
            })
            .toDate(),
          scheduledEndTime: moment
            .utc(scheduledEndTime)
            .set({
              hour: 0,
              minute: 0,
              seconds: 0,
            })
            .toDate(),
          marketAggregateIds: {},
          startTime: moment
            .utc(scheduledTime)
            .set({
              hour: 0,
              minute: 0,
              seconds: 0,
            })
            .toDate(),
          sportId: selectedSport.value,
          categoryId: selectedCategory.value,
          tournamentId: selectedTournament.value,
          competitors: [],
          streamingProviders: [],
          currentProducerId: '14',
          feedProducers: [
            {
              feedProviderId: `efb:season:${new Date().getTime()}`,
              producerId: '14',
            },
          ],
          liveCoverage: true,
        });
      }
      clearEditingSeason();
      setCurrent(false);
    },
    [
      addNotificationMessage,
      createSeason,
      current,
      editingSeason,
      scheduledEndTime,
      scheduledTime,
      selectedCategory.value,
      selectedSport.value,
      selectedTournament.value,
      updateSeason,
      clearEditingSeason,
      year,
      setCurrent,
    ]
  );

  const onClear = useCallback(() => {
    resetScheduledEndTime('');
    resetScheduledTime('');
    setYear(
      moment()
        .get('year')
        .toString()
    );
    clearEditingSeason();
    setCurrent(false);
  }, [
    clearEditingSeason,
    setCurrent,
    resetScheduledTime,
    resetScheduledEndTime,
  ]);

  return (
    <div className="efb-form mt-3">
      <div className="form-row mb-4">
        <Select
          placeholder="Select Sport"
          value={selectedSport}
          onChange={data => {
            setSelectedSport(data);
            setSportId(data.value);
          }}
          styles={selectStyles}
          options={sportOptions}
          className="col"
        />
        <Select
          placeholder="Select Category"
          value={selectedCategory}
          onChange={data => {
            setSelectedCategory(data);
            setCategoryId(data.value);
          }}
          styles={selectStyles}
          options={categoriesOptions}
          className="col"
        />
        <Select
          placeholder="Select Tournament"
          value={selectedTournament}
          onChange={data => {
            setSelectedTournament(data);
            setTournamentId(data.value);
          }}
          styles={selectStyles}
          options={tournamentsOptions}
          className="col"
        />
      </div>

      <div className="form-row">
        <div className="form-label-group col">
          <InputField
            type="date"
            label="Scheduled Start Time"
            className="form-control"
            onChange={setScheduledTime}
            value={scheduledTime}
          />
        </div>
        <div className="form-label-group col">
          <InputField
            type="date"
            label="Scheduled End Time"
            className="form-control"
            onChange={setScheduledEndTime}
            value={scheduledEndTime}
          />
        </div>
        <div className="form-label-group col">
          <InputField
            type="number"
            label="Year"
            className="form-control"
            value={year}
            onChange={e => setYear(e.target.value)}
          />
        </div>
        <div className="form-label-group col">
          <Switch
            label="Current season?"
            className="form-contol"
            isChecked={current}
            onChange={setCurrent}
          />
        </div>
      </div>

      <StsCreateForm
        sts="season"
        languages={languages}
        editingSts={editingSeason}
        onClear={onClear}
        onSubmit={submitSeason}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  sports: getStsBuilderData(state).sports,
  categories: getStsBuilderData(state).categories,
  tournaments: getStsBuilderData(state).tournaments,
});

const mapDispatchToProps = {
  getAllSports: StsActions.getAllSports,
  getSportCategories: StsActions.getSportCategories,
  getTournaments: StsActions.getTournaments,
  getSeasons: StsActions.getSeasons,
  createSeason: StsActions.createSeason,
  updateSeason: StsActions.updateSeason,
  addNotificationMessage: UiActions.addNotificationMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSeason);
