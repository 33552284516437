export const providers = [
  {
    label: 'Betradar',
    value: 1
  },
  {
    label: 'IMG',
    value: 2
  }
];

export const streamStatuses = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Mapped',
    value: 'mapped'
  },
  {
    label: 'Unmapped',
    value: 'unmapped'
  }
];
