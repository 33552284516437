import React, { Fragment, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Layout/Button';
import Select from 'react-select';
import { selectStyles } from '../../CMS/customReactSelectStyle';
import InputField from '../../../components/Form/InputField';
import { useStateForInput, useStateForSelect } from '../../../hooks';

const EmailTemplates = ({ bonuses, createEmailTemplate }) => {
  const [email, setEmail] = useStateForInput('');

  const [bonusId, setBonusId] = useStateForSelect('');

  const bonusesList = useMemo(() => {
    if (bonuses.length > 0) {
      return bonuses.map(element => {
        return {
          value: element.id,
          label: element.name,
          type: 'bonus'
        };
      });
    }
    return [];
  }, [bonuses]);

  const sendEmailTemp = useCallback(() => {
    createEmailTemplate({ bonusId: bonusId.value, text: email });
  }, [bonusId, createEmailTemplate, email]);

  return (
    <Fragment>
      <h2>Email template</h2>
      <br />
      <div className="efb-form">
        <div className="form-row">
          <div className="form-label-group col">
            <Select
              onChange={setBonusId}
              options={bonusesList}
              styles={selectStyles}
              placeholder={'Select bonus'}
              name="Bonus"
            />
          </div>
          <div className="form-label-group col">
            <InputField
              type="text"
              name="emailTemplate"
              className="form-control"
              label="Email template"
              placeholder="Email template"
              onChange={setEmail}
            />
          </div>
        </div>
      </div>
      <Button onClick={sendEmailTemp} cssClass="efb-btn__main col-1">
        Send email template
      </Button>
    </Fragment>
  );
};

EmailTemplates.propTypes = {
  bonuses: PropTypes.array,
  createEmailTemplate: PropTypes.func
};

export default EmailTemplates;
