import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NewCategory from './NewCategory';
import RiskCategoriesTable from './RiskCategoriesTable';
import { getRiskCategoryData } from '../../../../selectors';
import * as RiskCategoryActions from '../../../../redux/actions/riskManagement/RiskCategory';

const RiskCategories = ({ riskCategories, getRiskCategories }) => {
  const [category, setCategory] = React.useState({});

  React.useEffect(() => {
    getRiskCategories();
  }, [getRiskCategories]);

  const resetCategory = React.useCallback(() => setCategory({}), []);

  const onEditClick = React.useCallback(rc => {
    setCategory(rc);
  }, []);

  return (
    <div className="tab-pane card-body fade show active efb-form">
      <h3>Risk Categories</h3>
      <RiskCategoriesTable
        data={riskCategories}
        onEditClick={onEditClick}
        resetCategory={resetCategory}
      />
      <NewCategory
        category={category}
        resetCategory={resetCategory}
      />
    </div>
  );
};

RiskCategories.propTypes = {
  riskCategories: PropTypes.array,
  getRiskCategories: PropTypes.func
};

const mapStateToProps = state => ({
  riskCategories: getRiskCategoryData(state).riskCategories
});

const mapDispatchToProps = {
  getRiskCategories: RiskCategoryActions.getRiskCategories
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskCategories);
