const LOCAL = false;

export const rootApiDev = LOCAL
  ? 'http://localhost:9080'
  : 'http://164.92.173.250:31122';
export const rootApiTest = 'https://apigw-test-efbet.awd-group.tech';
export const rootApiStage = 'https://apigw-stage-efbet.awd-group.tech';

export const wsUrlDev = LOCAL
  ? 'http://localhost:9902/websocket-gateway'
  : 'http://164.92.173.250:31495/websocket-gateway';
export const wsUrlTest =
  'https://apigw-test-efbet.awd-group.tech/websocket-gateway';
export const wsUrlStage =
  'https://apigw-stage-efbet.awd-group.tech/websocket-gateway';

const ROOT_URL = process.env.REACT_APP_DOMAIN_URL || rootApiDev;
let WS_URL;
if (ROOT_URL === rootApiDev) {
  WS_URL = wsUrlDev;
} else if (ROOT_URL === rootApiTest) {
  WS_URL = wsUrlTest;
} else if (ROOT_URL === rootApiStage) {
  WS_URL = wsUrlStage;
}

console.log(ROOT_URL);
console.log(WS_URL);

export const config = {
  /** The domain url to use when building the project. */
  domainUrl: ROOT_URL,
  wsUrl: WS_URL,
  local: LOCAL,
};
