import React from 'react';

const IconPen = ({
  className,
  width,
  height,
  fill,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      className={className}
      viewBox="0 0 8 11.209" {...otherProps}>
      <path
        d="M0,0,2.795.023l.054,6.7L.054,6.7Z"
        transform="translate(4.484 1.917) rotate(30)"
        fill={`${fill ? fill : '#424242'}`}
      />
      <path
        d="M1.181,0h0A1.181,1.181,0,0,1,2.361,1.181v.738a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1.181A1.181,1.181,0,0,1,1.181,0Z"
        transform="translate(5.955 0) rotate(30)"
        fill={`${fill ? fill : '#424242'}`}
      />
      <path
        d="M1.181,0,2.361,2.066H0Z"
        transform="translate(2.045 11.209) rotate(-150)"
        fill={`${fill ? fill : '#424242'}`}
      />
    </svg>
  );
};

export default IconPen;