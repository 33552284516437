import React, { useCallback, useState, useMemo } from 'react';
import Select from 'react-select';

import { useStateForSelect, useUpdate } from '../../../hooks';
import InputField from '../../../components/Form/InputField';
import Button from '../../../components/Layout/Button';
import { selectStyles } from '../customReactSelectStyle';

const statusesOptions = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'DEACTIVATED' }
];

const StsCreateForm = ({
  languages,
  editingSts,
  sts,
  onClear,
  onSubmit,
  hideStatus
}) => {
  const mappedLanguagesToNames = useMemo(
    () =>
      languages.reduce(
        (acc, val) => ({
          ...acc,
          [val.languageCode]: { name: '', translated: true }
        }),
        {}
      ),
    [languages]
  );

  const [status, setStatus] = useStateForSelect(statusesOptions[0]);
  const [names, setNames] = useState(mappedLanguagesToNames);

  useUpdate(() => {
    if (editingSts) {
      setStatus(
        statusesOptions.find(option => option.value === editingSts.status)
      );
      setNames(editingSts.names);
    } else {
      clearFields();
    }
  }, [editingSts]);

  const onNamesChange = useCallback(
    e => {
      const { name, value } = e.target;
      setNames({ ...names, [name]: { ...names[name], name: value } });
    },
    [names]
  );

  const clearFields = useCallback(() => {
    setStatus(statusesOptions[0]);
    setNames(mappedLanguagesToNames);
  }, [mappedLanguagesToNames, setStatus]);

  const mapLanguages = useCallback(
    lang => (
      <div key={lang.languageCode} className="form-label-group col-md">
        <InputField
          name={lang.languageCode}
          placeholder={lang.displayName}
          value={
            names[lang.languageCode] ? names[lang.languageCode].name : ''
          }
          onChange={onNamesChange}
          label={
            <span>
              {lang.displayName}{' '}
              <i
                className={`flag-icon flag-icon-${
                  lang.languageCode === 'en' ? 'gb' : lang.languageCode
                }`}
              />
            </span>
          }
        />
      </div>
    ),
    [names, onNamesChange]
  );

  return (
    <>
      {!hideStatus ? (
        <Select
          placeholder={`Select ${sts} status`}
          onChange={setStatus}
          value={status}
          options={statusesOptions}
          styles={selectStyles}
        />
      ) : null}

      <div className="d-flex form-row mt-4">{languages.map(mapLanguages)}</div>

      <div className="form-row justify-content-end">
        {editingSts ? (
          <Button cssClass="efb-btn__main" onClick={onClear}>
            Clear
          </Button>
        ) : null}
        <Button
          cssClass="efb-btn__main"
          onClick={() => onSubmit(names, status.value)}
        >
          {editingSts ? 'Update' : 'Submit'} {sts}
        </Button>
      </div>
    </>
  );
};

export default StsCreateForm;
