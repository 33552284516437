import React, { Fragment, useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCMSData, createLoadingSelector } from '../../../selectors';
import * as EventSearchActions from '../../../redux/actions/cms/eventSearch';
import EventPicker from './EventPicker';
import EventsList from './EventsList';
import EventMarkets from './EventMarkets';
import FullScreenLoader from '../../../components/Layout/FullScreenLoader/FullScreenLoader';
import useUpdate from '../../../hooks/useUpdate';
import useMount from '../../../hooks/useMount';
import PropTypes from 'prop-types';
import { addNotificationMessage } from '../../../redux/actions/ui';

const EventsSearch = ({
  getMarketsBySportEvent,
  sportEventMarkets,
  loading,
  events,
}) => {
  const [eventsList, setEventsList] = useState([]);
  const [currentEventName, setCurrentEventName] = useState('');
  const [sportIcon, setSportIcon] = useState({});
  const [eventMarkets, setEventMarkets] = useState([]);
  const [eventMarketsTable, setEventMarketsTable] = useState(false);
  const [sportName, setSportName] = useState('');
  const [countryName, setCountryName] = useState('');
  const [tournamentName, setTournamentName] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [currentProducer, setCurrentProducer] = useState('');

  useMount(() => {
    setSportIcon('');
  });

  useUpdate(() => {
    setEventMarkets(sportEventMarkets);
    if (!loading && eventMarketsTable) {
      const element = document.getElementById('event-markets');
      setTimeout(() => {
        window.scrollTo({
          behavior: element ? 'smooth' : 'auto',
          top: element ? element.offsetTop : 0,
        });
      }, 100);
    }
    if (!currentProducer && events.length > 0) {
      setEventsList(events);
    }
  }, [sportEventMarkets, events, loading, currentProducer]);

  const eventsListFilteredByProviders = useMemo(() => {
    return events.reduce((acc, val) => {
      if (val.feedProducers[0].producerId === currentProducer) {
        return [...acc, val];
      }
      return acc;
    }, []);
  }, [events, currentProducer]);

  const getEventMarkets = useCallback(
    (eventId, eventNames, eventIndex) => {
      const eventsClone = [...events].map(event => {
        event['areMarketDetailsActive'] = false;
        return event;
      });
      getMarketsBySportEvent(eventId);
      setCurrentEventName(eventNames.en.name);
      eventsClone[eventIndex].areMarketDetailsActive = true;
      setEventsList(eventsClone);
      setEventMarketsTable(true);
    },
    [getMarketsBySportEvent, events]
  );

  const clearEventMarkets = useCallback(
    eventIndex => {
      const eventsClone = [...eventsList];
      eventsClone[eventIndex].areMarketDetailsActive = false;
      setEventMarkets([]);
      setEventsList(eventsClone);
      setEventMarketsTable(false);
    },
    [eventsList]
  );

  return (
    <Fragment>
      <FullScreenLoader isActive={loading} />
      <div className="form-row">
        <div className="col-md-3">
          <EventPicker
            setSportName={setSportName}
            setCountryName={setCountryName}
            setTournamentName={setTournamentName}
            setFilterDate={setFilterDate}
            setSportIcon={setSportIcon}
            events={eventsList}
            setCurrentProducer={setCurrentProducer}
          />
        </div>
        <div className="col-md-9 events-search-list">
          <EventsList
            events={
              currentProducer ? eventsListFilteredByProviders : eventsList
            }
            sportName={sportName}
            countryName={countryName}
            tournamentName={tournamentName}
            filterDate={filterDate}
            sportIcon={sportIcon}
            getEventMarkets={getEventMarkets}
            clearEventMarkets={clearEventMarkets}
          />
          <div id="event-markets">
            <EventMarkets
              currentEventName={currentEventName}
              sportEventMarkets={eventMarkets}
              eventMarketsTable={eventMarketsTable}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

EventsSearch.propTypes = {
  getMarketsBySportEvent: PropTypes.func,
  sportEventMarkets: PropTypes.array,
  loading: PropTypes.bool,
  sports: PropTypes.array,
  countries: PropTypes.array,
  getSports: PropTypes.func,
  getCountries: PropTypes.func,
  getTournaments: PropTypes.func,
  tournaments: PropTypes.array,
  getEvents: PropTypes.func,
  events: PropTypes.array,
  setSportName: PropTypes.func,
};

const loadingReducer = createLoadingSelector(['GET_MARKETS_BY_SPORT_EVENT']);

const mapStateToProps = state => ({
  events: getCMSData(state).eventSearchReducer.events,
  sportEventMarkets: getCMSData(state).eventSearchReducer.sportEventMarkets,
  loading: loadingReducer(state),
});

const mapDispatchToProps = {
  getSports: EventSearchActions.getSports,
  getCountries: EventSearchActions.getCountries,
  getTournaments: EventSearchActions.getTournaments,
  getMarketsBySportEvent: EventSearchActions.getMarketsBySportEvent,
  addNotificationMessage,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventsSearch)
);
