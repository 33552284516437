import React, { useCallback } from 'react';

const ByCountry = ({ bUnits, betScroller, onChangeCountry }) => {
  const checkCountry = useCallback(
    e => {
      const currentCountry = e.target.title;
      const filterCountries = [...betScroller.businessUnits];
      onChangeCountry({
        ...betScroller,
        businessUnits: filterCountries.includes(currentCountry)
          ? filterCountries.filter(
              betScroller => betScroller !== currentCountry
            )
          : [...betScroller.businessUnits, currentCountry],
      });
    },
    [betScroller, onChangeCountry]
  );

  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      {bUnits.map(unit => {
        return (
          <div
            key={unit.name}
            className={`position-relative m-1 ${
              betScroller.businessUnits.includes(unit.name)
                ? 'border-checked'
                : 'box-checked-class'
            }`}
          >
            <i
              title={unit.name}
              onClick={checkCountry}
              className={`flag-icon flag-icon-${unit.name.toLowerCase()} flag-size`}
            >
              <div className="title-filter-icon">{unit.name}</div>
            </i>
            {betScroller.businessUnits.includes(unit.name) ? (
              <i className="fas fa-check ml-1 icon-check" />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default ByCountry;