import React from 'react';

export const ArrowSmall = (props) => {
    return (
        <span className='efb-icon'>
            <svg className={`efb-icon--${props.size} efb-arrow__small`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 36 36" >
                <polygon points="36,13 0,13 18,31 " />
            </svg>
            {props.children}
        </span>
    )
}

export default ArrowSmall;