import React, { useState } from 'react';

import CreateCategory from './CreateCategory';
import CategoriesTable from './CategoriesTable';

const Categories = ({ languages }) => {
  const [editingCategory, setEditingCategory] = useState(null);
  const [sportId, setSportId] = useState(null);

  return (
    <>
      <h3>Categories</h3>
      <CreateCategory
        languages={languages}
        editingCategory={editingCategory}
        clearEditingCategory={() => setEditingCategory(null)}
        setSportId={setSportId}
      />
      <hr />
      <CategoriesTable
        languages={languages}
        editCategory={setEditingCategory}
        editingCategory={editingCategory}
        sportId={sportId}
      />
    </>
  );
};

export default Categories;
