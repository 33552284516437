import React, { useState, useCallback } from 'react';
import Input from '../../../../../../components/Form/Input';
import InputField from '../../../../../../components/Form/InputField';
import Button from '../../../../../../components/Layout/Button';
import PropTypes from 'prop-types';
import { useUpdate } from '../../../../../../hooks';

const TableContentRegular = ({ subpage, currentLang, tableIndex }) => {
  const [tableColumns, setTableColumns] = useState([[{ key: 0, value: '' }]]);
  const [tableKeysAndLabels, setTableKeysAndLabels] = useState([
    { key: 0, labelValue: '', keyValue: '' },
  ]);
  const [subpageObj, setSubpageObj] = useState('');

  useUpdate(() => {
    setSubpageObj(subpage);
  }, [subpage]);

  const handleInputChange = useCallback(
    (e, rowIndex, columnIndex) => {
      const { name, value } = e.target;
      const tableColumnsClone = [...tableColumns];
      const tableKeysAndLabelsClone = [...tableKeysAndLabels];
      const subpageClone = { ...subpageObj };
      const table =
        subpageClone.content[0][currentLang.value].tables[tableIndex].table;
      switch (name) {
        case 'key':
          table[0][rowIndex] = value;
          tableKeysAndLabelsClone[rowIndex].keyValue = value;
          break;

        case 'label':
          table[1][rowIndex] = value;
          tableKeysAndLabelsClone[rowIndex].labelValue = value;
          break;

        case 'row':
          tableColumnsClone[columnIndex][rowIndex].value = value;
          table[columnIndex + 2][rowIndex] = value;
          break;

        default:
          return null;
      }
      setTableColumns(tableColumnsClone);
      setSubpageObj(subpageClone);
    },
    [
      currentLang.value,
      subpageObj,
      tableColumns,
      tableIndex,
      tableKeysAndLabels,
    ]
  );

  const addTableColumn = useCallback(() => {
    const tableColumnsClone = [...tableColumns];
    const subpageClone = { ...subpageObj };

    let counter = tableColumnsClone.length * tableColumnsClone[0].length;

    tableColumnsClone.push([]);

    if (subpageClone.content[0][currentLang.value].tables[0].table.length > 0) {
      subpageClone.content[0][currentLang.value].tables[0].table[
        tableIndex
      ].map(row => {
        return tableColumnsClone[tableColumnsClone.length - 1].push({
          key: counter++,
          value: '',
        });
      });
    }
    setTableColumns(tableColumnsClone);
    setSubpageObj(subpageClone);
  }, [currentLang.value, subpageObj, tableColumns, tableIndex]);

  const removeTableKeyAndLabel = useCallback(
    index => {
      const subpageClone = { ...subpageObj };
      const tableColumnsClone = [...tableColumns];
      const tableKeysAndLabelsClone = [...tableKeysAndLabels];

      subpageClone.content[0][currentLang.value].tables[tableIndex].table.map(
        row => {
          return row.splice(index, 1);
        }
      );

      tableColumnsClone.map(column => {
        return column.splice(index, 1);
      });

      tableKeysAndLabelsClone.splice(index, 1);

      setSubpageObj(subpageClone);
      setTableColumns(tableColumnsClone);
      setTableKeysAndLabels(tableKeysAndLabelsClone);
    },
    [
      currentLang.value,
      subpageObj,
      tableColumns,
      tableIndex,
      tableKeysAndLabels,
    ]
  );

  const setTableName = useCallback(
    e => {
      const { value } = e.target;
      const subpageClone = { ...subpageObj };
      subpageClone.content[0][currentLang.value].tables[
        tableIndex
      ].name = value;
      setSubpageObj(subpageClone);
    },
    [currentLang.value, subpageObj, tableIndex]
  );

  const addTableRow = useCallback(() => {
    console.log('test');
    const subpageClone = { ...subpageObj };
    const tableColumnsClone = [...tableColumns];
    const tableKeysAndLabelsClone = [...tableKeysAndLabels];

    let counter = tableColumnsClone.length * tableColumnsClone[0].length;

    tableKeysAndLabelsClone.push({
      key: counter++,
      labelValue: '',
      keyValue: '',
    });

    tableColumnsClone.map(column => {
      return column.push({ key: counter++, value: '' });
    });

    subpageClone.content[0][currentLang.value].tables[tableIndex].table[0].push(
      ''
    );
    subpageClone.content[0][currentLang.value].tables[tableIndex].table[1].push(
      ''
    );

    setTableColumns(tableColumnsClone);
    setTableKeysAndLabels(tableKeysAndLabelsClone);
    setSubpageObj(subpageClone);
  }, [
    currentLang.value,
    subpageObj,
    tableColumns,
    tableIndex,
    tableKeysAndLabels,
  ]);

  const deleteTableRow = useCallback(
    columnIndex => {
      const tableColumnsClone = [...tableColumns];
      const subpageClone = { ...subpageObj };
      subpageClone.content[0][currentLang.value].tables[
        tableIndex
      ].table.splice(columnIndex + 2, 1);
      tableColumns.splice(columnIndex, 1);
      setTableColumns(tableColumnsClone);
      setSubpageObj(subpageClone);
    },
    [currentLang.value, subpageObj, tableColumns, tableIndex]
  );

  return (
    <>
      <h4>Table {tableIndex + 1}. </h4>
      <br />
      <div className="efb-form">
        <div className="form-row">
          <div className="form-label-group col-md">
            <InputField
              type="text"
              name="name"
              placeholder="Table Name"
              onChange={setTableName}
              label="Table Name"
            />
          </div>
        </div>
      </div>
      <div className="efb-form">
        <div className="form-row">
          <div className="table-responsive">
            <table className="sortable table table-hover table-striped efb-table static-page-table">
              <thead>
                <tr>
                  <th>No.</th>
                  {tableKeysAndLabels.map((label, rowIndex) => {
                    return (
                      <th key={label.key}>
                        <Button
                          disabled={tableKeysAndLabels.length === 1}
                          cssClass="btn btn-sm btn-outline-danger btn-DEMO-del-val column-delete-btn"
                          onClick={() => removeTableKeyAndLabel(rowIndex)}
                        >
                          <i className="fa fa-trash"></i>
                        </Button>
                        <Input
                          label={'label'}
                          name={'label'}
                          placeholder="label"
                          onChange={e => handleInputChange(e, rowIndex, 0)}
                        />
                      </th>
                    );
                  })}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Table key</td>
                  {tableKeysAndLabels.map((key, rowIndex) => {
                    return (
                      <td key={key.key}>
                        <Input
                          key={'key'}
                          name={'key'}
                          placeholder="key"
                          onChange={e => handleInputChange(e, rowIndex, 1)}
                        />
                      </td>
                    );
                  })}
                  <td>/</td>
                </tr>
                {tableColumns.map((column, columnIndex) => {
                  return (
                    <tr key={column[0].key}>
                      <td>{columnIndex + 1}.</td>
                      {column.map((tableColumn, rowIndex) => {
                        return (
                          <td key={tableColumn.key}>
                            <Input
                              label={'row'}
                              name={'row'}
                              placeholder="row"
                              onChange={e =>
                                handleInputChange(e, rowIndex, columnIndex)
                              }
                            />
                          </td>
                        );
                      })}
                      <td>
                        <Button
                          disabled={tableColumns.length === 1}
                          cssClass="efb-btn__main"
                          onClick={() => deleteTableRow(columnIndex)}
                        >
                          <i className="fa fa-trash"></i> Delete data row
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <br />

        <div className="form-row">
          <Button cssClass={'efb-btn__main'} onClick={addTableColumn}>
            Add data row
          </Button>
          <Button
            disabled={tableColumns.length === 0}
            cssClass={'efb-btn__main'}
            onClick={addTableRow}
          >
            Add table column cell
          </Button>
        </div>
        <br />
        <hr />
      </div>
    </>
  );
};

TableContentRegular.propTypes = {
  currentLang: PropTypes.object,
  subpage: PropTypes.object,
  subpageIndex: PropTypes.number,
};

export default TableContentRegular;
