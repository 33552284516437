import React, { Fragment, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import MarketCategoriesTableRow from './MarketCategoriesTableRow';
import Button from '../../../components/Layout/Button';
import DragAndDrop from '../../../components/DragAndDrop/DragAndDrop';
import { useMount, useUpdate } from '../../../hooks';

const MarketCategoriesTable = ({
  categoryList,
  setResetOrdering,
  deleteCategory,
  editCategory,
  viewMarketTypes,
  selectedBusinessUnit,
  addNotificationMessage,
  saveRanking,
}) => {
  const [orderedMarketCategories, setOrderedMarketCategories] = useState('');
  const [changed, setChanged] = useState(false);

  useMount(() => {
    setResetOrdering(resetOrdering);
  });

  useUpdate(() => {
    setResetOrdering(resetOrdering);
    orderByRank(categoryList);
  }, [selectedBusinessUnit, categoryList]);

  const resetOrdering = useCallback(() => {
    setOrderedMarketCategories(categoryList);
    setChanged(false);
  }, [categoryList]);

  const orderByRank = useCallback(
    list => {
      const newList = [...list].sort((a, b) => {
        if (
          a.rank[selectedBusinessUnit.label || 'BG'] &&
          b.rank[selectedBusinessUnit.label || 'BG']
        ) {
          return (
            a.rank[selectedBusinessUnit.label || 'BG'].rank -
            b.rank[selectedBusinessUnit.label || 'BG'].rank
          );
        }
        return 0;
      });
      setOrderedMarketCategories(newList);
    },
    [selectedBusinessUnit]
  );

  const onDrop = useCallback(newData => {
    setOrderedMarketCategories(newData);
    setChanged(true);
  }, []);

  const mapMarkets = useMemo(() => {
    return orderedMarketCategories
      ? orderedMarketCategories.map((market, i) => ({
          businessUnit: selectedBusinessUnit.value,
          id: market.id,
          type: 'CATEGORY',
          rank: i,
        }))
      : [];
  }, [orderedMarketCategories, selectedBusinessUnit]);

  const saveMarketCatRanking = useCallback(() => {
    if (!selectedBusinessUnit.value) {
      addNotificationMessage('Select BU first!', 'error', 'Error!');
      return;
    }
    saveRanking(mapMarkets, 'Market categories saved successfully!');
    setChanged(false);
  }, [
    addNotificationMessage,
    mapMarkets,
    saveRanking,
    selectedBusinessUnit.value,
  ]);

  const moveToTop = useCallback(
    (category, index) => {
      const categoriesClone = [...orderedMarketCategories];
      categoriesClone.splice(index, 1);
      categoriesClone.unshift(category);
      setOrderedMarketCategories(categoriesClone);
      setChanged(true);
    },
    [orderedMarketCategories]
  );

  return (
    <Fragment>
      <h3>Market Categories</h3>
      {changed && (
        <div className="d-flex flex-row mb-3">
          <Button onClick={saveMarketCatRanking} cssClass="btn btn-primary">
            Save Rankings
          </Button>
          <Button onClick={resetOrdering} cssClass="btn btn-danger">
            Reset Rankings
          </Button>
        </div>
      )}
      <table className="table table-striped table-sm efb-table efb-table-translations">
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <DragAndDrop data={orderedMarketCategories} onDrop={onDrop}>
          {({ droppableProps, draggableProps }) => (
            <tbody {...droppableProps}>
              {orderedMarketCategories
                ? orderedMarketCategories.map((category, index) => {
                    return (
                      <MarketCategoriesTableRow
                        key={index}
                        category={category}
                        viewMarketTypes={viewMarketTypes}
                        index={index}
                        deleteCategory={deleteCategory}
                        editCategory={editCategory}
                        draggableProps={draggableProps}
                        moveToTop={moveToTop}
                      />
                    );
                  })
                : null}
            </tbody>
          )}
        </DragAndDrop>
      </table>
    </Fragment>
  );
};

MarketCategoriesTable.propTypes = {
  addNotificationMessage: PropTypes.func.isRequired,
  saveRanking: PropTypes.func.isRequired,
  setResetOrdering: PropTypes.func.isRequired,
  categoryList: PropTypes.array,
  deleteCategory: PropTypes.func,
  editCategory: PropTypes.func,
  viewMarketTypes: PropTypes.func,
};

export default MarketCategoriesTable;
