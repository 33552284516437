import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from '../../Layout';

const FilteredTransactionsRow = ({ tableData, resolve }) => {
  const renderRow = useCallback(
    (transaction, i) => {
      return (
        <tr key={transaction.id}>
          <td>{i + 1}.</td>
          <td>
            {moment(transaction.createDate).format('DD/MM/YYYY HH:mm:ss')}
          </td>
          <td>{transaction.username}</td>
          <td>{transaction.currency}</td>
          <td>{Number(transaction.amount / 100).toFixed(2)}</td>
          <td>{transaction.transactionType}</td>
          <td>{transaction.paymentMethod}</td>
          <td>{transaction.status}</td>
          <td>
            <Button
              disabled={transaction.status === 'ACCEPTED'}
              cssClass="btn btn-main"
              onClick={() => resolve(transaction)}
            >
              <i className="fa fa-check-square" /> Resolve manually
            </Button>
          </td>
        </tr>
      );
    },
    [resolve]
  );
  return tableData.map(renderRow);
};

FilteredTransactionsRow.propTypes = {
  tableData: PropTypes.array,
  resolve: PropTypes.func,
};

export default React.memo(FilteredTransactionsRow);
