import React, { Fragment, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  filtersObj,
  resetEventForm,
  resetMarketObj,
} from './Form/EventBuilderObj';
import FiltersContainer from './Form/FiltersContainer';
import {
  getCMSData,
  createLoadingSelector,
  getLanguages,
} from '../../../selectors';
import * as EventBuilderActions from '../../../redux/actions/cms/eventBuilder';
import {
  eventObj,
  marketObj,
  updateMarketObj,
  validateMarketForm,
  updateEventStatus,
  validateEventForm,
} from './EventBuilderUtils';
import PropTypes from 'prop-types';
import { addNotificationMessage } from '../../../redux/actions/ui';
import EventsTable from './EventsList/EventsTable';
import MarketsTable from './MarketsList/MarketsTable';
import { useUpdate, useMount } from '../../../hooks';
import * as LanguagesActions from '../../../redux/actions/Languages';

const EventBuilder = ({
  sports,
  countries,
  seasons,
  tournaments,
  homeCompetitors,
  awayCompetitors,
  loaded,
  marketTypes,
  marketCategories,
  eventMarkets,
  events,
  getCountries,
  updatedDefaultMarket,
  getSports,
  getTournaments,
  getSeasons,
  resetSelectors,
  proceedToGetSportObj,
  addNotificationMessage,
  getEvents,
  createEvent,
  updateEventStartTime,
  updateMarket,
  outcomes,
  getMarketCategories,
  getMarketTypes,
  getMarketTypeObjDetails,
  getOutcomes,
  getCompetitors,
  emptySports,
  marketType,
  sportObj,
  createMarket,
  emptyMarketType,
  getAllLanguages,
  languages,
  updateMatchStatus,
  updateDefaultMarket,
}) => {
  const [filterType, setFilterType] = useState('event_info');
  const [filters, setFilters] = useState(filtersObj);
  const [sportEventObj, setSportEventObj] = useState('');
  const [eventMarketsList, setEventMarketsList] = useState([]);

  useMount(() => {
    getSports();
    getAllLanguages();
  });

  useUpdate(() => {
    setEventMarketsList(eventMarkets);
    pushToMarketTypesList();
    if (filters.markets[0].marketCategory) {
      if (outcomes.length > 0 && filters.markets[0].marketType !== '')
        setStateOutcomes();
    }
    if (marketType) {
      const filtersClone = { ...filters };
      filtersClone.specifiers = marketType.specifiers;
      filtersClone.markets[0].specifiers = '';
      setFilters(filtersClone);
    }
    setSportEventObj(sportObj);
  }, [eventMarkets, marketTypes, outcomes, sportObj, marketType]);

  const pushToMarketTypesList = useCallback(() => {
    const filtersClone = { ...filters };
    filtersClone.marketTypesList[0] = marketTypes;
    setFilters(filtersClone);
  }, [marketTypes, filters]);

  const setStateOutcomes = useCallback(() => {
    const filtersClone = { ...filters };
    filtersClone.markets[0].outcomes = outcomes;
    setFilters(filtersClone);
  }, [filters, outcomes]);

  const updateFilter = useCallback(
    (filter, tab, name) => {
      const filtersClone = { ...filters };
      filtersClone[tab] = filter;
      setFilters(filtersClone);
      switch (name) {
        case 'sport':
          getCountries(filtersClone.general.sport);
          break;

        case 'country':
          getTournaments(filtersClone.general.country);
          getSeasons(filtersClone.general.country);
          break;

        case 'tournament':
          getEvents(filtersClone.general.tournament);
          break;

        case 'marketCategory':
          filtersClone.markets[0].outcomes = [];
          filtersClone.markets[0].marketType = '';
          setFilters(filtersClone);
          getMarketTypes(filters.markets[0].marketCategory.value.id);
          break;

        case 'marketType':
          // specifically check if it's not empty string
          if (filtersClone.markets[0].marketType !== '') {
            const marketTypeObj = filtersClone.markets[0].marketType;
            getMarketTypeObjDetails(marketTypeObj.value.id);
            getOutcomes(marketTypeObj.value.id);
          }
          break;

        default:
          return '';
      }
    },
    [
      filters,
      getCountries,
      getEvents,
      getMarketTypeObjDetails,
      getMarketTypes,
      getOutcomes,
      getSeasons,
      getTournaments,
    ]
  );

  const createSportEvent = useCallback(
    e => {
      e.preventDefault();
      const isValid = validateEventForm(filters.general);
      if (isValid) addNotificationMessage(isValid, 'error', 'Error');
      else {
        createEvent(eventObj(filters.general));
        const filtersClone = { ...filters };
        filtersClone.general = resetEventForm;
        setFilters(filtersClone);
        resetSelectors();
      }
    },
    [addNotificationMessage, createEvent, filters, resetSelectors]
  );

  const createSportEventMarket = useCallback(
    e => {
      e.preventDefault();
      const filtersClone = { ...filters };
      const isValid = validateMarketForm(filtersClone);
      if (isValid) addNotificationMessage(isValid, 'error', 'Error');
      else {
        createMarket(
          marketObj(sportEventObj, filters.markets[0], marketType),
          sportObj,
          eventMarkets.length
        );
        filtersClone.markets = [resetMarketObj()];
        filtersClone.specifiers = [];
        setFilters(filtersClone);
      }
    },
    [
      filters,
      addNotificationMessage,
      createMarket,
      sportEventObj,
      marketType,
      sportObj,
      eventMarkets,
    ]
  );

  const getCompetitorsList = debounce((value, side) => {
    if (value.length >= 3) {
      getCompetitors(value, filters.general.sport, side);
    }
  }, 1000);

  const addMarketToEvent = useCallback(
    event => {
      getMarketCategories();
      setFilterType('market_list');
      proceedToGetSportObj(event.id);
      window.scrollTo(0, 0);
      proceedToGetSportObj(event.id);
    },
    [getMarketCategories, proceedToGetSportObj]
  );

  const backToBuilder = useCallback(() => {
    const filtersClone = { ...filters };
    filtersClone.markets = [
      { marketCategory: '', marketType: '', outcomes: [], assigned: false },
    ];
    filtersClone.specifiers = [];
    emptyMarketType();
    emptySports();
    getSports();
    setFilterType('event_info');
    setFilters(filtersClone);
  }, [emptySports, filters, getSports, emptyMarketType]);

  const proceedToUpdateMarketStatus = useCallback(
    market => {
      const marketObj = updateMarketObj(market, sportObj);
      if (Object.keys(marketObj).length === 0) {
        addNotificationMessage(
          'Cannot update this market, outcome odds are empty',
          'error',
          'Error'
        );
      } else {
        updateMarket(marketObj);
      }
    },
    [updateMarket, addNotificationMessage, sportObj]
  );

  const prepareToUpdateDefaultMarket = useCallback(
    (currentDefaultMarket, previousDefaultMarket) => {
      updateDefaultMarket(
        previousDefaultMarket
          ? updateMarketObj(previousDefaultMarket, sportObj)
          : undefined,
        updateMarketObj(currentDefaultMarket, sportObj)
      );
    },
    [sportObj, updateDefaultMarket]
  );

  const updateMarketOdds = useCallback(
    market => {
      updateMarket(updateMarketObj(market, sportObj), 'marketPrice');
    },
    [sportObj, updateMarket]
  );

  const prepareMatchToUpdate = useCallback(
    event => {
      updateMatchStatus(updateEventStatus(event), event.id, event.tournamentId);
    },
    [updateMatchStatus]
  );

  return (
    <Fragment>
      <FiltersContainer
        filterType={filterType}
        filters={filters}
        listOfSports={sports}
        listOfCountries={countries}
        listOfSeasons={seasons}
        listOfTournaments={tournaments}
        homeCompetitors={homeCompetitors}
        awayCompetitors={awayCompetitors}
        getCompetitors={getCompetitorsList}
        createEvent={createSportEvent}
        loaded={loaded}
        updateFilter={updateFilter}
        marketTypes={marketTypes}
        marketCategories={marketCategories}
        createSportEventMarket={createSportEventMarket}
        eventMarkets={eventMarketsList}
        backToBuilder={backToBuilder}
        sportObj={sportObj}
        languages={languages}
      />
      <br />
      {filterType === 'event_info' ? (
        <EventsTable
          events={events}
          addMarketToEvent={addMarketToEvent}
          updateEventStartTime={updateEventStartTime}
          filters={filters}
          updateMatchStatus={prepareMatchToUpdate}
        />
      ) : null}
      {filterType === 'market_list' ? (
        <MarketsTable
          loaded={loaded}
          eventMarkets={eventMarkets}
          updateMarketOdds={updateMarketOdds}
          proceedToUpdateMarketStatus={proceedToUpdateMarketStatus}
          updatedDefaultMarket={updatedDefaultMarket}
          updateDefaultMarket={prepareToUpdateDefaultMarket}
        />
      ) : null}
    </Fragment>
  );
};

EventBuilder.propTypes = {
  competitors: PropTypes.array,
  countries: PropTypes.array,
  createEvent: PropTypes.func,
  createMarket: PropTypes.func,
  getCompetitors: PropTypes.func,
  getCountries: PropTypes.func,
  getMarketCategories: PropTypes.func,
  getMarketTypes: PropTypes.func,
  getOutcomes: PropTypes.func,
  getSeasons: PropTypes.func,
  getSportObj: PropTypes.func,
  getSports: PropTypes.func,
  getTournaments: PropTypes.func,
  marketCategories: PropTypes.array,
  marketTypes: PropTypes.array,
  outcomes: PropTypes.array,
  seasons: PropTypes.array,
  sportObj: PropTypes.object,
  tournaments: PropTypes.array,
  sports: PropTypes.array,
  outcomesId: PropTypes.number,
  eventMarkets: PropTypes.array,
  addDefaultMarkets: PropTypes.func,
  removeMarket: PropTypes.func,
};

const loadingReducer = createLoadingSelector([
  'HOME_COMPETITORS',
  'AWAY_COMPETITORS',
  'GET_MARKET_CATEGORIES',
  'GET_MARKET_TYPES',
  'GET_OUTCOMES',
  'GET_EVENT_MARKETS',
  'UPDATED_DEFAULT_MARKET',
]);

const mapStateToProps = state => ({
  countries: getCMSData(state).eventBuilderReducer.countries,
  sports: getCMSData(state).eventBuilderReducer.sports,
  seasons: getCMSData(state).eventBuilderReducer.seasons,
  tournaments: getCMSData(state).eventBuilderReducer.tournaments,
  homeCompetitors: getCMSData(state).eventBuilderReducer.homeCompetitors,
  awayCompetitors: getCMSData(state).eventBuilderReducer.awayCompetitors,
  marketTypes: getCMSData(state).eventBuilderReducer.marketTypes,
  marketCategories: getCMSData(state).eventBuilderReducer.marketCategories,
  outcomes: getCMSData(state).eventBuilderReducer.outcomes,
  sportObj: getCMSData(state).eventBuilderReducer.sportObj,
  eventMarkets: getCMSData(state).eventBuilderReducer.eventMarkets,
  events: getCMSData(state).eventBuilderReducer.events,
  marketType: getCMSData(state).eventBuilderReducer.marketType,
  languages: getLanguages(state).languages,
  loaded: loadingReducer(state),
});

const mapDispatchToProps = {
  getCountries: EventBuilderActions.getCountries,
  getSports: EventBuilderActions.getSports,
  getTournaments: EventBuilderActions.getTournaments,
  createEvent: EventBuilderActions.createEvent,
  getSeasons: EventBuilderActions.getSeasons,
  getCompetitors: EventBuilderActions.getCompetitors,
  getMarketTypes: EventBuilderActions.getMarketTypes,
  getMarketCategories: EventBuilderActions.getMarketCategories,
  getMarketTypeObjDetails: EventBuilderActions.getMarketTypeObjDetails,
  getOutcomes: EventBuilderActions.getOutcomes,
  getSportObj: EventBuilderActions.getSportObj,
  createMarket: EventBuilderActions.createMarket,
  addNotificationMessage,
  getEvents: EventBuilderActions.getEvents,
  proceedToGetSportObj: EventBuilderActions.proceedToGetSportObj,
  emptySports: EventBuilderActions.emptySports,
  updateMarket: EventBuilderActions.updateMarket,
  resetSelectors: EventBuilderActions.resetSelectors,
  resetMarketCategories: EventBuilderActions.resetMarketCategories,
  updateEventStartTime: EventBuilderActions.updateEventStartTime,
  emptyMarketType: EventBuilderActions.emptyMarketType,
  getAllLanguages: LanguagesActions.getAllLanguages,
  updateMatchStatus: EventBuilderActions.updateMatchStatus,
  updateDefaultMarket: EventBuilderActions.updateDefaultMarket,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventBuilder)
);
