import React from 'react';

export const RiskMng = (props) => {
    return (
	<span className='efb-icon'>
    	<svg className={`efb-icon--${props.size} ${props.cssClass}`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 128 128" >
	 
            <path fill="#91BAE1" d="M61.1,123.3L61.1,123.3C94.7,110.7,117,78.6,117,42.7V20.9c-11.5,0-20.9-9.4-20.9-20.9H61.1H26
                c0,11.5-9.4,20.9-20.9,20.9v21.8C5.1,78.6,27.4,110.7,61.1,123.3L61.1,123.3z"/>
            <path fill="#556080" d="M61.1,123.3L61.1,123.3C94.7,110.7,117,78.6,117,42.7V20.9c-11.5,0-20.9-9.4-20.9-20.9H61.1
                C61.1,0,61.1,95.9,61.1,123.3L61.1,123.3z"/>
            <polygon fill="#FFFFFF" points="53.3,83.4 34.7,64.9 44.9,54.6 53.3,63 77.2,39 87.4,49.3 	"/>

            <path fill="#FEB719" d="M91.8,99.6h26.7c2.4,0,4.4,2,4.4,4.4v19.6c0,2.4-2,4.4-4.4,4.4H91.8c-2.4,0-4.4-2-4.4-4.4V104
                C87.4,101.6,89.4,99.6,91.8,99.6z"/>
            <path fill="#556080" d="M110.5,112c0-2.9-2.4-5.3-5.3-5.3s-5.3,2.4-5.3,5.3c0,2.3,1.5,4.3,3.6,5c0,0.1-0.1,0.2-0.1,0.3v3.5
                c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8v-3.5c0-0.1,0-0.2-0.1-0.3C108.9,116.3,110.5,114.4,110.5,112z"/>
            <path fill="#BDC3C7" d="M96.3,96.1c0-4.9,4-8.9,8.9-8.9c4.9,0,8.9,4,8.9,8.9v3.5h3.5v-3.5c0-6.8-5.6-12.4-12.4-12.4
                c-6.8,0-12.4,5.6-12.4,12.4v3.5h3.5V96.1z"/>

    	</svg>     
		{props.children}
	</span>
    )
}

export default RiskMng;