import { CustomerValidationsReducer } from './CustomerValidationReducer';
import { authReducer } from './authRducer';
import { birDelayReducer } from './birDelayReducer';
import { bonusSystemReducer } from './bonusSystemReducer';
import { cashoutManagerReducer } from './riskManagement/cashoutManagerReducer';
import { cmsReducer } from './cms/index';
import { combineReducers } from 'redux';
import { customerReducer } from '../reducers/customer/index';
import { errorReducer } from './errorReducer';
import { languagesReducer } from './LanguagesReducer';
import { liveStreamingReducer } from './LiveStreamingReducer';
import { loadingReducer } from './loadingReducer';
import { notificationReducer } from './notificationReducer';
import { riskManagementReducer } from './riskManagement/index';
import { rolesReducer } from './rolesReducer';
import { transactionReducer } from './transactionReducer';
import { uiComponentsReducer } from './uiComponentsReducer';
import { messageTemplatesReducer } from './messageTemplatesReducer';
import { userReducer } from './userReducer';
import { wsReducer } from './wsReducer';

//Imoport Reducers here

export default combineReducers({
  auth: authReducer,
  users: userReducer,
  uiComponentsReducer: uiComponentsReducer,
  customerReducer: customerReducer,
  rolesReducer: rolesReducer,
  errorReducer: errorReducer,
  loadingReducer: loadingReducer,
  notificationReducer: notificationReducer,
  riskManagementReducer: riskManagementReducer,
  wsReducer: wsReducer,
  cmsReducer: cmsReducer,
  languagesReducer: languagesReducer,
  birDelayReducer: birDelayReducer,
  transactionReducer: transactionReducer,
  cashoutManagerReducer: cashoutManagerReducer,
  liveStreamingReducer: liveStreamingReducer,
  bonusSystemReducer: bonusSystemReducer,
  messageTemplatesReducer: messageTemplatesReducer,
  CustomerValidationsReducer: CustomerValidationsReducer,
});
