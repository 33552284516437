import React, { useState, useCallback, useMemo } from 'react';
import Table from '../../../../components/Table/Table';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal/Modal';
import TextEditor from '../ContentCreator/TextEditor';
import InputField from '../../../../components/Form/InputField';
import Button from '../../../../components/Layout/Button';
import { prepareHtml } from '../StaticContentUtils';
import Select from 'react-select';
import { selectStyles } from '../../customReactSelectStyle';
import {
  useStateForSelect,
  useUpdate,
  useStateForInput,
} from '../../../../hooks';

const PageTable = ({
  getRegularPageContent,
  showSubpages,
  pages,
  editPage,
  updatePageStatus,
  saveEditedName,
  pagesListLang,
  editPageContent,
  extendPageContent,
  regularPageContent,
  images,
  uploadImage,
  updateRegularPageContent,
  deletePage,
}) => {
  const [regularContentEdit, setRegularContentEdit] = useState(false);
  const [regularPageName, setRegularPageName] = useStateForInput('');
  const [regularPageHtml, setRegularPageHtml] = useState('');
  const [regularPageId, setRegularPageId] = useState('');
  const [currentPage, setCurrentPage] = useStateForSelect('');

  const columns = useMemo(() => {
    return [
      { name: 'No.', value: 'no', type: 'string' },
      { name: 'Language', value: 'lang', type: 'string' },
      { name: 'Name', value: 'name', type: 'string' },
      { name: 'Active', value: 'active', type: 'bool' },
      { name: 'Created on', value: 'created', type: 'timestamp' },
      { name: 'Updated on', value: 'updated', type: 'timestamp' },
      { name: 'Action', value: 'action', type: '' },
    ];
  }, []);

  useUpdate(() => {
    if (currentPage) {
      setRegularPageName(currentPage.label);
      setRegularPageId(currentPage.value);
      const url = currentPage.url.replace('/api/backend', '');
      getRegularPageContent(url);
    }
  }, [currentPage]);

  const handleInputChange = useCallback(e => {
    const html = prepareHtml(e.target.value);
    setRegularPageHtml(html);
  }, []);

  const prepareRegularPageEdit = useCallback(
    pageId => {
      const rootPages = pages.find(x => x.children[0].id === pageId);
      setRegularContentEdit(true);

      setCurrentPage({
        value: rootPages.children[0].id,
        label: rootPages.children[0].name,
        url: rootPages.children[0].url,
      });
    },
    [pages, setCurrentPage]
  );

  const closeModal = useCallback(() => {
    setRegularContentEdit(false);
    setRegularPageHtml('');
    setRegularPageId('');
    setCurrentPage('');
    setRegularPageName('');
  }, [setCurrentPage, setRegularPageName]);

  const updateContent = useCallback(() => {
    updateRegularPageContent(
      { html: regularPageHtml, name: regularPageName },
      regularPageId
    );
    setCurrentPage({ ...currentPage, label: regularPageName });
  }, [
    currentPage,
    regularPageHtml,
    regularPageId,
    regularPageName,
    setCurrentPage,
    updateRegularPageContent,
  ]);

  const regularPages = useMemo(() => {
    if (!regularPageId) {
      return [];
    }

    const rootPages = pages.find(x =>
      x.children.find(c => c.id === regularPageId)
    );

    if (rootPages) {
      return rootPages.children.reduce(
        (acc, page) => [
          ...acc,
          { value: page.id, label: page.name, url: page.url },
        ],
        []
      );
    }
  }, [pages, regularPageId]);

  return (
    <>
      <Modal
        isActive={regularContentEdit}
        onClose={closeModal}
        headerText="Edit"
      >
        <div className="modal-wrapper">
          <div className="text-left">
            <Select
              value={currentPage}
              options={regularPages}
              placeholder={'Select Regular subpage to edit'}
              onChange={setCurrentPage}
              styles={selectStyles}
            />
          </div>

          <hr />
          <div className="efb-form">
            <div className="form-row">
              <div className="col-md form-label-group">
                <InputField
                  label="Name"
                  value={regularPageName}
                  onChange={setRegularPageName}
                />
              </div>
            </div>
            <TextEditor
              htmlBody={handleInputChange}
              images={images}
              uploadImage={uploadImage}
              value={regularPageContent.htmlContent}
            />
          </div>
          <br />
          <hr />
          <div className="form-group d-flex flex-row justify-content-start">
            <Button cssClass="efb-btn__main" onClick={updateContent}>
              Update
            </Button>
            <Button cssClass="efb-btn__main" onClick={closeModal}>
              {' '}
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <Table
        columns={columns}
        data={pages}
        editPage={editPage}
        showSubpages={showSubpages}
        prepareRegularPageEdit={prepareRegularPageEdit}
        updatePageStatus={updatePageStatus}
        pagesListLang={pagesListLang}
        editPageContent={editPageContent}
        saveEditedName={saveEditedName}
        extendPageContent={extendPageContent}
        regularPageContent={regularPageContent}
        deletePage={deletePage}
        tableType="staticPagesTable"
        tableStyle="sortable table table-hover table-striped efb-table efb-data-table"
      />
    </>
  );
};

PageTable.propTypes = {
  showSubpages: PropTypes.func,
  pages: PropTypes.array,
  editPage: PropTypes.func,
  updatePageStatus: PropTypes.func,
  saveEditedName: PropTypes.func,
};

export default PageTable;
