import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../../components/Form/Checkbox';

class AppViewPrivilegesRoles extends Component {
  handleActionsChange = (action, viewLabel) => {
    if (!this.props.readOnly) {
      let roleChange = { ...this.props.transformedRole };
      if (roleChange[roleChange.appLabel][viewLabel]) {
        let view = roleChange[roleChange.appLabel][viewLabel];
        if (view.actions.includes(action)) {
          let updatedActions = [];
          if (action === 'write') {
            updatedActions = view.actions.filter(
              viewAction => viewAction !== action
            );
          }
          view.actions = [...updatedActions];
        } else {
          let updatedActions =
            action === 'write' ? ['read', 'write'] : ['read'];
          view.actions = [...updatedActions];
        }
      }
      this.props.updateRole(roleChange);
    }
  };

  generateActionsColumn = view => {
    let actionsColumn;
    switch (view.actions.length) {
      case 0:
        actionsColumn = (
          <Fragment>
            <Checkbox
              type="userActivity"
              readOnly={true}
              isActive={false}
              name="read"
              label="Read"
              disabled={this.props.readOnly}
              onChange={() => this.handleActionsChange('read', view.label)}
            />
            <Checkbox
              type="userActivity"
              readOnly={true}
              isActive={false}
              name="write"
              label="Write"
              disabled={this.props.readOnly}
              onChange={() => this.handleActionsChange('write', view.label)}
            />
          </Fragment>
        );
        break;
      case 1:
        actionsColumn = (
          <Fragment>
            <Checkbox
              type="userActivity"
              readOnly={this.props.readOnly}
              isActive={true}
              name="read"
              label="Read"
              disabled={this.props.readOnly}
              onChange={() => this.handleActionsChange('read', view.label)}
            />
            <Checkbox
              type="userActivity"
              readOnly={this.props.readOnly}
              isActive={false}
              name="write"
              label="Write"
              disabled={this.props.readOnly}
              onChange={() => this.handleActionsChange('write', view.label)}
            />
          </Fragment>
        );
        break;
      case 2:
        actionsColumn = (
          <Fragment>
            <Checkbox
              type="userActivity"
              readOnly={this.props.readOnly}
              isActive={true}
              name="read"
              label="Read"
              disabled={this.props.readOnly}
              onChange={() => this.handleActionsChange('read', view.label)}
            />
            <Checkbox
              type="userActivity"
              readOnly={this.props.readOnly}
              isActive={true}
              name="write"
              label="Write"
              disabled={this.props.readOnly}
              onChange={() => this.handleActionsChange('write', view.label)}
            />
          </Fragment>
        );
        break;
      default:
        actionsColumn = null;
        break;
    }
    return actionsColumn;
  };

  render() {
    let { transformedRole } = this.props;
    return (
      <Fragment>
        <Fragment>
          <section className="efb-app-view-priv-header">
            <span style={{ width: '20%' }}>Role</span>
            <span style={{ width: '30%' }}>Application</span>
            <span style={{ width: '30%' }}>View</span>
            <span style={{ width: '20%' }}>Privileges/Actions</span>
          </section>
          <section className="efb-app-view-priv-container">
            <section className="efb-app-view-priv-container role-section">
              <section style={{ width: '20%' }}>{transformedRole.role}</section>
              {transformedRole.appLabel ? (
                <Fragment>
                  <section style={{ width: '30%' }}>
                    {transformedRole.appLabel}
                  </section>
                  <section
                    style={{ flexDirection: 'column', width: '50%' }}
                    className="efb-app-view-priv"
                  >
                    {Object.keys(transformedRole[transformedRole.appLabel]).map(
                      (key, idx) => (
                        <section
                          className="efb-app-view-priv__view-priv"
                          key={key}
                        >
                          <div
                            className={
                              transformedRole[transformedRole.appLabel][key]
                                .actions.length
                                ? 'efb-app-view-priv__view-priv__view'
                                : 'efb-app-view-priv__view-priv__view disabled'
                            }
                          >
                            <span>
                              {
                                transformedRole[transformedRole.appLabel][key]
                                  .label
                              }
                            </span>
                          </div>
                          <div className="efb-app-view-priv__view-priv__actions">
                            <span>{transformedRole.domainObject}</span>
                            <div>
                              {this.generateActionsColumn(
                                transformedRole[transformedRole.appLabel][key]
                              )}
                            </div>
                          </div>
                        </section>
                      )
                    )}
                  </section>
                </Fragment>
              ) : (
                <Fragment>
                  <section style={{ width: '30%' }}>
                    {transformedRole.appLabel}
                  </section>
                  <section
                    style={{ flexDirection: 'column', width: '50%' }}
                    className="efb-app-view-priv"
                  />
                </Fragment>
              )}
            </section>
          </section>
        </Fragment>
      </Fragment>
    );
  }
}

AppViewPrivilegesRoles.propTypes = {
  readOnly: PropTypes.bool,
  container: PropTypes.string,
  updateRole: PropTypes.func,
  transformedRole: PropTypes.object
};

export default AppViewPrivilegesRoles;
