import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Input from '../../../../components/Form/Input';
import Switch from '../../../../components/Form/Switch/Switch';
import Button from '../../../../components/Layout/Button';
import * as RiskCategoryActions from '../../../../redux/actions/riskManagement/RiskCategory';
import * as UiActions from '../../../../redux/actions/ui';

const requiredFieldsMessage = 'Name and max bets fields are required';

const NewCategory = React.memo(
  ({
    updateRiskCategory,
    createRiskCategory,
    resetCategory,
    category,
    addNotificationMessage,
  }) => {
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [referralMandatoryCnt, setReferralMandatoryCnt] = React.useState('');
    const [autopilotLimit, setAutopilotLimit] = React.useState('');
    const [color, setColor] = React.useState('');
    const [skipReferral, setSkipReferral] = React.useState(false);
    const [autopilotAware, setAutopilotAware] = React.useState(false);

    React.useEffect(() => {
      if (Object.keys(category).length) {
        setName(category.name || '');
        setDescription(category.description || '');
        setReferralMandatoryCnt(
          typeof category.referralMandatoryCnt === 'number'
            ? category.referralMandatoryCnt
            : ''
        );
        setAutopilotLimit(
          typeof category.autopilotLimit === 'number'
            ? category.autopilotLimit
            : ''
        );
        setColor(category.color || '#ffffff');
        setSkipReferral(category.skipReferral || false);
        setAutopilotAware(category.autopilotAware || false);
      }
    }, [category]);

    const resetForm = () => {
      setName('');
      setDescription('');
      setReferralMandatoryCnt('');
      setAutopilotLimit('');
      setColor('#ffffff');
      setSkipReferral(false);
      setAutopilotAware(false);
    };

    const validateFields = () => {
      if (typeof referralMandatoryCnt !== 'number') {
        return 0;
      }
      if (!name) {
        return 0;
      }
      if (!color) {
        return 0;
      }
      return 1;
    };

    const createCategory = () => {
      if (!validateFields()) {
        addNotificationMessage(requiredFieldsMessage, 'error', 'Error!');
        return;
      }
      createRiskCategory({
        name,
        description,
        referralMandatoryCnt,
        color,
        skipReferral,
        autopilotAware,
        autopilotLimit,
      });
      clearForm();
    };

    const updateCategory = () => {
      if (!validateFields()) {
        addNotificationMessage(requiredFieldsMessage, 'error', 'Error!');
        return;
      }
      updateRiskCategory({
        id: category.id,
        name,
        description,
        referralMandatoryCnt,
        color,
        skipReferral,
        autopilotAware,
        autopilotLimit,
      });
      clearForm();
    };

    const clearForm = () => {
      resetCategory();
      resetForm();
    };

    return (
      <>
        <div className="form-row mt-5">
          <div className="col">
            <Input
              placeholder="Name"
              className="form-control"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="col">
            <Input
              placeholder="Description"
              className="form-control"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </div>
          <div className="col">
            <Input
              type="number"
              className="form-control"
              placeholder="Max bets for referral"
              value={referralMandatoryCnt}
              onChange={e => {
                if (Math.sign(Number(e.target.value)) === -1) {
                  return;
                }
                setReferralMandatoryCnt(Number(e.target.value));
              }}
            />
          </div>
          <div className="col">
            <Input
              type="number"
              className="form-control"
              placeholder="Autopilot Limit"
              value={autopilotLimit}
              onChange={e => {
                if (Math.sign(Number(e.target.value)) === -1) {
                  return;
                }
                setAutopilotLimit(Number(e.target.value));
              }}
            />
          </div>
          <div className="col">
            <Input
              type="color"
              className="form-control"
              name="color"
              value={color}
              onChange={e => setColor(e.target.value)}
            />
          </div>
          <Switch
            value={skipReferral}
            name="skipReferral"
            label="Skip Referral"
            isChecked={skipReferral}
            onChange={e => setSkipReferral(e.target.checked)}
          />
          <Switch
            value={autopilotAware}
            name="autopilotAware"
            label="Directly to referral"
            isChecked={autopilotAware}
            onChange={e => setAutopilotAware(e.target.checked)}
          />
        </div>
        <div className="form-row mt-3">
          <Button
            cssClass="btn btn-primary"
            onClick={
              Object.keys(category).length ? updateCategory : createCategory
            }
          >
            {Object.keys(category).length ? 'Update' : 'Create'} Category
          </Button>
          <Button cssClass="btn btn-secondary" onClick={clearForm}>
            Clear form
          </Button>
        </div>
      </>
    );
  }
);

NewCategory.propTypes = {
  createRiskCategory: PropTypes.func,
  updateRiskCategory: PropTypes.func,
  category: PropTypes.object,
  resetCategory: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  createRiskCategory: RiskCategoryActions.createRiskCategory,
  updateRiskCategory: RiskCategoryActions.updateRiskCategory,
  addNotificationMessage: UiActions.addNotificationMessage,
};

export default connect(null, mapDispatchToProps)(NewCategory);
