import React, { useState, useCallback } from 'react';
import Input from '../../../Form/Input';
import classes from './SearchFilter.module.scss';
import SearchIcon from '../../../Icons/UI/ui/IconSearch';

const SearchFilter = ({ name, label, onFilterChange: pushFilterChange }) => {
  const [value, setValue] = useState('');

  const handleChange = useCallback(
    newValue => {
      setValue(newValue.target.value);
      pushFilterChange({ name, value: newValue.target.value });
    },
    [name, pushFilterChange, setValue]
  );

  return (
    <div className={classes.searchFilter}>
      <label>{label}:</label>
      <div className={classes.search}>
        <SearchIcon width={16} height={16} />
        <Input customInput value={value} onChange={handleChange} />
      </div>
    </div>
  );
};

export default SearchFilter;
