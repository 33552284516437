import PropTypes from 'prop-types';
import React from 'react';

const CheckboxSquare = ({
    onChange,
    name,
    readOnly,
    isActive,
    value,
    label,
    disabled,
    placeholder,
    checked
}) => (
        <div className='control-group'>
            <label className="control control--checkbox">
                <span className='label'>{label}</span>
                <input type="checkbox"
                    value={value}
                    onChange={onChange}
                    name={name}
                    checked={checked}
                    readOnly={readOnly}
                    disabled={disabled}
                    placeholder={placeholder}
                />
                <div className="control__indicator"></div>
            </label>
        </div>
    );

CheckboxSquare.propTypes = {
    disabled: PropTypes.bool,
    isActive: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    type: PropTypes.string
};

export default CheckboxSquare;
