import {
  GET_MARKETS_BY_SPORT_EVENT,
  EVENT_SEARCH_GET_COUNTRIES,
  EVENT_SEARCH_GET_SPORTS,
  EVENT_SEARCH_GET_TOURNAMENTS,
  EVENT_SEARCH_GET_EVENTS,
  SEARCH_SPORT_EVENTS,
} from '../../actions/cms/eventSearch';

const initialState = {
  sports: [],
  sportEventMarkets: [],
  countries: [],
  tournaments: [],
  events: [],
  searchSportEvents: [],
};

export const eventSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_SEARCH_GET_SPORTS.SUCCESS:
      return {
        ...state,
        sports: action.payload,
      };

    case EVENT_SEARCH_GET_COUNTRIES.SUCCESS:
      return {
        ...state,
        countries: action.payload,
        events: [],
        sportEventMarkets: [],
      };

    case EVENT_SEARCH_GET_TOURNAMENTS.SUCCESS:
      return {
        ...state,
        tournaments: action.payload,
      };

    case EVENT_SEARCH_GET_EVENTS.SUCCESS:
      return {
        ...state,
        events: action.payload,
      };

    case GET_MARKETS_BY_SPORT_EVENT.SUCCESS:
      return {
        ...state,
        sportEventMarkets: action.payload,
      };

    case SEARCH_SPORT_EVENTS.SUCCESS:
      return {
        ...state,
        searchSportEvents: action.payload.results,
      };

    case SEARCH_SPORT_EVENTS.EMPTY:
      return {
        ...state,
        searchSportEvents: [],
      };

    default:
      return state;
  }
};
