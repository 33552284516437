import React, { useState, useCallback } from 'react';
import { newBetScroller, validateBSTabs } from './BetScrollerUtils';
import { useUpdate } from '../../../hooks';

const BetScrollerTabs = ({
  betScrollersCopy,
  setBetScrollersCopy,
  openSelectedBSScroller,
  setCurrentBetScroller,
  setBetScrollerForTable,
  position,
  setPosition,
  ownId,
  addNotificationMessage,
}) => {
  const [toggle, setToggle] = useState(true);

  const renderBetScrollers = useCallback(() => {
    return betScrollersCopy.filter(bs =>
      toggle ? bs.createdUserId === ownId || !bs.id : bs.createdUserId !== ownId && bs.id
    );
  },[betScrollersCopy, ownId, toggle])();

  useUpdate(() => {
    if(renderBetScrollers.length > 0){
      setCurrentBetScroller(renderBetScrollers[position] || renderBetScrollers[0]);
      setBetScrollerForTable(renderBetScrollers[position] || renderBetScrollers[0]);
    }
  }, [renderBetScrollers, position])

  const addBetScroller = useCallback(() => {
    validateBSTabs(
      renderBetScrollers,
      ownId,
      betScrollersCopy,
      newBetScroller,
      setBetScrollersCopy,
      addNotificationMessage
    );
    setPosition(betScrollersCopy.length);
  }, [
    addNotificationMessage,
    betScrollersCopy,
    ownId,
    renderBetScrollers,
    setBetScrollersCopy,
    setPosition
  ]);

  return (
    <div className="mx-1">
      <div className="form-row p-2 bet-scroller-tabs"
     >
        {renderBetScrollers.map((bs, index) => {
          return (
            <div
              onClick={() => {
                setPosition(index);
                setCurrentBetScroller(bs);
              }}
              key={index}
              className={`bet-tab pointer btn ${
                index === position
                  ? 'active-bet-scroller'
                  : 'default-bet-scroller'
              }`}
            >
              <span>{bs.name}</span>
              <i
                className={`fas fa-${toggle ? 'pen' : 'info-circle'} ml-2`}
                onClick={() => openSelectedBSScroller(index, bs)}
              />
            </div>
          );
        })}
        {toggle ? (
          <div
            onClick={addBetScroller}
            className="px-4 py-1 bet-tab pointer btn default-bet-scroller"
          >
            + Add
          </div>
        ) : null}
        <div
          className="px-4 py-1 bet-tab pointer btn default-bet-scroller"
          onClick={() => setToggle(!toggle)}
        >
          {toggle ? 'Еxternal' : 'My'} BetScrollers
        </div>
      </div>
    </div>
  );
};

export default BetScrollerTabs