import React, { useMemo, useCallback } from 'react';
import { uniqBy } from 'lodash';
import BetScrollerTableRow from './BetScrollerTableRow';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

const BetScrollerTable = ({ betScrollerData, currentBetScroller }) => {
  const filterByMultiplieCriteria = useCallback(
    wsData => {
      const {
        totalBet,
        type,
        businessUnit,
        totalTakeout,
        betscrollerIds,
      } = wsData;
      const {
        betTypes,
        businessUnits,
        betStakeMax,
        betStakeMin,
        takeoutMin,
        id,
      } = currentBetScroller;

      if (totalBet < betStakeMin || totalBet > betStakeMax) {
        return false;
      }
      if (!businessUnits.includes(businessUnit) && !isEmpty(businessUnits)) {
        return false;
      }
      if (!betTypes.includes(type[0]) && !isEmpty(betTypes)) {
        return false;
      }
      if (takeoutMin && takeoutMin > totalTakeout) {
        return false;
      }
      if (!betscrollerIds.includes(id)) {
        return false;
      }
      return true;
    },
    [currentBetScroller]
  );

  const customerData = useMemo(() => {
    const limit = 100;
    if (betScrollerData.length >= limit) {
      betScrollerData.length = limit;
    }
    return uniqBy([...betScrollerData], 'createdAt')
      .map(data => ({
        betscrollerIds: data.betscrollerIds,
        businessUnit: data.businessUnit,
        channel: data.channel,
        status: data.type,
        riskRules: data.riskRules,
        riskCategoryColor: data.riskCategoryColor,
        customerId: data.customerId,
        createdAt: moment(data.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        customerName: data.customerName,
        type: data.systemBets.map(bets => bets.type),
        totalBet: (Number(data.totalBet) / 100).toFixed(2),
        totalTakeout: (Number(data.totalTakeout) / 100).toFixed(2),
        eventData: data.selections.map(event => ({
          eventName: event.eventName,
          outcomeOddsName: event.outcomeOddsName,
          outcomeOdds: event.outcomeOdds,
          sportName: event.sportName,
          inPlay: event.inPlay,
        })),
      }))
      .filter(filterByMultiplieCriteria);
  }, [betScrollerData, filterByMultiplieCriteria]);

  return <BetScrollerTableRow customerData={customerData} />;
};

export default React.memo(BetScrollerTable);
