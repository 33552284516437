import { GET_COMPETITORS_LIST, GET_COUNTRY_CODES, GET_SPORTS } from '../../actions/cms/competitorBuilder';

const initialState = {
  sports: [],
  countryCodes: [],
  competitors: []
};

export const competitorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPORTS.SUCCESS:
      return {
        ...state,
        sports: action.payload
      };

    case GET_COUNTRY_CODES.SUCCESS:
      return {
        ...state,
        countryCodes: action.payload
      };

    case GET_COMPETITORS_LIST.SUCCESS:
      return {
        ...state,
        competitors: action.payload
      };

    default:
      return state;
  }
};