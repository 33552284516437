import React from 'react';
import classes from './Switch.module.scss';

const Switch = ({
  label,
  name,
  onChange,
  isChecked,
  defIsChecked,
  isDisabled,
  className,
  value,
  smallSwitch,
  readOnly = false,
}) => {
  const stopPropagation = e => e.stopPropagation();

  return (
    <label
      onClick={stopPropagation}
      className={
        className !== undefined
          ? 'efb-switch__label ' + className
          : 'efb-switch__label'
      }
    >
      <input
        className="efb-switch"
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={isChecked}
        disabled={isDisabled}
        value={value}
        defaultChecked={defIsChecked}
        readOnly={readOnly}
      />
      <span
        className={
          smallSwitch
            ? `checkbox-slider ${classes.smallSwitch}`
            : 'checkbox-slider'
        }
      />
      <span
        className={
          smallSwitch
            ? `efb-switch__label-text ${classes.smallSwitch}`
            : 'efb-switch__label-text'
        }
        htmlFor={name}
      >
        {label}
      </span>
    </label>
  );
};

export default Switch;
