import React, { useState, useCallback, useMemo } from 'react';
import classes from './ResponsibleGambling.module.scss';
import Select from 'react-select';
import Switch from '../../../../../components/Form/Switch/Switch';
import CalendarDayPicker from '../../../../../components/CalendarDayPicker/CalendarDayPicker';
import moment from 'moment';
import { useUpdate } from '../../../../../hooks';
import { underlinedSelectStyles } from '../../../../../utils/selectStyles';

const SelfExclusionRow = ({
  name,
  provider,
  setProvider,
  restriction,
  casinoProvidersOptions,
  refetch,
}) => {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  useUpdate(() => {
    if (restriction.provider && restriction.section === 'CASINO') {
      setProvider({
        ...provider,
        endDate: restriction.active !== false ? restriction.endDate : null,
      });
    }
  }, [restriction.provider]);

  const isRestrictionActive = useMemo(
    () => (restriction.active === false ? null : restriction.active),
    [restriction]
  );

  const isChecked = useMemo(
    () => (restriction.active !== null ? restriction.active : false),
    [restriction]
  );

  const handleDayClick = useCallback(
    day => {
      setProvider({
        ...provider,
        active: isChecked || isRestrictionActive,
        section: name,
        endDate: day,
      });
      setIsOpenCalendar(false);
    },
    [provider, setProvider, isChecked, isRestrictionActive, name]
  );

  const handleSwitchChange = useCallback(
    e => {
      setProvider({
        ...provider,
        endDate:
          provider.section === name ? provider.endDate : restriction.endDate,
        section: name,
        active: e.target.checked,
      });
    },
    [name, provider, restriction, setProvider]
  );

  const selectChange = useCallback(
    e => {
      setProvider({
        ...provider,
        section: name,
        active:
          provider.endDate || provider.active === false
            ? null
            : provider.active,
        casinoProvider: e.value,
      });
    },
    [name, provider, setProvider]
  );

  return (
    <div className={classes.section}>
      <div>
        <Switch
          smallSwitch
          label={name}
          name={name}
          onChange={handleSwitchChange}
          isChecked={isChecked}
        />
      </div>
      {restriction.section === 'CASINO' ? (
        <div>
          <Select
            value={{
              value: provider.casinoProvider || restriction.provider,
              label: provider.casinoProvider || restriction.provider,
            }}
            defaultValue={casinoProvidersOptions?.[0]}
            options={casinoProvidersOptions}
            placeholder="Providers"
            styles={underlinedSelectStyles}
            onChange={e => {
              refetch();
              selectChange(e);
            }}
          />
        </div>
      ) : null}
      {isOpenCalendar ? (
        <span className={classes.calendar}>
          <CalendarDayPicker
            handleDayClick={day => handleDayClick(day)}
            from={restriction.endDate}
          />
        </span>
      ) : null}
      <div className={classes.date} onClick={() => setIsOpenCalendar(true)}>
        {provider.section === name
          ? provider.endDate
            ? moment(provider.endDate).format('DD/MM/YYYY')
            : 'Expiry Date'
          : restriction.endDate
          ? moment(restriction.endDate).format('DD/MM/YYYY')
          : 'Expiry Date'}
      </div>
    </div>
  );
};

export default SelfExclusionRow;
