import { action } from '.';

//Ex. Action Types - Constant needs to have the same name as the value
export const SOME_ACTION = 'SOME_ACTION',
  OPEN_FILTERS_MODAL = 'OPEN_FILTERS_MODAL',
  CLOSE_FILTERS_MODAL = 'CLOSE_FILTERS_MODAL',
  TOGGLE_USERS_MODAL = 'TOGGLE_USERS_MODAL',
  TOGGLE_ROLES_FILTERS = 'TOGGLE_ROLES_FILTERS',
  TOGGLE_ROLES_MODAL = 'TOGGLE_ROLES_MODAL',
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  TOGGLE_ROLE_PERMISSIONS_MODAL = 'TOGGLE_ROLE_PERMISSIONS_MODAL',
  TOGGLE_PLAYER_MANAGER_FILTERS_MODAL = 'TOGGLE_PLAYER_MANAGER_FILTERS_MODAL',
  TOGGLE_EVENT_SETTLE_MODAL = 'TOGGLE_EVENT_SETTLE_MODAL',
  EMPTY_NOTIFICATION = 'EMPTY_NOTIFICATION';

const createRequestTypes = base => {
  return [
    OPEN_FILTERS_MODAL,
    CLOSE_FILTERS_MODAL,
    TOGGLE_USERS_MODAL,
    TOGGLE_ROLES_FILTERS,
    TOGGLE_ROLES_MODAL,
    ADD_NOTIFICATION,
    TOGGLE_ROLE_PERMISSIONS_MODAL,
    TOGGLE_PLAYER_MANAGER_FILTERS_MODAL,
    TOGGLE_EVENT_SETTLE_MODAL,
  ].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
};

export const getUiRequestType = createRequestTypes('UI');

export const uiActions = {
  openFiltersModal: () => action(getUiRequestType.OPEN_FILTERS_MODAL),
  closeFiltersModal: () => action(getUiRequestType.CLOSE_FILTERS_MODAL),
  toggleUsersModal: payload =>
    action(getUiRequestType.TOGGLE_USERS_MODAL, { payload }),
  toggleRolesFilter: payload =>
    action(getUiRequestType.TOGGLE_ROLES_FILTERS, { payload }),
  toggleRolesModal: payload =>
    action(getUiRequestType.TOGGLE_ROLES_MODAL, { payload }),
  toggleRolePermissionsModal: payload =>
    action(getUiRequestType.TOGGLE_ROLE_PERMISSIONS_MODAL, { payload }),
  togglePlayerManagerFiltersModal: payload =>
    action(getUiRequestType.TOGGLE_PLAYER_MANAGER_FILTERS_MODAL, { payload }),
  toggleEventSettleModal: payload =>
    action(getUiRequestType.TOGGLE_EVENT_SETTLE_MODAL, { payload }),
};

//Ex. Create the Action to Dispatch
export const someAction = payload => action(SOME_ACTION, { payload });

export const openFiltersModal = () => {
  return dispatch => {
    dispatch(uiActions.openFiltersModal());
  };
};

export const closeFiltersModal = () => {
  return dispatch => {
    dispatch(uiActions.closeFiltersModal());
  };
};

export const toggleUsersModal = payload => {
  return dispatch => {
    dispatch(uiActions.toggleUsersModal(payload));
  };
};

export const toggleRolesFilter = payload => {
  return dispatch => {
    dispatch(uiActions.toggleRolesFilter(payload));
  };
};

export const toggleRolesModal = payload => {
  return dispatch => {
    dispatch(uiActions.toggleRolesModal(payload));
  };
};

export const addNotificationMessage = (message, level, title, position) => {
  return dispatch => {
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        message: message,
        level: level,
        title: title,
        position: position,
      },
    });
  };
};

export const emptyNotificationMessage = () => {
  return dispatch => {
    dispatch({
      type: EMPTY_NOTIFICATION,
    });
  };
};

export const toggleRolePermissionsModal = payload => {
  return dispatch => {
    dispatch(uiActions.toggleRolePermissionsModal(payload));
  };
};

export const togglePlayerManagerFiltersModal = payload => {
  return dispatch => {
    dispatch(uiActions.togglePlayerManagerFiltersModal(payload));
  };
};
//action for player filter modal
export const TOGGLE_PLAYER_FILTER_MODAL = 'TOGGLE_PLAYER_FILTER_MODAL';
export const togglePlayerFilterModalAction = payload =>
  action(TOGGLE_PLAYER_FILTER_MODAL, { payload });

export const toggleSettleEventModal = payload => {
  return dispatch => {
    dispatch(uiActions.toggleEventSettleModal(payload));
  };
};
