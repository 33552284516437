export const casinoManagerObj = (data, gamesQuant) => {
  const casinoObj = { ...data };

  const casinoGameRankings = [
    {
      businessUnit: 'bg',
      rank: gamesQuant,
    },
    {
      businessUnit: 'net',
      rank: gamesQuant,
    },
    {
      businessUnit: 'ro',
      rank: gamesQuant,
    },
  ];

  delete casinoObj.webImage;
  delete casinoObj.mobileImage;
  delete casinoObj.realAvailable;

  casinoObj.ranking = casinoGameRankings;

  return casinoObj;
};

export const imageObj = (image, gameId, platform, imageSize) => {
  const imageFormData = new FormData();
  const randomId =
    Math.random()
      .toString(36)
      .substr(2, 9) + gameId;
  image[imageSize].url.name = randomId + image[imageSize].url.name;

  if (image) {
    imageFormData.append(
      'file',
      !image[imageSize].url.url.name
        ? image[imageSize].url
        : image[imageSize].url.url,
      image[imageSize].url.name
    );
    imageFormData.append('imageType', 'CASINO_GAME');
    imageFormData.append('gameId', gameId);
    imageFormData.append('channel', platform === 'webImage' ? 'WEB' : 'MOBILE');
    imageFormData.append('gameImageSize', imageSize.toUpperCase());

    return imageFormData;
  }
};

export const editGameCasinoObj = casinoGameObj => {
  const casinoObj = { ...casinoGameObj };
  const casinoGameRankings = [];

  const casinoGameNames = [];
  const casinoGameBu = [];
  const namesToArr = Object.keys(casinoObj.names);
  const buToArr = Object.keys(casinoObj.businessUnits);

  if (casinoObj.ranking) {
    Object.keys(casinoObj.ranking).forEach(bu => {
      casinoGameRankings.push({
        businessUnit: bu,
        rank: casinoObj.ranking[bu].rank,
      });
    });
    casinoObj.ranking = casinoGameRankings;
  }

  if (namesToArr.length > 0) {
    namesToArr.forEach(locale => {
      casinoGameNames.push({
        locale: locale,
        name: casinoObj.names[locale].name,
        translated: true,
      });
    });
    casinoObj.names = casinoGameNames;
  } else {
    casinoObj.names = [];
  }

  if (buToArr.length > 0) {
    buToArr.forEach((bu, index) => {
      casinoGameBu.push({
        businessUnit: casinoObj.businessUnits[index].businessUnit,
        enabled: casinoObj.businessUnits[index].enabled,
      });
    });
    casinoObj.businessUnits = casinoGameBu;
  } else {
    casinoObj.businessUnits = [];
  }

  const obj = {
    active: casinoObj.active,
    demoAvailable: casinoObj.demoAvailable,
    web: casinoObj.web,
    mobile: casinoObj.mobile,
    gameId: casinoObj.gameId,
    gameType: casinoObj.gameType,
    jackpot: casinoObj.jackpot,
    realAvailable: !casinoObj.modes.real ? false : true,
    modes: casinoObj.modes,
    names: casinoObj.names,
    businessUnits: casinoObj.businessUnits,
    images: casinoObj.images,
    ranking: casinoObj.ranking,
    new: casinoObj.new,
    numberOfLines: casinoObj.numberOfLines,
    popular: casinoObj.popular,
    provider: casinoObj.provider,
    webImage: {
      big: '',
      small: '',
    },
    mobileImage: {
      big: '',
      small: '',
    },
  };

  return obj;
};

export const updatedCasinoGameObj = casinoGameObj => {
  const casinoObj = { ...casinoGameObj };
  const casinoGameNames = [];
  const casinoGameModes = [];
  const casinoGameRankings = [];

  delete casinoObj.realAvailable;
  delete casinoObj.images;

  const modesToArr = Object.keys(casinoObj.modes);
  const namesToArr = Object.keys(casinoObj.names);
  const rankingsToArr = Object.keys(casinoObj.ranking);

  if (modesToArr.length > 0) {
    modesToArr.forEach((mode, index) => {
      casinoGameModes.push({ mode: mode, url: casinoObj.modes[mode].url });
      if (
        casinoObj.modes[mode].url === 'true' ||
        casinoObj.modes[mode].url === 'false'
      ) {
        delete casinoObj.modes[mode];
        casinoGameModes.splice(index, 1);
      }
    });
    casinoObj.modes = casinoGameModes;
  } else {
    casinoObj.modes = [];
  }

  if (namesToArr.length > 0) {
    namesToArr.forEach(locale => {
      casinoGameNames.push({
        locale: casinoObj.names[locale].locale,
        name: casinoObj.names[locale].name,
        translated: true,
      });
    });
    casinoObj.names = casinoGameNames;
  } else {
    casinoObj.names = [];
  }

  if (rankingsToArr.length > 0) {
    rankingsToArr.forEach(bu => {
      casinoGameRankings.push({
        businessUnit: bu.toLowerCase(),
        rank: casinoObj.ranking[bu].rank,
      });
    });
    casinoObj.ranking = casinoGameRankings;
  } else {
    casinoObj.ranking = [];
  }

  return casinoObj;
};

export const checkForGameId = (gameId, games) => {
  let isGameIdUnique = true;
  games.forEach(game => {
    if (game.gameId === gameId) {
      isGameIdUnique = false;
    }
  });
  return isGameIdUnique;
};

export const setGameRanking = (casinoGame, newRank, bu) => {
  return {
    ...casinoGame.ranking,
    [bu]: { rank: newRank, businessUnit: bu, gameId: casinoGame.gameId },
  };
};
