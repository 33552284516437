import axiosInstance from './axios-instance';
import { createRequestTypes, actionCreator } from '.';

export const LANGUAGES = createRequestTypes('LANGUAGES');

export const getAllLanguages = () => async dispatch => {
  dispatch(actionCreator.request(LANGUAGES));
  try {
    const res = await axiosInstance.get('/customer/view/public/languages');
    dispatch(actionCreator.success(LANGUAGES, res));
  } catch (err) {
    dispatch(actionCreator.failure(LANGUAGES, err.message));
  }
};
