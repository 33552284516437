import React from 'react';
import DayPicker from 'react-day-picker';

import Button from '../Layout/Button';

import './DayPicker.scss';

const CalendarDayPicker = ({ handleDayClick, from, to, close }) => {
  return (
    <>
      <div onClick={close} className="background" />
      <DayPicker
        className="Range"
        numberOfMonths={1}
        showOutsideDays
        weekdayElement={() => null}
        navbarElement={<CalendarNavbar />}
        selectedDays={[from, { from, to }]}
        modifiers={{ start: from, end: to }}
        onDayClick={handleDayClick}
      />
    </>
  );
};

const CalendarNavbar = ({
  onPreviousClick,
  onNextClick,
  className,
  month,
  localeUtils,
}) => {
  const months = localeUtils.getMonths();
  const curr = months[month.getMonth()];

  return (
    <div className={className}>
      <Button cssClass="calendar-btn" onClick={() => onPreviousClick()}>
        {'<'}
      </Button>
      <span>
        {curr} {month.getFullYear()}
      </span>
      <Button cssClass="calendar-btn" onClick={() => onNextClick()}>
        {'>'}
      </Button>
    </div>
  );
};

export default CalendarDayPicker;
