import React, { useState, useCallback } from 'react';
import TextEditor from '../TextEditor';
import PropTypes from 'prop-types';
import { prepareHtml } from '../../StaticContentUtils';
import { useUpdate } from '../../../../../hooks';

const Regular = ({
  pageObj,
  index,
  updateObj,
  currentLang,
  images,
  uploadImage,
}) => {
  const [staticPageObj, setStaticPageObj] = useState({});

  useUpdate(() => {
    setStaticPageObj(pageObj);
  }, [pageObj]);

  const updateChanges = useCallback(
    e => {
      const { value } = e.target;
      const pageObjClone = { ...staticPageObj };
      pageObjClone.subpages[index].content[0][
        currentLang.value
      ].html = prepareHtml(value, index);
      updateObj(pageObjClone);
    },
    [currentLang.value, index, staticPageObj, updateObj]
  );

  return (
    <>
      <TextEditor
        htmlBody={updateChanges}
        images={images}
        uploadImage={uploadImage}
      />
      <br />
    </>
  );
};

Regular.propTypes = {
  updateObj: PropTypes.func,
  pageObj: PropTypes.object,
};

export default Regular;
