import React, { Fragment, useState, useMemo } from 'react';
import ContextMenu from '../../../components/ContextMenu/ContextMenu';

import { useContextLocation } from '../../../hooks';
import { riskRules } from './BetScrollerUtils';
import { withRouter } from 'react-router';

const BetScrollerTableRow = ({ customerData, history }) => {
  const [isSelected, setIsSelected] = useState({});
  const [customerId, setCustomerId] = useState('');
  const [openedContextMenu, setOpenedContextMenu] = useContextLocation(null);

  const contextRows = useMemo(() => {
    return [
      {
        name: 'Bet History',
        onClick: () =>
          history.push(
            `/adm/rm/bet-history?customerId=${customerId}&entityType=customer`
          ),
      },
      {
        name: 'Client Profile',
        onClick: () => history.push(`/adm/pm/manager?customerId=${customerId}`),
      },
    ];
  }, [customerId, history]);

  return (
    <table className="bet-scroller-table">
      {customerData.length > 0 ? (
        <tbody className="tbody-parent">
          <tbody>
            <tr className="m-2 table-headers">
              <th>BU</th>
              <th>BC</th>
              <th>Time/Event</th>
              <th>ARM</th>
              <th>Status</th>
              <th>Customer/Market</th>
              <th>Stake + Selection</th>
              <th>Individual Stake + Bet Type</th>
              <th>Pt</th>
              <th>EU Odds</th>
              <th>Takeout</th>
            </tr>

            {customerData.map((data, index) => {
              return (
                <Fragment key={index}>
                  {openedContextMenu ? (
                    <ContextMenu
                      onClose={() => setOpenedContextMenu(null)}
                      position={openedContextMenu.contextLocation}
                      rows={contextRows}
                      customer={data}
                    />
                  ) : null}
                  <tr
                    onClick={() =>
                      setIsSelected(isSelected === data ? {} : data)
                    }
                    onContextMenu={e => {
                      e.preventDefault();
                      setOpenedContextMenu(e, data);
                      setCustomerId(data.customerId);
                    }}
                    className={isSelected === data ? 'is-selected' : ''}
                  >
                    <td>
                      <i
                        className={`flag-icon flag-icon-${data.businessUnit.toLowerCase()}`}
                      />{' '}
                    </td>
                    <td>
                      <i
                        className={`fa fa-${
                          data.channel === 'WEB' ? 'desktop' : 'mobile'
                        }`}
                      />
                    </td>
                    <td>{data.createdAt}</td>
                    <td></td>
                    <td>{data.status}</td>
                    <td>
                      <span
                        style={{
                          background: data.riskCategoryColor || '#fff',
                        }}
                      >
                        {data.customerName}
                      </span>
                    </td>
                    <td>
                      € {data.totalBet}{' '}
                      {data.type.length > 1
                        ? data.type.join(' ')
                        : data.type[0]}
                    </td>
                    <td>
                      € {data.totalBet}{' '}
                      {data.type.length > 1
                        ? data.type.join(' ')
                        : data.type[0]}
                    </td>
                    <td></td>
                    <td></td>
                    <td>$ {data.totalTakeout}</td>
                  </tr>
                  {data.eventData.map((event, idx) => {
                    return (
                      <tr key={idx}>
                        <td></td>
                        <td></td>
                        <td>{event.eventName}</td>
                        <td>
                          <span
                            style={{
                              background: '#fd3131',
                              color: '#fff',
                            }}
                          >
                            {data.riskRules.length > 0
                              ? riskRules(data.riskRules)
                              : null}
                          </span>
                        </td>
                        <td></td>
                        <td>Match Betting</td>
                        <td>{event.outcomeOddsName}</td>
                        <td></td>
                        <td>CP</td>
                        <td>{event.outcomeOdds}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                  <tr className="end-of-row">
                    <td colSpan="12"></td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </tbody>
      ) : null}
    </table>
  );
};

export default withRouter(BetScrollerTableRow);
