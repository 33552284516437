import {
  GET_CASINO_GAMES,
  GET_CASINO_PROVIDERS
} from '../../actions/customer/Casino';

const initialState = {
  games: [],
  providers: []
};

export const casinoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CASINO_GAMES.SUCCESS:
      return {
        ...state,
        games: action.payload
      };
    case GET_CASINO_PROVIDERS.SUCCESS:
      return {
        ...state,
        providers: action.payload
      };
    default:
      return state;
  }
};
