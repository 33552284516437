import React from 'react';

const IconFolderLive = ({
  className,
  width,
  height,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 17 13"
      className={className}
      {...otherProps}
    >
      <defs>
        <style>{'.a{fill:#cf8808;}.b{fill:#ffbe4a;}.c{fill:#d92400;}'}</style>
      </defs>
      <g transform="translate(-252 -113.353)">
        <rect
          className="a"
          width="7"
          height="3"
          rx="1"
          transform="translate(252 113.353)"
        />
        <rect
          className="b"
          width="15"
          height="11"
          rx="1"
          transform="translate(252 114.353)"
        />
      </g>
      <circle
        className="c"
        cx="3"
        cy="3"
        r="3"
        transform="translate(11 7)"
      />
    </svg>
  );
};

export default IconFolderLive;