import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getRiskConfigurationData } from '../../../../selectors';
import Input from '../../../../components/Form/Input';
import Button from '../../../../components/Layout/Button';
import SelectMarketType from './SelectMarketType';
import RiskControlParameters from './RiskControlParameters';
// import Options from './Options';
import SelectSCT from './SelectSCT';

class ShowMarketType extends PureComponent {
  state = {
    name: '',
    marketType: '',
    sportId: '',
    categoryId: '',
    tournamentId: '',
    riskParams: [],
    marketTypesViews: []
  };

  componentDidMount() {
    this.prepareViews();
    if (!_.isEmpty(this.props.marketTypeConfig, true)) {
      this.setConfig();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.marketTypesViews !== this.props.marketTypesViews) {
      this.prepareViews();
    }
    if (
      prevProps.marketTypeConfig !== this.props.marketTypeConfig &&
      this.props.mode === 'edit'
    ) {
      this.setConfig();
    }
  }

  setConfig = () => {
    const {
      name,
      marketTypeId,
      sportId,
      categoryId,
      tournamentId,
      riskControlParameters
    } = this.props.marketTypeConfig;
    const fullMarketType = this.props.marketTypesViews.find(
      mtv => mtv.id === marketTypeId
    );
    let marketType = {};
    if (fullMarketType) {
      marketType = {
        value: fullMarketType.id,
        label: fullMarketType.name
      };
    }

    if (this.setSCT) {
      this.setSCT({ sportId, categoryId, tournamentId });
    }

    this.setState({
      name,
      sportId,
      categoryId,
      tournamentId,
      marketType,
      riskParams: riskControlParameters
    });
  };

  prepareViews = () => {
    const mapped = this.props.marketTypesViews.map(view => ({
      value: view.id,
      label: view.name
    }));

    this.setState({
      marketTypesViews: mapped
    });
  };

  handleSubmit = () => {
    const {
      name,
      riskParams,
      marketType,
      sportId,
      categoryId,
      tournamentId
    } = this.state;
    const idsToAdd = {};
    if (categoryId) {
      idsToAdd.categoryId = categoryId;
    }
    if (tournamentId) {
      idsToAdd.tournamentId = tournamentId;
    }
    const payload = {
      name,
      sportId,
      marketTypeId: marketType.value,
      riskControlParameters: riskParams,
      ...idsToAdd
    };

    this.props.handleSubmit(payload);
  };

  onRiskChange = e => {
    const { name } = e.target;
    let value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (name === 'MSF_DURATION') {
      value = `PT${value}M`;
    }
    const riskParams = [...this.state.riskParams];

    const foundIndex = riskParams.findIndex(x => x.code === name);
    if (foundIndex > -1) {
      if (!value) {
        riskParams.splice(foundIndex, 1);
      } else {
        riskParams[foundIndex]['value'] = value;
      }
    } else {
      riskParams.push({
        code: name,
        value
      });
    }

    this.setState({
      riskParams
    });
  };

  onTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSelectMarketChange = (data, { name }) => {
    this.setState(
      {
        [name]: data,
        [name + 'Name']: data.label
      },
      this.setName
    );
  };

  onSelectSCTChange = (data, name) => {
    let resetStuff = {};
    if (name === 'sport') {
      resetStuff = {
        categoryId: '',
        categoryName: '',
        tournamentId: '',
        tournamentName: ''
      };
    } else if (name === 'category') {
      resetStuff = {
        tournamentId: '',
        tournamentName: ''
      };
    }
    this.setState(
      {
        [name + 'Id']: data.value,
        [name + 'Name']: data.label,
        ...resetStuff
      },
      this.setName
    );
  };

  setName = () => {
    const name = [
      ...Object.values(
        _.pick(this.state, [
          'sportName',
          'categoryName',
          'tournamentName',
          'marketTypeName'
        ])
      ).filter(name => name)
    ].join(' - ');

    this.setState({
      name
    });
  };

  setSCTFunc = func => {
    this.setSCT = func;
  };

  render() {
    const { mode, closeShow, marketSportViews } = this.props;
    const { name, marketType, riskParams, marketTypesViews } = this.state;

    return (
      <div
        className="my-3"
        id="showMarketType"
      >
        <div className="efb-form">
          {marketSportViews.length ? (
            <SelectSCT
              template={this.state}
              marketSportViews={marketSportViews}
              setSCTFunc={this.setSCTFunc}
              onChange={this.onSelectSCTChange}
              mode={mode}
            />
          ) : null}
          <div className="form-row mb-3">
            <SelectMarketType
              mode={mode}
              marketType={marketType}
              onSelectChange={this.onSelectMarketChange}
              marketTypesViews={marketTypesViews}
            />
          </div>
          <div className="form-row mb-3">
            <div className="col-lg-6">
              <Input
                className="form-control"
                placeholder="Enter name"
                type="text"
                name="name"
                id="name"
                onChange={this.onTextChange}
                value={name}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <RiskControlParameters
              onRiskChange={this.onRiskChange}
              riskParams={riskParams}
            />
          </div>
          {/* TODO: Needed when those are implemented in backend */}
          {/* <div className="form-row">
            <Options
              riskParams={riskParams}
              onOptionChange={this.onRiskChange}
            />
          </div> */}
          <div className="form-group d-flex flex-row justify-content-end">
            <Button
              cssClass="efb-btn__light"
              onClick={closeShow}
            >
              Cancel
            </Button>
            <Button
              cssClass="efb-btn__main"
              onClick={this.handleSubmit}
            >
              {mode === 'create' ? 'Create' : 'Save'} Market Type Template
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ShowMarketType.propTypes = {
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  closeShow: PropTypes.func.isRequired,
  marketTypesViews: PropTypes.array
};

const mapStateToProps = state => ({
  marketTypesViews: getRiskConfigurationData(state).marketTypesViews,
  marketSportViews: getRiskConfigurationData(state).marketSportViews
});

export default connect(mapStateToProps)(ShowMarketType);
