import React from 'react';

export const DataWarehouse = (props) => {
    return (
	<span className='efb-icon'>
    	<svg className={`efb-icon--${props.size} ${props.cssClass}`} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 128 128" >

	    <polygon fill="#ECF0F1" points="121,128 3.1,128 8.5,36.7 61.6,5.3 121,36.7 	"/>
	    <path fill="#FEB719" d="M127.6,122.6V32c0-2.2-1.1-4.1-3-4.9L66.1,0.4c-1.4-0.5-3-0.5-4.3,0L3.3,27.1c-1.9,0.8-3,2.7-3,4.9v90.6
		    c0,3,2.5,5.4,5.4,5.4s5.4-2.5,5.4-5.4V35.5l53.3-24.2l53,24.2v87.1c0,3,2.5,5.4,5.4,5.4S128.4,125.6,127.6,122.6z"/>
	
		<path fill="#545E73" d="M86.3,32.4L86.3,32.4c-0.6-3.9-9.9-6.9-21.2-6.9S44.6,28.5,44,32.4l0,0h0v0.4v9.9v0.4v0.4v9.5v0.4V54v10.4
			h0c0.6,3.9,9.9,6.9,21.2,6.9s20.5-3.1,21.2-6.9h0V54v-0.4v-0.4v-9.5v-0.4v-0.4v-9.9L86.3,32.4L86.3,32.4z"/>
		
        <path fill="#38454F" d="M65.1,60.9c-11.7,0-21.2-3.3-21.2-7.3v10.8h0c0.6,3.9,9.9,6.9,21.2,6.9s20.5-3.1,21.2-6.9h0V53.5
            C86.4,57.6,76.9,60.9,65.1,60.9z"/>
        <path fill="#38454F" d="M43.9,53.1v0.4C43.9,53.4,44,53.3,43.9,53.1L43.9,53.1z"/>
        <path fill="#38454F" d="M86.3,53.1c0,0.1,0,0.3,0,0.4L86.3,53.1L86.3,53.1z"/>
    
    
        <path fill="#556080" d="M65.1,50.5c-11.7,0-21.2-3.3-21.2-7.3V54h0c0.6,3.9,9.9,6.9,21.2,6.9s20.5-3.1,21.2-6.9h0V43.2
            C86.4,47.2,76.9,50.5,65.1,50.5z"/>
        <path fill="#556080" d="M43.9,42.8v0.4C43.9,43,44,42.9,43.9,42.8L43.9,42.8z"/>
        <path fill="#556080" d="M86.3,42.8c0,0.1,0,0.3,0,0.4L86.3,42.8L86.3,42.8z"/>
		
		<ellipse fill="#91BAE1" cx="65.1" cy="32.8" rx="21.2" ry="7.3"/>
		
        <path fill="#8697CB" d="M65.1,40.2c-11.7,0-21.2-3.3-21.2-7.3v10.8h0c0.6,3.9,9.9,6.9,21.2,6.9s20.5-3.1,21.2-6.9h0V32.8
            C86.4,36.9,76.9,40.2,65.1,40.2z"/>
        <path fill="#8697CB" d="M43.9,32.4v0.4C43.9,32.7,44,32.5,43.9,32.4L43.9,32.4z"/>
        <path fill="#8697CB" d="M86.3,32.4c0,0.1,0,0.3,0,0.4L86.3,32.4L86.3,32.4z"/>

		<path fill="#545E73" d="M110.4,84L110.4,84c-0.6-3.9-9.9-6.9-21.2-6.9S68.7,80.2,68,84l0,0h0v0.4v9.9v0.4v0.4v9.5v0.4v0.4V116h0
			c0.6,3.9,9.9,6.9,21.2,6.9s20.5-3.1,21.2-6.9h0v-10.4v-0.4v-0.4v-9.5v-0.4v-0.4v-9.9L110.4,84L110.4,84z"/>
		
        <path fill="#38454F" d="M89.2,112.5c-11.7,0-21.2-3.3-21.2-7.3V116h0c0.6,3.9,9.9,6.9,21.2,6.9s20.5-3.1,21.2-6.9h0v-10.8
            C110.4,109.2,100.9,112.5,89.2,112.5z"/>
        <path fill="#38454F" d="M68,104.7v0.4C68,105,68,104.9,68,104.7L68,104.7z"/>
        <path fill="#38454F" d="M110.4,104.7c0,0.1,0,0.3,0,0.4L110.4,104.7L110.4,104.7z"/>
    
        <path fill="#556080" d="M89.2,102.2c-11.7,0-21.2-3.3-21.2-7.3v10.8h0c0.6,3.9,9.9,6.9,21.2,6.9s20.5-3.1,21.2-6.9h0V94.8
            C110.4,98.9,100.9,102.2,89.2,102.2z"/>
        <path fill="#556080" d="M68,94.4v0.4C68,94.7,68,94.5,68,94.4L68,94.4z"/>
        <path fill="#556080" d="M110.4,94.4c0,0.1,0,0.3,0,0.4L110.4,94.4L110.4,94.4z"/>
		
		<ellipse fill="#91BAE1" cx="89.2" cy="84.5" rx="21.2" ry="7.3"/>
		
        <path fill="#8697CB" d="M89.2,91.8c-11.7,0-21.2-3.3-21.2-7.3v10.8h0c0.6,3.9,9.9,6.9,21.2,6.9s20.5-3.1,21.2-6.9h0V84.5
            C110.4,88.5,100.9,91.8,89.2,91.8z"/>
        <path fill="#8697CB" d="M68,84v0.4C68,84.3,68,84.2,68,84L68,84z"/>
        <path fill="#8697CB" d="M110.4,84c0,0.1,0,0.3,0,0.4L110.4,84L110.4,84z"/>
	
		<path fill="#545E73" d="M61.2,84L61.2,84c-0.6-3.9-9.9-6.9-21.2-6.9S19.5,80.2,18.9,84l0,0h0v0.4v9.9v0.4v0.4v9.5v0.4v0.4V116h0
			c0.6,3.9,9.9,6.9,21.2,6.9s20.5-3.1,21.2-6.9h0v-10.4v-0.4v-0.4v-9.5v-0.4v-0.4v-9.9L61.2,84L61.2,84z"/>
		
        <path fill="#38454F" d="M40,112.5c-11.7,0-21.2-3.3-21.2-7.3V116h0c0.6,3.9,9.9,6.9,21.2,6.9s20.5-3.1,21.2-6.9h0v-10.8
            C61.2,109.2,51.7,112.5,40,112.5z"/>
        <path fill="#38454F" d="M18.8,104.7v0.4C18.8,105,18.8,104.9,18.8,104.7L18.8,104.7z"/>
        <path fill="#38454F" d="M61.2,104.7c0,0.1,0,0.3,0,0.4L61.2,104.7L61.2,104.7z"/>
    
    
        <path fill="#556080" d="M40,102.2c-11.7,0-21.2-3.3-21.2-7.3v10.8h0c0.6,3.9,9.9,6.9,21.2,6.9s20.5-3.1,21.2-6.9h0V94.8
            C61.2,98.9,51.7,102.2,40,102.2z"/>
        <path fill="#556080" d="M18.8,94.4v0.4C18.8,94.7,18.8,94.5,18.8,94.4L18.8,94.4z"/>
        <path fill="#556080" d="M61.2,94.4c0,0.1,0,0.3,0,0.4L61.2,94.4L61.2,94.4z"/>
		
		<ellipse fill="#91BAE1" cx="40" cy="84.5" rx="21.2" ry="7.3"/>
		
        <path fill="#8697CB" d="M40,91.8c-11.7,0-21.2-3.3-21.2-7.3v10.8h0c0.6,3.9,9.9,6.9,21.2,6.9s20.5-3.1,21.2-6.9h0V84.5
            C61.2,88.5,51.7,91.8,40,91.8z"/>
        <path fill="#8697CB" d="M18.8,84v0.4C18.8,84.3,18.8,84.2,18.8,84L18.8,84z"/>
        <path fill="#8697CB" d="M61.2,84c0,0.1,0,0.3,0,0.4L61.2,84L61.2,84z"/>

    	</svg>     
		{props.children}
	</span>
    )
}

export default DataWarehouse;