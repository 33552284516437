import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import round from 'lodash/round';

export const channels = ['WEB', 'MOBILE'];

export const filterMenu = [
  'Main',
  'By Country',
  'By Device',
  'By Sports',
  'By Bet Types',
  'Add Events',
  'By Risk Category',
];

export const menuesForSelectAll = {
  'By Country': 'businessUnits',
  'By Sports': 'sports',
  'By Bet Types': 'betTypes',
  'By Risk Category': 'riskCategories',
};

export const newBetScroller = {
  betStakeMax: 0,
  betStakeMin: 0,
  betTypes: [],
  betStates: [],
  games: [],
  businessChannels: [],
  businessUnits: [],
  events: [],
  name: 'BetScroller',
  riskCategories: [],
  sports: [],
  takeoutMin: '',
};

const emptySystem = {
  systemTakeout: 0,
  systemBet: 0,
  type: '',
  systemOdds: 0,
  bets: [],
};

const isBsExist = (betScrollers, currentBetScroller) => {
  const { id, name, betStakeMax, betStakeMin, takeoutMin } = currentBetScroller;
  if (!id) {
    return betScrollers.some(bs => bs.id && bs.name === name);
  } else {
    const filtered = [...betScrollers].filter(bs => bs.id !== id);
    return filtered.some(
      bs =>
        bs.name === name ||
        (bs.betStakeMin === betStakeMin &&
          bs.betStakeMax === betStakeMax &&
          bs.takeoutMin === takeoutMin)
    );
  }
};

export const transformSports = sports => {
  let sportsOptions = [],
    sportOption = {};

  if (isEmpty(sports)) {
    return [];
  }

  sports.forEach(sport => {
    sportOption = {
      ...sport,
      code: sport.name,
      label: sport.name,
      value: sport.name,
    };
    sportsOptions = [...sportsOptions, sportOption];
  });

  return sportsOptions;
};

export const validateBetScroller = (
  betScrollersCopy,
  currentBetScroller,
  addNotificationMessage
) => {
  const { betStakeMin, betStakeMax, takeoutMin, name } = currentBetScroller;
  if (!name) {
    addNotificationMessage('You have to add a name!', 'warning', 'Warning');
    return false;
  }
  if (isBsExist(betScrollersCopy, currentBetScroller)) {
    addNotificationMessage(
      'The Bet Scroller with same filters allready exist!',
      'warning',
      'Warning'
    );
    return false;
  }
  if (betStakeMin >= betStakeMax) {
    addNotificationMessage(
      'Bet stake max must be bigger then bet stake min!',
      'warning',
      'Warning'
    );
    return false;
  }
  if (betStakeMin <= 0 || betStakeMax <= 1) {
    addNotificationMessage(
      'Bet Stake Min/Max must be bigger than zero!',
      'warning',
      'Warning'
    );
    return false;
  }
  if (takeoutMin && takeoutMin < 1) {
    addNotificationMessage(
      'Take out must be bigger than zero!',
      'warning',
      'Warning'
    );
    return false;
  }
  return true;
};

export const validateBSTabs = (
  renderBetScrollers,
  ownId,
  betScrollersCopy,
  newBetScroller,
  setBetScrollersCopy,
  addNotificationMessage
) => {
  if (renderBetScrollers.length < 5 && ownId) {
    setBetScrollersCopy([...betScrollersCopy, newBetScroller]);
  }
  if (renderBetScrollers.length >= 5 && ownId) {
    addNotificationMessage(
      'You can not create more than five bet scrollers!',
      'info',
      'Info'
    );
    return;
  }
  if (!ownId) {
    addNotificationMessage(
      "You don't have permission to create bet scrollers!.",
      'info',
      'Info'
    );
    return;
  }
};

export const riskRules = allRules => {
  const wsRules = allRules.map(rule => rule.rule);
  const rules = ['MBL', 'MST', 'MAX_TAKEOUT', 'MSF', 'MMCTC', 'MT'];
  const hitedRule = rules.filter(rule => wsRules.includes(rule))[0];

  switch (hitedRule) {
    case 'MBL':
      return 'Max Book Loss';
    case 'MST':
      return 'Max Singles Takeout';
    case 'MAX_TAKEOUT':
      return 'Max Takeout';
    case 'MSF':
      return 'Max Syndicate Frequency';
    case 'MMCTC':
      return 'Max Multiple Capped Takeout Contribution';
    case 'MMT':
      return 'Max Multiple Takeout';
    default:
      return '';
  }
};

export const convertCents = num => Number(num / 100).toFixed(2);

export const transformSingleBetslip = betslip => {
  let generalInfoBet = omit(betslip, ['bet']),
    singleBet = betslip.bet[0],
    systemBet = {},
    systemBets = [];

  generalInfoBet = {
    ...generalInfoBet,
    totalBet: singleBet['bet'],
    totalTakeout: (singleBet['bet'] * singleBet['odds']).toFixed(2),
  };

  systemBet = {
    type: singleBet.type,
    systemBet: singleBet['bet'],
    systemOdds: singleBet['odds'].toFixed(2),
    systemTakeout: round(singleBet['bet'] * singleBet['odds'], 2),
    bets: betslip['bet'],
  };
  systemBets.push(systemBet);
  generalInfoBet = { ...generalInfoBet, systemBets: systemBets };
  return generalInfoBet;
};

export const transformMultipleBetslip = betslip => {
  let generalInfoBet = omit(betslip, ['bet']),
    systemBets = [],
    system = {};
  let groupedBets = groupBy(betslip.bet, bet => bet.type);

  Object.keys(groupedBets).forEach(group => {
    system = {
      ...emptySystem,
      type: groupedBets[group][0].type,
      bets: groupedBets[group],
    };
    let systemBetSum = groupedBets[group].reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.bet;
        },
        0
      ),
      systeOddsSum = groupedBets[group].reduce((accumulator, currentValue) => {
        return accumulator + currentValue.odds;
      }, 0);
    system = {
      ...system,
      systemBet: systemBetSum,
      systemOdds: round(systeOddsSum, 2),
      systemTakeout: round(round(systeOddsSum, 2) * system.bets[0].bet, 2),
    };
    systemBets = [...systemBets, system];
  });
  let totalTakeout =
      systemBets.length > 1
        ? systemBets.reduce((a, b) => a.systemTakeout + b.systemTakeout)
        : systemBets[0].systemTakeout,
    totalBet =
      systemBets.length > 1
        ? systemBets.reduce((a, b) => a.systemBet + b.systemBet)
        : systemBets[0].systemBet;
  generalInfoBet = {
    ...generalInfoBet,
    totalBet: totalBet,
    totalTakeout: round(totalTakeout, 2),
  };
  return { ...generalInfoBet, systemBets: systemBets };
};
