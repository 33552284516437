import React, {Component, Fragment} from 'react';
import Arrow from '../../../../components/Icons/Arrow';
import Edit from '../../../../components/Icons/Edit';
import Button from '../../../../components/Layout/Button';
import PropTypes from 'prop-types';
import AppViewPrivilegesRoles from './AppViewPrivilegesRoles';
import {transformRole} from "./rolesUtils";

class RolesTable extends Component {

	state = {
		selectedRole: null,
		selectedSection: 'general'
	};

	toggleTableRow = (role, event) => {
		event.preventDefault();
		this.setState({
			selectedRole: this.state.selectedRole ? null : role.id
		});
		this.props.setRoleForEdit(role);
	};

	handleEditClick = (clickedRole, event) => {
		this.props.setRoleForEdit(clickedRole);
		this.props.toggleRolesModal(true);
		this.props.setModalType('edit');
	};

	handleClickedSection = (section, event) => {
		this.setState({selectedSection: section});
	};

	render() {
		let {roles, selectedRole} = this.props;
		return (
			<Fragment>
				<ul className="efb-data-table">
					<li className='efb-data-table__header'>
						<section className='efb-data-table__section roles'>
							<span>Role:</span>
							<span>Description:</span>
							<span><span className='efb-f-light'>Records Found:</span> {roles.length}</span>
						</section>
					</li>
					{roles.map((role, i) => (
						<li key={i}
						    className={`efb-data-table__row ${this.state.selectedRole === role.id ? 'selected' : ''}`}
						    onClick={(event) => this.toggleTableRow(role, event)}>
							<section className='efb-data-table__section roles'>
								<span>{role.name}</span>
								<span>{role.description}</span>
								<Arrow size='xs'/>
							</section>
							{this.state.selectedRole === role.id ?
								<section onClick={(event) => event.stopPropagation()} className='efb-data-table__desc'>
									<aside className='efb-data-table__controls'>
										<Button onClick={(event) => this.handleClickedSection('general', event)}
										        cssClass={this.state.selectedSection === 'general' ? 'efb-btn__link selected' : 'efb-btn__link'}>General</Button>
										<Button onClick={(event) => this.handleClickedSection('permissions', event)}
										        cssClass={this.state.selectedSection === 'permissions' ? 'efb-btn__link selected' : 'efb-btn__link'}>Permissions</Button>
									</aside>
									{
										this.state.selectedSection === 'general' ? (
											<section className='efb-data-table__user'>
												<ul className='efb-data-table__user-data'>
													<li>ID: <strong>{role.id}</strong></li>
													<li>Role Name: <strong>{role.name}</strong></li>
													<li>Role Domain Object: <strong>USER</strong></li>
													<li>Role Application: <strong>DBCORE</strong></li>
												</ul>
											</section>
										) : (
											<div style={{width: '100%', padding: '5px 5px 0 5px', fontSize: '14px'}}>
												<AppViewPrivilegesRoles transformedRole={transformRole(selectedRole)} readOnly={true}/>
											</div>
										)
									}

									<aside className='efb-data-table__controls'>
										<Button onClick={(e) => this.handleEditClick(role, e)} cssClass="efb-btn__link">
											<Edit size='sm'/>
										</Button>
									</aside>
								</section> : null}
						</li>)
					)}
				</ul>
			</Fragment>
		)
	}
}

RolesTable.propTypes = {
	roles: PropTypes.array,
	toggleRolesModal: PropTypes.func,
	setRoleForEdit: PropTypes.func,
	selectedRole: PropTypes.object,
	setModalType: PropTypes.func
};

export default RolesTable;