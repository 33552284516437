import React, { useCallback } from 'react';
import Button from '../../Layout/Button';
import PropTypes from 'prop-types';

const SettlementEventsList = React.memo(({ tableData: events, viewEventDetails }) => {
	const renderRow = useCallback(
		(event, index) => (
			<tr key={event.id}>
				<td>{index + 1}.</td>
				<td>{event.names.en.name}</td>
				<td>
					<Button cssClass="efb-btn__main" onClick={() => viewEventDetails(event)}>
						<i className="fa fa-eye" /> View markets
					</Button>
				</td>
			</tr>
		),
		[ viewEventDetails ]
	);
	return events.map(renderRow);
});

SettlementEventsList.propTypes = {
	tableData: PropTypes.array,
	viewEventDetails: PropTypes.func
};

export default SettlementEventsList;
