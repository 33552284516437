import React, { useState, useMemo } from 'react';

import classes from './Dashboard.module.scss';
import RiskManager from './RiskManager/RiskManager';
import Sidebar from '../../../components/Sidebar/Sidebar';
import BetScrollerNewDesign from '../BetScroller/BetScrollerNewDesign';

const Dashboard = () => {
  const [currentMarketId, setCurrentMarketId] = useState(
    '000001702e305805-0242ac1300170001'
  );

  const treeProps = useMemo(
    () => ({
      onEntityClick: (entity, entityType) => {
        if (entityType === 'market') {
          setCurrentMarketId(entity.id);
          return true;
        }
        return false;
      },
      showMarkets: true,
    }),
    []
  );

  return (
    <div className={classes.wrapper}>
      <Sidebar treeProps={treeProps} />
      <div className={classes.main}>
        {/* WIP */}
        <div>
          {currentMarketId ? <RiskManager marketId={currentMarketId} /> : null}
        </div>
        <div>
          <BetScrollerNewDesign />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Dashboard);
