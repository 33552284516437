import React, { useState, useCallback } from 'react';
import PageCreator from '../Forms/PageCreator';
import PageTable from './PageTable';
import { subPagePushData } from '../StaticContentObj';
import PropTypes from 'prop-types';
import { useUpdate } from '../../../../hooks';

const MainPages = ({
  showSubpages,
  pageObj,
  updateObj,
  submitPage,
  getPages,
  pages,
  editPage,
  updatePageStatus,
  saveEditedName,
  setContentLang,
  currentLang,
  images,
  uploadImage,
  contentType,
  updateContentTypeValue,
  subpageContent,
  cancelPageContentEditMode,
  pagesListLang,
  editPageContent,
  pageContentEdit,
  extendPageContent,
  getRegularPageContent,
  regularPageContent,
  updateRegularPageContent,
  availableCountries,
  currencies,
  deletePage,
  prepareSubpath,
  serverError,
  languages,
}) => {
  const [staticPageObj, setStaticPageObj] = useState('');

  useUpdate(() => {
    setStaticPageObj(pageObj);
  }, [pageObj]);

  const addSubPage = useCallback(
    e => {
      e.preventDefault();
      const pageObjClone = { ...staticPageObj };
      pageObjClone.subpages.push(subPagePushData());
      setStaticPageObj(pageObjClone);
    },
    [staticPageObj]
  );

  const removeSubpage = useCallback(
    (e, index) => {
      e.preventDefault();
      const pageObjClone = { ...staticPageObj };
      pageObjClone.subpages.splice(index, 1);
      setStaticPageObj(pageObjClone);
    },
    [staticPageObj]
  );

  return (
    <>
      <PageCreator
        pageObj={pageObj}
        updateObj={updateObj}
        addSubPage={addSubPage}
        uploadImage={uploadImage}
        currentLang={currentLang}
        contentType={contentType}
        setContentLang={setContentLang}
        images={images}
        updateContentTypeValue={updateContentTypeValue}
        submitPage={submitPage}
        subpageContent={subpageContent}
        cancelPageContentEditMode={cancelPageContentEditMode}
        pageContentEdit={pageContentEdit}
        availableCountries={availableCountries}
        currencies={currencies}
        prepareSubpath={prepareSubpath}
        serverError={serverError}
        getPages={getPages}
        removeSubpage={removeSubpage}
        languages={languages}
      />
      <hr />

      {!pageContentEdit ? (
        <>
          <h3 className="efb-h3">Pages</h3>
          <div className="row">
            <div className="col-md">
              <PageTable
                showSubpages={showSubpages}
                pages={pages}
                saveEditedName={saveEditedName}
                uploadImage={uploadImage}
                images={images}
                pagesListLang={pagesListLang}
                updatePageStatus={updatePageStatus}
                editPageContent={editPageContent}
                extendPageContent={extendPageContent}
                getRegularPageContent={getRegularPageContent}
                regularPageContent={regularPageContent}
                updateRegularPageContent={updateRegularPageContent}
                deletePage={deletePage}
                editPage={editPage}
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

MainPages.propTypes = {
  editPage: PropTypes.func,
  getPages: PropTypes.func,
  languages: PropTypes.array,
  pageObj: PropTypes.object,
  pages: PropTypes.array,
  saveEditedName: PropTypes.func,
  setContentLang: PropTypes.func,
  showSubpages: PropTypes.func,
  submitPage: PropTypes.func,
  updateObj: PropTypes.func,
  updatePageStatus: PropTypes.func,
};

export default MainPages;
