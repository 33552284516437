import React from 'react';
import PropTypes from 'prop-types';

const Tabs = ({ currentTab, setCurrentTab, availableTabs }) => {
  return (
    <ul className="nav nav-tabs nav-fill" role="tablist">
      {availableTabs.map((tab, index) => (
        <li key={tab} className="nav-item" onClick={() => setCurrentTab(tab)}>
          <div
            tabIndex={index + 1}
            className={`nav-link ${tab === currentTab ? 'active' : ''}`}
          >
            {tab[0].toUpperCase() + tab.slice(1)}
          </div>
        </li>
      ))}
    </ul>
  );
};

Tabs.propTypes = {
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  availableTabs: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Tabs;
