import React, { useCallback, useMemo, useState } from 'react';
import Switch from '../../../../components/Form/Switch/Switch';
import InputField from '../../../../components/Form/InputField';
import Select from 'react-select';
import { selectStylesAbsolute } from '../../../../utils/selectStyles';
import {
  useStateForSelect,
  useUpdate,
  useStateForSwitch,
} from '../../../../hooks';

const GameSection = ({
  casinoGameObj,
  updateChanges,
  gameProviders,
  editMode,
}) => {
  const [gameType, setGameType] = useStateForSelect('');
  const [gameProvider, setGameProvider] = useStateForSelect('');
  const [editModeInitialLoad, setEditModeInitialLoad] = useState(true);
  const [newGame, setNewGame] = useStateForSwitch(false);
  const [popularGame, setPopularGame] = useStateForSwitch(false);
  const [jackpotGame, setJackpotGame] = useStateForSwitch(false);

  useUpdate(() => {
    if (!editMode) {
      setGameType('');
      setGameProvider('');
      setNewGame(false);
      setPopularGame(false);
      setJackpotGame(false);
      setEditModeInitialLoad(false);
    } else {
      setEditModeInitialLoad(true);
    }
  }, [editMode]);

  useUpdate(() => {
    if (editMode && editModeInitialLoad) {
      setGameType({
        value: casinoGameObj.gameType,
        label: casinoGameObj.gameType,
      });
      setGameProvider({
        value: casinoGameObj.provider,
        label: casinoGameObj.provider,
      });
      setNewGame(casinoGameObj.new);
      setPopularGame(casinoGameObj.popular);
      setJackpotGame(casinoGameObj.jackpot);
      setEditModeInitialLoad(false);
    }
  }, [casinoGameObj, editMode, editModeInitialLoad]);

  useUpdate(() => {
    if (!editModeInitialLoad) {
      updateChanges({
        ...casinoGameObj,
        gameType: gameType.value,
        provider: gameProvider.value,
      });
    }
  }, [gameType, gameProvider, editModeInitialLoad]);

  useUpdate(() => {
    updateChanges({
      ...casinoGameObj,
      new: newGame,
      popular: popularGame,
      jackpot: jackpotGame,
    });
  }, [newGame, popularGame, jackpotGame]);

  const handleInputChange = useCallback(
    e => {
      const { name, value } = e.target;
      const casinoGameObjClone = { ...casinoGameObj };
      casinoGameObjClone[name] = value;
      updateChanges(casinoGameObjClone);
    },
    [casinoGameObj, updateChanges]
  );

  const gameTypes = useMemo(() => {
    return [
      { value: 'SLOT', label: 'Slot' },
      { value: 'SCRATCH', label: 'Scratch' },
      { value: 'TABLE_GAME', label: 'Table game' },
      { value: 'KENO', label: 'Keno' },
      { value: 'VIDEO_POKER', label: 'Video poker' },
    ];
  }, []);

  const gameProvidersList = useMemo(() => {
    return gameProviders
      ? gameProviders.reduce(
          (acc, val) => [
            ...acc,
            {
              value: val.name,
              label: val.name,
            },
          ],
          []
        )
      : [];
  }, [gameProviders]);

  return (
    <div>
      <div className="form-row">
        <div className="col-md">
          <Switch
            label="New Game"
            name="new"
            value={newGame}
            isChecked={newGame}
            onChange={setNewGame}
          />
          <Switch
            label="Popular Game"
            name="popular"
            value={popularGame}
            isChecked={popularGame}
            onChange={setPopularGame}
          />
          <Switch
            label="Jackpot Game"
            name="jackpot"
            value={jackpotGame}
            isChecked={jackpotGame}
            onChange={setJackpotGame}
          />
        </div>
      </div>
      <br />
      <div className="form-row">
        <div className="efb-form form-label-group col-md">
          <InputField
            type="number"
            label={'Number of lines'}
            className="form-control efb-input"
            value={casinoGameObj.numberOfLines}
            onChange={handleInputChange}
            name="numberOfLines"
            placeholder="Number of lines"
          />
        </div>
        <div className="form-label-group col-md">
          <Select
            value={gameType}
            options={gameTypes}
            onChange={setGameType}
            placeholder="Game Type"
            styles={selectStylesAbsolute}
          />
        </div>
        <div className="form-label-group col-md">
          <Select
            value={gameProvider}
            options={gameProvidersList}
            onChange={setGameProvider}
            placeholder="Game Provider"
            styles={selectStylesAbsolute}
          />
        </div>
        <div className="efb-form form-label-group col-md">
          <InputField
            type="text"
            label={'Game Id'}
            disabled={editMode}
            className="form-control efb-input"
            value={casinoGameObj.gameId}
            onChange={handleInputChange}
            name="gameId"
            placeholder="Game ID"
          />
        </div>
      </div>
    </div>
  );
};

export default GameSection;
