import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Layout/Button';
import Input from '../../../components/Form/Input';
import { useUpdate } from '../../../hooks';

const MarketCategoryForm = ({
  updateMarketCategory,
  marketCategoryObj,
  isEditMode,
  cancelEdit,
}) => {
  const [marketCategory, setMarketCategory] = useState({});

  useUpdate(() => {
    setMarketCategory(marketCategoryObj);
  }, [marketCategoryObj]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    const marketCategoryClone = { ...marketCategory };
    marketCategoryClone.names[name] = {
      ...marketCategoryClone.names[name],
      name: value,
    };
    setMarketCategory(marketCategoryClone);
  };

  return (
    <Fragment>
      {Object.keys(marketCategory).length !== 0 ? (
        <form className="efb-form">
          <h3 className="efb-h3">Market Category name</h3>
          <br />
          <div className="form-row whole-input-clickable">
            <div className="form-label-group col-md">
              <Input
                type="text"
                className="form-control"
                value={
                  marketCategory.names.en ? marketCategory.names.en.name : ''
                }
                placeholder="Sport Name"
                onChange={handleInputChange}
                name="en"
              />
              <label htmlFor="en">
                English name <i className="flag-icon flag-icon-gb" />
              </label>
            </div>
            <div className="form-label-group col-md">
              <Input
                type="text"
                className="form-control"
                value={
                  marketCategory.names.bg ? marketCategory.names.bg.name : ''
                }
                placeholder="Sport Name"
                onChange={handleInputChange}
                name="bg"
              />
              <label htmlFor="bg">
                Bulgarian name <i className="flag-icon flag-icon-bg" />{' '}
              </label>
            </div>
            <div className="form-label-group col-md">
              <Input
                type="text"
                className="form-control"
                value={
                  marketCategory.names.ro ? marketCategory.names.ro.name : ''
                }
                placeholder="Sport Name"
                onChange={handleInputChange}
                name="ro"
              />
              <label htmlFor="ro">
                Romanian name <i className="flag-icon flag-icon-ro" />
              </label>
            </div>
            <div className="form-label-group col-md">
              <Input
                type="text"
                className="form-control"
                value={
                  marketCategory.names.tr ? marketCategory.names.tr.name : ''
                }
                placeholder="Sport Name"
                onChange={handleInputChange}
                name="tr"
              />
              <label htmlFor="tr">
                Turkish name <i className="flag-icon flag-icon-tr" />
              </label>
            </div>
            <div className="form-label-group col-md">
              <Input
                type="text"
                className="form-control"
                value={
                  marketCategory.names.gr ? marketCategory.names.gr.name : ''
                }
                placeholder="Sport Name"
                onChange={handleInputChange}
                name="gr"
              />
              <label htmlFor="gr">
                Greek name <i className="flag-icon flag-icon-gr" />
              </label>
            </div>
          </div>
        </form>
      ) : null}
      {isEditMode && Object.keys(marketCategory).length > 0 ? (
        <div className="form-group d-flex flex-row justify-content-end">
          <Button
            cssClass="efb-btn__main"
            onClick={() => updateMarketCategory(marketCategory)}
          >
            Update
          </Button>
          <Button cssClass="efb-btn__light" onClick={cancelEdit}>
            Cancel
          </Button>
        </div>
      ) : null}
      <hr />
    </Fragment>
  );
};

MarketCategoryForm.propTypes = {
  cancelEdit: PropTypes.func,
  marketCategoryObj: PropTypes.object,
  isEditMode: PropTypes.bool,
};

export default MarketCategoryForm;
