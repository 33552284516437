import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import RolesToolbar from '../../../../components/Toolbar/Toolbar';
import RolesTable from "./RolesTable";
import RolesFiltersModal from './RolesFiltersModal';
import RolesModal from './RolesModal';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getRolesData, getUiComponentsReducer} from "../../../../selectors";
import {toggleRolesFilter, toggleRolesModal, addNotificationMessage} from "../../../../redux/actions/ui";
import {getAllRoles, fetchApplications} from "../../../../redux/actions/rolesApi";

const roleObject = {
	description: "",
	id: "",
	privileges: [],
	role: "",
};

const filterObj = {
	role: ''
};

class RolesContainer extends Component {

	state = {
		role: Object.assign({}, roleObject),
		filters: Object.assign({}, filterObj),
		modalType: ''
	};

	clickNewRole = () => {
		this.setState({
			role: Object.assign({}, roleObject),
			modalType: 'create'
		});
		this.props.toggleRolesModal(true);
	};

	openFiltersModal = () => {
		this.props.toggleRolesFilter(true);
	};

	updateRole = (role) => {
		if(Object.keys(role).length === 0) {
			this.setState({role: Object.assign({}, roleObject)})
		} else {
			this.setState({role: role});
		}
	};

	updateRoleCheckboxes = (role) => {this.setState({role: role})};
	setRolesFilters = (filters) => this.setState({filters: filters});

	setRoleForEdit = (role) => {
		this.setState({role: role})
	};
	changeModalType = (modal) => this.setState({modalType: modal});

	render() {
		let { roles, rolesFiltersModal, toggleRolesFilter, getAllRoles, toggleRolesModal, rolesModal, fetchApplications, addNotificationMessage } = this.props,
			{role, filters, modalType} = this.state;
		return(
			<Fragment>
				<RolesToolbar
					onClickFilter={this.openFiltersModal}
					onClickNew={this.clickNewRole}
				/>
				<RolesTable
					roles={roles}
					toggleRolesModal={toggleRolesModal}
					setRoleForEdit={this.setRoleForEdit}
					selectedRole={role}
					setModalType={this.changeModalType}
				/>
				<RolesFiltersModal
					getAllRoles={getAllRoles}
					isModalOpen={rolesFiltersModal}
					toggleRolesFilter={toggleRolesFilter}
					filters={filters}
					setRolesFilters={this.setRolesFilters}
				/>
				{
					rolesModal ? (
						<RolesModal
							isRolesModalOpen={rolesModal}
							toggleRolesModal={toggleRolesModal}
							updateRoleForEdit={this.updateRole}
							updateRoleCheckboxes={this.updateRoleCheckboxes}
							role={role}
							modalType={modalType}
							fetchApplications={fetchApplications}
							addNotificationMessage={addNotificationMessage}
						/>
					) : null
				}

			</Fragment>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const roles = getRolesData(state),
		rolesFiltersModal = getUiComponentsReducer(state).toggleRolesFilter,
		rolesModal = getUiComponentsReducer(state).toggleRolesModal;
	return {
		roles,
		rolesFiltersModal,
		rolesModal
	};
};
const mapDispatchToProps = {
	getAllRoles,
	toggleRolesFilter,
	toggleRolesModal,
	fetchApplications,
	addNotificationMessage
};

RolesContainer.propTypes = {
	roles: PropTypes.array,
	toggleRolesFilter: PropTypes.func,
	rolesFiltersModal: PropTypes.bool,
	rolesModal: PropTypes.bool
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RolesContainer));