import { STS_GET_ALL_SPORTS, STS_GET_SPORT_CATEGORIES, STS_GET_TOURNAMENTS, STS_GET_SEASONS } from '../../actions/cms/stsBuilder';

const initialState = {
    sports: [],
    categories: [],
    tournaments: [],
    seasons: [],
    catUniqueId: 0
}

export const stsBuilderReducer = (state = initialState, action) => {
    switch (action.type) {

        case STS_GET_ALL_SPORTS.SUCCESS:
            return {
                ...state,
                sports: action.payload
            }

        case STS_GET_SPORT_CATEGORIES.SUCCESS:
            return {
                ...state,
                categories: action.payload,
                catUniqueId: Date.now() + Math.random()
            }

        case STS_GET_TOURNAMENTS.SUCCESS:
            return {
                ...state,
                tournaments: action.payload
            }

        case STS_GET_SEASONS.SUCCESS:
            return {
                ...state,
                seasons: action.payload
            }

        default:
            return state
    }
}

