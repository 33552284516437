import PropTypes from 'prop-types';
import React from 'react';
import classes from './CheckboxDiv.module.scss';
import Input from '../Input';

const CheckboxDiv = ({
  name,
  id,
  isChecked,
  onChange,
  label,
  className
}) => (
  <div
    className={`${classes.checkbox} ${className}`}
  >
    <Input
      type="checkbox"
      className="d-none"
      id={id}
      name={name}
      isChecked={isChecked}
      value={isChecked}
      onChange={onChange}
    />
    <label htmlFor={id}>{label}</label>
  </div>
);

CheckboxDiv.propTypes = {
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.any
};

export default CheckboxDiv;



