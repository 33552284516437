import React from 'react';
import { PropTypes } from 'prop-types';

const Tabs = ({
	currentTab,
	setFilterType
}) => {
	return (
		<ul className="nav nav-tabs nav-fill" id="searchController">
			<li className="nav-item" onClick={() => setFilterType('form')}>
				<span className={currentTab === 'form' ? 'nav-link active' : 'nav-link'}>Add / Edit </span>
			</li>
			<li className="nav-item" onClick={() => setFilterType('rankings')}>
				<span className={currentTab === 'rankings' ? 'nav-link active' : 'nav-link'}>Rankings</span>
			</li>
		</ul>
	)
}

Tabs.propTypes = {
	filterType: PropTypes.string,
	setFilterType: PropTypes.func
}

export default Tabs;