export const isEvent = (ev, producers, type) => {
  return (
    producers
      .filter(producer => producer.scope === type)
      .map(producer => producer.id)
      .indexOf(ev.currentProducerId) > -1
  );
};

export const isEventPrematch = (ev, producers) =>
  isEvent(ev, producers, 'prematch');
export const isEventLive = (ev, producers) => isEvent(ev, producers, 'live');
