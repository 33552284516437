import { SYSTEM_SETTINGS, SPORT_TYPES_VIEW, FEED_PROVIDERS } from '../../actions/riskManagement/systemSettings';

const initialState = {
	systemSettings: {},
	sportTypesView: [],
	feedProviders: []
};

//use switch it's more readable
export const systemSettingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SYSTEM_SETTINGS.SUCCESS:
			return {
				...state,
				systemSettings: action.payload
			};
		case SPORT_TYPES_VIEW.SUCCESS:
			return {
				...state,
				sportTypesView: action.payload
			};
		case FEED_PROVIDERS.SUCCESS:
			return {
				...state,
				feedProviders: action.payload
			};
		default:
			return state;
	}
};
