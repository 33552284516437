import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as NraScgActions from '../../../../redux/actions/reporting/nra-scg';
import * as UiActions from '../../../../redux/actions/ui';

import Button from '../../../../components/Layout/Button';
import Select from 'react-select';

import { useStateForSelect } from '../../../../hooks';
import { selectStyles } from '../../../CMS/customReactSelectStyle';
import { currencyConvert } from '../../../../utils/helpers';

const IncomingBetsWinnings = ({
  monthsOptions,
  getNraReports,
  addNotificationMessage,
}) => {
  const [selectedMonth, setSelectedMonth] = useStateForSelect('');
  const [selectedTypes, setSelectedTypes] = useStateForSelect('');

  const typeOptions = useMemo(
    () => [
      { label: 'Casino', value: 'casino' },
      { label: 'Sport', value: 'sport' },
    ],
    []
  );

  const generateReport = useCallback(async () => {
    if (selectedMonth && selectedTypes.length > 0) {
      const data = await getNraReports(selectedMonth);
      if (data) {
        const sheetData = selectedTypes.map(type => ({
          'Game Type': type.label,
          Period: moment.utc(selectedMonth.from).format('MMMM'),
          Currency: 'BGN',
          'Bets Placed': currencyConvert(
            data[`totalBet${type.value[0].toUpperCase() + type.value.slice(1)}`]
          ),
          'Winnings Paid': currencyConvert(
            data[
              `totalProfit${type.value[0].toUpperCase() + type.value.slice(1)}`
            ]
          ),
        }));
        const fileType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const ws = XLSX.utils.json_to_sheet(sheetData);
        ws['!cols'] = [
          'Game Type/License',
          'Period',
          'Currency',
          'Bets Placed',
          'Winnings Paid',
        ].map(type => ({ wch: type.length }));
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const file = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(file, 'IncomingBetsAndWinnings.xlsx');
      }
    } else {
      addNotificationMessage('All fields are required!', 'warning', 'Warning!');
    }
  }, [addNotificationMessage, getNraReports, selectedMonth, selectedTypes]);

  return (
    <>
      <p>
        1.Report for incoming bets and winnings paid in CCU of the organizer of
        all registered clients and carrying out bets from the territory of
        Bulgaria with a possibility to choose time period
      </p>
      <Select
        styles={selectStyles}
        onChange={setSelectedMonth}
        value={selectedMonth}
        options={monthsOptions}
        placeholder="Select month"
      />
      <Select
        styles={selectStyles}
        onChange={setSelectedTypes}
        value={selectedTypes}
        options={typeOptions}
        className="mt-3"
        placeholder="Select type"
        isMulti
      />

      <Button cssClass="efb-btn__main btn-block mt-3" onClick={generateReport}>
        Generate Report
      </Button>
    </>
  );
};

const mapDispatchToProps = {
  addNotificationMessage: UiActions.addNotificationMessage,
  getNraReports: NraScgActions.getNraReports,
};

export default connect(null, mapDispatchToProps)(IncomingBetsWinnings);
