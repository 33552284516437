import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { selectStyles } from '../../../customReactSelectStyle';
import { useUpdate, useStateForSelect } from '../../../../../hooks';

const Selectors = ({ marketCategories, marketTypes, filters, index, updateFilter }) => {
	const [ marketCategory, setMarketCategory ] = useStateForSelect('');
	const [ marketType, setMarketType ] = useStateForSelect('');

	useUpdate(
		() => {
			if (Object.keys(marketCategory).length > 0) handleSelectChange(marketCategory, 'marketCategory');
			setMarketType('');
		},
		[ marketCategory ]
	);

	useUpdate(
		() => {
			if (Object.keys(marketType).length > 0) handleSelectChange(marketType, 'marketType');
		},
		[ marketType ]
	);

	const marketCategoriesList = useMemo(
		() => {
			return marketCategories.reduce(
				(acc, val) => [
					...acc,
					{
						value: { names: val.names, id: val.id, feedProviderId: val.feedProviderId },
						label: val.names.en.name
					}
				],
				[]
			);
		},
		[ marketCategories ]
	);

	const marketTypesList = useMemo(
		() => {
			return marketTypes.reduce(
				(acc, val) => [
					...acc,
					{ value: { names: val.descriptionTemplates, id: val.id }, label: val.descriptionTemplates.en.name }
				],
				[]
			);
		},
		[ marketTypes ]
	);

	const handleSelectChange = useCallback(
		(value, name) => {
			const filtersClone = { ...filters };
			filtersClone.markets[index][name] = value;
			updateFilter(filters, 'market_list', name);
		},
		[ filters, index, updateFilter ]
    );
    
	return (
		<div className="form-row">
			<div className="form-label-group col-md">
				<Select
					value={marketCategory}
					onChange={setMarketCategory}
					options={marketCategoriesList}
					placeholder="Market Category"
					styles={selectStyles}
				/>
			</div>
			<div className="form-label-group col-md">
				<Select
					value={marketType}
					onChange={setMarketType}
					isDisabled={marketTypes.length === 0  || filters.markets[index].marketCategory === ''}
					options={marketTypesList}
					placeholder="Market Type"
					styles={selectStyles}
				/>
			</div>
		</div>
	);
};

Selectors.propTypes = {
	filters: PropTypes.object,
	handleInputChange: PropTypes.func,
	index: PropTypes.number,
	market: PropTypes.object,
	marketCategories: PropTypes.array,
	marketTypes: PropTypes.array
};

export default Selectors;
