import React, { PureComponent } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { groupBy } from 'lodash';
import { selectStyles } from '../../../CMS/customReactSelectStyle';
import * as EventsManagerActions from '../../../../redux/actions/cms/eventsManager';
import * as SCTManager from '../../../../redux/actions/cms/sctManager';
import { getEventsManagerData } from '../../../../selectors';
import { uniqueSportType } from '../SportTemplates/utils';

export class SelectSCT extends PureComponent {
  state = {
    sport: '',
    category: '',
    tournament: '',
    sports: [],
    categories: [],
    tournaments: []
  };

  componentDidMount() {
    this.prepareSCT();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.template !== this.props.template) {
      this.prepareSCT();
    }
  }

  prepareSCT = () => {
    const { template, marketSportViews } = this.props;
    const sports = uniqueSportType(marketSportViews, 'sportName');
    const mappedSports = sports.map(sportView => ({
      value: sportView.sportId,
      label: sportView.sportName
    }));

    let mappedCategories = [];
    if (template.sportId) {
      const allCategories = groupBy(marketSportViews, 'sportId')[
        template.sportId
      ];
      const categories = uniqueSportType(allCategories, 'categoryName');
      mappedCategories = categories.map(sportView => ({
        value: sportView.categoryId,
        label: sportView.categoryName
      }));
    }

    let mappedTournaments = [];
    if (template.categoryId) {
      const allTournaments = marketSportViews.filter(
        event =>
          event.categoryId === template.categoryId &&
          event.sportId === template.sportId
      );

      mappedTournaments = allTournaments.map(sportView => ({
        value: sportView.tournamentId,
        label: sportView.tournamentName
      }));
    }

    let setSelects = {};
    if (this.props.mode === 'edit') {
      setSelects = {
        sport: '',
        category: '',
        tournament: ''
      };
      Object.keys(template)
        .filter(name => name.indexOf('Id') > -1)
        .forEach(key => {
          if (template[key]) {
            const name = key.slice(0, -2) + 'Name';
            const label = marketSportViews.find(
              sportView => sportView[key] === template[key]
            )[name];
            setSelects[key.slice(0, -2)] = {
              value: template[key],
              label
            };
          }
        });
    }

    this.setState({
      sports: mappedSports,
      categories: mappedCategories,
      tournaments: mappedTournaments,
      ...setSelects
    });
  };

  prepareTournaments = () => {
    const mapped = this.props.tournaments.map(tournament => ({
      value: tournament.id,
      label: tournament.names.en.name
    }));

    this.setState({
      tournaments: mapped
    });
  };

  prepareCategories = () => {
    const mapped = this.props.categories.map(category => ({
      value: category.id,
      label: category.names.en.name
    }));

    this.setState({
      categories: mapped
    });
  };

  prepareSports = () => {
    const mapped = this.props.sports.map(sport => ({
      value: sport.id,
      label: sport.names.en.name
    }));

    this.setState({
      sports: mapped
    });
  };

  setSCT = async ({ sportId, categoryId, tournamentId }) => {
    const sport = await this.props.getSportById(sportId);
    const addons = { category: '', tournament: '' };
    if (categoryId) {
      const category = this.props.getCategoryById(categoryId);
      addons.category = {
        label: category.name.en.name,
        value: sport.id
      };
    }
    if (tournamentId) {
      const tournament = this.props.getTournamentById(tournamentId);
      addons.tournament = {
        label: tournament.names.en.name,
        value: tournament.id
      };
    }

    this.setState({
      sport: {
        label: sport.names.en.name,
        value: sport.id
      },
      ...addons
    });
  };

  onChange = (data, { name }) => {
    let resetStuff = {};
    if (name === 'sport') {
      resetStuff = {
        category: '',
        tournament: ''
      };
    } else if (name === 'category') {
      resetStuff = {
        tournament: ''
      };
    }

    this.props.onChange(data, name);
    this.setState({
      [name]: data,
      ...resetStuff
    });
  };

  render() {
    const { mode } = this.props;
    const {
      sports,
      categories,
      tournaments,
      sport,
      category,
      tournament
    } = this.state;
    return (
      <div className="my-3 form-row">
        <Select
          className="col-4"
          name="sport"
          placeholder="Select sport"
          styles={selectStyles}
          value={sport}
          options={sports}
          onChange={this.onChange}
          isDisabled={mode === 'edit'}
        />
        {(category && mode === 'edit') || (sport && mode === 'create') ? (
          <Select
            className="col-4"
            name="category"
            placeholder="Select category"
            styles={selectStyles}
            value={category}
            options={categories}
            onChange={this.onChange}
            isDisabled={mode === 'edit'}
          />
        ) : null}
        {(category && mode === 'create') || (mode === 'edit' && tournament) ? (
          <Select
            className="col-4"
            name="tournament"
            placeholder="Select tournament"
            styles={selectStyles}
            value={tournament}
            options={tournaments}
            onChange={this.onChange}
            isDisabled={mode === 'edit'}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sports: getEventsManagerData(state).sports,
  categories: getEventsManagerData(state).categories,
  tournaments: getEventsManagerData(state).seasons
});

const mapDispatchToProps = {
  getSportById: SCTManager.getSportById,
  getCategoryById: SCTManager.getCategoryById,
  getTournamentById: SCTManager.getTournamentById,
  getTournaments: EventsManagerActions.getSeasons,
  getCategories: EventsManagerActions.getCategories,
  getSports: EventsManagerActions.getSports
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectSCT);
