import { GET_SPORTS } from '../actions/birDelay';

const initialState = {
  dateForTable: [],
  sports: [],
};

export const birDelayReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPORTS.SUCCESS:
      return {
        ...state,
        dateForTable: action.payload,
        sports: action.payload,
      };

    default:
      return state;
  }
};
