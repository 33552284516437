// Helper function to create simple action
export const action = (type, payload = {}) => {
  return { type, ...payload };
};

export const verboseAction = (type, payload = {}) => {
  return {
    type: type,
    payload: { ...payload }
  };
};

export const getToken = () => {
  let string;
  try {
    string = localStorage.getItem('currentUser')
      ? JSON.parse(localStorage.getItem('currentUser'))['access_token']
      : '';
  } catch (error) {
    throw new Error('Unable to parse user token');
  }
  return string;
};

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const EMPTY = 'EMPTY';

export const createRequestTypes = base => {
  return [REQUEST, SUCCESS, FAILURE, EMPTY].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
};

export const actionCreator = {
  request: type => action(type.REQUEST),
  success: (type, payload) => action(type.SUCCESS, { payload }),
  failure: (type, error) => verboseAction(type.FAILURE, error),
  empty: type => action(type.EMPTY)
};
