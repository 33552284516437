import React, { useCallback, useMemo, useState } from 'react';
import Select from 'react-select';
import moment from 'moment';

import classes from './SelectionsTableRow.module.scss';
import Switch from '../../Form/Switch/Switch';
import Input from '../../Form/Input';
import {
  useStateForInput,
  useStateForSelect,
  useStateForSwitch,
  useUpdate,
} from '../../../hooks';
import { selectStyles } from '../../../containers/CMS/customReactSelectStyle';

const Row = ({
  selection,
  changedOdds,
  oddChanges,
  setOddChanges,
  priceChanges,
  setPriceChanges,
}) => {
  const [odds, setOdds] = useState(
    changedOdds[selection.selectionId] || selection.outcomeOdds
  );
  const [minutes, setMinutes] = useStateForSwitch(false);
  const [amount, setAmount] = useStateForInput(1);

  useUpdate(() => {
    if (changedOdds[selection.selectionId]) {
      setOdds(changedOdds[selection.selectionId]);
    }
  }, [changedOdds]);

  const predefinedAmounts = useMemo(
    () => [
      ...Array.from({ length: 5 }).map((_, i) => ({
        label: `${i + 1} ${minutes ? 'Minutes' : 'Seconds'}`,
        value: i + 1,
      })),
      { label: 'Other', value: '' },
    ],
    [minutes]
  );

  const startOdds = useMemo(
    () => changedOdds[selection.selectionId] || selection.outcomeOdds,
    [changedOdds, selection.outcomeOdds, selection.selectionId]
  );

  const [predefinedAmount, setPredefinedAmount] = useStateForSelect(
    predefinedAmounts[predefinedAmounts.length - 1]
  );

  const onOddsChange = useCallback(
    e => {
      if (
        e.target.value >
        (changedOdds[selection.selectionId] || selection.outcomeOdds)
      ) {
        setOdds(changedOdds[selection.selectionId] || selection.outcomeOdds);
      } else if (Number(e.target.value) === 0) {
        setOdds('');
      } else if (e.target.value.length > 6) {
        return;
      } else {
        setOdds(e.target.value);
      }
    },
    [changedOdds, selection.outcomeOdds, selection.selectionId, setOdds]
  );

  const buildIsoDuration = useCallback(
    () => `PT${predefinedAmount.value || amount}${minutes ? 'M' : 'S'}`,
    [amount, minutes, predefinedAmount.value]
  );

  useUpdate(() => {
    if (Number(odds) === startOdds) {
      const oddChangesCopy = { ...oddChanges };
      delete oddChangesCopy[selection.selectionId];

      setPriceChanges(
        priceChanges.filter(
          priceChange => priceChange.selectionId !== selection.selectionId
        )
      );
      setOddChanges(oddChangesCopy);
    } else if (oddChanges[selection.selectionId]) {
      setPriceChanges(
        priceChanges.map(priceChange =>
          priceChange.selectionId === selection.selectionId
            ? {
                ...priceChange,
                duration: buildIsoDuration(),
                newPrice: Number(odds),
              }
            : priceChange
        )
      );
      setOddChanges({ ...oddChanges, [selection.selectionId]: Number(odds) });
    } else {
      setPriceChanges([
        ...priceChanges,
        {
          selectionId: selection.selectionId,
          duration: buildIsoDuration(),
          marketId: selection.marketId,
          newPrice: Number(odds),
          outcomeId: selection.outcomeOddsId,
        },
      ]);
      setOddChanges({ ...oddChanges, [selection.selectionId]: Number(odds) });
    }
  }, [odds, buildIsoDuration, predefinedAmount, amount, minutes]);

  return (
    <tr>
      <td>{selection.sportName}</td>
      <td>{selection.eventName}</td>
      <td>{selection.marketName}</td>
      <td>{selection.outcomeOddsName}</td>
      <td>{Number(selection.outcomeOdds).toFixed(2)}</td>
      <td className="bg-success">
        <div className="d-flex justify-content-center align-items-center text-white">
          <Input
            type="number"
            step={0.01}
            className={`form-control ${classes.oddsInput}`}
            maxLength={4}
            min={1.01}
            max={changedOdds[selection.selectionId] || selection.outcomeOdds}
            value={odds}
            onChange={onOddsChange}
          />
          {selection.priceChange
            ? `(${Number(selection.priceChange).toFixed(2)})`
            : ''}
        </div>
      </td>
      <td>
        {selection.priceChangeExpireAt
          ? moment
              .unix(selection.priceChangeExpireAt)
              .format('DD/MM/YYYY HH:mm')
          : ''}
      </td>
      <td>{selection.priceChangedBy}</td>
      <td>
        <div
          className={`d-flex align-items-center flex-column ${classes.oddsDuration}`}
        >
          <div className="d-flex w-100">
            <Select
              className="col"
              styles={selectStyles}
              onChange={setPredefinedAmount}
              value={predefinedAmount}
              options={predefinedAmounts}
            />
            {!predefinedAmount.value ? (
              <Input
                type="number"
                className="form-control col"
                value={amount}
                min={1}
                max={999}
                onChange={setAmount}
              />
            ) : null}
          </div>
          <div className="form-row d-flex align-items-center mt-2">
            <p className="m-0">Seconds</p>
            <Switch
              className="mx-2"
              isChecked={minutes}
              onChange={setMinutes}
            />
            <p className="m-0">Minutes</p>
          </div>
        </div>
      </td>
    </tr>
  );
};

const SelectionsTableRow = ({ tableData, ...props }) => {
  return tableData.map(selection => (
    <Row key={selection.selectionId} selection={selection} {...props} />
  ));
};

export default SelectionsTableRow;
