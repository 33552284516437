import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as TranslationsActions from '../../../redux/actions/cms/translations';
import FullScreenLoader from '../../../components/Layout/FullScreenLoader/FullScreenLoader';
import { getCMSData, createLoadingSelector } from '../../../selectors';
import TranslationsTable from './TranslationsTable';
import AddTranslations from './AddTranslations';
import CSVData from './CSVData';
import PropTypes from 'prop-types';
import { useUpdate, useStateForSelect } from '../../../hooks';
import { Button } from '../../../components/Layout';
import { confirmAlert } from 'react-confirm-alert';

const Translations = ({
  getFilteredTranslations,
  translations,
  loading,
  addTranslations,
  deleteTranslations,
}) => {
  const [translationsList, setTranslationsList] = useState([]);
  const [importedCSV, setImportedCSV] = useState(false);
  const [language, setLanguage] = useStateForSelect('en');
  const [csvTranslations, setCsvTranslations] = useState([
    { id: 0, key: '', value: '' },
  ]);

  useUpdate(() => {
    setTranslationsList(
      translations.length > 0 ? translations[0].translations : []
    );
  }, [translations]);

  useUpdate(() => {
    if (language) getFilteredTranslations(language);
  }, [language]);

  const translationsArr = useMemo(() => {
    if (translationsList && Object.keys(translationsList).length > 0) {
      return Object.keys(translationsList).reduce(
        (acc, tr, index) => [
          ...acc,
          { key: tr, value: Object.values(translationsList)[index] },
        ],
        []
      );
    }
    return [];
  }, [translationsList]);

  const prepareTranslationForUpdate = useCallback(
    translation => {
      addTranslations(
        {
          language,
          translations: { [translation.key]: translation.value },
        },
        translation.editMode
      );
    },
    [addTranslations, language]
  );

  const prepareTranslationsForSave = useCallback(
    async translations => {
      let translationsObj = {};
      translations.map(tr => {
        return (translationsObj = { ...translationsObj, [tr.key]: tr.value });
      });
      const isSaved = await addTranslations({
        language,
        translations: translationsObj,
      });
      if (isSaved) {
        setCsvTranslations([{ id: 0, key: '', value: '' }]);
        setImportedCSV(false);
      }
    },
    [addTranslations, language]
  );

  const prepareTranslationForDelete = useCallback(
    translation => {
      deleteTranslations({
        language,
        translations: translation,
      });
    },
    [deleteTranslations, language]
  );

  const prepareAllTranslationsToDelete = useCallback(() => {
    confirmAlert({
      title: 'Confirm to delete',
      message: (
        <p>
          Are you sure you want to delete all <b>{translationsArr.length}</b>{' '}
          translations?{' '}
        </p>
      ),
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteTranslations({
              language,
              translations: translationsList,
            });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  }, [deleteTranslations, language, translationsArr.length, translationsList]);

  return (
    <>
      <br />
      <AddTranslations
        importedCSV={importedCSV}
        setImportedCSV={setImportedCSV}
        translations={translationsList}
        addTranslations={addTranslations}
        saveChanges={prepareTranslationsForSave}
        csvTranslations={csvTranslations}
        setLanguage={setLanguage}
        language={language}
      />
      <hr />
      <FullScreenLoader isActive={loading} />
      <div className="row">
        <div className="col-md">
          <Button
            cssClass="mr-1 ml-1 bet-scroller-btn delete-btn btn no-width"
            onClick={prepareAllTranslationsToDelete}
          >
            <i className="fa fa-trash" /> Delete all translations
          </Button>
        </div>
        <div className="col-md">
          <CSVData
            translations={translationsArr}
            language={language}
            setImportedCSV={setImportedCSV}
            setCsvTranslations={setCsvTranslations}
          />
        </div>
      </div>
      <br />
      <TranslationsTable
        translationsList={translationsArr}
        language={language}
        updateTranslation={prepareTranslationForUpdate}
        deleteTranslation={prepareTranslationForDelete}
      />
    </>
  );
};

Translations.propTypes = {
  addTranslations: PropTypes.func,
  deleteTranslations: PropTypes.func,
  filtered: PropTypes.array,
  getFilteredTranslations: PropTypes.func,
  insertTranslations: PropTypes.func,
  insertedTranslations: PropTypes.object,
  translations: PropTypes.array,
};

const loadingReducer = createLoadingSelector(['FILTER_TRANSLATIONS']);

const mapStateToProps = state => ({
  translations: getCMSData(state).translationsReducer.translations,
  insertedTranslations: getCMSData(state).translationsReducer
    .insertedTranslations,
  loading: loadingReducer(state),
});

const mapDispatchToProps = {
  addTranslations: TranslationsActions.addTranslations,
  deleteTranslations: TranslationsActions.deleteTranslations,
  getFilteredTranslations: TranslationsActions.getFilteredTranslations,
  insertTranslations: TranslationsActions.insertTranslations,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Translations)
);
