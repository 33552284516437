import React, { memo } from 'react';

export const Athletics = ({
  className,
  colorful,
  fill,
  height,
  selected,
  width,
  ...otherProps
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      className={`Athletics ${colorful ? 'Color' : ''} ${selected ? 'Selected' : ''} ${className ? className : ''}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 149.5 147.68"
      width={width || '100%'}
      height={'100%'}
      {...otherProps}
    >
      <g>
        <path
          className={'Figure'}
          d="M143.33,52.54h0a8.54,8.54,0,0,0-11.65,3L120.9,73.75,107.09,52a8.31,8.31,0,0,0-2.5-2.54,8.63,8.63,0,0,0-2-1.94L73.89,27.86a8.55,8.55,0,0,0-4.5-1.48,8.49,8.49,0,0,0-6,1.48L41.17,43.53a8.54,8.54,0,0,0-2,11.86h0a8.52,8.52,0,0,0,11.85,2l17.69-12.5L77.61,51,55.28,70.65a19.3,19.3,0,0,0-1.5,1.5c-.29.21-.57.44-.85.68L6,114a11.29,11.29,0,0,0-1,15.88H5a11.29,11.29,0,0,0,15.88,1L57.61,98.68l10.63,12.11L57,110.06a9.43,9.43,0,0,0-10,8.77h0a9.43,9.43,0,0,0,8.77,10l36.29,2.35a9.44,9.44,0,0,0,10-8.77h0a9.41,9.41,0,0,0-.29-3A11.14,11.14,0,0,0,99,111.7L81.93,92.27,101.56,75l12,18.86A8.61,8.61,0,0,0,115.41,96,8.12,8.12,0,0,0,117,97.13h0a8.52,8.52,0,0,0,11.65-3l17.72-29.95A8.53,8.53,0,0,0,143.33,52.54Z"
        />
        <path
          className={'Figure'}
          d="M114,47.72A16.06,16.06,0,1,0,98,31.24,16.1,16.1,0,0,0,114,47.72Z"
        />
      </g>
    </svg>
  );
};

export default memo(Athletics);
