import axiosInstance from './axios-instance';
import { createRequestTypes, actionCreator } from './index';

export const GET_SPORTS = createRequestTypes('GET_SPORTS');

export const getAllSports = () => async dispatch => {
  try {
    const res = await axiosInstance.get('/sport/event/view/sport/admin/');
    dispatch(actionCreator.success(GET_SPORTS, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_SPORTS, ''));
  }
};
