import { BET_HISTORY } from '../../actions/riskManagement/betHistory';

const initialState = {
  filteredBetHistory: [],
};

export const betHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case BET_HISTORY.SUCCESS:
      return {
        ...state,
        filteredBetHistory: action.payload,
      };
    default:
      return state;
  }
};
