import React from 'react';
import Switch from '../../../../components/Form/Switch/Switch';
import PropTypes from 'prop-types';
import { useStateForSwitch, useUpdate } from '../../../../hooks';

const GameUrl = ({ casinoGameObj, updateChanges }) => {
  const [demo, setDemo] = useStateForSwitch(false);

  useUpdate(() => {
    setDemo(casinoGameObj.demoAvailable);
  }, [casinoGameObj]);

  useUpdate(() => {
    updateChanges({ ...casinoGameObj, demoAvailable: demo });
  }, [demo]);

  return (
    <div className="efb-form">
      <div className="form-row">
        <div className="col-md">
          <Switch
            label={'Demo'}
            name="demoAvailable"
            isChecked={demo}
            value={demo}
            onChange={setDemo}
          />
        </div>
      </div>
    </div>
  );
};

GameUrl.propTypes = {
  casinoGameObj: PropTypes.object,
  editMode: PropTypes.bool,
  updateChanges: PropTypes.func,
};

export default GameUrl;
