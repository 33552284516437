import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../../components/Layout/Button';
import FullScreenLoader from '../../../../../components/Layout//FullScreenLoader/FullScreenLoader';
import Selectors from './Selectors';
import Outcomes from './Outcomes';
import Specifiers from './Specifiers';

const Market = ({
  filterType,
  filters,
  marketCategories,
  marketTypes,
  createSportEventMarket,
  loaded,
  backToBuilder,
  updateFilter,
  sportObj,
  languages,
}) => {
  const handleInputChange = useCallback(
    (e, index, outcomeIndex) => {
      const { name, value } = e.target;
      const filtersClone = { ...filters };
      switch (name) {
        case 'odds':
          filtersClone.markets[0].outcomes[outcomeIndex][name] = value;
          break;

        default:
          return null;
      }
    },
    [filters]
  );

  return (
    <Fragment>
      <FullScreenLoader isActive={loaded} />
      <form
        className={
          filterType === 'market_list' ? 'efb-form-client-search' : 'd-none'
        }
      >
        {filters.markets.map((market, index) => {
          return (
            <Fragment key={market.id}>
              <Selectors
                filters={filters}
                marketTypes={marketTypes}
                index={index}
                handleInputChange={handleInputChange}
                marketCategories={marketCategories}
                updateFilter={updateFilter}
                market={market}
              />
              <Outcomes
                filters={filters}
                handleInputChange={handleInputChange}
                index={index}
                sportObjNames={sportObj.names}
                languages={languages}
              />
              <br />
              <hr />
              {Object.keys(filters.specifiers).length > 0 ? (
                <Fragment>
                  <h3 className="efb-h3">Specifiers</h3>
                  <Specifiers filters={filters} updateFilter={updateFilter} />
                </Fragment>
              ) : null}
            </Fragment>
          );
        })}
        <div className="form-row justify-content-end">
          <Button cssClass={'efb-btn__main'} onClick={createSportEventMarket}>
            <i className="fas fa-plus" /> Assign market to event
          </Button>
        </div>
        <div className="form-row justify-content-start">
          <div className="form-label-group col-md-2">
            <Button
              type="button"
              cssClass="efb-btn__light btn-block"
              onClick={backToBuilder}
            >
              <i className="fas fa-arrow-left" /> Back to builder
            </Button>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

Market.propTypes = {
  createSportEventMarket: PropTypes.func,
  filterType: PropTypes.string,
  filters: PropTypes.object,
  marketCategories: PropTypes.array,
  marketTypes: PropTypes.array,
  updateFilter: PropTypes.func,
  addDefaultMarkets: PropTypes.func,
  backToBuilder: PropTypes.func,
};

export default Market;
