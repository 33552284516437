import React, { useState, useCallback } from 'react';
import Button from '../../Layout/Button';
import Switch from '../../../components/Form/Switch/Switch';
import { useUpdate } from '../../../hooks';

const CasinoGameTableRow = ({
  tableData,
  currentBu,
  previewImage,
  updateGameStatus,
  prepareImageUpload,
  editGame,
  editMode,
  deleteGame,
}) => {
  const [allGames, setAllGames] = useState(tableData);

  useUpdate(() => {
    setAllGames(tableData);
  }, [tableData]);

  const setStatus = useCallback(
    (e, index) => {
      const { name, checked } = e.target;
      const allGamesClone = [...allGames];
      allGamesClone[index][name] = checked;
      setAllGames(allGamesClone);
      updateGameStatus(allGamesClone[index]);
    },
    [allGames, updateGameStatus]
  );

  const mapGames = useCallback(
    (game, index) => {
      return (
        <tr key={game.gameId}>
          <td>{index + 1}.</td>
          <td>{currentBu}</td>
          <td className="capitalize">
            {game.names.en ? game.names.en.name : '/'}
          </td>
          <td>
            {game.web ? 'Web ' : null} {game.web & game.mobile ? '|' : null}{' '}
            {game.mobile ? 'Mobile' : null}{' '}
            {!game.web && !game.platform ? '/' : null}
          </td>
          <td>{game.provider}</td>
          <td>
            {game.images && game.images.length > 0 ? (
              <img
                src={
                  game.images.findIndex(x => x.channel === 'WEB') > -1
                    ? game.images[
                        game.images.findIndex(x => x.channel === 'WEB')
                      ].url
                    : ''
                }
                onClick={() => previewImage(game.images, game.names)}
                width="150"
                alt=""
              />
            ) : (
              '/'
            )}
          </td>
          <td>
            <Switch
              value={game.active}
              name="active"
              isDisabled={editMode}
              isChecked={game.active}
              onChange={e => setStatus(e, index)}
            />
          </td>
          <td>
            <Button
              cssClass={'btn btn-primary btn-md'}
              disabled={editMode || !game.web}
              onClick={() => prepareImageUpload(game, 'webImage')}
            >
              <i className="fa fa-desktop"></i> Web images
            </Button>
            <Button
              cssClass={'btn btn-primary btn-md'}
              disabled={editMode || !game.mobile}
              onClick={() => prepareImageUpload(game, 'mobileImage')}
            >
              <i className="fa fa-mobile-alt"></i> Mobile images
            </Button>
            <Button
              cssClass={'btn btn-primary btn-md'}
              disabled={editMode}
              onClick={() => editGame(game)}
            >
              <i className="fa fa-pen"></i> Edit game
            </Button>
            <Button
              cssClass={'btn btn-danger btn-md'}
              disabled={editMode}
              onClick={() => deleteGame(game.gameId)}
            >
              <i className="fa fa-trash"></i> Delete
            </Button>
          </td>
        </tr>
      );
    },
    [
      deleteGame,
      editGame,
      editMode,
      currentBu,
      prepareImageUpload,
      previewImage,
      setStatus,
    ]
  );

  return allGames.map(mapGames);
};

export default CasinoGameTableRow;
