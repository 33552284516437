import React, { useCallback } from 'react';
import { prepareImageList } from '../StaticContentUtils';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import { tinyMceApiKey } from '../../../../config/constants';

const TextEditor = ({
  htmlBody,
  images,
  index,
  uploadImage,
  value,
  initialValue,
}) => {
  const handleChange = useCallback(
    htmlValue => {
      const html = { target: { name: 'htmlBody', value: htmlValue } };
      htmlBody(html, index);
    },
    [htmlBody, index]
  );

  return (
    <div className="text-editor">
      <Editor
        initialValue={initialValue}
        value={value}
        apiKey={tinyMceApiKey}
        init={{
          plugins: 'link image code fullscreen',
          toolbar:
            'formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment fullscreen',
          image_list: prepareImageList(images),
          min_height: 300,
          images_upload_handler: (blobInfo, success, failure) =>
            uploadImage(blobInfo, success, failure),
        }}
        onEditorChange={handleChange}
      />
    </div>
  );
};

TextEditor.propTypes = {
  htmlBody: PropTypes.func,
  index: PropTypes.number,
  images: PropTypes.array,
  uploadImage: PropTypes.func,
  value: PropTypes.string,
  initialValue: PropTypes.string,
};

export default TextEditor;
