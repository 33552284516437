import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Close from '../Icons/Close';

const Modal = ({
  isActive,
  onClose,
  headerText = 'Modal',
  myClassName = '',
  children,
  type,
  small,
  big,
  fullscreen
}) => {
  const timeout = { enter: 500, exit: 300 };

  return (
    <TransitionGroup
      className="efb-modal__animation"
      appear={true}
    >
      {isActive ? (
        <CSSTransition timeout={timeout}>
          <section className={'efb-modal ' + myClassName}>
            <div className="efb-modal__overlay">
              <div
                className={
                  type === 'customers'
                    ? 'efb-modal__wrapper customers'
                    : type === 'transactions'
                    ? 'efb-modal__wrapper transactions'
                    : `efb-modal__wrapper ${small ? 'small' : ''}${
                      big ? 'big' : ''
                    }${fullscreen ? 'fullscreen' : ''}`
                }
              >
                <header className="efb-modal__header">
                  <h1>{headerText}</h1>
                  <span onClick={onClose}>
                    <Close size="xs" />
                  </span>
                </header>
                {children}
              </div>
            </div>
          </section>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  );
};

export default Modal;
