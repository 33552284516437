import React from 'react';
import ImageSize from './ImageSize';
import PropTypes from 'prop-types';

const Platform = ({
  images,
  loading,
  getImages,
  updateChanges,
  casinoGameObj,
  imagePlatform,
  activeImageSize,
  finishUploadingImage,
  currentGameImages,
}) => {
  return (
    <ImageSize
      casinoGameObj={casinoGameObj}
      images={images}
      loading={loading}
      activeImageSize={activeImageSize}
      finishUploadingImage={finishUploadingImage}
      getImages={getImages}
      imagePlatform={imagePlatform}
      updateChanges={updateChanges}
      currentGameImages={currentGameImages}
    />
  );
};

Platform.propTypes = {
  imagePlatform: PropTypes.string,
  getImages: PropTypes.func,
  casinoGameObj: PropTypes.object,
  images: PropTypes.array,
  loading: PropTypes.bool,
  updateChanges: PropTypes.func,
};

export default Platform;
