export const handleSort = (data, bu) =>
  data.sort((a, b) =>
    !a.rank[bu] || !b.rank[bu] ? 1 : a.rank[bu].rank - b.rank[bu].rank
  );

export const handleSearch = (arr, search, language) =>
  arr.filter(
    sport =>
      sport.names[language].name.toLowerCase().indexOf(search.toLowerCase()) !==
      -1
  );

export const mapSts = sts => ({ label: sts.names.en.name, value: sts.id });
