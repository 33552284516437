import React from 'react';
import Button from '../../Layout/Button';

const RiskCategoriesRow = ({ tableData, onEditClick }) => {
  const renderRow = rc => (
    <tr key={rc.id}>
      <td>{rc.name}</td>
      <td>{rc.description}</td>
      <td>{rc.referralMandatoryCnt}</td>
      <td>{rc.autopilotLimit}</td>
      <td>{rc.skipReferral ? 'True' : 'False'}</td>
      <td>{rc.autopilotAware ? 'True' : 'False'}</td>
      <td>
        <Button
          cssClass="btn btn-outline-secondary"
          onClick={() => onEditClick(rc)}
        >
          Edit
        </Button>
      </td>
    </tr>
  );
  return tableData.map(renderRow);
};

export default React.memo(RiskCategoriesRow);
