import axiosInstance from '../axios-instance';
import { actionCreator, createRequestTypes } from '../index';
import { addNotificationMessage } from '../ui';

export const SPORT_TEMPLATES = createRequestTypes('SPORT_TEMPLATES');
export const SPORT_TYPES_TEMPLATES = createRequestTypes(
  'SPORT_TYPES_TEMPLATES'
);
export const SPORT_VIEWS = createRequestTypes('SPORT_VIEWS'); // ENUM
export const GET_MARKET_TYPES_VIEWS = createRequestTypes(
  'GET_MARKET_TYPES_VIEWS'
);
export const GET_MARKET_SPORT_VIEWS = createRequestTypes(
  'GET_MARKET_SPORT_VIEWS'
);
export const GET_MARKET_TYPES_CONFIGS = createRequestTypes(
  'GET_MARKET_TYPES_CONFIGS'
);
export const GET_SYSTEM_TYPES = createRequestTypes('GET_SYSTEM_TYPES');
export const GET_BET_TYPE_CONFIGS = createRequestTypes('GET_BET_TYPE_CONFIGS');
export const CREATE_BET_TYPE_CONFIGS = createRequestTypes('CREATE_BET_TYPE_CONFIGS');
export const UPDATE_BET_TYPE_CONFIGS = createRequestTypes('UPDATE_BET_TYPE_CONFIGS');
export const DELETE_BET_TYPE_CONFIGS = createRequestTypes('DELETE_BET_TYPE_CONFIGS');
export const GET_ALL_BIR_DELAY = createRequestTypes('GET_ALL_BIR_DELAY');

// SPORT TEMPLATES
export const getSportTemplates = () => {
  return dispatch => {
    dispatch(actionCreator.request(SPORT_TEMPLATES));
    axiosInstance
      .get('/riskmgnt/view/admin/sports')
      .then(response => {
        return dispatch(actionCreator.success(SPORT_TEMPLATES, response));
      })
      .catch(err => {
        return dispatch(actionCreator.failure(SPORT_TEMPLATES, err));
      });
  };
};

export const addSportTemplate = sportTemplate => {
  return dispatch => {
    axiosInstance
      .post('/riskmgnt/view/admin/sports', sportTemplate)
      .then(response => {
        dispatch(
          addNotificationMessage(
            'Successful create new sport template',
            'success',
            'Success!'
          )
        );
        return dispatch(getSportTemplates());
      })
      .catch(error => {
        if (error.errors) {
          let errors = error.errors;
          let messages = Object.keys(errors).map(key => {
            return errors[key];
          });
          messages.forEach(message => {
            dispatch(addNotificationMessage(message, 'error', 'Error!'));
          });
        }
      });
  };
};

export const editSportTemplate = sportTemplate => {
  return dispatch => {
    axiosInstance
      .put(`/riskmgnt/view/admin/sports/${sportTemplate.id}`, sportTemplate)
      .then(response => {
        dispatch(getSportTemplates());
        dispatch(
          addNotificationMessage('Successful editing', 'success', 'Success!')
        );
      })
      .catch(error => {
        if (error.errors) {
          let errors = error.errors;
          let messages = Object.keys(errors).map(key => {
            return errors[key];
          });
          messages.forEach(message => {
            dispatch(addNotificationMessage(message, 'error', 'Error!'));
          });
        }
      });
  };
};

export const deleteSportTemplate = sportTemplate => {
  return dispatch => {
    axiosInstance
      .delete(`/riskmgnt/view/admin/sports/${sportTemplate.id}`)
      .then(response => {
        dispatch(
          addNotificationMessage('Successful deleting', 'success', 'Success!')
        );
        return dispatch(getSportTemplates());
      })
      .catch(error => {
        if (error.errors) {
          let errors = error.errors;
          let messages = Object.keys(errors).map(key => {
            return errors[key];
          });
          messages.forEach(message => {
            dispatch(addNotificationMessage(message, 'error', 'Error!'));
          });
        }
      });
  };
};

export const emptySportTemplates = () => {
  return dispatch => {
    dispatch(actionCreator.empty(SPORT_TEMPLATES));
  };
};

export const getSportViews = () => {
  return dispatch => {
    axiosInstance
      .get('riskmgnt/view/admin/sports/views')
      .then(response => {
        return dispatch(actionCreator.success(SPORT_VIEWS, response));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

// SPORT TEMPLATES - END

// MARKET TYPES

export const getMarketTypesConfigs = () => async dispatch => {
  dispatch(actionCreator.request(GET_MARKET_TYPES_CONFIGS));
  try {
    const res = await axiosInstance.get('/riskmgnt/view/admin/marketTypes');
    dispatch(actionCreator.success(GET_MARKET_TYPES_CONFIGS, res));
  } catch (error) {
    dispatch(
      addNotificationMessage(
        'Something went wrong, while trying to fetch configs',
        'error',
        'Error!'
      )
    );
    dispatch(actionCreator.failure(GET_MARKET_TYPES_CONFIGS, error.message));
  }
};

export const getMarketSportViews = () => async dispatch => {
  dispatch(actionCreator.request(GET_MARKET_SPORT_VIEWS));
  try {
    const res = await axiosInstance.get('/riskmgnt/view/admin/sports/views');
    dispatch(actionCreator.success(GET_MARKET_SPORT_VIEWS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_MARKET_SPORT_VIEWS));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const getMarketTypesViews = () => async dispatch => {
  dispatch(actionCreator.request(GET_MARKET_TYPES_VIEWS));
  try {
    const res = await axiosInstance.get(
      '/riskmgnt/view/admin/marketTypes/views'
    );

    dispatch(actionCreator.success(GET_MARKET_TYPES_VIEWS, res));
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    dispatch(actionCreator.failure(GET_MARKET_TYPES_VIEWS, error.message));
  }
};

export const createMarketTypeConfig = payload => async dispatch => {
  try {
    await axiosInstance.post('/riskmgnt/view/admin/marketTypes', payload);
    dispatch(
      addNotificationMessage(
        'Config created successfully!',
        'success',
        'Success!'
      )
    );
    dispatch(getMarketTypesConfigs());
  } catch (error) {
    if (Object.keys(error.errors).length) {
      Object.values(error.errors).forEach(message =>
        dispatch(addNotificationMessage(message, 'error', 'Error!'))
      );
    } else {
      dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
    }
  }
};

export const updateMarketTypeConfig = (payload, id) => async dispatch => {
  try {
    await axiosInstance.put(`/riskmgnt/view/admin/marketTypes/${id}`, payload);
    dispatch(
      addNotificationMessage(
        'Config updated successfully!',
        'success',
        'Success!'
      )
    );
    dispatch(getMarketTypesConfigs());
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

export const deleteMarketTypeConfig = id => async dispatch => {
  try {
    await axiosInstance.delete(`/riskmgnt/view/admin/marketTypes/${id}`);
    dispatch(
      addNotificationMessage(
        'Config deleted successfully!',
        'success',
        'Success!'
      )
    );
    dispatch(getMarketTypesConfigs());
  } catch (error) {
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

// Bet Types
export const getSystemTypes = () => async dispatch => {
  dispatch(actionCreator.request(GET_SYSTEM_TYPES));
  try {
    const res = await axiosInstance.get('/betslip/view/admin/system-types');
    dispatch(actionCreator.success(GET_SYSTEM_TYPES, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_SYSTEM_TYPES, error.message));
    dispatch(addNotificationMessage(error.message, 'error', 'Error!'));
  }
};

// Bet Type Configs
export const getBetTypeConfigs = () => async dispatch => {
  dispatch(actionCreator.request(GET_BET_TYPE_CONFIGS));
  try {
    const res = await axiosInstance.get('/riskmgnt/view/admin/betSystems');
    dispatch(actionCreator.success(GET_BET_TYPE_CONFIGS, res));
  } catch (error) {
    dispatch(actionCreator.failure(GET_BET_TYPE_CONFIGS, error));
  }
};

export const createBetTypeConfig = config => async dispatch => {
  dispatch(actionCreator.request(CREATE_BET_TYPE_CONFIGS));
  try {
    const res = await axiosInstance.post('/riskmgnt/view/admin/betSystems', config);
    dispatch(getBetTypeConfigs());
    dispatch(actionCreator.success(CREATE_BET_TYPE_CONFIGS, res));
    dispatch(addNotificationMessage('Successfully created config!', 'success', 'Success!'));
  } catch (error) {
    for (const message of Object.values(error.errors)) {
      dispatch(addNotificationMessage(message, 'error', 'Error!'));
    }
    dispatch(actionCreator.failure(CREATE_BET_TYPE_CONFIGS, error));
    throw error;
  }
};

export const updateBetTypeConfig = (configId, newConfig) => async dispatch => {
  dispatch(actionCreator.request(UPDATE_BET_TYPE_CONFIGS));
  try {
    const res = await axiosInstance.put(`/riskmgnt/view/admin/betSystems/${configId}`, newConfig);
    dispatch(getBetTypeConfigs());
    dispatch(actionCreator.success(UPDATE_BET_TYPE_CONFIGS, res));
    dispatch(addNotificationMessage('Successfully updated config!', 'success', 'Success!'));
  } catch (error) {
    for (const message of Object.values(error.errors)) {
      dispatch(addNotificationMessage(message, 'error', 'Error!'));
    }
    dispatch(actionCreator.failure(UPDATE_BET_TYPE_CONFIGS, error));
  }
};

export const deleteBetTypeConfig = configId => async dispatch => {
  dispatch(actionCreator.request(DELETE_BET_TYPE_CONFIGS));
  try {
    const res = await axiosInstance.delete(`/riskmgnt/view/admin/betSystems/${configId}`);
    dispatch(getBetTypeConfigs());
    dispatch(addNotificationMessage('Successfully deleted config!', 'success', 'Success!'));
    dispatch(actionCreator.success(DELETE_BET_TYPE_CONFIGS, res));
  } catch (error) {
    dispatch(actionCreator.failure(DELETE_BET_TYPE_CONFIGS, error));
  }
};

// Bir delay

export const getAllBirDelay = () => async dispatch => {
  dispatch(actionCreator.request(GET_ALL_BIR_DELAY));
  try {
    const res = await axiosInstance.get('/riskmgnt/view/admin/bird');
    dispatch(actionCreator.success(GET_ALL_BIR_DELAY, res));
  } catch (err) {
    dispatch(actionCreator.failure(GET_ALL_BIR_DELAY, err));
  }
};

export const addBirDelay = (birDelayObject) => async dispatch => {
  try {
    await axiosInstance.post('/riskmgnt/view/admin/bird', birDelayObject)
    dispatch(
      addNotificationMessage(
        'Successful create bir delay',
        'success',
        'Success!'
      )
    );
    dispatch(getAllBirDelay());
  } catch (err) {
    if (err.errors) {
      let errors = err.errors;
      let messages = Object.keys(errors).map(key => {
        return errors[key];
      });
      messages.forEach(message => {
        dispatch(addNotificationMessage(message, 'error', 'Error!'));
      });
    }
  }
};

export const editBirDelay = (id, birDelayObject) => async dispatch => {
  try {
    await axiosInstance.put(`/riskmgnt/view/admin/bird/${id}`, birDelayObject);
    dispatch(
      addNotificationMessage('Successful editing', 'success', 'Success!')
    );
    dispatch(getAllBirDelay());
  } catch (err) {
    if (err.errors) {
      let errors = err.errors;
      let messages = Object.keys(errors).map(key => {
        return errors[key];
      });
      messages.forEach(message => {
        dispatch(addNotificationMessage(message, 'error', 'Error!'));
      });
    }
  }
};

export const deleteBirDelay = birDelayObject => async dispatch => {
  try {
    await axiosInstance.delete(
      `/riskmgnt/view/admin/bird/${birDelayObject.id}`
    );

    dispatch(
      addNotificationMessage('Successful deleting', 'success', 'Success!')
    );
    dispatch(getAllBirDelay());
  } catch (err) {
    if (err.errors) {
      let errors = err.errors;
      let messages = Object.keys(errors).map(key => {
        return errors[key];
      });
      messages.forEach(message => {
        dispatch(addNotificationMessage(message, 'error', 'Error!'));
      });
    }
  }
};