import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useUpdate, useMount, useStateForSelect } from '../../../../hooks';
import LanguageSelector from '../../../../components/LanguageSelector/LanguageSelector';

const GameName = ({
  casinoGameObj,
  updateChanges,
  editMode,
  languages: reduxLangs,
}) => {
  const [languages, setLanguages] = useStateForSelect([]);
  const [casinoGame, setCasinoGame] = useState('');
  const [editModeInitialLoad, setEditModeInitialLoad] = useState(false);

  useUpdate(() => {
    if (editMode) setEditModeInitialLoad(true);
  }, [editMode]);

  useMount(() => {
    setCasinoGame(casinoGameObj);
  });

  useUpdate(() => {
    setCasinoGame(casinoGameObj);
    if (editModeInitialLoad && casinoGameObj.names.length > 0) {
      const gameLangs = casinoGameObj.names.reduce((acc, lang) => {
        return [
          ...acc,
          {
            value: lang.locale,
            label: reduxLangs.find(x => x.languageCode === lang.locale)
              .displayName,
            inputValue: lang.name,
          },
        ];
      }, []);
      setLanguages(gameLangs);
    }
    if (!editMode && casinoGameObj.names.length === 0) {
      setLanguages([]);
    }
  }, [casinoGameObj, editModeInitialLoad, editMode]);

  useUpdate(() => {
    if (!editModeInitialLoad) {
      const casinoGameObjClone = { ...casinoGame };
      if (languages && languages.length) onChange(languages);
      if (!languages) {
        casinoGameObjClone.names = [];
        setCasinoGame(casinoGameObjClone);
        updateChanges(casinoGameObjClone);
      }
    }
    setEditModeInitialLoad(false);
  }, [languages]);

  const handleInputChange = useCallback(
    e => {
      setEditModeInitialLoad(false);
      const { name, value } = e.target;
      const casinoGameObjClone = { ...casinoGame };
      const index = casinoGameObjClone.names.findIndex(x => x.locale === name);
      if (index === -1) {
        casinoGameObjClone.names.push({
          locale: name,
          name: value,
          translated: true,
        });
      } else {
        casinoGameObjClone.names[index].name = value;
      }

      setCasinoGame(casinoGameObjClone);
      updateChanges(casinoGameObjClone);
    },
    [casinoGame, updateChanges]
  );

  const onChange = useCallback(
    value => {
      let langArr = value;
      const casinoGameObjClone = { ...casinoGame };
      if (langArr) {
        if (casinoGameObjClone.names.length > langArr.length) {
          casinoGameObjClone.names = casinoGameObjClone.names.filter(entry1 => {
            return langArr.some(entry2 => {
              return entry1.locale === entry2.value;
            });
          });
          updateChanges(casinoGameObjClone);
        } else {
          langArr.map(lang => {
            const duplicatedLangIndex = casinoGameObjClone.names.findIndex(
              x => x.locale === lang.value
            );
            if (duplicatedLangIndex === -1) {
              casinoGameObjClone.names = [
                ...casinoGameObjClone.names,
                { locale: lang.value, name: '', translated: true },
              ];
            }
            return casinoGameObjClone;
          });
          updateChanges(casinoGameObjClone);
        }
      } else {
        casinoGameObjClone.names = [];
        langArr = [];
        updateChanges(casinoGameObjClone);
      }
      let choosenLanguagesClone = [...languages, langArr];
      choosenLanguagesClone = langArr;
      setLanguages(choosenLanguagesClone);
    },
    [casinoGame, languages, setLanguages, updateChanges]
  );

  const mapLanguages = useCallback(
    language => {
      const casinoGameClone = { ...casinoGame };
      const langObj =
        casinoGameClone.names.length > 0
          ? casinoGameClone.names.find(x => x.locale === language.value)
          : '';
      return (
        <Fragment key={language.value}>
          <div className="form-label-group col-md">
            <input
              type="text"
              className="form-control efb-input"
              value={langObj ? langObj.name : ''}
              placeholder="Sport Name"
              onChange={handleInputChange}
              name={language.value}
            />
            <label htmlFor={language.value}>
              {language.label}{' '}
              <i
                className={
                  language.value === 'en'
                    ? 'flag-icon flag-icon-gb'
                    : `flag-icon flag-icon-${language.value}`
                }
              ></i>{' '}
            </label>
          </div>
        </Fragment>
      );
    },
    [casinoGame, handleInputChange]
  );

  return (
    <Fragment>
      <div className="form-row">
        <div className="col-md">
          <LanguageSelector
            defaultLang={languages}
            onChange={setLanguages}
            isMulti
            placeholder="Select Game Language"
          />
          <br />
          <div className="efb-form">
            <div className="form-row">
              {languages ? languages.map(mapLanguages) : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

GameName.propTypes = {
  casinoGameObj: PropTypes.object,
  languages: PropTypes.array,
  updateChanges: PropTypes.func,
};

export default GameName;
