export const prepareForSettle = market => {
  Object.keys(market.outcomeBetInfos).map(outcome => {
    market.outcomeBetInfos[outcome]['outcomeSettlement'] = {
      winning: false,
      voidFactor: 0,
      deadHeatFactor: 0,
      outcomeResult: '',
      voidReason: null,
      voidReasonDescription: null,
      certainty: 'CONFIRMED',
    };
    return market.outcomeBetInfos[outcome]['outcomeSettlement'];
  });
  return market;
};

export const settleMarketObj = (market, outcomes) => {
  const marketClone = { ...market };
  const outcomesClone = [...outcomes];
  const outcomeBetInfosObj = outcomesClone.reduce((acc, outcome) => {
    outcome['id'] = outcome.outcomeId;
    outcome.outcomeSettlement['outcomeResult'] = outcome.outcomeSettlement
      .winning
      ? 'WON'
      : 'LOST';
    return [...acc, outcome];
  }, []);
  marketClone['betstopReason'] = '';
  marketClone['betstopReasonDescription'] = '';
  marketClone['bettingStatus'] = '';
  marketClone['nextBetStop'] = '';
  marketClone.marketTypeCategories.reduce((acc, val) => {
    val['specifiers'] = '';
    return [...acc, val];
  }, []);
  marketClone.outcomeBetInfos = outcomeBetInfosObj;
  return marketClone;
};

export const rollbackSettledMarket = market => {
  const marketClone = { ...market };
  marketClone['betstopReason'] = '';
  marketClone['betstopReasonDescription'] = '';
  marketClone['bettingStatus'] = '';
  marketClone['nextBetStop'] = '';
  marketClone['defaultMarket'] = false;
  if (marketClone.outcomeBetInfos.length) {
    marketClone.outcomeBetInfos.reduce((acc, val) => {
      val['id'] = val.outcomeId;
      return [...acc, val];
    }, []);
  }
  marketClone.marketTypeCategories.reduce((acc, val) => {
    val['specifiers'] = '';
    return [...acc, val];
  }, []);
  return marketClone;
};

export const betCancelMarketObj = {
  endTime: '2019-09-24T10:14:05.391Z',
  startTime: '2019-09-24T10:14:05.391Z',
  supercededBy: '',
};

export const prepareSportList = placedBetsFiltered => {
  const sports = placedBetsFiltered.sports
    ? placedBetsFiltered.sports.reduce(
        (acc, val) => [...acc, { value: val.sportId, label: val.sportName }],
        []
      )
    : null;
  return sports;
};
