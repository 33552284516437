import React from 'react';
import classes from './Footer.module.scss';
import { currencyConvert } from '../../../utils/helpers';

const Footer = ({ footerData }) => {
  const { totalReturn, totalStakes, wonLoss } = footerData;
  return (
    <div className={classes.footer}>
      <div>
        <div className={classes.customerTotals}>Customer Totals</div>
        <div className={classes.total}>
          Stakes: €{totalStakes ? currencyConvert(totalStakes) : 0}
        </div>
        <div className={classes.total}>
          Return: €{totalReturn ? currencyConvert(totalReturn) : 0}
        </div>
        <div className={classes.total}>
          Win/Loss: €{wonLoss ? currencyConvert(wonLoss) : 0}
        </div>
        <div className={classes.total}>
          Profit: €
          {totalStakes && wonLoss && wonLoss > 0
            ? ((wonLoss / totalStakes) * 100).toFixed(2)
            : 0}
          %
        </div>
        <div className={classes.graphic}>Graphic</div>
      </div>
    </div>
  );
};

export default React.memo(Footer);
