import React from 'react';
import { Button } from '../../Layout';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const ValidationsTableRow = ({ tableData, viewFullSize, history }) => {
  const renderRow = validation => {
    if (validation.status === 'PENDING') {
      return (
        <tr key={validation.id}>
          <td
            className="pointer"
            onClick={() =>
              history.push(
                `/adm/pm/manager?customerId=${validation.customerId}`
              )
            }
          >
            {validation.username}
          </td>
          <td
            className="pointer"
            onClick={() =>
              history.push(
                `/adm/pm/manager?customerId=${validation.customerId}`
              )
            }
          >
            {validation.id}
          </td>
          <td>
            {validation.verificationDocument
              ? validation.verificationDocument.documentType
              : null}
          </td>
          <td>{moment(validation.uploadDate).format('DD/MM/YYYY HH:mm:ss')}</td>
          <td>
            <Button
              cssClass="btn btn-danger btn-md mr-3"
              onClick={() => viewFullSize(validation)}
            >
              Review
            </Button>
          </td>
        </tr>
      );
    }
  };
  return tableData.map(renderRow);
};

export default React.memo(withRouter(ValidationsTableRow));
