import React, { useCallback } from 'react';
import DragAndDrop from '../../../components/DragAndDrop/DragAndDrop';
import Button from '../../../components/Layout/Button';
import Switch from '../../../components/Form/Switch/Switch';

const Row = ({
  index,
  draggableProps,
  sts,
  updateStatus,
  openEdit,
  editingSts,
  ranking,
}) => {
  return (
    <tr
      draggable={ranking}
      data-index={index}
      {...draggableProps}
      data-id={sts.id}
    >
      <td>{index + 1}</td>
      <td>{sts.names.en ? sts.names.en.name : 'Not available name'}</td>
      <td>
        <Switch
          className="no-margin-status-switcher"
          isChecked={sts.status === 'ACTIVE'}
          onChange={e =>
            updateStatus(sts, e.target.checked ? 'ACTIVE' : 'DEACTIVATED')
          }
        />
      </td>
      <td>
        {editingSts === sts ? (
          <span>Currently editing</span>
        ) : (
          <Button
            cssClass="btn btn-sm btn-outline-secondary"
            onClick={() => openEdit(sts)}
          >
            <i className="fas fa-edit" /> Edit
          </Button>
        )}
      </td>
    </tr>
  );
};

const StsTable = ({
  data,
  onDrop,
  updateStatus,
  editSts,
  editingSts,
  ranking = true,
}) => {
  const renderTBody = useCallback(
    (droppableProps = {}, draggableProps = {}) => (
      <tbody {...droppableProps}>
        {data
          ? data.map((sts, index) => (
              <Row
                key={sts.id}
                index={index}
                sts={sts}
                draggableProps={draggableProps}
                updateStatus={updateStatus}
                openEdit={editSts}
                editingSts={editingSts}
                ranking={ranking}
              />
            ))
          : null}
      </tbody>
    ),
    [data, editSts, editingSts, ranking, updateStatus]
  );

  if (!data.length) return null;
  return (
    <table className="table table-striped table-sm efb-table efb-table-translations">
      <thead>
        <tr>
          <th>No.</th>
          <th>Name</th>
          <th>Inactive / Active</th>
          <th>Edit</th>
        </tr>
      </thead>
      {ranking ? (
        <DragAndDrop data={data} onDrop={onDrop}>
          {({ draggableProps, droppableProps }) =>
            renderTBody(droppableProps, draggableProps)
          }
        </DragAndDrop>
      ) : (
        renderTBody()
      )}
    </table>
  );
};

export default StsTable;
